// @ts-nocheck
import React, { useCallback, useEffect, useRef } from 'react'
import { Button } from '@lemonsqueezy/wedges';
import { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { DropdownGroup } from '../type';
import FormBuilder from './FormBuilder';
import Toolbox from '../../../components/Toolbox';
import { FormElement } from './types';
import { v4 as uuidv4 } from 'uuid';
import { fetchAllStepsIntergrationDetails, submitSurveyStepIntegration } from '../integration-redux/integration.actions';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState, useAppDispatch } from '../../../store/store';
import { toast } from 'sonner';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FormContext } from './FormContext';



function IntegrationSurvey({ integration }: any) {
    const [isFromAsideOpen, setIsFormAsideOpen] = useState(false);
    const [elements, setElements] = useState<FormElement[]>([]);
    const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
    const baseId = baseIdString ? JSON.parse(baseIdString) : null;
    const [steps, setSteps] = useState([]);
    const prevIntegrationId = useRef<string | null>(null);
    const [errors, setErrors] = useState<{ isError: boolean, reason?: string }[]>([]);
    const [isFromReOrderOpen, setIsFormReOrderOpen] = useState(false);
    const [elementOrder, setElementOrder] = useState([]);

    const [loading, setLoading] = useState(false)
    const [loadingOrder, setLoadingOrder] = useState(false)

    const handleAddElement = (element: FormElement) => {
        setElements([...elements, { ...element, id: uuidv4() }]);
        setElementOrder([...elementOrder, { ...element, id: uuidv4() }]);
        setErrors([...errors, false]);
    };


    const handleFormStepOpen = () => {
        setIsFormAsideOpen(true);
        setIsFormReOrderOpen(false);

    }

    const handleReOderOpen = () => {
        setIsFormReOrderOpen(true);
        setIsFormAsideOpen(false);
    }

    const updateElementMandatory = useCallback((elementId, isMandatory) => {
        setElements(prevElements => 
          prevElements.map(el => 
            el.id === elementId ? { ...el, is_mandatory: isMandatory } : el
          )
        );
    }, []);
      

    const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useAppDispatch();

    const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setLoading(true);

        const integrationSteps = elements.map((element, index) => {
    
            const stepData = {
                id: element.id,
                base_id: baseId,
                integration_id: integration.id,
                step_sequence: index + 1,
                step_title: element.headerText || "Default Title",
                step_input_type: element.type,
                step_input_options: JSON.stringify(element.options || {}),
                step_show_condition: "No",
                step_show_logic: "",
                // step_mandatory: element.is_mandatory === true ? "YES" : "NO"

            };
    
            // Only set step_mandatory if is_mandatory has been explicitly set
            if (element.hasOwnProperty('is_mandatory')) {
                stepData.step_mandatory = element.is_mandatory === true ? "YES" : "NO";
            }
            

            return stepData;
        });


        try {
            const response = await dispatch(submitSurveyStepIntegration(baseId, integrationSteps));
            const { data } = response;

            const newErrors = data.map((step: { isError: boolean; reason?: string }, index: number) => {
                if (step.isError) {
                    toast.error(`Step ${index + 1}: ${step.reason || 'Unknown error'}`);
                    return { isError: true, reason: step.reason || 'Unknown error' };
                }
                return { isError: false };
            });

            setErrors(newErrors);

            if (newErrors.every(error => !error.isError)) {
                toast.success("Integration steps saved successfully.");
            }
            // Fetch updated steps after successful save
            const updatedSteps = await dispatch(fetchAllStepsIntergrationDetails(baseId, integration.id));

            if (Array.isArray(updatedSteps)) {
                const formattedElements = updatedSteps.map(step => (
                    
                    {
                    ...step,
                    id: step.id || uuidv4(),
                    type: step.step_input_type,
                    headerText: step.step_title,
                    required: step.step_mandatory === 'yes',
                    options: JSON.parse(step.step_input_options || '{}')
                }));
                setElements(formattedElements);
                setElementOrder(formattedElements);  // Update elementOrder as well
            }

        } catch (error) {
            console.error("Error submitting integration steps:", error);
            toast.error("An error occurred while submitting the integration steps.");
        } finally {
            setLoading(false);
        }
    };



    // all integration steps details

    useEffect(() => {
        const fetchSteps = async () => {
            if (integration?.id && baseId) {
                try {
                    const response = await dispatch(fetchAllStepsIntergrationDetails(baseId, integration.id));
                    if (Array.isArray(response)) {
                        const formattedElements = response.map(step => ({
                            ...step,
                            id: step.id || uuidv4(),
                            type: step.step_input_type,
                            headerText: step.step_title,
                            required: step.step_mandatory,
                            options: JSON.parse(step.step_input_options || '{}')
                        }));
                        setElements(formattedElements);
                        setElementOrder(formattedElements);
                    } else {
                        console.log("Response is not an array:", response);
                    }
                } catch (error) {
                    console.error("Failed to fetch integration steps:", error);
                }
            }
        };
        fetchSteps();
    }, [dispatch, integration?.id, baseId]);


    // draggable 
    const handleOnDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) return;

        const newElementOrder = Array.from(elementOrder);
        const [movedElement] = newElementOrder.splice(source.index, 1);
        newElementOrder.splice(destination.index, 0, movedElement);

        setElementOrder(newElementOrder);
    };

    const updateReOrder = async () => {
        setLoadingOrder(true);

        const integrationSteps = elementOrder.map((element, index) => ({
            id: element.id,
            base_id: baseId,
            integration_id: integration.id,
            step_sequence: index + 1,
            step_title: element.headerText || "Default Title",
            step_input_type: element.type,
            step_input_options: JSON.stringify(element.options || {}),
            step_mandatory: element.is_mandatory ? "YES" : "NO",
            step_show_condition: "No",
            step_show_logic: ""
        }));

        try {
            const response = await dispatch(submitSurveyStepIntegration(baseId, integrationSteps));
            const { data } = response;

            const newErrors = data.map((step: { isError: boolean; reason?: string }, index: number) => {
                if (step.isError) {
                    toast.error(`Step ${index + 1}: ${step.reason || 'Unknown error'}`);
                    return { isError: true, reason: step.reason || 'Unknown error' };
                }
                return { isError: false };
            });

            if (newErrors.every(error => !error.isError)) {
                toast.success("Integration steps reordered successfully.");

                // Fetch updated steps after successful reorder
                const updatedSteps = await dispatch(fetchAllStepsIntergrationDetails(baseId, integration.id));

                if (Array.isArray(updatedSteps)) {
                    const formattedElements = updatedSteps.map(step => ({
                        ...step,
                        id: step.id || uuidv4(),
                        type: step.step_input_type,
                        headerText: step.step_title,
                        required: step.step_mandatory === "YES",
                        options: JSON.parse(step.step_input_options || '{}')
                    }));
                    setElements(formattedElements);
                    setElementOrder(formattedElements);
                } else {
                    console.log("Updated response is not an array:", updatedSteps);
                }
            }

        } catch (error) {
            console.error("Error reordering integration steps:", error);
            toast.error("An error occurred while reordering the integration steps.");
        } finally {
            setLoadingOrder(false);
            setIsFormReOrderOpen(false);
        }
    };

    return (
        <FormContext.Provider value={{ elements, updateElementMandatory }}>
        <div>
            <div className="flex items-center justify-between my-7">
                <h4 className="font-medium text-dark-black-200  mr-1 text-[15px] flex items-center gap-[6px]">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-dark-black-200 ">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                    </svg>
                    Steps
                </h4>
                <div className='flex items-center gap-x-2'>

                    <Button size='xs-icon'
                        type="button"
                        onClick={handleSubmit}
                        className="bg-indigo-600 h-[32px] hover:bg-indigo-500 focus-visible:outline-indigo-600"
                    >
                        <div className='flex items-center  gap-[6px]'>
                            Save
                            {loading && (
                                <div className="simple-spinner">
                                    <span></span>
                                </div>
                            )}
                        </div>
                    </Button>


                </div>

            </div>


            {elements.length === 0 ? (
                <div className="text-gray-500 text-center mt-8 pt-8">
                    No Integrations steps here.
                </div>
            ) : (
                <ul>
                    {elements.map((element, index) => (
                        <li key={index} className="relative flex gap-x-4 mt-4">
                            <div className="relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[10px] flex items-center justify-center">
                                <span className="text-[11px] font-medium text-gray-700">{index + 1}</span>
                            </div>
                            <div
                                className={`flex-auto rounded-md p-3 cursor-pointer ring-1 ring-inset ${errors[index]?.isError ? 'ring-red-500' : 'ring-gray-200'
                                    } feedback-delete-hover`}
                            // className="flex-auto rounded-md p-3 cursor-pointer ring-1 ring-inset ring-gray-200 feedback-delete-hover"
                            >
                                <div className="block items-start">
                                    <div className="w-full flex flex-col gap-1">
                                        <div className="flex items-center">
                                            <FormBuilder elements={[element]} setElements={setElements} index={index} setIsFormAsideOpen={setIsFormAsideOpen}
                                                error={errors[index]}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-[5%]"></div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
            <div className='flex items-center gap-x-2 mt-10 ml-[36px]'>
                <Button size='xs-icon'
                    onClick={handleReOderOpen}
                    className="bg-indigo-600 h-[32px] hover:bg-indigo-500 focus-visible:outline-indigo-600"
                >
                    Reorder
                </Button>

                <Button size='xs-icon'
                    onClick={handleFormStepOpen}
                    className="bg-indigo-600 h-[32px] hover:bg-indigo-500 focus-visible:outline-indigo-600"
                >Add Step</Button>

            </div>
            <div className='flex flex-col min-w-[200px] bg-white border-t-0 border border-light-gray-200 border-solid z-10 absolute lg:fixed right-0 max-w-[80%] view-feedback-right-side'
                style={{
                    width: isFromAsideOpen ? '351px' : '350px',
                    transform: isFromAsideOpen ? 'none' : 'none',
                    marginRight: isFromAsideOpen ? '0' : '-400px',
                    top: isFromAsideOpen ? '46px' : '46px',
                    ...(window.innerWidth <= 768 && {
                        width: isFromAsideOpen ? '300px' : '300px',
                        marginRight: isFromAsideOpen ? '0' : '-300px'
                    }),
                }}
            >
                <div className='h-full'>
                    <aside className='flex flex-col justify-stretch
                     border-l-0 border-r border-solid border-light-gray-200 p-[1.5rem] items-start gap-4'>

                        <div className="mx-auto w-full flex items-center justify-between">
                            <p className="text-center text-wedges-gray text-sm font-medium">
                                Add step
                            </p>

                            <button
                                type="button"
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                                onClick={() => setIsFormAsideOpen(false)}
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                            </button>

                        </div>
                        <Toolbox onAddElement={handleAddElement} />

                    </aside>
                </div>

            </div>

            <div className='flex flex-col min-w-[200px] bg-white border-t-0 border border-light-gray-200 border-solid z-10 absolute lg:fixed right-0 max-w-[80%] view-feedback-right-side'
                style={{
                    width: isFromReOrderOpen ? '351px' : '350px',
                    transform: isFromReOrderOpen ? 'none' : 'none',
                    marginRight: isFromReOrderOpen ? '0' : '-400px',
                    top: isFromReOrderOpen ? '46px' : '46px',
                    ...(window.innerWidth <= 768 && {
                        width: isFromReOrderOpen ? '300px' : '300px',
                        marginRight: isFromReOrderOpen ? '0' : '-300px'
                    }),
                }}
            >
                <div className='h-full'>
                    <aside className='flex flex-col justify-stretch
                     border-l-0 border-r border-solid border-light-gray-200 p-[1.5rem] items-start gap-4'>

                        <div className="mx-auto w-full flex items-center justify-between">
                            <p className="text-center text-wedges-gray text-sm font-medium">
                                Reorder
                            </p>
                            <div className='flex items-center gap-x-2'>
                                <Button size='xs-icon'
                                    onClick={updateReOrder}
                                    className="bg-indigo-600 text-[12px] py-1 h-[26px] hover:bg-indigo-500 focus-visible:outline-indigo-600"
                                >
                                    <div className='flex items-center  gap-[6px]'>
                                        Save reorder
                                        {loadingOrder && (
                                            <div className="simple-spinner">
                                                <span></span>
                                            </div>
                                        )}
                                    </div>


                                </Button>
                                <button
                                    type="button"
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                                    onClick={() => setIsFormReOrderOpen(false)}
                                >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>

                        </div>
                        <ul className='w-full'>

                            {isFromReOrderOpen && (
                                <DragDropContext onDragEnd={handleOnDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided) => (
                                            <ul {...provided.droppableProps} ref={provided.innerRef} className='flex flex-col gap-y-3'>
                                                {elementOrder.map((element, index) => (
                                                    <Draggable key={element.id} draggableId={element.id} index={index}>
                                                        {(provided) => (
                                                            <li
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className="flex-auto rounded-md p-3 cursor-pointer ring-1 ring-inset ring-gray-200 feedback-delete-hover w-ful"
                                                            >

                                                                <div className='flex flex-col gap-y-2'>
                                                                    <div className='flex items-center gap-x-2'>

                                                                        <span className="text-[11px] font-medium text-gray-700">{index + 1}</span>

                                                                        <div className='cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2  text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0'>
                                                                            {element.type}
                                                                        </div>
                                                                    </div>
                                                                    <div className='font-[500] text-[#121217] text-[14px]'>
                                                                        {element.headerText}
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </ul>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            )}
                        </ul>

                    </aside>
                </div>

            </div>

        </div>
        </FormContext.Provider>
    )
}

export default IntegrationSurvey