import { _baseRequest } from "../../../services/api";
import { COMMON } from "../../../utils/common";

const API_END_POINT = {
    CREATENEWINTEGRATION: "/v1/integration/{0}/addIntegration",
    FETCHINTEGRATIONDATALIST: "/v1/integration/{0}/integrationList",
    FETCHINTEGRATIONDETAILS: "/v1/integration/{0}/{1}/integrationDetails",
    ITEMINTEGRATIONDETAILSUPDATE: "/v1/integration/{0}/{1}/updateIntegration",
    ITEMSTEPSURVEYINTEGRATION: "/v1/integration_steps/{0}/addIntegrationSteps",
    INTEMINTEGRATIONSTEPSDETAILS: "/v1/integration_steps/{0}/{1}/integrationStepList",
    INTEMINTEGRATIONSTEPSDETAILSPUBLIC: "/v1/integration/{0}/publicIntegrationDetails",
    INTEMINTEGRATIONSTEPRESPONSE: "/v1/integration-response/addResponse",
    DELETEMULTIINTEGRATIONRESPONSE: "/v1/integration-response/10010/deleteResponses",
    DELETEMULTIINTEGRATION: "/v1/integration/10010/deleteIntegrations",
    INTEGRATIONRESPONSECOUNT: "/v1/integration-response/{0}/{1}/{2}/countResponse"
}

interface IntegrationStep {
    id: string;
    base_id: number;
    integration_id: string;
    step_sequence: number;
    step_title: string;
    step_input_type: string;
    step_input_options: string;
    step_mandatory: string;
    step_show_condition: string;
    step_show_logic: string;
}


export const _submitCreateIntegration = async (baseId: number, integrationTypeData: { integration_name: string, integration_type: string }): Promise<{ data: any }> => {
    return _baseRequest(
        COMMON.stringFormat(API_END_POINT.CREATENEWINTEGRATION, baseId),
        "POST",
        integrationTypeData
    ).then((response) => {
        return response;
    }).catch((error) => {
        throw error;
    });
};

export const _fetchIntegrationList = async (baseId: number): Promise<{ data: any }> => {
    return _baseRequest(
        COMMON.stringFormat(API_END_POINT.FETCHINTEGRATIONDATALIST, baseId),
        "GET",
    ).then((response) => {
        return response;
    }).catch((error) => {
        throw error;
    });
};

export const _fetchIntergrationDetailsAPI = async (id: number, baseId: number): Promise<{ data: any }> => {
    return _baseRequest(
        COMMON.stringFormat(API_END_POINT.FETCHINTEGRATIONDETAILS, id, baseId),
        "GET",
    ).then((response) => {
        return response;
    }).catch((error) => {
        throw error;
    });
};

export const _updateIntegrationDetailsAPI = async (
    id: number,
    baseId: number,
    options?: {
        integration_name?: string;
        integration_status?: string;
        integration_type?: string;
        integration_label?: string;
        integration_ai_assist?: string;
    }
): Promise<{ data: any }> => {
    const payload = {
        base_id: baseId,
        ...options,
    };

    return _baseRequest(
        COMMON.stringFormat(API_END_POINT.ITEMINTEGRATIONDETAILSUPDATE, baseId, id),
        "PUT",
        payload
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            throw error;
        });
};


export const _submitSurveyStepIntegration = async (
    baseId: number,
    integrationSteps: IntegrationStep[]
): Promise<{ data: any }> => {
    return _baseRequest(
        COMMON.stringFormat(API_END_POINT.ITEMSTEPSURVEYINTEGRATION, baseId),
        "POST",
        integrationSteps
    ).then((response) => {
        return response;
    }).catch((error) => {
        throw error;
    });
};


export const _fetchAllStepsIntergrationDetails = async (id: number, baseId: number, query: string = ''): Promise<{ data: any }> => {
    return _baseRequest(
        COMMON.stringFormat(API_END_POINT.INTEMINTEGRATIONSTEPSDETAILS, id, baseId) + query,
        "GET",
    ).then((response) => {
        return response;
    }).catch((error) => {
        throw error;
    });
};

export const _fetchIntergrationDetailsAPI1 = async (baseId: number, id: string): Promise<{ data: any }> => {
    return _baseRequest(
        COMMON.stringFormat(API_END_POINT.FETCHINTEGRATIONDETAILS, baseId, id),
        "GET"
    ).then((response) => {
        return response;
    }).catch((error) => {
        throw error;
    });
};

export const _fetchIntergrationDetailsAPIPublic = async (id: string): Promise<{ data: any }> => {
    return _baseRequest(
        COMMON.stringFormat(API_END_POINT.INTEMINTEGRATIONSTEPSDETAILSPUBLIC, id),
        "GET",
    ).then((response) => {
        return response;
    }).catch((error) => {
        throw error;
    });
};

export const _submitStepIntegrationResponse = async (
    requestData: {
        integration_id: string;
        response_id: string;
        customer_data: { customer_name: string; customer_email: string; customer_phone_no: string };
        integration_response: Array<{ step_id: string; step_type: string; response: string }>;
        feedback_response: 'partial' | 'full';
    },
    integrationId: string
): Promise<{ data: any }> => {
    const url = `${COMMON.stringFormat(API_END_POINT.INTEMINTEGRATIONSTEPRESPONSE, integrationId)}`;

    return _baseRequest(
        url,
        "POST",
        requestData
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            throw error;
        });
};

export const _deleteItemMultiResponses = async (baseId: number, responseIds: number[]): Promise<any> => {
    return _baseRequest(
        COMMON.stringFormat(API_END_POINT.DELETEMULTIINTEGRATIONRESPONSE, baseId),
        "DELETE",
        { responseIds }
    ).then((response) => {
        return response;
    }).catch((error) => {
        throw error;
    });
};

export const _fetchIntergrationCountResponse = async (step_id: number, baseId: number, integration_id: number): Promise<{ data: any }> => {
    return _baseRequest(
        COMMON.stringFormat(API_END_POINT.INTEGRATIONRESPONSECOUNT, step_id, baseId, integration_id),
        "GET",
    ).then((response) => {     
        return response;
    }).catch((error) => {
        throw error;
    });
};
