import { useState } from 'react';
import { Routes, Route, Outlet } from "react-router-dom";
import Sidebar from '../../setting/settingSidebar/SideBar';
import Header from '../../setting/settingHeader/Header';


const User: React.FC = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
    const baseId = baseIdString ? JSON.parse(baseIdString) : null;

    return (
        <div className='overflow-auto h-full'>
            <Sidebar id={baseId} onClickValue={sidebarOpen} onClick={(e: boolean) => setSidebarOpen(e)} />
            <div className='lg:pl-[225px]'>
                <Header id={baseId} onClick={() => setSidebarOpen(true)} />
                <div className='px-5 lg:px-0 w-full h-screen mb-[140px]'>
                <main className='py-0'>
                    <div className='w-full'>
                        <Outlet />
                    </div>
                </main>
                </div>
            </div>
        </div>
    );
};

export default User;
