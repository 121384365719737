
import React, { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { Dialog, Label, Listbox, Transition, DialogTitle, DialogPanel } from '@headlessui/react';
import ViewFeedbackQuickMode from "../../qucikMode/ViewFeedbackQuickMode";
import { _feedback_update_item } from "../../setting/settingPages/baseAPI";


// Helper function to get local storage data
const getLocalStorageData = () => {
  const data = localStorage.getItem('baseMembersDetails');
  return data ? JSON.parse(data) : [];
};

const getLocalStorageBoards = () => {
  const data = localStorage.getItem('channelData');
  return data ? JSON.parse(data) : [];
};

const ListItem = ({ item, index }: any) => {
  const localStorageData = getLocalStorageData();
  const assignedMember = localStorageData.find((member: { id: any; }) => member.id === item.item_assigned_to);
  const title = assignedMember ? assignedMember.display_name : '';

  // boards
  const localStroageBoards = getLocalStorageBoards();
  const assignBoards = localStroageBoards.find((member: { id: any; }) => member.id === item.board_id);
  const boardsName = assignBoards ? assignBoards.name : '';
  const boardsColor = assignBoards ? assignBoards.color : '';

  // sentiments 
  const getSentimentColor = (sentiment: string) => {
    switch (sentiment?.toLowerCase()) {
      case 'positive':
        return '#16a34a33';
      case 'negative':
        return '#dc26261a';
      case 'spam':
        return '#6b72801a';
      case 'mixed':
        return '#1d4ed81a';
      default:
        return 'transparent';
    }
  };

  const sentiment = item.item_sentiment?.trim();
  const bgColor = getSentimentColor(sentiment);

  const [selectedItemId, setSelectedItemId] = useState<any>(null)
  const [quickViewMode, setQuickViewMode] = useState(false)
  const [selectedItemType, setSelectedItemType] = useState<any>(null);

  const handleQuickMode = (itemId: any, itemType: any) => {
    setQuickViewMode(true)
    setSelectedItemId(itemId)
    setSelectedItemType(itemType);
  }

  return (
    <>
      <Draggable draggableId={String(item.id)} key={item.id} index={index}>
        {(provided, snapshot) => (
          <div
            className="flex items-stretch justify-stretch touch-pan-right mb-2 border border-solid border-light-gray-200 rounded-md bg-white"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...provided.draggableProps.style,
              backgroundColor: snapshot.isDragging ? "lightblue" : "white",
              boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)'
            }}
          >
            <div className="custom-drap-drop cursor-pointer no-underline outline-none flex flex-col w-min-0 break-words
         text-dark-black-100 w-full"  onClick={() => handleQuickMode(item.id, 'feedback')}>
              <div className="flex flex-col gap-[0rem] p-4 flex-1 relative">
                <div className="flex flex-col justify-start">
                  {
                    item.item_refernce_id &&
                    <div className="flex gap-2 items-center pb-[6px]">

                      <p className="font-[500] text-[13px] text-dark-black-200 leading-4 ">{item.item_refernce_id}</p>
                    </div>
                  }

                  {
                    (item?.item_company || item?.item_person) && (
                      <p className="font-[500] text-[13px] text-dark-black-200 leading-4 pb-[6px] line-clamp-2">

                        <div className="flex items-center">
                          {item?.item_company?.item_customer_name && item?.item_person?.item_customer_name && (
                            <span className="">
                              {item?.item_company?.item_customer_name} - {item?.item_person?.item_customer_name}
                            </span>
                          )}
                        </div>
                        <div className="flex items-center">
                          {item?.item_company === null && (
                            <span>
                              {item?.item_person?.item_customer_name}
                            </span>
                          )}
                        </div>
                        <div className="flex items-center">
                          {item?.item_person === null && (
                            <span>
                              {item?.item_company?.item_customer_name}
                            </span>
                          )}
                        </div>

                        <div className="flex items-center">

                          {
                            (!item?.item_company?.item_customer_name && !item?.item_person?.item_customer_name) && (
                              <div className="flex items-center">
                                {(item?.item_company?.item_customer_email_id || item?.item_person?.item_customer_email_id) && (
                                  <span className="flex items-center">
                                    {item?.item_company?.item_customer_email_id}
                                    {item?.item_company?.item_customer_email_id && item?.item_person?.item_customer_email_id && (
                                      <span className="feedback-kanban-design"> </span>
                                    )}
                                    {item?.item_person?.item_customer_email_id}
                                  </span>
                                )}
                              </div>
                            )
                          }
                        </div>

                        {
                          (!item?.item_company?.item_customer_name &&
                            !item?.item_person?.item_customer_name &&
                            !item?.item_company?.item_customer_email_id &&
                            !item?.item_person?.item_customer_email_id) && (
                            <span>
                              {item?.item_person?.item_customer_phone_number}
                            </span>
                          )
                        }
                      </p>
                    )
                  }


                  <div className="flex items-start gap-1">
                    <p className="text-dark-black-100 text-[13px] leading-4 font-medium "> {item.item_title}</p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  {
                    boardsName && boardsColor &&
                    <div className="pt-[1rem]">
                      <div className="flex items-center max-w-fit font-[500] leading-[1.2] bg-light-gray-100 rounded-full text-[0.75rem] px-[0.5rem] py-[5px] gap-2">
                        <span className="flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] 
                font-medium text-white capitalize" style={{ backgroundColor: boardsColor }}></span>
                        <span className="text-[#52555a] capitalize">{boardsName}</span>
                      </div>
                    </div>
                  }
                  {
                    item.item_sentiment &&
                    <div className="pt-[1rem]">
                      <div className="flex items-center max-w-fit font-[500] leading-[1.2] bg-light-gray-100 rounded-full text-[0.75rem] px-[0.5rem] py-[5px] gap-2">
                        <span className="flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] 
                font-medium text-white capitalize" style={{ backgroundColor: bgColor }}></span>
                        <span className="text-[#52555a] capitalize">{item.item_sentiment}</span>
                      </div>
                    </div>
                  }
                  {
                    title &&
                    <div className="pt-[1rem]">
                      <div className="w-5 h-5 rounded-full bg-gray-200 flex items-center justify-center text-[10px] table-tooltip leading-4 before:leading-4" data-title={title}>
                        <span className="text-[9px] font-medium text-gray-700">
                          {title ? title.charAt(0) : ''}
                        </span>
                      </div>
                    </div>
                  }


                </div>

              </div>
            </div>
          </div>
        )}
      </Draggable>


      <Dialog open={quickViewMode} onClose={setQuickViewMode} className="relative z-10">
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <DialogPanel

                className="pointer-events-auto w-screen max-w-[40rem] transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
              >
                <div className="flex h-full flex-col  bg-white py-0 shadow-xl">
                  <div className="px-0">
                    <div className="flex items-start justify-between flex-col">
                      <ViewFeedbackQuickMode
                        itemId={selectedItemId}
                        isSidebarOpen={quickViewMode}
                        itemType={selectedItemType}
                        setIsSidebarOpen={setQuickViewMode} />


                    </div>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>

    </>
  );
};

export default ListItem;
