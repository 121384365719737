//@ts-nocheck

import React, { useEffect, useRef, useState } from "react"
import { format } from 'date-fns';
import CustomerTable from "../../customer/list/CustomerTable";
import { useLocation } from "react-router-dom";
import { useOutletContext } from 'react-router-dom';
import { useSearch } from "../../../context/SearchContext";


export default function Customer() {
  const location = useLocation();
  const [activeButton, setActiveButton] = useState('one');
  const [sectionHeight, setSectionHeight] = useState(0);
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);
  const storedFilters = localStorage.getItem('activeFiltersCustomer');
  const { searchTerm } = useOutletContext<{ searchTerm: string }>();
  let initialFilters: any;
  if (location.state) {
    const { rowData } = location.state;
    initialFilters = JSON.parse(rowData?.view_keys)
  } else {

    initialFilters = storedFilters ? JSON.parse(storedFilters) : {
      Boards: [], label: [], assign: [],
      priority: [], sentiment: [], status: [], 'Created at': [],
      'Updated By': []
    };
  }
  const [activeFilters, setActiveFilters] = useState({
    ...initialFilters,
    search: searchTerm, 
  });
  const [searchQuery, setSearchQuery] = useState("");
  const selectedValuesRef = useRef<HTMLDivElement>(null);
  const [activeState, setActiveState] = useState('all');
  const [assignedItemId, setAssignedItemId] = useState<string | null>(null);
  const [cusType, setCusType] = useState<string | null>(null);
  const { clearAll } = useSearch();

  useEffect(() => {
    setActiveFilters(prevFilters => ({ ...prevFilters, search: searchTerm }));
  }, [searchTerm]);

    useEffect(() => {
      setActiveFilters({
        ...initialFilters,
        search: '',
      });
    }, [location.pathname]);
  

    const removeFilter = (category, id) => {
      if (category === 'search') {
        setActiveFilters((prevFilters) => ({
          ...prevFilters,
          search: ''
        }));
      } else {
        setActiveFilters((prevFilters) => ({
          ...prevFilters,
          [category]: (prevFilters[category] || []).filter((filter) => filter.id !== id)
        }));
      }
    };
  useEffect(() => {
    if (selectedValuesRef.current) {
      setSectionHeight(selectedValuesRef.current.offsetHeight);
    } else {
      setSectionHeight(0);
    }
  }, [activeFilters]);


  const handleClearAll = () => {
    clearAll()
    setActiveFilters([]);
  }


  const renderIcon = (icon) => {
    if (icon && icon.type && icon.props) {
      return React.createElement(icon.type, icon.props);
    }

    return null;
  };

  function handleCustomerType(type: string) {
    setAssignedItemId("item_customer_type");
    setCusType(type)
    setActiveState(type)
  }



  useEffect(() => {
    localStorage.setItem('activeFiltersCustomer', JSON.stringify(activeFilters));
  }, [activeFilters]);

  const clearPaginationFromState = () => {
    window.dispatchEvent(new CustomEvent('paginationresetsetate'));
  };

  const hasActiveFilters = Object.values(activeFilters).some(filters => filters.length > 0);


  return (
    <>
      <div className={`fixed top-[56px] feedback-table-section`}>
        <div className='flex h-full lg:h-[45px] shrink-0 justify-between items-center gap-1 md:gap-2 lg:gap-4 bg-white shadow-custom-1 py-2 px-2 md:py-4 lg:py-4 md:px-4 lg:px-4 flex-wrap lg:flex-nowrap'>
          <div className='flex items-center rounded-md'>
            <button
              className={`px-[6px] md:px-2 lg:px-2 text-[12px] md:text-[0.8125rem] lg:text-[0.8125rem] leading-[1.2] font-semibold py-[5px]
               border border-r-0 border-solid border-[rgba(0, 0, 0, 0.08)] rounded-tl-md rounded-bl-md ${activeState === 'all' ? 'bg-[#6b7280] text-white  border-[#6b7280]' : 'bg-[#f9fafa] text-[#0000007a] hover:text-black-100'}`}
              onClick={() => {
                setActiveState('all');
                setAssignedItemId(null);
                setCusType(null)
              }}
            >
              All
            </button>
            <button
              className={`px-[6px] md:px-2 lg:px-2 text-[12px] md:text-[0.8125rem] lg:text-[0.8125rem] leading-[1.2] font-semibold py-[5px] border
               border-solid border-[rgba(0, 0, 0, 0.08)] ${activeState === 'person' ? 'bg-[#6b7280] text-white border-[#6b7280]' : 'bg-[#f9fafa] text-[#0000007a] hover:text-black-100'}`}
              onClick={() => {

                handleCustomerType('person')
                clearPaginationFromState();

              }}
            >
              Person
            </button>
            <button
              className={`px-[6px] md:px-2 lg:px-2 text-[12px] md:text-[0.8125rem] lg:text-[0.8125rem] leading-[1.2] font-semibold py-[5px] border
               border-solid border-[rgba(0, 0, 0, 0.08)] rounded-tr-md rounded-br-md ${activeState === 'company' ? 'bg-[#6b7280] text-white border-[#6b7280]' : 'bg-[#f9fafa] text-[#0000007a] hover:text-black-100'}`}
              onClick={() => {

                handleCustomerType('company')
                clearPaginationFromState();

              }}
            >
              Company
            </button>
            <div className="relative inline-block text-left">
              {/* <FilterButton label="Filter" onClick={handleMainMenuClick} /> */}
              {isMainMenuOpen && (
                <div ref={mainMenuRef} className="absolute right-0 z-10 mt-2 left-0 mq575:w-[160px] mq3000:w-[234px] origin-top-right rounded-md bg-white filter-dropdown border border-solid border-[#00000014]"
                  style={{ boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)' }}>
                  <div className="py-1">
                    <input
                      type="search"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="w-full outline-none border-t-0 border-l-0 border-r-0 border-b border-solid border-[#00000029] 
                      rounded-none text-sm h-[36px] pl-[10px] pr-[30px focus:outline-none focus:shadow-none focus:ring-0 focus:border-[#00000029] font-normal text-[#9f9f9fff]"
                      placeholder="Filter..."
                    />
                    <span className="right-0 w-[2.25rem] h-[2.25rem] text-sm flex items-center justify-center absolute text-[#000000eb]  opacity-60 top-[2px]" style={{ zIndex: 2 }}>F</span>

                    {filteredMainItems.map((item) => (
                      <button
                        key={item.id}
                        className="text-dark-black-100 flex items-center gap-[0.5rem] px-[0.75rem] py-[0.375rem] h-[32px] text-[13px] font-normal cursor-pointer w-full hover:bg-[#f1f1f2] focus:bg-[#f1f1f2] active:bg-[#e7e7e8]"
                        onClick={() => {
                          item.setMenuOpen(true);
                          setIsMainMenuOpen(false);
                        }}
                      >
                        {item.icon}
                        {item.label}
                      </button>
                    ))}
                  </div>
                </div>
              )}
        
            </div>
          </div>
        </div>
        <div className="flex items-end md:items-center lg:items-center justify-between px-4">
          <div className={`${!hasActiveFilters ? 'py-0' : 'py-2 flex justify-start flex-wrap w-full gap-2'}`} ref={selectedValuesRef}>


            {Object.entries(activeFilters).map(([category, filters]) =>
              category === 'search' ? (
                filters && (
                  <div key="search" className="flex justify-start flex-wrap shrink-0 items-center bg-white py-0 pr-0 md:py-0 lg:py-0 md:pr-0 lg:pr-0 lg:flex-nowrap filter-btn">
                    <span className="border border-[#e5e7eb] border-solid text-[#6b7280] text-[0.75rem] gap-[0.675rem] leading-4 pr-1 pl-[0.625rem] py-1 bg-white rounded-md items-center inline-flex">
                      <span className="capitalize">Search</span>
                      <span className="bg-[#e5e7eb] w-[1px] h-[1rem] mx-1"></span>
                      <span className="text-[#111827] font-medium capitalize cursor-pointer">{filters}</span>
                      <button
                        onClick={() => removeFilter('search', 'search')}
                        className="text-[#6b7280] rounded justify-center items-center w-[1.25rem] h-[1.25rem] flex ml-[-.375rem] cursor-pointer"
                      >
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" aria-hidden="true">
                          <path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path>
                        </svg>
                      </button>
                    </span>
                  </div>
                )
              ) : (
                Array.isArray(filters) && filters.length > 0 ? (
                  filters.map(({ id, value, icon, color }) => {

                    return (
                      <div key={id} className="flex justify-start flex-wrap shrink-0 items-center bg-white py-0 pr-0 md:py-0 lg:py-0 md:pr-0 lg:pr-0 lg:flex-nowrap filter-btn">
                        <span className="border border-[#e5e7eb] border-solid text-[#6b7280] text-[0.75rem] gap-[0.675rem] leading-4 pr-1 pl-[0.625rem] py-1 bg-white rounded-md items-center inline-flex">
                          <span className="text-[#1d2025] w-2 h-2 rounded-full bg-transparent border-2 border-solid border-[#7d7f83] block"></span>
                          <span className="capitalize">{category}</span>
                          <span className="bg-[#e5e7eb] w-[1px] h-[1rem] mx-1"></span>

                          <span className="mr-[-6px]"> {renderIcon(icon)}</span>

                          {color && (
                            <span
                              className="flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full text-[0.625rem] font-medium text-white"
                              style={{ backgroundColor: color }}
                            ></span>
                          )}
                          <span
                            className="text-[#111827] font-medium capitalize cursor-pointer"
                            onClick={() => handleValueClick(category, id)}
                          >
                            {value}
                          </span>
                          <button
                            onClick={() => removeFilter(category, id)}
                            className="text-[#6b7280] rounded justify-center items-center w-[1.25rem] h-[1.25rem] flex ml-[-.375rem] cursor-pointer"
                          >
                            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" aria-hidden="true">
                              <path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path>
                            </svg>
                          </button>
                        </span>
                      </div>
                    );
                  })
                ) : null
              )
            )}





          </div>
          {hasActiveFilters && (
            <button
              className="inline-flex items-center py-1 justify-center rounded-md font-semibold min-w-[5rem] text-[0.8125rem] px-3 hover:bg-light-gray-100"
              onClick={handleClearAll}
            >
              Clear All
            </button>
          )}
        </div>
      </div>
      <div className=''>
        {activeButton === 'one' && <CustomerTable sectionHeight={sectionHeight} activeFilters={activeFilters} itemAssignedTo={assignedItemId} customerType={cusType} />}
      </div>
    </>

  )
}
