import { ThunkAction } from '@reduxjs/toolkit';
import {
    IntegrationActionTypes,
    FETCH_INTEGRATION_TABLE_LIST_REQUEST,
    FETCH_INTEGRATION_TABLE_LIST_SUCCESS,
    FETCH_INTEGRATION_TABLE_LIST_FAILURE,
    FETCH_INTEGRATION_DETAILS_REQUEST,
    FETCH_INTEGRATION_DETAILS_SUCCESS,
    FETCH_INTEGRATION_DETAILS_FAILURE,
    UPDATE_INTEGRATION_DETAILS_REQUEST,
    UPDATE_INTEGRATION_DETAILS_SUCCESS,
    UPDATE_INTEGRATION_DETAILS_FAILURE,
    SELECT_ELEMENT_INTEGRATION_STEP,
    submitSurveyStepIntegrationSuccess,
    submitSurveyStepIntegrationFailure,
    submitSurveyStepIntegrationRequest,
    FETCH_ALL_STEPS_INTEGRATION_DETAILS_REQUEST,
    FETCH_ALL_STEPS_INTEGRATION_DETAILS_SUCCESS,
    FETCH_ALL_STEPS_INTEGRATION_DETAILS_FAILURE,
    CLOSE_FORM_ASIDE,
    DELETE_INTEGRATION_RESPONSES_LIST_REQUEST,
    DELETE_INTEGRATION_RESPONSES_LIST_SUCCESS,
    DELETE_INTEGRATION_RESPONSES_LIST_FAILURE,
    FETCH_INTEGRATION_COUNT_RESPONSE_REQUEST,
    FETCH_INTEGRATION_COUNT_RESPONSE_SUCCESS,
    FETCH_INTEGRATION_COUNT_RESPONSE_FAILURE,
} from './integration.types';
import { AnyAction, Dispatch } from 'redux';
import { AppThunk, RootState } from '../../../store/store';
import { _deleteItemMultiResponses, _fetchAllStepsIntergrationDetails, _fetchIntegrationList, _fetchIntergrationCountResponse, _fetchIntergrationDetailsAPI,
   _fetchIntergrationDetailsAPI1, _fetchIntergrationDetailsAPIPublic, _submitCreateIntegration, _submitStepIntegrationResponse, _submitSurveyStepIntegration, 
   _updateIntegrationDetailsAPI } from './integration.api';
import { v4 as uuidv4 } from 'uuid'; 


interface IntegrationStep {
  id: string;
  base_id: number;
  integration_id: string;
  step_sequence: number;
  step_title: string;
  step_input_type: string;
  step_input_options: string;
  step_mandatory: string;
  step_show_condition: string;
  step_show_logic: string;
  data?: any;
}


export const submitIntegrationGroup = (baseId: number, integrationTypeData: { integration_name: string, integration_type: string }): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch: Dispatch) => {
      dispatch({ type: 'SUBMIT_CREATE_INTEGRATION_REQUEST' });
      try {
        const response = await _submitCreateIntegration(baseId, integrationTypeData);
        dispatch({ type: 'SUBMIT_CREATE_INTEGRATION_SUCCESS', payload: response });
        return response;
      } catch (error) {
        dispatch({ type: 'SUBMIT_CREATE_INTEGRATION_FAILURE', payload: error });
        throw error;
      }
    };
};


  export const fetchIntegrationListData = (baseId: number) => async (dispatch: Dispatch<IntegrationActionTypes>) => {
    dispatch({ type: FETCH_INTEGRATION_TABLE_LIST_REQUEST });
  
    try {
      const response = await _fetchIntegrationList(baseId);
      dispatch({
        type: FETCH_INTEGRATION_TABLE_LIST_SUCCESS,
        payload: response.data
      });
      return response.data;
    } catch (error: any) {
      console.error('API error:', error);
      dispatch({
        type: FETCH_INTEGRATION_TABLE_LIST_FAILURE,
        payload: error.message
      });
      throw error;
    }
  };

  export const fetchIntergrationDetails = (id: number, baseId: number) => async (dispatch: Dispatch<IntegrationActionTypes>) => {
    dispatch({ type: FETCH_INTEGRATION_DETAILS_REQUEST });

    try {
        const response = await _fetchIntergrationDetailsAPI(id, baseId);
        dispatch({
            type: FETCH_INTEGRATION_DETAILS_SUCCESS,
            payload: response.data
        });
        return response.data;
    } catch (error: any) {
        console.error('API error:', error);
        dispatch({
            type: FETCH_INTEGRATION_DETAILS_FAILURE,
            payload: error.message
        });
        throw error;
    }
};

export const UpdateIntegrationDetails = (
    baseId: number,
    id: number,
    options?: {
        integration_name?: string;
        integration_status?: string;
        integration_type?: string;
        integration_label?: string;
        integration_ai_assist?: string;
    }
) => async (dispatch: Dispatch<IntegrationActionTypes>) => {
    dispatch({ type: UPDATE_INTEGRATION_DETAILS_REQUEST });
    try {
        const response = await _updateIntegrationDetailsAPI(id, baseId, options);        
        dispatch({ type: UPDATE_INTEGRATION_DETAILS_SUCCESS, payload: response.data });
        return response;
    } catch (error: any) {
        dispatch({ type: UPDATE_INTEGRATION_DETAILS_FAILURE, payload: error.message });
    }
};

export const selectElementSteps = (id: string, element: any) => ({
  type: SELECT_ELEMENT_INTEGRATION_STEP,
  payload: { id, element },
});

export const closeFormAside = (): IntegrationActionTypes => ({
  type: CLOSE_FORM_ASIDE,
});


export const submitSurveyStepIntegration = (
  baseId: number,
  integrationSteps: IntegrationStep[]
): AppThunk => async (dispatch) => {
  dispatch(submitSurveyStepIntegrationRequest());
  try {
    const response = await _submitSurveyStepIntegration(baseId, integrationSteps);
    dispatch(submitSurveyStepIntegrationSuccess(response));
    return response;
  } catch (error: any) {
    dispatch(submitSurveyStepIntegrationFailure(error.message));
    throw error;
  }
};

export const fetchAllStepsIntergrationDetails = (baseId: number, integrationId: number,  query: string = '') => async (dispatch: Dispatch<IntegrationActionTypes>) => {
  dispatch({ type: FETCH_ALL_STEPS_INTEGRATION_DETAILS_REQUEST });

  try {
    const response = await _fetchAllStepsIntergrationDetails(baseId, integrationId, query);
    dispatch({
      type: FETCH_ALL_STEPS_INTEGRATION_DETAILS_SUCCESS,
      payload: response.data
    });
    return response.data;
  } catch (error: any) {
    console.error('API error:', error);
    dispatch({
      type: FETCH_ALL_STEPS_INTEGRATION_DETAILS_FAILURE,
      payload: error.message
    });
    throw error;
  }
};

export const fetchIntergrationDetails1 = (baseId: number, id: string) => async (dispatch: Dispatch<IntegrationActionTypes>) => {
  dispatch({ type: FETCH_INTEGRATION_DETAILS_REQUEST });

  try {
      const response = await _fetchIntergrationDetailsAPI1(baseId, id);
      dispatch({
          type: FETCH_INTEGRATION_DETAILS_SUCCESS,
          payload: response.data
      });
      return response.data;
  } catch (error: any) {
      console.error('API error:', error);
      dispatch({
          type: FETCH_INTEGRATION_DETAILS_FAILURE,
          payload: error.message
      });
      throw error;
  }
};

export const fetchIntergrationDetailsPublic = ( id: string) => async (dispatch: Dispatch<IntegrationActionTypes>) => {
  dispatch({ type: FETCH_INTEGRATION_DETAILS_REQUEST });

  try {
      const response = await _fetchIntergrationDetailsAPIPublic(id);
      dispatch({
          type: FETCH_INTEGRATION_DETAILS_SUCCESS,
          payload: response.data
      });
      return response.data;
  } catch (error: any) {
      console.error('API error:', error);
      dispatch({
          type: FETCH_INTEGRATION_DETAILS_FAILURE,
          payload: error.message
      });
      throw error;
  }
};

export const submitStepIntegrationResponse = (
  integrationId: string, 
  customerData: { customer_name: string; customer_email: string; customer_phone_no: string },  
  integrationResponse: Array<{ step_id: string; step_type: string; response: string }>,
  feedbackResponse: 'partial' | 'full' = 'partial'
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: 'SUBMIT_CREATE_INTEGRATION_RESPONSE_REQUEST' });

    try {
      const responseId = localStorage.getItem('surveyResponseId');
      if (!responseId) {
        throw new Error('Response ID is not available');
      }

      const requestData = {
        integration_id: integrationId,
        response_id: responseId, 
        customer_data: customerData,
        integration_response: integrationResponse,
        feedback_response: feedbackResponse,
      };

      const response = await _submitStepIntegrationResponse(requestData, integrationId);

      dispatch({ type: 'SUBMIT_CREATE_INTEGRATION_RESPONSE_SUCCESS', payload: response });
      return response;
    } catch (error) {
      dispatch({ type: 'SUBMIT_CREATE_INTEGRATION_RESPONSE_FAILURE', payload: error });
      throw error;
    }
  };
};


export const deleteItemIntegrationResponse = (baseId: number, responseIds: number[]) => async (dispatch: Dispatch<IntegrationActionTypes>) => {
  dispatch({ type: DELETE_INTEGRATION_RESPONSES_LIST_REQUEST });
  try {
    const response = await _deleteItemMultiResponses(baseId, responseIds);
    dispatch({ type: DELETE_INTEGRATION_RESPONSES_LIST_SUCCESS, payload: response.data });
    return response;
  } catch (error: any) {
    dispatch({ type: DELETE_INTEGRATION_RESPONSES_LIST_FAILURE, payload: error.message });
    throw error;
  }
};


export const fetchIntegrationCountResponse = (
  step_id: number,
  baseId: number,
  integration_id: number
) => async (dispatch: Dispatch<IntegrationActionTypes>) => {
  dispatch({ type: FETCH_INTEGRATION_COUNT_RESPONSE_REQUEST });

  try {
    const response = await _fetchIntergrationCountResponse(step_id, baseId, integration_id);
        dispatch({
      type: FETCH_INTEGRATION_COUNT_RESPONSE_SUCCESS,
      payload: response.data,
    });   
    return response.data;
  } catch (error: any) {
    console.error('API error:', error);
    dispatch({
      type: FETCH_INTEGRATION_COUNT_RESPONSE_FAILURE,
      payload: error.message,
    });
    throw error;
  }
};
