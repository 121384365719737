// insight.tsx
// @ts-nocheck
import React, { Fragment, HTMLProps, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  ColumnDef,
  PaginationState,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
} from '@tanstack/react-table'
import { MenuDropdown } from '../../../components/MenuDropdown'
import { format } from 'date-fns';
import { _baseRequest } from '../../../services/api';
import { Dialog, Label, Listbox, Transition, DialogTitle, DialogPanel } from '@headlessui/react';
import { Button } from "@lemonsqueezy/wedges";
import { useDispatch } from 'react-redux';
import { UpdateTicketInsightData, deleteItemInsight, deleteItemInsightGroup } from '../insight-redux/insight.actions';
import { MagnifyingGlassIcon, TagIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Toaster, toast } from 'sonner';
import { XMarkIcon } from '@heroicons/react/24/outline'
import { _item_TopicSearchByParam } from '../../setting/settingPages/baseAPI';
import { debounce } from 'lodash';
import ViewFeedback from '../../feedbacks/ViewFeedback';
import ViewFeedbackQuickMode from '../../qucikMode/ViewFeedbackQuickMode';
import { COMMON } from '../../../utils/common';
import { DropdownGroup, InsightTableProps, PaginationState1, Person } from '../type';
import { getSentimentColor } from '../../../utils/colorUtils';



function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!)

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return (
    <input
      type="checkbox"
      ref={ref}
      className="h-4 w-4 rounded border-gray-300 text-indigo-600 ring-0 outline-none focus:outline-none focus:shadow-none focus:ring-transparent"
      {...rest}
    />
  )
}

const InsightTable: React.FC<InsightTableProps> = ({ sectionHeight, activeFilters, itemAssignedTo }) => {


  const [data, setData] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<PaginationState1>({
    pageIndex: 0,
    pageSize: 25,
    currentPage: 1,
    hasNextPage: false,
    totalPages: 1,
    totalItems: 0,
  })
  const [rowSelection, setRowSelection] = React.useState({})
  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;
  const isInitialMount = useRef(true);
  const [initialLoading, setInitialLoading] = useState(true);
  const [isModalOpenCheckbox, setIsModalOpenCheckbox] = useState(false);
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const [deleteOk, setDeleteOk] = useState(false)
  const [rowSelectionDelete, setRowSelectionDelete] = useState<number[]>([]);
  const [isAssignTicketOpen, setIsAssignTicketOpen] = useState(false);
  const [quickViewMode, setQuickViewMode] = useState(false)
  const dispatch = useDispatch();
  const [deleteLoading, setDeleteLoading] = useState(false);

  const _get_ItemInsightsList = async (pageIndex:any, pageSize:number, filters:any, itemAssignedTo:any) => {
    const url = `${COMMON.apiBaseUrl()}/v1/item-insight/${baseId}/Insights`;


    let params = new URLSearchParams();

    if (typeof params.append !== 'function') {
      params = {
        append: (key, value) => {
          if (!this[key]) {
            this[key] = [];
          }
          this[key].push(value);
        },
        toString: () => {
          return Object.entries(this)
            .filter(([key]) => key !== 'append' && key !== 'toString')
            .flatMap(([key, values]) => values.map(value => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`))
            .join('&');
        }
      };
    }

    params.append('page', pageIndex.toString());
    params.append('pageSize', pageSize.toString());

    if (filters) {
      Object.entries(filters).forEach(([filterType, filterValues]) => {
        if (Array.isArray(filterValues) && filterValues?.length > 0) {
          const keyPrefix = getKeyPrefix(filterType);

          filterValues.forEach((filterObj) => {
            let value;
            if (filterType === 'Boards' || filterType === 'status' || filterType === 'assign') {
              value = filterObj.id;
            } else if (filterType === 'label' || filterType === 'priority' || filterType === 'sentiment') {
              value = filterObj.value;
            } else if (filterType === 'Created at') {
              const [startDate, endDate] = filterObj.value.split(' - ').map((dateStr: any) => {
                const date = parseDate(dateStr);
                date.setHours(12, 0, 0, 0);
                return date;
              });
              params.append('fromDate', formatDate(startDate));
              params.append('toDate', formatDate(endDate));
              return;
            }
            // else if (filterType === 'Updated at') {
            //   const [startDate, endDate] = filterObj.value.split(' - ').map(dateStr => {
            //     const date = parseDate(dateStr);
            //     date.setHours(12, 0, 0, 0);
            //     return date;
            //   });
            //   params.append('updated_from', formatDate(startDate));
            //   params.append('updated_till', formatDate(endDate));
            //   return;
            // }

            if (value !== undefined) {
              params.append(keyPrefix, value.toString());
            }
          });
        }
      });
    }
    const loginUserId = localStorage.getItem("userAuthId")
    if (itemAssignedTo) {
      params.append('item_assigned_to', loginUserId);
    }

    if (filters.search) {
      params.append('search', filters.search);
    }

    try {
      const queryString = params.toString();
      const fullUrl = `${url}?${queryString}`;
      return await _baseRequest(fullUrl, 'GET');
    } catch (error:any) {
      throw new Error(`Error fetching item feedback list: ${error.message}`);
    }
  };

  const getKeyPrefix = (filterType:any) => {
    switch (filterType) {
      case 'Boards':
        return 'board_id';
      case 'label':
        return 'item_label';
      case 'status':
        return 'item_base_status_id';
      case 'assign':
        return 'item_assigned_to';
      case 'priority':
        return 'item_priority';
      case 'sentiment':
        return 'itemContextSentiment';
      case 'Created at':
        return 'fromDate';
      case 'Updated at':
        return 'updated_from';
      default:
        return filterType.toLowerCase();
    }
  };

  const parseDate = (dateString:any) => {
    const [day, month, year] = dateString.split(' ');
    const monthIndex = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].indexOf(month);
    return new Date(year, monthIndex, parseInt(day));
  };

  const formatDate = (date:any) => {
    return date.toISOString().split('T')[0];
  };


  const fetchItemFeedbackListData = useCallback(debounce(async (pageIndex, pageSize, filters, isInitialFetch = false, itemAssignedTo) => {
    setLoading(true);

    try {
      const response = await _get_ItemInsightsList(pageIndex, pageSize, filters, itemAssignedTo);

      if (response.status === 200) {
        const newData = response.data.item_insight;
        const newPagination = {
          currentPage: response.data.currentPage,
          hasNextPage: response.data.hasNextPage,
          totalPages: response.data.totalPages,
          pageSize: response.data.pageSize,
          pageIndex: response.data.currentPage - 1,
          total: response.data.totalInsight,
        };

        setData(newData);
        setPagination(newPagination);
        setTotalPages(response.data.totalPages);

        localStorage.setItem('cachedInsightData', JSON.stringify({
          data: newData,
          pagination: newPagination,
          timestamp: Date.now(),
        }));

        if (!isInitialFetch) {
          console.log('Data updated in background');
        }
      }
    } catch (error) {
      console.error('Error fetching item feedback list:', error);
    } finally {
      setLoading(false);
      if (isInitialFetch) {
        setInitialLoading(false);
      }
    }
    // }, [baseId]);
  }, 500), []); // Adjust the debounce delay as needed


  useEffect(() => {
    const handleSidebarItemClick = () => {
      setPagination({
        pageIndex: 0,
        pageSize: 25,
        currentPage: 1,
        hasNextPage: false,
        totalPages: 1,
        total: 0,
      });
      setTotalPages(0);
      fetchItemFeedbackListData(1, 25, true);
    };

    window.addEventListener('paginationresetsetate', handleSidebarItemClick);

    return () => {
      window.removeEventListener('paginationresetsetate', handleSidebarItemClick);
    };
  }, [fetchItemFeedbackListData]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      const cachedData = localStorage.getItem('cachedInsightData');

      if (cachedData) {
        setLoading(true);
        const { data: cachedItems, pagination: cachedPagination } = JSON.parse(cachedData);
        setData(cachedItems);
        setPagination(prevPagination => ({
          ...prevPagination,
          ...cachedPagination,
        }));
        setTotalPages(cachedPagination.totalPages);
        setLoading(false);
      } else {


        fetchItemFeedbackListData(pagination.currentPage, pagination.pageSize, activeFilters, true, itemAssignedTo);
      }
    } else {
      fetchItemFeedbackListData(pagination.currentPage, pagination.pageSize, activeFilters, true, itemAssignedTo);
    }
  }, [fetchItemFeedbackListData, pagination.currentPage, pagination.pageSize, activeFilters, itemAssignedTo]);


  const handlePrevPage = () => {
    setLoading(false)
    if (pagination.currentPage > 1) {
      setPagination(prev => ({ ...prev, currentPage: prev.currentPage - 1 }));
    }
  };

  const handleNextPage = () => {
    setLoading(false)
    if (pagination.hasNextPage) {
      setPagination(prev => ({ ...prev, currentPage: prev.currentPage + 1 }));
    }
  };


  useEffect(() => {
    const handleSidebarItemClick = () => {
      setData([]);
      setPagination({
        pageIndex: 0,
        pageSize: 25,
        currentPage: 1,
        hasNextPage: false,
        totalPages: 1,
        total: 0,
      });
      setTotalPages(0);
      fetchItemFeedbackListData(1, 25, true);
    };

    window.addEventListener('sidebarItemClicked', handleSidebarItemClick);

    return () => {
      window.removeEventListener('sidebarItemClicked', handleSidebarItemClick);
    };
  }, [fetchItemFeedbackListData]);

  const rerender = React.useReducer(() => ({}), {})[1]


  // const handleSelect = (row: Row<Person>) => {
  //   setSelectedRows((prevSelectedRows) => {
  //     const newRowState = { ...prevSelectedRows };
  //     newRowState[row.id] = !prevSelectedRows[row.id];
  //     return newRowState;
  //   });
  // };

  const handleSelect = (row: Row<Person>) => {
    setSelectedRows((prevSelectedRows) => {
      const newRowState = { ...prevSelectedRows }
      newRowState[row.id] = !prevSelectedRows[row.id]
      return newRowState
    })
  }

  useEffect(() => {
    setSelectedRowsCount(Object.keys(rowSelection).length);
  }, [rowSelection]);


  const handleSelectAllClick = useCallback((checked: any) => {
    const newRowSelection = {};
    const newRowSelectionDelete = [];

    data.forEach((row, index) => {
      newRowSelection[index] = checked;
      if (checked) {
        newRowSelectionDelete.push(row.id);
      }
    });

    setRowSelection(newRowSelection);
    setRowSelectionDelete(newRowSelectionDelete);
    setIsModalOpenCheckbox(newRowSelectionDelete.length > 0);
  }, [data]);


  const handleCheckboxClick = (row:any, id:any) => {
    setSelectedRows((prevSelectedRows) => ({
      ...prevSelectedRows,
      [row.id]: !prevSelectedRows[row.id]
    }));
    setRowSelectionDelete((prevSelection) => {

      if (prevSelection?.includes(id)) {
        return prevSelection.filter((itemId) => itemId !== id);
      } else {
        return [...prevSelection, id];
      }
    });

    setIsModalOpenCheckbox(true);
  };




  const columns = useMemo<ColumnDef<Person>[]>(() => [
    {
      id: 'select',
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: (e) => {
              table.toggleAllRowsSelected(e.target.checked);
              handleSelectAllClick(e.target.checked);
            },
          }}
        />
      ),
      cell: ({ row }) => (
        <div className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              // onChange: row.getToggleSelectedHandler(),
              onChange: (e) => {
                row.getToggleSelectedHandler()(e);
                handleCheckboxClick(row, row.original.id);
              },
            }}
          />
        </div>
      ),
      // key: (row) => row.original.id, // Ensure unique key for each row

    },
    {
      accessor: 'id',
      header: 'ID',
      // cell: info => info.row.original.name,
      cell: info => (

        <span className="flex items-center  gap-[1rem] text-[13px] text-[#52555a]">
          {info.row.original.insight_ref_id}
        </span>
      ),
      sort: (a, b) => a.insight_ref_id.localeCompare(b.insight_ref_id),
    },
    {
      accessor: 'insight',
      header: 'Insight',
      cell: info => {
        return (
          <div className='w-full'>
            <div className=' relative'>
              <div data-title={info.row.original.itemInsight} className=' table-tooltip hover:overflow-visible tabletooltip-title'>
                <div className=' text-[#1b1b1b] text-[13px] font-medium line-clamp-2 '>
                  {info.row.original.itemInsight}
                </div>
              </div>

            </div>

          </div>
        );
      },
      sort: (a, b) => a.itemInsight.localeCompare(b.itemInsight),
    },
    {
      accessor: 'context',
      header: 'Context',
      cell: info => {
        return (
          <div className='w-full'>
            <div className=' relative'>
              <div data-title={info.row.original.itemContext} className=' table-tooltip hover:overflow-visible tabletooltip-title italic'>
                <div className=' text-[#1b1b1b] text-[13px] font-[400] line-clamp-2 '>
                  {info.row.original.itemContext}
                </div>
              </div>
            </div>
          </div>
        );
      },
      sort: (a: { context: string }, b: { context: any }) => a.context.localeCompare(b.context),
    },
    {
      accessor: 'itemContextSentiment',
      header: 'Sentiment',
      cell: info => {

        const sentiment = info.row.original.itemContextSentiment?.trim(); // Trim whitespace
        const bgColor = getSentimentColor(sentiment);

        return (
          <div  className='w-full'>
            {sentiment === 'positive' ? (
              <span
                className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 h-[24px] text-xs font-medium text-green-700 capitalize"
                style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
              >
                {sentiment}
              </span>
            ) : sentiment === 'negative' ? (
              <span
                className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 h-[24px] text-xs font-medium text-red-700 capitalize "
                style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
              >
                {sentiment}
              </span>
            ) : sentiment === 'spam' ? (
              <span
                className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 h-[24px] text-xs font-medium text-gray-600 capitalize"
                style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
              >
                {sentiment}
              </span>
            ) : sentiment === 'mixed' ? (
              <span
                className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 h-[24px] text-xs font-medium text-blue-700 capitalize"
                style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
              >
                {sentiment}
              </span>
            ) : null}
          </div>
        );
      },
      sort: (a, b) => a.itemContextSentiment.localeCompare(b.itemContextSentiment)
    },
    {
      accessor: 'feedback',
      header: 'Feedback',
      cell: info => {
        return (
          <div className='w-full cursor-pointer' onClick={() => handleQuickMode(info.row.original.item_id, 'feedback')}>
            <div data-title={info.row.original.item_ref_id} className="table-tooltip text-[#52555a] font-normal capitalize truncate text-[13px] leading-4 whitespace-nowrap max-w-[80px] w-full overflow-hidden text-ellipsis text-start">
              {info.row.original.item_ref_id}
            </div>
          </div>
        );
      },
      sort: (a: { feedback: string }, b: { feedback: string }) => new Date(a.feedback).getTime() - new Date(b.feedback).getTime(),
    },
    {
      accessor: 'ticket',
      header: 'Ticket',
      cell: info => {
        return (
          <div className='w-full cursor-pointer' onClick={() => handleQuickMode(info.row.original.insight_ticket_id, 'ticket')}>
            <div data-title={info.row.original.insight_ticket_ref_id} className="table-tooltip text-[#52555a] font-normal capitalize truncate text-[13px] leading-4 whitespace-nowrap max-w-[80px] w-full overflow-hidden text-ellipsis text-start">
              {info.row.original.insight_ticket_ref_id}
            </div>
          </div>
        );
      },
      sort: (a: { topic: string }, b: { topic: string }) => new Date(a.topic).getTime() - new Date(b.topic).getTime(),
    },
    {
      accessor: 'date',
      header: 'Date',
      cell: info => {
        const formattedDate = format(new Date(info.row.original.created_at), 'MMM d');
        const formattedDatetooltip = format(new Date(info.row.original.created_at), 'MMM d');
        return (
          <div className='w-full'>
            <div data-title={formattedDatetooltip} className="table-tooltip text-[#52555a] font-normal capitalize truncate text-[13px] leading-4 whitespace-nowrap max-w-[80px] w-full overflow-hidden text-ellipsis text-start">
              {formattedDate}
            </div>
          </div>
        );
      },
      sort: (a: { date: string }, b: { date: string }) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    },
  ], [handleSelectAllClick]);

  // delete row
  const handleConfirmDelete = (id) => {
    setDeleteOk(true)
  }

  const handleDeleteConfirm = async () => {
    setDeleteLoading(true);
    try {
      let groupIdsToDelete = Array.isArray(rowSelectionDelete) ? rowSelectionDelete : [rowSelectionDelete];

      const deleteResponse = await dispatch(deleteItemInsight(baseId, groupIdsToDelete));

      if (!deleteResponse || !deleteResponse.data) {
        throw new Error('Invalid delete response received');
      }

      // Process the delete response
      const successfulDeletes = deleteResponse.data.filter((item:any) => item.isSuccess).map((item) => item.id);
      const failedDeletes = deleteResponse.data.filter((item:any) => !item.isSuccess);

      if (successfulDeletes.length > 0) {
        toast.success(`Successfully deleted item(s)`);
      }
      // successfulDeletes.forEach((id) => {
      //   toast.success(`Successfully deleted item ${id}`);
      // });
      failedDeletes.forEach((item:any) => {
        toast.error(`Failed to delete item ${item.id}: ${item.reason}`);
      });

      if (successfulDeletes.length > 0) {
        const updatedResponse = await _get_ItemInsightsList(
          pagination.pageIndex + 1,
          pagination.pageSize,
          activeFilters,
          false,
          itemAssignedTo
        );

        if (updatedResponse.status === 200) {
          const newData = updatedResponse.data.item_insight;
          const newPagination = {
            currentPage: updatedResponse.data.currentPage,
            hasNextPage: updatedResponse.data.hasNextPage,
            totalPages: updatedResponse.data.totalPages,
            pageSize: updatedResponse.data.pageSize,
            pageIndex: updatedResponse.data.currentPage - 1,
            total: updatedResponse.data.totalInsight,
          };

          setData(newData);
          setPagination(newPagination);
          setTotalPages(updatedResponse.data.totalPages);

          // Update local storage with new data
          localStorage.setItem('cachedInsightData', JSON.stringify({
            data: newData,
            pagination: newPagination,
            timestamp: Date.now(),
          }));

          // Reset states
          setRowSelectionDelete([]);
          setDeleteOk(false);
          setIsModalOpenCheckbox(false);
          setSelectedRows({});
          setRowSelection({});
          setSelectedRowsCount(0);
        } else {
          console.error('Error fetching updated item feedback list:', updatedResponse);
          toast.error('Failed to fetch updated item feedback list');
        }
      }
    } catch (error:any) {

      if (error.response && error.response.data && error.response.data.message) {
        toast.error(`An error occurred: ${error.response.data.message}`);
      } else {
        toast.error('An error occurred while deleting insight groups. Please try again later.');
      }
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleDeleteSingleRow = async (rowId?: string) => {
    
    setDeleteLoading(true);
    try {
      let idsToDelete: string[];
      
      if (rowId) {
        // Single row deletion
        idsToDelete = [rowId];
      } 
  
      const deleteResponse = await dispatch(deleteItemInsight(baseId, idsToDelete));

      if (!deleteResponse || !deleteResponse.data) {
        throw new Error('Invalid delete response received');
      }

      // Process the delete response
      const successfulDeletes = deleteResponse.data.filter((item:any) => item.isSuccess).map((item) => item.id);
      const failedDeletes = deleteResponse.data.filter((item:any) => !item.isSuccess);

      if (successfulDeletes.length > 0) {
        toast.success(`Successfully deleted item(s)`);
      }
      // successfulDeletes.forEach((id) => {
      //   toast.success(`Successfully deleted item ${id}`);
      // });
      failedDeletes.forEach((item:any) => {
        toast.error(`Failed to delete item ${item.id}: ${item.reason}`);
      });

      if (successfulDeletes.length > 0) {
        const updatedResponse = await _get_ItemInsightsList(
          pagination.pageIndex + 1,
          pagination.pageSize,
          activeFilters,
          false,
          itemAssignedTo
        );

        if (updatedResponse.status === 200) {
          const newData = updatedResponse.data.item_insight;
          const newPagination = {
            currentPage: updatedResponse.data.currentPage,
            hasNextPage: updatedResponse.data.hasNextPage,
            totalPages: updatedResponse.data.totalPages,
            pageSize: updatedResponse.data.pageSize,
            pageIndex: updatedResponse.data.currentPage - 1,
            total: updatedResponse.data.totalInsight,
          };

          setData(newData);
          setPagination(newPagination);
          setTotalPages(updatedResponse.data.totalPages);

          // Update local storage with new data
          localStorage.setItem('cachedInsightData', JSON.stringify({
            data: newData,
            pagination: newPagination,
            timestamp: Date.now(),
          }));

          // Reset states
          setRowSelectionDelete([]);
          setDeleteOk(false);
          setIsModalOpenCheckbox(false);
          setSelectedRows({});
          setRowSelection({});
          setSelectedRowsCount(0);
        } else {
          console.error('Error fetching updated item feedback list:', updatedResponse);
          toast.error('Failed to fetch updated item feedback list');
        }
      }
    } catch (error:any) {

      if (error.response && error.response.data && error.response.data.message) {
        toast.error(`An error occurred: ${error.response.data.message}`);
      } else {
        toast.error('An error occurred while deleting insight groups. Please try again later.');
      }
    } finally {
      setDeleteLoading(false);
    }
  };




  // assign ticket 
  const handleAssignTicket = () => {
    setIsAssignTicketOpen(true)
    setSearchTerm('');
    setLastSearchedTerm('');
    setSearchResults([]);
    setAttachedTopics({});
    setLocalLoading(true);
    setIsSearching(false);

  }
  const searchContainerRef = useRef(null);
  const searchInputRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [lastSearchedTerm, setLastSearchedTerm] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchResults1, setSearchResults1] = useState([]);
  const [attachedTopics, setAttachedTopics] = useState({});
  const [localLoading, setLocalLoading] = useState(true);

  const handleTopicSearch = (e:any) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyPress = (e:any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      performSearch();
    }
  };

  const [searchLoading1, setSearchLoading1] = useState(false)

  const handleSearchIconClick = async () => {
    setSearchLoading1(true); 

    try {
      await performSearch();
    } catch (error) {
      console.error('Error performing search:', error);
      toast.error('Failed to perform search');
    } finally {
      setSearchLoading1(false);
    }
  };
  const [selectedInsights, setSelectedInsights] = useState<number[]>([]);


  const handleAttachTopic = (itemId: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAttachedTopics(prev => ({ ...prev, [itemId]: !prev[itemId] }));
    setLocalLoading(false);
  };

  useEffect(() => {
    setSelectedInsights(rowSelectionDelete);
  }, [rowSelectionDelete]);

  const performSearch = useCallback(async () => {
    if (searchTerm.length >= 3 && searchTerm !== lastSearchedTerm) {
      setIsSearching(true);

      try {
        const payload = { search: searchTerm };
        const results = await _item_TopicSearchByParam(Number(baseId), payload);

        setSearchResults(results.data.items);
        setSearchResults1(results.data);
        setLastSearchedTerm(searchTerm);

      } catch (error:any) {
        if (error.response && error.response.status === 422) {
          const errorMessage = error.response.data.data.errors.search || 'Validation error occurred';
          toast.error(errorMessage);
        } else {
          toast.error('Failed to search topics');
        }
      } finally {
        setIsSearching(false);
        setSearchLoading1(false)
      }
    } else if (searchTerm.length < 3) {
      setSearchResults([]);
      setLastSearchedTerm('');
      setSearchLoading1(false);

    }
  }, [searchTerm, baseId, lastSearchedTerm]);


  useEffect(() => {
    let timeoutId;
    const handleClickOutside = (event:any) => {
      if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
          performSearch();
        }, 300);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [performSearch]);


  // replace local stroage data 
  const updateLocalStorage = (apiResponse: any) => {
    const localStorageKey = 'cachedInsightData';
    const existingDataString = localStorage.getItem(localStorageKey);

    if (!existingDataString) {
      console.error('No existing data found in local storage');
      return;
    }

    const existingData: LocalStorageData = JSON.parse(existingDataString);

    const updatedData = existingData.data.map((item: InsightData) => {
      const updatedItem = apiResponse.data.find((responseItem: any) => responseItem.insight.id === item.id);
      if (updatedItem) {
        return { ...item, ...updatedItem.insight };
      }
      return item;
    });

    const updatedLocalStorageData: LocalStorageData = {
      ...existingData,
      data: updatedData,
      timestamp: Date.now()
    };

    localStorage.setItem(localStorageKey, JSON.stringify(updatedLocalStorageData));
    setData(updatedData);
  };
  const [insightUpdateLoading, setInsightUpdateLoading] = useState(false);

  const handleInsightUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    setInsightUpdateLoading(true); 

    const selectedTopicId = Object.keys(attachedTopics).find(key => attachedTopics[key]);

    // if (!selectedTopicId || selectedInsights.length === 0) {
    //   toast.error('Please select at least one insight and a ticket');
    //   return;
    // }

    const insightData = {
      insightIds: selectedInsights,
      ticketID: Number(selectedTopicId)
    };

    try {
      const response = await dispatch(UpdateTicketInsightData(Number(baseId), insightData));
      updateLocalStorage(response);

      setAttachedTopics({});
      setSelectedInsights([]); 
      setSelectedRows({});
      setRowSelection({});
      setRowSelectionDelete([]);
      setSelectedRowsCount(0);
      toast.success('Successfully updated');
      setIsAssignTicketOpen(false);
    } catch (error) {
      console.error('Error updating insight:', error);
      toast.error('Failed to update insight');
    } finally {
      setInsightUpdateLoading(false); 
    }
  };

  const table = useReactTable({
    data,
    columns,
    state: {
      pagination,
      rowSelection,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
    onPaginationChange: setPagination,


  });


  useEffect(() => {
    const applyStyles = () => {
      const tableElement = document.querySelector('.feedback-col') as HTMLElement | null;
      const tableElement1 = document.querySelector('.feedback-thead') as HTMLElement | null;
      const isTabScreen = window.matchMedia('(max-width: 1499px)').matches;
      const isSmallScreen = window.matchMedia('(max-width: 767px)').matches;
      const hasSearchFilter = !!activeFilters.search;

     
      if (tableElement) {
        if (isSmallScreen) {
          tableElement.style.marginTop = sectionHeight ? `calc(50px + ${sectionHeight}px)` : '50px';
        } else if (isTabScreen) {
          tableElement.style.marginTop = sectionHeight ? `calc(45px + ${sectionHeight}px)` : '46px';
        } else {
          tableElement.style.marginTop = sectionHeight ? `calc(87px + ${sectionHeight}px)` : '87px';
        }
      }

      // Apply top style to tableElement1 based on screen size and search filter
      if (tableElement1) {
        if (isSmallScreen) {
          tableElement1.style.top = sectionHeight ? `calc(80px + ${sectionHeight}px)` : '80px';
        } else if (isTabScreen) {
          tableElement1.style.top = sectionHeight ? `calc(101px + ${sectionHeight}px)` : '101px';
        } else {
          tableElement1.style.top = sectionHeight ? `calc(101px + ${sectionHeight}px)` : '101px';
        }

        if (sectionHeight) {
          tableElement1.style.borderTop = '1px solid #e5e7eb';
        } else {
          tableElement1.style.borderTop = 'none';
        }
      }
    };

    applyStyles();

    window.addEventListener('resize', applyStyles);
    return () => {
      window.removeEventListener('resize', applyStyles);
    };
  }, [sectionHeight]);

  const [selectedItemId, setSelectedItemId] = useState(null)
  const [selectedItemType, setSelectedItemType] = useState(null);

  const handleQuickMode = (itemId:any, itemType:any) => {
    setQuickViewMode(true)
    setSelectedItemId(itemId)
    setSelectedItemType(itemType);

  }

  return (
    <>
      <div className='mx-auto max-w-full'>
        <div className="px-4 sm:px-6 lg:px-8 feedback-table">
          <div className="flow-root h-full">
            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8 h-full">
              <div className="inline-block min-w-full py-2 align-middle h-full max-w-full">
                <div className="relative  h-full block pb-[5rem]">
                  <table className="block border-collapse overflow-x-auto w-full feedback-col overflow-y-auto feedback-table-height h-full">
                    <thead
                      className='mq1499:sticky mq3000:fixed bg-white grid feedback-thead feedback-table-row'
                    // className='sticky md:fixed lg:fixed bg-white grid mq767:top-[0px] mq1023:top-[116px] mq3000:top-[101px] feedback-thead feedback-table-row'
                    >
                      {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id} className='bg-white flex relative w-full'>
                          {headerGroup.headers.map(header => {

                            return (
                              <th key={header.id} colSpan={header.colSpan} scope="col"
                                className={`sticky bg-white flex flex-1 py-[0.75rem] px-[1rem] cursor-pointer text-[0.75rem] items-center justify-start
                                 leading-[1rem] text-[#52555a] font-[500] border-b  border-solid border-light-gray-200 min-w-[50px] md:min-w-[160px] lg:min-w-[50px]
                                   ${header.id === 'select' ? 'max-w-[50px] [&>div]:px-0' : ''} 
                                   ${header.id === 'ID' ? 'max-w-[60px]' : ''}
                                   ${header.id === 'Insight' ? 'max-w-[-webkit-fill-available] !min-w-[500px]' : ''}
                                   ${header.id === 'Context' ? 'max-w-[-webkit-fill-available] !min-w-[400px]' : ''}
                                   ${header.id === 'Sentiment' ? '!min-w-[106px]' : ''}
                                   ${header.id === 'Feedback' ? '!min-w-[106px]' : ''}
                                   ${header.id === 'Ticket' ? '!min-w-[106px]' : ''}
                                   ${header.id === 'Date' ? '!min-w-[106px]' : ''}
                                     `}
                              >
                                <div
                                  {...{
                                    className: header.column.getCanSort()
                                      ? 'cursor-pointer select-none '
                                      : '',
                                    onClick: header.column.getToggleSortingHandler(),
                                  }}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                  {{
                                    asc: ' 🔼',
                                    desc: ' 🔽',
                                  }[header.column.getIsSorted() as string] ?? null}
                                </div>
                              </th>
                            )
                          })}
                          <th
                            className={`sticky bg-white flex flex-1 py-[0.75rem] px-[1rem] cursor-pointer text-[0.75rem] items-center justify-start
                            leading-[1rem] text-[#52555a] font-[500] border-r border-b  border-solid border-light-gray-200 min-w-10 max-w-[56px]`}
                          ></th>
                        </tr>
                      ))}
                    </thead>
                    <div>

                      {loading ? (
                        <div className="flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 191px)' }}>
                          <div className="simple-spinner-loader">
                            <span></span>
                          </div>
                        </div>
                      ) : pagination.totalPages === 0 ? (
                        <div className="flex items-center justify-center z-50" style={{ height: 'calc(100vh - 191px)' }}>
                          <p>No data found</p>
                        </div>
                      ) : (
                        <tbody className="grid">
                          {table.getRowModel().rows.map((row) => {
                            const allRowsSelected = table.getIsAllRowsSelected();
                            const dropdownGroups: DropdownGroup[] = [
                              {
                                items: [
                                  {
                                    label: `Delete`,
                                    className: "py-[0.375rem] px-[0.75rem] bg-white text-[#000000eb] text-[13px] hover:bg-[#e7e7e8]",
                                    onClick: () => handleDeleteSingleRow(row.original.id),
                                  },
                                ],
                              },
                            ];
                            return (
                              <tr
                                key={row.id}
                                data-selected={allRowsSelected ? true : selectedRows[row.id]}
                                onClick={() => handleSelect(row)}
                                className={`border-b border-solid border-light-gray-200 bg-white flex w-full relative ${selectedRows[row.id] ? 'bg-gray-100' : ''}`}
                              >
                                {row.getVisibleCells().map((cell) => (
                                  <td
                                    key={cell.id}
                                    className={`flex items-center justify-start text-start px-[1rem] py-[0.9rem] leading-[1rem]
                                    flex-1 text-dark-black-200 max-w-[350px]
                                    ${/(_select)$/.test(cell.id) ? 'max-w-[50px] [&>div]:px-0' : ''} 
                                    ${/(_ID)$/.test(cell.id) ? 'max-w-[60px]' : ''}
                                    ${/(_Insight)$/.test(cell.id) ? '!max-w-[-webkit-fill-available] min-w-[500px]' : ''}
                                    ${/(_Context)$/.test(cell.id) ? '!max-w-[-webkit-fill-available] min-w-[400px]' : ''}
                                    ${/(_Sentiment)$/.test(cell.id) ? '!min-w-[106px]' : ''}
                                    ${/(_Feedback)$/.test(cell.id) ? '!min-w-[106px]' : ''}
                                    ${/(_Ticket)$/.test(cell.id) ? '!min-w-[106px]' : ''}
                                    ${/(_Date)$/.test(cell.id) ? '!min-w-[106px]' : ''}

                                      
                                    
                                    `}
                                  >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                  </td>
                                ))}
                                <td
                                  className={`flex items-center text-start px-[0.75rem] py-[1rem] leading-[1rem] flex-1 max-w-[50px] text-dark-black-200 justify-center [&>button]:w-auto [&>button]:bg-transparent [&>button]:hover:bg-transparent`}
                                >
                                  <MenuDropdown
                                    groups={dropdownGroups}
                                    image={
                                      <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        aria-hidden="true"
                                        focusable="false"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <circle cx="12" cy="12" r="1"></circle>
                                        <circle cx="12" cy="5" r="1"></circle>
                                        <circle cx="12" cy="19" r="1"></circle>
                                      </svg>
                                    }
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                    </div>

                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="fixed z-10 bg-white border-t border-solid border-light-gray-200 py-[0.5rem] px-[1rem] bottom-0 pagination-bottom">
        <div className="relative flex items-center justify-between">



          <div className="flex items-center gap-2">
            <span className="flex items-center gap-1">
              <div className='text-[0.875rem] font-[500] text-[#000000eb]'>Page
                {/* 
                               <span className='mr-1'> {table.getState().pagination.pageIndex + 1} of{' '}
                      {table.getPageCount().toLocaleString()}</span> */}
              </div>
            </span>
            <span className="flex items-center gap-1">

              <input
                type="number"
                value={pagination.currentPage}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) : 1;
                  setPagination((prevPagination) => ({
                    ...prevPagination,
                    currentPage: page,
                  }));
                }}
                className="w-[4rem] h-[2rem] text-[0.8125rem] px-[0.75rem] rounded-md border border-solid border-light-gray-100"
              />

              <span className='mr-1 text-[#000000eb] text-[0.8125rem]'>  of{' '}

                {totalPages}
              </span>
            </span>


            <div className='flex items-center gap-1'>

              <select
                value={table.getState().pagination.pageSize}
                onChange={e => {
                  table.setPageSize(Number(e.target.value))
                }}
                className="w-[6rem] h-[2rem] text-[0.8125rem] px-[0.75rem] rounded-md border border-solid border-light-gray-100 block"
              >
                {[25, 50, 100].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
              <div className='mr-1 text-[#000000eb] text-[0.8125rem] ml-[14px]'>
                {pagination.total} Items
              </div>
            </div>


          </div>
          <div className='flex gap-2'>
            <button
              className="border rounded py-2 px-2"
              onClick={handlePrevPage}
              // disabled={!table.getCanPreviousPage()}
              disabled={pagination.currentPage === 1}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-2 w-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
              </svg>

            </button>
            <button
              className="border rounded py-2 px-2"
              onClick={handleNextPage}
              // disabled={!table.getCanNextPage()}
              disabled={!pagination.hasNextPage}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-2 w-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
              </svg>

            </button>
          </div>
        </div>
      </div>


      <div open={isModalOpenCheckbox} onClose={() => { }} className={`relative z-10 w-full ${isModalOpenCheckbox ? 'common-slider-one' : 'common-slider-two'}`}
      >
        {selectedRowsCount > 0 && (
          <div className="fixed inset-y-0 right-0 flex checkbox-modal h-[100%] w-[800px] max-w-[800px] bottom-0 top-[-70px]">
            <div className="flex h-auto flex-col overflow-y-scroll bg-white py-6 rounded-lg" style={{ boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1),0 2px 4px -1px rgba(0, 0, 0, 0.06)' }}>
              <div className="px-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <span className='text-dark-black-100 text-[13px] font-[700]'>{selectedRowsCount} selected</span>
                  <div className="flex items-center">
                    <div className="w-full flex items-center justify-between">
                      <div className="flex items-center gap-[0.5rem]">
                        <Button
                          onClick={handleAssignTicket}
                          className='text-[13px] text-dark-black-100 font-[600] [&>span]:font-[600] h-[32px] rounded-md flex-row-reverse border-[#e7e7e8] shadow-none'
                          after={<TagIcon className='w-[13px] h-[13px] text-dark-black-100 font-[600] opacity-80' strokeWidth={3} />} variant="outline">
                          Assign Ticket
                        </Button>
                        <Button
                          onClick={(id) => handleConfirmDelete(id)}
                          className='text-[13px] text-dark-black-100 font-[600] [&>span]:font-[600] h-[32px] rounded-md flex-row-reverse border-[#e7e7e8] shadow-none'
                          after={<TrashIcon className='w-[13px] h-[13px] text-dark-black-100 font-[600] opacity-80' strokeWidth={3} />} variant="outline">
                          Delete
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        )}
      </div>
      <Transition.Root show={deleteOk} as={Fragment}>
        <Dialog as="div" className="relative z-50 md:z-10 lg:z-10" onClose={() => { }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center sm:items-start h-full items-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  transition
                  className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4
                   data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full
                    sm:max-w-[500px] sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                >
                  <span className='font-[600]'>Delete
                    <span> {selectedRowsCount} Insight </span>
                  </span>

                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to delete your insights?
                    </p>
                  </div>
                  <div className="mt-5 sm:mt-4 flex gap-2 justify-end">
                    <Button
                      type="button"
                      onClick={handleDeleteConfirm}
                      className={`bg-red-600 hover:hover:bg-red-500 focus-visible:outline-red-600 relative
                      [&>span]:flex [&>span]:items-center [&>span]:gap-[8px]`}
                    >
                      Delete
                      {deleteLoading && (
                        <div className="simple-spinner">
                          <span></span>
                        </div>
                      )}
                    </Button>
                    <button
                      type="button"
                      onClick={() => setDeleteOk(false)}
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>

              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/*  assign ticket */}
      <Dialog open={isAssignTicketOpen} onClose={setIsAssignTicketOpen} className="relative z-10">
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 top-[56px] right-0 flex max-w-full pl-10">
              <DialogPanel
                transition
                className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
              >
                <div className="flex h-full flex-col overflow-y-scroll bg-white py-4 shadow-xl">
                  <div className="px-4 sm:px-4">
                    <div className="flex items-center justify-between">
                      <DialogTitle className="text-[14px] font-semibold leading-6 text-gray-900"> Assign Ticket <span>{selectedRowsCount}</span></DialogTitle>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          onClick={() => setIsAssignTicketOpen(false)}
                          className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon aria-hidden="true" className="h-5 w-5" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative mt-8 flex-1 px-4 sm:px-4">
                    <>
                      <div className="relative" ref={searchContainerRef}>
                        <label
                          htmlFor="searchTerm"
                          className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                        >
                          Ticket Search
                        </label>
                        <input
                          id="searchTerm"
                          name="searchTerm"
                          ref={searchInputRef}
                          value={searchTerm}

                          onChange={handleTopicSearch}
                          onKeyPress={handleKeyPress}
                          type="search"
                          className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
      ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 
      focus:ring-inset focus:ring-indigo-600 sm:leading-6 h-[36px] pr-10"
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3">


                          <MagnifyingGlassIcon
                            className="h-5 w-5 text-gray-400 cursor-pointer"
                            onClick={handleSearchIconClick}
                          />

                        </div>
                      </div>
                      {searchTerm && (
                        <>
                          {searchLoading1 ? (
                            <div className="flex items-center justify-center relative z-50 my-5">
                              <div className="simple-spinner-loader">
                                <span></span>
                              </div>
                            </div>
                          ) : (
                            <>
                              {searchResults.length > 0 ? (
                                <div className='mt-6 flex flex-col gap-2'>
                                  {searchResults.map((result, index) => (
                                    <div className={`${attachedTopics[result.id] ? 'bg-blue-50 rounded-md p-3  text-blue-700 ring-blue-700/10' : 'flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 feedback-delete-hover'}`}>
                                      <div className="flex justify-between gap-x-4">
                                        <div className="text-[13px] leading-5 text-gray-500 flex items-center">
                                          {
                                            result.item_refernce_id &&
                                            <div className="flex items-center">
                                              <p className='mr-1 text-[13px]'>
                                                {result.item_refernce_id}
                                              </p>
                                              <span className="text-[#b1b1b1] text-[13px] mr-1">-</span>
                                            </div>
                                          }
                                          {
                                            result.item_sentiment &&
                                            <div className="flex items-center">
                                              <p className='mr-1 text-[13px] capitalize'>
                                                {result.item_sentiment}
                                              </p>
                                            </div>
                                          }

                                        </div>


                                        <div
                                          className={`${attachedTopics[result.id] ? ' opacity-100' : 'feedback-delete-btn-hover'}`}>
                                          <button className='text-[13px] text-[#266df0]' onClick={handleAttachTopic(result.id)}
                                          >
                                            {attachedTopics[result.id] ? 'Selected' : 'Select'}
                                          </button>
                                        </div>

                                      </div>
                                      {
                                        result.item_title &&
                                        <span className="text-[13px] font-medium text-dark-black-100 mr-1 leading-4 line-clamp-2 mt-[5px]">
                                          {result.item_title}
                                        </span>
                                      }
                                      {
                                        result.item_details &&
                                        <div className="flex items-center">

                                          <p className="text-[13px] text-gray-500 leading-4 line-clamp-3 mt-[5px]">
                                            {result.item_details}
                                          </p>

                                        </div>
                                      }

                                    </div>

                                  ))}
                                </div>
                              ) : searchResults1.totalItems === 0 ? (
                                <span className="text-[13px] my-5 text-[#000] block">No Data Found</span>
                              ) : null}

                              <div className='mt-5 flex justify-end'>
                                <Button
                                  type="submit"
                                  onClick={handleInsightUpdate}
                                  className={`bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600 relative
            [&>span]:flex [&>span]:items-center [&>span]:gap-[8px]`}
                                // disabled={searchLoading1} // Disable the button while searching
                                >
                                  Save
                                  {insightUpdateLoading && (
                                    <div className="simple-spinner">
                                      <span></span>
                                    </div>
                                  )}
                                </Button>
                              </div>
                            </>
                          )}
                        </>
                      )}

                    </>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>



      <Dialog open={quickViewMode} onClose={setQuickViewMode} className="relative z-10">
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <DialogPanel
                transition
                className="pointer-events-auto w-screen max-w-[40rem] transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
              >
                <div className="flex h-full flex-col  bg-white py-0 shadow-xl">
                  <div className="px-0">
                    <div className="flex items-start justify-between flex-col">
                      {/* <DialogTitle className="text-base font-semibold leading-6 text-gray-900">Panel title</DialogTitle> */}
                      <ViewFeedbackQuickMode
                        itemId={selectedItemId}
                        isSidebarOpen={quickViewMode}
                        itemType={selectedItemType}
                        setIsSidebarOpen={setQuickViewMode} />
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>




    </>
  );

}


export default InsightTable;