// @ts-nocheck

import React, { useEffect, useRef, useState } from 'react';
import { _item_TopicSearchByParam } from '../setting/settingPages/baseAPI';
import { Bars3Icon, SparklesIcon } from '@heroicons/react/24/outline'
import { ViewIntegrationProps } from './type';
import { UpdateIntegrationDetails, fetchFeedbackData, fetchIntergrationDetails } from './integration-redux/integration.actions';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../hooks/hooks';
import { RootState } from '../../store/store';
import { FeedbackType } from './integration-redux/feedbacks.types';
import { Toaster, toast } from 'sonner';
import CustomerFeedback from './CustomerIntegration';
import ViewFeedbackHeader from './ViewIntegrationHeader';
import ViewFeedbackDetails from './ViewIntegrationDetails';
import IntegrationSteps from './integration-steps';
import IntegrationResponseTable from './IntegrationResponseTable';
import { Link } from 'react-router-dom';
import RatingResponse from './RatingResponse';


const ViewIntegration: React.FC<ViewIntegrationProps> = ({ item, isSidebarOpen, setIsSidebarOpen, headernone, itemId }) => {
  const [selectedTab, setSelectedTab] = useState<string>('');
  const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;
  const [assignedItemId, setAssignedItemId] = useState<string | null>(null);
  const [activeState, setActiveState] = useState('all');



  const [isAsideOpen, setIsAsideOpen] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  const [localLoading, setLocalLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editedIntegrationName, setEditedIntegrationName] = useState('');
  const [isEditingDetail, setIsEditingDetail] = useState(false);
  const [editedIntegrationDetail, setEditedIntegrationDetail] = useState(item?.integration_detail || '');
  const [isDetailButtonClicked, setIsDetailButtonClicked] = useState(false);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { data, loading, error } = useSelector((state: RootState) => state.integration);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const handleAssignToMe = () => {
    const itemIdToAssign = "item_assigned_to";
    setAssignedItemId(itemIdToAssign);
    setActiveState('assignToMe')
  };

  const handleRatingToMe = () => {
    setActiveState('ratingToMe')
  }


  useEffect(() => {
    const fetchData = async () => {
      if (itemId || id) {
        setLocalLoading(true);
        try {
          const integrationId = itemId || id;

          await dispatch(fetchIntergrationDetails(baseId, integrationId));
        } catch (error) {
          console.error("Error fetching feedback data:", error);
        } finally {
          setLocalLoading(false);
        }
      }
    };

    fetchData();
  }, [dispatch, itemId, id, baseId]);


  const handleNameClick = (name: string) => {
    setIsEditing(true);
    setEditedIntegrationName(name);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedIntegrationName(e.target.value);
  };

  const handleNameBlur = async () => {
    setIsEditing(false);
    if (editedIntegrationName !== item?.integration_name) {
      try {
        const updatedField = { integration_name: editedIntegrationName };
        await dispatch(UpdateIntegrationDetails(baseId, data[0].id, updatedField));
        dispatch(fetchIntergrationDetails(baseId, data[0].id));
        toast.success("Integration name updated successfully!");
      } catch (error) {
        toast.error("Error updating integration name.");
        console.error("Error updating integration name:", error);
      }
    }
  };

  const handleDetailChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setEditedIntegrationDetail(e.target.value);
  };

  const handleDetailButtonClick = () => {
    setIsDetailButtonClicked(true);
    setIsEditingDetail(true);
  };

  const handleDetailBlur = async () => {
    setIsEditingDetail(false);
    setIsDetailButtonClicked(false);

    if (editedIntegrationDetail !== item?.integration_detail) {
      try {
        const updatedField = { integration_detail: editedIntegrationDetail };
        await dispatch(UpdateIntegrationDetails(baseId, data[0].id, updatedField));
        dispatch(fetchIntergrationDetails(baseId, data[0].id));
        toast.success("Integration detail updated successfully!");
      } catch (error) {
        toast.error("Error updating integration detail.");
        console.error("Error updating integration detail:", error);
      }
    }
  };

  const handleNavigate = () => {
    window.open(`/survey/${data[0].id}`, "_blank"); 
  };



  if (loading) return (
    <div className=" flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 0px)' }}>
      <div className="simple-spinner-loader">
        <span></span>
      </div>
    </div>
  );
  if (error) return <div>Error: {error}</div>;
  if (data?.length === 0) return <div>No feedback available</div>;


  return (
    <>
      <Toaster richColors />
      <div className="sticky top-0">
        <div className='flex h-[46px] shrink-0 items-center justify-start bg-white px-4 shadow-custom-1 sm:px-6 lg:px-4'>
          {!headernone && (
            <div className="">
              <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                onClick={() => setIsSidebarOpen?.(!isSidebarOpen)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          )}

          {/* Separator */}
          <div className='flex items-center justify-between w-full'>
            <div className='flex items-center gap-8'>
            <h2 className='text-black-100 font-medium text-sm'>{selectedTab ? selectedTab : `${data[0].integration_ref_Id}`}</h2>
              <div className='flex items-center rounded-md'>
                <button
                  className={`px-[6px] md:px-2 lg:px-2 text-[12px] md:text-[0.8125rem] lg:text-[0.8125rem] leading-[1.2] font-semibold py-[5px]
               border border-r-0 border-solid border-[rgba(0, 0, 0, 0.08)] rounded-tl-md rounded-bl-md ${activeState === 'all' ? 'bg-[#6b7280] text-white  border-[#6b7280]' : 'bg-[#f9fafa] text-[#0000007a] hover:text-black-100'}`}
                  onClick={() => {
                    setActiveState('all');
                    setAssignedItemId(null);
                  }}
                >
                  Manage
                </button>
                <button
                  className={`px-[6px] md:px-2 lg:px-2 text-[12px] md:text-[0.8125rem] lg:text-[0.8125rem] leading-[1.2] font-semibold py-[5px] border-y border-l
               border-solid border-[rgba(0, 0, 0, 0.08)]  ${activeState === 'assignToMe' ? 'bg-[#6b7280] text-white border-[#6b7280]' : 'bg-[#f9fafa] text-[#0000007a] hover:text-black-100'}`}
                  onClick={handleAssignToMe}
                >
                  Responses
                </button>
                <button
                  className={`px-[6px] md:px-2 lg:px-2 text-[12px] md:text-[0.8125rem] lg:text-[0.8125rem] leading-[1.2] font-semibold py-[5px] border
               border-solid border-[rgba(0, 0, 0, 0.08)] rounded-tr-md rounded-br-md ${activeState === 'ratingToMe' ? 'bg-[#6b7280] text-white border-[#6b7280]' : 'bg-[#f9fafa] text-[#0000007a] hover:text-black-100'}`}
                  onClick={handleRatingToMe}
                >
                  Ratings
                </button>

              </div>
           
              {/* <button className='md:hidden sidebar-inbox-mobile' onClick={handleSidebarToggle}>
                        {`>`}
                    </button> */}
            </div>
            <div className='flex justify-between items-center gap-2'>
              <button
                onClick={handleNavigate}
                className="rounded-md duration-200 font-[600] text-[0.8125rem] h-[32px] w-[32px] flex items-center justify-center border border-solid border-[#e7e7e8]"
                style={{
                  transitionProperty:
                    "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform"
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                  data-slot="icon"
                  className="w-[1.1em] h-[1.1em] text-[#000]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                  />
                </svg>
              </button>

              <button
                onClick={() => setIsAsideOpen(!isAsideOpen)}
                style={{ transitionProperty: 'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform' }}
                className='rounded-md duration-200 font-[600] text-[0.8125rem] h-[32px] w-[32px] flex items-center justify-center border border-solid border-[#e7e7e8]'>
                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false"
                  height="1.1em" width="1.1em" xmlns="http://www.w3.org/2000/svg">
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="9" y1="3" x2="9" y2="21"></line></svg>
              </button>
            </div>

          </div>

        </div>
      </div>
      {data.map((integration: FeedbackType, index: number) => (
        <div key={index} className='flex-1 p-0'>
          <div className=''>
            {activeState === 'all' && <div>
              <div className='min-h-full max-w-full' style={{ margin: '0px auto' }}>
                <div className='flex items-stretch flex-row gap-0 w-full h-full relative overflow-x-hidden overflow-y-hidden'>
                  <main className='relative flex-1 flex '>
                    <div
                      className={isHovered ? 'overflowing-element hovered mq1023:max-w-full mq1800:max-w-[100%] mq3000:max-w-[100%] flex-1 w-full px-5 lg:px-[60px] overflow-y-auto view-feedback-data pb-20' : 'pb-20 new-feedbackoverflow  mq1023:max-w-full mq1800:max-w-[100%] mq3000:max-w-[100%] flex-1 w-full px-5 lg:px-[60px] overflow-y-auto view-feedback-data overflowing-element'}
                      onMouseOver={handleMouseOver}
                      onMouseOut={handleMouseOut}
                    >
                      <div className='max-w-full md:max-w-[710px] lg:max-w-[710px] m-auto'>
                        {isEditing ? (
                          <input
                            className="mb-4 mt-5 lg:mt-[55px] w-full p-0 text-[24px] leading-8 font-medium pb-[0px] h-[40px] text-dark-black-100 cursor-pointer
                         border-0 focus:border-0 focus:outline-none focus-within:border-none  !ring-0 !shadow-none !border-transparent"
                            value={editedIntegrationName}
                            onChange={handleNameChange}
                            onBlur={() => handleNameBlur(integration.id)}
                            autoFocus
                            maxLength={100}
                          />
                        ) : (
                          <div
                            className="mb-4 mt-5 lg:mt-[55px] min-h-[40px] h-[100%] w-full p-0 text-[24px] leading-[42px] font-medium text-dark-black-100 cursor-pointer"
                            onClick={() => handleNameClick(integration.integration_name)}
                          >
                            {integration.integration_name}
                          </div>
                        )}
                        {(isEditingDetail || isDetailButtonClicked) ? (
                          <textarea
                            value={editedIntegrationDetail}
                            onChange={handleDetailChange}
                            onBlur={handleDetailBlur}
                            autoFocus
                            maxLength={500}
                            className="border-none outline-none bg-transparent focus:ring-0 text-[14px] text-dark-black-100 w-full p-0 m-0 placeholder:text-[#9e9ea0] border-0 focus:border-0 focus:outline-none focus-within:border-none !ring-0 !shadow-none !border-transparent"
                          />
                        ) : (
                          integration?.integration_detail && (
                            <div className="cursor-pointer" onClick={() => setIsEditingDetail(true)}>
                              <div className="border-none outline-none bg-transparent focus:outline-none focus:ring-0 text-[14px] text-dark-black-100
                           w-full p-0 m-0 placeholder:text-[#9e9ea0]">
                                {integration?.integration_detail}
                              </div>
                            </div>
                          )
                        )}

                        {!integration?.integration_detail && !isDetailButtonClicked && (
                          <button className='font-medium text-dark-black-200  mr-1 text-[14px]'
                            onClick={handleDetailButtonClick}>Add details </button>
                        )}
                        <hr className='my-3'></hr>

                        <IntegrationSteps integration={integration} />

                      </div>
                    </div>



                    {/* feedback sidebar */}
                    <div
                      className={`${headernone ? ' main-feedback-side flex flex-col min-w-[200px] bg-white border-t-0 border border-light-gray-200 border-solid absolute lg:static top-0 right-0 max-w-[80%] view-feedback-right-side' : 'flex flex-col min-w-[200px] bg-white border-t-0 border border-light-gray-200 border-solid absolute lg:static top-0 right-0 max-w-[80%] view-feedback-right-side'}`}
                      style={{
                        width: isAsideOpen ? '350px' : '350px',
                        transform: isAsideOpen ? 'none' : 'none',
                        marginRight: isAsideOpen ? '0' : '-400px',
                        height: headernone ? 'calc(100vh - 46px)' : '',
                        ...(window.innerWidth <= 768 && {
                          width: isAsideOpen ? '300px' : '300px',
                          marginRight: isAsideOpen ? '0' : '-300px',
                        }),
                      }}
                    >
                      <div className='h-full'>
                        <ViewFeedbackDetails
                          itemId={itemId}
                          setLocalLoading={setLocalLoading}
                        />
                        <CustomerFeedback
                          itemId={itemId}
                          data1={data} />
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
            }
            {activeState === 'assignToMe' && <IntegrationResponseTable  id={integration.id} />}
            {activeState === 'ratingToMe' && <RatingResponse  id={integration.id} setIsAsideOpen={setIsAsideOpen} isAsideOpen={isAsideOpen} />}
          </div>

        </div>
      ))}


    </>
  );
};

export default ViewIntegration;
