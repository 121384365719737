//@ts-nocheck
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import "./style/dragList.css";
import DraggableElement from "./DraggableElement";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import { _fetchTopicListing } from "../../topic/topic-redux/topic.api";
import { _feedback_update_item } from "../../setting/settingPages/baseAPI";
import { toast } from "sonner";
import { getKeyPrefix } from "../../../utils/filterUtils";
import ListItem from "./ListItem";
import { _fetchFeedbackListing } from "../feedback-redux/feedbacks.api";


const DragList = ({ sectionHeight, activeFilters, itemAssignedTo }) => {

  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const scrollContainers = useRef({});
  const [apiData, setApiData] = useState({});
  const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;

  const { lists, statusIdMap } = useMemo(() => {
    try {
      const savedData = localStorage.getItem("itemStatusList");
      if (!savedData) {
        throw new Error("No saved data found in local storage");
      }
      const parsedData = JSON.parse(savedData);
      
      if (!parsedData.data || !Array.isArray(parsedData.data)) {
        throw new Error("Invalid data structure in local storage");
      }

      const statusMap = new Map();
      const idMap = new Map();
      parsedData.data.forEach(item => {
        statusMap.set(item.base_status_title, {
          title: item.base_status_title,
          color: item.color
        });
        idMap.set(item.base_status_title, item.id);
      });

      const statusList = Array.from(statusMap.values());

      return { lists: statusList, statusIdMap: idMap };
    } catch (error) {
      console.error("Error loading status titles from local storage:", error);
      return { lists: [], statusIdMap: new Map() };
    }
  }, []);

  const [isHovered, setIsHovered] = useState(
    lists.reduce((acc, listKey) => ({ ...acc, [listKey]: false }), {})
  );

  const handleMouseOver = (listKey) => {
    setIsHovered((prev) => ({ ...prev, [listKey]: true }));
  };

  const handleMouseOut = (listKey) => {
    setIsHovered((prev) => ({ ...prev, [listKey]: false }));
  };


  // send types in params

  const prepareFilters = (activeFilters) => {
    const filters = {};

    Object.keys(activeFilters).forEach((filterType) => {
      const key = getKeyPrefix(filterType);

      if (activeFilters[filterType] && activeFilters[filterType].length > 0) {
        if (filterType === 'sentiment') {
          filters[key] = activeFilters[filterType].map(item => item.value).join(',');
        } else {
          filters[key] = activeFilters[filterType].map(item => item.id).join(',');
        }
      }
    });

    return filters;
  };



  // topic list api
  useEffect(() => {
    const fetchData = async () => {
      setInitialLoading(true);
      const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
      const baseId = baseIdString ? JSON.parse(baseIdString) : null;
      const itemType = "feedback";
      const page = 1;
      const loginUserId = localStorage.getItem("userAuthId");
      const newApiData = {};

      const filters = {
        ...prepareFilters(activeFilters),
        ...(itemAssignedTo && { item_assigned_to: loginUserId }),
      };


      for (const listItem of lists) {
        const statusId = statusIdMap.get(listItem.title);
        if (statusId) {
          try {
            const response = await _fetchFeedbackListing(baseId, itemType, statusId, page, filters);
            if (response && response.data && response.data.items && Array.isArray(response.data.items)) {
              newApiData[listItem.title] = response.data;
            }
          } catch (error) {
            console.error(`Error fetching data for ${listItem.title}:`, error);
          }
        }
      }

      setApiData(newApiData);
      setInitialLoading(false);
    };

    fetchData();
  }, [lists, statusIdMap, activeFilters, itemAssignedTo]);


  // const onDragEnd = useCallback(
  //   (result: DropResult) => {
  //     if (!result.destination) {
  //       return;
  //     }

  //     const sourceList = apiData[result.source.droppableId]?.items;
  //     const destList = apiData[result.destination.droppableId]?.items;

  //     if (!Array.isArray(sourceList) || !Array.isArray(destList)) {
  //       console.error('Source or destination list is not an array');
  //       return;
  //     }

  //     const [removed] = sourceList.splice(result.source.index, 1);
  //     destList.splice(result.destination.index, 0, removed);

  //     setApiData({
  //       ...apiData,
  //       [result.source.droppableId]: {
  //         ...apiData[result.source.droppableId],
  //         items: sourceList,
  //       },
  //       [result.destination.droppableId]: {
  //         ...apiData[result.destination.droppableId],
  //         items: destList,
  //       },
  //     });
  //   },
  //   [apiData]
  // );

  // update change status with drag and drop 

  const onDragEnd = useCallback(
    async (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      const sourceList = apiData[result.source.droppableId]?.items;
      const destList = apiData[result.destination.droppableId]?.items;

      if (!Array.isArray(sourceList) || !Array.isArray(destList)) {
        console.error('Source or destination list is not an array');
        return;
      }

      const [removed] = sourceList.splice(result.source.index, 1);
      destList.splice(result.destination.index, 0, removed);

      setApiData({
        ...apiData,
        [result.source.droppableId]: {
          ...apiData[result.source.droppableId],
          items: sourceList,
        },
        [result.destination.droppableId]: {
          ...apiData[result.destination.droppableId],
          items: destList,
        },
      });

      const updatedItem = {
        item_id: removed.id,
        item_base_status_id: statusIdMap.get(result.destination.droppableId),
        item_assigned_to: removed.item_assigned_to,
      };

      try {
        const response = await _feedback_update_item(removed.id, baseId, updatedItem);
        if (response && response.data) {
          toast.success('Item status changed successfully');
        }
      } catch (error) {
        console.error('Update failed:', error);
      }
    },
    [apiData, statusIdMap]
  );


  const loadMoreItems = useCallback(
    async (listKey: string) => {
      if (loading || !apiData[listKey] || !apiData[listKey].hasNextPage) {
        return;
      }
      setLoading(true);

      const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
      const baseId = baseIdString ? JSON.parse(baseIdString) : null;
      const itemType = "feedback";
      const statusId = statusIdMap.get(listKey);
      const nextPage = apiData[listKey].currentPage + 1;

      try {
        const response = await _fetchFeedbackListing(baseId, itemType, statusId, nextPage);

        if (response && response.data && response.data.items && Array.isArray(response.data.items)) {
          setApiData(prev => ({
            ...prev,
            [listKey]: {
              ...response.data,
              items: [...prev[listKey].items, ...response.data.items],
            }
          }));
        }
      } catch (error) {
        console.error(`Error loading more items for ${listKey}:`, error);
      } finally {
        setLoading(false);
      }
    },
    [loading, apiData, statusIdMap]
  );


  const handleScroll = useCallback(
    (listItem) => {
      const container = scrollContainers.current[listItem.title];
      if (container) {
        if (
          container.scrollTop + container.clientHeight >= container.scrollHeight - 200 &&
          !loading &&
          apiData[listItem.title]?.hasNextPage
        ) {
          loadMoreItems(listItem.title);
        }
      }
    },
    [loading, loadMoreItems, apiData]
  );


  useEffect(() => {
    const listeners = {};
    lists.forEach(listItem => {
      const container = scrollContainers.current[listItem.title];
      if (container) {
        const handleScrollWrapper = () => handleScroll(listItem);
        container.addEventListener("scroll", handleScrollWrapper);
        listeners[listItem.title] = handleScrollWrapper;
      }
    });

    return () => {
      lists.forEach(listItem => {
        const container = scrollContainers.current[listItem.title];
        if (container && listeners[listItem.title]) {
          container.removeEventListener("scroll", listeners[listItem.title]);
        }
      });
    };
  }, [lists, handleScroll]);


  // dynamic filter height
  useEffect(() => {
    const applyStyles = () => {
      const tableElement = document.querySelector('.feedback-col') as HTMLElement | null;
      const tableElement1 = document.querySelector('.feedback-thead') as HTMLElement | null;
      const isTabScreen = window.matchMedia('(max-width: 1499px)').matches;
      const isSmallScreen = window.matchMedia('(max-width: 767px)').matches;
      if (tableElement) {
        if (isSmallScreen) {
          tableElement.style.marginTop = sectionHeight ? `calc(50px + ${sectionHeight}px)` : '50px';
        } else if (isTabScreen) {
          tableElement.style.marginTop = sectionHeight ? `calc(45px + ${sectionHeight}px)` : '46px';
        } else {
          tableElement.style.marginTop = sectionHeight ? `calc(45px + ${sectionHeight}px)` : '45px';
        }
      }

      // Apply top style to tableElement1 based on screen size
      if (tableElement1) {
        if (isSmallScreen) {
          tableElement1.style.top = sectionHeight ? `calc(80px + ${sectionHeight}px)` : '80px';
        } else if (isTabScreen) {
          tableElement1.style.top = sectionHeight ? `calc(101px + ${sectionHeight}px)` : '101px';
        } else {
          tableElement1.style.top = sectionHeight ? `calc(101px + ${sectionHeight}px)` : '101px';
        }

        if (sectionHeight) {
          tableElement1.style.borderTop = '1px solid #e5e7eb';
        } else {
          tableElement1.style.borderTop = 'none';
        }
      }
    };

    applyStyles();
    window.addEventListener('resize', applyStyles);
    return () => {
      window.removeEventListener('resize', applyStyles);
    };
  }, [sectionHeight]);


  return (
    <div className="p- 0 flex-1 bg-[#f9fafa] feedback-col">
      <div className="drag-list-container feedback-thead px-[15px] drag-list-containerpx]">
        <DragDropContext onDragEnd={onDragEnd}>
          {lists.map((listItem) => (
            <div key={listItem.title}
              className={`kanban-list-overflow overflowing-element drag-container rounded-[0.25rem] min-w-[320px] w-[320px] px-[1rem] flex flex-col flex-1 pb-[0.5rem] bg-[#f9fafa] ${isHovered[listItem.title] ? 'hovered' : ''}`}
              onMouseOver={() => handleMouseOver(listItem.title)}
              onMouseOut={() => handleMouseOut(listItem.title)}
              ref={(el) => (scrollContainers.current[listItem.title] = el)}>
              <div className="flex items-center justify-between h-[52px] min-h-[52px] sticky top-[0px] left-0 kanban-listing-main bg-[#f9fafa]">
                <div className='flex items-center max-w-full font-[500] leading-[1.2] text-[0.75rem] px-[0rem] py-[5px] gap-[0.5rem]'>
                  <span
                    className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full text-[0.625rem] font-medium text-white capitalize border-2 border-solid bg-white ${listItem.color.replace('bg-', 'border-')}`}
                  ></span>
                  <span className='text-dark-black-100 capitalize text-[13px]'>{listItem.title}</span>
                  <span className="text-dark-black-200 text-[0.8125rem] font-normal">{apiData[listItem.title]?.items?.length || 0}</span>
                </div>
                {/* <div className='flex items-center text-start  leading-[1rem] flex-1 max-w-[50px] text-dark-black-200 justify-end [&>button]:w-auto  [&>button]:bg-transparent  [&>button]:hover:bg-transparent'>
          <MenuDropdown groups={dropdownGroups} image={
            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle>
            </svg>
          } />
        </div> */}
              </div>
              {initialLoading ? (
                <div className="skeleton-wrapper rounded-[0.25rem] min-w-[280px] w-[320px] px-[0rem] flex flex-col flex-1 pb-[0.5rem] bg-[#f9fafa]">
                  {Array.from({ length: 1 }).map((_, index) => (
                    <div key={index} className="bg-white dark:bg-slate-800 p-4 ring-1 ring-slate-900/5 rounded-lg shadow-sm max-w-xs w-full h-20">
                      <Skeleton circle={true} height={30} width={30} />
                      <div className="skeleton-details">
                        {/* <Skeleton width={`80%`} height={`10px`} /> */}
                        <Skeleton width={`60%`} height={`10px`} />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <DraggableElement
                  elements={apiData[listItem.title]?.items || []}
                  prefix={listItem}
                  initialLoading={initialLoading}
                />
              )}

              {apiData[listItem.title]?.hasNextPage && loading && (
                <div className="skeleton-wrapper rounded-[0.25rem] min-w-[280px] w-[320px] px-[1rem] flex flex-col flex-1 pb-[0.5rem] bg-[#f9fafa]">
                  {Array.from({ length: 1 }).map((_, index) => (
                    <div key={index} className="bg-white dark:bg-slate-800 p-4 ring-1 ring-slate-900/5 rounded-lg shadow-sm max-w-xs w-full h-28">
                      <Skeleton circle={true} height={30} width={30} />
                      <div className="skeleton-details">
                        <Skeleton width={`80%`} height={`10px`} />
                        <Skeleton width={`60%`} height={`10px`} />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </DragDropContext>
      </div>
    </div>
  );
};

export default DragList;


