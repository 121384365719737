// @ts-nocheck



import { XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from '@lemonsqueezy/wedges';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import StarRating from '../../../components/StarRating';
import { closeFormAside, selectElementSteps } from '../integration-redux/integration.actions';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { useDispatch } from 'react-redux';
import { FormElement } from './types';
import { Switch } from '@headlessui/react'
import { textSpanEnd } from 'typescript';
import SmileyRating from '../../../components/SmileyRating';
import ThumbRating from '../../../components/ThumbRating';
import { FormContext } from './FormContext';


interface FormBuilderProps {
  elements: FormElement[];
  setElements: React.Dispatch<React.SetStateAction<FormElement[]>>;
  index?: number;
  setIsFormAsideOpen: React.Dispatch<React.SetStateAction<boolean>>;
  error?: string;
}

const FormBuilder: React.FC<FormBuilderProps> = ({ elements, setElements, setIsFormAsideOpen, error }) => {
  const [editingState, setEditingState] = useState<{ id: string | null; field: keyof FormElement | null }>({ id: null, field: null });
  const [editingValue, setEditingValue] = useState<string>('');
  const [newOptionLabel, setNewOptionLabel] = useState('');
  const [editingOptionId, setEditingOptionId] = useState<string | null>(null);
  const { isFormAsideOpen, isFormAsideView, selectedElement } = useSelector((state: RootState) => state.integration);
  const [enabled, setEnabled] = useState(selectedElement?.step_mandatory === 'yes');
  const [enabled1, setEnabled1] = useState(false)
  const [rating, setRating] = React.useState<number>(0);
  const [isHovered, setIsHovered] = useState(false);
  const [selectedSmiley, setSelectedSmiley] = useState<string | null>(null);
  const [selectedThumb, setSelectedThumb] = useState<number | null>(null);
  const { updateElementMandatory } = useContext(FormContext);

  useEffect(() => {
    setEnabled(selectedElement?.step_mandatory === 'yes');
  }, [selectedElement]);


  const handleMandatoryToggle = (elementId) => {
    const newStatus = !enabled;
    setEnabled(newStatus);
    updateElementMandatory(elementId, newStatus);
    
    setElements(prevElements => 
        prevElements.map(element => 
            element.id === elementId 
                ? { ...element, is_mandatory: newStatus } 
                : element
        )
    );
};
  const dispatch = useDispatch()
  const handleEdit = (id: string, value: string, field: keyof FormElement) => {
    setEditingState({ id, field });
    setEditingValue(value);
  };


  const handleStepDelete = (id: string) => {
    if (id) {
      setElements((prevElements) => {
        const updatedElements = prevElements.filter(element => element.id !== id);
        return updatedElements;
      });
      setEditingState({ id: null, field: null });
    }
  };



  const handleStepView = useCallback((id: string) => {
    const element = elements.find(step => step.id === id);
    if (element) {
      dispatch(selectElementSteps(id, element));
      setIsFormAsideOpen(false);
    }
  }, [elements, dispatch, isFormAsideOpen]);



  const handleSave = () => {
    if (editingState.id && editingState.field) {
      setElements(prevElements => {
        const updatedElements = prevElements.map(element => {
          if (element.id === editingState.id) {
            const updatedElement = { ...element };
            if (editingState.field === 'options') {
              updatedElement.options = editingValue.split(',').map(option => option.trim());
            } else {
              updatedElement[editingState.field] = editingValue;
            }

            return updatedElement;
          }
          return element;
        });

        return updatedElements;
      });

      setEditingState({ id: null, field: null });
      setEditingValue('');
    }
  };


  const handleOptionClick = (elementId: string, optionId: number) => {
    setElements(prevElements => prevElements.map(element =>
      element.id === elementId
        ? {
          ...element,
          options: element.options?.map(option =>
            option.id === optionId
              ? { ...option, selected: !option.selected }
              : option
          )
        }
        : element
    ));
  };

  const handleRemoveOption = (elementId: string, optionId: number) => {
    setElements(prevElements => prevElements.map(element =>
      element.id === elementId
        ? { ...element, options: element.options?.filter(option => option.id !== optionId) }
        : element
    ));
  };

  const handleAddOption = (elementId: string) => {
    setElements(prevElements => prevElements.map(element =>
      element.id === elementId
        ? {
          ...element,
          options: [
            ...(element.options || []),
            { id: uuidv4(), label: 'New Option', selected: false }
          ]
        }
        : element
    ));
  };

  const handleAddOption1 = (elementId: string) => {
    setElements(prevElements => prevElements.map(element =>
      element.id === elementId
        ? {
          ...element,
          options: [
            ...(element.options || []),
            { id: uuidv4(), label: 'No.', selected: false }
          ]
        }
        : element
    ));
  };


  const handleOptionEdit = (elementId: string, optionId: string, label: string) => {
    setEditingOptionId(optionId);
    setEditingValue(label);
  };

  const handleOptionSave = (elementId: string) => {
    if (editingOptionId) {
      setElements(prevElements => prevElements.map(element =>
        element.id === elementId
          ? {
            ...element,
            options: element.options?.map(option =>
              option.id === editingOptionId
                ? { ...option, label: editingValue }
                : option
            ),
          }
          : element
      ));

      setEditingOptionId(null);
      setEditingValue('');
    }
  };

  const textareaRef1 = useRef(null);
  const textareaRef2 = useRef(null);


  useEffect(() => {
    if (textareaRef1.current) {
      textareaRef1.current.style.height = '0px';
      const scrollHeight = textareaRef1.current.scrollHeight;

      textareaRef1.current.style.height = `${scrollHeight}px`;
    }
  }, [newOptionLabel]);

  useEffect(() => {
    if (textareaRef2.current) {
      textareaRef2.current.style.height = '0px';
      const scrollHeight = textareaRef2.current.scrollHeight;
      textareaRef2.current.style.height = `19 +${scrollHeight}px`;
    }
  }, [newOptionLabel]);



  const renderEditableText = (element: FormElement, index: number, field: keyof FormElement, label: string) => {
    const value = element[field] || '';
    const displayText = element.label || element.step_input_type || 'No Value';


    if (editingState.id === element.id && editingState.field === field) {
      return (
        <div className='flex items-start gap-2 p-2'>
          <div className='flex flex-col gap-y-2 w-full'>
            <div className='flex items-center gap-x-1'>
              <span className="cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 
           text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">{displayText} </span>
              {element?.step_mandatory === "yes" && <span className='text-red-500 '>*</span>}
            </div>
            <input
              type="text"
              id={`input-${field}`}
              value={editingValue}
              onChange={(e) => setEditingValue(e.target.value)}
              onBlur={handleSave}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSave();
                }
              }}
              className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1
             h-[36px] ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 
             focus:ring-inset focus:ring-indigo-600 sm:leading-6"
              autoFocus
            />

          </div>
          <div className='w-4 h-4' onClick={() => handleStepView(element.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
              <path fillRule="evenodd" d="M8.34 1.804A1 1 0 0 1 9.32 1h1.36a1 1 0 0 1 .98.804l.295 1.473c.497.144.971.342 1.416.587l1.25-.834a1 1 0 0 1 1.262.125l.962.962a1 1 0 0 1 .125 1.262l-.834 1.25c.245.445.443.919.587 1.416l1.473.294a1 1 0 0 1 .804.98v1.361a1 1 0 0 1-.804.98l-1.473.295a6.95 6.95 0 0 1-.587 1.416l.834 1.25a1 1 0 0 1-.125 1.262l-.962.962a1 1 0 0 1-1.262.125l-1.25-.834a6.953 6.953 0 0 1-1.416.587l-.294 1.473a1 1 0 0 1-.98.804H9.32a1 1 0 0 1-.98-.804l-.295-1.473a6.957 6.957 0 0 1-1.416-.587l-1.25.834a1 1 0 0 1-1.262-.125l-.962-.962a1 1 0 0 1-.125-1.262l.834-1.25a6.957 6.957 0 0 1-.587-1.416l-1.473-.294A1 1 0 0 1 1 10.68V9.32a1 1 0 0 1 .804-.98l1.473-.295c.144-.497.342-.971.587-1.416l-.834-1.25a1 1 0 0 1 .125-1.262l.962-.962A1 1 0 0 1 5.38 3.03l1.25.834a6.957 6.957 0 0 1 1.416-.587l.294-1.473ZM13 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clipRule="evenodd" />
            </svg>
          </div>

          <div className='w-4 h-4' onClick={() => handleStepDelete(element.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
              <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clipRule="evenodd" />
            </svg>
          </div>


        </div>

      );
    }

    return (
      <div
        onClick={() => handleEdit(element.id, value.toString(), field)}
        className="cursor-pointer flex items-start justify-between py-2 px-2 relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className='w-full flex flex-col gap-y-2'>
          <div className='flex items-center gap-x-1'>
            <span className="cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 
           text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">{displayText} </span>
            {element?.step_mandatory === "yes" && <span className='text-red-500 '>*</span>}
          </div>
          <p className="cursor-pointer font-[400] text-[#121217] text-[13px] flex items-center">{value}
          </p>

        </div>
        <div className='flex items-center gap-2'>

          <div className='w-4 h-4' onClick={() => handleStepView(element.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
              <path fillRule="evenodd" d="M8.34 1.804A1 1 0 0 1 9.32 1h1.36a1 1 0 0 1 .98.804l.295 1.473c.497.144.971.342 1.416.587l1.25-.834a1 1 0 0 1 1.262.125l.962.962a1 1 0 0 1 .125 1.262l-.834 1.25c.245.445.443.919.587 1.416l1.473.294a1 1 0 0 1 .804.98v1.361a1 1 0 0 1-.804.98l-1.473.295a6.95 6.95 0 0 1-.587 1.416l.834 1.25a1 1 0 0 1-.125 1.262l-.962.962a1 1 0 0 1-1.262.125l-1.25-.834a6.953 6.953 0 0 1-1.416.587l-.294 1.473a1 1 0 0 1-.98.804H9.32a1 1 0 0 1-.98-.804l-.295-1.473a6.957 6.957 0 0 1-1.416-.587l-1.25.834a1 1 0 0 1-1.262-.125l-.962-.962a1 1 0 0 1-.125-1.262l.834-1.25a6.957 6.957 0 0 1-.587-1.416l-1.473-.294A1 1 0 0 1 1 10.68V9.32a1 1 0 0 1 .804-.98l1.473-.295c.144-.497.342-.971.587-1.416l-.834-1.25a1 1 0 0 1 .125-1.262l.962-.962A1 1 0 0 1 5.38 3.03l1.25.834a6.957 6.957 0 0 1 1.416-.587l.294-1.473ZM13 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clipRule="evenodd" />
            </svg>
          </div>

          <div className='w-4 h-4' onClick={() => handleStepDelete(element.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
              <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clipRule="evenodd" />
            </svg>
          </div>

        </div>
        {
          error?.isError === true &&
          <>
            {error && isHovered && (
              <div className="absolute bottom-full left-0 mb-2 px-2 py-1 top-[-39px] bg-red-100 text-red-700 rounded shadow-md text-[13px]">
                {error.reason}
              </div>
            )}
          </>
        }
      </div>
    );
  };

  const renderEditableTextHeading = (element: FormElement, index: number, field: keyof FormElement, label: string) => {
    const value = element[field] || '';
    const displayText = element.label || element.step_input_type || 'No Value';

    if (editingState.id === element.id && editingState.field === field) {
      return (
        <div className='flex items-start gap-2 p-2'>
          <div className='flex flex-col gap-y-2 w-full'>
            <div className='flex items-center gap-x-1'>
              <span className="cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 
        text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">{displayText} </span>
              {element?.step_mandatory === "yes" && <span className='text-red-500 '>*</span>}
            </div>
            <input
              type="text"
              id={`input-${field}`}
              value={editingValue}
              onChange={(e) => setEditingValue(e.target.value)}
              onBlur={handleSave}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSave();
                }
              }}
              className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1
             h-[36px] ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 
             focus:ring-inset focus:ring-indigo-600 sm:leading-6"
              autoFocus
            />
          </div>

          <div className='w-4 h-4' onClick={() => handleStepView(element.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
              <path fillRule="evenodd" d="M8.34 1.804A1 1 0 0 1 9.32 1h1.36a1 1 0 0 1 .98.804l.295 1.473c.497.144.971.342 1.416.587l1.25-.834a1 1 0 0 1 1.262.125l.962.962a1 1 0 0 1 .125 1.262l-.834 1.25c.245.445.443.919.587 1.416l1.473.294a1 1 0 0 1 .804.98v1.361a1 1 0 0 1-.804.98l-1.473.295a6.95 6.95 0 0 1-.587 1.416l.834 1.25a1 1 0 0 1-.125 1.262l-.962.962a1 1 0 0 1-1.262.125l-1.25-.834a6.953 6.953 0 0 1-1.416.587l-.294 1.473a1 1 0 0 1-.98.804H9.32a1 1 0 0 1-.98-.804l-.295-1.473a6.957 6.957 0 0 1-1.416-.587l-1.25.834a1 1 0 0 1-1.262-.125l-.962-.962a1 1 0 0 1-.125-1.262l.834-1.25a6.957 6.957 0 0 1-.587-1.416l-1.473-.294A1 1 0 0 1 1 10.68V9.32a1 1 0 0 1 .804-.98l1.473-.295c.144-.497.342-.971.587-1.416l-.834-1.25a1 1 0 0 1 .125-1.262l.962-.962A1 1 0 0 1 5.38 3.03l1.25.834a6.957 6.957 0 0 1 1.416-.587l.294-1.473ZM13 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clipRule="evenodd" />
            </svg>
          </div>

          <div className='w-4 h-4' onClick={() => handleStepDelete(element.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
              <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clipRule="evenodd" />
            </svg>
          </div>


        </div>

      );
    }

    return (
      <div
        onClick={() => handleEdit(element.id, value.toString(), field)}
        className="cursor-pointer flex items-start justify-between py-2 px-2 relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className='w-full flex flex-col gap-y-2'>
          <div className='flex items-center gap-x-1'>
            <span className="cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 
           text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">{displayText} </span>
            {element?.step_mandatory === "yes" && <span className='text-red-500 '>*</span>}
          </div>
          <p className="cursor-pointer font-[500] text-[#121217] text-[14px] flex items-center">{value}
          </p>

        </div>
        <div className='flex items-center gap-2'>
          <div className='w-4 h-4' onClick={() => handleStepView(element.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
              <path fillRule="evenodd" d="M8.34 1.804A1 1 0 0 1 9.32 1h1.36a1 1 0 0 1 .98.804l.295 1.473c.497.144.971.342 1.416.587l1.25-.834a1 1 0 0 1 1.262.125l.962.962a1 1 0 0 1 .125 1.262l-.834 1.25c.245.445.443.919.587 1.416l1.473.294a1 1 0 0 1 .804.98v1.361a1 1 0 0 1-.804.98l-1.473.295a6.95 6.95 0 0 1-.587 1.416l.834 1.25a1 1 0 0 1-.125 1.262l-.962.962a1 1 0 0 1-1.262.125l-1.25-.834a6.953 6.953 0 0 1-1.416.587l-.294 1.473a1 1 0 0 1-.98.804H9.32a1 1 0 0 1-.98-.804l-.295-1.473a6.957 6.957 0 0 1-1.416-.587l-1.25.834a1 1 0 0 1-1.262-.125l-.962-.962a1 1 0 0 1-.125-1.262l.834-1.25a6.957 6.957 0 0 1-.587-1.416l-1.473-.294A1 1 0 0 1 1 10.68V9.32a1 1 0 0 1 .804-.98l1.473-.295c.144-.497.342-.971.587-1.416l-.834-1.25a1 1 0 0 1 .125-1.262l.962-.962A1 1 0 0 1 5.38 3.03l1.25.834a6.957 6.957 0 0 1 1.416-.587l.294-1.473ZM13 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clipRule="evenodd" />
            </svg>
          </div>
          <div className='w-4 h-4' onClick={() => handleStepDelete(element.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
              <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clipRule="evenodd" />
            </svg>
          </div>

        </div>
        {
          error?.isError === true &&
          <>
            {error && isHovered && (
              <div className="absolute bottom-full left-0 mb-2 px-2 py-1 top-[-39px] bg-red-100 text-red-700 rounded shadow-md text-[13px]">
                {error.reason}
              </div>
            )}
          </>
        }
      </div>
    );
  };

  const renderEditableInputText = (element: FormElement, index: number, field: keyof FormElement, label: string) => {
    const value = element[field] || '';
    const displayText = element.label || element.step_input_type || 'No Value';

    if (editingState.id === element.id && editingState.field === field) {
      return (
        <div className='flex items-start gap-2 p-2'>
          <div className='flex flex-col w-full gap-y-3'>
            {editingState.id === null && element.input ? (
              <div className='flex items-center gap-x-1'>
                <span className="cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 
        text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">{displayText} </span>
                {element?.step_mandatory === "yes" && <span className='text-red-500 '>*</span>}
              </div>
            ) : (
              <div className='flex items-center gap-x-1'>
                <span className="cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 
           text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">{displayText} </span>
                {element?.step_mandatory === "yes" && <span className='text-red-500 '>*</span>}
              </div>
            )}
            <input
              type="text"
              id={`input-${field}`}
              value={editingValue}
              onChange={(e) => setEditingValue(e.target.value)}
              onBlur={handleSave}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSave();
                }
              }}
              className="block w-full rounded-md border-0 py-1.5 px-2 text-[#121217] text-[14px] font-[500]
              shadow-sm ring-1 h-[36px] ring-inset ring-gray-300 placeholder:text-[#52555a] 
               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
              autoFocus
            />
            <input
              type="text"
              id={`input-${field}`}
              // value={editingValue}
              onChange={(e) => setEditingValue(e.target.value)}
              onBlur={handleSave}
              placeholder='Type here ..'
              disabled

              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSave();
                }
              }}
              className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 h-[36px]
              shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2
               focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed
                disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-[13px] sm:leading-6"
              autoFocus
            />

          </div>
          <div className='w-4 h-4' onClick={() => handleStepView(element.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
              <path fillRule="evenodd" d="M8.34 1.804A1 1 0 0 1 9.32 1h1.36a1 1 0 0 1 .98.804l.295 1.473c.497.144.971.342 1.416.587l1.25-.834a1 1 0 0 1 1.262.125l.962.962a1 1 0 0 1 .125 1.262l-.834 1.25c.245.445.443.919.587 1.416l1.473.294a1 1 0 0 1 .804.98v1.361a1 1 0 0 1-.804.98l-1.473.295a6.95 6.95 0 0 1-.587 1.416l.834 1.25a1 1 0 0 1-.125 1.262l-.962.962a1 1 0 0 1-1.262.125l-1.25-.834a6.953 6.953 0 0 1-1.416.587l-.294 1.473a1 1 0 0 1-.98.804H9.32a1 1 0 0 1-.98-.804l-.295-1.473a6.957 6.957 0 0 1-1.416-.587l-1.25.834a1 1 0 0 1-1.262-.125l-.962-.962a1 1 0 0 1-.125-1.262l.834-1.25a6.957 6.957 0 0 1-.587-1.416l-1.473-.294A1 1 0 0 1 1 10.68V9.32a1 1 0 0 1 .804-.98l1.473-.295c.144-.497.342-.971.587-1.416l-.834-1.25a1 1 0 0 1 .125-1.262l.962-.962A1 1 0 0 1 5.38 3.03l1.25.834a6.957 6.957 0 0 1 1.416-.587l.294-1.473ZM13 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clipRule="evenodd" />
            </svg>
          </div>

          <div className='w-4 h-4' onClick={() => handleStepDelete(element.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
              <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clipRule="evenodd" />
            </svg>
          </div>
        </div>

      );
    }

    return (
      <div
        className="cursor-pointer flex items-start justify-between gap-2 p-2 relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className='flex flex-col w-full gap-y-3'>
          <span className=" cursor-pointer font-[500] text-[#121217] text-[14px] flex items-start gap-2 justify-between flex-col"
          >
            <div className='flex items-center gap-x-1'>
              <span className="cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 
           text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">{displayText} </span>
              {element?.step_mandatory === "yes" && <span className='text-red-500 '>*</span>}
            </div>
            {editingState.id === null && !element.input &&
              <div className='w-[94%]'>
                <div className='font-[500] text-[#121217] text-[14px]'
                  onClick={() => handleEdit(element.id, value.toString(), field)}>{element.headerText}</div>
              </div>
            }

          </span>
          <input
            type="text"
            id={`input-${field}`}
            value={editingValue}
            onChange={(e) => setEditingValue(e.target.value)}
            onBlur={handleSave}
            placeholder='Type here ..'
            disabled

            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSave();
              }
            }}
            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 h-[36px]
             shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2
              focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed
               disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-[13px] sm:leading-6"

            autoFocus
          />
        </div>
        <div className='flex items-center gap-2'>

          <div className='w-4 h-4' onClick={() => handleStepView(element.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
              <path fillRule="evenodd" d="M8.34 1.804A1 1 0 0 1 9.32 1h1.36a1 1 0 0 1 .98.804l.295 1.473c.497.144.971.342 1.416.587l1.25-.834a1 1 0 0 1 1.262.125l.962.962a1 1 0 0 1 .125 1.262l-.834 1.25c.245.445.443.919.587 1.416l1.473.294a1 1 0 0 1 .804.98v1.361a1 1 0 0 1-.804.98l-1.473.295a6.95 6.95 0 0 1-.587 1.416l.834 1.25a1 1 0 0 1-.125 1.262l-.962.962a1 1 0 0 1-1.262.125l-1.25-.834a6.953 6.953 0 0 1-1.416.587l-.294 1.473a1 1 0 0 1-.98.804H9.32a1 1 0 0 1-.98-.804l-.295-1.473a6.957 6.957 0 0 1-1.416-.587l-1.25.834a1 1 0 0 1-1.262-.125l-.962-.962a1 1 0 0 1-.125-1.262l.834-1.25a6.957 6.957 0 0 1-.587-1.416l-1.473-.294A1 1 0 0 1 1 10.68V9.32a1 1 0 0 1 .804-.98l1.473-.295c.144-.497.342-.971.587-1.416l-.834-1.25a1 1 0 0 1 .125-1.262l.962-.962A1 1 0 0 1 5.38 3.03l1.25.834a6.957 6.957 0 0 1 1.416-.587l.294-1.473ZM13 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clipRule="evenodd" />
            </svg>
          </div>

          <div className='w-4 h-4' onClick={() => handleStepDelete(element.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
              <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clipRule="evenodd" />
            </svg>
          </div>

        </div>
        {
          error?.isError === true &&
          <>
            {error && isHovered && (
              <div className="absolute bottom-full left-0 mb-2 px-2 py-1 top-[-39px] bg-red-100 text-red-700 rounded shadow-md text-[13px]">
                {error.reason}
              </div>
            )}
          </>
        }
      </div>
    );
  };


  const renderEditableTextarea = (element: FormElement, index: number, field: keyof FormElement, label: string) => {
    const value = element[field] || '';
    const displayText = element.label || element.step_input_type || 'No Value';

    if (editingState.id === element.id && editingState.field === field) {
      return (
        <div className='flex items-start gap-2 p-2'>
          <div className='flex flex-col w-full gap-y-3'>
            {editingState.id === null && element.textarea ? (
              <div className='flex items-center gap-x-1'>
                <span className="cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 
           text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">{displayText} </span>
                {element?.step_mandatory === "yes" && <span className='text-red-500 '>*</span>}
              </div>
            ) : (
              <div className='flex items-center gap-x-1'>
                <span className="cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 
           text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">{displayText} </span>
                {element?.step_mandatory === "yes" && <span className='text-red-500 '>*</span>}
              </div>
            )}
            <textarea
              rows={2}
              id={`input-${field}`}
              value={editingValue}
              onChange={(e) => setEditingValue(e.target.value)}
              onBlur={handleSave}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSave();
                }
              }}
              className="block w-full rounded-md border-0 py-1.5 px-2 text-[#121217] text-[14px] font-[500]
              shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#52555a] 
               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
              autoFocus
            />
            <textarea
              rows={2}
              id={`input-${field}`}
              // value={editingValue}
              onChange={(e) => setEditingValue(e.target.value)}
              onBlur={handleSave}
              placeholder='Type here ..'
              disabled

              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSave();
                }
              }}
              className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900
              shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2
               focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed
                disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-[13px] sm:leading-6"
              autoFocus
            />

          </div>
          <div className='w-4 h-4' onClick={() => handleStepView(element.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
              <path fillRule="evenodd" d="M8.34 1.804A1 1 0 0 1 9.32 1h1.36a1 1 0 0 1 .98.804l.295 1.473c.497.144.971.342 1.416.587l1.25-.834a1 1 0 0 1 1.262.125l.962.962a1 1 0 0 1 .125 1.262l-.834 1.25c.245.445.443.919.587 1.416l1.473.294a1 1 0 0 1 .804.98v1.361a1 1 0 0 1-.804.98l-1.473.295a6.95 6.95 0 0 1-.587 1.416l.834 1.25a1 1 0 0 1-.125 1.262l-.962.962a1 1 0 0 1-1.262.125l-1.25-.834a6.953 6.953 0 0 1-1.416.587l-.294 1.473a1 1 0 0 1-.98.804H9.32a1 1 0 0 1-.98-.804l-.295-1.473a6.957 6.957 0 0 1-1.416-.587l-1.25.834a1 1 0 0 1-1.262-.125l-.962-.962a1 1 0 0 1-.125-1.262l.834-1.25a6.957 6.957 0 0 1-.587-1.416l-1.473-.294A1 1 0 0 1 1 10.68V9.32a1 1 0 0 1 .804-.98l1.473-.295c.144-.497.342-.971.587-1.416l-.834-1.25a1 1 0 0 1 .125-1.262l.962-.962A1 1 0 0 1 5.38 3.03l1.25.834a6.957 6.957 0 0 1 1.416-.587l.294-1.473ZM13 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clipRule="evenodd" />
            </svg>
          </div>

          <div className='w-4 h-4' onClick={() => handleStepDelete(element.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
              <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clipRule="evenodd" />
            </svg>
          </div>
        </div>

      );
    }

    return (
      <div
        className="cursor-pointer flex items-start justify-between gap-2 p-2 relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className='flex flex-col w-full gap-y-3'>
          <span className=" cursor-pointer font-[500] text-[#121217] text-[14px] flex items-start justify-between flex-col gap-y-2
          " >
            <div className='flex items-center gap-x-1'>
              <span className="cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 
           text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">{displayText} </span>
              {element?.step_mandatory === "yes" && <span className='text-red-500 '>*</span>}
            </div>
            {editingState.id === null && !element.textarea &&
              <div className='w-[94%]'>


                <div className='font-[500] text-[#121217] text-[14px]'
                  onClick={() => handleEdit(element.id, value.toString(), field)}>{element.headerText}</div>

              </div>
            }

          </span>
          <textarea
            rows={2}
            id={`input-${field}`}
            value={editingValue}
            onChange={(e) => setEditingValue(e.target.value)}
            onBlur={handleSave}
            placeholder='Type here ..'
            disabled

            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSave();
              }
            }}
            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 h-[36px]
             shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2
              focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed
               disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-[13px] sm:leading-6"

            autoFocus
          />
        </div>
        <div className='flex items-center gap-2 '>

          <div className='w-4 h-4' onClick={() => handleStepView(element.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
              <path fillRule="evenodd" d="M8.34 1.804A1 1 0 0 1 9.32 1h1.36a1 1 0 0 1 .98.804l.295 1.473c.497.144.971.342 1.416.587l1.25-.834a1 1 0 0 1 1.262.125l.962.962a1 1 0 0 1 .125 1.262l-.834 1.25c.245.445.443.919.587 1.416l1.473.294a1 1 0 0 1 .804.98v1.361a1 1 0 0 1-.804.98l-1.473.295a6.95 6.95 0 0 1-.587 1.416l.834 1.25a1 1 0 0 1-.125 1.262l-.962.962a1 1 0 0 1-1.262.125l-1.25-.834a6.953 6.953 0 0 1-1.416.587l-.294 1.473a1 1 0 0 1-.98.804H9.32a1 1 0 0 1-.98-.804l-.295-1.473a6.957 6.957 0 0 1-1.416-.587l-1.25.834a1 1 0 0 1-1.262-.125l-.962-.962a1 1 0 0 1-.125-1.262l.834-1.25a6.957 6.957 0 0 1-.587-1.416l-1.473-.294A1 1 0 0 1 1 10.68V9.32a1 1 0 0 1 .804-.98l1.473-.295c.144-.497.342-.971.587-1.416l-.834-1.25a1 1 0 0 1 .125-1.262l.962-.962A1 1 0 0 1 5.38 3.03l1.25.834a6.957 6.957 0 0 1 1.416-.587l.294-1.473ZM13 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clipRule="evenodd" />
            </svg>
          </div>

          <div className='w-4 h-4' onClick={() => handleStepDelete(element.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
              <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clipRule="evenodd" />
            </svg>
          </div>

        </div>
        {
          error?.isError === true &&
          <>
            {error && isHovered && (
              <div className="absolute bottom-full left-0 mb-2 px-2 py-1 top-[-39px] bg-red-100 text-red-700 rounded shadow-md text-[13px]">
                {error.reason}
              </div>
            )}
          </>
        }
      </div>

    );
  };

  const renderEditableEmailText = (element: FormElement, index: number, field: keyof FormElement, label: string) => {
    const value = element[field] || '';
    const displayText = element.label || element.step_input_type || 'No Value';

    if (editingState.id === element.id && editingState.field === field) {
      return (
        <div className='flex items-start gap-2 p-2'>
          <div className='flex flex-col w-full gap-y-3'>
            {editingState.id === null && element.email ? (
              <div className='flex items-center gap-x-1'>
                <span className="cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 
           text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">{displayText} </span>
                {element?.step_mandatory === "yes" && <span className='text-red-500 '>*</span>}
              </div>
            ) : (
              <div className='flex items-center gap-x-1'>
                <span className="cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 
           text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">{displayText} </span>
                {element?.step_mandatory === "yes" && <span className='text-red-500 '>*</span>}
              </div>
            )}
            <input
              type="text"
              id={`input-${field}`}
              value={editingValue}
              onChange={(e) => setEditingValue(e.target.value)}
              onBlur={handleSave}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSave();
                }
              }}
              className="block w-full rounded-md border-0 py-1.5 px-2 text-[#121217] text-[14px] font-[500]
              shadow-sm ring-1 h-[36px] ring-inset ring-gray-300 placeholder:text-[#52555a] 
               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
              autoFocus
            />
            <input
              type="text"
              id={`input-${field}`}
              // value={editingValue}
              onChange={(e) => setEditingValue(e.target.value)}
              onBlur={handleSave}
              placeholder='Please enter you email id'
              disabled

              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSave();
                }
              }}
              className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 h-[36px]
              shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2
               focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed
                disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-[13px] sm:leading-6"
              autoFocus
            />

          </div>
          <div className='w-4 h-4' onClick={() => handleStepView(element.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
              <path fillRule="evenodd" d="M8.34 1.804A1 1 0 0 1 9.32 1h1.36a1 1 0 0 1 .98.804l.295 1.473c.497.144.971.342 1.416.587l1.25-.834a1 1 0 0 1 1.262.125l.962.962a1 1 0 0 1 .125 1.262l-.834 1.25c.245.445.443.919.587 1.416l1.473.294a1 1 0 0 1 .804.98v1.361a1 1 0 0 1-.804.98l-1.473.295a6.95 6.95 0 0 1-.587 1.416l.834 1.25a1 1 0 0 1-.125 1.262l-.962.962a1 1 0 0 1-1.262.125l-1.25-.834a6.953 6.953 0 0 1-1.416.587l-.294 1.473a1 1 0 0 1-.98.804H9.32a1 1 0 0 1-.98-.804l-.295-1.473a6.957 6.957 0 0 1-1.416-.587l-1.25.834a1 1 0 0 1-1.262-.125l-.962-.962a1 1 0 0 1-.125-1.262l.834-1.25a6.957 6.957 0 0 1-.587-1.416l-1.473-.294A1 1 0 0 1 1 10.68V9.32a1 1 0 0 1 .804-.98l1.473-.295c.144-.497.342-.971.587-1.416l-.834-1.25a1 1 0 0 1 .125-1.262l.962-.962A1 1 0 0 1 5.38 3.03l1.25.834a6.957 6.957 0 0 1 1.416-.587l.294-1.473ZM13 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clipRule="evenodd" />
            </svg>
          </div>

          <div className='w-4 h-4' onClick={() => handleStepDelete(element.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
              <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clipRule="evenodd" />
            </svg>
          </div>
        </div>

      );
    }

    return (
      <div
        className="cursor-pointer flex items-start justify-between gap-2 p-2 relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className='flex flex-col w-full gap-y-3'>
          <span className=" cursor-pointer font-[500] text-[#121217] text-[14px] flex items-start gap-2 justify-between flex-col"
          >
             <div className='flex items-center gap-x-1'>
              <span className="cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 
           text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">{displayText} </span>
              {element?.step_mandatory === "yes" && <span className='text-red-500 '>*</span>}
            </div>
            {editingState.id === null && !element.email &&
              <div className='w-[94%]'>
                <div className='font-[500] text-[#121217] text-[14px]'
                  onClick={() => handleEdit(element.id, value.toString(), field)}>{element.headerText}</div>
              </div>
            }
          </span>
          <input
            type="text"
            id={`input-${field}`}
            value={editingValue}
            onChange={(e) => setEditingValue(e.target.value)}
            onBlur={handleSave}
            placeholder='Please enter you email id'
            disabled

            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSave();
              }
            }}
            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 h-[36px]
             shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2
              focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed
               disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-[13px] sm:leading-6"

            autoFocus
          />
        </div>
        <div className='flex items-center gap-2'>

          <div className='w-4 h-4' onClick={() => handleStepView(element.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
              <path fillRule="evenodd" d="M8.34 1.804A1 1 0 0 1 9.32 1h1.36a1 1 0 0 1 .98.804l.295 1.473c.497.144.971.342 1.416.587l1.25-.834a1 1 0 0 1 1.262.125l.962.962a1 1 0 0 1 .125 1.262l-.834 1.25c.245.445.443.919.587 1.416l1.473.294a1 1 0 0 1 .804.98v1.361a1 1 0 0 1-.804.98l-1.473.295a6.95 6.95 0 0 1-.587 1.416l.834 1.25a1 1 0 0 1-.125 1.262l-.962.962a1 1 0 0 1-1.262.125l-1.25-.834a6.953 6.953 0 0 1-1.416.587l-.294 1.473a1 1 0 0 1-.98.804H9.32a1 1 0 0 1-.98-.804l-.295-1.473a6.957 6.957 0 0 1-1.416-.587l-1.25.834a1 1 0 0 1-1.262-.125l-.962-.962a1 1 0 0 1-.125-1.262l.834-1.25a6.957 6.957 0 0 1-.587-1.416l-1.473-.294A1 1 0 0 1 1 10.68V9.32a1 1 0 0 1 .804-.98l1.473-.295c.144-.497.342-.971.587-1.416l-.834-1.25a1 1 0 0 1 .125-1.262l.962-.962A1 1 0 0 1 5.38 3.03l1.25.834a6.957 6.957 0 0 1 1.416-.587l.294-1.473ZM13 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clipRule="evenodd" />
            </svg>
          </div>

          <div className='w-4 h-4' onClick={() => handleStepDelete(element.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
              <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clipRule="evenodd" />
            </svg>
          </div>

        </div>
        {
          error?.isError === true &&
          <>
            {error && isHovered && (
              <div className="absolute bottom-full left-0 mb-2 px-2 py-1 top-[-39px] bg-red-100 text-red-700 rounded shadow-md text-[13px]">
                {error.reason}
              </div>
            )}
          </>
        }
      </div>
    );
  };

  const renderEditableNumberText = (element: FormElement, index: number, field: keyof FormElement, label: string) => {
    const value = element[field] || '';
    const displayText = element.label || element.step_input_type || 'No Value';
    if (editingState.id === element.id && editingState.field === field) {
      return (
        <div className='flex items-start gap-2 p-2'>
          <div className='flex flex-col w-full gap-y-3'>
            {editingState.id === null && element.number ? (
            <div className='flex items-center gap-x-1'>
            <span className="cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 
         text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">{displayText} </span>
            {element?.step_mandatory === "yes" && <span className='text-red-500 '>*</span>}
          </div>
            ) : (
              <div className='flex items-center gap-x-1'>
              <span className="cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 
           text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">{displayText} </span>
              {element?.step_mandatory === "yes" && <span className='text-red-500 '>*</span>}
            </div>
            )}
            <input
              type="text"
              id={`input-${field}`}
              value={editingValue}
              onChange={(e) => setEditingValue(e.target.value)}
              onBlur={handleSave}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSave();
                }
              }}
              className="block w-full rounded-md border-0 py-1.5 px-2 text-[#121217] text-[14px] font-[500]
              shadow-sm ring-1 h-[36px] ring-inset ring-gray-300 placeholder:text-[#52555a] 
               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
              autoFocus
            />
            <input
              type="text"
              id={`input-${field}`}
              // value={editingValue}
              onChange={(e) => setEditingValue(e.target.value)}
              onBlur={handleSave}
              placeholder='Please enter your number'
              disabled

              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSave();
                }
              }}
              className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 h-[36px]
              shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2
               focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed
                disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-[13px] sm:leading-6"
              autoFocus
            />

          </div>
          <div className='w-4 h-4' onClick={() => handleStepView(element.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
              <path fillRule="evenodd" d="M8.34 1.804A1 1 0 0 1 9.32 1h1.36a1 1 0 0 1 .98.804l.295 1.473c.497.144.971.342 1.416.587l1.25-.834a1 1 0 0 1 1.262.125l.962.962a1 1 0 0 1 .125 1.262l-.834 1.25c.245.445.443.919.587 1.416l1.473.294a1 1 0 0 1 .804.98v1.361a1 1 0 0 1-.804.98l-1.473.295a6.95 6.95 0 0 1-.587 1.416l.834 1.25a1 1 0 0 1-.125 1.262l-.962.962a1 1 0 0 1-1.262.125l-1.25-.834a6.953 6.953 0 0 1-1.416.587l-.294 1.473a1 1 0 0 1-.98.804H9.32a1 1 0 0 1-.98-.804l-.295-1.473a6.957 6.957 0 0 1-1.416-.587l-1.25.834a1 1 0 0 1-1.262-.125l-.962-.962a1 1 0 0 1-.125-1.262l.834-1.25a6.957 6.957 0 0 1-.587-1.416l-1.473-.294A1 1 0 0 1 1 10.68V9.32a1 1 0 0 1 .804-.98l1.473-.295c.144-.497.342-.971.587-1.416l-.834-1.25a1 1 0 0 1 .125-1.262l.962-.962A1 1 0 0 1 5.38 3.03l1.25.834a6.957 6.957 0 0 1 1.416-.587l.294-1.473ZM13 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clipRule="evenodd" />
            </svg>
          </div>
          <div className='w-4 h-4' onClick={() => handleStepDelete(element.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
              <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clipRule="evenodd" />
            </svg>
          </div>
        </div>
      );
    }

    return (
      <div
        className="cursor-pointer flex items-start justify-between gap-2 p-2 relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className='flex flex-col w-full gap-y-3'>
          <span className=" cursor-pointer font-[500] text-[#121217] text-[14px] flex items-start gap-2 justify-between flex-col"
          >
          <div className='flex items-center gap-x-1'>
              <span className="cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 
           text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">{displayText} </span>
              {element?.step_mandatory === "yes" && <span className='text-red-500 '>*</span>}
            </div>
            {editingState.id === null && !element.number &&

              <div className='w-[94%]'>

                <div className='font-[500] text-[#121217] text-[14px]'
                  onClick={() => handleEdit(element.id, value.toString(), field)}>{element.headerText}</div>
              </div>
            }
          </span>
          <input
            type="number"
            id={`input-${field}`}
            value={editingValue}
            onChange={(e) => setEditingValue(e.target.value)}
            onBlur={handleSave}
            placeholder='Please enter your phone no.'
            disabled

            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSave();
              }
            }}
            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 h-[36px]
             shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2
              focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed
               disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-[13px] sm:leading-6"

            autoFocus
          />
        </div>
        <div className='flex items-center gap-2'>

          <div className='w-4 h-4' onClick={() => handleStepView(element.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
              <path fillRule="evenodd" d="M8.34 1.804A1 1 0 0 1 9.32 1h1.36a1 1 0 0 1 .98.804l.295 1.473c.497.144.971.342 1.416.587l1.25-.834a1 1 0 0 1 1.262.125l.962.962a1 1 0 0 1 .125 1.262l-.834 1.25c.245.445.443.919.587 1.416l1.473.294a1 1 0 0 1 .804.98v1.361a1 1 0 0 1-.804.98l-1.473.295a6.95 6.95 0 0 1-.587 1.416l.834 1.25a1 1 0 0 1-.125 1.262l-.962.962a1 1 0 0 1-1.262.125l-1.25-.834a6.953 6.953 0 0 1-1.416.587l-.294 1.473a1 1 0 0 1-.98.804H9.32a1 1 0 0 1-.98-.804l-.295-1.473a6.957 6.957 0 0 1-1.416-.587l-1.25.834a1 1 0 0 1-1.262-.125l-.962-.962a1 1 0 0 1-.125-1.262l.834-1.25a6.957 6.957 0 0 1-.587-1.416l-1.473-.294A1 1 0 0 1 1 10.68V9.32a1 1 0 0 1 .804-.98l1.473-.295c.144-.497.342-.971.587-1.416l-.834-1.25a1 1 0 0 1 .125-1.262l.962-.962A1 1 0 0 1 5.38 3.03l1.25.834a6.957 6.957 0 0 1 1.416-.587l.294-1.473ZM13 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clipRule="evenodd" />
            </svg>
          </div>

          <div className='w-4 h-4' onClick={() => handleStepDelete(element.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
              <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clipRule="evenodd" />
            </svg>
          </div>

        </div>
        {
          error?.isError === true &&
          <>
            {error && isHovered && (
              <div className="absolute bottom-full left-0 mb-2 px-2 py-1 top-[-39px] bg-red-100 text-red-700 rounded shadow-md text-[13px]">
                {error.reason}
              </div>
            )}
          </>
        }
      </div>
    );
  };


  const renderEditableLikertText = (element: FormElement, index: number, field: keyof FormElement) => {
    const displayText = element.label || element.step_input_type || 'No Value';


    const handleLikertOptionClick = (optionId: string, currentLabel: string) => {
      setEditingOptionId(optionId);
      setNewOptionLabel(currentLabel);
    };

    const handleLikertOptionSave = (optionId: string) => {
      setElements(prevElements => {
        return prevElements.map(el => {
          if (el.id === element.id) {
            const updatedElement = { ...el };
            updatedElement.options = updatedElement.options?.map(option => {
              if (option.id === optionId) {
                return { ...option, label: newOptionLabel };
              }
              return option;
            });
            return updatedElement;
          }
          return el;
        });
      });
      setEditingOptionId(null);
      setNewOptionLabel('');
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, optionId: string) => {
      if (event.key === 'Enter') {
        handleLikertOptionSave(optionId);
      }
    };

    return (
      <div className="flex flex-col gap-2 p-2 relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className='flex items-start justify-between'>
          <div className='flex flex-col gap-y-2 w-full'>

          <div className='flex items-center gap-x-1'>
              <span className="cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 
           text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">{displayText} </span>
              {element?.step_mandatory === "yes" && <span className='text-red-500 '>*</span>}
            </div>
            {editingState.id === element.id && editingState.field === 'headerText' ? (
              <input
                type="text"
                value={editingValue}
                onChange={(e) => setEditingValue(e.target.value)}
                onBlur={handleSave}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSave();
                  }
                }}
                className="block w-full rounded-md border-0 py-1.5 px-2 text-[#121217] text-[14px] font-[500]
              shadow-sm ring-1 h-[36px] ring-inset ring-gray-300 placeholder:text-[#52555a] 
               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                autoFocus
              />
            ) : (
              <>
                {editingState.id === null && !element.likert &&
                  <div className='w-[94%]' onClick={() => handleEdit(element.id, element.headerText || '', 'headerText')}>
                    <div className='font-[500] text-[#121217] text-[14px]'>
                      {element.headerText}
                    </div>
                  </div>
                }
              </>
            )}
          </div>
          <div className='flex items-center gap-2'>

            <div className='w-4 h-4' onClick={() => handleStepView(element.id)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
                <path fillRule="evenodd" d="M8.34 1.804A1 1 0 0 1 9.32 1h1.36a1 1 0 0 1 .98.804l.295 1.473c.497.144.971.342 1.416.587l1.25-.834a1 1 0 0 1 1.262.125l.962.962a1 1 0 0 1 .125 1.262l-.834 1.25c.245.445.443.919.587 1.416l1.473.294a1 1 0 0 1 .804.98v1.361a1 1 0 0 1-.804.98l-1.473.295a6.95 6.95 0 0 1-.587 1.416l.834 1.25a1 1 0 0 1-.125 1.262l-.962.962a1 1 0 0 1-1.262.125l-1.25-.834a6.953 6.953 0 0 1-1.416.587l-.294 1.473a1 1 0 0 1-.98.804H9.32a1 1 0 0 1-.98-.804l-.295-1.473a6.957 6.957 0 0 1-1.416-.587l-1.25.834a1 1 0 0 1-1.262-.125l-.962-.962a1 1 0 0 1-.125-1.262l.834-1.25a6.957 6.957 0 0 1-.587-1.416l-1.473-.294A1 1 0 0 1 1 10.68V9.32a1 1 0 0 1 .804-.98l1.473-.295c.144-.497.342-.971.587-1.416l-.834-1.25a1 1 0 0 1 .125-1.262l.962-.962A1 1 0 0 1 5.38 3.03l1.25.834a6.957 6.957 0 0 1 1.416-.587l.294-1.473ZM13 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clipRule="evenodd" />
              </svg>
            </div>

            <div className='w-4 h-4' onClick={() => handleStepDelete(element.id)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
                <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clipRule="evenodd" />
              </svg>
            </div>

          </div>
        </div>

        <div className="flex gap-2
         justify-start flex-wrap items-start">
          {element.options?.map((option) => (
            <div key={option.id} className="flex items-center space-x-2 relative group">
              {editingOptionId === option.id ? (
                <textarea
                  type="text"
                  value={newOptionLabel}
                  onChange={(e) => setNewOptionLabel(e.target.value)}
                  onBlur={() => handleLikertOptionSave(option.id)}
                  // ref={textareaRef1}
                  maxLength={18}
                  onKeyDown={(e) => handleKeyPress(e, option.id)}
                  className="rounded-md likertselect border-0 py-[6px]  w-[100px] !h-[50px] flex-wrap flex px-2 text-[#121217] text-[13px]
                   font-[500] shadow-sm ring-1  ring-inset ring-gray-300 placeholder:text-[#52555a] focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-[16px] leading-4"
                  autoFocus

                />
              ) : (
                <div
                  onClick={() => handleLikertOptionClick(option.id, option.label)}
                  className={`py-2 px-4 text-[13px] relative w-[100px] h-[50px] text-center flex justify-center items-center   leading-4 font-normal border rounded-lg ${option.selected
                    ? 'bg-gray-200 text-dark-black-200'
                    : 'bg-white text-dark-black-200 border-gray-300 hover:bg-gray-100'
                    }`}
                  // ref={textareaRef2}
                  style={{ overflowWrap: 'anywhere' }}
                >
                  {option.label}
                </div>
              )}
              <button
                onClick={() => handleRemoveOption(element.id, option.id)}
                className={`text-red-500 absolute top-[-6px] right-[-6px] opacity-0 group-hover:opacity-100 transition-opacity duration-300`}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                  className="h-4 w-4 text-[#758482]">
                  <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clipRule="evenodd" />
                </svg>
              </button>
            </div>
          ))}
        </div>
        <div className="mt-4 flex space-x-2 w-full justify-end ">
          <Button onClick={() => handleAddOption(element.id)}
            variant="outline" className='text-[#758482] text-[13px] font-normal h-[28px]'>
            Add
          </Button>

        </div>
        {
          error?.isError === true &&
          <>
            {error && isHovered && (
              <div className="absolute bottom-full left-0 mb-2 px-2 py-1 top-[-39px] bg-red-100 text-red-700 rounded shadow-md text-[13px]">
                {error.reason}
              </div>
            )}
          </>
        }
      </div>
    );
  };

  const renderEditableTextScaleText = (element: FormElement, index: number, field: keyof FormElement) => {

    const displayText = element.label || element.step_input_type || 'No Value';

    const handleLikertOptionClick = (optionId: string, currentLabel: string) => {
      setEditingOptionId(optionId);
      setNewOptionLabel(currentLabel);
    };

    const handleLikertOptionSave = (optionId: string) => {
      setElements(prevElements => {
        return prevElements.map(el => {
          if (el.id === element.id) {
            const updatedElement = { ...el };
            updatedElement.options = updatedElement.options?.map(option => {
              if (option.id === optionId) {
                return { ...option, label: newOptionLabel };
              }
              return option;
            });
            return updatedElement;
          }
          return el;
        });
      });
      setEditingOptionId(null);
      setNewOptionLabel('');
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, optionId: string) => {
      if (event.key === 'Enter') {
        handleLikertOptionSave(optionId);
      }
    };

    return (
      <div className="flex flex-col gap-2 p-2 relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className='flex items-start justify-between'>
          <div className='flex flex-col gap-y-2 w-full'>
          <div className='flex items-center gap-x-1'>
              <span className="cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 
           text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">{displayText} </span>
              {element?.step_mandatory === "yes" && <span className='text-red-500 '>*</span>}
            </div>
            {editingState.id === element.id && editingState.field === 'headerText' ? (
              <input
                type="text"
                value={editingValue}
                onChange={(e) => setEditingValue(e.target.value)}
                onBlur={handleSave}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSave();
                  }
                }}
                className="block w-full rounded-md border-0 py-1.5 px-2 text-[#121217] text-[14px] font-[500]
              shadow-sm ring-1 h-[36px] ring-inset ring-gray-300 placeholder:text-[#52555a] 
               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                autoFocus
              />
            ) : (
              <>
                {editingState.id === null && !element.numberscale &&
                  <div className='w-[94%]' onClick={() => handleEdit(element.id, element.headerText || '', 'headerText')}>
                    <div className='font-[500] text-[#121217] text-[14px]'>
                      {element.headerText}
                    </div>
                  </div>
                }
              </>
            )}
          </div>
          <div className='flex items-center gap-2'>

            <div className='w-4 h-4' onClick={() => handleStepView(element.id)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
                <path fillRule="evenodd" d="M8.34 1.804A1 1 0 0 1 9.32 1h1.36a1 1 0 0 1 .98.804l.295 1.473c.497.144.971.342 1.416.587l1.25-.834a1 1 0 0 1 1.262.125l.962.962a1 1 0 0 1 .125 1.262l-.834 1.25c.245.445.443.919.587 1.416l1.473.294a1 1 0 0 1 .804.98v1.361a1 1 0 0 1-.804.98l-1.473.295a6.95 6.95 0 0 1-.587 1.416l.834 1.25a1 1 0 0 1-.125 1.262l-.962.962a1 1 0 0 1-1.262.125l-1.25-.834a6.953 6.953 0 0 1-1.416.587l-.294 1.473a1 1 0 0 1-.98.804H9.32a1 1 0 0 1-.98-.804l-.295-1.473a6.957 6.957 0 0 1-1.416-.587l-1.25.834a1 1 0 0 1-1.262-.125l-.962-.962a1 1 0 0 1-.125-1.262l.834-1.25a6.957 6.957 0 0 1-.587-1.416l-1.473-.294A1 1 0 0 1 1 10.68V9.32a1 1 0 0 1 .804-.98l1.473-.295c.144-.497.342-.971.587-1.416l-.834-1.25a1 1 0 0 1 .125-1.262l.962-.962A1 1 0 0 1 5.38 3.03l1.25.834a6.957 6.957 0 0 1 1.416-.587l.294-1.473ZM13 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clipRule="evenodd" />
              </svg>
            </div>

            <div className='w-4 h-4' onClick={() => handleStepDelete(element.id)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
                <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clipRule="evenodd" />
              </svg>
            </div>
          </div>
        </div>

        <div className="flex
         justify-start gap-2 flex-wrap items-start">
          {element.options?.map((option) => (
            <div key={option.id} className="flex items-center space-x-2 relative group">
              {editingOptionId === option.id ? (
                <textarea
                  type="number"
                  value={newOptionLabel}
                  onChange={(e) => setNewOptionLabel(e.target.value)}
                  onBlur={() => handleLikertOptionSave(option.id)}
                  // ref={textareaRef2}
                  maxLength={5}
                  onKeyDown={(e) => handleKeyPress(e, option.id)}
                  className=" rounded-lg likertselect border-0 py-[6px]  w-[50px] !h-[50px] flex-wrap flex px-2 text-[#121217] text-[13px]
                   font-[500] shadow-sm ring-1  ring-inset ring-gray-300 placeholder:text-[#52555a] focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-[36px] leading-8"
                  autoFocus
                />
              ) : (
                <div
                  onClick={() => handleLikertOptionClick(option.id, option.label)}
                  className={`py-2 px-4 text-[13px] rounded-lg relative w-[50px] h-[50px] flex items-center justify-center text-center leading-4 font-normal border ${option.selected
                    ? 'bg-gray-200 text-dark-black-200'
                    : 'bg-white text-dark-black-200 border-gray-300 hover:bg-gray-100'
                    }`}
                >
                  {option.label}
                </div>
              )}
              <button
                onClick={() => handleRemoveOption(element.id, option.id)}
                className={`text-red-500 absolute top-[-6px] right-[-6px] opacity-0 group-hover:opacity-100 transition-opacity duration-300`}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                  className="h-4 w-4 text-[#758482]">
                  <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clipRule="evenodd" />
                </svg>
              </button>
            </div>
          ))}
        </div>
        <div className="mt-4 flex space-x-2 w-full justify-end ">
          <Button onClick={() => handleAddOption1(element.id)}
            variant="outline" className='text-[#758482] text-[13px] font-normal h-[28px]'>
            Add
          </Button>

        </div>
        {
          error?.isError === true &&
          <>
            {error && isHovered && (
              <div className="absolute bottom-full left-0 mb-2 px-2 py-1 top-[-39px] bg-red-100 text-red-700 rounded shadow-md text-[13px]">
                {error.reason}
              </div>
            )}
          </>
        }
      </div>
    );
  };

  const renderEditableDropdownText = (element: FormElement, index: number, field: keyof FormElement, label: string) => {
    const value = element[field] || '';
    const displayText = element.label || element.step_input_type || 'No Value';

    if (editingState.id === element.id && editingState.field === field) {
      return (
        <div className='flex items-start gap-2 p-2'>
          <div className='flex flex-col w-full gap-y-3'>
            {editingState.id === null && element.options ? (
            <div className='flex items-center gap-x-1'>
            <span className="cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 
         text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">{displayText} </span>
            {element?.step_mandatory === "yes" && <span className='text-red-500 '>*</span>}
          </div>
            ) : (
              <div className='flex items-center gap-x-1'>
              <span className="cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 
           text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">{displayText} </span>
              {element?.step_mandatory === "yes" && <span className='text-red-500 '>*</span>}
            </div>
            )}
            <input
              type="text"
              value={editingValue}
              onChange={(e) => setEditingValue(e.target.value)}
              onBlur={handleSave}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSave();
                }
              }}
              className="block w-full rounded-md border-0 py-1.5 px-2 text-[#121217] text-[14px] font-[500]
              shadow-sm ring-1 h-[36px] ring-inset ring-gray-300 placeholder:text-[#52555a] 
               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
              autoFocus
            />
          </div>
        </div>
      );
    }

    return (
      <div className="cursor-pointer flex flex-col items-start justify-between gap-2 p-2 relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span className="justify-between items-start flex w-full">
          <div className='flex flex-col gap-y-2 w-full'>
          <div className='flex items-center gap-x-1'>
              <span className="cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 
           text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">{displayText} </span>
              {element?.step_mandatory === "yes" && <span className='text-red-500 '>*</span>}
            </div>

            {editingState.id === element.id && editingState.field === 'headerText' ? (
              <input
                type="text"
                value={editingValue}
                onChange={(e) => setEditingValue(e.target.value)}
                onBlur={handleSave}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSave();
                  }
                }}
                className="block w-full rounded-md border-0 py-1.5 px-2 text-[#121217] text-[14px] font-[500]
              shadow-sm ring-1 h-[36px] ring-inset ring-gray-300 placeholder:text-[#52555a] 
               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                autoFocus
              />
            ) : (
              <>
                {editingState.id === null && !element.radio &&
                  <div className='w-[94%]' onClick={() => handleEdit(element.id, element.headerText || '', 'headerText')}>
                    <div className='font-[500] text-[#121217] text-[14px]'>
                      {element.headerText}
                    </div>
                  </div>
                }
              </>
            )}

          </div>

          <div className='flex items-center gap-2 mt-[6px] '>

            <div className='w-4 h-4' onClick={() => handleStepView(element.id)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
                <path fillRule="evenodd" d="M8.34 1.804A1 1 0 0 1 9.32 1h1.36a1 1 0 0 1 .98.804l.295 1.473c.497.144.971.342 1.416.587l1.25-.834a1 1 0 0 1 1.262.125l.962.962a1 1 0 0 1 .125 1.262l-.834 1.25c.245.445.443.919.587 1.416l1.473.294a1 1 0 0 1 .804.98v1.361a1 1 0 0 1-.804.98l-1.473.295a6.95 6.95 0 0 1-.587 1.416l.834 1.25a1 1 0 0 1-.125 1.262l-.962.962a1 1 0 0 1-1.262.125l-1.25-.834a6.953 6.953 0 0 1-1.416.587l-.294 1.473a1 1 0 0 1-.98.804H9.32a1 1 0 0 1-.98-.804l-.295-1.473a6.957 6.957 0 0 1-1.416-.587l-1.25.834a1 1 0 0 1-1.262-.125l-.962-.962a1 1 0 0 1-.125-1.262l.834-1.25a6.957 6.957 0 0 1-.587-1.416l-1.473-.294A1 1 0 0 1 1 10.68V9.32a1 1 0 0 1 .804-.98l1.473-.295c.144-.497.342-.971.587-1.416l-.834-1.25a1 1 0 0 1 .125-1.262l.962-.962A1 1 0 0 1 5.38 3.03l1.25.834a6.957 6.957 0 0 1 1.416-.587l.294-1.473ZM13 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clipRule="evenodd" />
              </svg>
            </div>
            <div className='w-4 h-4' onClick={() => handleStepDelete(element.id)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
                <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clipRule="evenodd" />
              </svg>
            </div>

          </div>
        </span>
        <div className="space-y-2 w-full">
          {element.options?.map((option, optionIndex) => (
            <div
              key={option.id}
              className={`flex items-center space-x-2 justify-between group`}
            >
              <div className='flex items-center gap-x-2 w-full custom-integration-radio'>
                <input
                  name={`radio-group-${element.id}`}
                  checked={option.selected}
                  onChange={() => handleOptionClick(element.id, option.id)}
                  type="radio"
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 hidden"
                  aria-describedby="comments-description"
                />
                <label htmlFor={`radio-${option.id}`} className="custom-radio" />

                {editingOptionId === option.id ? (
                  <div className=' w-full'>
                    <input
                      type="text"
                      name={`radio-group-${element.id}`}
                      className="block rounded-md border-0 py-0 h-auto  w-full
                       px-2 text-[#000] text-[13px] font-[500] shadow-sm ring-1 
                        ring-inset ring-gray-300 placeholder:text-[#52555a] focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                      value={editingValue}
                      onChange={(e) => setEditingValue(e.target.value)}
                      onBlur={() => handleOptionSave(element.id)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleOptionSave(element.id);
                        }
                      }}
                      autoFocus
                    />
                  </div>
                ) : (
                  <span
                    className="flex-grow font-[400] text-dark-black-200 text-[13px] capitalize"
                    onClick={() => handleOptionEdit(element.id, option.id, option.label)}
                  >
                    {option.label}
                  </span>
                )}
              </div>
              <button
                onClick={() => handleRemoveOption(element.id, option.id)}
                className={`text-red-500 opacity-0 group-hover:opacity-100 transition-opacity duration-300`}
              >
                <XMarkIcon className="h-3 w-3 text-[#758482]" aria-hidden="true" />
              </button>
            </div>
          ))}

        </div>
        <div className="mt-4 flex space-x-2 w-full justify-end">
          <Button onClick={() => handleAddOption(element.id)}
            variant="outline" className='text-[#758482] text-[13px] font-normal h-[28px]'>
            Add
          </Button>
        </div>
        {
          error?.isError === true &&
          <>
            {error && isHovered && (
              <div className="absolute bottom-full left-0 mb-2 px-2 py-1 top-[-39px] bg-red-100 text-red-700 rounded shadow-md text-[13px]">
                {error.reason}
              </div>
            )}
          </>
        }
      </div>
    );
  };


  const renderEditableRadioText = (element: FormElement, index: number, field: keyof FormElement, label: string) => {
    const value = element[field] || '';
    const displayText = element.label || element.step_input_type || 'No Value';

    if (editingState.id === element.id && editingState.field === field) {
      return (
        <div className='flex items-start gap-2 p-2'>
          <div className='flex flex-col w-full gap-y-3'>
            {editingState.id === null && element.options ? (
             <div className='flex items-center gap-x-1'>
             <span className="cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 
          text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">{displayText} </span>
             {element?.step_mandatory === "yes" && <span className='text-red-500 '>*</span>}
           </div>
            ) : (
              <div className='flex items-center gap-x-1'>
              <span className="cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 
           text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">{displayText} </span>
              {element?.step_mandatory === "yes" && <span className='text-red-500 '>*</span>}
            </div>
            )}
            <input
              type="text"
              value={editingValue}
              onChange={(e) => setEditingValue(e.target.value)}
              onBlur={handleSave}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSave();
                }
              }}
              className="block w-full rounded-md border-0 py-1.5 px-2 text-[#121217] text-[14px] font-[500]
              shadow-sm ring-1 h-[36px] ring-inset ring-gray-300 placeholder:text-[#52555a] 
               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
              autoFocus
            />
          </div>
        </div>
      );
    }

    return (
      <div className="cursor-pointer flex flex-col items-start justify-between gap-2 p-2 relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span className="justify-between items-start flex w-full">
          <div className='flex flex-col gap-y-2 w-full'>
          <div className='flex items-center gap-x-1'>
              <span className="cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 
           text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">{displayText} </span>
              {element?.step_mandatory === "yes" && <span className='text-red-500 '>*</span>}
            </div>

            {editingState.id === element.id && editingState.field === 'headerText' ? (
              <input
                type="text"
                value={editingValue}
                onChange={(e) => setEditingValue(e.target.value)}
                onBlur={handleSave}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSave();
                  }
                }}
                className="block w-full rounded-md border-0 py-1.5 px-2 text-[#121217] text-[14px] font-[500]
              shadow-sm ring-1 h-[36px] ring-inset ring-gray-300 placeholder:text-[#52555a] 
               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                autoFocus
              />
            ) : (
              <>
                {editingState.id === null && !element.radio &&
                  <div className='w-[94%]' onClick={() => handleEdit(element.id, element.headerText || '', 'headerText')}>
                    <div className='font-[500] text-[#121217] text-[14px]'>
                      {element.headerText}
                    </div>
                  </div>
                }
              </>
            )}

          </div>

          <div className='flex items-center gap-2 mt-[6px] '>

            <div className='w-4 h-4' onClick={() => handleStepView(element.id)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
                <path fillRule="evenodd" d="M8.34 1.804A1 1 0 0 1 9.32 1h1.36a1 1 0 0 1 .98.804l.295 1.473c.497.144.971.342 1.416.587l1.25-.834a1 1 0 0 1 1.262.125l.962.962a1 1 0 0 1 .125 1.262l-.834 1.25c.245.445.443.919.587 1.416l1.473.294a1 1 0 0 1 .804.98v1.361a1 1 0 0 1-.804.98l-1.473.295a6.95 6.95 0 0 1-.587 1.416l.834 1.25a1 1 0 0 1-.125 1.262l-.962.962a1 1 0 0 1-1.262.125l-1.25-.834a6.953 6.953 0 0 1-1.416.587l-.294 1.473a1 1 0 0 1-.98.804H9.32a1 1 0 0 1-.98-.804l-.295-1.473a6.957 6.957 0 0 1-1.416-.587l-1.25.834a1 1 0 0 1-1.262-.125l-.962-.962a1 1 0 0 1-.125-1.262l.834-1.25a6.957 6.957 0 0 1-.587-1.416l-1.473-.294A1 1 0 0 1 1 10.68V9.32a1 1 0 0 1 .804-.98l1.473-.295c.144-.497.342-.971.587-1.416l-.834-1.25a1 1 0 0 1 .125-1.262l.962-.962A1 1 0 0 1 5.38 3.03l1.25.834a6.957 6.957 0 0 1 1.416-.587l.294-1.473ZM13 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clipRule="evenodd" />
              </svg>
            </div>

            <div className='w-4 h-4' onClick={() => handleStepDelete(element.id)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
                <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clipRule="evenodd" />
              </svg>
            </div>

          </div>
        </span>
        <div className="space-y-2 w-full">
          {element.options?.map((option, optionIndex) => (
            <div
              key={option.id}
              className={`flex items-center space-x-2 justify-between group`}
            >
              <div className='flex items-center gap-x-2 w-full'>
                <input
                  name={`radio-group-${element.id}`}
                  checked={option.selected}
                  onChange={() => handleOptionClick(element.id, option.id)}
                  type="radio"
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  aria-describedby="comments-description"
                />
                {editingOptionId === option.id ? (
                  <div className=' w-full'>
                    <input
                      type="text"
                      name={`radio-group-${element.id}`}
                      className="block rounded-md border-0 py-0 h-auto  w-full
                       px-2 text-[#000] text-[13px] font-[500] shadow-sm ring-1 
                        ring-inset ring-gray-300 placeholder:text-[#52555a] focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                      value={editingValue}
                      onChange={(e) => setEditingValue(e.target.value)}
                      onBlur={() => handleOptionSave(element.id)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleOptionSave(element.id);
                        }
                      }}
                      autoFocus
                    />
                  </div>
                ) : (
                  <span
                    className="flex-grow font-[400] text-dark-black-200 text-[13px] capitalize"
                    onClick={() => handleOptionEdit(element.id, option.id, option.label)}
                  >
                    {option.label}
                  </span>
                )}

              </div>
              <button
                onClick={() => handleRemoveOption(element.id, option.id)}
                className={`text-red-500 opacity-0 group-hover:opacity-100 transition-opacity duration-300`}
              >
                <XMarkIcon className="h-3 w-3 text-[#758482]" aria-hidden="true" />
              </button>
            </div>
          ))}

        </div>

        <div className="mt-4 flex space-x-2 w-full justify-end">
          <Button onClick={() => handleAddOption(element.id)}
            variant="outline" className='text-[#758482] text-[13px] font-normal h-[28px]'>
            Add
          </Button>
        </div>
        {
          error?.isError === true &&
          <>
            {error && isHovered && (
              <div className="absolute bottom-full left-0 mb-2 px-2 py-1 top-[-39px] bg-red-100 text-red-700 rounded shadow-md text-[13px]">
                {error.reason}
              </div>
            )}
          </>
        }
      </div>
    );
  };


  const renderEditableMultiSelect = (element: FormElement, index: number, field: keyof FormElement, label: string) => {
    const value = element[field] || '';
    const displayText = element.label || element.step_input_type || 'No Value';

    if (editingState.id === element.id && editingState.field === field) {
      return (
        <div className='flex items-start gap-2'>
          <div className='flex flex-col w-full gap-y-3'>
            {editingState.id === null && element.options ? (
                <div className='flex items-center gap-x-1'>
                <span className="cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 
             text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">{displayText} </span>
                {element?.step_mandatory === "yes" && <span className='text-red-500 '>*</span>}
              </div>
            ) : (
              <div className='flex items-center gap-x-1'>
              <span className="cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 
           text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">{displayText} </span>
              {element?.step_mandatory === "yes" && <span className='text-red-500 '>*</span>}
            </div>
            )}
            <input
              type="text"
              value={editingValue}
              onChange={(e) => setEditingValue(e.target.value)}
              onBlur={handleSave}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSave();
                }
              }}
              className="block w-full rounded-md border-0 py-1.5 px-2 text-[#121217] text-[14px] font-[500] shadow-sm ring-1 h-[36px] ring-inset ring-gray-300 placeholder:text-[#52555a] focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
              autoFocus
            />
          </div>
        </div>
      );
    }

    return (
      <div className="cursor-pointer flex flex-col items-start justify-between gap-2 p-2 relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span className="justify-between items-start flex w-full">
          <div className='flex flex-col gap-y-2 w-full'>
          <div className='flex items-center gap-x-1'>
              <span className="cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 
           text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">{displayText} </span>
              {element?.step_mandatory === "yes" && <span className='text-red-500 '>*</span>}
            </div>
            {editingState.id === element.id && editingState.field === 'headerText' ? (
              <input
                type="text"
                value={editingValue}
                onChange={(e) => setEditingValue(e.target.value)}
                onBlur={handleSave}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSave();
                  }
                }}
                className="block w-full rounded-md border-0 py-1.5 px-2 text-[#121217] text-[14px] font-[500]
              shadow-sm ring-1 h-[36px] ring-inset ring-gray-300 placeholder:text-[#52555a] 
               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                autoFocus
              />
            ) : (
              <>
                {editingState.id === null && !element.checkbox &&
                  <div className='w-[94%]' onClick={() => handleEdit(element.id, element.headerText || '', 'headerText')}>
                    <div className='font-[500] text-[#121217] text-[14px]'>
                      {element.headerText}
                    </div>
                  </div>
                }
              </>
            )}

          </div>

          <div className='flex items-center gap-2 mt-[6px] '>

            <div className='w-4 h-4' onClick={() => handleStepView(element.id)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
                <path fillRule="evenodd" d="M8.34 1.804A1 1 0 0 1 9.32 1h1.36a1 1 0 0 1 .98.804l.295 1.473c.497.144.971.342 1.416.587l1.25-.834a1 1 0 0 1 1.262.125l.962.962a1 1 0 0 1 .125 1.262l-.834 1.25c.245.445.443.919.587 1.416l1.473.294a1 1 0 0 1 .804.98v1.361a1 1 0 0 1-.804.98l-1.473.295a6.95 6.95 0 0 1-.587 1.416l.834 1.25a1 1 0 0 1-.125 1.262l-.962.962a1 1 0 0 1-1.262.125l-1.25-.834a6.953 6.953 0 0 1-1.416.587l-.294 1.473a1 1 0 0 1-.98.804H9.32a1 1 0 0 1-.98-.804l-.295-1.473a6.957 6.957 0 0 1-1.416-.587l-1.25.834a1 1 0 0 1-1.262-.125l-.962-.962a1 1 0 0 1-.125-1.262l.834-1.25a6.957 6.957 0 0 1-.587-1.416l-1.473-.294A1 1 0 0 1 1 10.68V9.32a1 1 0 0 1 .804-.98l1.473-.295c.144-.497.342-.971.587-1.416l-.834-1.25a1 1 0 0 1 .125-1.262l.962-.962A1 1 0 0 1 5.38 3.03l1.25.834a6.957 6.957 0 0 1 1.416-.587l.294-1.473ZM13 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clipRule="evenodd" />
              </svg>
            </div>

            <div className='w-4 h-4' onClick={() => handleStepDelete(element.id)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
                <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clipRule="evenodd" />
              </svg>
            </div>

          </div>
        </span>
        <div className="space-y-2 w-full">
          {element.options?.map((option, optionIndex) => (
            <div
              key={option.id}
              className={`flex items-center space-x-2 justify-between group`}
            >
              <div className='flex items-center gap-x-2 w-full'>
                <input
                  checked={option.selected}
                  onChange={() => handleOptionClick(element.id, option.id)}
                  type="checkbox"
                  aria-describedby="comments-description"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                {editingOptionId === option.id ? (
                  <div className=' w-full'>
                    <input
                      type="text"
                      value={editingValue}
                      onChange={(e) => setEditingValue(e.target.value)}
                      onBlur={() => handleOptionSave(element.id)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleOptionSave(element.id);
                        }
                      }}
                      className="block rounded-md border-0 py-0 h-auto w-full px-2 text-[#121217] text-[14px] font-[500] shadow-sm ring-1  ring-inset ring-gray-300 placeholder:text-[#52555a] focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                      autoFocus
                    />
                  </div>
                ) : (
                  <span
                    className="flex-grow font-[400] text-dark-black-200 text-[13px] capitalize"
                    onClick={() => handleOptionEdit(element.id, option.id, option.label)}
                  >
                    {option.label}
                  </span>
                )}
              </div>
              <button
                onClick={() => handleRemoveOption(element.id, option.id)}
                className={`text-red-500 opacity-0 group-hover:opacity-100 transition-opacity duration-300`}
              >
                <XMarkIcon className="h-3 w-3 text-[#758482]" aria-hidden="true" />

              </button>
            </div>
          ))}

        </div>

        <div className="mt-4 flex space-x-2 w-full justify-end">
          <Button onClick={() => handleAddOption(element.id)}
            variant="outline" className='text-[#758482] text-[13px] font-normal h-[28px]'>
            Add
          </Button>

        </div>
        {
          error?.isError === true &&
          <>
            {error && isHovered && (
              <div className="absolute bottom-full left-0 mb-2 px-2 py-1 top-[-39px] bg-red-100 text-red-700 rounded shadow-md text-[13px]">
                {error.reason}
              </div>
            )}
          </>
        }
      </div>
    );
  };

  const renderEditableRatingText = (element: FormElement, index: number, field: keyof FormElement, label: string) => {
    const displayText = element.label || element.step_input_type || 'No Value';

    const handleRatingChange = (rating: number) => {
      setElements(prevElements =>
        prevElements.map((el, i) =>
          i === index ? { ...el, value: rating.toString() } : el
        )
      );
      setRating(rating);
    };

    return (
      <div className="flex items-start flex-col gap-2 mb-0 p-2 relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span className="justify-between items-start flex w-full">
          <div className='flex flex-col gap-y-2 w-full'>
          <div className='flex items-center gap-x-1'>
              <span className="cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 
           text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">{displayText} </span>
              {element?.step_mandatory === "yes" && <span className='text-red-500 '>*</span>}
            </div>
            {editingState.id === element.id && editingState.field === 'headerText' ? (
              <input
                type="text"
                value={editingValue}
                onChange={(e) => setEditingValue(e.target.value)}
                onBlur={handleSave}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSave();
                  }
                }}
                className="block w-full rounded-md border-0 py-1.5 px-2 text-[#121217] text-[14px] font-[500]
              shadow-sm ring-1 h-[36px] ring-inset ring-gray-300 placeholder:text-[#52555a] 
               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                autoFocus
              />
            ) : (
              <>
                {editingState.id === null && !element.star &&
                  <div className='w-[94%]' onClick={() => handleEdit(element.id, element.headerText || '', 'headerText')}>
                    <div className='font-[500] text-[#121217] text-[14px]'>
                      {element.headerText}
                    </div>
                  </div>
                }
              </>
            )}
          </div>

          <div className='flex items-center gap-2 mt-[6px] '>

            <div className='w-4 h-4' onClick={() => handleStepView(element.id)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
                <path fillRule="evenodd" d="M8.34 1.804A1 1 0 0 1 9.32 1h1.36a1 1 0 0 1 .98.804l.295 1.473c.497.144.971.342 1.416.587l1.25-.834a1 1 0 0 1 1.262.125l.962.962a1 1 0 0 1 .125 1.262l-.834 1.25c.245.445.443.919.587 1.416l1.473.294a1 1 0 0 1 .804.98v1.361a1 1 0 0 1-.804.98l-1.473.295a6.95 6.95 0 0 1-.587 1.416l.834 1.25a1 1 0 0 1-.125 1.262l-.962.962a1 1 0 0 1-1.262.125l-1.25-.834a6.953 6.953 0 0 1-1.416.587l-.294 1.473a1 1 0 0 1-.98.804H9.32a1 1 0 0 1-.98-.804l-.295-1.473a6.957 6.957 0 0 1-1.416-.587l-1.25.834a1 1 0 0 1-1.262-.125l-.962-.962a1 1 0 0 1-.125-1.262l.834-1.25a6.957 6.957 0 0 1-.587-1.416l-1.473-.294A1 1 0 0 1 1 10.68V9.32a1 1 0 0 1 .804-.98l1.473-.295c.144-.497.342-.971.587-1.416l-.834-1.25a1 1 0 0 1 .125-1.262l.962-.962A1 1 0 0 1 5.38 3.03l1.25.834a6.957 6.957 0 0 1 1.416-.587l.294-1.473ZM13 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clipRule="evenodd" />
              </svg>
            </div>
            <div className='w-4 h-4' onClick={() => handleStepDelete(element.id)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
                <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clipRule="evenodd" />
              </svg>
            </div>
          </div>
        </span>
        <div className='flex justify-start items-start'>
          <StarRating
            value={Number(element.value) || rating}
            onChange={handleRatingChange}
          />
        </div>

        {
          error?.isError === true &&
          <>
            {error && isHovered && (
              <div className="absolute bottom-full left-0 mb-2 px-2 py-1 top-[-39px] bg-red-100 text-red-700 rounded shadow-md text-[13px]">
                {error.reason}
              </div>
            )}
          </>
        }
      </div>
    );
  };


  const renderEditableSmileyText = (
    element: FormElement,
    index: number,
    field: keyof FormElement,
    label: string
  ) => {
    const displayText = element.label || element.step_input_type || 'No Value';

    const handleSmileySelect = (rating: number) => {

      setElements(prevElements =>
        prevElements.map((el, i) =>
          i === index ? { ...el, value: rating.toString() } : el
        )
      );
      setSelectedSmiley(rating);
    };


    return (
      <div
        className="flex items-start flex-col gap-2 mb-0 p-2 relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span className="justify-between items-start flex w-full gap-2">
          <div className="flex flex-col gap-y-2 w-full">
          <div className='flex items-center gap-x-1'>
              <span className="cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 
           text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">{displayText} </span>
              {element?.step_mandatory === "yes" && <span className='text-red-500 '>*</span>}
            </div>
            {editingState.id === element.id && editingState.field === 'headerText' ? (
              <input
                type="text"
                value={editingValue}
                onChange={(e) => setEditingValue(e.target.value)}
                onBlur={handleSave}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSave();
                  }
                }}
                className="block w-full rounded-md border-0 py-1.5 px-2 text-[#121217] text-[14px] font-[500]
              shadow-sm ring-1 h-[36px] ring-inset ring-gray-300 placeholder:text-[#52555a] 
               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                autoFocus
              />
            ) : (
              <>
                {editingState.id === null && !element.thumbsup &&
                  <div className='w-[94%]' onClick={() => handleEdit(element.id, element.headerText || '', 'headerText')}>
                    <div className='font-[500] text-[#121217] text-[14px]'>
                      {element.headerText}
                    </div>
                  </div>
                }
              </>
            )}
          </div>

          <div className='flex items-center gap-2 mt-[6px] '>
            <div className='w-4 h-4' onClick={() => handleStepView(element.id)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
                <path fillRule="evenodd" d="M8.34 1.804A1 1 0 0 1 9.32 1h1.36a1 1 0 0 1 .98.804l.295 1.473c.497.144.971.342 1.416.587l1.25-.834a1 1 0 0 1 1.262.125l.962.962a1 1 0 0 1 .125 1.262l-.834 1.25c.245.445.443.919.587 1.416l1.473.294a1 1 0 0 1 .804.98v1.361a1 1 0 0 1-.804.98l-1.473.295a6.95 6.95 0 0 1-.587 1.416l.834 1.25a1 1 0 0 1-.125 1.262l-.962.962a1 1 0 0 1-1.262.125l-1.25-.834a6.953 6.953 0 0 1-1.416.587l-.294 1.473a1 1 0 0 1-.98.804H9.32a1 1 0 0 1-.98-.804l-.295-1.473a6.957 6.957 0 0 1-1.416-.587l-1.25.834a1 1 0 0 1-1.262-.125l-.962-.962a1 1 0 0 1-.125-1.262l.834-1.25a6.957 6.957 0 0 1-.587-1.416l-1.473-.294A1 1 0 0 1 1 10.68V9.32a1 1 0 0 1 .804-.98l1.473-.295c.144-.497.342-.971.587-1.416l-.834-1.25a1 1 0 0 1 .125-1.262l.962-.962A1 1 0 0 1 5.38 3.03l1.25.834a6.957 6.957 0 0 1 1.416-.587l.294-1.473ZM13 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clipRule="evenodd" />
              </svg>
            </div>
            <div className='w-4 h-4' onClick={() => handleStepDelete(element.id)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
                <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clipRule="evenodd" />
              </svg>
            </div>
          </div>
        </span>
        <div className="w-full flex items-center gap-2">
          {element.options?.map((option) => (
            <div key={option.id} className="flex gap-x-2 flex-col items-center">
              <SmileyRating
                type={option.type as 'sad' | 'ok' | 'happy'}
                isSelected={selectedSmiley === option.type}
                onSelect={() => handleSmileySelect(option.type)}
                value={option.type}
              />
            </div>
          ))}
        </div>
        {error?.isError && isHovered && (
          <div className="absolute bottom-full left-0 mb-2 px-2 py-1 top-[-39px] bg-red-100 text-red-700 rounded shadow-md text-[13px]">
            {error.errorMessage}
          </div>
        )}
      </div>
    );
  };

  const renderEditableThumbText = (
    element: FormElement,
    index: number,
    field: keyof FormElement,
    label: string
  ) => {
    const displayText = element.label || element.step_input_type || 'No Value';

    const handleThumbSelect = (value: number) => {
      setSelectedThumb(value);
    };
  

    return (
      <div
        className="flex items-start flex-col gap-2 mb-0 p-2 relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span className="justify-between items-start flex w-full gap-2">
          <div className="flex flex-col gap-y-2 w-full">
          <div className='flex items-center gap-x-1'>
              <span className="cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 
           text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">{displayText} </span>
              {element?.step_mandatory === "yes" && <span className='text-red-500 '>*</span>}
            </div>
            {editingState.id === element.id && editingState.field === 'headerText' ? (
              <input
                type="text"
                value={editingValue}
                onChange={(e) => setEditingValue(e.target.value)}
                onBlur={handleSave}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSave();
                  }
                }}
                className="block w-full rounded-md border-0 py-1.5 px-2 text-[#121217] text-[14px] font-[500]
              shadow-sm ring-1 h-[36px] ring-inset ring-gray-300 placeholder:text-[#52555a] 
               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                autoFocus
              />
            ) : (
              <>
                {editingState.id === null && !element.thumbsup &&
                  <div className='w-[94%]' onClick={() => handleEdit(element.id, element.headerText || '', 'headerText')}>
                    <div className='font-[500] text-[#121217] text-[14px]'>
                      {element.headerText}
                    </div>
                  </div>
                }
              </>
            )}
          </div>

          <div className='flex items-center gap-2 mt-[6px] '>
            <div className='w-4 h-4' onClick={() => handleStepView(element.id)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
                <path fillRule="evenodd" d="M8.34 1.804A1 1 0 0 1 9.32 1h1.36a1 1 0 0 1 .98.804l.295 1.473c.497.144.971.342 1.416.587l1.25-.834a1 1 0 0 1 1.262.125l.962.962a1 1 0 0 1 .125 1.262l-.834 1.25c.245.445.443.919.587 1.416l1.473.294a1 1 0 0 1 .804.98v1.361a1 1 0 0 1-.804.98l-1.473.295a6.95 6.95 0 0 1-.587 1.416l.834 1.25a1 1 0 0 1-.125 1.262l-.962.962a1 1 0 0 1-1.262.125l-1.25-.834a6.953 6.953 0 0 1-1.416.587l-.294 1.473a1 1 0 0 1-.98.804H9.32a1 1 0 0 1-.98-.804l-.295-1.473a6.957 6.957 0 0 1-1.416-.587l-1.25.834a1 1 0 0 1-1.262-.125l-.962-.962a1 1 0 0 1-.125-1.262l.834-1.25a6.957 6.957 0 0 1-.587-1.416l-1.473-.294A1 1 0 0 1 1 10.68V9.32a1 1 0 0 1 .804-.98l1.473-.295c.144-.497.342-.971.587-1.416l-.834-1.25a1 1 0 0 1 .125-1.262l.962-.962A1 1 0 0 1 5.38 3.03l1.25.834a6.957 6.957 0 0 1 1.416-.587l.294-1.473ZM13 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clipRule="evenodd" />
              </svg>
            </div>
            <div className='w-4 h-4' onClick={() => handleStepDelete(element.id)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-[15px] text-[#758482]">
                <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clipRule="evenodd" />
              </svg>
            </div>
          </div>
        </span>
        <div className="w-full flex items-center gap-2">
          {/* Render ThumbRating Components */}
          <ThumbRating
            type="thumbs-up"
            isSelected={selectedThumb === 1}
            onSelect={handleThumbSelect}
            value="thumbs-up"
          />
          <ThumbRating
            type="thumbs-down"
            isSelected={selectedThumb === -1}
            onSelect={handleThumbSelect}
            value="thumbs-down"
          />
        </div>
      </div>
    );
  };




  const renderElement = (element: FormElement, index: number) => {
    switch (element.type) {
      case 'text':
        return renderEditableText(element, index, 'headerText', 'Text');
      case 'heading':
        return renderEditableTextHeading(element, index, 'headerText', 'Heading');
      case 'input':
        return renderEditableInputText(element, index, 'headerText', 'Input');
      case 'textarea':
        return renderEditableTextarea(element, index, 'headerText', 'Textarea');
      case 'email':
        return renderEditableEmailText(element, index, 'headerText', 'Email');
      case 'number':
        return renderEditableNumberText(element, index, 'headerText', 'Number');
      case 'dropdown':
        return renderEditableDropdownText(element, index, 'options', 'Radio');
      case 'radio':
        return renderEditableRadioText(element, index, 'options', 'Radio');
      case 'checkbox':
        return renderEditableMultiSelect(element, index, 'options', 'Options');
      case 'likert':
        return renderEditableLikertText(element, index, 'options', 'Radio');
      case 'numberscale':
        return renderEditableTextScaleText(element, index, 'options', 'Radio');
      case 'star':
        return renderEditableRatingText(element, index, 'options', 'Radio');
      case 'smiley':
        return renderEditableSmileyText(element, index, 'options', 'Radio');
      case 'thumbsup':
        return renderEditableThumbText(element, index, 'options', 'Radio');
      default:
        return null;
    }
  };

  return (
    <div className="flex-1 p-0">
      <div className="">
        {elements.map((element, index) => (
          <div key={index} className="px-1">
            {renderElement(element, index)}
          </div>
        ))}

      </div>
      <div className='flex flex-col min-w-[200px] bg-white border-t-0 border border-light-gray-200 border-solid z-10 absolute lg:fixed right-0 max-w-[80%] view-feedback-right-side'
        style={{
          width: isFormAsideOpen ? '351px' : '350px',
          transform: isFormAsideOpen ? 'none' : 'none',
          marginRight: isFormAsideOpen ? '0' : '-400px',
          top: isFormAsideOpen ? '46px' : '46px',
          ...(window.innerWidth <= 768 && {
            width: isFormAsideOpen ? '300px' : '300px',
            marginRight: isFormAsideOpen ? '0' : '-300px'
          }),
        }}
      >
        <div className='h-full'>
          <aside className='flex flex-col justify-stretch
                     border-l-0 border-r border-solid border-light-gray-200 p-[13px] items-start gap-4'>

            <div className="mx-auto w-full flex  items-center justify-between">
              <p className=" text-wedges-gray text-sm font-medium">
                Manage Step
              </p>
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                onClick={() => dispatch(closeFormAside())}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>

            </div>
            {selectedElement && (
              <div className="w-full mt-5">
                <p className='cursor-auto font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0'>
                  {selectedElement.type}
                </p>
                <p className='cursor-auto font-[500] text-[#121217] text-[14px] flex items-center mt-3'>
                  {selectedElement.headerText}
                </p>
              </div>
            )}



            <div className="w-full">
              <div className="w-full flex items-center justify-between lg:justify-start py-1">
                <div className="mr-2 py-1 px-0 font-normal text-dark-black-200 text-[13px] w-[50%]">
                  Is Mandatory
                </div>
                <div className="w-[50%] flex items-center justify-center gap-3">
                  <Switch
                    checked={enabled}
                    onChange={() => handleMandatoryToggle(selectedElement.id)}
                    className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                  >
                    <span className="sr-only">Use setting</span>
                    <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
                    <span
                      aria-hidden="true"
                      className="pointer-events-none absolute mx-auto h-4 w-9 rounded-full bg-gray-200 transition-colors duration-200 ease-in-out group-data-[checked]:bg-indigo-600"
                    />
                    <span
                      aria-hidden="true"
                      className="pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                    />
                  </Switch>
                </div>
              </div>
              {/* <div className="w-full flex items-center justify-between lg:justify-start py-1">
                <div className="mr-2 py-1 px-0 font-normal text-dark-black-200 text-[13px] w-[50%]">
                  Display logic
                </div>
                <div className="w-[50%] flex items-center justify-center gap-3">
                  <Switch
                    checked={enabled1}
                    onChange={setEnabled1}
                    className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                  >
                    <span className="sr-only">Use setting</span>
                    <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
                    <span
                      aria-hidden="true"
                      className="pointer-events-none absolute mx-auto h-4 w-9 rounded-full bg-gray-200 transition-colors duration-200 ease-in-out group-data-[checked]:bg-indigo-600"
                    />
                    <span
                      aria-hidden="true"
                      className="pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                    />
                  </Switch>
                </div>
              </div> */}
            </div>
          </aside>
        </div>

      </div>
    </div>
  );
};

export default FormBuilder;