import React, { useState, Fragment, useEffect, useCallback } from "react"
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, CheckIcon } from '@heroicons/react/20/solid'
import { _createChannel, _getChannelById, _updateChannel } from './baseAPI'
import { useLocation, useNavigate } from "react-router-dom"
import AlertPopup from "../../../components/AlertPopup"
import { Switch } from '@headlessui/react'
import { Button } from "@lemonsqueezy/wedges"
import { Listbox } from '@headlessui/react'
import { QueueListIcon, TableCellsIcon, Bars2Icon } from '@heroicons/react/20/solid';
import { _getBoardById, _updateBoardApi } from "../boardApi/BoardApi"


const colorList = [
  { name: "bg-[#3b82f6]" },
  { name: "bg-[#6b7280]" },
  { name: "bg-[#bfdbfe]" },
  { name: "bg-[#ec4899]" },
  { name: "bg-[#6366f1]" },
  { name: "bg-[#eab308]" },
  { name: "bg-[#22c55e]" },
  { name: "bg-[#06b6d4]" },
  { name: "bg-[#dc2626]" },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}


interface ProjectData {
  id: number;
  base_id: number;
  created_by: number;
  updated_by: number;
  name: string;
  color: string;
  created_at: string;
  description: string;
  privacy: string;
  team_status: string;
  updated_at: string;
}



const CreateBaseChannel = () => {
  const [checked, setChecked] = useState(false);
  const [data, setData] = useState<any>()
  const [showAlert, setShowAlert] = useState(false)
  const [color, setColor] = useState("bg-[#6b7280]")
  const [name, setName] = useState("");
  const [purpose, setPurpose] = useState("");
  const [nameError, setNameError] = useState<string>("");
  const [purposeError, setPurposeError] = useState<string>("");
  const navigate = useNavigate()
  const { state } = useLocation(); // Remove the type argument
  const [projectData, setProjectData] = useState<ProjectData | null>(null);
  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [projectNameError, setProjectNameError] = useState<string>("");
  const [projectPurposeError, setProjectPurposeError] = useState<string>("");
  const [colorP, setColorP] = useState("")
  const [projectColor, setProjectColor] = useState(colorP || "bg-[#6b7280]");
  const selectRoleType = [
    { id: 1, name: 'Kanban', icon: <Bars2Icon className="h-4 w-4" /> },
    { id: 2, name: 'List', icon: <QueueListIcon className="h-4 w-4" /> },
    // { id: 3, name: 'Table', icon: <TableCellsIcon className="h-4 w-4" /> },
  ]


  const [selectRole, setSelectRole] = useState(selectRoleType[1]); // Initialize with a default role, e.g., the first role in selectRoleType array

  // get data according to id

  useEffect(() => {
    const baseId = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
        if (baseId && state?.id) {
      _getBoardById(state.id, baseId)
        .then((res) => {
          if (res?.data?.errors?.color) {
            setProjectColor("#6b7280"); // or any other fallback color
          } else {
            setProjectData(res?.data);
            setProjectName(res?.data?.name || "");
            setProjectDescription(res?.data?.description || "");
            setProjectColor(res?.data?.color || "#6b7280");
            setChecked(res?.data?.privacy === "public");
          }
        })
        .catch((err) => {
          console.log("Error:", err);
        });
    }
  }, [state?.id]);

  // this is for update project

  const handleUpdateProject = (e: any) => {
    e.preventDefault();
    if (state && state?.baseId && state?.id) {
      const nameValue = e.target.name.value;
      const purposeValue = e.target.purpose.value;
  
      const userStatus = localStorage.getItem('userAuthStatus') || "";
      const privacy = checked ? "public" : "private";
      // Use projectColor if it exists, otherwise fallback to default color
      const colorValue = projectColor || '#6b7280';
      const lowerCaseRoleName = selectRole.name.toLowerCase(); // Convert to lowercase
      const baseId = localStorage.getItem('baseId'); // Retrieve baseId from localStorage
  
      if (baseId !== null) { // Check if baseId is not null
        _updateBoardApi(
          state?.id,
          baseId, // Pass baseId here
          nameValue,
          purposeValue,
          privacy,
          colorValue,
          userStatus,
          lowerCaseRoleName
        )
        .then((res) => {
          if (res.status === 200) {
            navigate(-1);
          } else {
            setData(res);
            setShowAlert(true);
          }
        })
        .catch((err) => {
          console.log('Error:', err);
        });
      } else {
        // console.log('baseId is null or undefined'); // Handle null case if needed
      }
    }
  };
  



  const handleChangeEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'name') {

      setProjectName(value);
    } else if (name === 'purpose') {
      setProjectDescription(value);
    }
  };



  const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');

  const userId = localStorage.getItem('userAuthId');



  const createChannel = (e: any) => {
    e.preventDefault()
    // const baseId = JSON.parse(localStorage.getItem('baseId') || "");

    const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
    const baseId = baseIdString ? JSON.parse(baseIdString) : null;


    const userId = JSON.parse(localStorage.getItem('userAuthId') || "");


    const userStatus = localStorage.getItem('userAuthStatus') || "";
    let name = e.target[1].value
    let purpose = e.target[2].value
    let privacy = checked ? "public" : "private"


    // Validate form inputs
    if (name.trim().length === 0) {
      setNameError("Name is required");
      return;
    }
    if (purpose.trim().length === 0) {
      setPurposeError("Purpose is required");
      return;
    }
    const lowerCaseRoleName = selectRole.name.toLowerCase(); // Convert to lowercase

    _createChannel(
      baseId,
      name,
      purpose,
      privacy,
      color,
      userStatus,
      lowerCaseRoleName
    )
      .then((res) => {
        if (res.status === 200) {
          navigate(-1)
        } else {
          setData(res)
          setShowAlert(true)
        }
      })
      .catch((err) => {
        console.log('check', err)
      })
  }
  const handleSwitchChange = () => {
    setChecked(!checked);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'name') {
      setName(value);
      setProjectName(value)
      if (value.trim().length === 0) {
        setNameError("Name is required");
        setProjectNameError("Name is required")
      } else {
        setNameError("");
        setProjectNameError("")
      }
    } else if (name === 'purpose') {
      setPurpose(value);
      setProjectDescription(value)
      if (value.trim().length === 0) {
        setPurposeError("Purpose is required");
        setProjectPurposeError("Purpose is required");
      } else {
        setPurposeError("");
        setProjectPurposeError("");
      }
    }
  };

  useEffect(() => {
    if (projectData && projectData.color) {
      setProjectColor(projectData.color);
    }
  }, [projectData]);



  return (<>


    {projectData ? (
      <>
        <div className="grid grid-cols-1 lg:grid-cols-3 h-screen">
          <div className="hidden lg:block sm:col-span-1">
            <img
              className="h-full w-full object-none"
              src="https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
              alt=""
            />
          </div>
          <div className="col-span-1 sm:col-span-2 flex flex-1 flex-col justify-start sm:px-8 lg:flex-none py-12 px-4 xl:px-12">
            <div className="w-full justify-end flex items-center mb-[16px] lg:mb-[0px]">

              <Button
                onClick={() => navigate(-1)}
                type='button'
                className=" bg-light-pink  hover:bg-gray-100 text-black-100 focus-visible:outline-light-pink  [&>span]:w-full  [&>span]:flex  [&>span]:items-center"
                size="sm"              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='w-4 h-4 mr-1'>
                  <path strokeLinecap='round' strokeLinejoin='round' d='M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18' />
                </svg>
                Go back
              </Button>
            </div>
            <div className="flex flex-1 flex-col justify-center max-w-xl m-auto w-full">
              <div>
                <img
                  className="h-10 w-auto m-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt="Your Company"
                />
                <h2 className="mt-8 text-2xl text-center font-bold leading-9 tracking-tight text-gray-900">
                Create Channel
                </h2>
              </div>
              <form action="#" onSubmit={(e) => handleUpdateProject(e)} className="space-y-6 mt-10">

                <div className="sm:col-span-2">
                  <label htmlFor="phone-number" className="wg-label inline-flex shrink-0 items-center gap-1 text-surface-900 wg-antialiased">
                  Name
                  </label>
                  <div className="relative mt-2.5">

                    <Menu as='div' className='flex items-center'>
                      <Menu.Button className='absolute inset-y-0 left-0 flex items-center px-2'>
                        <span className='sr-only'>Open user menu</span>
                        <div
                          className={`border-0 bg-none text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm h-6 w-6  rounded-full bg-[${projectColor}] ${projectColor} border flex justify-center items-center`}
                        >
                          <ChevronDownIcon className='h-5 w-5 text-white' aria-hidden='true' />
                        </div>
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'>
                        <Menu.Items className='absolute flex flex-wrap left-0 top-8 z-10 mt-2.5 w-44 p-2 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none'>
                          {colorList.map((value: any, index) => {
                            return (
                              <div onClick={() => setProjectColor(value.name)} key={index} className={`flex h-6 w-6 border ${value.name} rounded-full m-2 justify-center items-center`}>
                                {(projectColor === value.name) ? <CheckIcon className=' h-3 w-3 text-white' aria-hidden='true' /> : null}
                              </div>
                            )
                          })}

                        </Menu.Items>
                      </Transition>
                      <input
                        id='name'
                        name='name'
                        type='text'
                        autoComplete='name'
                        placeholder='Enter Name'
                        value={projectName}
                        onChange={handleChangeEdit}
                        className="flex grow rounded-lg border bg-background pl-9 px-4 py-2 text-sm leading-6 shadow-wg-xs transition-colors duration-100 placeholder:text-surface-500 outline-primary focus:outline focus:outline-2 focus:-outline-offset-1 text-surface-900 hover:border-surface-300 dark:hover:border-surface-200 border-surface-200 dark:border-surface-100 focus:outline-indigo-600"
                      />
                    </Menu>
                  </div>
                  <p className="mt-2 text-sm text-red-600" id="name-error">
                    {projectNameError}
                  </p>
                </div>
                <div>
                  <label htmlFor="text" className="wg-label inline-flex shrink-0 items-center gap-1 text-surface-900 wg-antialiased">
                    Purpose
                  </label>
                  <div className="mt-2">
                    <input
                      id="text"
                      name='purpose'
                      type='text'
                      autoComplete="text"
                      value={projectDescription}
                      onChange={handleChangeEdit}
                      placeholder='Enter Purpose'
                      className="flex grow rounded-lg border bg-background w-full px-4 py-2 text-sm leading-6 shadow-wg-xs transition-colors duration-100 placeholder:text-surface-500 outline-primary focus:outline focus:outline-2 focus:-outline-offset-1 text-surface-900 hover:border-surface-300 dark:hover:border-surface-200 border-surface-200 dark:border-surface-100 focus:outline-indigo-600"
                    />
                    <p className="mt-2 text-sm text-red-600" id="purpose-error">
                      {projectPurposeError}
                    </p>
                  </div>
                </div>

                <div className='flex flex-col gap-2 wg-antialiased'>
                  <Listbox value={selectRole} onChange={setSelectRole}>
                    {({ open }) => (
                      <>
                        <Listbox.Label className="wg-label inline-flex cursor-pointer items-center gap-1 text-sm font-medium leading-6">View Type</Listbox.Label>
                        <div className="relative">
                          <Listbox.Button className="relative w-full cursor-default h-[42px] rounded-lg bg-white py-1.5 pl-3 pr-10
                                                 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2
                                                  focus:ring-indigo-600 sm:text-sm sm:leading-6">
                            <span className="truncate flex items-center gap-[6px]">
                              {selectRole.icon}
                              {selectRole.name}</span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">

                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-gray-400">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                              </svg>

                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {selectRoleType.map((person) => (
                                <Listbox.Option
                                  key={person.id}
                                  className={({ active }) =>
                                    classNames(
                                      active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                      'relative cursor-default select-none py-2 pl-3 pr-9'
                                    )
                                  }
                                  value={person}
                                >
                                  {({ selected }) => (
                                    <>
                                      <span className={classNames(selected ? 'font-semibold flex items-center gap-[6px]' : 'font-normal  flex items-center gap-[6px]',
                                        ' flex items-center  gap-[6px]  truncate')}>
                                        {person.icon}
                                        {person.name}
                                      </span>

                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>

                </div>
                <div className="flex items-center">
                  <Switch
                    checked={checked}
                    onChange={handleSwitchChange}
                    className={classNames(
                      checked ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                    )}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      className={classNames(
                        checked ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      )}
                    >
                      <span
                        className={classNames(
                          checked ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
                          'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                        )}
                        aria-hidden="true"
                      >

                      </span>
                      <span
                        className={classNames(
                          checked ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
                          'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                        )}
                        aria-hidden="true"
                      >

                      </span>
                    </span>
                  </Switch>
                  <span className="ml-3 text-sm font-medium text-gray-900 dark:text-black-300 capitalize">
                    {/* {checked ? projectData?.privacy : "Private"} */}
                    {checked ? (projectData?.privacy === 'public' ? 'Public' : 'Private') : 'Private'}

                  </span>
                </div>
                <p className="fontfamily text-gray-600 text-sm">{checked ? "Everyone on your team can view and join this channel." : "Only invited members will be able to view & access this channel."}</p>

                <div>
                  <Button
                    type="submit"
                    className=" bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600"
                    size="sm"
                  >
                  Create Channel
                  </Button>
                </div>
              </form>
            </div>
          </div>

        </div>
      </>
    ) : (
      <>
        <div className="grid grid-cols-1 lg:grid-cols-3 h-screen">
          <div className="hidden lg:block sm:col-span-1">
            <img
              className="h-full w-full object-none"
              src="https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
              alt=""
            />
          </div>
          <div className="col-span-1 sm:col-span-2 flex flex-1 flex-col justify-start sm:px-8 lg:flex-none py-12 px-4 xl:px-12">
            <div className="w-full justify-end flex items-center mb-[16px] lg:mb-[0px]">

              <Button
                onClick={() => navigate(-1)}
                type='button'
                className=" bg-light-pink  hover:bg-gray-100 text-black-100 focus-visible:outline-light-pink [&>span]:flex [&>span]:w-full [&>span]:items-center"
                size="sm"              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='w-4 h-4 mr-1'>
                  <path strokeLinecap='round' strokeLinejoin='round' d='M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18' />
                </svg>
                Go back
              </Button>
            </div>
            <div className="flex flex-1 flex-col justify-center max-w-xl m-auto w-full">
              <div>
                <img
                  className="h-10 w-auto m-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt="Your Company"
                />
                <h2 className="mt-8 text-2xl text-center font-bold leading-9 tracking-tight text-gray-900">
                  Create Board
                </h2>
              </div>
              <form action="#" onSubmit={(e) => createChannel(e)} className="space-y-6 mt-10">

                <div className="sm:col-span-2">
                  <label htmlFor="phone-number" className="wg-label inline-flex shrink-0 items-center gap-1 text-surface-900 wg-antialiased">
                   Name
                  </label>
                  <div className="relative mt-2.5">

                    <Menu as='div' className='flex items-center'>
                      <Menu.Button className='absolute inset-y-0 left-0 flex items-center px-2'>
                        <span className='sr-only'>Open user menu</span>
                        <div
                          className={`border-0 bg-none text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm h-6 w-6  rounded-full ${color} border flex justify-center items-center`}
                        >
                          <ChevronDownIcon className='h-5 w-5 text-white' aria-hidden='true' />
                        </div>
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'>
                        <Menu.Items className='absolute flex flex-wrap left-0 top-8 z-10 mt-2.5 w-44 p-2 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none'>
                          {colorList.map((value: any, index) => {
                            return (
                              <div onClick={() => setColor(value.name)} key={index} className={`flex h-6 w-6 border ${value.name} rounded-full m-2 justify-center items-center`}>
                                {(color === value.name) ? <CheckIcon className=' h-3 w-3 text-white' aria-hidden='true' /> : null}
                              </div>
                            )
                          })}

                        </Menu.Items>
                      </Transition>
                      <input
                        id='name'
                        name='name'
                        type='text'
                        autoComplete='name'
                        placeholder='Enter Name'
                        value={name}
                        onChange={(e) => handleChange(e)}
                        className="flex grow rounded-lg border bg-background pl-9 px-4 focus:outline-indigo-600 py-[6px] h-[36px] text-sm leading-6 shadow-wg-xs transition-colors duration-100 placeholder:text-surface-500 outline-primary focus:outline focus:outline-2 focus:-outline-offset-1 text-surface-900 hover:border-surface-300 dark:hover:border-surface-200 border-surface-200 dark:border-surface-100"
                      />
                    </Menu>
                  </div>
                  <p className="mt-2 text-sm text-red-600" id="name-error">
                    {nameError}
                  </p>
                </div>
                <div>
                  <label htmlFor="text" className="wg-label inline-flex shrink-0 items-center gap-1 text-surface-900 wg-antialiased">
                   Purpose
                  </label>
                  <div className="mt-2">
                    <input
                      id="text"
                      name='purpose'
                      type='text'
                      autoComplete="text"
                      value={purpose}
                      onChange={(e) => handleChange(e)}
                      placeholder='Enter Purpose'
                      className="flex grow rounded-lg border bg-background w-full px-4 focus:outline-indigo-600 py-[6px] h-[36px] text-sm leading-6 shadow-wg-xs transition-colors duration-100 placeholder:text-surface-500 outline-primary focus:outline focus:outline-2 focus:-outline-offset-1 text-surface-900 hover:border-surface-300 dark:hover:border-surface-200 border-surface-200 dark:border-surface-100"
                    />
                    <p className="mt-2 text-sm text-red-600" id="purpose-error">
                      {purposeError}
                    </p>
                  </div>
                </div>
                <div className='flex flex-col gap-2 wg-antialiased'>
                  <Listbox value={selectRole} onChange={setSelectRole}>
                    {({ open }) => (
                      <>
                        <Listbox.Label className="wg-label inline-flex shrink-0 items-center gap-1 text-surface-900 wg-antialiased">View Type</Listbox.Label>
                        <div className="relative">
                          <Listbox.Button className="relative w-full cursor-default h-[36px] rounded-lg bg-white py-1.5 pl-3 pr-10
                                                 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2
                                                  focus:ring-indigo-600 sm:text-sm sm:leading-6">
                            <span className="truncate flex items-center gap-[6px]">
                              {selectRole.icon}
                              {selectRole.name}</span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">

                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-gray-400">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                              </svg>

                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {selectRoleType.map((person) => (
                                <Listbox.Option
                                  key={person.id}
                                  className={({ active }) =>
                                    classNames(
                                      active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                      'relative cursor-default select-none py-2 pl-3 pr-9'
                                    )
                                  }
                                  value={person}
                                >
                                  {({ selected }) => (
                                    <>
                                      <span className={classNames(selected ? 'font-semibold flex items-center gap-[6px]' : 'font-normal  flex items-center gap-[6px]',
                                        ' flex items-center  gap-[6px]  truncate')}>
                                        {person.icon}
                                        {person.name}
                                      </span>

                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>

                </div>
                <div className="flex items-center">
                  <Switch
                    checked={checked}
                    onChange={handleSwitchChange}
                    className={classNames(
                      checked ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                    )}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      className={classNames(
                        checked ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      )}
                    >
                      <span
                        className={classNames(
                          checked ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
                          'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                        )}
                        aria-hidden="true"
                      >

                      </span>
                      <span
                        className={classNames(
                          checked ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
                          'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                        )}
                        aria-hidden="true"
                      >

                      </span>
                    </span>
                  </Switch>
                  <span className="ml-3 text-sm font-medium text-gray-900 dark:text-black-300 capitalize">{checked ? "Public" : "Private"}</span>
                </div>
                <p className="fontfamily text-gray-600 text-sm">{checked ? "Everyone on your team can view and join this channel." : "Only invited members will be able to view & access this channel."}</p>

                <div className="flex justify-end">
                  <Button
                    type="submit"
                    className=" bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600 py-[6px]"
                    size="sm"                  >
                    Create Channel
                  </Button>
                </div>
              </form>
            </div>
          </div>

        </div>
        <AlertPopup data={data} show={showAlert} setShow={() => setShowAlert(false)} />
      </>
    )}

  </>)
}
export default CreateBaseChannel
