
import { _baseRequest } from "../../../services/api";
import { COMMON } from "../../../utils/common";
import { TopicItem } from "./topic.types";

const API_END_POINT = {
    FETCHTOPICLIST: "/v1/item/list/{0}/items",
};

// export const _fetchTopicListing = async (baseId: number, item_type: string, item_base_status_id: number, page: number): Promise<{ data: TopicItem[] }> => {
//     const params = new URLSearchParams({
//         item_type,
//         item_base_status_id: item_base_status_id.toString(),
//         page: page.toString(),
//     }).toString();

//     return _baseRequest(
//         `${COMMON.stringFormat(API_END_POINT.FETCHTOPICLIST, baseId)}?${params}`,
//         "GET",
//     ).then((response) => {
//         return response;
//     }).catch((error) => {
//         throw error;
//     });
// };

// export const _fetchTopicListing = async (baseId: number, item_type: string, item_base_status_id: number, page: number): Promise<{ data: TopicItem[] }> => {
//     const params = new URLSearchParams({
//         item_type: item_type ?? '',
//         item_base_status_id: (item_base_status_id ?? 0).toString(),
//         page: (page ?? 1).toString(),

//     }).toString();

//     return _baseRequest(
//         `${COMMON.stringFormat(API_END_POINT.FETCHTOPICLIST, baseId)}?${params}`,
//         "GET",
//     ).then((response) => {
//         return response;
//     }).catch((error) => {
//         throw error;
//     });
// };
export const _fetchTopicListing = async (
    baseId: number,
    item_type: string,
    item_base_status_id: number,
    page: number,
    filters: Record<string, any>
  ): Promise<{ data: TopicItem[] }> => {
    const params = new URLSearchParams({
      item_type: item_type ?? '',
      item_base_status_id: (item_base_status_id ?? 0).toString(),
      page: (page ?? 1).toString(),
      ...filters
    }).toString();
  
    return _baseRequest(
      `${COMMON.stringFormat(API_END_POINT.FETCHTOPICLIST, baseId)}?${params}`,
      "GET",
    ).then((response) => {
      return response;
    }).catch((error) => {
      throw error;
    });
  };
  