export const FETCH_DASHBOARD_LIST_REQUEST = 'FETCH_DASHBOARD_LIST_REQUEST';
export const FETCH_DASHBOARD_LIST_SUCCESS = 'FETCH_DASHBOARD_LIST_SUCCESS';
export const FETCH_DASHBOARD_LIST_FAILURE = 'FETCH_DASHBOARD_LIST_FAILURE';

export const FETCH_INSIGHT_DASHBOARD_LIST_REQUEST = 'FETCH_INSIGHT_DASHBOARD_LIST_REQUEST';
export const FETCH_INSIGHT_DASHBOARD_LIST_SUCCESS = 'FETCH_INSIGHT_DASHBOARD_LIST_SUCCESS';
export const FETCH_INSIGHT_DASHBOARD_LIST_FAILURE = 'FETCH_INSIGHT_DASHBOARD_LIST_FAILURE';

export const FETCH_ACTIVITY_DASHBOARD_LIST_REQUEST = 'FETCH_ACTIVITY_DASHBOARD_LIST_REQUEST';
export const FETCH_ACTIVITY_DASHBOARD_LIST_SUCCESS = 'FETCH_ACTIVITY_DASHBOARD_LIST_SUCCESS';
export const FETCH_ACTIVITY_DASHBOARD_LIST_FAILURE = 'FETCH_ACTIVITY_DASHBOARD_LIST_FAILURE';

export interface FeedbackSentimentCount {
  positive: number;
  mixed: number;
  negative: number;
  spam: number;
}

export interface TicketSentimentCount {
  negative: number;
  mixed: number;
}

export interface DashboardData {
  totalItems: number;
  feedbackItem: number;
  feedbackSentimentCount: FeedbackSentimentCount;
  ticketItem: number;
  ticketSentimentCount: TicketSentimentCount;
}

export interface DashboardType {
  data: {
    data: DashboardData;
    previousData: any; 
  };
}

// Action types union
export type DashboardActionTypes =
  | { type: typeof FETCH_DASHBOARD_LIST_REQUEST }
  | { type: typeof FETCH_DASHBOARD_LIST_SUCCESS, payload: DashboardType }
  | { type: typeof FETCH_DASHBOARD_LIST_FAILURE, payload: string }
  | { type: typeof FETCH_INSIGHT_DASHBOARD_LIST_REQUEST }
  | { type: typeof FETCH_INSIGHT_DASHBOARD_LIST_SUCCESS, payload:  DashboardType }
  | { type: typeof FETCH_INSIGHT_DASHBOARD_LIST_FAILURE, payload: string } 
  | { type: typeof FETCH_ACTIVITY_DASHBOARD_LIST_REQUEST }
  | { type: typeof FETCH_ACTIVITY_DASHBOARD_LIST_SUCCESS, payload: DashboardType }
  | { type: typeof FETCH_ACTIVITY_DASHBOARD_LIST_FAILURE, payload: string }
