// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@lemonsqueezy/wedges';
import { _item_TopicSearchByParam } from '../setting/settingPages/baseAPI';
import { MenuDropdown } from '../../components/MenuDropdown';
import { ArrowUpRightIcon, Bars3Icon, GlobeAltIcon, RssIcon, SparklesIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { DropdownGroup, Feedback } from './type';
import {
  DeleteInsightData, deleteItemActivity, fetchFeedbackData,
  fetchFeedbackItemActivity, fetchItemInsightData
} from '../feedbacks/feedback-redux/feedbacks.actions';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../hooks/hooks';
import { RootState } from '../../store/store';
import { FeedbackType } from '../feedbacks/feedback-redux/feedbacks.types';
import { Toaster, toast } from 'sonner';
import { formatDistance } from 'date-fns';
import { fetchItemInsightTopicData } from '../insights/insight-redux/insight.actions';
import { ItemType } from '../topic/topicKanban/interface/index';


function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

interface ViewFeedbackProps {
  item?: {
    id: number;
    name: string;
    daysAgo: string;
    createdBy: string;
    avatarUrl: string;
    description: string;
    statusColor: string;
  };
  activity?: any[];
  itemId?: number;
  isSidebarOpen?: boolean;
  itemType?: string;
  setIsSidebarOpen?: (open: boolean) => void;
  headernone?: boolean;
  handleSidebarToggle?: () => void;
}



const ViewFeedbackQuickMode: React.FC<ViewFeedbackProps> = ({ activity, item, isSidebarOpen, setIsSidebarOpen, headernone, handleSidebarToggle, itemId, itemType }) => {
  const [isAsideOpen, setIsAsideOpen] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [itemStatusActivity, setItemStatusActivity] = useState<ItemStatus[]>([]);
  const [itemInsight, setItemInsight] = useState<ItemStatus[]>([]);
  const [selectedInsightId, setSelectedInsightId] = useState(null);
  const [fetchedInsightData, setFetchedInsightData] = useState(null);
  const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;
  const [localLoading, setLocalLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState<string>('');
  const [itemAttachmentShow, setItemAttachmentShow] = useState('')
  const navigate = useNavigate()
  // add insight
  const [formDataInsight, setFormDataInsight] = useState<Partial<FeedbackType>>({
    itemInsight: '',
    itemContext: '',
  });

  const textareaRef = useRef(null);
  const textareaRef1 = useRef(null);
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const { data, loading, error } = useSelector((state: RootState) => state.feedbacks);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };


  useEffect(() => {
    const fetchData = async () => {
      if (itemId || id) {
        setLocalLoading(true);
        try {
          const res = await dispatch(fetchFeedbackData((parseInt(itemId, 10) || parseInt(id, 10)), baseId));
          setItemAttachmentShow(res)
        } catch (error) {
          console.error("Error fetching feedback data:", error);
        } finally {
          setLocalLoading(false);
        }
      }
    };

    fetchData();
  }, [dispatch, itemId, id, baseId]);


  useEffect(() => {
    const fetchData = async () => {
      if (id && baseId) {
        setLocalLoading(true);
        try {
          const getItemActivity = await dispatch(fetchFeedbackItemActivity(parseInt(id, 10), baseId));
          setItemStatusActivity(getItemActivity)

        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLocalLoading(false)
        }
      }
    };

    fetchData();
  }, [dispatch, id, baseId]);

  useEffect(() => {
    fetchFeedbackItemActivity()
  }, [])



  useEffect(() => {
    const fetchData = async () => {
      if (id || itemId && itemType === 'feedback') {
        setLocalLoading(true);
        try {
          const itemInsightRes = await dispatch(fetchItemInsightData(baseId, (parseInt(id, 10) || parseInt(itemId, 10))));
          setItemInsight(itemInsightRes)
        } catch (error) {
          console.error("Error fetching feedback data:", error);
        } finally {
          setLocalLoading(false);
        }
      }
    };

    fetchData();
  }, [dispatch, itemId, id, baseId, itemType]);





  useEffect(() => {
    const fetchData = async () => {
      if (itemId) {
        setLocalLoading(true);
        try {
          const getItemActivity = await dispatch(fetchFeedbackItemActivity(parseInt(itemId, 10), baseId));
          setItemStatusActivity(getItemActivity)
        } catch (error) {
          console.error("Error fetching feedback data:", error);
        } finally {
          setLocalLoading(false);
        }
      }
    };

    fetchData();
  }, [dispatch, itemId, baseId]);

  useEffect(() => {
    const fetchData = async () => {
      if ((id || itemId) && itemType === 'ticket') {
        setLocalLoading(true);
        try {
          const itemInsightRes = await dispatch(fetchItemInsightTopicData(baseId, (parseInt(id, 10) || parseInt(itemId, 10))));
          setItemInsight(itemInsightRes)
        } catch (error) {
          console.error("Error fetching feedback data:", error);
        } finally {
          setLocalLoading(false);
        }
      }
    };

    fetchData();
  }, [dispatch, itemId, id, baseId, itemType]);


  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '0px';
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = `${scrollHeight}px`;
    }
  }, [formDataInsight?.itemInsight]);

  useEffect(() => {
    if (textareaRef1.current) {
      textareaRef1.current.style.height = '0px';
      const scrollHeight = textareaRef1.current.scrollHeight;
      textareaRef1.current.style.height = `${scrollHeight}px`;
    }
  }, [formDataInsight?.itemContext]);


  if (loading || localLoading) return (
    <div className=" flex items-center justify-center relative z-50 w-full" style={{ height: 'calc(100vh - 0px)' }}>
      <div className="simple-spinner-loader">
        <span></span>
      </div>
    </div>
  );
  if (error) return <div>Error: {error}</div>;
  if (data?.length === 0) return <div>No feedback available</div>;

  // for create by user
  const baseMembersDetailsString = localStorage.getItem('baseMembersDetails');
  const baseMembersDetails = baseMembersDetailsString ? JSON.parse(baseMembersDetailsString) : [];


  if (fetchFeedbackData?.length > 0) {
    const createdAt = fetchedInsightData?.created_at;

    if (createdAt) {
      const parsedDate = new Date(createdAt);
      if (!isNaN(parsedDate.getTime())) {
        const timeAgoViewInsight = formatDistance(parsedDate, new Date(), { includeSeconds: true });
        cleanedTimeAgoViewInsight = timeAgoViewInsight.replace(/^about\s/, '');
      } else {
        console.error('Invalid created_at date:', createdAt);
      }
    }
  }


  if (fetchFeedbackData?.length > 0) {
    const createdAt1 = fetchedInsightData?.updated_at;

    if (createdAt1) {
      const parsedDate = new Date(createdAt1);
      if (!isNaN(parsedDate.getTime())) {
        const timeAgoViewInsight1 = formatDistance(parsedDate, new Date(), { includeSeconds: true });
        cleanedTimeAgoViewInsight1 = timeAgoViewInsight1.replace(/^about\s/, '');
      } else {
        console.error('Invalid created_at date:', createdAt1);
      }
    }
  }


  const createdById = fetchedInsightData?.created_by;
  const matchingUser = baseMembersDetails.find(user => user.id === createdById);
  const createdByName = matchingUser ? matchingUser.name : 'AI';


  const createdById1 = fetchedInsightData?.updated_by;
  const matchingUser1 = baseMembersDetails.find(user => user.id === createdById1);
  const createdByName1 = matchingUser1 ? matchingUser1.name : '';



  // Function to get member details by ID
  const getMemberDetails = (id, creatorType) => {
    switch (creatorType) {
      case 'user':
        const userMember = baseMembersDetails.find(member => member.id == id);
        return userMember ? userMember.name : '';
      case 'ai':
        return <div className='relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[9px] flex items-center justify-center'><SparklesIcon className='w-3 h-3 text-dark-black-200' /></div>;
      case 'customer':
        return id;
      default:
        return '';
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const sentimentCategories = Object.keys(itemInsight || {});

  const isInsightEmpty = !itemInsight || Object.keys(itemInsight).length === 0;

  const handleFeedbackDetail = (ItemType) => {
    if (itemType === 'feedback') {
      navigate(`/feedback/${itemId}`);
    }
    if (itemType === 'ticket') {
      navigate(`/ticket/${itemId}`);
    }
  }


  return (
    <>
      <Toaster richColors />
      <div className="sticky top-0 w-full">
        <div className='flex h-[46px] shrink-0 items-center gap-x-4 bg-white px-4 shadow-custom-1 sm:gap-x-6 sm:px-6 lg:px-[20px]'>
          <div className='flex items-center justify-between w-full'>
            <div className='flex items-center gap-2'>
              <h2 className='text-black-100 font-medium text-sm'>{selectedTab ? selectedTab : `${data[0].item_refernce_id}`}</h2>

            </div>
            <div className='flex justify-between items-center gap-2'>
              <button
                onClick={handleFeedbackDetail}
                style={{ transitionProperty: 'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform' }}
                className='rounded-md  duration-200 font-[600] text-[0.8125rem] h-[32px] w-[32px] flex items-center justify-center border border-solid border-[#e7e7e8]'>
                <ArrowUpRightIcon className='w-[1.1em] h-[1.1em] text-[#000]' />

              </button>
              <button
                onClick={() => setIsSidebarOpen(false)}
                style={{ transitionProperty: 'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform' }}
                className='rounded-md  duration-200 font-[600] text-[0.8125rem] h-[32px] w-[32px] flex items-center justify-center border border-solid border-[#e7e7e8]'>

                <XMarkIcon className='w-[1.1em] h-[1.1em] text-[#000]' />
              </button>

            </div>
          </div>



        </div>
      </div>
      {data.map((feedback: FeedbackType, index: number) => (
        <div key={index} className='flex-1 p-0'>
          <div className='min-h-full max-w-full' style={{ margin: '0px auto' }}>
            <div className='flex items-stretch flex-row gap-0 w-full h-full relative overflow-x-hidden overflow-y-hidden'>
              <main className='relative flex-1 flex '>
                <div
                  className={isHovered ? 'overflowing-element hovered mq1023:max-w-full mq1800:max-w-[100%] mq3000:max-w-[100%] flex-1 w-full px-5 lg:px-[40px] overflow-y-auto view-feedback-data pb-5' : 'new-feedbackoverflow  mq1023:max-w-full mq1800:max-w-[100%] mq3000:max-w-[100%] flex-1 w-full px-5 lg:px-[40px] overflow-y-auto view-feedback-data overflowing-element pb-5'}
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}

                >
                  <div className='max-w-full md:max-w-[710px] lg:max-w-[710px] m-auto'>
                    {feedback.item_title &&
                      <div className="mb-4 mt-5 lg:mt-[25px]">
                        <div

                          className="border-none outline-none bg-transparent focus:outline-none focus:ring-0 text-[24px] leading-8  font-medium pb-2 text-dark-black-100 w-full p-0 m-0 placeholder:text-[#9e9ea0]"

                        >
                          {feedback?.item_title}
                        </div>
                      </div>
                    }
                    {feedback.item_details &&
                      <div className="mt-4">
                        <div
                          className="border-none outline-none bg-transparent focus:outline-none focus:ring-0 text-[14px] text-dark-black-100 w-full p-0 m-0 placeholder:text-[#9e9ea0]"
                        >
                          {feedback?.item_details}
                        </div>
                      </div>
                    }

{
                      itemAttachmentShow &&
                      itemAttachmentShow.item_attachment &&
                      itemAttachmentShow.item_attachment.length > 0 && (
                        <img
                          src={
                            itemAttachmentShow?.item_attachment?.[0]?.fileUrl
                              ? `${process.env.REACT_APP_API_BASE_URL}/${itemAttachmentShow.item_attachment[0].fileUrl}`
                              : ''
                          }
                          alt="Selected file preview"
                          className="mt-5 w-full object-cover rounded-md max-w-[250px]"
                        />
                      )
                    }
                    {/* )} */}
                    <hr className='my-5' />
                    {feedback?.item_summary &&
                      <div className='bg-[#ffeab6] p-5 rounded-xl mt-5'>
                        <div className=' flex items-center gap-[6px]   mb-4'>
                          <SparklesIcon className='w-4 h-4 text-dark-black-200' />
                          <p className='text-[13px] leading-4 text-dark-black-200 font-medium uppercase'>AI Summary
                          </p>
                        </div>
                        <p className='text-[14px] text-dark-black-100 font-normal' dangerouslySetInnerHTML={{ __html: feedback?.item_summary }}></p>
                      </div>
                    }
                    <div className='mt-8'>
                      <div className='flex items-center justify-between'>
                        <h4 className='font-medium text-dark-black-200  mr-1 text-[15px] flex items-center gap-[6px]'>
                          <GlobeAltIcon className="w-4 h-4 text-dark-black-200 " />
                          Insights
                        </h4>

                      </div>

                      {isInsightEmpty ? (
                        <p className='text-gray-500 mt-2 text-[13px]'>No insights available at the moment.</p>
                      ) : (
                        sentimentCategories.map((category) => (

                          <div key={category}>
                            <p className='font-medium text-dark-black-100 mr-1 text-[13px] mt-4'>
                              {capitalizeFirstLetter(category)}
                            </p>
                            {itemInsight[category] && Array?.isArray(itemInsight[category]) && itemInsight[category].length > 0 ? (
                              itemInsight[category]?.map((insight, index) => (

                                <li key={insight.id} className="relative flex gap-x-4 mt-4">
                                  <div className="relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[10px] flex items-center justify-center">
                                    <span className="text-[11px] font-medium text-gray-700">{index + 1}</span>
                                  </div>
                                  {
                                    insight?.itemInsight &&
                                    <div

                                      className="flex-auto rounded-md p-3 cursor-pointer ring-1 ring-inset ring-gray-200 feedback-delete-hover">
                                      <div className='flex items-start'>
                                        <div
                                          className={`${insight?.itemContext ? 'w-[95%] flex flex-col gap-1' : 'w-[95%] flex flex-col gap-0'}`}
                                        >
                                          <div className="flex justify-between gap-x-4">
                                            {
                                              insight?.itemInsight &&
                                              <div className="text-[13px] leading-5 text-gray-500 flex items-start">
                                                <span className="font-medium text-dark-black-100 mr-1 leading-4">{insight?.itemInsight}</span>
                                              </div>
                                            }

                                          </div>
                                          <div className='flex items-center'>
                                            {
                                              insight?.itemContext &&
                                              <p className="text-[13px] text-gray-500 italic leading-4">'{insight?.itemContext}'</p>
                                            }
                                          </div>
                                          {
                                            insight.attached_item &&
                                            <div className='border-t border-solid border-light-gray-200 pt-3  mt-3'>
                                              <div className='flex items-center gap-1'>
                                                {
                                                  insight.attached_item.item_refernce_id &&
                                                  <div className='flex items-center'>
                                                    <span className='text-[13px] text-gray-500 leading-4'>
                                                      {insight.attached_item.item_refernce_id}
                                                      <span className='text-[13px] text-gray-500 leading-4  ml-1'>
                                                        -
                                                      </span>
                                                    </span>
                                                  </div>
                                                }

                                                {
                                                  insight.attached_item.item_title &&
                                                  <span className='text-[13px] text-dark-black-100 leading-4 line-clamp-1'>
                                                    {insight.attached_item.item_title}
                                                  </span>
                                                }

                                              </div>
                                              {
                                                insight.attached_item.item_details &&
                                                <span className='text-[13px] text-gray-500  leading-4 line-clamp-2 mt-2'>
                                                  {insight.attached_item.item_details}
                                                </span>
                                              }

                                            </div>
                                          }

                                        </div>

                                      </div>
                                    </div>
                                  }

                                  {
                                    !insight.itemInsight &&
                                    <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 feedback-delete-hover flex flex-row-reverse justify-between">
                                      <div className="flex justify-between gap-x-4">
                                        {
                                          insight.itemInsight &&
                                          <div className="text-[13px] leading-5 text-gray-500 flex items-start">
                                            <span className="font-medium text-dark-black-100 mr-1 leading-4">{insight.itemInsight}</span>
                                          </div>
                                        }


                                      </div>
                                      <div className='flex items-center'>
                                        {insight?.itemContext &&
                                          <span className='text-[13px] text-gray-500 italic leading-4'>'</span>
                                        }
                                        <p className="text-[13px] text-gray-500 italic leading-4">{insight?.itemContext}</p>
                                        {insight?.itemContext &&
                                          <span className='text-[13px] text-gray-500 italic leading-4'>'</span>
                                        }

                                      </div>
                                    </div>
                                  }

                                </li>
                              ))
                            ) : (
                              <p>No insights available for this category.</p>
                            )}

                          </div>
                        ))
                      )}
                    </div>


                    <div className="mt-[32px]">
                      <h4 className='font-medium text-dark-black-200  mr-1 text-[15px] flex items-center gap-[6px]'>
                        <RssIcon className="w-4 h-4 text-dark-black-200 " />
                        Activity</h4>
                      <>
                        <ul role="list" className="space-y-6 mt-[0.9rem]">
                          {itemStatusActivity.map((activityItem, activityItemIdx) => {

                            const timeAgo = formatDistance(new Date(activityItem.created_at), new Date(), { includeSeconds: true });
                            const cleanedTimeAgo = timeAgo.replace(/^about\s/, '');
                            return (
                              <li key={activityItem.id} className="relative flex gap-x-4">
                                <div
                                  className={classNames(
                                    activityItemIdx === itemStatusActivity?.length - 1 ? 'h-6' : '-bottom-6',
                                    'absolute left-0 top-0 flex w-6 justify-center'
                                  )}
                                >
                                  <div className="w-px bg-gray-200" />
                                </div>
                                {activityItem.item_activity_type === 'comment' || activityItem.item_activity_type === 'email' ? (
                                  <>

                                    <div className="relative flex-none w-6 h-6 rounded-full bg-gray-200 text-[10px] flex items-center justify-center">
                                      <span className="text-[7px] font-medium text-gray-700"></span>
                                      {typeof getMemberDetails(activityItem.created_by, activityItem.creator_type) === 'string' &&
                                        getMemberDetails(activityItem.created_by, activityItem.creator_type).length > 0 ?
                                        getMemberDetails(activityItem.created_by, activityItem.creator_type).charAt(0).toUpperCase() :
                                        ''}
                                    </div>
                                    <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 feedback-delete-hover">
                                      <div className="flex justify-between gap-x-4">
                                        <div className=" leading-4 text-[13px] text-gray-500 flex items-start">
                                          {activityItem && <span className="font-medium text-dark-black-100 mr-1 flex items-center gap-1">
                                            {activityItem.creator_type === 'ai' &&
                                              <div className='relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[9px] flex items-center justify-center'>
                                                <SparklesIcon className='w-3 h-3 text-dark-black-200' />
                                              </div>
                                            }
                                            {activityItem.creator_type === 'user' &&
                                              <>

                                                {getMemberDetails(activityItem.created_by, activityItem.creator_type)}
                                              </>
                                            }


                                            {activityItem.creator_type === 'customer' &&
                                              <>

                                                {getMemberDetails(activityItem.created_by, activityItem.creator_type)}
                                              </>
                                            }
                                          </span>}
                                          {item && <span className="font-medium text-dark-black-100 mr-1 flex items-center gap-1">{item?.name}</span>}
                                          commented
                                          <div className='flex items-center gap-[6px] ml-[6px]'>
                                            <span className='flex h-[4px] w-[4px] shrink-0 items-center justify-center rounded-full
                                        border-none text-[0.625rem] font-medium text-white capitalize bg-[#6b7280]'></span>
                                            <time dateTime={activityItem.created_at} className="flex-none text-xs leading-5 text-gray-500">
                                              {cleanedTimeAgo} ago
                                            </time>
                                            {
                                              activityItem.item_activity_type === 'email' && <p className='flex-none text-xs leading-5 text-gray-500'> ( via email )</p>
                                            }
                                            {
                                              activityItem.item_activity_privacy === 'external' && <p className='flex-none text-xs leading-5 text-gray-500'> (shared with customer )</p>
                                            }
                                          </div>
                                        </div>

                                      </div>
                                      <p className="text-sm leading-6 text-gray-500">{activityItem.item_activity_text}</p>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                                      <div className="h-1 w-1 rounded-full bg-transparent ring-2 ring-[#7d7f83]" />
                                    </div>
                                    <p className="flex-auto py-0.5 text-[13px] leading-5 text-gray-500 flex items-center">
                                      {activityItem && <span className="font-medium text-dark-black-100 mr-1 flex items-center">
                                        {activityItem.creator_type === 'ai' &&

                                          <SparklesIcon className='w-3 h-3 text-dark-black-200' />

                                        }



                                        {activityItem.creator_type === 'ai' &&
                                          <>
                                            {getMemberDetails(activityItem.created_by)}
                                            <div className='text-dark-black-200 ml-1 flex items-center gap-1'>
                                              {activityItem.item_activity_text}
                                            </div>
                                          </>
                                        }
                                        {activityItem.creator_type === 'user' &&
                                          <>
                                            {getMemberDetails(activityItem.created_by, activityItem.creator_type)}

                                            <span className=''>
                                              {activityItem.item_activity_type === 'comment' && 'commented'}</span>
                                            <div className='text-dark-black-200 ml-1 flex items-center gap-1'> {activityItem.item_activity_text}</div>
                                          </>
                                        }


                                        {activityItem.creator_type === 'customer' &&
                                          <>
                                            {getMemberDetails(activityItem.created_by, activityItem.creator_type)}

                                            <span className='mr-[3px]'>
                                              {activityItem.item_activity_type === 'comment' && 'commented'}</span>
                                            <div className='text-dark-black-200 ml-1 flex items-center gap-1'> {activityItem.item_activity_text}</div>
                                          </>
                                        }

                                      </span>}

                                    </p>


                                  </>
                                )}
                              </li>
                            )
                          })}
                        </ul>

                      </>
                    </div>
                  </div>
                </div>



              </main>

            </div>
          </div>
        </div>
      ))}

    </>
  );
};

export default ViewFeedbackQuickMode;

