import { useEffect } from 'react';
import { Toaster, toast } from 'sonner';

type AlertProps = {
	show: boolean;
	setShow: (show: boolean) => void;
	data: {
		status: number;
		message: string;
	};
};

const AlertPopup: React.FC<AlertProps> = ({ show, setShow, data }) => {
	useEffect(() => {
		if (show) {
			if (data?.status === 200 || data?.status === 201) {
				toast.success(data.message);
			} else {
				toast.error(data.message);
			}
			setTimeout(() => {
				setShow(false);
			}, 3000);
		}
	}, [show, setShow, data]);

	return (
		<>
			<Toaster />
		</>
	);
};

export default AlertPopup;
