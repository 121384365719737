import React, { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

interface ViewResponseQuickModeProps {
  columns: any[];
  data: any;
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isOpen: boolean) => void;
}

const ViewResponseQuickMode: React.FC<ViewResponseQuickModeProps> = ({
  columns,
  data,
  isSidebarOpen,
  setIsSidebarOpen,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const navigate = useNavigate()

  const handleClose = () => {
    setIsSidebarOpen(false);
  };

  const handlePrevious = () => {
    setSelectedIndex((prev) => (prev > 0 ? prev - 1 : prev));
  };


  const handleNavigate = () => {
    navigate(`/feedback/${data.item_id}`)
  };

  const customerObject = JSON.parse(data.customer_data);
  const date = new Date(data.created_at);

  // Format the date using date-fns
  const formattedDateTime = format(date, 'd MMM. yyyy -  h:mm a');


  return (
    <div className="flex flex-col h-full bg-white w-full">
      <div className="flex justify-between items-start py-4 px-[1.5rem] border-b">
        <div>
          <h2 className="text-black-100 font-medium text-sm">#{data.response_id}</h2>
          <p className='text-gray-500 mt-2 text-[13px]'>{formattedDateTime}</p>
        </div>
        {/* <p>{data.created_at}</p> */}
        <div className='flex justify-between items-center gap-2'>
          {
            data?.item_id && (
              <>
                <button
                  onClick={handleNavigate}
                  className="rounded-md duration-200 font-[600] text-[0.8125rem] h-[32px] w-[32px] flex items-center justify-center border border-solid border-[#e7e7e8]"
                  style={{
                    transitionProperty:
                      "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform"
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                    data-slot="icon"
                    className="w-[1.1em] h-[1.1em] text-[#000]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                    />
                  </svg>
                </button>
              </>
            )
          }


          <button
            onClick={handleClose}
            style={{ transitionProperty: 'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform' }}
            className='rounded-md duration-200 font-[600] text-[0.8125rem] h-[32px] w-[32px] flex items-center justify-center border border-solid border-[#e7e7e8]'>
            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false"
              height="1.1em" width="1.1em" xmlns="http://www.w3.org/2000/svg">
              <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="9" y1="3" x2="9" y2="21"></line></svg>
          </button>
        </div>

      </div>
      <div className=' overflow-y-auto feedback-interation-res'>
        {
          (customerObject.customer_name || customerObject.customer_email || customerObject.customer_phone_no) && (
            <>

              <div className='px-[1.5rem] mt-5 h-auto'>

                <div className='flex-auto rounded-md p-4 cursor-pointer ring-1 ring-inset ring-gray-200 feedback-delete-hover w-full'>
                  <p className=' block items-center leading-5 uppercase rounded-lg bg-transparent text-dark-black-200 font-[400] text-[12px] focus:outline-none justify-start'>
                    Customer Details
                  </p>
                  {customerObject.customer_name && <p>{customerObject.customer_name}</p>}
                  {customerObject.customer_email && <p>{customerObject.customer_email}</p>}
                  {customerObject.customer_phone_no && <p>{customerObject.customer_phone_no}</p>}
                </div>
              </div>
            </>
          )
        }


        <div className="flex-grow py-4 px-[1.5rem] flex items-start flex-col gap-y-6 ">
          {columns.slice(1).map((column) => {

            return (
              <div
                key={column.accessorKey}
                className="flex-auto rounded-md p-4 cursor-pointer ring-1 ring-inset ring-gray-200 feedback-delete-hover w-full"
              >
                <div className='flex items-start justify-between w-full'>
                  <div className='w-[90%]'>
                    <h3 className="text-sm font-medium text-gray-500">{column.header}</h3>
                    <p className="mt-1 text-sm text-gray-900">
                      {data[column.accessorKey] ?? ""}
                    </p>
                  </div>
                  <div className='w-[10%] flex justify-end'>
                    <p className='inline-flex capitalize items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit'>
                      {column.type}</p>
                  </div>
                </div>


              </div>
            );
          })}


        </div>
      </div>



    </div>
  );
};

export default ViewResponseQuickMode;