
import { _baseRequest } from "../../../services/api";
// import { COMMON } from "../../../utils/common";

const API_END_POINT = {
    GETPROFILE: "/v1/profile",
    GETPROFILEUPDATE: "/v1/profile/update",
    TWOSTEP: "/v1/profile/two/step",
    CHANGEPASSWORD: "/v1/profile/change/password",
    GETSESSION: "/v1/auth/sessions",

}

export const _getProfileDetails = async () => {
    return _baseRequest(API_END_POINT.GETPROFILE, "GET").then((response) => {
        return response;
    }).catch((error) => {
        return error
    });
};

export const _updateProfile = async (
    name: string,
) => {
    return _baseRequest(API_END_POINT.GETPROFILEUPDATE, "PUT", {
        name
    }).then((res) => {
        console.log("res", res)
        return res
    }).catch((error) => {
        console.log("error", error)
        return error
    });
};

export const _twoStep = async (
    two_step: boolean,
) => {
    return _baseRequest(API_END_POINT.TWOSTEP, "PUT", {
        two_step,
    }).then((res) => {
        return res
    }).catch((error) => {
        return error
    });
};

export const changepassword = async (
    old_password: string,
    password: string,
) => {
    return _baseRequest(API_END_POINT.CHANGEPASSWORD, "PUT", {
        old_password,
        password
    }).then((res) => {
        return res
    }).catch((error) => {
        return error
    });
};

export const _getSessions = async () => {
    return _baseRequest(API_END_POINT.GETSESSION, "GET").then((response) => {
        return response;
    }).catch((error) => {
        return error
    });
};
