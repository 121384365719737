import { _baseRequest } from "../../../services/api";
import { COMMON } from "../../../utils/common";
import { FeedbackType } from './feedbacks.types';

const API_END_POINT = {
    SUBMITFEEDBACKDATA: "/v1/item/add",
    FETCHFEEDBACKDATA: "/v1/item/{0}/{1}/itemDetails",
    ITEMUPDATEDATA: "/v1/item/{0}/{1}/update",
    FETCHFEEDBACKACTIVITYBYID: "/v1/item-activity/{0}/{1}/activity",
    FETCHFEEDBACKACTIVITY: "/v1/item-activity/{0}/add",
    DELETEITEMACTIVITY: "/v1/item-activity/{0}/{1}/delete",
    FETCHITEMINSIGHT: "/v1/item-insight/{0}/{1}/listInsight",
    FETCHALLACTIVITYBYBASEID: "/v1/item-activity/{0}/activityList",
    ADDINSIGHTDATA: '/v1/item-insight/{0}/addInsight',
    UPDATEINSIGHTDATA: '/v1/item-insight/{0}/{1}/updateInsight',
    DELETEINSIGHTDATA: '/v1/item-insight/{0}/{1}/deleteInsight',
    FETCHINSIGHTBYID: '/v1/item-insight/{0}/{1}/byid/insight',
    DELETEMULTIFEEDBACK: '/v1/item/{0}/deleteItems',
    FETCHFEEDBACKLIST: "/v1/item/list/{0}/items",
    SUBMUTFEEDBACKATTACHMENT: "/v1/item-attachments/{0}/add"

}

export const _submitFeedbackAPI = async (feedback: FeedbackType): Promise<{ data: any }> => {
    return _baseRequest(
        COMMON.stringFormat(API_END_POINT.SUBMITFEEDBACKDATA),
        "POST",
        feedback
    ).then((response) => {
        return response;
    }).catch((error) => {
        throw error;
    });
};


export const _fetchFeedbackAPI = async (id: number, baseId: number): Promise<{ data: any }> => {
    return _baseRequest(
        COMMON.stringFormat(API_END_POINT.FETCHFEEDBACKDATA, id, baseId),
        "GET",
    ).then((response) => {
        return response;
    }).catch((error) => {
        throw error;
    });
};

export const _fetchFeedbackActivitybyId = async (id: number, baseId: number): Promise<{ data: any }> => {
    return _baseRequest(
        COMMON.stringFormat(API_END_POINT.FETCHFEEDBACKACTIVITYBYID, baseId, id),
        "GET",
    ).then((response) => {

        return response.data;
    }).catch((error) => {
        throw error;
    });
};

export const _submitItemActivity = async (baseId: number, feedback: FeedbackType): Promise<{ data: any }> => {
    return _baseRequest(
        COMMON.stringFormat(API_END_POINT.FETCHFEEDBACKACTIVITY, baseId),
        "POST",
        feedback
    ).then((response) => {
        return response;
    }).catch((error) => {
        throw error;
    });
};

export const _deleteItemActivity = async (baseId: number, itemId: number, feedback: FeedbackType): Promise<void> => {
    return _baseRequest(
        COMMON.stringFormat(API_END_POINT.DELETEITEMACTIVITY, baseId, itemId),
        "DELETE",
        feedback
    ).then((response) => {
        return response;
    }).catch((error) => {
        throw error;
    });
};

export const _fetchItemInsight = async ( baseId: number, id: number): Promise<{ data: any }> => {
    return _baseRequest(
        COMMON.stringFormat(API_END_POINT.FETCHITEMINSIGHT, baseId, id),
        "GET",
    ).then((response) => {
        return response;
    }).catch((error) => {
        throw error;
    });
};

export const _updateFeedbackAPI = async (id: number, baseId: number, feedback: FeedbackType): Promise<{ data: any }> => {
    return _baseRequest(
        COMMON.stringFormat(API_END_POINT.ITEMUPDATEDATA, id, baseId),
        "PUT",
        feedback
    ).then((response) => {
        return response;
    }).catch((error) => {
        throw error;
    });
};

export const _fetchAllActivityByBaseId = async (baseId: number): Promise<{ data: any }> => {
    return _baseRequest(
        COMMON.stringFormat(API_END_POINT.FETCHALLACTIVITYBYBASEID, baseId),
        "GET",
    ).then((response) => {
        return response;
    }).catch((error) => {
        throw error;
    });
};


export const _submitInsightAPI = async (baseId: number, feedback: FeedbackType): Promise<{ data: any }> => {

    return _baseRequest(
        COMMON.stringFormat(API_END_POINT.ADDINSIGHTDATA, baseId),
        "POST",
        feedback
    ).then((response) => {
        return response;
    }).catch((error) => {
        console.error('API call error:', error);
        throw error;
    });
};

export const _updateInsightAPI = async (baseId: number, id: number, feedback: FeedbackType): Promise<{ data: any }> => {
    return _baseRequest(
        COMMON.stringFormat(API_END_POINT.UPDATEINSIGHTDATA, baseId, id),
        "PUT",
        feedback
    ).then((response) => {
        return response;
    }).catch((error) => {
        throw error;
    });
};


export const _deleteInsightAPI = async (baseId: number, itemId: number, feedback: FeedbackType): Promise<{ data: any }> => {
    return _baseRequest(
        COMMON.stringFormat(API_END_POINT.DELETEINSIGHTDATA, baseId, itemId),
        "DELETE",
        feedback
    ).then((response) => {
        return response;
    }).catch((error) => {
        throw error;
    });
};

export const _fetchInsightbyId = async (baseId: number, itemId: number): Promise<{ data: any }> => {
    return _baseRequest(
        COMMON.stringFormat(API_END_POINT.FETCHINSIGHTBYID, baseId, itemId),
        "GET",
    ).then((response) => {
        return response.data;
    }).catch((error) => {
        throw error;
    });
};

export const _deleteItemMulti = async (baseId: number, itemIds: number[]): Promise<any> => {
    return _baseRequest(
      COMMON.stringFormat(API_END_POINT.DELETEMULTIFEEDBACK, baseId),
      "DELETE",
      { itemIds }
    ).then((response) => {
      return response; // Return the full response
    }).catch((error) => {
      throw error;
    });
  };

  export const _fetchFeedbackListing = async (
    baseId: number,
    item_type: string,
    item_base_status_id: number,
    page: number,
    filters: Record<string, any>
  ): Promise<{ data: FeedbackType[] }> => {
    const params = new URLSearchParams({
      item_type: item_type ?? '',
      item_base_status_id: (item_base_status_id ?? 0).toString(),
      page: (page ?? 1).toString(),
      ...filters
    }).toString();
  
    return _baseRequest(
      `${COMMON.stringFormat(API_END_POINT.FETCHFEEDBACKLIST, baseId)}?${params}`,
      "GET",
    ).then((response) => {
      return response;
    }).catch((error) => {
      throw error;
    });
};


export const _submitFeedbackAttachment = async (baseId: number, file: File): Promise<{ data: any }> => {
    const formData = new FormData();
    formData.append('attachments', file, file.name);

    try {
        const response = await _baseRequest(
            `/v1/item-attachments/${baseId}/add`,
            'POST',
            formData,
            { 'Content-Type': 'multipart/form-data' }
        );
        return response;
    } catch (error) {
        throw error;
    }
};