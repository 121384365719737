import { _baseRequest } from "../../../services/api";
import { COMMON } from "../../../utils/common";
import { InsightType, UpdateTicketInsightPayload } from "./insight.types";

const API_END_POINT = {
    FETCHINSIGHTDATA: "/v1/item-insight/{0}/Insights",
    SUBMITINSIGHTGROUP: "/v1/insight-group/{0}/add",
    FETCHINSIGHTGROUPLIST: "/v1/insight-group/{0}/insightGroups",
    DELETEINSIGHTGROUP: "/v1/insight-group/{0}/deleteGroup",
    DELETEINSIGHTITEM: "/v1/item-insight/{0}/deleteInsight",
    FETCHITEMTOPICINSIGHT: "/v1/item-insight/{0}/{1}/listTopicInsight",
    UPDATETICKETINSIGHTDATA: "/v1/item-insight/{0}/update/assign/insightTickets",
    FETCHINSIGHTDASHBOARD : "/v1/item-insight/{0}/Insights"
}

export const _fetchInsightAPI = async (baseId: number, queryParams?: URLSearchParams): Promise<{ data: any, config: { url: string } }> => {
    let url = COMMON.stringFormat(API_END_POINT.FETCHINSIGHTDATA, baseId);
    if (queryParams) {
        url += `?${queryParams.toString()}`;
    }

    return _baseRequest(url, "GET")
        .then((response) => {
            return { data: response, config: { url } };
        })
        .catch((error) => {
            throw error;
        });
};

export const _submitInsightGroup = async (baseId: number, insightGroupData: { insight_group: string, insight_group_sentiment: string }): Promise<{ data: any }> => {
    return _baseRequest(
        COMMON.stringFormat(API_END_POINT.SUBMITINSIGHTGROUP, baseId),
        "POST",
        insightGroupData
    ).then((response) => {
        return response;
    }).catch((error) => {
        throw error;
    });
};

export const _fetchInsightGroupList = async (baseId: number): Promise<{ data: any }> => {
    return _baseRequest(
        COMMON.stringFormat(API_END_POINT.FETCHINSIGHTGROUPLIST, baseId),
        "GET",
    ).then((response) => {
        return response;
    }).catch((error) => {
        throw error;
    });
};

export const _deleteInsightGroupMulti = async (baseId: number, groupIds: number[]): Promise<void> => {
    return _baseRequest(
        COMMON.stringFormat(API_END_POINT.DELETEINSIGHTGROUP, baseId),
        "DELETE",
        { groupIds } 
    ).then((response) => {
        return response;
    }).catch((error) => {
        throw error;
    });
};


export const _deleteInsightMulti = async (baseId: number, insightIds: number[]): Promise<void> => {
    return _baseRequest(
        COMMON.stringFormat(API_END_POINT.DELETEINSIGHTITEM, baseId),
        "DELETE",
        { insightIds } // This will create the correct format: { "groupIds": [41, 40] }
    ).then((response) => {
        return response;
    }).catch((error) => {
        throw error;
    });
};

export const _fetchItemTopicInsight = async ( baseId: number, id: number): Promise<{ data: any }> => {
    return _baseRequest(
        COMMON.stringFormat(API_END_POINT.FETCHITEMTOPICINSIGHT, baseId, id),
        "GET",
    ).then((response) => {
        return response;
    }).catch((error) => {
        throw error;
    });
};

export const _updateTicketInsightAPI = async (baseId: number, payload: UpdateTicketInsightPayload): Promise<{ data: any }> => {
    return _baseRequest(
      COMMON.stringFormat(API_END_POINT.UPDATETICKETINSIGHTDATA, baseId),
      "PUT",
      payload
    ).then((response) => {
      return response;
    }).catch((error) => {
      throw error;
    });
  };
