// routes.tsx

import {
	Bars3Icon,
	BookmarkSquareIcon,
	HashtagIcon,
	InboxIcon,
	MagnifyingGlassIcon,
	RectangleStackIcon,
	SquaresPlusIcon,
	UserIcon,
} from '@heroicons/react/24/outline'
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import { HomeIconDashboard } from '../../assets/images/icons';
import Inbox from './pages/Inbox';
import Topics from './pages/Topics';
import Insights from './pages/Insights';
import Boards from './pages/Boards';
import Integration from './pages/Integration';
import Customer from './pages/Customer';


type RouteProps = {
	layout: string;
	pages: {
		icon: React.FC<React.SVGProps<SVGSVGElement>>;
		name: string;
		path: string;
		id: string;
		component: () => JSX.Element;
		current: boolean;
	}[];
}[]



export const routes: RouteProps = [
	{
		layout: "dashboard",
		pages: [
			{
				icon: HomeIconDashboard,
				name: "Dashboard",
				path: "/dashboard",
				id: "dashboard",
				component: Home,
				current: true
			},
			{
				icon: InboxIcon,
				name: "Inbox",
				path: "/inbox",
				id: "inbox",
				component: Inbox,
				current: false
			},
			{
				icon: HashtagIcon,
				name: "Feedbacks",
				path: "/feedbacks",
				id:"feedbacks",
				component: Profile,
				current: false
			},
			{
				icon: RectangleStackIcon,
				name: "Insights",
				path: "/insights",
				id:"insights",
				component: Insights,
				current: false
			},
			{
				icon: Bars3Icon,
				name: "Tickets",
				path: "/tickets",
				id:"tickets",
				component: Topics,
				current: false
			},
			{
				icon: SquaresPlusIcon,
				name: "Integrations",
				path: "/integrations",
				id:"integrations",
				component: Integration,
				current: false
			},
			{
				icon: UserIcon,
				name: "Customers",
				path: "/customers",
				id:"customers",
				component: Customer,
				current: false
			}
		],
	},
];
