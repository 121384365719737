

// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { fetchAllStepsIntergrationDetails, fetchIntergrationDetailsPublic, submitStepIntegrationResponse } from '../integration-redux/integration.actions';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import DynamicSurveyForm from './DynamicSurveyForm';
import { FormElement } from '../integration-steps/types';
import surveyLogo from "../../../assets/images/surveylogo.avif"
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, Input } from '@lemonsqueezy/wedges';
import { FlagIcon } from '@iconicicons/react';
import { motion } from "framer-motion";
import { Toaster, toast } from 'sonner';

interface FormValues {
  customer_name: string;
  customer_email_id: string;
  customer_phone_no: string;
}

interface ViewSurveyModeProps {
  onBlur?: (data: { [key: string]: string }) => void;
}

function ViewSurveyMode() {
  const dispatch = useAppDispatch();
  const { data, loading, error } = useAppSelector(state => state.integration);
  const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;
  const { id } = useParams<{ id: string }>();
  const [formSteps, setFormSteps] = useState<FormElement[]>([]);
  const [proceedState, setProceedState] = useState(false)
  const [formSurvey, setFormSurvey] = useState(false)
  const [customerData, setCustomerData] = useState({ name: '', email: '', phone: '' });
  const [responseId, setResponseId] = useState<string | null>(null);
  const [integrationResponse, setIntegrationResponse] = useState<any[]>([]);
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);


  useEffect(() => {
    if (showThankYouMessage) {

      const timer = setTimeout(() => {
        window.location.reload();
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [showThankYouMessage]);


  useEffect(() => {
    if (id) {
      dispatch(fetchIntergrationDetailsPublic(id)).catch(error => {
        console.error('Error fetching integration details:', error);
      });
    } else {
      console.error('id or baseId is missing');
    }
  }, [dispatch, id]);

  useEffect(() => {
    const fetchSteps = async () => {
      if (data[0]?.id && baseId) {
        try {
          const response = await dispatch(fetchAllStepsIntergrationDetails(baseId, data[0].id));
          if (Array.isArray(response)) {
            const formattedElements = response.map(step => ({
              ...step,
              id: step.id || uuidv4(),
              type: step.step_input_type,
              headerText: step.step_title,
              required: step.step_mandatory,
              options: JSON.parse(step.step_input_options || '{}')
            }));
            setFormSteps(formattedElements);
          } else {
            console.log("Response is not an array:", response);
          }
        } catch (error) {
          console.error("Failed to fetch integration steps:", error);
        }
      }
    };
    fetchSteps();
  }, [dispatch, data[0]?.id, baseId]);

  let customerDataErrors = {}

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .when('customer_name', (customerName, schema) => {
        let data = customerName?.toString() === 'yes'
          ? schema
            .max(50, 'Please enter valid name')
            .required('Name is required')
          : schema;
        return data
      }),

    email: Yup.string()
      .when('customer_email_id', (customerEmailId, schema) => {
        let data = customerEmailId?.toString() === 'yes'
          ? schema
            .email('Invalid email')
            .max(30, 'Please enter valid email address')
            .required('Email is required')
          : schema;
        return data
      }),


    phone: Yup.string().when('customer_phone_no', (customerPhoneNo, schema) => {
      let data = customerPhoneNo?.toString() === 'yes'
        ? schema
          .matches(/^[0-9]+$/, "Must be only digits")
          .min(6, 'Please enter valid phone no.')
          .max(15, 'Please enter valid phone no.')
          .required("Phone number is required")
        : schema.nullable();
      return data
    }),
  });


  const initialValues: FormValues = {
    customer_name: data[0]?.integration.customer_name || '',
    customer_email_id: data[0]?.integration.customer_email_id || '',
    customer_phone_no: data[0]?.integration.customer_phone_no || '',
  };

  // useEffect(() => {
  //   localStorage.removeItem('surveyResponseId');
  // }, []);

  // const handleProceed = () => {
  //   const newResponseId = uuidv4();
  //   localStorage.setItem('surveyResponseId', newResponseId);

  //   setProceedState(true);
  //   setResponseId(newResponseId);
  // };

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('surveyResponseId');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleProceed = () => {
    const newResponseId = uuidv4();
    localStorage.setItem('surveyResponseId', newResponseId);

    setProceedState(true);
    setResponseId(newResponseId);
  };


  const handleSurvey = () => {


    if (data[0]?.integration.customer_info == 'yes') {
      let errors = {}
      let error = false
      if (data[0]?.integration.customer_name === 'yes') {
        if (!customerData.name) {
          error = true
          customerDataErrors.name = 'Name is required'
          // toast.error('Name is required');
        } else if (customerData.name.length > 50) {
          error = true
          customerDataErrors.name = 'Please enter a valid name';
        } else {
          customerDataErrors.name = null
        }
      }

      // Validate email
      if (data[0]?.integration.customer_email_id === 'yes') {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!customerData.email) {
          error = true
          customerDataErrors.email = 'Email is required';
          // toast.error('Email is required');
        } else if (!emailRegex.test(customerData.email)) {
          error = true
          customerDataErrors.email = 'Invalid email';
        } else if (customerData.email.length > 30) {
          error = true
          customerDataErrors.email = 'Please enter a valid email address';
        } else {
          customerDataErrors.email = null
        }
      }

      // Validate phone
      if (data[0]?.integration.customer_phone_no === 'yes') {
        const phoneRegex = /^[0-9]+$/;
        if (!customerData.phone) {
          error = true
          customerDataErrors.phone = 'Phone number is required'
          errors.phone = 'Phone number is required';
          // toast.error('Phone number is required');
        } else if (!phoneRegex.test(customerData.phone)) {
          error = true
          customerDataErrors.phone = 'Must be only digits';
        } else if (customerData.phone.length < 6 || customerData.phone.length > 15) {
          error = true
          customerDataErrors.phone = 'Please enter a valid phone number';
        } else {
          customerDataErrors.phone = null
        }
      }

      if (error) return;
    }
    setFormSurvey(true)
  }




  const handleSubmitStepIntegrationResponse = async (customerData: any, integrationResponse: any[], errors: FormikErrors<FormValues> | undefined,
    touched: FormikTouched<FormValues> | undefined, isFull: boolean = false) => {


    if (id) {
      try {
        const transformedCustomerData = {
          customer_name: customerData.name,
          customer_email: customerData.email,
          customer_phone_no: customerData.phone
        };
        const hasValidationErrors = errors
          ? Object.keys(touched).some(key => touched[key as keyof FormValues] && errors[key as keyof FormValues])
          : false;

        if (customerDataErrors.name) hasValidationErrors = true
        if (customerDataErrors.email) hasValidationErrors = true
        if (customerDataErrors.phone) hasValidationErrors = true


        if (hasValidationErrors) {
          toast.error("Please fill all required field");
          return;
        }

        await new Promise(resolve => setTimeout(resolve, 1000));
        const payload = {
          integration_id: id,
          response_id: localStorage.getItem('surveyResponseId'),
          customer_data: transformedCustomerData,
          integration_response: integrationResponse,
          feedback_response: isFull ? 'full' : 'partial'
        };

        await dispatch(submitStepIntegrationResponse(
          id,
          JSON.stringify(payload.customer_data),
          JSON.stringify(payload.integration_response),
          payload.feedback_response
        ));
      } catch (error) {
        console.error('Error dispatching action:', error);
      }
    } else {
      toast.error('Please fill required field');
    }
  };

  const handleBlur = async (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    formikHandleBlur: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void,
    values: FormValues,
    errors: FormikErrors<FormValues>,
    touched: FormikTouched<FormValues>
  ) => {
    formikHandleBlur(e);
    const { name, value } = e.target;
    setFieldTouched(name, true, true);
    const updatedCustomerData = { ...customerData, [name]: value };
    setCustomerData(updatedCustomerData);
    await handleSubmitStepIntegrationResponse(updatedCustomerData, integrationResponse, errors, touched);
  };


  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    formikHandleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void
  ) => {
    formikHandleChange(e);
    setFieldTouched(e.target.name, true, true);
  };


  if (loading) {
    return (
      <div className='flex-1 p-0 h-full'>
        <div className='min-h-full h-full max-w-full' style={{ margin: '0px auto' }}>
          <div className='flex items-stretch flex-row gap-0 w-full h-full relative overflow-x-hidden overflow-y-hidden'>
            <main className='relative flex-1 flex'>
              <div className='overflowing-element hovered mq1023:max-w-full mq1800:max-w-[100%] mq3000:max-w-[100%] flex-1 w-full overflow-y-auto h-full'>
                <div className='max-w-full md:max-w-[710px] lg:max-w-[710px] m-auto h-full'>
                  <div className="simple-spinner-loader h-full flex items-center justify-center">
                    <span></span>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className='flex-1 p-0 h-full'>
        <div className='min-h-full h-full max-w-full' style={{ margin: '0px auto' }}>
          <div className='flex items-stretch flex-row gap-0 w-full h-full relative overflow-x-hidden overflow-y-hidden'>
            <main className='relative flex-1 flex'>
              <div className='overflowing-element hovered mq1023:max-w-full mq1800:max-w-[100%] mq3000:max-w-[100%] flex-1 w-full overflow-y-auto h-full'>
                <div className='max-w-full md:max-w-[710px] lg:max-w-[710px] m-auto h-full'>
                  <div className=' text-[16px] leading-6 font-medium text-gray-700 h-full flex items-center justify-center'>Nothing found here</div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>

    );
  }

  if (data?.length === 0) {
    return (
      <div className='flex-1 p-0 h-full'>
        <div className='min-h-full h-full max-w-full' style={{ margin: '0px auto' }}>
          <div className='flex items-stretch flex-row gap-0 w-full h-full relative overflow-x-hidden overflow-y-hidden'>
            <main className='relative flex-1 flex'>
              <div className='overflowing-element hovered mq1023:max-w-full mq1800:max-w-[100%] mq3000:max-w-[100%] flex-1 w-full overflow-y-auto h-full'>
                <div className='max-w-full md:max-w-[710px] lg:max-w-[710px] m-auto h-full'>
                  <div className="simple-spinner-loader h-full flex items-center justify-center">
                    <span></span>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Toaster richColors />
      <div className='flex-1 p-0 h-full bg-[#f5f5f5]'>

        <div className='min-h-full max-w-full' style={{ margin: '0px auto' }}>
          <div className='flex items-stretch flex-row gap-0 w-full h-full relative overflow-x-hidden overflow-y-hidden'>
            <main className='relative flex-1 flex'>
              <div
                className='overflowing-element hovered mq1023:max-w-full mq1800:max-w-[100%] mq3000:max-w-[100%] flex-1 w-full px-5 lg:px-[40px] overflow-y-auto h-screen'>
                <div className='max-w-full md:max-w-[710px] lg:max-w-[710px] m-auto pt-10 h-full'>
                  <div className='min-h-[200px] max-h-[200px] h-auto relative'>
                    <img
                      src={`${process.env.REACT_APP_API_BASE_URL}/${data[0]?.base?.cover_image}`}
                      // onError={(e) => {
                      //   e.target.onerror = null; // prevents looping
                      //   e.target.src = "https://images.unsplash.com/photo-1579546929518-9e396f3cc809?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxNTE3NDZ8MHwxfGFsbHw5OXx8fHx8fDF8fDE2NTA1Nzk1MzA&ixlib=rb-1.2.1&q=80&w=1080";
                      // }}
                      alt="Cover Image"
                      className='w-full object-cover rounded-2xl h-full max-h-[200px]' />

                    <img
                      src={`${process.env.REACT_APP_API_BASE_URL}/${data[0]?.base?.logo}`}
                      // onError={(e) => {
                      //   e.target.onerror = null; // prevents looping
                      //   e.target.src = surveyLogo
                      // }}
                      alt="Cover Image"
                      className='h-[88px] w-[88px] border-[5px] border-solid border-[#f5f5f5]  rounded-2xl absolute top-[152px] left-[46px]' />

                  </div>
                  <div className='px-0 md:px-2 lg:px-[46px] w-full max-w-full'>
                    {!showThankYouMessage &&
                      <>
                        <div className="mb-4 mt-[85px] min-h-[40px] h-[100%] w-full p-0 text-[20px] md:text-[24px] lg:text-[24px] leading-7 lg:leading-[42px] font-medium text-dark-black-100">
                          {data[0].integration?.integration_name}
                        </div>
                        <div className="">
                          <div className="border-none outline-none bg-transparent focus:outline-none focus:ring-0 text-[14px] md:text-[16px] lg:text-[16px] leading-6 font-normal text-dark-black-100 w-full p-0 m-0 placeholder:text-[#9e9ea0]">
                            {data[0].integration?.integration_detail}
                          </div>
                        </div>
                        <hr className='my-8'></hr>
                      </>
                    }



                    {/* {
                      (data[0].integration.customer_name === 'yes' || data[0].integration.customer_email_id === 'yes' || data[0].integration.customer_phone_no === 'yes') && */}

                    <div>
                      {
                        !proceedState &&
                        <button
                          onClick={handleProceed}
                          className='group inline-flex shrink-0 select-none items-center justify-center text-sm font-medium
    leading-6 transition-colors duration-100 wg-antialiased focus:outline-0 focus-visible:outline focus-visible:outline-2 
    focus-visible:outline-offset-2 disabled:pointer-events-none gap-0 px-8  py-2 rounded-lg text-white outline-primary
    disabled:opacity-50 bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600'
                        >
                          {`Share Feedback ->`}
                        </button>
                      }
                    </div>
                    {/* } */}

                    {
                      proceedState &&
                      <>
                        <Formik
                          initialValues={initialValues}
                          validationSchema={validationSchema}
                          // onSubmit={(values, { touched, errors }) => {
                          //   handleSubmitStepIntegrationResponse(values, integrationResponse, errors, touched);
                          // }}
                          onSubmit={(values, { setSubmitting, errors, touched }) => {
                            handleSubmitStepIntegrationResponse(values, integrationResponse, errors, touched);
                            setSubmitting(false);
                          }}
                        >
                          {({ errors, touched, isSubmitting, handleChange: formikHandleChange, handleBlur: formikHandleBlur, values, setFieldTouched, submitForm }) => (

                            <Form noValidate>
                              <motion.div
                                className="mb-4 mt-5 lg:mt-[45px]"
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5 }}
                              >
                                {!showThankYouMessage &&
                                  <>
                                    <div className='mb-4 mt-5 lg:mt-[45px]'>
                                      {(data[0]?.integration.customer_name === "yes" ||
                                        data[0]?.integration.customer_email_id === "yes" ||
                                        data[0]?.integration.customer_phone_no === "yes") && (
                                          <div className='flex flex-col gap-y-6 w-full'>
                                            <p className='items-center uppercase rounded-lg bg-transparent text-dark-black-200 font-[500] text-[0.8125rem] focus:outline-none justify-start inline-flex'>
                                              Share us your detail
                                            </p>

                                            {data[0]?.integration.customer_name === "yes" && (
                                              <div className='flex flex-col items-start gap-y-1 w-full'>
                                                <label htmlFor="text" className="wg-label capitalize inline-flex shrink-0 items-center gap-1 text-[14px] text-[#2f2f31] wg-antialiased  leading-5 font-medium">
                                                  Name
                                                  {
                                                    data[0]?.integration.customer_info === "yes" && <span className="text-red-500 ml-1">*</span>
                                                  }

                                                </label>
                                                <div className="mt-1 w-full">
                                                  <Field
                                                    as="input"
                                                    className="flex w-full grow rounded-lg border bg-background px-4 py-2 h-[42px] md:h-[38px] lg:h-[38px] border-[#d1d5db] focus:!border-none focus:shadow-none focus:border-transparent
                                                     text-sm leading-5 shadow-sm transition-colors duration-100 placeholder:text-surface-500 outline-primary focus:outline focus:outline-2
                                                      focus:-outline-offset-1 text-surface-900  focus:outline-indigo-600 focus:ring-0"

                                                    id="name"
                                                    name="name"
                                                    type="text"
                                                    autoComplete="name"
                                                    placeholder='Enter your name'

                                                    onBlur={(e) => handleBlur(e, formikHandleBlur, setFieldTouched, values, errors, touched)}
                                                    onChange={(e) => handleChange(e, formikHandleChange, setFieldTouched)}
                                                    value={values.name}
                                                  />
                                                  {(touched.name && errors.name) ? (
                                                    <div className="text-red-500 text-[13px] mt-1">{errors.name}</div>
                                                  ) : customerDataErrors.name ? (
                                                    <div className="text-red-500 text-[13px] mt-1">{customerDataErrors.name}</div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            )}
                                            {data[0]?.integration.customer_email_id === "yes" && (
                                              <div className='flex flex-col items-start gap-y-1'>
                                                <label htmlFor="text" className="wg-label capitalize inline-flex shrink-0 items-center gap-1 text-[14px] text-[#2f2f31] wg-antialiased  leading-5 font-medium">
                                                  email
                                                  {
                                                    data[0]?.integration.customer_info === "yes" && <span className="text-red-500 ml-1">*</span>
                                                  }
                                                </label>
                                                <div className="mt-1 w-full">
                                                  <Field
                                                    as="input"
                                                    className="flex w-full grow rounded-lg border bg-background px-4 py-2 h-[42px] md:h-[38px] lg:h-[38px] border-[#d1d5db] focus:!border-none focus:shadow-none focus:border-transparent
                                                    text-sm leading-5 shadow-sm transition-colors duration-100 placeholder:text-surface-500 outline-primary focus:outline focus:outline-2
                                                     focus:-outline-offset-1 text-surface-900  focus:outline-indigo-600 focus:ring-0"                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    onBlur={(e) => handleBlur(e, formikHandleBlur, setFieldTouched, values, errors, touched)}
                                                    onChange={(e) => handleChange(e, formikHandleChange, setFieldTouched)}
                                                    value={values.email}
                                                    placeholder="Enter your email .."

                                                  />
                                                  {(touched.email && errors.email) ? (
                                                    <div className="text-red-500 text-[13px] mt-1">{errors.email}</div>
                                                  ) : customerDataErrors.email ? (
                                                    <div className="text-red-500 text-[13px] mt-1">{customerDataErrors.email}
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            )}
                                            {data[0]?.integration.customer_phone_no === "yes" && (
                                              <div className='flex flex-col items-start gap-y-1'>
                                                <label htmlFor="text" className="wg-label capitalize inline-flex shrink-0 items-center gap-1 text-[14px] text-[#2f2f31] wg-antialiased  leading-5 font-medium">
                                                  Phone
                                                  {
                                                    data[0]?.integration.customer_info === "yes" && <span className="text-red-500 ml-1">*</span>
                                                  }
                                                </label>
                                                <div className="mt-1 w-full">
                                                  <Field
                                                    as="input"
                                                    className="flex w-full grow rounded-lg border bg-background px-4 py-2 h-[42px] md:h-[38px] lg:h-[38px] border-[#d1d5db] focus:!border-none focus:shadow-none focus:border-transparent
                                                    text-sm leading-5 shadow-sm transition-colors duration-100 placeholder:text-surface-500 outline-primary focus:outline focus:outline-2
                                                     focus:-outline-offset-1 text-surface-900  focus:outline-indigo-600 focus:ring-0"
                                                    id="phone"
                                                    name="phone"
                                                    type="tel"
                                                    onBlur={(e) => handleBlur(e, formikHandleBlur, setFieldTouched, values, errors, touched)}
                                                    onChange={(e) => handleChange(e, formikHandleChange, setFieldTouched)}
                                                    value={values.phone}
                                                    placeholder='Enter your phone number'
                                                  />
                                                  {(touched.phone && errors.phone) ? (
                                                    <div className="text-red-500 text-[13px] mt-1">{errors.phone}</div>
                                                  ) : customerDataErrors.phone ? (
                                                    <div className="text-red-500 text-[13px] mt-1">{customerDataErrors.phone}</div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        )}
                                    </div>
                                  </>
                                }




                              </motion.div>
                              {
                                !showThankYouMessage &&
                                <>
                                  {
                                    !formSurvey &&
                                    <button
                                      onClick={handleSurvey}
                                      className='mt-5 group inline-flex shrink-0 select-none items-center justify-center text-sm font-medium
        leading-6 transition-colors duration-100 wg-antialiased focus:outline-0 focus-visible:outline focus-visible:outline-2 
        focus-visible:outline-offset-2 disabled:pointer-events-none gap-0 px-8  py-2 rounded-lg text-white outline-primary
         disabled:opacity-50 bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600'
                                    >
                                      <div className='flex items-center gap-x-1'>
                                        {`Proceed ->`}

                                      </div>
                                    </button>
                                  }
                                </>
                              }
                              {
                                !showThankYouMessage && <>
                                  {
                                    formSurvey && <hr className='my-8'></hr>
                                  }
                                </>
                              }

                              {
                                formSurvey &&
                                <motion.div
                                  className="mb-4 mt-5 lg:mt-[45px]"
                                  initial={{ opacity: 0, y: -10 }}
                                  animate={{ opacity: 1, y: 0 }}
                                  transition={{ duration: 0.5 }}
                                >
                                  {
                                    !showThankYouMessage &&
                                    <p className='mt-[-10px] block items-center uppercase rounded-lg bg-transparent text-dark-black-200 font-[500] text-[0.8125rem] focus:outline-none
                                  justify-start'>
                                      Share us your Feedback
                                    </p>
                                  }
                                  <div className='pt-4'>

                                    <DynamicSurveyForm
                                      steps={data[0].integrationStep}
                                      formSteps={formSteps}
                                      submitStepIntegrationResponse={handleSubmitStepIntegrationResponse}
                                      formikHandleChange={handleChange}
                                      // formikHandleBlur={formikHandleBlur}
                                      // setFieldTouched={setFieldTouched}
                                      // values={values}
                                      customerData={customerData}
                                      setCustomerData={setCustomerData}
                                      integrationResponse={integrationResponse}
                                      setIntegrationResponse={setIntegrationResponse}
                                      setShowThankYouMessage={setShowThankYouMessage}
                                      showThankYouMessage={showThankYouMessage}
                                      setLoadingForm={setLoadingForm}
                                      loadingForm={loadingForm}
                                      customer_name  = {data[0]?.integration.customer_name}
                                      customer_email_id={data[0]?.integration.customer_email_id} 
                                      customer_phone_no={data[0]?.integration.customer_phone_no }
                                      customer_info= {data[0]?.integration.customer_info}
                                    />
                                  </div>


                                </motion.div>
                              }
                              {
                                showThankYouMessage &&
                                <motion.div
                                  className="mb-4 mt-5 lg:mt-[45px]"
                                  initial={{ opacity: 0, y: -10 }}
                                  animate={{ opacity: 1, y: 0 }}
                                  transition={{ duration: 0.5 }}
                                >
                                  {/* <p className='mt-[-10px] block items-center uppercase rounded-lg bg-transparent text-dark-black-200 font-[500] text-[0.8125rem] focus:outline-none
                                  justify-start'>
                                    Thank you for your feedback!
                                  </p> */}
                                  <div className='pt-4'>
                                    {/* <img
                                      className="w-full h-full object-cover"
                                      src={thankYouImage}
                                      alt="thankYouImage"

                                    /> */}
                                  </div>


                                </motion.div>
                              }



                            </Form>
                          )}
                        </Formik>


                      </>
                    }



                    {
                      (data[0]?.integration.customer_name === 'no' && data[0]?.integration.customer_email_id === 'no' && data[0]?.integration.customer_phone_no === 'no') && (
                        <>

                          <motion.div
                            className="mb-4 mt-5 lg:mt-[45px]"
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                          >
                            <p className='mt-[-10px] block items-center uppercase rounded-lg bg-transparent text-dark-black-200 font-[500] text-[0.8125rem] focus:outline-none
                   justify-start'>
                              Share us your Feedback
                            </p>
                            <div className='pt-4'>

                              <DynamicSurveyForm
                                steps={data[0].integrationStep}
                                formSteps={formSteps}
                                submitStepIntegrationResponse={handleSubmitStepIntegrationResponse}
                                formikHandleChange={handleChange}
                                // formikHandleBlur={formikHandleBlur}
                                // setFieldTouched={setFieldTouched}
                                // values={values}
                                customerData={customerData}
                                setCustomerData={setCustomerData}
                                integrationResponse={integrationResponse}
                                setIntegrationResponse={setIntegrationResponse}
                                setShowThankYouMessage={setShowThankYouMessage}
                                showThankYouMessage={showThankYouMessage}
                                setLoadingForm={setLoadingForm}
                                loadingForm={loadingForm}
                                customer_name  = {data[0]?.integration.customer_name}
                                customer_email_id={data[0]?.integration.customer_email_id} 
                                customer_phone_no={data[0]?.integration.customer_phone_no }
                                customer_info= {data[0]?.integration.customer_info}

                              />
                            </div>


                          </motion.div>
                        </>
                      )}
                  </div>

                  <div className={`max-w-full md:max-w-[710px] lg:max-w-[710px] pb-25px  md:pb-0 lg:pb-0 m-auto ${showThankYouMessage ? 'h-[54%]' : 'h-[30%] md:h-[36%] lg:h-[39%]'} flex items-center md:items-center lg:items-end w-full`}>
                    <div className='px-2 lg:px-[46px] py-5 flex items-center justify-center md:justify-between lg:justify-between flex-wrap gap-2 lg:flex-nowrap w-full'>
                      <p className='text-[14px] text-gray-700 leading-4'>{data[0]?.base?.name}</p>
                      <a href="" className='text-[14px] text-gray-700 leading-4 flex items-center gap-1'><FlagIcon className="w-[16px] h-[16px]" />Built with feedbacks.app</a>
                    </div>
                  </div>


                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>

  );
}

export default ViewSurveyMode;
