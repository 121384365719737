// projectboardApi.ts

import { _baseRequest } from "../../../services/api";
import { COMMON } from "../../../utils/common";

const API_END_POINT = {
    CREATEBOARD: "/v1/board/{0}/addBoard",
    GETBOARD: "/v1/board/{0}/Boards",
    GETBOARDBYID: "/v1/board/{0}/{1}/boardDetail",
    UPDATEBOARD: "/v1/board/{0}/{1}/updateBoard",
}


// post api
export const _create_board = async (
    value: number,
    name: string,
    description: string,
    privacy: string,
    color: string,
    boardStatus: string,
    selectRoleName: string // Added parameter
) => {
    const colorValue = color.substring(color.indexOf("[#") + 1, color.indexOf("]"));
    return _baseRequest(COMMON.stringFormat(API_END_POINT.CREATEBOARD, value), "POST", {
        base_id: value,
        name: name,
        description: description,
        color: colorValue,
        privacy: privacy,
        board_status: boardStatus,
        board_view: selectRoleName // Add this to the request payload

    }).then((response) => {
        return response;
    }).catch((error) => {
        return error
    });
};


// get board list
export const _get_board_list = async (value: number) => {
    return _baseRequest(COMMON.stringFormat(API_END_POINT.GETBOARD, value), "GET").then((response) => {
        return response;
    }).catch((error) => {
        return error
    });
};

// get board by id
export const _getBoardById = async (id: number, baseId: string) => {
    const url = COMMON.stringFormat(API_END_POINT.GETBOARDBYID, id, baseId);
    return _baseRequest(url, "GET")
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// delete board
export const _getBoardDelete = async (id: number, baseId: number) => {
    const url = `/v1/board/${id}/${baseId}/deleteBoard`; // Construct the endpoint URL with the dynamic id
    return _baseRequest(url, "DELETE").then((response) => {
        return response;
    }).catch((error) => {
        return error;
    });
};

// update board

export const _updateBoardApi = async (
    id: number,
    baseId: string,
    name: string,
    description: string,
    privacy: string,
    color: string,
    boardStatus: string,
    selectRoleName: string // Added parameter
) => {
    const colorValue = color.substring(color.indexOf("[#") + 1, color.indexOf("]")) || color;
    const url = COMMON.stringFormat(API_END_POINT.UPDATEBOARD, id, baseId); // Include baseId in the URL    
    const requestBody = {
        base_id: parseInt(baseId), // Ensure baseId is parsed as needed (if it's numeric)
        name: name,
        description: description,
        color: colorValue,
        privacy: privacy,
        board_status: boardStatus,
        board_view: selectRoleName // Add this to the request payload
    };

    return _baseRequest(url, "PUT", requestBody)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};