import { Fragment, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

type DropDownProps = {
    selectValue?: string,
    disable?: string
    data: {
        name: string
    }[],
    previousValue?: string,
    onChangeValue?: (val: string) => void,
    onDelete?: () => void; 
    channel?: boolean;
    style?: React.CSSProperties; 
}

export default function DropDown({ data, disable, onChangeValue, previousValue, onDelete, channel, style }: DropDownProps) {
    const [value, setValue] = useState(previousValue ? previousValue : "")
    return (
        <Menu as="div" className={`${channel ? '' : 'relative text-left'}`}>
            {disable === "admin" ?
                <button type="button" disabled className="capitalize w-20 flex items-center justify-between cursor-not-allowed rounded bg-slate-200 px-2 py-1 text-xs font-semibold text-white shadow-sm
             hover:bg-slate-200
             focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    <p className="block text-[12px] md:text-sm lg:text-sm font-medium leading-6 text-gray-900">{value}</p>
                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                </button>

                :
                <div>
                    <Menu.Button className="inline-flex w-full justify-between gap-x-1.5 rounded-lg bg-white py-[8px] h-[36px] px-3 text-sm font-semibold 
                    focus:outline focus:outline-2 focus:-outline-offset-1 focus:outline-indigo-600
                    text-gray-900 shadow-sm ring-1 ring-inset ring-surface-200 hover:ring-surface-300 hover:bg-white">
                        <p className='text-sm fontfamily font-[400] capitalize'>{value === "suspended" ? "Delete" : value}</p>
                        <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </Menu.Button>
                </div>
            }
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items style={style} className="w-full absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        {data && (<>
                            {data.map((item: { name: string }, index) => {
                                return (
                                    <Menu.Item key={index}>
                                        {({ active }) => (
                                            <button
                                                onClick={() => {
                                                    setValue(item.name);
                                                    onChangeValue?.(item.name);
                                                    if (item.name === "suspended" && onDelete) {
                                                        onDelete(); 
                                                    }
                                                }}
                                                className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900 capitalize w-full text-left' : 'text-gray-700 capitalize w-full text-left',
                                                    'block px-4 py-2 text-sm w-full text-left'
                                                )}
                                            >
                                                {item.name === "suspended" ? "Delete" : item.name}
                                            </button>
                                        )}
                                    </Menu.Item>
                                )
                            })}

                        </>)}

                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}
