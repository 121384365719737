import { Payload } from "recharts/types/component/DefaultLegendContent"

export const SUBMIT_CREATE_INTEGRATION_REQUEST = 'SUBMIT_CREATE_INTEGRATION_REQUEST'
export const SUBMIT_CREATE_INTEGRATION_SUCCESS = 'SUBMIT_CREATE_INTEGRATION_SUCCESS'
export const SUBMIT_CREATE_INTEGRATION_FAILURE = 'SUBMIT_CREATE_INTEGRATION_FAILURE'

export const FETCH_INTEGRATION_TABLE_LIST_REQUEST = 'FETCH_INTEGRATION_TABLE_LIST_REQUEST'
export const FETCH_INTEGRATION_TABLE_LIST_SUCCESS = 'FETCH_INTEGRATION_TABLE_LIST_SUCCESS'
export const FETCH_INTEGRATION_TABLE_LIST_FAILURE = 'FETCH_INTEGRATION_TABLE_LIST_FAILURE'

export const FETCH_INTEGRATION_DETAILS_REQUEST = 'FETCH_INTEGRATION_DETAILS_REQUEST';
export const FETCH_INTEGRATION_DETAILS_SUCCESS = 'FETCH_INTEGRATION_DETAILS_SUCCESS';
export const FETCH_INTEGRATION_DETAILS_FAILURE = 'FETCH_INTEGRATION_DETAILS_FAILURE';

export const UPDATE_INTEGRATION_DETAILS_REQUEST = 'UPDATE_INTEGRATION_DETAILS_REQUEST'
export const UPDATE_INTEGRATION_DETAILS_SUCCESS = 'UPDATE_INTEGRATION_DETAILS_SUCCESS'
export const UPDATE_INTEGRATION_DETAILS_FAILURE = 'UPDATE_INTEGRATION_DETAILS_FAILURE'

export const SELECT_ELEMENT_INTEGRATION_STEP = 'SELECT_ELEMENT_INTEGRATION_STEP';

export const SUBMIT_SURVEY_STEP_INTEGRATION_REQUEST = 'SUBMIT_SURVEY_STEP_INTEGRATION_REQUEST';
export const SUBMIT_SURVEY_STEP_INTEGRATION_SUCCESS = 'SUBMIT_SURVEY_STEP_INTEGRATION_SUCCESS';
export const SUBMIT_SURVEY_STEP_INTEGRATION_FAILURE = 'SUBMIT_SURVEY_STEP_INTEGRATION_FAILURE';

export const FETCH_ALL_STEPS_INTEGRATION_DETAILS_REQUEST = 'FETCH_ALL_STEPS_INTEGRATION_DETAILS_REQUEST';
export const FETCH_ALL_STEPS_INTEGRATION_DETAILS_SUCCESS = 'FETCH_ALL_STEPS_INTEGRATION_DETAILS_SUCCESS';
export const FETCH_ALL_STEPS_INTEGRATION_DETAILS_FAILURE = 'FETCH_ALL_STEPS_INTEGRATION_DETAILS_FAILURE';


export const DELETE_INTEGRATION_RESPONSES_LIST_REQUEST = 'DELETE_INTEGRATION_RESPONSES_LIST_REQUEST';
export const DELETE_INTEGRATION_RESPONSES_LIST_SUCCESS = 'DELETE_INTEGRATION_RESPONSES_LIST_SUCCESS';
export const DELETE_INTEGRATION_RESPONSES_LIST_FAILURE = 'DELETE_INTEGRATION_RESPONSES_LIST_FAILURE';

export const FETCH_INTEGRATION_COUNT_RESPONSE_REQUEST = 'FETCH_INTEGRATION_COUNT_RESPONSE_REQUEST';
export const FETCH_INTEGRATION_COUNT_RESPONSE_SUCCESS = 'FETCH_INTEGRATION_COUNT_RESPONSE_SUCCESS';
export const FETCH_INTEGRATION_COUNT_RESPONSE_FAILURE = 'FETCH_INTEGRATION_COUNT_RESPONSE_FAILURE';


export const CLOSE_FORM_ASIDE = 'CLOSE_FORM_ASIDE';

export interface IntegrationType {
    allActivityItem: any[];
    itemId: any;
    base_id: number;
    board_id?: number;
    item_type?: string;
    item_title?: string;
    item_text?: string;
    item_details: string;
    item_tag_id?: number;
    item_base_status_id?: [];
    item_sentiment?: string;
    item_meta?: string;
    item_revenue?: string;
    item_user_id?: number;
    item_group_id?: number;
    item_priority?: string;
    item_source_type?: string;
    item_summary?: string;
    item_source_id?: string;
    ai_check?: string;
    ai_check_time?: string;
    ai_check_source?: string;
    ai_check_tokens?: string;
    insight_ticket_id?: number;
    item_user_name?: string;
    item_user_unique_identifier?: string;
    item_user_email_id?: string;
    item_user_phone_number?: string;
    item_user_meta?: string;
    item_user_segment?: string;
    item_user_group_name?: string;
    item_user_group_unique_identifier?: string;
    item_user_group_revenue?: string;
    item_user_group_segment?: string;
    item_user_group_meta?: string;
    id?: number;
    name?: string;
    color?: string;
    base_status_title?: string;
    tag_title?: string;
    item_customer_name?: string;
    item_customer_unique_id?: string;
    item_customer_email_id?: string;
    item_customer_phone_number?: string;
    item_customer_meta?: string;
    item_customer_label?: string;
    item_customer_type?: string;
    item_customer_image?: string;
    item_customer_revenue?: string;
    item_customer_parent_id?: number;
    itemInsight?: string;
    itemContext?: string;
    itemSentiment?: string;
    attached_item?: any;
}



export interface FeedbackState {
    loading: boolean;
    error: string;
    success: boolean;
    data: any[];
    selectedElementId: string | null; 
    selectedElement: any; 
    isFormAsideOpen: boolean; 
    isFormAsideView: boolean;
    integrationDetails: IntegrationType;
    allIntegrationList: IntegrationType[];
    allIntegrationDetails: IntegrationType;
    allActivityItem: {
        itemsActivities: any[];
        totalItemActivity: number;
        hasNextPage: boolean;
        pageSize: number;
        totalPages: number;
    };
}


export interface AllActivityItem {
    itemsActivities: any[];
    totalItemActivity: number;
    hasNextPage: boolean;
    pageSize: number;
    totalPages: number;
}

export const submitSurveyStepIntegrationRequest = () => ({
    type: SUBMIT_SURVEY_STEP_INTEGRATION_REQUEST
});

export const submitSurveyStepIntegrationSuccess = (data: any) => ({
    type: SUBMIT_SURVEY_STEP_INTEGRATION_SUCCESS,
    payload: data
});

export const submitSurveyStepIntegrationFailure = (error: string) => ({
    type: SUBMIT_SURVEY_STEP_INTEGRATION_FAILURE,
    payload: error
});

// Define action type
interface CloseFormAsideAction {
    type: typeof CLOSE_FORM_ASIDE;
}

export type IntegrationActionTypes =
    | { type: typeof SUBMIT_CREATE_INTEGRATION_REQUEST }
    | { type: typeof SUBMIT_CREATE_INTEGRATION_SUCCESS, payload: IntegrationType }
    | { type: typeof SUBMIT_CREATE_INTEGRATION_FAILURE, payload: string }
    | { type: typeof FETCH_INTEGRATION_TABLE_LIST_REQUEST }
    | { type: typeof FETCH_INTEGRATION_TABLE_LIST_SUCCESS, payload: IntegrationType[] }
    | { type: typeof FETCH_INTEGRATION_TABLE_LIST_FAILURE, payload: string }
    | { type: typeof FETCH_INTEGRATION_DETAILS_REQUEST }
    | { type: typeof FETCH_INTEGRATION_DETAILS_SUCCESS, payload: IntegrationType }
    | { type: typeof FETCH_INTEGRATION_DETAILS_FAILURE, payload: string }
    | { type: typeof UPDATE_INTEGRATION_DETAILS_REQUEST }
    | { type: typeof UPDATE_INTEGRATION_DETAILS_SUCCESS, payload: IntegrationType }
    | { type: typeof UPDATE_INTEGRATION_DETAILS_FAILURE, payload: string }
    | { type: typeof SELECT_ELEMENT_INTEGRATION_STEP, payload: { id: string, element: any } }
    | { type: typeof SUBMIT_SURVEY_STEP_INTEGRATION_REQUEST }
    | { type: typeof SUBMIT_SURVEY_STEP_INTEGRATION_SUCCESS, payload: any }
    | { type: typeof SUBMIT_SURVEY_STEP_INTEGRATION_FAILURE, payload: string }
    | { type: typeof FETCH_ALL_STEPS_INTEGRATION_DETAILS_REQUEST }
    | { type: typeof FETCH_ALL_STEPS_INTEGRATION_DETAILS_SUCCESS, payload: IntegrationType }
    | { type: typeof FETCH_ALL_STEPS_INTEGRATION_DETAILS_FAILURE, payload: string }
    | { type: typeof DELETE_INTEGRATION_RESPONSES_LIST_REQUEST }
    | { type: typeof DELETE_INTEGRATION_RESPONSES_LIST_SUCCESS, payload: any }
    | { type: typeof DELETE_INTEGRATION_RESPONSES_LIST_FAILURE, payload: string }
    | { type: typeof FETCH_INTEGRATION_COUNT_RESPONSE_REQUEST }
    | { type: typeof FETCH_INTEGRATION_COUNT_RESPONSE_SUCCESS, payload: any }
    | { type: typeof FETCH_INTEGRATION_COUNT_RESPONSE_FAILURE, payload: string }
    | CloseFormAsideAction;



