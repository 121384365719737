import React, { useState, Fragment, useEffect } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Button, Input } from '@lemonsqueezy/wedges'
import { CheckIcon } from '@heroicons/react/20/solid'
import { MenuItem, MenuItems, MenuButton } from '@headlessui/react'
import { Link } from 'react-router-dom'
import { _createItemsStatus, _get_ItemStatusList, _getItemStatusById, _updateItemStatusById, _getItemStatusDelete, _updateOrderBaseStatus } from './baseAPI'
import { Toaster, toast } from 'sonner';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'


const colorList = [
  { name: "bg-blue-500" },
  { name: "bg-gray-500" },
  { name: "bg-blue-200" },
  { name: "bg-pink-500" },
  { name: "bg-indigo-500" },
  { name: "bg-yellow-500" },
  { name: "bg-green-500" },
  { name: "bg-cyan-500" },
  { name: "bg-red-600" },
]

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const DraggableItem = ({ item, index, handleEdit, handleItemTagsDelete, editInput,
  color, setColor, setLabelName, labelName, handleCancel, handleUpdateSave, loading, isDragActive, handleMakeDefault }: any) => {


  return (

    <div>


      {
        isDragActive && (
          <Draggable draggableId={item.id.toString()} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={{
                  ...provided.draggableProps.style,
                  opacity: snapshot.isDragging ? 0.5 : 1
                }}
                className='p-2 rounded-[3px] bg-white border border-solid border-[#e3e3e3] mb-1 relative pl-7 feedback-tags-hover last-of-type:mb-[10px]'
              >
                {editInput === item.id ? (

                  <div className='w-full'>
                    <form>
                      <div className='flex flex-row items-center gap-2 flex-1'>
                        {/* <div className='px-2 flex items-center justify-center cursor-pointer h-[32px] w-[32px] bg-white border border-solid border-[#d8d8d86] rounded-md text-sm'> */}

                        <Menu as='div' className='relative flex items-center '>
                          <Menu.Button className=''>
                            <span className='sr-only'>Open user menu</span>
                            <div className='px-2 flex items-center justify-center cursor-pointer h-[32px] w-[32px] bg-white border border-solid border-[#d8d8d86] rounded-md text-sm'>
                              <div
                                className={`border-0 bg-none text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm w-[9px] h-[9px] rounded-full ${color} border flex justify-center items-center`}
                              >
                              </div>
                            </div>

                          </Menu.Button>
                          <Transition
                            as={Fragment}
                            enter='transition ease-out duration-100'
                            enterFrom='transform opacity-0 scale-95'
                            enterTo='transform opacity-100 scale-100'
                            leave='transition ease-in duration-75'
                            leaveFrom='transform opacity-100 scale-100'
                            leaveTo='transform opacity-0 scale-95'>
                            <Menu.Items className='absolute flex flex-wrap left-0 top-8 z-10 mt-2.5 w-44 p-2 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none'>
                              {colorList.map((value: any, index) => {
                                return (
                                  <div onClick={() => setColor(value.name)} key={index} className={`flex h-6 w-6 border ${value.name} rounded-full m-2 justify-center items-center`}>
                                    {(color === value.name) ? <CheckIcon className=' h-3 w-3 text-white' aria-hidden='true' /> : null}
                                  </div>
                                )
                              })}
                            </Menu.Items>
                          </Transition>

                        </Menu>
                        <div className='w-full'>
                          <Input
                            name='text'
                            type='text'
                            required
                            value={labelName}
                            onChange={(e) => setLabelName((e.target as HTMLInputElement).value)}
                            placeholder='Enter Purpose'
                            className="focus:outline-indigo-600 w-full block h-[32px]"
                          />
                        </div>
                        <span className='bg-[#e3e3e3] w-[1px] h-[30px]'></span>
                        <div className='flex items-center gap-x-2 flex-1'>
                          <Button
                            // onClick={() => navigate(`/${baseId}/create/project`)}
                            type='button'
                            variant="outline"
                            onClick={handleCancel}
                            size="sm">
                            Cancel
                          </Button>
                          <Button
                            // onClick={() => navigate(`/${baseId}/create/project`)}
                            onClick={handleUpdateSave}
                            type='button'
                            className={`bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600 relative
          [&>span]:flex [&>span]:items-center [&>span]:gap-[8px] ${!labelName && 'opacity-50 cursor-not-allowed'
                              }`}
                            disabled={!labelName} // Disable button when labelName is falsy

                            size="sm"
                          >
                            Save
                            {loading && (
                              <div className="simple-spinner">
                                <span></span>
                              </div>
                            )}
                          </Button>
                        </div>

                      </div>
                    </form>
                  </div>
                ) : (
                  <>


                    <div className='flex flex-row items-center justify-between flex-grow shrink-0'>
                      <div className='flex items-center gap-x-[16px]'>
                        <div
                          className={` shrink-0 w-[9px] h-[9px] rounded-full ${item.color}`}

                        ></div>
                        <span className='flex-shrink whitespace-nowrap overflow-hidden text-ellipsis font-medium text-[#1b1b1b] text-sm leading-5'>{item.base_status_title}</span>

                        <div className=' cursor-default select-none flex flex-row'>
                          <button className='p-0 h-auto text-[#5e5e5f] inline-flex items-center justify-center whitespace-nowrap rounded-[5px] font-medium leading-5
          min-w-[24px] text-[0.75rem] bg-transparent' style={{ transitionDuration: '0.15s', transitionProperty: 'border, background-color, color, opacity' }}>
                            {
                              item.isDefault === true && <span className='inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
                                Default
                              </span>
                            }
                          </button>
                        </div>
                      </div>
                      <div className='mr-[6px] flex  flex-row items-center flex-grow-0'>
                        <div className='flex flex-row gap-[8px]'>
                          <button className='feedback-info-btn-hover' onClick={() => handleMakeDefault(item.id)} title="Make Default">
                            <svg
                              className=""
                              width={16}
                              height={16}
                              viewBox="0 0 16 16"
                              fill="#a0a0a2"
                              role="img"
                              focusable="false"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8 4C7.44772 4 7 4.44772 7 5C7 5.55228 7.44772 6 8 6C8.55228 6 9 5.55228 9 5C9 4.44772 8.55228 4 8 4ZM8 7C7.44772 7 7 7.44772 7 8V11C7 11.5523 7.44772 12 8 12C8.55228 12 9 11.5523 9 11V8C9 7.44772 8.55228 7 8 7Z"
                              />
                            </svg>
                          </button>
                          <button className='feedback-edit-btn-hover'>
                            <svg
                              onClick={() => handleEdit(item.id)}
                              width={16}
                              height={16}
                              viewBox="0 0 16 16"
                              fill="#a0a0a2"
                              role="img"
                              focusable="false"
                              aria-hidden="true"
                            >
                              <path d="M10.1805 3.34195L4.14166 9.416C5.32948 9.77021 6.29238 10.6629 6.74008 11.8184L12.6877 5.8425C11.6642 5.22123 10.8043 4.36352 10.1805 3.34195Z" />
                              <path d="M13.7391 4.71631C14.1575 4.02948 14.0727 3.11738 13.4846 2.5219C12.8908 1.92072 11.9784 1.83892 11.298 2.27649C11.8547 3.31132 12.7037 4.15999 13.7391 4.71631Z" />
                              <path d="M3.03104 10.7502C4.30296 10.7658 5.36645 11.7423 5.49783 13.0114C4.83268 13.426 3.40197 13.7922 2.53114 13.9886C2.2001 14.0632 1.92026 13.7602 2.02075 13.4373C2.25326 12.6902 2.64592 11.5136 3.03104 10.7502Z" />
                            </svg>
                          </button>
                          <button className='feedback-more-btn-hover'>
                            <Menu as="div" className="relative inline-block text-left">
                              <div>
                                <MenuButton className="rounded-md flex items-center justify-center">
                                  <span className="sr-only">Open options</span>
                                  <svg stroke="currentColor" fill="#a0a0a2" className='text-[#a0a0a2]' strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle>
                                  </svg>
                                </MenuButton>
                              </div>

                              <Transition
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  <div className="py-1">

                                    <MenuItem>
                                      {({ focus }) => (
                                        <Link
                                          to="#"
                                          className={classNames(
                                            focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'block px-4 py-2 text-sm'
                                          )}
                                          onClick={() => handleItemTagsDelete(item.id)}
                                        >
                                          Delete
                                        </Link>
                                      )}
                                    </MenuItem>
                                  </div>
                                </MenuItems>
                              </Transition>
                            </Menu>


                          </button>
                        </div>
                      </div>
                    </div>

                  </>
                )
                }
              </div>
            )}
          </Draggable>
        )
      }
    </div>
  )

}

function FeedbackStatus() {
  const [editInput, setEditInput] = useState<string | null>(null)
  const [labelName, setLabelName] = useState("")
  const [color, setColor] = useState("bg-gray-500")
  const [newGroup, setNewGroup] = useState(false)
  // const [newLabel, setNewLabel] = useState(false)
  const [itemTagBaseId, setItemTagBaseId] = useState<string | null>(null);
  const [itemTagsList, setItemTagsList] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const baseId = localStorage.getItem('baseId');
  const [isDragActive, setIsDragActive] = useState(false);
  const [isItemId, setIsItemId] = useState<string | null>(null)

  const toggleDragAndDrop = () => {
    setIsDragActive((prevState) => !prevState);
  };


  const handleSearchChange = (e: any) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {

    setItemTagBaseId(baseId);

    const savedItemTagsList = localStorage.getItem('itemStatusList');

    if (savedItemTagsList) {
      try {
        const parsedList = JSON.parse(savedItemTagsList);

        if (Array.isArray(parsedList)) {
          setItemTagsList(parsedList);
        } else {
          setItemTagsList([]);
        }
      } catch (error) {
        setItemTagsList([]);
      }
    } else if (baseId) {
      fetchItemStatusList(parseInt(baseId));
    } else {
      setItemTagsList([]);
    }
  }, []);


  const fetchItemStatusList = async (baseId: number) => {
    try {
      const response = await _get_ItemStatusList(baseId);
      if (response.status === 200 && Array.isArray(response.data)) {
        const sortedData = response.data.sort((a: { order: number }, b: { order: number }) => a.order - b.order);
        setItemTagsList(sortedData);
        localStorage.setItem('itemStatusList', JSON.stringify(response));
      } else {
        setItemTagsList([]);
      }
    } catch (error) {
      setItemTagsList([]);
    }
  };

  useEffect(() => {
    const baseIdString = localStorage.getItem('baseId');

    if (baseIdString) {
      const baseId = parseInt(baseIdString, 10);
      fetchItemStatusList(baseId);
    }
  }, []);

  // for edit items by id
  const handleEdit = async (itemId: string) => {
    try {
      const response = await _getItemStatusById(parseInt(itemId), parseInt(itemTagBaseId || ""));
      if (response) {
        const { base_status_title, color } = response.data;
        setLabelName(base_status_title);
        setColor(color);
        setEditInput(itemId);
        setNewGroup(false);
      }
    } catch (error) {
      console.error('Error fetching item status for edit:', error);
    }
  };

  // for update item by id 
  const handleUpdateSave = async () => {
    setLoading(true);

    try {
      if (!itemTagBaseId || !editInput) {
        console.error('Base ID or Item ID not available.');
        return;
      }

      const response = await _updateItemStatusById(parseInt(itemTagBaseId), parseInt(editInput), undefined, labelName, color,);
      if (response) {
        toast.success('Update tags successfully');
        fetchItemStatusList(parseInt(itemTagBaseId));
        setEditInput(null);
      }
    } catch (error) {
      console.error('Error updating item tag:', error);
      toast.error('Failed to update item tag.');
    }
    finally {
      setLoading(false);
    }
  }


  const handleCancel = () => {
    setEditInput(null)
    setNewGroup(false)
    // setNewLabel(false)
  }

  const handleSave = async () => {
    setLoading(true);
    try {
      if (itemTagBaseId) {
        await _createItemsStatus(parseInt(itemTagBaseId), labelName, color);
        fetchItemStatusList(parseInt(itemTagBaseId));
        toast.success('Successfully create tags');
        setLabelName("")
      }
      setEditInput(null)
      setNewGroup(false);
    } catch (error) {
      console.error('Error creating item tag:', error);
      toast.error('Failed to create item tags');
    } finally {
      setLoading(false);
    }
  };

  const handleNewGroup = () => {
    setNewGroup(true)
    setLabelName("")
    setColor("bg-gray-500")
  }

  // for delete item tags
  const handleItemTagsDelete = async (itemId: number) => {
    const baseId = localStorage.getItem('baseId');

    if (baseId) {
      try {
        await _getItemStatusDelete(itemId, parseInt(baseId));
        toast.success('Item tag deleted successfully');
        fetchItemStatusList(parseInt(baseId));
      } catch (error) {
        console.error('Error deleting item tag:', error);
        toast.error('Failed to delete item tag.');
      }
    }
  };


  const onDragEnd = async (result: { destination: any; source: any }) => {
    const { destination, source } = result;

    if (!destination || destination.index === source.index) {
      return;
    }

    const newItems = Array.from(itemTagsList);
    const [movedItem] = newItems.splice(source.index, 1);
    newItems.splice(destination.index, 0, movedItem);

    setItemTagsList(newItems);

    const updates = newItems.map((item, index) => ({
      id: item.id,
      order: index + 1,
    }));

    if (baseId) {
      try {
      const response =   await _updateOrderBaseStatus(parseInt(baseId), updates);
        toast.success('Order updated successfully');
        fetchItemStatusList(parseInt(baseId));
      } catch (error) {
        console.error('Failed to update order:', error);
      }
    }
  };


  const handleMakeDefault = async (itemId: string) => {
    setIsItemId(itemId);
    try {
      if (!itemTagBaseId || !itemId) {
        console.error('Base ID or Item ID not available.');
        return;
      }

      const response = await _updateItemStatusById(parseInt(itemTagBaseId), parseInt(itemId), true);
      if (response) {
        toast.success('Update tags successfully');
        fetchItemStatusList(parseInt(itemTagBaseId));
        setEditInput(null);
      }
    } catch (error) {
      console.error('Error updating item tag:', error);
      toast.error('Failed to update item tag.');
    }
  }



  return (
    <>
      <Toaster richColors />
      <main className='py-[34px] lg:py-[70px] px-0 lg:px-10 h-full flex'>
        <div className='px-[2px] sm:px-6 lg:px-8 w-full flex justify-center'>
          <div className='w-full max-w-[520px]'>
            <div className='flex flex-col gap-y-[6px]'>
              <h2 className='text-[20px] lg:text-[24px] font-medium leading-8 text-[#1b1b1b] tracking-[-0.01rem]'>Feedback Status</h2>
              <p className='text-sm leading-6 text-[#5c5c5e] font-medium capitalize'>manage feedback tags</p>
            </div>
            <div className='bg-[#e0e0e0] w-full h-[1px] my-6'></div>
            <div className='mt-6 lg:mt-10 mb-8'>
              <p className=' text-[#5c5c5e] text-[13px] leading-[18px]'>
                Use labels and label groups to help organize and filter issues in your workspace.
                Labels created in this section are available for all teams to use. To create labels or label
                groups that only apply to certain teams, add them in the team-specific label settings.
              </p>
            </div>
            <div className='w-full mt-6 mb-3 flex pb-4 justify-end md:justify-between lg:justify-between items-center shrink-0 flex-wrap lg:flex-nowrap gap-2'>
              <div className="relative items-center">
                <Input
                  type="text"
                  //   value={searchVal}
                  //   onChange={(e) => setSearchVal((e.target as HTMLInputElement).value)}
                  value={searchQuery}
                  onChange={handleSearchChange}
                  name="search"
                  id="search"
                  placeholder='Search name and email'
                  className="focus:outline-indigo-600 w-full pl-8 py-[3px]"
                />
                <div className="absolute inset-y-0 left-0 flex py-1.5 pr-1.5">

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.2em"
                    height="1.2em"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className=' w-[15px] h-[15px] my-auto mx-2 '
                  >
                    <circle cx={11} cy={11} r={8} />
                    <path d="m21 21-4.3-4.3" />
                  </svg>

                </div>
              </div>
              <div className='flex items-center gap-x-2'>
                <Button
                  // onClick={() => navigate(`/${baseId}/create/project`)}
                  type='button'
                  variant="outline"
                  onClick={handleNewGroup}
                  // disabled={newGroup || newLabel}
                  disabled={newGroup}
                  // className=" bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600"
                  size="sm">
                  Create status
                </Button>

              </div>

            </div>
            <div className='flex justify-end mb-5'>
              <Button
                onClick={toggleDragAndDrop}
                variant='primary'
                size='sm'
                className="bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600"
              >
                {isDragActive ? 'Save' : 'Re-order'}
              </Button>
            </div>
            <div className='flex flex-col gap-y-2'>
              {newGroup && (
                <div className='bg-[#edeef2] p-2 border border-solid border-[#e0e0e0] rounded mb-1'>
                  <div className='w-full'>
                    <form>
                      <div className='flex flex-row items-center gap-2 flex-1'>
                        {/* <div className='px-2 flex items-center justify-center cursor-pointer h-[32px] w-[32px] bg-white border border-solid border-[#d8d8d86] rounded-md text-sm'> */}

                        <Menu as='div' className='relative flex items-center '>
                          <Menu.Button className=''>
                            <span className='sr-only'>Open user menu</span>
                            <div className='px-2 flex items-center justify-center cursor-pointer h-[32px] w-[32px] bg-white border border-solid border-[#d8d8d86] rounded-md text-sm'>
                              <div
                                className={`border-0 bg-none text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm w-[9px] h-[9px] rounded-full ${color} border flex justify-center items-center`}
                              >
                              </div>
                            </div>

                          </Menu.Button>
                          <Transition
                            as={Fragment}
                            enter='transition ease-out duration-100'
                            enterFrom='transform opacity-0 scale-95'
                            enterTo='transform opacity-100 scale-100'
                            leave='transition ease-in duration-75'
                            leaveFrom='transform opacity-100 scale-100'
                            leaveTo='transform opacity-0 scale-95'>
                            <Menu.Items className='absolute flex flex-wrap left-0 top-8 z-10 mt-2.5 w-44 p-2 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none'>
                              {colorList.map((value: any, index) => {
                                return (
                                  <div onClick={() => setColor(value.name)} key={index} className={`flex h-6 w-6 border ${value.name} rounded-full m-2 justify-center items-center`}>
                                    {(color === value.name) ? <CheckIcon className=' h-3 w-3 text-white' aria-hidden='true' /> : null}
                                  </div>
                                )
                              })}
                            </Menu.Items>
                          </Transition>

                        </Menu>
                        <div className='w-full'>
                          <Input
                            name='text'
                            type='text'
                            required
                            value={labelName}
                            onChange={(e) => setLabelName((e.target as HTMLInputElement).value)}
                            placeholder='Enter Purpose'
                            // req
                            className="focus:outline-indigo-600 w-full block h-[32px]"
                          />
                        </div>
                        <span className='bg-[#e3e3e3] w-[1px] h-[30px]'></span>
                        <div className='flex items-center gap-x-2 flex-1'>
                          <Button
                            // onClick={() => navigate(`/${baseId}/create/project`)}
                            type='button'
                            variant="outline"
                            onClick={handleCancel}
                            size="sm">
                            Cancel
                          </Button>
                          <Button
                            onClick={handleSave}
                            type='button'
                            className={`bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600 relative
                           [&>span]:flex [&>span]:items-center [&>span]:gap-[8px] ${!labelName && 'opacity-50 cursor-not-allowed'
                              }`}
                            disabled={!labelName}

                            size="sm">
                            Save
                            {loading && (
                              <div className="simple-spinner">
                                <span></span>
                              </div>
                            )}
                          </Button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="list">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {Array.isArray(itemTagsList) && itemTagsList?.length > 0 && (
                        itemTagsList?.filter(item => item.base_status_title.toLowerCase().includes(searchQuery.toLowerCase()))
                          .map((item: any, index: number) => (
                            <DraggableItem
                              key={item.id}
                              item={item}
                              index={index}
                              handleEdit={handleEdit}
                              handleItemTagsDelete={handleItemTagsDelete}
                              editInput={editInput}
                              setEditInput={setEditInput}
                              setLabelName={setLabelName}
                              setColor={setColor}
                              setNewGroup={setNewGroup}
                              loading={loading}
                              color={color}
                              labelName={labelName}
                              handleCancel={handleCancel}
                              handleUpdateSave={handleUpdateSave}
                              isDragActive={isDragActive}
                              handleMakeDefault={handleMakeDefault}

                            />
                          ))
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {
                !isDragActive &&
                (
                  <>
                    {Array.isArray(itemTagsList) && itemTagsList?.length > 0 && (
                      itemTagsList?.filter(item => item.base_status_title.toLowerCase().includes(searchQuery.toLowerCase()))
                        .map((item: any) => (
                          <div className='p-2 rounded-[3px] bg-white border border-solid border-[#e3e3e3] mb-1 relative pl-4 feedback-tags-hover last-of-type:mb-[70px]'>


                            {editInput === item.id ? (

                              <div className='w-full'>
                                <form>
                                  <div className='flex flex-row items-center gap-2 flex-1'>
                                    {/* <div className='px-2 flex items-center justify-center cursor-pointer h-[32px] w-[32px] bg-white border border-solid border-[#d8d8d86] rounded-md text-sm'> */}

                                    <Menu as='div' className='relative flex items-center '>
                                      <Menu.Button className=''>
                                        <span className='sr-only'>Open user menu</span>
                                        <div className='px-2 flex items-center justify-center cursor-pointer h-[32px] w-[32px] bg-white border border-solid border-[#d8d8d86] rounded-md text-sm'>
                                          <div
                                            className={`border-0 bg-none text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm w-[9px] h-[9px] rounded-full ${color} border flex justify-center items-center`}
                                          >
                                          </div>
                                        </div>

                                      </Menu.Button>
                                      <Transition
                                        as={Fragment}
                                        enter='transition ease-out duration-100'
                                        enterFrom='transform opacity-0 scale-95'
                                        enterTo='transform opacity-100 scale-100'
                                        leave='transition ease-in duration-75'
                                        leaveFrom='transform opacity-100 scale-100'
                                        leaveTo='transform opacity-0 scale-95'>
                                        <Menu.Items className='absolute flex flex-wrap left-0 top-8 z-10 mt-2.5 w-44 p-2 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none'>
                                          {colorList.map((value: any, index) => {
                                            return (
                                              <div onClick={() => setColor(value.name)} key={index} className={`flex h-6 w-6 border ${value.name} rounded-full m-2 justify-center items-center`}>
                                                {(color === value.name) ? <CheckIcon className=' h-3 w-3 text-white' aria-hidden='true' /> : null}
                                              </div>
                                            )
                                          })}
                                        </Menu.Items>
                                      </Transition>

                                    </Menu>
                                    <div className='w-full'>
                                      <Input
                                        name='text'
                                        type='text'
                                        required
                                        value={labelName}
                                        onChange={(e) => setLabelName((e.target as HTMLInputElement).value)}
                                        placeholder='Enter Purpose'
                                        className="focus:outline-indigo-600 w-full block h-[32px]"
                                      />
                                    </div>
                                    <span className='bg-[#e3e3e3] w-[1px] h-[30px]'></span>
                                    <div className='flex items-center gap-x-2 flex-1'>
                                      <Button
                                        // onClick={() => navigate(`/${baseId}/create/project`)}
                                        type='button'
                                        variant="outline"
                                        onClick={handleCancel}
                                        size="sm">
                                        Cancel
                                      </Button>
                                      <Button
                                        // onClick={() => navigate(`/${baseId}/create/project`)}
                                        onClick={handleUpdateSave}
                                        type='button'
                                        className={`bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600 relative
     [&>span]:flex [&>span]:items-center [&>span]:gap-[8px] ${!labelName && 'opacity-50 cursor-not-allowed'
                                          }`}
                                        disabled={!labelName} // Disable button when labelName is falsy

                                        size="sm"
                                      >
                                        Save
                                        {loading && (
                                          <div className="simple-spinner">
                                            <span></span>
                                          </div>
                                        )}
                                      </Button>
                                    </div>

                                  </div>
                                </form>
                              </div>
                            ) : (
                              <>


                                <div className='flex flex-row items-center justify-between flex-grow shrink-0'>
                                  <div className='flex items-center gap-x-[16px]'>
                                    <div
                                      className={` shrink-0 w-[9px] h-[9px] rounded-full ${item.color}`}

                                    ></div>
                                    <span className='flex-shrink whitespace-nowrap overflow-hidden text-ellipsis font-medium text-[#1b1b1b] text-sm leading-5'>{item.base_status_title}</span>

                                    <div className=' cursor-default select-none flex flex-row'>
                                      <button className='p-0 h-auto text-[#5e5e5f] inline-flex items-center justify-center whitespace-nowrap rounded-[5px] font-medium leading-5
                                          min-w-[24px] text-[0.75rem] bg-transparent' style={{ transitionDuration: '0.15s', transitionProperty: 'border, background-color, color, opacity' }}>
                                                        {
                              item.isDefault === true && <span className='inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
                                Default
                              </span>
                            }
                                      </button>
                                    </div>
                                  </div>
                                  <div className='mr-[6px] flex  flex-row items-center flex-grow-0'>
                                    <div className='flex flex-row gap-[8px]'>
                                      <button className='feedback-info-btn-hover' onClick={() => {
                                        handleMakeDefault(item.id);
                                      }}

                                        title="Make Defaul">
                                        <svg
                                          className=""
                                          width={16}
                                          height={16}
                                          viewBox="0 0 16 16"
                                          fill="#a0a0a2"
                                          role="img"
                                          focusable="false"
                                          aria-hidden="true"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8 4C7.44772 4 7 4.44772 7 5C7 5.55228 7.44772 6 8 6C8.55228 6 9 5.55228 9 5C9 4.44772 8.55228 4 8 4ZM8 7C7.44772 7 7 7.44772 7 8V11C7 11.5523 7.44772 12 8 12C8.55228 12 9 11.5523 9 11V8C9 7.44772 8.55228 7 8 7Z"
                                          />
                                        </svg>
                                      </button>
                                      <button className='feedback-edit-btn-hover'>
                                        <svg
                                          onClick={() => handleEdit(item.id)}
                                          width={16}
                                          height={16}
                                          viewBox="0 0 16 16"
                                          fill="#a0a0a2"
                                          role="img"
                                          focusable="false"
                                          aria-hidden="true"
                                        >
                                          <path d="M10.1805 3.34195L4.14166 9.416C5.32948 9.77021 6.29238 10.6629 6.74008 11.8184L12.6877 5.8425C11.6642 5.22123 10.8043 4.36352 10.1805 3.34195Z" />
                                          <path d="M13.7391 4.71631C14.1575 4.02948 14.0727 3.11738 13.4846 2.5219C12.8908 1.92072 11.9784 1.83892 11.298 2.27649C11.8547 3.31132 12.7037 4.15999 13.7391 4.71631Z" />
                                          <path d="M3.03104 10.7502C4.30296 10.7658 5.36645 11.7423 5.49783 13.0114C4.83268 13.426 3.40197 13.7922 2.53114 13.9886C2.2001 14.0632 1.92026 13.7602 2.02075 13.4373C2.25326 12.6902 2.64592 11.5136 3.03104 10.7502Z" />
                                        </svg>
                                      </button>
                                      <button className='feedback-more-btn-hover'>
                                        <Menu as="div" className="relative inline-block text-left">
                                          <div>
                                            <MenuButton className="rounded-md flex items-center justify-center">
                                              <span className="sr-only">Open options</span>
                                              <svg stroke="currentColor" fill="#a0a0a2" className='text-[#a0a0a2]' strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle>
                                              </svg>
                                            </MenuButton>
                                          </div>

                                          <Transition
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                          >
                                            <MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                              <div className="py-1">

                                                <MenuItem>
                                                  {({ focus }) => (
                                                    <Link
                                                      to="#"
                                                      className={classNames(
                                                        focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block px-4 py-2 text-sm'
                                                      )}
                                                      onClick={() => handleItemTagsDelete(item.id)}
                                                    >
                                                      Delete
                                                    </Link>
                                                  )}
                                                </MenuItem>
                                              </div>
                                            </MenuItems>
                                          </Transition>
                                        </Menu>


                                      </button>
                                    </div>
                                  </div>
                                </div>

                              </>
                            )
                            }
                          </div>
                        ))
                    )}
                  </>
                )

              }
            </div>
          </div>
        </div>
      </main>

    </>
  )
}

export default FeedbackStatus