interface UserDetails {
    user?: {
      id: number;
      // other user details
    };
  }
  
  interface BaseMember {
    id: number;
    role: string;
    // other base member details
  }
  
  export const getUserAndBaseData = () => {
    const getUserDetails = (): UserDetails | null => {
      const storedData = localStorage.getItem("userProfileDetails");
      if (storedData) {
        try {
          return JSON.parse(storedData);
        } catch (error) {
          console.error("Error parsing stored user profile details:", error);
        }
      }
      return null;
    };
  
    const getBaseMemberDetails = (): BaseMember[] => {
      const storedData = localStorage.getItem("baseMembersDetails");
      if (storedData) {
        try {
          return JSON.parse(storedData);
        } catch (error) {
          console.error("Error parsing stored base member details:", error);
        }
      }
      return [];
    };
  
    const getShowBaseSetting = (userDetails: UserDetails | null, baseMemberDetails: BaseMember[]): boolean => {
      if (userDetails && baseMemberDetails.length > 0) {
        const currentUserId = userDetails.user?.id;
        const baseMember = baseMemberDetails.find(member => member.id === currentUserId);
        return baseMember?.role === "admin";
      }
      return false;
    };
  
    const userDetails = getUserDetails();
    const baseMemberDetails = getBaseMemberDetails();
    const showBaseSetting = getShowBaseSetting(userDetails, baseMemberDetails);
  
    return {
      userDetails,
      baseMemberDetails,
      showBaseSetting
    };
  };