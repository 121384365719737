import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';
import * as api from './signupAPI';
import { UserSignup } from '../../../reducer/type';

export interface AuthState {
    loading: boolean;
    userInfo: UserSignup | null;
}

const initialState: AuthState = {
    loading: false,
    userInfo: null,
};


export const userSignUp = createAsyncThunk(
    'user/signUp',
    async (
        options: UserSignup,
        { rejectWithValue }
    ) => {
        try {
            return await api.user_signUp(options.name, options.email, options.password, options.deviceId, options.deviceName);
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
    },
});


export default userSlice.reducer;


