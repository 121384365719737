export const formFieldsLogin = [
  {
    "id": "email",
    "label": "Email",
    "type": "email",
    "autoComplete": "email",
    "required": true
  },
  {
    "id": "password",
    "label": "Password",
    "type": "password",
    "autoComplete": "current-password",
    "required": true
  }
];


export const formFieldsSignUp = [
  {
    "id": "name",
    "label": "Name",
    "type": "text",
    "autoComplete": "text",
    "required": true
  },
  {
    "id": "email",
    "label": "Email",
    "type": "email",
    "autoComplete": "email",
    "required": true
  },
  {
    "id": "password",
    "label": "Password",
    "type": "password",
    "autoComplete": "current-password",
    "required": true
  },
];

export const formFieldsReset = [
  {
    "id": "number",
    "label": "Code",
    "type": "number",
    "autoComplete": "number",
    "required": true
  },
  {
    "id": "password",
    "label": "Password",
    "type": "password",
    "autoComplete": "current-password",
    "required": true
  },
];