export const FETCH_INSIGHT_REQUEST = 'FETCH_INSIGHT_REQUEST';
export const FETCH_INSIGHT_SUCCESS = 'FETCH_INSIGHT_SUCCESS';
export const FETCH_INSIGHT_FAILURE = 'FETCH_INSIGHT_FAILURE';

export const SUBMIT_INSIGHT_GROUP_REQUEST = 'SUBMIT_INSIGHT_GROUP_REQUEST';
export const SUBMIT_INSIGHT_GROUP_SUCCESS = 'SUBMIT_INSIGHT_GROUP_SUCCESS';
export const SUBMIT_INSIGHT_GROUP_FAILURE = 'SUBMIT_INSIGHT_GROUP_FAILURE';

export const FETCH_INSIGHT_GROUP_LIST_REQUEST = 'FETCH_INSIGHT_GROUP_LIST_REQUEST';
export const FETCH_INSIGHT_GROUP_LIST_SUCCESS = 'FETCH_INSIGHT_GROUP_LIST_SUCCESS';
export const FETCH_INSIGHT_GROUP_LIST_FAILURE = 'FETCH_INSIGHT_GROUP_LIST_FAILURE';

export const DELETE_INSIGHT_GROUP_LIST_REQUEST = 'DELETE_INSIGHT_GROUP_LIST_REQUEST';
export const DELETE_INSIGHT_GROUP_LIST_SUCCESS = 'DELETE_INSIGHT_GROUP_LIST_SUCCESS';
export const DELETE_INSIGHT_GROUP_LIST_FAILURE = 'DELETE_INSIGHT_GROUP_LIST_FAILURE';


export const DELETE_INSIGHT_LIST_REQUEST = 'DELETE_INSIGHT_LIST_REQUEST';
export const DELETE_INSIGHT_LIST_SUCCESS = 'DELETE_INSIGHT_LIST_SUCCESS';
export const DELETE_INSIGHT_LIST_FAILURE = 'DELETE_INSIGHT_LIST_FAILURE';


export const FETCH_ITEM_TOPIC_INSIGHT_REQUEST = 'FETCH_ITEM_TOPIC_INSIGHT_REQUEST';
export const FETCH_ITEM_TOPIC_INSIGHT_SUCCESS = 'FETCH_ITEM_TOPIC_INSIGHT_SUCCESS';
export const FETCH_ITEM_TOPIC_INSIGHT_FAILURE = 'FETCH_ITEM_TOPIC_INSIGHT_FAILURE';

export const UPDATE_TICKET_INSIGHT_REQUEST = 'UPDATE_INSIGHT_REQUEST';
export const UPDATE_TICKET_INSIGHT_SUCCESS = 'UPDATE_INSIGHT_SUCCESS';
export const UPDATE_TICKET_INSIGHT_FAILURE = 'UPDATE_INSIGHT_FAILURE';


export interface InsightType {
    allActivityItem: any[];
    itemId: any;
    base_id: number;
    board_id?: number;
    item_type?: string;
    item_title?: string;
    item_text?: string;
    item_details: string;
    item_tag_id?: number;
    item_base_status_id?:  [];
    item_sentiment?: string;
    item_meta?: string;
    item_revenue?: string;
    item_user_id?: number;
    item_group_id?: number;
    item_priority?: string;
    item_source_type?: string;
    item_summary?: string;
    item_source_id?: string;
    ai_check?: string;
    ai_check_time?: string;
    ai_check_source?: string;
    ai_check_tokens?: string;
    insight_ticket_id?: number;
    item_user_name?: string;
    item_user_unique_identifier?: string;
    item_user_email_id?: string;
    item_user_phone_number?: string;
    item_user_meta?: string;
    item_user_segment?: string;
    item_user_group_name?: string;
    item_user_group_unique_identifier?: string;
    item_user_group_revenue?: string;
    item_user_group_segment?: string;
    item_user_group_meta?: string;
    id?: number;
    name?: string;
    color?: string;
    base_status_title?: string;
    tag_title?: string;
    item_customer_name?: string;
    item_customer_unique_id?: string;
    item_customer_email_id?: string;
    item_customer_phone_number?: string;
    item_customer_meta?: string;
    item_customer_label?: string;
    item_customer_type?: string;
    item_customer_image?: string;
    item_customer_revenue?: string;
    item_customer_parent_id?: number;
}

export interface AllActivityItem {
  itemsActivities: any[]; 
  totalItemActivity: number;
  hasNextPage: boolean;
  pageSize: number;
  totalPages: number;
}

export interface InsightState {
    insightGroups: any;
    loading: boolean;
    error: string;
    success: boolean;
    data: any[];
    allActivityItem: AllActivityItem;
}

export interface UpdateTicketInsightPayload {
    insightIds: number[];
    ticketID: number;
  }

// Action types union
export type InsightActionTypes =
    | { type: typeof FETCH_INSIGHT_REQUEST }
    | { type: typeof FETCH_INSIGHT_SUCCESS, payload: InsightType }
    | { type: typeof FETCH_INSIGHT_FAILURE, payload: string }
    | { type: typeof SUBMIT_INSIGHT_GROUP_REQUEST }
    | { type: typeof SUBMIT_INSIGHT_GROUP_SUCCESS, payload: InsightType }
    | { type: typeof SUBMIT_INSIGHT_GROUP_FAILURE, payload: string }
    | { type: typeof FETCH_INSIGHT_GROUP_LIST_REQUEST }
    | { type: typeof FETCH_INSIGHT_GROUP_LIST_SUCCESS, payload: InsightType }
    | { type: typeof FETCH_INSIGHT_GROUP_LIST_FAILURE, payload: string }
    | { type: typeof DELETE_INSIGHT_GROUP_LIST_REQUEST }
    | { type: typeof DELETE_INSIGHT_GROUP_LIST_SUCCESS, payload: number[] }
    | { type: typeof DELETE_INSIGHT_GROUP_LIST_FAILURE, payload: string }
    | { type: typeof DELETE_INSIGHT_LIST_REQUEST }
    | { type: typeof DELETE_INSIGHT_LIST_SUCCESS, payload: number[] }
    | { type: typeof DELETE_INSIGHT_LIST_FAILURE, payload: string }
    | { type: typeof FETCH_ITEM_TOPIC_INSIGHT_REQUEST }
    | { type: typeof FETCH_ITEM_TOPIC_INSIGHT_SUCCESS, payload: InsightType }
    | { type: typeof FETCH_ITEM_TOPIC_INSIGHT_FAILURE, payload: string }
    | { type: typeof UPDATE_TICKET_INSIGHT_REQUEST }
    | { type: typeof UPDATE_TICKET_INSIGHT_SUCCESS, payload: number[] }
    | { type: typeof UPDATE_TICKET_INSIGHT_FAILURE, payload: string };



    