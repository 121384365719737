import React from 'react';
import { FormElement } from '../features/integration/integration-steps/types';
import { v4 as uuidv4 } from 'uuid'; // Import UUID library

interface ToolboxProps {
    onAddElement: (element: FormElement) => void;
}

const Toolbox: React.FC<ToolboxProps> = ({ onAddElement }) => {
    const textAndHeadingElements: FormElement[] = [
        { id: uuidv4(), type: 'text', label: 'Text', icon: 'T', headerText: 'Sample Text' },
        { id: uuidv4(), type: 'heading', label: 'Heading', icon: 'H', headerText: 'Sample Heading' }
    ];

    const allOtherElements: FormElement[] = [
        { id: uuidv4(), type: 'input', label: 'Input', headerText: 'Sample Input', icon: 'I', placeholder: 'Enter your details' },
        { id: uuidv4(), type: 'textarea', label: 'Textarea', headerText: 'Sample Textarea', icon: 'T', placeholder: 'Enter long data' },
        { id: uuidv4(), type: 'email', label: 'Email', headerText: 'Sample Email', icon: 'E', placeholder: 'Enter your email' },
        { id: uuidv4(), type: 'number', label: 'Number', headerText: 'Sample Number', icon: 'N', placeholder: 'Enter a number' },
        {
            id: uuidv4(), type: 'dropdown', label: 'Dropdown', headerText: 'Sample Dropdown', icon: 'D',
            options: [
                { id: uuidv4(), label: 'Strongly disagree', selected: false },
                { id: uuidv4(), label: 'Disagree', selected: false },
                { id: uuidv4(), label: 'Neither agree nor disagree', selected: false },
                { id: uuidv4(), label: 'Agreement', selected: false },
                { id: uuidv4(), label: 'Strongly agree', selected: false },
            ]
        },
        {
            id: uuidv4(), type: 'radio', label: 'Radio', headerText: 'Sample Options', icon: 'R',
            options: [
                { id: uuidv4(), label: 'Strongly disagree', selected: false },
                { id: uuidv4(), label: 'Disagree', selected: false },
                { id: uuidv4(), label: 'Neither agree nor disagree', selected: false },
                { id: uuidv4(), label: 'Agreement', selected: false },
                { id: uuidv4(), label: 'Strongly agree', selected: false },
            ]
        },
        {
            id: uuidv4(), type: 'checkbox', label: 'Checkbox', headerText: 'Sample Multi select', icon: 'C',
            options: [
                { id: uuidv4(), label: 'Very unsatisfied', selected: false },
                { id: uuidv4(), label: 'Satisfied', selected: false },
                { id: uuidv4(), label: 'Very satisfied', selected: false },
                { id: uuidv4(), label: 'New Option', selected: false },
                { id: uuidv4(), label: 'New Option', selected: false },
                { id: uuidv4(), label: 'New Option', selected: false },
            ]
        },
        {
            id: uuidv4(), type: 'likert', label: 'Text scale', headerText: 'Sample Text scale', icon: 'D',
            options: [
                { id: uuidv4(), label: 'Strongly Disagree', selected: false },
                { id: uuidv4(), label: 'Disagree', selected: false },
                { id: uuidv4(), label: 'Neutral', selected: false },
                { id: uuidv4(), label: 'Agree', selected: false },
                { id: uuidv4(), label: 'Strongly Agree', selected: false }
            ]
        },
        {
            id: uuidv4(), type: 'numberscale', label: 'Number scale', headerText: 'Sample Number Scale', icon: '🏷',
            options: [
                { id: uuidv4(), label: '1', selected: false },
                { id: uuidv4(), label: '2', selected: false },
                { id: uuidv4(), label: '3', selected: false },
                { id: uuidv4(), label: '4', selected: false },
                { id: uuidv4(), label: '5', selected: false },
                { id: uuidv4(), label: '6', selected: false },
                { id: uuidv4(), label: '7', selected: false },
                { id: uuidv4(), label: '8', selected: false },
                { id: uuidv4(), label: '9', selected: false },
                { id: uuidv4(), label: '10', selected: false }
            ]
        },
        { id: uuidv4(), type: 'star', label: '5 Star', headerText: 'Sample 5star', icon: '▦', options: [] },
        {
            id: uuidv4(), type: 'smiley', label: 'Smiley face', headerText: 'Sample Smiley', icon: 'S',
            options: [
                { id: uuidv4(), type: 'sad', label: 'Sad', selected: false },
                { id: uuidv4(), type: 'ok', label: 'Okay', selected: false },
                { id: uuidv4(), type: 'happy', label: 'Happy', selected: false }
            ],
        },
        // how to create for thumps up
        {
            id: uuidv4(), type: 'thumbsup', label: 'Thumb rating', headerText: 'Sample Thumbs Up', icon: 'T',
            options: [
                { id: uuidv4(), type: 'thumbsup', label: 'Thumbs Up', selected: false },
                { id: uuidv4(), type: 'thumbsdown', label: 'Thumbs Down', selected: false },
            ]
        },

    ];

    return (
        <div className="p-0 w-full bg-transparent">
            <div className="mb-4">
                <h3 className=" text-wedges-gray text-[13px] font-medium mb-2">Display</h3>
                <ul>
                    {textAndHeadingElements.map((element) => (
                        <li
                            key={element.id}
                            onClick={() => onAddElement(element)}
                            className="flex items-center py-[6px] px-3 mb-2 cursor-pointer border border-dashed border-gray-300 rounded hover:bg-gray-200"
                        >
                            <span className="mr-2 text-dark-black-200 text-[13px] font-normal">{element.icon}</span>
                            <span className='text-dark-black-200 text-[13px] font-normal'>{element.label}</span>
                        </li>
                    ))}
                </ul>
            </div>

            <div>
                <h3 className=" text-wedges-gray text-[13px] font-medium mb-2 mt-5 pt-3">Input</h3>
                <ul>
                    {allOtherElements.map((element) => (
                        <li
                            key={element.id}
                            onClick={() => onAddElement(element)}
                            className="flex items-center py-[6px] px-3 mb-2 cursor-pointer border border-dashed border-gray-300 rounded hover:bg-gray-200"
                        >
                            <span className="mr-2 text-dark-black-200 text-[13px] font-normal">{element.icon}</span>
                            <span className='text-dark-black-200 text-[13px] font-normal'>{element.label}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Toolbox;
