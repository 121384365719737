// @ts-nocheck

import {
    SUBMIT_FEEDBACK_REQUEST,
    SUBMIT_FEEDBACK_SUCCESS,
    SUBMIT_FEEDBACK_FAILURE,
    FETCH_FEEDBACK_REQUEST,
    FETCH_FEEDBACK_SUCCESS,
    FETCH_FEEDBACK_FAILURE,
    FeedbackState,
    FeedbackActionTypes,
    FETCH_ITEM_ACTIVITY_REQUEST,
    FETCH_ITEM_ACTIVITY_FAILURE,
    FETCH_ITEM_ACTIVITY_SUCCESS,
    FETCH_ITEM_TYPE_FAILURE,
    FETCH_ITEM_TYPE_REQUEST,
    DELETE_ITEM_ACTIVITY_SUCCESS,
    DELETE_ITEM_ACTIVITY_FAILURE,
    FETCH_ITEM_INSIGHT_REQUEST,
    FETCH_ITEM_INSIGHT_FAILURE,
    UPDATE_FEEDBACK_FAILURE,
    UPDATE_FEEDBACK_SUCCESS,
    UPDATE_FEEDBACK_REQUEST,
    FETCH_ALL_ACTIVITY_BY_BASE_ID_REQUEST,
    FETCH_ALL_ACTIVITY_BY_BASE_ID_FAILURE,
    FETCH_ALL_ACTIVITY_BY_BASE_ID_SUCCESS,
    SUBMIT_INSIGHT_FAILURE,
    SUBMIT_INSIGHT_REQUEST,
    SUBMIT_INSIGHT_SUCCESS,
    DELETE_INSIGHT_SUCCESS,
    DELETE_INSIGHT_FAILURE,
    DELETE_INSIGHT_REQUEST,
    DELETE_FEEDBACK_LIST_REQUEST,
    DELETE_FEEDBACK_LIST_SUCCESS,
    DELETE_FEEDBACK_LIST_FAILURE,
    FETCH_FEEDBACK_LISTING_REQUEST,
    FETCH_FEEDBACK_LISTING_SUCCESS,
    FETCH_FEEDBACK_LISTING_FAILURE
} from './feedbacks.types';

const initialState: FeedbackState = {
    loading: false,
    error: '',
    success: false,
    data: [],
    allActivityItem: {
        itemsActivities: [],
        totalItemActivity: 0,
        hasNextPage: false,
        pageSize: 10,
        totalPages: 0,
    },
};


// const feedbacksReducer = (state = initialState, action: FeedbackActionTypes): FeedbackState => {
//     switch (action.type) {
//         case FETCH_FEEDBACK_REQUEST:
//         case FETCH_ITEM_INSIGHT_REQUEST:
//         case UPDATE_FEEDBACK_REQUEST:
//         case FETCH_ALL_ACTIVITY_BY_BASE_ID_REQUEST:
//             return { ...state, loading: true, error: '', success: false };
//         case SUBMIT_FEEDBACK_SUCCESS:
//             return {
//                 ...state,
//                 error: '',
//                 success: true,
//                 data: [...state.data, action.payload]
//             };
//         case UPDATE_FEEDBACK_SUCCESS:
//             return {
//                 ...state,
//                 loading: false,
//                 error: '',
//                 success: true,
//                 data: state.data.map(item =>
//                     item.id === action.payload.id ? action.payload : item
//                 )
//             };
//             case SUBMIT_INSIGHT_SUCCESS:
//                 return {
//                     ...state,
//                     error: '',
//                     success: true,
//                     loading: false,
//                     data: [...state.data, action.payload] // Add the new feedback to the existing data
//                 };
//         case SUBMIT_FEEDBACK_SUCCESS:
//             return {
//                 ...state,
//                 loading: false,
//                 error: '',
//                 success: true,
//                 data: [...state.data, action.payload] // Add the new feedback to the existing data
//             };
//         case FETCH_FEEDBACK_SUCCESS:

//             return {
//                 ...state,
//                 loading: false,
//                 error: '',
//                 success: true,
//                 data: Array.isArray(action.payload) ? action.payload : [action.payload] // Ensure data is always an array
//             };
//         case FETCH_ALL_ACTIVITY_BY_BASE_ID_SUCCESS:
//             return {
//                 ...state,
//                 loading: false,
//                 error: '',
//                 success: true,
//                 allActivityItem: action.payload,
//             };
//         case DELETE_ITEM_ACTIVITY_SUCCESS:

//             return {
//                 ...state,
//                 loading: false,
//                 error: '',
//                 success: true,
//                 data: state.data.filter(item => item.id !== action.payload) // Remove the deleted item
//             };
//         case SUBMIT_FEEDBACK_FAILURE:
//         case FETCH_FEEDBACK_FAILURE:
//         case FETCH_ITEM_ACTIVITY_FAILURE:
//         case FETCH_ITEM_TYPE_FAILURE:
//         case DELETE_ITEM_ACTIVITY_FAILURE:
//         case FETCH_ITEM_INSIGHT_FAILURE:
//         case UPDATE_FEEDBACK_FAILURE:
//         case SUBMIT_INSIGHT_FAILURE:
//         case FETCH_ALL_ACTIVITY_BY_BASE_ID_FAILURE:

//             return {
//                 ...state,
//                 loading: false,
//                 error: action.payload,
//                 success: false
//             };
//         default:
//             return state;
//     }
// };
const feedbacksReducer = (state = initialState, action: FeedbackActionTypes): FeedbackState => {
    switch (action.type) {
        case FETCH_FEEDBACK_REQUEST:
        case UPDATE_FEEDBACK_REQUEST:
        case FETCH_ALL_ACTIVITY_BY_BASE_ID_REQUEST:
        case FETCH_FEEDBACK_LISTING_REQUEST:
            return { ...state, loading: true, error: '', success: false };

        case FETCH_ITEM_INSIGHT_REQUEST:
            return { ...state, loading: false, error: '', success: false };

        case DELETE_INSIGHT_REQUEST:
            return { ...state, loading: false, error: '', success: false };
        case DELETE_FEEDBACK_LIST_REQUEST:
            return {
                ...state,
                // loading: true,
            };
        case SUBMIT_FEEDBACK_SUCCESS:
        case SUBMIT_INSIGHT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                success: true,
                data: [...state.data, action.payload]
            };

        case UPDATE_FEEDBACK_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                success: true,
                data: state.data.map(item =>
                    item.id === action.payload.id ? action.payload : item
                )
            };
        case DELETE_FEEDBACK_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                // insightGroups: state.insightGroups.filter((group: { id: number; }) => !action.payload.includes(group.id)),
                insightGroups: state.insightGroups?.filter((group: { id: number; }) => {
                    const deletedItem = action.payload.find((item: { id: number; isSuccess: boolean; }) => item.id === group.id);
                    return !deletedItem || !deletedItem.isSuccess;
                }),
            };
        case FETCH_FEEDBACK_SUCCESS:
        case FETCH_ALL_ACTIVITY_BY_BASE_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                success: true,
                data: Array.isArray(action.payload) ? action.payload : [action.payload]
            };

        case DELETE_ITEM_ACTIVITY_SUCCESS:
        case DELETE_INSIGHT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                success: true,
                data: state.data.filter(item => item.id !== action.payload)
            };
        case FETCH_FEEDBACK_LISTING_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                error: '',
                data: action.payload
            };


        case SUBMIT_FEEDBACK_FAILURE:
        case FETCH_FEEDBACK_FAILURE:
        case FETCH_ITEM_ACTIVITY_FAILURE:
        case FETCH_ITEM_TYPE_FAILURE:
        case DELETE_ITEM_ACTIVITY_FAILURE:
        case FETCH_ITEM_INSIGHT_FAILURE:
        case UPDATE_FEEDBACK_FAILURE:
        case SUBMIT_INSIGHT_FAILURE:
        case DELETE_FEEDBACK_LIST_FAILURE:
        case FETCH_ALL_ACTIVITY_BY_BASE_ID_FAILURE:
        case DELETE_INSIGHT_FAILURE:
        case FETCH_FEEDBACK_LISTING_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false
            };

        default:
            return state;
    }
};

export default feedbacksReducer;


