import ChannelData from '../../channels/channelPages/ChannelData';
import { PlusIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState } from 'react';
import Modal from '../../../components/Modal';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, CheckIcon, QueueListIcon, Bars2Icon } from '@heroicons/react/20/solid';
import { Switch } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../../store/store';
import projectBoardReducer from './BoardReducer';
import { Listbox } from '@headlessui/react'
import { createBoard, fetchBoardList } from './BoardActions';
// import { Channel } from '../../../reducer/type';

type BoardPageProps = {
    isModalOpen: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    baseId: number;
    editBoardData?: { id: number; baseId: number } | null; 
};


function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
}

// const BoardPage: React.FC<HeaderProps> = ({ data, channelId }: HeaderProps) => {
const BoardPage: React.FC<BoardPageProps> = ({ isModalOpen, setIsModalOpen,editBoardData }: any) => {

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const [color, setColor] = useState("bg-[#6b7280]");
    const [checked, setChecked] = useState(false);
    const [name, setName] = useState("");
    const [purpose, setPurpose] = useState("");
    const [nameError, setNameError] = useState<string>("");
    const [purposeError, setPurposeError] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const [fetchingBoards, setFetchingBoards] = useState(false);

    const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>();

    const handleSwitchChange = () => {
        setChecked(!checked);
    };


    const selectRoleType = [
        { id: 1, name: 'Kanban', icon: <Bars2Icon className="h-4 w-4" /> },
        { id: 2, name: 'List', icon: <QueueListIcon className="h-4 w-4" /> },
    ]


    const [selectRole, setSelectRole] = useState(selectRoleType[1]); // Initialize with a default role, e.g., the first role in selectRoleType array


    const colorList = [
        { name: "bg-[#3b82f6]" },
        { name: "bg-[#6b7280]" },
        { name: "bg-[#bfdbfe]" },
        { name: "bg-[#ec4899]" },
        { name: "bg-[#6366f1]" },
        { name: "bg-[#eab308]" },
        { name: "bg-[#22c55e]" },
        { name: "bg-[#06b6d4]" },
        { name: "bg-[#dc2626]" },
    ];


    const handleSave = async (e: any) => {
        e.preventDefault();
        setLoading(true);

        // Reset errors
        setNameError("");
        setPurposeError("");

        if (name.trim().length === 0) {
            setNameError("Name is required");
            setLoading(false);
            return;
        } else if (purpose.trim().length === 0) {
            setPurposeError("Description is required");
            setLoading(false);
            return;
        }

        const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
        const baseId = baseIdString ? JSON.parse(baseIdString) : null;
        const userStatus = localStorage.getItem('userAuthStatus') || "";
        const privacy = checked ? "public" : "private";
        const lowerCaseRoleName = selectRole.name.toLowerCase();

        try {
            await dispatch(createBoard(
                baseId,
                name,
                purpose,
                privacy,
                color,
                userStatus,
                lowerCaseRoleName
            ));

            setIsModalOpen(false);
            setName("");
            setPurpose("");
            setSelectRole({ id: 2, name: 'List', icon: <QueueListIcon className="h-4 w-4" /> });

            setFetchingBoards(true);
            const response = await dispatch(fetchBoardList(baseId));
            localStorage.setItem('channelData', JSON.stringify(response));

        } catch (error) {
            console.error("Failed to create project board:", error);
        } finally {
            setLoading(false);
            setFetchingBoards(false);
            setIsModalOpen(false);
        }
    };


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === 'name') {
            setName(value);
            if (value.trim().length === 0) {
                setNameError("Name is required");
            } else {
                setNameError("");
            }
        } else if (name === 'purpose') {
            setPurpose(value);
            if (value.trim().length === 0) {
                setPurposeError("Description is required");
            } else {
                setPurposeError("");
            }
        }
    };


    // fetch project board list
    const projectBoardState = useSelector((state: ReturnType<typeof projectBoardReducer>) => state) || [];
    // const projectBoardState = useSelector((state: RootState) => state.projectBoardReducer) || [];

    // const projectBoardList = projectBoardState.Board.projectBoardList;


    useEffect(() => {
        const storedData = localStorage.getItem('channelData');
        const baseId = Number(localStorage.getItem('baseId'));
    
        if (baseId !== null && !storedData) {
            setFetchingBoards(true);
            dispatch(fetchBoardList(baseId)).finally(() => setFetchingBoards(false));
        }
    }, [dispatch]);
    
    if (projectBoardState.loading || fetchingBoards) {
        return <div>Loading...</div>;
    }

    if (projectBoardState.error) {
        return <div>Error: {projectBoardState.error}</div>;
    }

    return (
        <div className=''>
            <button onClick={openModal}>
                {/* <PlusIcon className="-mr-1 h-4 w-4 text-wedges-gray-900" aria-hidden="true" /> */}
                <Modal isOpen={isModalOpen} onClose={closeModal} title="Create Board" onSave={(e) => {
                    handleSave(e)
                }}
                    loading={loading}
                >
                    <form action="#" onSubmit={handleSave} className="space-y-6 mt-10">
                        <div className='flex flex-col gap-y-2'>
                            <label className='wg-label inline-flex cursor-pointer items-center gap-1 text-sm font-medium leading-6'>Name</label>
                            <div className="relative">
                                <Menu as='div' className='flex items-center'>
                                    <Menu.Button className='absolute inset-y-0 left-0 flex items-center px-2'>
                                        <span className='sr-only'>Open user menu</span>
                                        <div
                                            className={`border-0 bg-none text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm h-6 w-6  rounded-full ${color} border flex justify-center items-center`}
                                        >
                                            <ChevronDownIcon className='h-5 w-5 text-white' aria-hidden='true' />
                                        </div>
                                    </Menu.Button>
                                    <Transition
                                        as={Fragment}
                                        enter='transition ease-out duration-100'
                                        enterFrom='transform opacity-0 scale-95'
                                        enterTo='transform opacity-100 scale-100'
                                        leave='transition ease-in duration-75'
                                        leaveFrom='transform opacity-100 scale-100'
                                        leaveTo='transform opacity-0 scale-95'>
                                        <Menu.Items className='absolute flex flex-wrap left-0 top-8 z-10 mt-2.5 w-44 p-2 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none'>
                                            {colorList.map((value: any, index) => {
                                                return (
                                                    <div onClick={() => setColor(value.name)} key={index} className={`flex h-6 w-6 border ${value.name} rounded-full m-2 justify-center items-center`}>
                                                        {(color === value.name) ? <CheckIcon className='h-3 w-3 text-white' aria-hidden='true' /> : null}
                                                    </div>
                                                )
                                            })}
                                        </Menu.Items>
                                    </Transition>
                                    <input
                                        id='name'
                                        name='name'
                                        type='text'
                                        autoComplete='name'
                                        placeholder='Enter Name'
                                        value={name}
                                        onChange={(e) => handleInputChange(e)}
                                        className="flex grow rounded-lg border bg-background pl-9 px-4 focus:outline-indigo-600 py-2 text-sm leading-6 shadow-wg-xs transition-colors duration-100 placeholder:text-surface-500 outline-primary focus:outline focus:outline-2 focus:-outline-offset-1 text-surface-900 hover:border-surface-300 dark:hover:border-surface-200 border-surface-200 dark:border-surface-100"
                                    />
                                </Menu>
                            </div>
                            <p className="text-sm text-red-600" id="name-error">
                                {nameError}
                            </p>
                            <div className='flex flex-col gap-y-2 mt-1'>
                                <label className='wg-label inline-flex cursor-pointer items-center gap-1 text-sm font-medium leading-6 capitalize'>description</label>
                                <input
                                    placeholder="Your view description"
                                    id="text"
                                    name='purpose'
                                    type='text'
                                    autoComplete="text"
                                    value={purpose}
                                    onChange={(e) => handleInputChange(e)}
                                    className="flex grow rounded-lg border w-full bg-background px-4 focus:outline-indigo-600 py-2 text-sm leading-6 shadow-wg-xs transition-colors duration-100 placeholder:text-surface-500 outline-primary focus:outline focus:outline-2 focus:-outline-offset-1 text-surface-900 hover:border-surface-300 dark:hover:border-surface-200 border-surface-200 dark:border-surface-100"
                                />
                                <p className="text-sm text-red-600" id="purpose-error">
                                    {purposeError}
                                </p>
                            </div>
                            <div className='flex flex-col gap-2 wg-antialiased'>
                                <Listbox value={selectRole} onChange={setSelectRole}>
                                    {({ open }) => (
                                        <>
                                            <Listbox.Label className="wg-label inline-flex cursor-pointer items-center gap-1 text-sm font-medium leading-6">View Type</Listbox.Label>
                                            <div className="relative">
                                                <Listbox.Button className="relative w-full cursor-default h-[42px] rounded-lg bg-white py-1.5 pl-3 pr-10
                                                 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2
                                                  focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                    <span className="truncate flex items-center gap-[6px]">
                                                        {selectRole.icon}
                                                        {selectRole.name}</span>
                                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">

                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-gray-400">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                                        </svg>

                                                    </span>
                                                </Listbox.Button>

                                                <Transition
                                                    show={open}
                                                    as={Fragment}
                                                    leave="transition ease-in duration-100"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                >
                                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                        {selectRoleType.map((person) => (
                                                            <Listbox.Option
                                                                key={person.id}
                                                                className={({ active }) =>
                                                                    classNames(
                                                                        active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                                                        'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                    )
                                                                }
                                                                value={person}
                                                            >
                                                                {({ selected }) => (
                                                                    <>
                                                                        <span className={classNames(selected ? 'font-semibold flex items-center gap-[6px]' : 'font-normal  flex items-center gap-[6px]',
                                                                            ' flex items-center  gap-[6px]  truncate')}>
                                                                            {person.icon}
                                                                            {person.name}
                                                                        </span>

                                                                    </>
                                                                )}
                                                            </Listbox.Option>
                                                        ))}
                                                    </Listbox.Options>
                                                </Transition>
                                            </div>
                                        </>
                                    )}
                                </Listbox>

                            </div>
                            <div className="flex items-center mt-3">
                                <Switch
                                    checked={checked}
                                    onChange={handleSwitchChange}
                                    className={classNames(
                                        checked ? 'bg-indigo-600' : 'bg-gray-200',
                                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                                    )}
                                >
                                    <span className="sr-only">Use setting</span>
                                    <span
                                        className={classNames(
                                            checked ? 'translate-x-5' : 'translate-x-0',
                                            'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                        )}
                                    >
                                        <span
                                            className={classNames(
                                                checked ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
                                                'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                                            )}
                                            aria-hidden="true"
                                        >
                                        </span>
                                        <span
                                            className={classNames(
                                                checked ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
                                                'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                                            )}
                                            aria-hidden="true"
                                        >
                                        </span>
                                    </span>
                                </Switch>
                                <span className="ml-3 text-sm font-medium text-gray-900 dark:text-black-300 capitalize">
                                    {checked ? 'Public' : 'Private'}
                                </span>
                            </div>
                            <p className="fontfamily text-gray-600 text-sm mb-4">
                                {checked ? "Everyone on your team can view and join this channel." : "Only invited members will be able to view & access this channel."}
                            </p>
                        </div>
                    </form>
                </Modal>
            </button>
        </div>
    );
};

export default BoardPage;


