// @ts-nocheck

import React, { Fragment, HTMLProps, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  ColumnDef,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
} from '@tanstack/react-table'
import { MenuDropdown } from '../../../components/MenuDropdown'
import { Link } from 'react-router-dom'
import { format } from 'date-fns';
import { _baseRequest } from '../../../services/api';
import { CheckIcon, TagIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Dialog, Label, Listbox, Transition } from '@headlessui/react';
import { Button } from "@lemonsqueezy/wedges";
import { useDispatch } from 'react-redux';
import { deleteItemInsightGroup } from '../insight-redux/insight.actions';
import { Toaster, toast } from 'sonner';
import { debounce } from 'lodash';
import { COMMON } from '../../../utils/common';
import { DropdownGroup, InsightTableProps, PaginationState1, Person } from '../type';
import { getSentimentColor } from '../../../utils/colorUtils';



const dropdownGroups: DropdownGroup[] = [
  {
    items: [
      {
        label: `Delete`,
        className: "py-[0.375rem] px-[0.75rem] bg-white text-[#000000eb] text-[13px] hover:bg-[#e7e7e8]",
      },
    ],
  },
];



function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!)

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return (
    <input
      type="checkbox"
      ref={ref}
      className="h-4 w-4 rounded border-gray-300 text-indigo-600 ring-0 outline-none focus:outline-none focus:shadow-none focus:ring-transparent"
      {...rest}
    />
  )
}

const InsightGroupTable: React.FC<InsightTableProps> = ({ sectionHeight, activeFilters, itemAssignedTo }: any) => {
  const [data, setData] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<PaginationState1>({
    pageIndex: 0,
    pageSize: 25,
    currentPage: 1,
    hasNextPage: false,
    totalPages: 1,
    totalItems: 0,
  })
  const [rowSelection, setRowSelection] = React.useState({})
  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;
  const isInitialMount = useRef(true);
  const [initialLoading, setInitialLoading] = useState(true);
  const [isModalOpenCheckbox, setIsModalOpenCheckbox] = useState(false);
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const [deleteOk, setDeleteOk] = useState(false)
  const [rowSelectionDelete, setRowSelectionDelete] = useState<number[]>([]);
  const dispatch = useDispatch();
  const [deleteLoading, setDeleteLoading] = useState(false);


  const _get_ItemGroupInsightList = async (pageIndex: any, pageSize: any, filters: any) => {
    const url = `${COMMON.apiBaseUrl()}/v1/insight-group/${baseId}/insightGroups`;
    let params = new URLSearchParams();

    if (typeof params.append !== 'function') {
      params = {
        append: (key, value) => {
          if (!this[key]) {
            this[key] = [];
          }
          this[key].push(value);
        },
        toString: () => {
          return Object.entries(this)
            .filter(([key]) => key !== 'append' && key !== 'toString')
            .flatMap(([key, values]) => values.map(value => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`))
            .join('&');
        }
      };
    }

    params.append('page', pageIndex.toString());
    params.append('pageSize', pageSize.toString());

    if (filters) {
      Object.entries(filters).forEach(([filterType, filterValues]) => {
        if (Array.isArray(filterValues) && filterValues?.length > 0) {
          const keyPrefix = getKeyPrefix(filterType);

          filterValues.forEach((filterObj) => {
            let value;
            if (filterType === 'sentiment') {
              value = filterObj.value;
            } else if (filterType === 'Created at') {
              const [startDate, endDate] = filterObj.value.split(' - ').map((dateStr: any) => {
                const date = parseDate(dateStr);
                date.setHours(12, 0, 0, 0);
                return date;
              });
              params.append('fromDate', formatDate(startDate));
              params.append('toDate', formatDate(endDate));
              return;
            } else if (filterType === 'Updated at') {
              const [startDate, endDate] = filterObj.value.split(' - ').map((dateStr: any) => {
                const date = parseDate(dateStr);
                date.setHours(12, 0, 0, 0);
                return date;
              });
              params.append('updated_from', formatDate(startDate));
              params.append('updated_till', formatDate(endDate));
              return;
            }

            if (value !== undefined) {
              params.append(keyPrefix, value.toString());
            }
          });
        }
      });
    }

    try {
      const queryString = params.toString();
      const fullUrl = `${url}?${queryString}`;
      return await _baseRequest(fullUrl, 'GET');
    } catch (error:any) {
      throw new Error(`Error fetching item feedback list: ${error.message}`);
    }
  };

  const getKeyPrefix = (filterType:any) => {
    switch (filterType) {
      case 'sentiment':
        return 'insight_group_sentiment';
      case 'Created at':
        return 'fromDate';
      default:
        return filterType.toLowerCase();
    }
  };

  const parseDate = (dateString:any) => {
    const [day, month, year] = dateString.split(' ');
    const monthIndex = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].indexOf(month);
    return new Date(year, monthIndex, parseInt(day));
  };

  const formatDate = (date:any) => {
    return date.toISOString().split('T')[0];
  };


  const fetchItemInsightListData = useCallback(debounce(async (pageIndex, pageSize, filters, isInitialFetch = false, itemAssignedTo) => {
    setLoading(true);

    try {
      const response = await _get_ItemGroupInsightList(pageIndex, pageSize, filters, itemAssignedTo);

      if (response.status === 200) {
        const newData = response.data.insight_groups;
        const newPagination = {
          currentPage: response.data.currentPage,
          hasNextPage: response.data.hasNextPage,
          totalPages: response.data.totalPages,
          pageSize: response.data.pageSize,
          pageIndex: response.data.currentPage - 1,
          total: response.data.totalInsights,
        };

        setData(newData);
        setPagination(newPagination);
        setTotalPages(response.data.totalPages);


        localStorage.setItem('groupListInsightData', JSON.stringify({
          data: newData,
          pagination: newPagination,
          timestamp: Date.now(),
        }));

        if (!isInitialFetch) {
          // console.log('Data updated in background');
        }
      }
    } catch (error) {
      console.error('Error fetching item feedback list:', error);
    } finally {
      setLoading(false);
      if (isInitialFetch) {
        setInitialLoading(false);
      }
    }
    // }, [baseId]);
  }, 500), []); // Adjust the debounce delay as needed


  useEffect(() => {
    const handleSidebarItemClick = () => {
      setPagination({
        pageIndex: 0,
        pageSize: 25,
        currentPage: 1,
        hasNextPage: false,
        totalPages: 1,
        total: 0,
      });
      setTotalPages(0);
      fetchItemInsightListData(1, 25, true);
    };

    window.addEventListener('paginationresetsetate', handleSidebarItemClick);

    return () => {
      window.removeEventListener('paginationresetsetate', handleSidebarItemClick);
    };
  }, [fetchItemInsightListData]);


  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      const cachedData = localStorage.getItem('groupListInsightData');

      if (cachedData) {
        setLoading(true);
        const { data: cachedItems, pagination: cachedPagination } = JSON.parse(cachedData);
        setData(cachedItems);
        setPagination(prevPagination => ({
          ...prevPagination,
          ...cachedPagination,
        }));
        setTotalPages(cachedPagination.totalPages);
        setLoading(false);
      } else {


        fetchItemInsightListData(pagination.currentPage, pagination.pageSize, activeFilters, true);
      }
    } else {
      fetchItemInsightListData(pagination.currentPage, pagination.pageSize, activeFilters, true);
    }
  }, [fetchItemInsightListData, pagination.currentPage, pagination.pageSize, activeFilters]);


  const handlePrevPage = () => {
    setLoading(false)
    if (pagination.currentPage > 1) {
      setPagination(prev => ({ ...prev, currentPage: prev.currentPage - 1 }));
    }
  };

  const handleNextPage = () => {
    setLoading(false)
    if (pagination.hasNextPage) {
      setPagination(prev => ({ ...prev, currentPage: prev.currentPage + 1 }));
    }
  };


  useEffect(() => {
    const handleSidebarItemClick = () => {
      setData([]);
      setPagination({
        pageIndex: 0,
        pageSize: 25,
        currentPage: 1,
        hasNextPage: false,
        totalPages: 1,
        total: 0,
      });
      setTotalPages(0);
      fetchItemInsightListData(1, 25, true);
    };

    window.addEventListener('sidebarItemClicked', handleSidebarItemClick);

    return () => {
      window.removeEventListener('sidebarItemClicked', handleSidebarItemClick);
    };
  }, [fetchItemInsightListData]);

  const rerender = React.useReducer(() => ({}), {})[1]


  // const handleSelect = (row: Row<Person>) => {
  //   setSelectedRows((prevSelectedRows) => {
  //     const newRowState = { ...prevSelectedRows };
  //     newRowState[row.id] = !prevSelectedRows[row.id];
  //     return newRowState;
  //   });
  // };

  const handleSelect = (row: Row<Person>) => {
    setSelectedRows((prevSelectedRows) => {
      const newRowState = { ...prevSelectedRows }
      newRowState[row.id] = !prevSelectedRows[row.id]
      return newRowState
    })
  }



  const columns = useMemo<ColumnDef<Person>[]>(() => [
    {
      id: 'select',
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: (e) => {
              table.getToggleAllRowsSelectedHandler()(e);
              handleCheckboxClick(table); 
            },
          }}
        />
      ),
      cell: ({ row }) => (
        <div className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: (e) => {
                row.getToggleSelectedHandler()(e);
                handleCheckboxClick(row, row.original.id);
              },
            }}
          />
        </div>
      ),

    },
    {
      accessor: 'insight_group',
      header: 'Insight Group',
      cell: info => {
        return (
          <Link to={`/insights/${info.row.original.id}`} className='w-full'>
            <div className=' relative'>
              <div data-title={info.row.original.insight_group} className=' table-tooltip hover:overflow-visible tabletooltip-title'>
                <div className=' text-[#1b1b1b] text-[13px] font-medium line-clamp-2 '>
                  {info.row.original.insight_group}
                </div>
              </div>

            </div>

          </Link>
        );
      },
      sort: (a:any, b:any) => a.insight_group.localeCompare(b.insight_group),
    },
    {
      accessor: 'insight_group_sentiment',
      header: 'Group Sentiment',
      cell: info => {

        const sentiment = info.row.original.insight_group_sentiment?.trim();
        const bgColor = getSentimentColor(sentiment);


        return (
          <Link to={`/insights/${info.row.original.id}`} className='w-full'>
            {sentiment === 'positive' ? (
              <span
                className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 h-[24px] text-xs font-medium text-green-700 capitalize"
                style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
              >
                {sentiment}
              </span>
            ) : sentiment === 'negative' ? (
              <span
                className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 h-[24px] text-xs font-medium text-red-700 capitalize "
                style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
              >
                {sentiment}
              </span>
            ) : sentiment === 'spam' ? (
              <span
                className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 h-[24px] text-xs font-medium text-gray-600 capitalize"
                style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
              >
                {sentiment}
              </span>
            ) : sentiment === 'mixed' ? (
              <span
                className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 h-[24px] text-xs font-medium text-blue-700 capitalize"
                style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
              >
                {sentiment}
              </span>
            ) : null}

          </Link>
        )
      },
      sort: (a, b) => a.insight_group_sentiment.localeCompare(b.insight_group_sentiment),
    },
    {
      accessor: 'count',
      header: 'Count',
      cell: info => {
        return (
          <Link to={`/insights/${info.row.original.id}`} className='w-full'>
            <div data-title={info.row.original.item_count} className="table-tooltip text-[#52555a] font-normal capitalize truncate text-[13px] leading-4 whitespace-nowrap max-w-[80px] w-full overflow-hidden text-ellipsis text-start">
              {info.row.original.item_count}
            </div>
          </Link>
        );
      },
      sort: (a: { count: string }, b: { count: string }) => new Date(a.count).getTime() - new Date(b.count).getTime(),
    },
    {
      accessor: 'ticket',
      header: 'Ticket',
      cell: info => {
        return (
          <>
            {
              info.row.original.item_ticket_id === null ? "" :

                <Link to={`/insights/${info.row.original.id}`} className='w-full'>
                  <div data-title={info.row.original.attached_group_ticket.item_title} className="table-tooltip text-[#52555a] font-normal capitalize truncate text-[13px] leading-4 whitespace-nowrap max-w-[440px] w-full overflow-hidden text-ellipsis text-start">
                    <div className='flex items-center gap-1'>
                      <span> {info.row.original.attached_group_ticket.item_refernce_id}</span>
                      -
                      <span>
                        {info.row.original.attached_group_ticket.item_title}
                      </span>
                    </div>

                  </div>
                </Link>
            }

          </>
        );
      },
      sort: (a: { ticket: string }, b: { ticket: string }) => new Date(a.ticket).getTime() - new Date(b.ticket).getTime(),
    },
    {
      accessor: 'date',
      header: 'Date',
      cell: info => {
        const formattedDate = format(new Date(info.row.original.created_at), 'MMM d');
        const formattedDatetooltip = format(new Date(info.row.original.created_at), 'MMM d');
        return (
          <Link to={`/insights/${info.row.original.id}`} className='w-full'>
            <div data-title={formattedDatetooltip} className="table-tooltip text-[#52555a] font-normal capitalize truncate text-[13px] leading-4 whitespace-nowrap max-w-[80px] w-full overflow-hidden text-ellipsis text-start">
              {formattedDate}
            </div>
          </Link>
        );
      },
      sort: (a: { date: string }, b: { date: string }) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    },
  ], []);

  // delete row

  useEffect(() => {
    setSelectedRowsCount(Object.keys(rowSelection)?.length);
  }, [rowSelection]);


  const handleCheckboxClick = (row:any, id:any) => {
    setSelectedRows((prevSelectedRows) => ({
      ...prevSelectedRows,
      [row.id]: !prevSelectedRows[row.id]
    }));
    setRowSelectionDelete((prevSelection) => {

      if (prevSelection?.includes(id)) {
        return prevSelection.filter((itemId) => itemId !== id);
      } else {
        return [...prevSelection, id];
      }
    });

    setIsModalOpenCheckbox(true);
  };

  const handleConfirmDelete = (id:any) => {
    setDeleteOk(true)
  }

  const handleDeleteConfirm = async () => {
    setDeleteLoading(true);
    try {
      let groupIdsToDelete = Array.isArray(rowSelectionDelete) ? rowSelectionDelete : [rowSelectionDelete];

      const deleteResponse = await dispatch(deleteItemInsightGroup(baseId, groupIdsToDelete));

      if (!deleteResponse || !deleteResponse.data) {
        throw new Error('Invalid delete response received');
      }

      // Process the delete response
      const successfulDeletes = deleteResponse.data.filter((item:any) => item.isSuccess).map((item) => item.id);
      const failedDeletes = deleteResponse.data.filter((item:any) => !item.isSuccess);

      if (successfulDeletes.length > 0) {
        toast.success(`Successfully deleted item(s)`);
      }
      // successfulDeletes.forEach((id) => {
      //   toast.success(`Successfully deleted item ${id}`);
      // });
      failedDeletes.forEach((item:any) => {
        toast.error(`Failed to delete item ${item.id}: ${item.reason}`);
      });

      if (successfulDeletes.length > 0) {
        const updatedResponse = await _get_ItemGroupInsightList(
          pagination.pageIndex + 1,
          pagination.pageSize,
          activeFilters,
          false,
          itemAssignedTo
        );

        if (updatedResponse.status === 200) {
          const newData = updatedResponse.data.insight_groups;
          const newPagination = {
            currentPage: updatedResponse.data.currentPage,
            hasNextPage: updatedResponse.data.hasNextPage,
            totalPages: updatedResponse.data.totalPages,
            pageSize: updatedResponse.data.pageSize,
            pageIndex: updatedResponse.data.currentPage - 1,
            total: updatedResponse.data.totalInsight,
          };

          setData(newData);
          setPagination(newPagination);
          setTotalPages(updatedResponse.data.totalPages);

          // Update local storage with new data
          localStorage.setItem('groupListInsightData', JSON.stringify({
            data: newData,
            pagination: newPagination,
            timestamp: Date.now(),
          }));

          // Reset states
          setRowSelectionDelete([]);
          setDeleteOk(false);
          setIsModalOpenCheckbox(false);
          setSelectedRows({});
          setRowSelection({});
          setSelectedRowsCount(0);
        } else {
          console.error('Error fetching updated item feedback list:', updatedResponse);
          toast.error('Failed to fetch updated item feedback list');
        }
      }
    } catch (error:any) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(`An error occurred: ${error.response.data.message}`);
      } else {
        console.error('Error deleting insight groups:', error);
        toast.error('An error occurred while deleting insight groups. Please try again later.');
      }
    } finally {
      setDeleteLoading(false);
    }
  };


  const table = useReactTable({
    data,
    columns,
    state: {
      pagination,
      rowSelection,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
    onPaginationChange: setPagination,


  });


  useEffect(() => {
    const applyStyles = () => {
      const tableElement = document.querySelector('.feedback-col') as HTMLElement | null;
      const tableElement1 = document.querySelector('.feedback-thead') as HTMLElement | null;
      const isTabScreen = window.matchMedia('(max-width: 1499px)').matches;
      const isSmallScreen = window.matchMedia('(max-width: 767px)').matches;
      if (tableElement) {
        if (isSmallScreen) {
          tableElement.style.marginTop = sectionHeight ? `calc(50px + ${sectionHeight}px)` : '50px';
        } else if (isTabScreen) {
          tableElement.style.marginTop = sectionHeight ? `calc(45px + ${sectionHeight}px)` : '46px';
        } else {
          tableElement.style.marginTop = sectionHeight ? `calc(87px + ${sectionHeight}px)` : '87px';
        }
      }
      if (tableElement1) {
        if (isSmallScreen) {
          tableElement1.style.top = sectionHeight ? `calc(80px + ${sectionHeight}px)` : '80px';
        } else if (isTabScreen) {
          tableElement1.style.top = sectionHeight ? `calc(101px + ${sectionHeight}px)` : '101px';
        } else {
          tableElement1.style.top = sectionHeight ? `calc(101px + ${sectionHeight}px)` : '101px';
        }

        if (sectionHeight) {
          tableElement1.style.borderTop = '1px solid #e5e7eb';
        } else {
          tableElement1.style.borderTop = 'none';
        }
      }
    };

    applyStyles();

    window.addEventListener('resize', applyStyles);
    return () => {
      window.removeEventListener('resize', applyStyles);
    };
  }, [sectionHeight]);


  return (
    <>
      <div className='mx-auto max-w-full'>
        <div className="px-4 sm:px-6 lg:px-8 feedback-table">
          <div className="flow-root h-full">
            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8 h-full">
              <div className="inline-block min-w-full py-2 align-middle h-full max-w-full">
                <div className="relative  h-full block pb-[5rem]">
                  <table className="block border-collapse overflow-x-auto w-full feedback-col overflow-y-auto feedback-table-height h-full">
                    <thead
                      className='mq1499:sticky mq3000:fixed bg-white grid feedback-thead feedback-table-row'
                    // className='sticky md:fixed lg:fixed bg-white grid mq767:top-[0px] mq1023:top-[116px] mq3000:top-[101px] feedback-thead feedback-table-row'
                    >
                      {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id} className='bg-white flex relative w-full'>
                          {headerGroup.headers.map(header => {

                            return (
                              <th key={header.id} colSpan={header.colSpan} scope="col"
                                className={`sticky bg-white flex flex-1 py-[0.75rem] px-[1rem] cursor-pointer text-[0.75rem] items-center justify-start
                                 leading-[1rem] text-[#52555a] font-[500] border-b  border-solid border-light-gray-200 min-w-[50px] md:min-w-[160px] lg:min-w-[50px]
                                   ${header.id === 'select' ? 'max-w-[50px] [&>div]:px-0' : ''} 
                                   ${header.id === 'Insight Group' ? 'max-w-[-webkit-fill-available] !min-w-[400px]' : ''}
                                   ${header.id === 'Group Sentiment' ? '!min-w-[106px]' : ''}
                                   ${header.id === 'Count' ? '!min-w-[106px]' : ''}
                                   ${header.id === 'Ticket' ? 'max-w-[-webkit-fill-available] !min-w-[700px]' : ''}
                                   ${header.id === 'Date' ? '!min-w-[106px]' : ''}
                                     `}
                              >
                                <div
                                  {...{
                                    className: header.column.getCanSort()
                                      ? 'cursor-pointer select-none '
                                      : '',
                                    onClick: header.column.getToggleSortingHandler(),
                                  }}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                  {{
                                    asc: ' 🔼',
                                    desc: ' 🔽',
                                  }[header.column.getIsSorted() as string] ?? null}
                                </div>
                              </th>
                            )
                          })}
                          <th
                            className={`sticky bg-white flex flex-1 py-[0.75rem] px-[1rem] cursor-pointer text-[0.75rem] items-center justify-start
                            leading-[1rem] text-[#52555a] font-[500] border-r border-b  border-solid border-light-gray-200 min-w-10 max-w-[56px]`}
                          ></th>
                        </tr>
                      ))}
                    </thead>
                    <div>


                      {loading ? (
                        <div className="flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 191px)' }}>
                          <div className="simple-spinner-loader">
                            <span></span>
                          </div>
                        </div>
                      ) : pagination.totalPages === 0 ? (
                        <div className="flex items-center justify-center z-50" style={{ height: 'calc(100vh - 191px)' }}>
                          <p>No data found</p>
                        </div>
                      ) : (
                        <tbody className="grid">
                          {table.getRowModel().rows.map((row) => {
                            const allRowsSelected = table.getIsAllRowsSelected();
                            return (
                              <tr
                                key={row.id}
                                data-selected={allRowsSelected ? true : selectedRows[row.id]}
                                onClick={() => handleSelect(row)}
                                className={`border-b border-solid border-light-gray-200 bg-white flex w-full relative ${selectedRows[row.id] ? 'bg-gray-100' : ''}`}
                              >
                                {row.getVisibleCells().map((cell) => (
                                  <td
                                    key={cell.id}
                                    className={`flex items-center justify-start text-start px-[1rem] py-[0.9rem] leading-[1rem]
                                    flex-1 text-dark-black-200 max-w-[350px]
                                    ${/(_select)$/.test(cell.id) ? 'max-w-[50px] [&>div]:px-0' : ''} 
                                    ${/(_Insight Group)$/.test(cell.id) ? '!max-w-[-webkit-fill-available] min-w-[400px]' : ''}
                                    ${/(_Group Sentiment)$/.test(cell.id) ? '!min-w-[106px]' : ''}
                                    ${/(_Count)$/.test(cell.id) ? '!min-w-[106px]' : ''}
                                    ${/(_Ticket)$/.test(cell.id) ? '!max-w-[-webkit-fill-available] min-w-[700px]' : ''}
                                    ${/(_Date)$/.test(cell.id) ? '!min-w-[106px]' : ''}

                                      
                                    
                                    `}
                                  >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                  </td>
                                ))}
                                <td
                                  className={`flex items-center text-start px-[0.75rem] py-[1rem] leading-[1rem] flex-1 max-w-[50px] text-dark-black-200 justify-center [&>button]:w-auto [&>button]:bg-transparent [&>button]:hover:bg-transparent`}
                                >
                                  <MenuDropdown
                                    groups={dropdownGroups}
                                    image={
                                      <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        aria-hidden="true"
                                        focusable="false"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <circle cx="12" cy="12" r="1"></circle>
                                        <circle cx="12" cy="5" r="1"></circle>
                                        <circle cx="12" cy="19" r="1"></circle>
                                      </svg>
                                    }
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                    </div>

                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed z-10 bg-white border-t border-solid border-light-gray-200 py-[0.5rem] px-[1rem] bottom-0 pagination-bottom">
        <div className="relative flex items-center justify-between">



          <div className="flex items-center gap-2">
            <span className="flex items-center gap-1">
              <div className='text-[0.875rem] font-[500] text-[#000000eb]'>Page
                {/* 
                               <span className='mr-1'> {table.getState().pagination.pageIndex + 1} of{' '}
                      {table.getPageCount().toLocaleString()}</span> */}
              </div>
            </span>
            <span className="flex items-center gap-1">

              <input
                type="number"
                value={pagination.currentPage}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) : 1;
                  setPagination((prevPagination) => ({
                    ...prevPagination,
                    currentPage: page,
                  }));
                }}
                className="w-[4rem] h-[2rem] text-[0.8125rem] px-[0.75rem] rounded-md border border-solid border-light-gray-100"
              />

              <span className='mr-1 text-[#000000eb] text-[0.8125rem]'>  of{' '}

                {totalPages}
              </span>
            </span>


            <div className='flex items-center gap-1'>

              <select
                value={table.getState().pagination.pageSize}
                onChange={e => {
                  table.setPageSize(Number(e.target.value))
                }}
                className="w-[6rem] h-[2rem] text-[0.8125rem] px-[0.75rem] rounded-md border border-solid border-light-gray-100 block"
              >
                {[25, 50, 100].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
              <div className='mr-1 text-[#000000eb] text-[0.8125rem] ml-[14px]'>
                {pagination.total} Items
              </div>
            </div>


          </div>
          <div className='flex gap-2'>
            <button
              className="border rounded py-2 px-2"
              onClick={handlePrevPage}
              // disabled={!table.getCanPreviousPage()}
              disabled={pagination.currentPage === 1}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-2 w-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
              </svg>

            </button>
            <button
              className="border rounded py-2 px-2"
              onClick={handleNextPage}
              // disabled={!table.getCanNextPage()}
              disabled={!pagination.hasNextPage}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-2 w-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
              </svg>

            </button>
          </div>
        </div>
      </div>



      <div open={isModalOpenCheckbox} onClose={() => { }} className={`relative z-10 w-full ${isModalOpenCheckbox ? 'common-slider-one' : 'common-slider-two'}`}
      >
        {selectedRowsCount > 0 && (
          <div className="fixed inset-y-0 right-0 flex checkbox-modal h-[100%] w-[800px] max-w-[800px] bottom-0 top-[-70px]">
            <div className="flex h-auto flex-col overflow-y-scroll bg-white py-6 rounded-lg" style={{ boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1),0 2px 4px -1px rgba(0, 0, 0, 0.06)' }}>
              <div className="px-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <span className='text-dark-black-100 text-[13px] font-[700]'>{selectedRowsCount} selected</span>
                  <div className="flex items-center">
                    <div className="w-full flex items-center justify-between">
                      <div className="flex items-center gap-[0.5rem]">
                        <Button className='text-[13px] text-dark-black-100 font-[600] [&>span]:font-[600] h-[32px] rounded-md flex-row-reverse border-[#e7e7e8] shadow-none'
                          after={<TagIcon className='w-[13px] h-[13px] text-dark-black-100 font-[600] opacity-80' strokeWidth={3} />} variant="outline">
                          Add tags
                        </Button>
                        <Button
                          onClick={(id) => handleConfirmDelete(id)}
                          className='text-[13px] text-dark-black-100 font-[600] [&>span]:font-[600] h-[32px] rounded-md flex-row-reverse border-[#e7e7e8] shadow-none'
                          after={<TrashIcon className='w-[13px] h-[13px] text-dark-black-100 font-[600] opacity-80' strokeWidth={3} />} variant="outline">
                          Delete
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        )}
      </div>
      <Transition.Root show={deleteOk} as={Fragment}>
        <Dialog as="div" className="relative z-50 md:z-10 lg:z-10" onClose={() => { }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center sm:items-start h-full items-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4
                   data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full
                    sm:max-w-[500px] sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                >
                  <span className='font-[600]'>Delete
                    <span> {selectedRowsCount} Insight Groups</span>
                  </span>

                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to delete your insights?
                    </p>
                  </div>
                  <div className="mt-5 sm:mt-4 flex gap-2 justify-end">
                    <Button
                      type="button"
                      onClick={handleDeleteConfirm}
                      className={`bg-red-600 hover:hover:bg-red-500 focus-visible:outline-red-600 relative
                      [&>span]:flex [&>span]:items-center [&>span]:gap-[8px]`}
                    >
                      Delete
                      {deleteLoading && (
                        <div className="simple-spinner">
                          <span></span>
                        </div>
                      )}
                    </Button>
                    <button
                      type="button"
                      onClick={() => setDeleteOk(false)}
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>

              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>


    </>
  );

}


export default InsightGroupTable;