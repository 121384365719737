
import { _baseRequest  } from "../../../services/api";

const API_END_POINT = {
    TWOSTEP: "/v1/auth/twoStep"
}

export const user_otp = async (
    token: string | null,
    otp: string,
    deviceId: string,
    deviceName: string
) => {
    return _baseRequest(API_END_POINT.TWOSTEP, "POST", {
        token,
        otp,
        deviceId,
        deviceName
    }).then((response) => {
        return response;
    }).catch((error) => {
        return error
    });
};


