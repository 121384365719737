// src/utils/filterUtils.ts
export const getKeyPrefix = (filterType: string): string => {
    switch (filterType) {
      case 'Boards':
        return 'board_id';
      case 'label':
        return 'item_label';
      case 'status':
        return 'item_base_status_id';
      case 'assign':
        return 'item_assigned_to';
      case 'priority':
        return 'item_priority';
      case 'sentiment':
        return 'item_sentiment';
      case 'Created at':
        return 'created_from';
      case 'Updated at':
        return 'updated_from';
      default:
        return filterType.toLowerCase();
    }
  };
  