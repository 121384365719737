//@ts-nocheck

import React, { useEffect, useRef, useState } from "react"
import DragList from '../../integration/integrationKanban/DragList';
import { v4 as uuidv4 } from 'uuid';
import MultiSelectLabelFilter from "../../../components/MultiSelectLabelFilter";
import Datepicker from "react-tailwindcss-datepicker";
import { format } from 'date-fns';
import IntegrationTable from "../../integration/list/IntegrationTable";


export default function Integration() {
  const [activeButton, setActiveButton] = useState('one');
  const [sectionHeight, setSectionHeight] = useState(0);
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);
  const [boardsMenuOpen, setBoardsMenuOpen] = useState(false);
  const [statusMenuOpen, setStatusMenuOpen] = useState(false);
  const [tagsMenuOpen, setTagsMenuOpen] = useState(false);
  const [createdMenuOpen, setCreatedMenuOpen] = useState(false);
  const storedFilters = localStorage.getItem('activeFilters');
  const initialFilters = storedFilters ? JSON.parse(storedFilters) : {
    Boards: [], label: [], assign: [],
    priority: [], sentiment: [], status: [], 'Created at': [],
    'Updated By': []
  };
  const [activeFilters, setActiveFilters] = useState(initialFilters);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusSearchQuery, setStatusSearchQuery] = useState("");
  const [tagsSearchQuery, setTagsSearchQuery] = useState("");
  const selectedValuesRef = useRef<HTMLDivElement>(null);
  const [localItemBoards, setLocalItemBoards] = useState([])
  const [localItemStatus, setLocalItemStatus] = useState([])
  const [localItemTags, setLocalItemTags] = useState([])
  const [activeIndex, setActiveIndex] = useState(-1);
  const [activeIndex1, setActiveIndex1] = useState(-1);
  const [activeState, setActiveState] = useState('all');
  const [boardsSearchQuery, setBoardsSearchQuery] = useState("");
  const [assignMenuOpen, setAssignMenuOpen] = useState(false);
  const [localItemAssign, setLocalItemAssign] = useState([])
  const [assignSearchQuery, setAssignSearchQuery] = useState("");
  const [priorityMenuOpen, setPriorityMenuOpen] = useState(false);
  const [localItemPriority, setLocalItemPriority] = useState([])
  const [prioritySearchQuery, setPrioritySearchQuery] = useState("");
  const [sentimentMenuOpen, setSentimentMenuOpen] = useState(false);
  const [localItemSentiment, setLocalItemSentiment] = useState([])
  const [sentimentSearchQuery, setSentimentSearchQuery] = useState("");
  const [selectedTags, setSelectedTags] = useState(() => {
    const storedFilters = localStorage.getItem('activeFilters');
    const initialFilters = storedFilters ? JSON.parse(storedFilters) : {
      Boards: [], label: [], assign: [], priority: [], sentiment: [], status: [], 'Created at': [], 'Updated By': []
    };
    return initialFilters.label || [];
  });
  
  const [updatedMenuOpen, setUpdatedMenuOpen] = useState(false);
  const [assignedItemId, setAssignedItemId] = useState<string | null>(null);
  const [editingFilter, setEditingFilter] = useState(null);
  const [openCategoryMenu, setOpenCategoryMenu] = useState(null);


  const handleAssignToMe = () => {
    const itemIdToAssign = "item_assigned_to";
    setAssignedItemId(itemIdToAssign);
    setActiveState('assignToMe')
  };


  const toggleFilter = (category, newFilter) => {
    setActiveFilters((prevFilters) => {
      const categoryFilters = prevFilters[category] || [];
      const existingFilterIndex = categoryFilters.findIndex(
        (filter) => filter.id === newFilter.id
      );

      let updatedCategoryFilters;
      if (existingFilterIndex !== -1) {
        updatedCategoryFilters = categoryFilters.map((filter, index) =>
          index === existingFilterIndex ? newFilter : filter
        );
      } else {
        updatedCategoryFilters = [...categoryFilters, newFilter];
      }
      return {
        ...prevFilters,
        [category]: updatedCategoryFilters
      };
    });
  };

  // boards
  useEffect(() => {
    const storedBoardsItems = localStorage.getItem("channelData");
    if (storedBoardsItems) {
      const parsedItems = JSON.parse(storedBoardsItems);
      if (parsedItems && parsedItems) {
        setLocalItemBoards(parsedItems);
      }
    }
  }, []);


  // status
  useEffect(() => {
    const storedStatusItems = localStorage.getItem("itemStatusList");
    if (storedStatusItems) {
      const parsedItems = JSON.parse(storedStatusItems);      
      if (parsedItems && parsedItems.data) {
        setLocalItemStatus(parsedItems.data);
      }
    }
  }, []);
  

  // assign to
  useEffect(() => {
    const storedAssignToItems = localStorage.getItem("baseMembersDetails");
    if (storedAssignToItems) {
      const parsedItems = JSON.parse(storedAssignToItems);
      if (parsedItems && parsedItems) {
        setLocalItemAssign(parsedItems);
      }
    }
  }, []);


  // priority
  useEffect(() => {
    const storedPriorityToItems = localStorage.getItem("priorities");
    if (storedPriorityToItems) {
      const parsedItems = JSON.parse(storedPriorityToItems);
      if (parsedItems && parsedItems) {
        setLocalItemPriority(parsedItems);
      }
    }
  }, []);


  // sentiments
  useEffect(() => {
    const storedSentimentToItems = localStorage.getItem("sentiments");
    if (storedSentimentToItems) {
      const parsedItems = JSON.parse(storedSentimentToItems);
      if (parsedItems && parsedItems) {
        setLocalItemSentiment(parsedItems);
      }
    }
  }, []);



  // tags
  useEffect(() => {
    const updatedFilters = selectedTags.map(tag => {
      return {
        id: tag.id,
        category: 'label',
        value: tag.value,
        isNew: tag.isNew,
        icon: tag.icon,
        color: tag.color

      };
    });
    setActiveFilters(prevFilters => ({
      ...prevFilters,
      label: updatedFilters
    }));
  }, [selectedTags]);


  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowUp') {
        setActiveIndex((prevIndex) => Math.max(prevIndex - 1, 0));
      } else if (event.key === 'ArrowDown') {
        setActiveIndex((prevIndex) => Math.min(prevIndex + 1, localItemTags.length - 1));
      } else if (event.key === 'Enter') {
        if (activeIndex >= 0 && activeIndex < localItemTags.length) {
          const selectedItem = localItemTags[activeIndex];
          setSelectedTags((prevSelectedTags) => {
            if (prevSelectedTags.find(tag => tag.id === selectedItem.id)) {
              return prevSelectedTags.filter(tag => tag.id !== selectedItem.id);
            } else {
              return [...prevSelectedTags, selectedItem];
            }
          });
          setTagsMenuOpen(false);
          setTagsSearchQuery("");
          setCreatedMenuOpen(false)
        }
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [localItemTags, activeIndex]);


  const itemsRef1 = useRef([]);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowUp") {
        setActiveIndex1((prevIndex) => {
          const newIndex = Math.max(prevIndex - 1, 0);
          scrollItemIntoView(newIndex);
          return newIndex;
        });
      } else if (event.key === "ArrowDown") {
        setActiveIndex1((prevIndex) => {
          const newIndex = Math.min(prevIndex + 1, localItemStatus.length - 1);
          const newIndex1 = Math.min(prevIndex + 1, localItemBoards.length - 1);
          const newIndex2 = Math.min(prevIndex + 1, localItemAssign.length - 1);
          const newIndex3 = Math.min(prevIndex + 1, localItemPriority.length - 1);
          const newIndex4 = Math.min(prevIndex + 1, localItemSentiment.length - 1);
          scrollItemIntoView(newIndex);
          scrollItemIntoView(newIndex1);
          scrollItemIntoView(newIndex2);
          scrollItemIntoView(newIndex3);
          scrollItemIntoView(newIndex4);
          return Math.max(newIndex, newIndex1, newIndex2, newIndex3, newIndex4);
        });
      } else if (event.key === "Enter") {
        if (activeIndex1 >= 0) {
          let newFilter = null;

          if (statusMenuOpen && activeIndex1 < localItemStatus.length) {
            const selectedItem = localItemStatus[activeIndex1];
            newFilter = {
              id: selectedItem.id,
              label: "Status",
              value: selectedItem.base_status_title,
              icon: <BadgeStatus borderColor={selectedItem.color} />
            };
            setStatusMenuOpen(false);
            setStatusSearchQuery("");
          } else if (boardsMenuOpen && activeIndex1 < localItemBoards.length) {
            const selectedItem = localItemBoards[activeIndex1];
            newFilter = {
              id: selectedItem.id,
              label: "Boards",
              value: selectedItem.name,
              icon: <BadgeBoards borderColor={selectedItem.color} />
            };
            setBoardsMenuOpen(false);
            setBoardsSearchQuery("");
          } else if (assignMenuOpen && activeIndex1 < localItemAssign.length) {
            const selectedItem = localItemAssign[activeIndex1];
            newFilter = {
              id: selectedItem.id,
              label: "Assign to",
              value: selectedItem.name,
              icon: <BadgeAssign borderColor={selectedItem.name} />
            };
            setAssignMenuOpen(false);
            setAssignSearchQuery("");
          } else if (tagsMenuOpen && activeIndex1 < localItemTags.length) {
            const selectedItem = localItemTags[activeIndex1];
            newFilter = {
              id: selectedItem.id,
              label: "Label",
              value: selectedItem.value,
              icon: <BadgeTags borderColor={selectedItem.color} />
            };
            setAssignMenuOpen(false);
          } else if (priorityMenuOpen && activeIndex1 < localItemPriority.length) {
            const selectedItem = localItemPriority[activeIndex1];
            newFilter = {
              id: selectedItem.id,
              label: "Priority",
              value: selectedItem.name,
              icon: <BadgeAssign borderColor={selectedItem.name} />
            };
            setPriorityMenuOpen(false);
            setPrioritySearchQuery("");
          } else if (sentimentMenuOpen && activeIndex1 < localItemSentiment.length) {
            const selectedItem = localItemSentiment[activeIndex1];
            newFilter = {
              id: selectedItem.id,
              label: "Sentiment",
              value: selectedItem.name,
              icon: <BadgeSentiment borderColor={selectedItem.label} value={selectedItem.name} />
            };
            setSentimentMenuOpen(false);
            setSentimentSearchQuery("");
          }


          if (newFilter) {
            setActiveFilters((prevFilters) => ({
              ...prevFilters,
              [newFilter.label]: [...(prevFilters[newFilter.label] || []), newFilter]
            }));
          }
        }
      }
    };

    const scrollItemIntoView = (index) => {
      const item = itemsRef1.current[index];
      if (item) {
        item.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [localItemStatus, localItemBoards, localItemAssign, activeFilters, setActiveFilters,
    setStatusMenuOpen, setAssignMenuOpen, setBoardsMenuOpen,
    setStatusSearchQuery, setBoardsSearchQuery, setAssignSearchQuery, activeIndex1,
    statusMenuOpen, boardsMenuOpen, assignMenuOpen, priorityMenuOpen, localItemPriority, setPriorityMenuOpen, setPrioritySearchQuery
    , localItemSentiment, setSentimentMenuOpen, setSentimentSearchQuery, setSentimentMenuOpen

  ]);


  // Badge Component
  const BadgeTags = ({ borderColor }) => (
    <span className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full text-[0.625rem] font-medium text-white border-2 border-solid border-${borderColor}`} />
  );

  const BadgeBoards = ({ borderColor }) => (
    <span className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-[2px] text-[0.625rem] font-medium text-white bg-[${borderColor}]`} />
  );

  const BadgeStatus = ({ borderColor }) => (
    <span className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full text-[0.625rem] font-medium text-white ${borderColor}`} />
  );

  const BadgeAssign = ({ borderColor, profilePic }) => (
    <div className="w-5 h-5 rounded-full bg-gray-200 flex items-center justify-center text-[10px] overflow-hidden">
    {profilePic ? (
      <img
        src={
          profilePic
            ? `${process.env.REACT_APP_API_BASE_URL}/${profilePic}`
            : ''
        }
        alt="Profile"
        className="w-full h-full object-cover rounded-full"
      />
    ) : (
      <span className="text-[7px] font-medium text-gray-700">
        {borderColor ? borderColor.charAt(0) : ''}
      </span>
    )}
  </div>
  );



  const BadgeSentiment = ({ borderColor, value }) => (
    <>
      {value === 'positive' ? (
        <span
          className="w-2 h-2 rounded-full block bg-[#15803d]"
        >

        </span>
      ) : value === 'negative' ? (
        <span
          className="w-2 h-2 rounded-full block bg-[#b91c1c] "
        >

        </span>
      ) : value === 'spam' ? (
        <span
          className="w-2 h-2 rounded-full block bg-[#4b5563]"
        >

        </span>
      ) : value === 'mixed' ? (
        <span
          className="w-2 h-2 rounded-full block bg-[#1d4ed8] "
        >
        </span>
      ) : value === 'request' ? (
        <span
          className="w-2 h-2 rounded-full block bg-[#1d4ed8] "
        >
        </span>
      ) : null}
    </>
  );


  const hasActiveFilters = Object.values(activeFilters).some(filters => filters.length > 0);

  const mainItems = [
    {
      id: uuidv4(),
      label: 'Boards',
      icon: <span className="text-[#1d2025] w-[8px] h-[8px] rounded-[2px] border-2 border-solid border-[#7d7f83] block"></span>,
      menuOpen: boardsMenuOpen,
      setMenuOpen: setBoardsMenuOpen,
    },
    {
      id: uuidv4(),
      label: 'Status',
      icon: <span className="text-[#1d2025] w-2 h-2 rounded-full bg-[#7d7f83] block"></span>,
      menuOpen: statusMenuOpen,
      setMenuOpen: setStatusMenuOpen,
    },
    {
      id: uuidv4(),
      label: 'Assign to',
      icon: <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        aria-hidden="true"
        data-slot="icon"
        className="w-[14px] h-[14px] text-[#1e1e1e]"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
        />
      </svg>,
      menuOpen: assignMenuOpen,
      setMenuOpen: setAssignMenuOpen,
    },
    {
      id: uuidv4(),
      label: 'Priority',
      icon: <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        aria-hidden="true"
        data-slot="icon"
        className="w-[14px] h-[14px] text-[#1e1e1e]"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z"
        />
      </svg>
      ,
      menuOpen: priorityMenuOpen,
      setMenuOpen: setPriorityMenuOpen,
    },
    {
      id: uuidv4(),
      label: 'Sentiment',
      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[14px] h-[14px] text-[#1e1e1e]">
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
      </svg>
      ,
      menuOpen: sentimentMenuOpen,
      setMenuOpen: setSentimentMenuOpen,
    },
    {
      id: uuidv4(),
      label: 'Label',
      icon: <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" focusable="false" aria-hidden="true"
        height="0.9em" width="0.9em" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path>
        <line x1="7" y1="7" x2="7.01" y2="7"></line>
      </svg>,
      menuOpen: tagsMenuOpen,
      setMenuOpen: setTagsMenuOpen,
    },
    {
      id: uuidv4(),
      label: 'Created at',
      icon: <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" focusable="false" aria-hidden="true"
        height="0.9em" width="0.9em" xmlns="http://www.w3.org/2000/svg">
        <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
        <line x1="16" y1="2" x2="16" y2="6"></line>
        <line x1="8" y1="2" x2="8" y2="6"></line>
        <line x1="3" y1="10" x2="21" y2="10"></line>
      </svg>,
      menuOpen: createdMenuOpen,
      setMenuOpen: setCreatedMenuOpen,
    },
    {
      id: uuidv4(),
      label: 'Updated at',
      icon: <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" focusable="false" aria-hidden="true"
        height="0.9em" width="0.9em" xmlns="XXXXXXXXXXXXXXXXXXXXXXXXXX">
        <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
        <line x1="16" y1="2" x2="16" y2="6"></line>
        <line x1="8" y1="2" x2="8" y2="6"></line>
        <line x1="3" y1="10" x2="21" y2="10"></line>
      </svg>,
      menuOpen: updatedMenuOpen,
      setMenuOpen: setUpdatedMenuOpen,
    },
  ];


  // boards
  const boardsItems = localItemBoards?.map((item, index) => ({
    id: item.id,
    label: item.name,
    icon: <BadgeBoards borderColor={item.color} />,
    onClick: () => {
      toggleFilter('Boards', {
        id: item.id,
        value: item.name,
        icon: <BadgeBoards borderColor={item.color} />
      });
      setBoardsMenuOpen(false);
      setBoardsSearchQuery("");
      setActiveIndex1(index);
    },
    className: activeIndex1 === index ? 'bg-[#f1f1f2]' : '',

  }));


  // status
  const statusItems = localItemStatus?.map((item, index) => ({
    id: item.id,
    label: item.base_status_title,
    icon: <BadgeStatus borderColor={item.color} />,
    onClick: () => {
      toggleFilter('status', {
        id: item.id,
        value: item.base_status_title,
        icon: <BadgeStatus borderColor={item.color} />
      });
      setStatusMenuOpen(false);
      setStatusSearchQuery("");
      setActiveIndex1(index);
    },
    className: activeIndex1 === index ? 'bg-[#f1f1f2]' : '',
  }));



  // assign
  const assignItems = localItemAssign?.map((item, index) => ({
    id: item.id,
    label: item.name,
    icon: <BadgeAssign
    borderColor={item.profile_pic ? item.profile_pic : item.name}
    profilePic={item.profile_pic}
  />,
    onClick: () => {
      toggleFilter('assign', {
        id: item.id,
        value: item.name,
        icon: <BadgeAssign
        borderColor={item.profile_pic ? item.profile_pic : item.name}
        profilePic={item.profile_pic}
      />
      });
      setAssignMenuOpen(false);
      setAssignSearchQuery("");
      setActiveIndex1(index);
    },
    className: activeIndex1 === index ? 'bg-[#f1f1f2]' : '',
  }));


  // priority
  const priorityItems = localItemPriority?.map((item, index) => ({
    id: item.id,
    label: item.name,
    icon: <BadgeAssign borderColor={item.name} />,
    onClick: () => {
      toggleFilter('priority', {
        id: item.id,
        value: item.name,
        icon: <BadgeAssign borderColor={item.name} />
      });
      setPriorityMenuOpen(false);
      setPrioritySearchQuery("");
      setActiveIndex1(index);
    },
    className: activeIndex1 === index ? 'bg-[#f1f1f2]' : '',
  }));


  // sentiment
  const sentimentItems = localItemSentiment?.map((item, index) => ({
    id: item.id,
    label: item.name,
    icon: <BadgeSentiment borderColor={item.label} value={item.name}/>,
    onClick: () => {
      toggleFilter('sentiment', {
        id: item.id,
        value: item.name,
        icon: <BadgeSentiment borderColor={item.label} value={item.name}/>
      });
      setSentimentMenuOpen(false);
      setSentimentSearchQuery("");
      setActiveIndex1(index);
    },
    className: activeIndex1 === index ? 'bg-[#f1f1f2]' : '',
  }));

  // tags
  const tagsItems = localItemTags?.map((item, index) => ({
    id: item.id,
    label: item.name,
    icon: <BadgeTags borderColor={item.label} />,
    onClick: () => {
      toggleFilter('label', {
        id: item.id,
        value: item.value,
        icon: <BadgeTags borderColor={item.label} />
      });
      setTagsMenuOpen(false);
      setTagsSearchQuery("");
      setActiveIndex(index);
    },
    className: activeIndex1 === index ? 'bg-[#f1f1f2]' : '',
  }));


  const handleMainMenuClick = () => {
    setIsMainMenuOpen(!isMainMenuOpen);
    setBoardsMenuOpen(false)
    setStatusMenuOpen(false);
    setTagsMenuOpen(false);
    setCreatedMenuOpen(false)
    setAssignMenuOpen(false);
    setSentimentMenuOpen(false)
    setUpdatedMenuOpen(false)
  };


  const filteredBoardsItems = boardsItems.filter(item =>
    item.label.toLowerCase().includes(boardsSearchQuery.toLowerCase())
  );

  const filteredStatusItems = statusItems.filter(item =>
    item.label.toLowerCase().includes(statusSearchQuery.toLowerCase())
  );

  const filteredAssignItems = assignItems.filter(item =>
    item.label.toLowerCase().includes(assignSearchQuery.toLowerCase())
  );

  const filteredPriorityItems = priorityItems.filter(item =>
    item.label.toLowerCase().includes(prioritySearchQuery.toLowerCase())
  );

  const filteredSentimentItems = sentimentItems.filter(item =>
    item.label.toLowerCase().includes(sentimentSearchQuery.toLowerCase())
  );

  const filteredTagsItems = tagsItems.filter((item) => {
    return item.label.toLowerCase().includes(tagsSearchQuery.toLowerCase());
  });


  const filteredMainItems = mainItems.filter(item =>
    item.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const removeFilter = (category, id) => {
    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      [category]: (prevFilters[category] || []).filter((filter) => filter.id !== id)
    }));
  };


  useEffect(() => {
    if (selectedValuesRef.current) {
      setSectionHeight(selectedValuesRef.current.offsetHeight);
    } else {
      setSectionHeight(0);
    }
  }, [activeFilters]);


  const handleClearAll = () => {
    setActiveFilters([]);
  }

  const FilterButton: React.FC<FilterButtonProps> = ({ label, onClick, activeFilters }) => {
    const totalActiveFilters = activeFilters
      ? Object.values(activeFilters).reduce((acc, filters) => acc + filters.length, 0)
      : 0;

    const isActive = totalActiveFilters > 0;

    const buttonClassName = `
      ml-0 lg:ml-[0.5rem] 
      flex items-center gap-[0.4rem] md:gap-[0.5rem] lg:gap-[0.5rem] 
      rounded-md py-[6px] px-[0.5rem] 
      text-[12px] md:text-[0.8125rem] lg:text-[0.8125rem] 
      font-semibold
      transition-colors duration-200
      ${isActive
        ? 'bg-blue-500 text-white hover:bg-blue-600'
        : 'bg-white text-dark-black-100 hover:bg-light-gray-100'}
    `;


    return (
      <button
        type="button"
        className={`ml-0 lg:ml-[0.5rem] flex items-center gap-[0.4rem] md:gap-[0.5rem] lg:gap-[0.5rem] rounded-md py-[6px] px-[0.5rem] hover:bg-light-gray-100 text-[12px] md:text-[0.8125rem] lg:text-[0.8125rem] ${hasActiveFilters ? 'text-red' : 'text-dark-black-100'
          }`}
        onClick={onClick}
      >
        <svg
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          aria-hidden="true"
          focusable="false"
          height="1em"
          width="1em"
          className={hasActiveFilters ? '#text-[#111827] ' : 'text-[#0000007a]'}
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
        </svg>
        <span
          className={hasActiveFilters ? '#text-[#111827] leading-[1.2] text-[12px] md:text-[0.8125rem] lg:text-[0.8125rem] font-semibold' : 'text-[#0000007a]  leading-[1.2] text-[12px] md:text-[0.8125rem] lg:text-[0.8125rem] font-semibold'}
        >
          {label}
        </span>
      </button>
    );
  };



  const handleTagChange = (newValue) => {
    setSelectedTags(newValue);
  };



  const boardsMenuRef = useRef(null)
  const statusMenuRef = useRef(null);
  const tagsMenuRef = useRef(null);
  const assignMenuRef = useRef(null);
  const priorityMenuRef = useRef(null);
  const sentimentMenuRef = useRef(null);
  const createdMenuRef = useRef(null);
  const updatedMenuRef = useRef(null)
  const mainMenuRef = useRef(null);
  const handleClickOutside = (event) => {
    if (boardsMenuRef.current && !boardsMenuRef.current.contains(event.target)) {
      setBoardsMenuOpen(false);
    }
    if (statusMenuRef.current && !statusMenuRef.current.contains(event.target)) {
      setStatusMenuOpen(false);
    }
    if (assignMenuRef.current && !assignMenuRef.current.contains(event.target)) {
      setAssignMenuOpen(false);
    }
    if (priorityMenuRef.current && !priorityMenuRef.current.contains(event.target)) {
      setPriorityMenuOpen(false);
    }
    if (sentimentMenuRef.current && !sentimentMenuRef.current.contains(event.target)) {
      setSentimentMenuOpen(false);
    }
    if (tagsMenuRef.current && !tagsMenuRef.current.contains(event.target)) {
      setTagsMenuOpen(false);
    }
    if (createdMenuRef.current && !createdMenuRef.current.contains(event.target)) {
      setCreatedMenuOpen(false);
    }
    if (updatedMenuRef.current && !updatedMenuRef.current.contains(event.target)) {
      setUpdatedMenuOpen(false);
    }
    if (mainMenuRef.current && !mainMenuRef.current.contains(event.target)) {
      setIsMainMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // created at
  const [value, setValue] = useState({
    startDate: null,
    endDate: null
  });
  const [updateValue, setUpdateValue] = useState({
    startDate: null,
    endDate: null
  });


  const handleValueChange = (newValue) => {

    if (newValue.startDate && newValue.endDate) {
      const formattedStartDate = format(new Date(newValue.startDate), 'd MMMM yyyy');
      const formattedEndDate = format(new Date(newValue.endDate), 'd MMMM yyyy');

      const dateFilter = {
        id: 'date-range',
        label: 'Date Range',
        value: `${formattedStartDate} - ${formattedEndDate}`,
        icon: null, // Add an icon if needed
      };

      setActiveFilters((prevFilters) => ({
        ...prevFilters,
        ['Created at']: [dateFilter], 
      }));
    }
    clearPaginationFromState();
    setValue(newValue);
    setCreatedMenuOpen(false);

  }

  const handleUpdatedValueChange = (newValue) => {

    if (newValue.startDate && newValue.endDate) {
      const formattedStartDate = format(new Date(newValue.startDate), 'd MMMM yyyy');
      const formattedEndDate = format(new Date(newValue.endDate), 'd MMMM yyyy');

      const dateFilter = {
        id: 'date-range',
        label: 'Date Range',
        value: `${formattedStartDate} - ${formattedEndDate}`,
        icon: null,
      };

      setActiveFilters((prevFilters) => ({
        ...prevFilters,
        ['Updated at']: [dateFilter],
      }));
    }
    clearPaginationFromState();
    setUpdateValue(newValue);
    setUpdatedMenuOpen(false);

  }



  const handleValueClick = (category, id) => {
    setOpenCategoryMenu(category);
    setEditingFilter({ category, id });
    setBoardsMenuOpen(category === 'Boards');
    setStatusMenuOpen(category === 'status');
    setAssignMenuOpen(category === 'assign');
    setPriorityMenuOpen(category === 'priority');
    setSentimentMenuOpen(category === 'sentiment');
    setTagsMenuOpen(category === 'label');
    setCreatedMenuOpen(category === 'Created at');
    setUpdatedMenuOpen(category === 'Updated at');
  };

  const renderIcon = (icon) => {
    if (icon && icon.type && icon.props) {
      
      return React.createElement(icon.type, icon.props);
    }
    
    return null;
  };


  // New function to handle updating a specific filter
  const handleFilterUpdate = (category, oldId, newId, newValue, newIcon) => {
    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      [category]: prevFilters[category].map(filter =>
        filter.id === oldId ? { ...filter, id: newId, value: newValue, icon: { type: newIcon.type, props: newIcon.props } } : filter

      )
    }));
    setOpenCategoryMenu(null);
    setEditingFilter(null);
    setBoardsMenuOpen(false);
    setStatusMenuOpen(false);

    setAssignMenuOpen(false);
    setPriorityMenuOpen(false);
    setSentimentMenuOpen(false);
    setTagsMenuOpen(false);
    setCreatedMenuOpen(false);
    setUpdatedMenuOpen(false);

  };

  // Set activeFilters to local storage

  useEffect(() => {
    localStorage.setItem('activeFilters', JSON.stringify(activeFilters));
  }, [activeFilters]);

  const clearPaginationFromState = () => {
    window.dispatchEvent(new CustomEvent('paginationresetsetate'));
  };


  return (
    <>
      <div className={`fixed top-[56px] feedback-table-section`}>
        <div className='flex h-full lg:h-[45px] shrink-0 justify-between items-center gap-1 md:gap-2 lg:gap-4 bg-white shadow-custom-1 py-2 px-2 md:py-4 lg:py-4 md:px-4 lg:px-4 flex-wrap lg:flex-nowrap'>
          <div className='flex items-center rounded-md'>
            <button
              className={`px-[6px] md:px-2 lg:px-2 text-[12px] md:text-[0.8125rem] lg:text-[0.8125rem] leading-[1.2] font-semibold py-[5px]
               border border-r-0 border-solid border-[rgba(0, 0, 0, 0.08)] rounded-tl-md rounded-bl-md ${activeState === 'all' ? 'bg-[#6b7280] text-white  border-[#6b7280]' : 'bg-[#f9fafa] text-[#0000007a] hover:text-black-100'}`}
              onClick={() => {
                setActiveState('all');
                setAssignedItemId(null); 
              }}
            >
              All Integration
            </button>
            <button
              className={`px-[6px] md:px-2 lg:px-2 text-[12px] md:text-[0.8125rem] lg:text-[0.8125rem] leading-[1.2] font-semibold py-[5px] border
               border-solid border-[rgba(0, 0, 0, 0.08)] rounded-tr-md rounded-br-md ${activeState === 'assignToMe' ? 'bg-[#6b7280] text-white border-[#6b7280]' : 'bg-[#f9fafa] text-[#0000007a] hover:text-black-100'}`}
              onClick={handleAssignToMe}
            >
              Assigned to me
            </button>
            <div className="relative inline-block text-left">
              <FilterButton label="Filter" onClick={handleMainMenuClick} />
              {isMainMenuOpen && (
                <div ref={mainMenuRef} className="absolute right-0 z-10 mt-2 left-0 mq575:w-[160px] mq3000:w-[234px] origin-top-right rounded-md bg-white filter-dropdown border border-solid border-[#00000014]"
                  style={{ boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)' }}>
                  <div className="py-1">
                    <input
                      type="search"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="w-full outline-none border-t-0 border-l-0 border-r-0 border-b border-solid border-[#00000029] 
                      rounded-none text-sm h-[36px] pl-[10px] pr-[30px focus:outline-none focus:shadow-none focus:ring-0 focus:border-[#00000029] font-normal text-[#9f9f9fff]"
                      placeholder="Filter..."
                    />
                    <span className="right-0 w-[2.25rem] h-[2.25rem] text-sm flex items-center justify-center absolute text-[#000000eb]  opacity-60 top-[2px]" style={{ zIndex: 2 }}>F</span>

                    {filteredMainItems.map((item) => (
                      <button
                        key={item.id}
                        className="text-dark-black-100 flex items-center gap-[0.5rem] px-[0.75rem] py-[0.375rem] h-[32px] text-[13px] font-normal cursor-pointer w-full hover:bg-[#f1f1f2] focus:bg-[#f1f1f2] active:bg-[#e7e7e8]"
                        onClick={() => {
                          item.setMenuOpen(true);
                          setIsMainMenuOpen(false);
                        }}
                      >
                        {item.icon}
                        {item.label}
                      </button>
                    ))}
                  </div>
                </div>
              )}
              {boardsMenuOpen && (
                <div ref={boardsMenuRef} className="absolute right-0 z-10 mt-2 left-0 mq575:w-[160px] mq3000:w-[234px] origin-top-right rounded-md bg-white filter-dropdown border border-solid border-[#00000014]"
                  style={{ boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)' }}>
                  <div className="py-1">
                    <input
                      type="search"
                      value={boardsSearchQuery}
                      onChange={(e) => setBoardsSearchQuery(e.target.value)}
                      className="w-full outline-none border-t-0 border-l-0 border-r-0 border-b border-solid border-[#00000029] 
                      rounded-none text-sm h-[36px] pl-[10px] pr-[30px focus:outline-none focus:shadow-none focus:ring-0 focus:border-[#00000029] font-normal text-dark-black-100"
                      placeholder="Status"
                    />
                    <span className="right-0 w-[2.25rem] h-[2.25rem] text-sm flex items-center justify-center absolute text-[#000000eb]  opacity-60 top-[2px]" style={{ zIndex: 2 }}>F</span>
                    <div className=" overflow-auto max-h-[200px]">
                      {filteredBoardsItems.map((item, index) => (
                        <button
                          key={item.id}
                          ref={(el) =>
                            (itemsRef1.current[index] = el)
                          }
                          className={`text-dark-black-100 flex items-center gap-[0.5rem] px-[0.75rem] py-[0.375rem] h-[32px] text-[13px] font-normal cursor-pointer w-full hover:bg-[#f1f1f2] focus:bg-[#f1f1f2] ${item.className}`}
                          onClick={() => {
                            if (editingFilter && editingFilter.category === 'Boards') {
                              handleFilterUpdate('Boards', editingFilter.id, item.id, item.label, item.icon);
                              clearPaginationFromState();
                            } else {
                              item.onClick();
                              clearPaginationFromState();
                            }
                          }}
                        >
                          {item.icon}
                          <span className=" whitespace-nowrap w-[150px] overflow-hidden text-ellipsis text-start">    {item.label}</span>

                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {statusMenuOpen && (
                <div ref={statusMenuRef} className="absolute right-0 z-10 mt-2 left-0 mq575:w-[160px] mq3000:w-[234px] origin-top-right rounded-md bg-white filter-dropdown border border-solid border-[#00000014]"
                  style={{ boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)' }}>
                  <div className="py-1">
                    <input
                      type="search"
                      value={statusSearchQuery}
                      onChange={(e) => setStatusSearchQuery(e.target.value)}
                      className="w-full outline-none border-t-0 border-l-0 border-r-0 border-b border-solid border-[#00000029] 
                      rounded-none text-sm h-[36px] pl-[10px] pr-[30px focus:outline-none focus:shadow-none focus:ring-0 focus:border-[#00000029] font-normal text-dark-black-100"
                      placeholder="Status"
                    />
                    <span className="right-0 w-[2.25rem] h-[2.25rem] text-sm flex items-center justify-center absolute text-[#000000eb]  opacity-60 top-[2px]" style={{ zIndex: 2 }}>F</span>
                    <div className=" overflow-auto max-h-[200px]">
                      {filteredStatusItems.map((item, index) => (
                        <button
                          key={item.id}
                          ref={(el) =>
                            (itemsRef1.current[index] = el)
                          }
                          className={`text-dark-black-100 flex items-center gap-[0.5rem] px-[0.75rem] py-[0.375rem] h-[32px] text-[13px] font-normal cursor-pointer w-full hover:bg-[#f1f1f2] focus:bg-[#f1f1f2] ${item.className}`}

                          onClick={() => {
                            if (editingFilter && editingFilter.category === 'status') {
                              handleFilterUpdate('status', editingFilter.id, item.id, item.label, item.icon);
                              clearPaginationFromState();
                            } else {
                              item.onClick();
                              clearPaginationFromState();
                            }
                          }}
                        >
                          {item.icon}
                          <span className=" whitespace-nowrap w-[150px] overflow-hidden text-ellipsis text-start">    {item.label}</span>

                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {assignMenuOpen && (
                <div ref={assignMenuRef} className="absolute right-0 z-10 mt-2 left-0 mq575:w-[160px] mq3000:w-[234px] origin-top-right 
                rounded-md bg-white filter-dropdown border border-solid border-[#00000014]"
                  style={{ boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)' }}>
                  <div className="py-1">
                    <input
                      type="search"
                      value={assignSearchQuery}
                      onChange={(e) => setAssignSearchQuery(e.target.value)}
                      className="w-full outline-none border-t-0 border-l-0 border-r-0 border-b border-solid border-[#00000029] 
                      rounded-none text-sm h-[36px] pl-[10px] pr-[30px focus:outline-none focus:shadow-none focus:ring-0 focus:border-[#00000029] font-normal text-dark-black-100"
                      placeholder="Status"
                    />
                    <span className="right-0 w-[2.25rem] h-[2.25rem] text-sm flex items-center justify-center absolute text-[#000000eb]  opacity-60 top-[2px]" style={{ zIndex: 2 }}>F</span>
                    <div className=" overflow-auto max-h-[200px]">
                      {filteredAssignItems.map((item, index) => (

                        <button
                          key={item.id}
                          ref={(el) =>
                            (itemsRef1.current[index] = el)
                          }
                          className={`text-dark-black-100 flex items-center gap-[0.5rem] px-[0.75rem] py-[0.375rem] h-[32px] text-[13px] font-normal cursor-pointer w-full hover:bg-[#f1f1f2] focus:bg-[#f1f1f2] ${item.className}`}

                          onClick={() => {
                            if (editingFilter && editingFilter.category === 'assign') {
                              handleFilterUpdate('assign', editingFilter.id, item.id, item.label, item.icon);
                              clearPaginationFromState();
                            } else {
                              item.onClick();
                              clearPaginationFromState();
                            }
                          }}
                        >
                          {item.icon}
                          <span className=" whitespace-nowrap w-[150px] overflow-hidden text-ellipsis text-start">
                            {item.label}</span>

                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {priorityMenuOpen && (
                <div ref={priorityMenuRef} className="absolute right-0 z-10 mt-2 left-0 mq575:w-[160px] mq3000:w-[234px] origin-top-right 
                rounded-md bg-white filter-dropdown border border-solid border-[#00000014]"
                  style={{ boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)' }}>
                  <div className="py-1">
                    <input
                      type="search"
                      value={prioritySearchQuery}
                      onChange={(e) => setPrioritySearchQuery(e.target.value)}
                      className="w-full outline-none border-t-0 border-l-0 border-r-0 border-b border-solid border-[#00000029] 
                      rounded-none text-sm h-[36px] pl-[10px] pr-[30px focus:outline-none focus:shadow-none focus:ring-0 focus:border-[#00000029] font-normal text-dark-black-100"
                      placeholder="Status"
                    />
                    <span className="right-0 w-[2.25rem] h-[2.25rem] text-sm flex items-center justify-center absolute text-[#000000eb]  opacity-60 top-[2px]" style={{ zIndex: 2 }}>F</span>
                    <div className=" overflow-auto max-h-[200px]">
                      {filteredPriorityItems.map((item, index) => (

                        <button
                          key={item.id}
                          ref={(el) =>
                            (itemsRef1.current[index] = el)
                          }
                          className={`text-dark-black-100 flex items-center gap-[0.5rem] px-[0.75rem] py-[0.375rem] h-[32px] text-[13px] font-normal cursor-pointer w-full hover:bg-[#f1f1f2] focus:bg-[#f1f1f2] ${item.className}`}

                          onClick={() => {
                            if (editingFilter && editingFilter.category === 'priority') {
                              handleFilterUpdate('priority', editingFilter.id, item.id, item.label, item.icon);
                              clearPaginationFromState();
                            } else {
                              item.onClick();
                              clearPaginationFromState();
                            }
                          }}
                        >
                          {item.icon}
                          <span className=" whitespace-nowrap w-[150px] overflow-hidden text-ellipsis text-start">
                            {item.label}</span>

                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {sentimentMenuOpen && (
                <div ref={sentimentMenuRef} className="absolute right-0 z-10 mt-2 left-0 mq575:w-[160px] mq3000:w-[234px] origin-top-right rounded-md bg-white filter-dropdown border border-solid border-[#00000014]"
                  style={{ boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)' }}>
                  <div className="py-1">
                    <input
                      type="search"
                      value={sentimentSearchQuery}
                      onChange={(e) => setSentimentSearchQuery(e.target.value)}
                      className="w-full outline-none border-t-0 border-l-0 border-r-0 border-b border-solid border-[#00000029] 
                      rounded-none text-sm h-[36px] pl-[10px] pr-[30px focus:outline-none focus:shadow-none focus:ring-0 focus:border-[#00000029] font-normal text-dark-black-100"
                      placeholder="Status"
                    />
                    <span className="right-0 w-[2.25rem] h-[2.25rem] text-sm flex items-center justify-center absolute text-[#000000eb]  opacity-60 top-[2px]" style={{ zIndex: 2 }}>F</span>
                    <div className=" overflow-auto max-h-[200px]">
                      {filteredSentimentItems.map((item, index) => (
                        <button
                          key={item.id}
                          ref={(el) =>
                            (itemsRef1.current[index] = el)
                          }
                          className={`text-dark-black-100 flex items-center gap-[0.5rem] px-[0.75rem] py-[0.375rem] h-[32px] text-[13px] font-normal cursor-pointer w-full hover:bg-[#f1f1f2] focus:bg-[#f1f1f2] ${item.className}`}

                          onClick={() => {
                            if (editingFilter && editingFilter.category === 'sentiment') {
                              handleFilterUpdate('sentiment', editingFilter.id, item.id, item.label, item.icon);
                              clearPaginationFromState();
                            } else {
                              item.onClick();
                              clearPaginationFromState();
                            }
                          }}
                        >
                          {item.label === 'positive' ? (
                            <span
                              className="w-2 h-2 rounded-full block bg-[#15803d]"
                            >
                            </span>
                          ) : item.label === 'negative' ? (
                            <span
                              className="w-2 h-2 rounded-full block bg-[#b91c1c] "
                            >
                            </span>
                          ) : item.label === 'spam' ? (
                            <span
                              className="w-2 h-2 rounded-full block bg-[#4b5563]"
                            >
                            </span>
                          ) : item.label === 'mixed' ? (
                            <span
                              className="w-2 h-2 rounded-full block bg-[#1d4ed8] "
                            >
                            </span>
                          ) : item.label === 'request' ? (
                            <span
                              className="w-2 h-2 rounded-full block bg-[#1d4ed8] "
                            >
                            </span>
                          ) : null}
                          <span className=" capitalize whitespace-nowrap w-[150px] overflow-hidden text-ellipsis text-start">    {item.label}</span>

                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {tagsMenuOpen && (
                <>
                  <MultiSelectLabelFilter
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    handleBoardChange={handleTagChange}
                    options={localItemTags}
                    setCreatedMenuOpen={setTagsMenuOpen}
                    editingFilter={editingFilter}
                    handleFilterUpdate={handleFilterUpdate}
                  />
                  <div className=" overflow-auto max-h-[200px]">
                    {filteredTagsItems.map((item, index) => (
                      <button
                        key={item.id}
                        ref={(el) =>
                          (itemsRef1.current[index] = el)
                        }
                        className={`text-dark-black-100 flex items-center gap-[0.5rem] px-[0.75rem] py-[0.375rem] h-[32px] text-[13px] font-normal cursor-pointer w-full hover:bg-[#f1f1f2] focus:bg-[#f1f1f2] ${item.className}`}

                        onClick={() => {
                          clearPaginationFromState();
                          item.onClick();
                        }}
                        
                      >
                        {item.icon}
                        <span className=" capitalize whitespace-nowrap w-[150px] overflow-hidden text-ellipsis text-start">    {item.label}</span>

                      </button>
                    ))}
                  </div>
                </>
              )}

              {createdMenuOpen && (
                <div ref={createdMenuRef} className="absolute right-0 z-10 mt-2 left-0 mq575:w-[160px] mq3000:w-[234px] origin-top-right rounded-md bg-white filter-dropdown border
                 border-solid border-[#00000014] date-picker"
                  style={{ boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)' }}>
                  <Datepicker
                    value={value}
                    onChange={handleValueChange}
                    showShortcuts={true}
                  />
                </div>

              )}
              {updatedMenuOpen && (
                <div ref={updatedMenuRef} className="absolute right-0 z-10 mt-2 left-0 mq575:w-[160px] mq3000:w-[234px] origin-top-right rounded-md bg-white filter-dropdown border
                 border-solid border-[#00000014] date-picker"
                  style={{ boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)' }}>

                  <Datepicker
                    value={updateValue}
                    onChange={handleUpdatedValueChange}
                    showShortcuts={true}
                  />
                </div>

              )}
            </div>
          </div>



          {/* table and kanban toggle */}
          {/* <div className='flex items-center rounded-md'>
            <button
              onClick={() => setActiveButton('one')}
              className={`  text-dark-black-100 hover:text-black-100 
             px-2 text-[12px] md:text-[0.8125rem] lg:text-[0.8125rem] leading-[1.2] font-semibold py-[5px] 
             border border-solid border-[rgba(0, 0, 0, 0.08)] rounded-tl-md rounded-bl-md ${activeButton === 'one' ? 'bg-[#6b7280]' : ''}`}

            >
              {activeButton === 'one' ?
                <svg
                  stroke="#fff" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1.1em" width="1.1em" xmlns="http://www.w3.org/2000/svg"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18">
                  </line><line x1="3" y1="6" x2="3.01" y2="6">
                  </line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg>
                :
                <svg
                  stroke="#6b7280" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1.1em" width="1.1em" xmlns="http://www.w3.org/2000/svg"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18">
                  </line><line x1="3" y1="6" x2="3.01" y2="6">
                  </line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg>
              }
            </button>


            <button
              onClick={() => setActiveButton('two')}
              className={`  text-dark-black-100 hover:text-black-100 px-2 text-[12px] md:text-[0.8125rem] lg:text-[0.8125rem]
          leading-[1.2] font-semibold py-[5px] border border-solid border-[rgba(0, 0, 0, 0.08)]
         rounded-tr-md rounded-br-md ${activeButton === 'two' ? 'bg-[#6b7280]' : ''}`}
            >
              {
                activeButton === 'two' ?
                  <svg stroke="#fff" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round"
                    height="1.1em" width="1.1em" xmlns="http://www.w3.org/2000/svg"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7">
                    </rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>
                  :
                  <svg stroke="#6b7280" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round"
                    height="1.1em" width="1.1em" xmlns="http://www.w3.org/2000/svg"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7">
                    </rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>

              }
            </button>

          </div> */}

        </div>
        <div className="flex items-end md:items-center lg:items-center justify-between px-4">
          <div className={`${!hasActiveFilters ? 'py-0' : 'py-2 flex justify-start flex-wrap w-full gap-2'}`}  ref={selectedValuesRef}>
            {Object.entries(activeFilters).map(([category, filters]) =>
              Array.isArray(filters) && filters.length > 0 ? (
                filters.map(({ id, value, icon, color }) => {
                  
                  return (
                    <div key={id} className="flex justify-start flex-wrap shrink-0 items-center bg-white py-0 pr-0 md:py-0 lg:py-0 md:pr-0 lg:pr-0 lg:flex-nowrap filter-btn">
                    <span className="border border-[#e5e7eb] border-solid text-[#6b7280] text-[0.75rem] gap-[0.675rem] leading-4 pr-1 pl-[0.625rem] py-1 bg-white rounded-md items-center inline-flex">
                        <span className="text-[#1d2025] w-2 h-2 rounded-full bg-transparent border-2 border-solid border-[#7d7f83] block"></span>
                        <span className="capitalize">{category}</span>
                        <span className="bg-[#e5e7eb] w-[1px] h-[1rem] mx-1"></span>

                        <span className="mr-[-6px]"> {renderIcon(icon)}</span>

                        {color && (
                          <span
                            className="flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full text-[0.625rem] font-medium text-white"
                            style={{ backgroundColor: color }}
                          ></span>
                        )}
                        <span
                          className="text-[#111827] font-medium capitalize cursor-pointer"
                          onClick={() => handleValueClick(category, id)}
                        >
                          {value}
                        </span>
                        <button
                          onClick={() => removeFilter(category, id)}
                          className="text-[#6b7280] rounded justify-center items-center w-[1.25rem] h-[1.25rem] flex ml-[-.375rem] cursor-pointer"
                        >
                          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" aria-hidden="true">
                            <path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path>
                          </svg>
                        </button>
                      </span>
                    </div>
                  );
                })
              ) : null
            )}
          </div>
          {hasActiveFilters && (
            <button
              className="inline-flex items-center py-1 justify-center rounded-md font-semibold min-w-[5rem] text-[0.8125rem] px-3 hover:bg-light-gray-100"
              onClick={handleClearAll}
            >
              Clear All
            </button>
          )}
        </div>

      </div>
      <div className=''>
        {activeButton === 'one' && <IntegrationTable sectionHeight={sectionHeight} activeFilters={activeFilters} itemAssignedTo={assignedItemId} />}
        {activeButton === 'two' && <DragList sectionHeight={sectionHeight} activeFilters={activeFilters} itemAssignedTo={assignedItemId} />}
      </div>
    </>

  )
}
