
import { _baseRequest } from "../../../services/api";
import { COMMON } from "../../../utils/common";
import { CustomerType } from "./customer.types";

const API_END_POINT = {
  UPDATECUSTOMERDATAINFO: "/v1/item-customer/{0}/{1}/updateCustomers",
};

export const _updateCustomerInfo = async (baseId: number, id: number, customer: CustomerType): Promise<{ data: any }> => {
  return _baseRequest(
    COMMON.stringFormat(API_END_POINT.UPDATECUSTOMERDATAINFO, baseId, id),
    "PUT",
    customer
  ).then((response) => {
    return response;
  }).catch((error) => {
    throw error;
  });
};