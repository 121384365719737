import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Header from './components/Header'
import { UserSelectors } from '../auth/otp/otpSlice'
import { create_base } from './baseAPI'
import { Button, Input } from '@lemonsqueezy/wedges'
import FeedbackLogo from "../../assets/feedback.png"
const Base = () => {
	const { userInfo } = UserSelectors()
	const [name, setName] = useState('')
	const [nameError, setNameError] = useState('')
	const navigate = useNavigate()

	const _create_baseHandler = (e: { preventDefault: () => void }) => {
		e.preventDefault()
		if (name.length === 0) {
			setNameError('Enter Name')
		} else {
			setNameError('')
			create_base(name)
				.then(async (res) => {
					if (res.status === 200) {
						const id = res?.data?.base?.id
						await localStorage.setItem("createBaseId", res?.data?.base?.id)
						navigate(`/setting/detail/${id}`)
					}
				})
				.catch((err) => {
					console.log('err', err)
				})
		}
	}

	return (
		<>
			<Header data={userInfo?.user} />
			<div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-md">
				<img src={FeedbackLogo} alt="" className='max-w-[208px] w-full m-auto'/>

				</div>
				<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
					<div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
						<form className="space-y-6" action="#">
							<div>
								<label htmlFor="name" className="wg-label inline-flex shrink-0 items-center gap-1 text-surface-900 wg-antialiased">
								Name
								</label>
								<div className="mt-2">
									<Input
										value={name}
										onChange={(e) => {
											setName((e.target as HTMLInputElement).value)
											setNameError('')
										}}
										type='text'
										name='name'
										id='name'
										className="focus:outline-indigo-600"
									/>

								</div>
								<p className="mt-2 text-sm text-red-600" id="email-error">
									{nameError}
								</p>

							</div>
							<div className='flex justify-end'>
								<Button
									type="submit"
									onClick={_create_baseHandler}
									className=" bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600"
									size="sm" 	
								>
									Submit
								</Button>
							</div>
						</form>
					</div>
				</div>
			</div>

		</>
	)
}
export default Base
