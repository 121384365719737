import React, { useEffect, useState, useCallback, Fragment } from 'react'
import { UserCircleIcon } from '@heroicons/react/24/solid'
import { _getBaseInvitations, _getBaseSettingMember, add_base, fetchBaseMemberDetails } from './baseAPI'
import AlertPopup from '../../../components/AlertPopup'
import SliderOvers from '../../../components/SliderOvers'
import DropDown from "../../../components/Dropdown"
import Badge from '../../../components/Badge';
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { Button, Input } from '@lemonsqueezy/wedges'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { MemberData } from '../../../reducer/type'
import { clearBaseInfo } from '../../createBase/baseSlice'


const roleList = [{ name: "admin" }, { name: "manager" }, { name: "member" }]
const roleSearchList = [{ name: "all" }, { name: "admin" }, { name: "manager" }, { name: "member" }, { name: "pending" }]


type MemberProps = {
  baseId: number
}


const BaseMemeber: React.FC<MemberProps> = React.memo(({  }) => {
  const [selectRole, setSelectRole] = useState("admin")
  const [selectSearchRole, setSelectSearchRole] = useState("all")

  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")
  const [data, setData] = useState<any>()
  const [showAlert, setShowAlert] = useState(false)
  const [showSlider, setShowSlider] = useState(false)
  const [memberData, setMemberData] = useState<MemberData[]>([]);
  const [memberId, setMemberId] = useState<any>()

  const dispatch = useDispatch<any>();
  const { error } = useSelector((state: RootState) => state.base);
  const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;

  useEffect(() => {
    const baseInfo = localStorage.getItem('baseMembersDetails');
    if (baseInfo) {
      const memberDataArray: MemberData[] = JSON.parse(baseInfo);
      setMemberData(memberDataArray);
      localStorage.setItem('baseMembersDetails', JSON.stringify(memberDataArray));
    }
  }, []);

  useEffect(() => {
    const storedMemberData = localStorage.getItem('baseMembersDetails');
    if (storedMemberData) {
      setMemberData(JSON.parse(storedMemberData));
    }
  }, []);
  

  useEffect(() => {
    return () => {
      dispatch(clearBaseInfo());
    };
  }, [dispatch]);


  if (error) {
    return <div>Error: {error}</div>;
  }


  const add_baseHandler = () => {
    if (email.length === 0) {
      setEmailError("Enter your email address")
    } else {
      add_base(email, selectRole, baseId).then((res) => {
        if (res.status === 200) {
          setData(res)
          setShowAlert(true)
        }
        if (res.status === 400) {
          setData(res)
          setShowAlert(true)
        }
      }).catch((err) => {
        console.log("err", err)
      })
    }
  }
  

  return (
    <div className='h-full'>
      <main className='py-[34px] lg:py-[70px] px-0 lg:px-10 h-full flex'>
        <div className='px-[2px] sm:px-6 lg:px-8 w-full flex justify-center'>
          <div className='w-full max-w-[520px]'>
            <div className='flex flex-col gap-y-[6px]'>
              <h2 className='text-[20px] lg:text-[24px] font-medium leading-8 text-[#1b1b1b] tracking-[-0.01rem]'>Personal Information</h2>
              <p className='text-sm leading-6 text-[#5c5c5e] font-medium'>
              Use a permanent address where you can receive mail.
              </p>
            </div>
            <div className='bg-[#e0e0e0] w-full h-[1px] my-6'></div>
            <div className=' mt-6 lg:mt-10 grid grid-cols-1 gap-x-6 gap-y-4 lg:gap-y-8 sm:grid-cols-6'>
              <div className='sm:col-span-3'>
                <label htmlFor='email' className='wg-label inline-flex shrink-0 items-center gap-1 text-[14px] text-[#2f2f31] wg-antialiased  leading-5'>
                Email
                </label>
                <div className='mt-[6px]'>
                  <Input
                    id='email'
                    name='email'
                    type='email'
                    value={email}
                    onChange={(e) => {
                      setEmailError("");
                      setEmail((e.target as HTMLInputElement).value)
                    }}
                    autoComplete='email'
                    placeholder='Email address'
                    className="focus:outline-indigo-600 w-full py-[5px]"
                  />
                  <p className="mt-[6px] text-sm text-red-600" id="email-error">
                    {emailError}
                  </p>

                </div>
              </div>
              <div className='sm:col-span-3'>
                <label htmlFor='country' className='wg-label inline-flex shrink-0 items-center gap-1 text-[14px] text-[#2f2f31] wg-antialiased  leading-5'>
                Role
                </label>
                <div className='mt-[6px]'>
                  <DropDown onChangeValue={(e) => setSelectRole(e)} data={roleList} previousValue={selectRole} />
                </div>
              </div>
              <div className='sm:col-span-6  flex items-center justify-end'>

                <Button
                  onClick={add_baseHandler}
                  type="submit"
                  className=" bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600 py-[6px]"
                  size="sm"
                >
                  Add to Base
                </Button>
              </div>
              <div className='sm:col-span-3'>
                <label htmlFor='email' className='wg-label inline-flex shrink-0 items-center gap-1 text-[14px] text-[#2f2f31] wg-antialiased  leading-5'>
                Base Members
                </label>
                <div className='mt-[6px]'>
                  <Input
                    id='email'
                    name='email'
                    type='email'
                    autoComplete='email'
                    placeholder='Enter Email'
                    className="focus:outline-indigo-600 w-full py-[5px]"
                  />
                </div>
              </div>

              <div className='sm:col-span-3'>
                <div className='mt-[32px]'>
                  <DropDown onChangeValue={(e) => setSelectSearchRole(e)} data={roleSearchList} previousValue={selectSearchRole} />
                </div>
              </div>
            </div>
            {memberData?.length > 0 && (
              <ul
                role="list"
                className="mt-8 divide-y divide-gray-100  bg-white shadow-sm ring-1 ring-surface-200 rounded-md 
              hover:ring-surface-300 dark:hover:border-surface-200 border-surface-200 dark:border-surface-100 focus:outline-indigo-600"
              >
                {memberData?.length === 0 ? null : (<>
                  {memberData.map((items: any, index) => {
                    
                    return (
                      <li key={index} className="flex justify-between items-center gap-x-6 p-2 flex-wrap lg:flex-nowrap">
                        <div className="flex min-w-0 gap-x-3 items-center">
                        <img
                      src={
                        items?.profile_pic
                          ? `${process.env.REACT_APP_API_BASE_URL}/${items?.profile_pic}`
                          : ''
                      }
                      alt="Selected file preview"
                      className=" h-12 w-12 flex-none rounded-full text-gray-300"
                    />
                          {/* <UserCircleIcon className='h-12 w-12 flex-none rounded-full text-gray-300' aria-hidden='true' /> */}
                          <div className="min-w-0 flex-auto">
                            <p className="text-sm font-semibold leading-4 text-gray-900">
                              <span className="inset-x-0 -top-px bottom-0" />
                              {items.name}
                              <Badge status={items?.status} />
                            </p>
                            <p className=" flex text-xs leading-5 text-gray-500">
                              {items?.email}
                            </p>
                          </div>
                        </div>
                        <div className='flex items-center justify-end'>
                          <div className='sm:col-span-3'>
                            <DropDown onChangeValue={(e) => { }}  data={roleList} previousValue={items?.role} />
                          </div>
                          {/* <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400 cursor-pointer" aria-hidden="true" onClick={() => { setShowSlider(true); setMemberId(items?.id) }} /> */}
                        </div>
                      </li>

                    )
                  })}
                </>)}
              </ul>
            )}
          </div>
        </div>
      </main>
      <AlertPopup data={data} show={showAlert} setShow={() => setShowAlert(false)} />
      <SliderOvers setData={(e: any) => setData(e)} memberData={memberData} memberId={memberId} baseId={baseId} show={showSlider} setShow={() => setShowSlider(false)} setShowAlert={() => setShowAlert(true)} />
    </div>
  )
});
export default BaseMemeber
