
import { Bars3Icon } from '@heroicons/react/24/outline'
import { useState } from 'react';

interface headerProps {
	onClick: any;
	id: string | null

}
const Header: React.FC<headerProps> = ({ onClick }: headerProps) => {
	const [zIndex, setZIndex] = useState<number>(50);

    const handleClick = () => {
        // When onClick is triggered, change the zIndex to -99
        setZIndex(-99);
        // Call the provided onClick function
        onClick();
    };
	return (
		<div className={`sticky top-0 z-${zIndex} flex h-16 shrink-0 items-center gap-x-4 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8 lg:hidden`}>
			<button type='button' className='-m-2.5 p-2.5 text-gray-700 lg:hidden' onClick={handleClick}>
				<span className='sr-only'>Open sidebar</span>
				<Bars3Icon className='h-6 w-6' aria-hidden='true' />
			</button>
		</div>
	)
}
export default Header
