import { Dispatch } from 'redux';
import { _updateCustomerInfo } from './customer.api';
import { CustomerActionTypes, CustomerType, UPDATE_CUSTOMER_INFO_DATA_FAILURE, UPDATE_CUSTOMER_INFO_DATA_REQUEST, UPDATE_CUSTOMER_INFO_DATA_SUCCESS } from './customer.types';

export const updateCustomerInfoData = (baseId: number, id: number, customer: CustomerType) => async (dispatch: Dispatch<CustomerActionTypes>) => {
    dispatch({ type: UPDATE_CUSTOMER_INFO_DATA_REQUEST });
    try {
        const response = await _updateCustomerInfo(baseId, id, customer);
        dispatch({ type: UPDATE_CUSTOMER_INFO_DATA_SUCCESS, payload: response.data });
        return response.data;
    } catch (error: any) {
        dispatch({ type: UPDATE_CUSTOMER_INFO_DATA_FAILURE, payload: error.message });
    }
};