// utils/colorUtils.ts
export const colorMap = {
    numbers: {
      "0": "#ffc107", "1": "#ff9900", "2": "#ffa07a", "3": "#e67e73", "4": "#d35400",
      "5": "#e74c3c", "6": "#f39c12", "7": "#ff8c00", "8": "#ffb6c1", "9": "#ff69b4"
    },
    alphabets: {
      "a": "#ff9900", "b": "#ffc107", "c": "#ffa07a", "d": "#e67e73", "e": "#d35400",
      "f": "#e74c3c", "g": "#f39c12", "h": "#ff8c00", "i": "#ffb6c1", "j": "#ff69b4",
      "k": "#ffc499", "l": "#ffa57d", "m": "#e6b8a3", "n": "#d2b48c", "o": "#ff9900",
      "p": "#ffc107", "q": "#ffa07a", "r": "#e67e73", "s": "#d35400", "t": "#e74c3c",
      "u": "#f39c12", "v": "#ff8c00", "w": "#ffb6c1", "x": "#ff69b4", "y": "#ffc499",
      "z": "#ffa57d"
    }
  };
  
  export const getColorForLabel = (label: string): string => {
    const firstChar = label?.charAt(0).toLowerCase();
    if (firstChar >= '0' && firstChar <= '9') {
      return colorMap.numbers[firstChar as keyof typeof colorMap.numbers] || '#808080';
    } else if (firstChar >= 'a' && firstChar <= 'z') {
      return colorMap.alphabets[firstChar as keyof typeof colorMap.alphabets] || '#808080';
    }
    return '#808080';
  };
  
 export const getSentimentColor = (sentiment: any) => {
    switch (sentiment?.toLowerCase()) {
      case 'positive':
        return '#16a34a33';
      case 'negative':
        return '#dc26261a';
      case 'spam':
        return '#6b72801a';
      case 'request':
        return '#1d4ed81a';
        case 'mixed':
          return '#1d4ed81a';
      default:
        return 'transparent';
    }
  };
  