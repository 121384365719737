//@ts-nocheck
import React, { useEffect, useState } from 'react';
import { _feedback_update_item, } from '../setting/settingPages/baseAPI';
import { motion, AnimatePresence } from 'framer-motion';
import { Listbox, ListboxButton, ListboxOptions, Transition } from '@headlessui/react'
import MultiSelectTags from '../../components/MultiSelectTags';
import { UpdateIntegrationDetails } from './integration-redux/integration.actions';
import { Toaster, toast } from 'sonner';
import { useAppDispatch } from '../../hooks/hooks';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ItemStatus, ViewIntegrationProps } from './type';
import { RootState } from '../../store/store';
import { Switch } from '@headlessui/react'


function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}


const ViewIntegrationDetails: React.FC<ViewIntegrationProps> = () => {
  const [statusOptions, setStatusOptions] = useState<{ id: number, name: string, label: string }[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<{ id: number, name: string, label: string } | null>(null);
  const [tagsOptions, setTagsOptions] = useState<{ id: number, name: string, label: string, value?: string; isNew?: boolean; }[]>([]);
  const [selectedTags, setSelectedTags] = useState<{ id: number; name: string; label: string; value?: string; isNew?: boolean; }[]>([]);
  const [memberOptions, setMemberOptions] = useState<{ id: number, name: string, label: string }[]>([]);
  const [selectedMember, setSelectedMember] = useState<{ id: number, name: string, label: string } | null>(null);
  const [boardOptions, setBoardOptions] = useState<{ id: number, name: string, label: string }[]>([]);
  const [selectedBoard, setSelectedBoard] = useState<{ id: number, name: string, label: string } | null>(null);
  const [collapsed, setCollapsed] = useState(false);
  const [collapsedCustomer, setCollapsedCustomer] = useState(false);
  const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;
  const [enabled, setEnabled] = useState(false)
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { data, loading, error } = useSelector((state: RootState) => state.integration);
  const [customerNameState, setCustomerNameState] = useState(data[0].customer_name === "yes");
  const [customerEmailState, setCustomerEmailState] = useState(data[0].customer_email_id === "yes");
  const [customerPhoneState, setCustomerPhoneState] = useState(data[0].customer_phone_no === "yes");
  const [customerMandatory, setCustomerMandatory] = useState(data[0].customer_info === "yes");
  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const toggleCollapseCustomer = () => {
    setCollapsedCustomer(!collapsedCustomer);
  };

  // item status
  useEffect(() => {
    const storedStatus = localStorage.getItem("integrationStatus");
    if (storedStatus) {
      const parsedStatus = JSON.parse(storedStatus);

      if (parsedStatus && parsedStatus) {
        const statusOptions = parsedStatus.map((status: any) => ({
          id: status.id,
          name: status.name,
          label: status.label
        }));
        setStatusOptions(statusOptions);
      } else {
        console.error("Invalid data format in local storage.");
      }
    }
  }, []);

  useEffect(() => {
    if (data && data.length > 0 && statusOptions.length > 0) {
      const itemStatus = localStorage.getItem('integrationStatus');
      const itemStatusArray = itemStatus !== null ? JSON.parse(itemStatus) : [];

      const statusObject = itemStatusArray.find((status: ItemStatus) => status.name === data[0].integration_status);

      if (statusObject) {
        const initialStatus = statusOptions.find(option => option.name === statusObject.name);

        setSelectedStatus(initialStatus || null);
      }
    }
  }, [data, statusOptions]);


  // item tags
  useEffect(() => {
    const storedTags = localStorage.getItem("itemTagsList");
    if (storedTags) {
      const parsedTags = JSON.parse(storedTags);
      const tagsOptions = parsedTags.data.map((tag: any) => ({
        id: tag.id,
        name: tag.tag_title,
        label: tag.color,
        value: tag.tag_title
      }));
      setTagsOptions(tagsOptions)
    }
  }, []);

  useEffect(() => {
    if (data && data.length > 0) {
      const itemTagIds = data[0]?.integration_label?.split(',') || [];
      setSelectedTags(itemTagIds);
    }
  }, [data, tagsOptions]);
  useEffect(() => {
  }, [selectedTags]);


  // base member 
  useEffect(() => {
    const storedMember = localStorage.getItem("baseMembersDetails");
    if (storedMember) {
      const parsedMember = JSON.parse(storedMember);
      const memberOptions = parsedMember.map((member: any) => ({
        id: member.id,
        name: member.name,
        profile_pic: member.profile_pic
      }));
      setMemberOptions(memberOptions);
    }
  }, []);

  useEffect(() => {
    if (data && data.length > 0 && memberOptions.length > 0) {
      const itemMember = localStorage.getItem('baseMembersDetails');

      const itemMemberArray = itemMember !== null ? JSON.parse(itemMember) : [];
      const memberObject = itemMemberArray.find(
        (member) => member.id === data[0].assign_to
      );

      if (memberObject) {
        const initialMember = memberOptions.find((option) => option.name === memberObject.name);

        if (initialMember) {
          const updatedInitialMember = {
            ...initialMember,
            profile_pic: memberObject.profile_pic,
          };

          console.log(updatedInitialMember, "initialMember");

          setSelectedMember(updatedInitialMember);
        } else {
          setSelectedMember(null);
        }
      }
    }
  }, [data, memberOptions]);


  // board
  useEffect(() => {
    let storedBoard;
    if (localStorage.getItem("boardDataItem")) {
      storedBoard = localStorage.getItem("boardDataItem") || localStorage.getItem("channelData")
    } else {
      storedBoard = localStorage.getItem("boardDataItem") || localStorage.getItem("channelData")
    }
    if (storedBoard) {
      const parsedBoard = JSON.parse(storedBoard);
      const boardOptions = parsedBoard.map((board: { id: any; name: any; color: any; }) => ({
        id: board.id,
        name: board.name,
        label: board.color,
      }));
      setBoardOptions(boardOptions);
    }
  }, [])

  useEffect(() => {
    if (data && data.length > 0 && boardOptions.length > 0) {

      const storedBoard = localStorage.getItem("boardDataItem") || localStorage.getItem("channelData")

      const itemBoardArray = storedBoard !== null ? JSON.parse(storedBoard) : [];
      const boardObject = itemBoardArray.find((board: { id: number | undefined; }) => board.id === data[0].board_id);

      if (boardObject) {
        const initialBoard = boardOptions.find(option => option.name === boardObject.name);

        setSelectedBoard(initialBoard || null);
      }
    }
  }, [data, boardOptions]);


  const handleCustomerNameToggle = async () => {
    const newCustomerNameState = !customerNameState;
    setCustomerNameState(newCustomerNameState);
    await handleBoardChange({ name: newCustomerNameState ? "yes" : "no", label: newCustomerNameState ? "yes" : "no" }, "customer_name");
    // toast.success('Customer Name Updated');
  };

  const handleCustomerEmailToggle = async () => {
    const newCustomerEmailState = !customerEmailState;
    setCustomerEmailState(newCustomerEmailState);
    await handleBoardChange({ name: newCustomerEmailState ? "yes" : "no", label: newCustomerEmailState ? "yes" : "no" }, "customer_email_id");
    // toast.success('Customer Email Updated');
  };

  const handleCustomerPhoneToggle = async () => {
    const newCustomerPhoneState = !customerPhoneState;
    setCustomerPhoneState(newCustomerPhoneState);
    await handleBoardChange({ name: newCustomerPhoneState ? "yes" : "no", label: newCustomerPhoneState ? "yes" : "no" }, "customer_phone_no");
    // toast.success('Customer Phone Updated');
  };

  const handleCustomerMandatory = async () => {
    const newCustomerMandatory = !customerMandatory;
    setCustomerMandatory(newCustomerMandatory);
    await handleBoardChange({ name: newCustomerMandatory ? "yes" : "no", label: newCustomerMandatory ? "yes" : "no" }, "customer_info");
    // toast.success('Customer Phone Updated');
  };



  const handleBoardChange = async (newValue: any, fieldName: any) => {
    if (!data || data.length === 0) return;

    const currentItem = data[0];
    let updatedField: any = {};

    switch (fieldName) {
      case 'status':
        setSelectedStatus(newValue);
        updatedField = { integration_status: newValue.name };
        break;
      case 'tags':
        setSelectedTags(newValue);
        updatedField = {
          integration_label: newValue.map((tag: { value: any; }) => tag.value || tag).join(',')
        };
        break;
      case 'board':
        setSelectedBoard(newValue);
        updatedField = { board_id: newValue.id };
        break;
      case 'member':
        setSelectedMember(newValue);
        updatedField = { assign_to: newValue.id };
        break;
      case "customer_name":
        updatedField = { customer_name: newValue.name };
        break;
      case "customer_email_id":
        updatedField = { customer_email_id: newValue.name };
        break;
      case "customer_phone_no":
        updatedField = { customer_phone_no: newValue.name };
        break;
      case "customer_info":
        updatedField = { customer_info: newValue.name };
        break;
      default:
        console.error('Unknown field:', fieldName);
        return;
    }

    try {
      const response = await dispatch(UpdateIntegrationDetails(baseId, currentItem.id, updatedField));
      toast.success('Item Updated');
    } catch (error) {
      console.error('Error updating item:', error);
      toast.error('Item Update failed');
    }
  };


  if (loading) return (
    <div className=" flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 0px)' }}>
      <div className="simple-spinner-loader">
        <span></span>
      </div>
    </div>
  );
  if (error) return <div>Error: {error}</div>;
  if (data?.length === 0) return <div>No feedback available</div>;

  return (
    <>
      <Toaster richColors />
      <aside className='h-auto flex flex-col justify-stretch min-h-[3rem] 
                    border-b border-l-0 border-r border-solid border-light-gray-200 p-[1.5rem] items-start gap-4'>
        <div className=" flex items-start justify-center w-full">
          <div className="mx-auto w-full">
            <p className="text-center text-wedges-gray text-sm" />
            <div className='flex items-center max-w-full w-fit mb-5 font-[500] leading-[1.2] bg-light-gray-100 rounded-full text-[0.75rem] px-[0.5rem] py-[5px] gap-2'>
              <span className='text-[#52555a] capitalize'>
                {data[0]?.integration_type}
              </span>

            </div>
            <div className="mb-2 mx-[-6px]">
              <a
                href="#"
                onClick={toggleCollapse}
                className="h-8 items-center rounded-lg bg-transparent hover:bg-light-gray-100 text-dark-black-200 font-medium text-[0.8125rem] 
                                focus:outline-none px-2 justify-start inline-flex"
              >

                Details

                <div className={`${collapsed ? 'rotate-180' : 'rotate-0'} h-6 w-6 flex-shrink-0 transform transition-transform duration-300`}>
                  <svg
                    width={24}
                    height={24}
                    fill="none"
                    className="h-6 w-6 fill-transparent stroke-current text-wedges-gray-400"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M15.25 10.75 12 14.25l-3.25-3.5"
                    />
                  </svg>
                </div>
              </a>

              <AnimatePresence initial={false}>
                {!collapsed && (
                  <motion.div
                    initial={{ height: 0, overflow: 'hidden', backgroundColor: 'white' }}
                    animate={{ height: 'auto', overflow: 'inherit', backgroundColor: 'white' }}
                    exit={{ height: 0, overflow: 'hidden', backgroundColor: 'white' }}
                    transition={{ height: { duration: 0.5 }, overflow: 'hidden', backgroundColor: 'red' }}
                  // className="overflow-hidden"
                  >
                    <div className="pr-4 pl-[0px]">
                      <div className='w-full'>
                        <div className="w-full flex items-center justify-between lg:justify-start py-1">
                          <div className="mr-2 py-1 px-2 font-normal text-dark-black-200 text-[13px] w-[30%]">
                            Boards
                          </div>


                          <Listbox value={selectedBoard} onChange={(newValue) => handleBoardChange(newValue, 'board')}>
                            {({ open }) => (
                              <div className="relative w-[70%]">
                                <ListboxButton className="relative justify-center flex items-center capitalize cursor-pointer rounded-md bg-white py-1 px-2 text-left text-[12px] text-[#52555a] font-medium bg-transparent border-solid hover:!border-light-gray-200 border border-white">
                                  <div className="flex items-center gap-1">
                                    {selectedBoard ? (
                                      <span
                                        className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-white capitalize bg-[${selectedBoard?.label}]`}
                                      />
                                    ) : (
                                      <span className="flex h-[8px] shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-white capitalize w-0 mr-[-4px]" />
                                    )}
                                    <span className="block truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full  overflow-hidden text-ellipsis text-start font-medium">
                                      {selectedBoard ? selectedBoard?.name : 'Add board'}
                                    </span>
                                  </div>
                                </ListboxButton>
                                <Transition show={open} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                                  <ListboxOptions className="absolute z-10 w-[160px] left-[-50px] mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {boardOptions.map((board) => (
                                      <Listbox.Option
                                        key={board.id}
                                        className={({ active }) =>
                                          classNames(
                                            active ? 'bg-light-gray-100 text-dark-black-100 text-[13px]' : '',
                                            !active ? 'text-dark-black-100 text-[13px]' : '',
                                            'relative cursor-default select-none py-1.5 pl-3 pr-9 text-[13px]'
                                          )
                                        }
                                        value={board}
                                      >
                                        {({ selected }) => (
                                          <div className="flex items-center gap-2">
                                            <span
                                              className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full border-none text-[0.625rem]
                                                             font-medium text-white capitalize bg-[${board?.label}]`}
                                            />
                                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate font-medium text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full  overflow-hidden text-ellipsis text-start')}>
                                              {board?.name}
                                            </span>
                                          </div>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </ListboxOptions>
                                </Transition>
                              </div>
                            )}
                          </Listbox>


                        </div>
                        <div className="w-full flex items-center justify-between lg:justify-start py-1">
                          <div className="mr-2 py-1 px-2 font-normal text-dark-black-200 text-[13px] w-[30%]">
                            Status
                          </div>



                          <Listbox value={selectedStatus} onChange={(newValue) => handleBoardChange(newValue, 'status')}>
                            {({ open }) => (
                              <div className="relative  w-[70%]">
                                <Listbox.Button className="relative justify-center capitalize flex items-center cursor-pointer rounded-md bg-white py-1 px-2 text-left 
                                          text-[12px] text-[#52555a] font-medium bg-transparent 
                                          border-solid hover:!border-light-gray-200 border border-white">
                                  <div className='flex items-center gap-1'>
                                    {selectedStatus ? (
                                      <span
                                        style={{ backgroundColor: selectedStatus?.label }}
                                        className="flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] 
                          font-medium text-white capitalize"
                                      />
                                    ) : (
                                      <span className="flex h-[8px] shrink-0 items-center justify-center rounded-full border-none text-[0.625rem]
                                       font-medium text-white capitalize w-0 mr-[-4px]" />
                                    )}
                                    <span className="truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full  overflow-hidden text-ellipsis text-start">
                                      {selectedStatus ? selectedStatus?.name : 'Add status'}
                                    </span>
                                  </div>
                                </Listbox.Button>

                                <Transition show={open} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                                  <Listbox.Options className="absolute z-10 w-[120px] left-[-50px] mt-1 max-h-60 
                                     overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {statusOptions.map((status) => (
                                      <Listbox.Option
                                        key={status.id}
                                        className={({ active }) =>
                                          classNames(
                                            active ? 'bg-light-gray-100 text-dark-black-100 text-[13px]' : '',
                                            !active ? 'text-dark-black-100 text-[13px]' : '',
                                            'relative cursor-default select-none py-1.5 pl-3 pr-9 text-[13px]'
                                          )
                                        }
                                        value={status}
                                      >
                                        {({ selected }) => (
                                          <div className="flex items-center gap-2">
                                            <span
                                              style={{ backgroundColor: status.label }}
                                              className="flex h-[8px] w-[8px] shrink-0 items-center justify-center
                                rounded-full border-none text-[0.625rem] font-medium text-white capitalize"
                                            />
                                            <span
                                              className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate font-medium text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full  overflow-hidden text-ellipsis text-start capitalize')}
                                            >
                                              {status?.name}
                                            </span>
                                          </div>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            )}
                          </Listbox>



                        </div>


                        <div className="w-full flex items-center justify-between lg:justify-start py-1">
                          <div className="mr-2 py-1 px-2 font-normal text-dark-black-200 text-[13px] w-[30%]">
                            Assign to
                          </div>



                          <Listbox value={selectedMember} onChange={(newValue) => handleBoardChange(newValue, 'member')}>
                            {({ open }) => (
                              <div className="relative  w-[70%]">
                                <ListboxButton className="relative justify-center flex items-center cursor-pointer rounded-md bg-white py-1 px-2 text-left 
                         text-[12px] text-[#52555a] font-medium bg-transparent border-solid 
                         hover:!border-light-gray-200 border border-white">
                                  <div className="flex items-center gap-2">
                                    {selectedMember ? (
                                      selectedMember?.profile_pic ? (
                                        <img
                                          src={`${process.env.REACT_APP_API_BASE_URL}/${selectedMember.profile_pic}`}
                                          alt="Profile"
                                          className="w-5 h-5 object-cover rounded-full"
                                        />
                                      ) : (
                                        <div className="w-[32px] h-5 rounded-full bg-gray-200 flex items-center justify-center text-[10px]">
                                          <span className="text-[7px] font-medium text-gray-700">
                                            {selectedMember.name.charAt(0).toUpperCase()}
                                          </span>
                                        </div>
                                      )
                                    ) : (
                                      <div className=" h-5 rounded-full  flex items-center justify-center text-[10px]">
                                        <span className="text-[7px] font-medium text-gray-700"></span>
                                      </div>
                                    )}

                                    <span className="block truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full overflow-hidden text-ellipsis text-start">
                                      {selectedMember ? selectedMember.name : 'Add Assign to'}
                                    </span>
                                  </div>
                                </ListboxButton>


                                <Transition show={open} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                                  <ListboxOptions className="absolute z-10 w-[150px] left-[-50px] mt-1 max-h-60 
                                                     overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {memberOptions.map((member) => (
                                      <Listbox.Option
                                        key={member.id}
                                        className={({ active }) =>
                                          classNames(
                                            active ? 'bg-light-gray-100 text-dark-black-100 text-[13px]' : '',
                                            !active ? 'text-dark-black-100 text-[13px]' : '',
                                            'relative cursor-default select-none py-1.5 pl-3 pr-3 text-[13px]'
                                          )
                                        }
                                        value={member}
                                      >
                                        {({ selected }) => (
                                          <div className="flex items-center gap-2">
                                            {member ? (
                                              <img
                                                src={
                                                  member?.profile_pic
                                                    ? `${process.env.REACT_APP_API_BASE_URL}/${member?.profile_pic}`
                                                    : ''
                                                }
                                                alt="Profile"
                                                className="w-5 h-5 object-cover rounded-full"
                                              />
                                            ) : (
                                              <div className='w-[32px] h-5 rounded-full bg-gray-200 flex items-center justify-center text-[10px]'>
                                                <span className="text-[7px] font-medium text-gray-700">
                                                  {member?.name.charAt(0).toUpperCase()}
                                                </span>
                                              </div>
                                            )}
                                            <span
                                              className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate font-medium text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full  overflow-hidden text-ellipsis text-start')}
                                            >
                                              {member.name}
                                            </span>
                                          </div>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </ListboxOptions>
                                </Transition>
                              </div>
                            )}
                          </Listbox>


                        </div>

                        <div>

                          <div className="w-full flex items-start justify-between lg:justify-start py-1 flex-1">
                            <div className="mr-2 py-1 px-2 font-normal text-dark-black-200 w-[30%] text-[13px]">
                              Label
                            </div>

                            <MultiSelectTags selectedTags={selectedTags}
                              setSelectedTags={setSelectedTags}
                              options={tagsOptions}
                              handleBoardChange={handleBoardChange}
                            />


                          </div>

                        </div>

                      </div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>

      </aside>
      <aside className='h-auto flex flex-col justify-stretch min-h-[3rem] 
                    border-b border-l-0 border-r border-solid border-light-gray-200 p-[1.5rem] items-start gap-4'>
        <div className=" flex items-start justify-center w-full">
          <div className="mx-auto w-full">
            <p className="text-center text-wedges-gray text-sm" />


            <div className="mb-2 mx-[-6px]">
              <a
                href="#"
                onClick={toggleCollapseCustomer}
                className="h-8 items-center rounded-lg bg-transparent hover:bg-light-gray-100 text-dark-black-200 font-medium text-[0.8125rem] 
                    focus:outline-none px-2 justify-start inline-flex"
              >

                Customer Details

                <div className={`${collapsedCustomer ? 'rotate-180' : 'rotate-0'} h-6 w-6 flex-shrink-0 transform transition-transform duration-300`}>
                  <svg
                    width={24}
                    height={24}
                    fill="none"
                    className="h-6 w-6 fill-transparent stroke-current text-wedges-gray-400"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M15.25 10.75 12 14.25l-3.25-3.5"
                    />
                  </svg>
                </div>
              </a>

              <AnimatePresence initial={false}>
                {!collapsedCustomer && (
                  <motion.div
                    initial={{ height: 0, overflow: 'hidden', backgroundColor: 'white' }}
                    animate={{ height: 'auto', overflow: 'inherit', backgroundColor: 'white' }}
                    exit={{ height: 0, overflow: 'hidden', backgroundColor: 'white' }}
                    transition={{ height: { duration: 0.5 }, overflow: 'hidden', backgroundColor: 'red' }}
                  >
                    <div className="pr-4 pl-[0px]">
                      <div className='w-full'>
                        <div className="w-full flex items-center justify-between lg:justify-start py-1">
                          <div className="mr-2 py-1 px-2 font-normal text-dark-black-200 text-[13px] w-[50%]">Collect Name</div>

                          <div className='w-[50%] flex items-center justify-center gap-3'>
                            <Switch
                              checked={customerNameState}
                              onChange={handleCustomerNameToggle}
                              className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                            >
                              <span className="sr-only">Use setting</span>
                              <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
                              <span
                                aria-hidden="true"
                                className="pointer-events-none absolute mx-auto h-4 w-9 rounded-full bg-gray-200 transition-colors duration-200 ease-in-out group-data-[checked]:bg-indigo-600"
                              />
                              <span
                                aria-hidden="true"
                                className="pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                              />
                            </Switch>
                            {/* <div className="text-[13px] leading-4 text-[#52555a]">
                              {customerNameState ? "yes" : "no"}
                            </div> */}
                          </div>

                          {/* <div className="text-[13px] leading-4 text-[#52555a] w-[10%]">{data[0].customer_name}</div> */}
                        </div>




                        <div className="w-full flex items-center justify-between lg:justify-start py-1">
                          <div className="mr-2 py-1 px-2 font-normal text-dark-black-200 text-[13px] w-[50%]">Collect Email</div>

                          <div className='w-[50%] flex items-center justify-center gap-3'>
                            <Switch
                              checked={customerEmailState}
                              onChange={handleCustomerEmailToggle}
                              className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                            >
                              <span className="sr-only">Use setting</span>
                              <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
                              <span
                                aria-hidden="true"
                                className="pointer-events-none absolute mx-auto h-4 w-9 rounded-full bg-gray-200 transition-colors duration-200 ease-in-out group-data-[checked]:bg-indigo-600"
                              />
                              <span
                                aria-hidden="true"
                                className="pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                              />
                            </Switch>
                            {/* <div className="text-[13px] leading-4 text-[#52555a]">
                              {customerEmailState ? "yes" : "no"}
                            </div> */}
                          </div>

                          {/* <div className="text-[13px] leading-4 text-[#52555a] w-[10%]">{data[0].customer_email_id}</div> */}
                        </div>


                        <div className="w-full flex items-center justify-between lg:justify-start py-1">
                          <div className="mr-2 py-1 px-2 font-normal text-dark-black-200 text-[13px] w-[50%]">Collect Phone No.</div>

                          <div className='w-[50%] flex items-center justify-center gap-3'>
                            <Switch
                              checked={customerPhoneState}
                              onChange={handleCustomerPhoneToggle}
                              className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                            >
                              <span className="sr-only">Use setting</span>
                              <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
                              <span
                                aria-hidden="true"
                                className="pointer-events-none absolute mx-auto h-4 w-9 rounded-full bg-gray-200 transition-colors duration-200 ease-in-out group-data-[checked]:bg-indigo-600"
                              />
                              <span
                                aria-hidden="true"
                                className="pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                              />
                            </Switch>
                            {/* <div className="text-[13px] leading-4 text-[#52555a]">
                              {customerPhoneState ? "yes" : "no"}
                            </div> */}
                          </div>

                        </div>

                        <div className="w-full flex items-center justify-between lg:justify-start py-1">
                          <div className="mr-2 py-1 px-2 font-normal text-dark-black-200 text-[13px] w-[50%]">Is Mandatory </div>

                          <div className='w-[50%] flex items-center justify-center gap-3'>
                            <Switch
                              checked={customerMandatory}
                              onChange={handleCustomerMandatory}
                              className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                            >
                              <span className="sr-only">Use setting</span>
                              <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
                              <span
                                aria-hidden="true"
                                className="pointer-events-none absolute mx-auto h-4 w-9 rounded-full bg-gray-200 transition-colors duration-200 ease-in-out group-data-[checked]:bg-indigo-600"
                              />
                              <span
                                aria-hidden="true"
                                className="pointer-events-none absolute left-0 inline-block h-5 w-5 
                                transform rounded-full border border-gray-200 bg-white shadow
                                 ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                              />
                            </Switch>
                            {/* <div className="text-[13px] leading-4 text-[#52555a]">
                              {customerPhoneState ? "yes" : "no"}
                            </div> */}
                          </div>

                        </div>

                      </div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>

            </div>


          </div>
        </div>

      </aside>
    </>
  );
};

export default ViewIntegrationDetails;
