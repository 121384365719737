import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { _twoStep, changepassword } from './profileAPI';
import AlertPopup from '../../../components/AlertPopup';
import { Switch } from '@headlessui/react'
import { Button, Input } from '@lemonsqueezy/wedges';


function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

const Security = () => {
    const [checked, setChecked] = useState<any>(localStorage.getItem("userAuth") === "true" ? true : false)
    const [open, setOpen] = useState(false)
    const cancelButtonRef = useRef(null)
    const [oldPass, setOldPass] = useState('')
    const [newPass, setNewPass] = useState('')
    const [error, setError] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [data, setData] = useState<any>()

    const handleChange = async () => {
        setChecked(!checked)
        let selectValue = checked ? false : true
        _twoStep(selectValue).then(async (res) => {
            if (res.status === 200) {
                await setChecked(res?.data?.user?.two_auth)
                await localStorage.setItem("userAuth", res?.data?.user?.two_auth)
                setData(res)
                setShowAlert(true)
            } else {
                setData(res)
                setShowAlert(true)
            }
        }).catch((err) => {
            console.log("err", err)
        })
    };
    const changepasswordHandler = async () => {
        if (oldPass.length === 0) {
            setError("Both field required")
        } else if (newPass.length <= 6) {
            setError("Password must be 6 digit")
        } else {
            changepassword(oldPass, newPass).then(async (res) => {
                if (res.status === 200) {
                    setOldPass("")
                    setNewPass("")
                    setOpen(false)
                    setData(res)
                    setShowAlert(true)
                } else {
                    setError(res?.data?.message)
                }
            }).catch((err) => {
                console.log("err", err)
            })
        }

    };

    // useEffect(() => {
    //     getSessions()
    // }, [])

    // const getSessions = () => {
    //     _getSessions().then((res) => {
    //         console.log("res", res)

    //     }).catch((err) => {
    //         console.log("err", err)
    //     })
    // }


    return (
        <>
            <main className='py-[34px] lg:py-[70px] px-0 lg:px-10 h-full flex'>
                <div className='px-[2px] sm:px-6 lg:px-8 w-full flex justify-center'>
                    <div className='w-full max-w-[520px]'>
                        <div className='flex flex-col gap-y-[6px]'>
                            <h2 className='text-[20px] lg:text-[24px] font-medium leading-8 text-[#1b1b1b] tracking-[-0.01rem]'>Privacy</h2>
                            <p className='text-sm leading-6 text-[#5c5c5e] font-medium'>
                                User Privacy information
                            </p>
                        </div>
                        <div className='mt-6 lg:mt-10 '>
                            <div className='py-4 border-b border-light-gray-200 flex justify-between'>
                                <div className='w-[70%] flex flex-col gap-y-2'>
                                    <p className='text-[0.8125rem] font-medium leading-5 text-[#1b1b1b]'>Two factor security</p>
                                    <p className='text-[0.8125rem] font-normal leading-4 text-[#5c5c5e]'>
                                    Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to log in.
                                    </p>
                                </div>
                                <div className='w-[25%] flex justify-end items-center'>


                                    <Switch
                                        checked={checked}
                                        onChange={handleChange}
                                        className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-0 focus:ring-white focus:ring-offset-2"
                                    >
                                        <span className="sr-only">Use setting</span>
                                        <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
                                        <span
                                            aria-hidden="true"
                                            className={classNames(
                                                checked ? 'bg-indigo-600' : 'bg-gray-200',
                                                'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
                                            )}
                                        />
                                        <span
                                            aria-hidden="true"
                                            className={classNames(
                                                checked ? 'translate-x-5' : 'translate-x-0',
                                                'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out'
                                            )}
                                        />
                                    </Switch>

                                </div>
                            </div>
                            <div className='py-4 border-b border-light-gray-200  flex justify-between flex-wrap lg:flex-nowrap gap-2'>
                                <div className='lg:w-[65%] w-full flex flex-col gap-y-2'>
                                    <p className='text-[0.8125rem] font-medium leading-5 text-[#1b1b1b]'>Change password</p>
                                    <p className='text-[0.8125rem] font-normal leading-4 text-[#5c5c5e]'>We will email you a confirmation when changing your password, so please expect that email after submitting.</p>
                                </div>
                                <div className='lg:w-[35%] w-full flex justify-start lg:justify-end items-center '>
                                    <Button
                                        onClick={() => setOpen(true)}
                                        className=" bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600"
                                        size="sm"
                                    >
                                        Change password
                                    </Button>
                                </div>
                            </div>
                            <div className='py-4 border-b border-light-gray-200  flex justify-between flex-wrap lg:flex-nowrap gap-2'>
                                <div className='lg:w-[65%] w-full flex flex-col gap-y-2'>
                                    <p className='text-[0.8125rem] font-medium leading-5 text-[#1b1b1b]'>Forgot Password</p>
                                    <p className='text-[0.8125rem] font-normal leading-4 text-[#5c5c5e]'>We will email you a confirmation when changing your password, so please expect that email after submitting.</p>
                                </div>
                                <div className='lg:w-[35%] w-full flex justify-start lg:justify-end items-center '>
                                    <Button
                                        type='submit'
                                        className=" bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600"
                                        size="sm">

                                     Forgot Password
                                    </Button>
                                </div>
                            </div>
                            <div className='w-full py-4 border-b border-light-gray-200 flex flex-col gap-y-2'>
                                <p className='text-[0.8125rem] font-medium leading-5 text-[#1b1b1b]'>Device History</p>
                                <p className='text-[0.8125rem] font-normal leading-4 text-[#5c5c5e]'>If you see a device here that you believe wasn’t you, please contact our account fraud department immediately.Active Sessions</p>
                            </div>
                            <div className='flex justify-between py-4'>
                                <div className='w-[50%] flex flex-col gap-y-2'>
                                    <p className='text-[0.8125rem] font-medium leading-5 text-[#1b1b1b]'>Web</p>
                                    <p className='text-[0.8125rem] font-normal leading-4 text-[#5c5c5e]'>182.68.171.67</p>
                                </div>
                                <div className='w-[50%] text-end flex items-center gap-1 justify-end'>
                                    <p className='text-[0.8125rem] font-medium leading-5 text-[#1b1b1b]'>Date</p>
                                    <Button
                                        type='submit'
                                        className=" bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600"
                                        size="sm"
                                    >
                                        Logout
                                    </Button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>


            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <div className="fixed inset-0 z-10 overflow-y-auto ">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div>
                                        <div className='w-full flex justify-between items-center'>
                                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            Change password
                                            </Dialog.Title>

                                        </div>

                                        <div className="mt-3  sm:mt-5">
                                            <div className='mt-2'>
                                                <label htmlFor="password" className="wg-label inline-flex shrink-0 items-center gap-1 text-surface-900 wg-antialiased">
                                                    Old Password
                                                </label>
                                            </div>
                                            <div className="mt-2">
                                                <Input
                                                    name="password"
                                                    type="password"
                                                    autoComplete="current-password"
                                                    required
                                                    value={oldPass}
                                                    onChange={(e) => {
                                                        setOldPass((e.target as HTMLInputElement).value);
                                                        setError("")
                                                    }}
                                                    placeholder='Enter old password'
                                                    className="focus:outline-indigo-600"

                                                />
                                                <Dialog.Title as="h6" className="mt-2 text-sm text-red-700">
                                                    {error}
                                                </Dialog.Title>
                                            </div>

                                            <div className='my-2'>
                                                <label htmlFor="password" className="wg-label inline-flex shrink-0 items-center gap-1 text-surface-900 wg-antialiased">
                                                    New Password
                                                </label>
                                            </div>
                                            <div className="mt-2">
                                                <Input
                                                    name="password"
                                                    type="password"
                                                    autoComplete="current-password"
                                                    required
                                                    value={newPass}
                                                    onChange={(e) => {
                                                        setNewPass((e.target as HTMLInputElement).value);
                                                        setError("")
                                                    }}
                                                    placeholder='Enter new password'
                                                    className="focus:outline-indigo-600"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <Button
                                            type="button"
                                            className=" bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600"
                                            size="sm"
                                            onClick={changepasswordHandler}
                                        >
                                            Change
                                        </Button>
                                        <Button
                                            type="button"
                                            variant="outline"
                                            size="sm"
                                            onClick={() => setOpen(false)}
                                            ref={cancelButtonRef}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>







            <AlertPopup data={data} show={showAlert} setShow={() => setShowAlert(false)} />
        </>
    )
}
export default Security
