import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { baseInfo } from '../../reducer/type';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { _fetchBaseDetails, _fetchBaseMemberDetails, _fetchItemStatusList, _fetchItemTagsList, _fetchUserProfileDetails, _getChannel1 } from '../setting/settingPages/baseAPI';


type PayloadProps = {
    payload: {
        id: number;
        email: string;
        name: string;
        role: string;
        status: string;
    };
    // channels?: any[]; // Add this property
    // members?: string[];
    type: string;
}

interface AuthState {
    baseInfo: baseInfo | null;
    loading: boolean;
    error: string | null;
    channels: string[];
    members: string[];
    channelData: any;
    baseDetails: any;
    baseMemberDetails: any;
    itemTags: any;
    itemStatus: any;
    itemUserProfileDetails: any
}

const initialState: AuthState = {
    baseInfo: null,
    loading: false,
    error: null,
    channels: [],
    members: [],
    channelData: null,
    baseDetails: null,
    baseMemberDetails: null,
    itemTags: null,
    itemStatus: null,
    itemUserProfileDetails: null
};


const baseSlice = createSlice({
    name: 'base',
    initialState,
    reducers: {
        baseInfoDataRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        baseInfoDataSuccess: (state, action: PayloadAction<baseInfo>) => {
            state.loading = false;
            state.baseInfo = action.payload;
            state.error = null;
        },
        baseInfoDataFailure: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
        },
        baseInfoData: (state, action: PayloadAction<baseInfo>) => {
            state.baseInfo = action.payload;
        },
        clearBaseInfo: (state) => {
            state.baseInfo = null;
            state.loading = false;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(_getChannel1.fulfilled, (state, action) => {
                state.channelData = action.payload;
                localStorage.setItem('channelData', JSON.stringify(action.payload));
            })
            .addCase(_fetchBaseDetails.fulfilled, (state, action) => {
                state.baseDetails = action.payload;
                localStorage.setItem('baseDetails', JSON.stringify(action.payload));
            })
            .addCase(_fetchBaseMemberDetails.fulfilled, (state, action) => {
                state.baseMemberDetails = action.payload;
                localStorage.setItem('baseMembersDetails', JSON.stringify(action.payload));
            })
            .addCase(_fetchItemTagsList.fulfilled, (state, action) => {
                state.itemTags = action.payload;
                localStorage.setItem('itemTagsList', JSON.stringify(action.payload));
            })
            .addCase(_fetchItemStatusList.fulfilled, (state, action) => {
                state.itemStatus = action.payload;
                localStorage.setItem('itemStatusList', JSON.stringify(action.payload.data));
            })
            .addCase(_fetchUserProfileDetails.fulfilled, (state, action) => {
                state.itemUserProfileDetails = action.payload;
                localStorage.setItem('userProfileDetails', JSON.stringify(action.payload));
            })
            .addMatcher(
                (action) => action.type.startsWith('base') && action.type.endsWith('/pending'),
                (state) => {
                    state.loading = true;
                    state.error = null;
                }
            )
            .addMatcher(
                (action) => action.type.startsWith('base') && action.type.endsWith('/fulfilled'),
                (state) => {
                    state.loading = false;
                }
            )
            .addMatcher(
                (action) => action.type.startsWith('base') && action.type.endsWith('/rejected'),
                (state, action) => {
                    state.loading = false;
                    state.error = action.error.message;
                }
            );
            
    },
});


export const { baseInfoDataRequest, baseInfoDataSuccess, baseInfoDataFailure, baseInfoData, clearBaseInfo } = baseSlice.actions;


export default baseSlice.reducer;

interface UserSelectorsType {
    baseInfo: baseInfo | null;

}

export const BaseSelectors = (): UserSelectorsType => {
    const baseInfo = useSelector((state: RootState) => state.base.baseInfo);
    if (baseInfo && baseInfo.name) {
        localStorage.setItem('name', baseInfo.name);
    }
    if (baseInfo && baseInfo.role) {
        localStorage.setItem('baseRole', baseInfo.role);
    }
    return {
        baseInfo,
    };
};