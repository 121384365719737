//@ts-nocheck

import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import { _channel_update, _getChannelDelete, _get_ChannelData, fetchChannelData } from './baseAPI'
import AlertPopup from '../../../components/AlertPopup'
import { Button, Input } from '@lemonsqueezy/wedges';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { Channel } from '../../../reducer/type';
import { clearBaseInfo } from '../../createBase/baseSlice';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom';
import { Toaster, toast } from 'sonner';
import BoardPage from '../boardApi/BoardPage';
import { deleteBoard, fetchBoardById, fetchBoardList } from '../boardApi/BoardActions';


type ChannelProps = {
  baseId: number
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const BaseChannel: React.FC<ChannelProps> = React.memo(({  }) => {
  const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;

  const [showAlert, setShowAlert] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>()
  const navigate = useNavigate()
  const [searchVal, setSearchVal] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [editBoardData, setEditBoardData] = useState<{ id: number; baseId: number } | null>(null);
  const prevBaseIdRef = useRef<number | null>(null);
  const [boardsFetched, setBoardsFetched] = useState(false);

  const dispatch = useDispatch<any>();
  const { error, loading: boardLoading, boardList } = useSelector((state: RootState) => {
    
    return {
      error: state.Board.error,
      loading: state.Board.loading,
      boardList: state.Board.projectBoardList 
    };
  });

  

// Fetch board list when baseId changes
useEffect(() => {
  const fetchBoards = async () => {
    const localBoards = localStorage.getItem("channelData");
    if (localBoards) {
      setBoardsFetched(true);
      dispatch({
        type: 'FETCH_BOARD_LIST_SUCCESS',
        payload: JSON.parse(localBoards),
      });
    } else if (!boardsFetched || baseId !== prevBaseIdRef.current) {
      const result = await dispatch(fetchBoardList(baseId));
      localStorage.setItem(`boards_${baseId}`, JSON.stringify(result.payload));
      setBoardsFetched(true);
      prevBaseIdRef.current = baseId;
    }
  };

  fetchBoards();
}, [baseId, dispatch, boardsFetched]);



  // Update local loading state from Redux
  useEffect(() => {
    setLoading(boardLoading);
  }, [boardLoading]);


  // Clear base info on unmount
  useEffect(() => {
    return () => {
      dispatch(clearBaseInfo());
    };
  }, [dispatch]);


  const handleDeleteBoard = useCallback(async (id: number) => {
    try {
      const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
      const baseId = baseIdString ? JSON.parse(baseIdString) : null;
  
      if (!baseId) {
        throw new Error('baseId is undefined or null');
      }
  
      await dispatch(deleteBoard(id, baseId));
      toast.success('Item deleted successfully!');
  
      // Fetch the updated board list
      dispatch(fetchBoardList(baseId));
    } catch (error) {
      toast.error('Error deleting item!');
    }
  }, [dispatch]);
  
  // ... other memoized functions


  // Edit project 
  // const handleEdit = useCallback((id: number, baseId: number) => {
  //   setEditBoardData({ id, baseId });
  //   setIsModalOpen(true);
  // }, []);
  const handleEdit = useCallback((id: number, baseId: number) => {
    navigate(`/${id}/create/board`, { state: { id, baseId } });
  }, [navigate]);

  useEffect(() => {
    if (editBoardData) {
      dispatch(fetchBoardById(editBoardData.id));
    }
  }, [editBoardData, dispatch]);



  if (error) {
    return <div>Error: {error}</div>;
  }
  const openModal = () => setIsModalOpen(true); // Open modal handler


  return (
    <>
      <Toaster richColors />

      <div className='h-full'>
        <main className='py-[34px] lg:py-[70px] px-0 lg:px-10 h-full flex'>
          <div className='px-[2px] sm:px-6 lg:px-8 w-full flex justify-center'>
            <div className='w-full max-w-[640px]'>
              <div className='flex flex-col gap-y-[6px]'>
                <h2 className='text-[20px] lg:text-[24px] font-medium leading-8 text-[#1b1b1b] tracking-[-0.01rem]'>Base Board</h2>
                <p className='text-sm leading-6 text-[#5c5c5e] font-medium'>
                  Base Board Description
                </p>
              </div>
              <div className='mt-6 lg:mt-10'>
                <div className='w-full flex pb-4 justify-end md:justify-between lg:justify-between items-center shrink-0 flex-wrap lg:flex-nowrap gap-2'>
                  <div className="relative items-center w-full">
                    <Input
                      type="text"
                      value={searchVal}
                      onChange={(e) => setSearchVal((e.target as HTMLInputElement).value)}
                      name="search"
                      id="search"
                      placeholder='Search name and email'
                      className="focus:outline-indigo-600 w-full pl-8 py-[3px]"
                    />
                    <div className="absolute inset-y-0 left-0 flex py-1.5 pr-1.5">

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1.2em"
                        height="1.2em"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className=' w-[15px] h-[15px] my-auto mx-2 '
                      >
                        <circle cx={11} cy={11} r={8} />
                        <path d="m21 21-4.3-4.3" />
                      </svg>

                    </div>
                  </div>
                  <Button
                    onClick={openModal}
                    // onClick={() => navigate(`/${baseId}/create/board`)}
                    type='button'
                    className=" bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600"
                    size="sm">
                 Create Channel
                  </Button>


                </div>
                {boardList?.length === 0 ?
                  <div className='w-full mt-5 flex h-[300px] justify-center items-center'><p>No Data Here</p></div>
                  :
                  (<>
                    {boardList?.filter((post: { name: String }) => {
                      if (searchVal === "") {
                        return post;
                      } else if (post.name.toLowerCase().includes(searchVal.toLowerCase())) {
                        return post;
                      }
                    }).map((items: any, index) => {
                      return (
                        <div key={index} className='w-full flex border-b border-light-gray-200'>
                          <ul className=' list-none w-full'>
                            <li className='flex flex-row items-center justify-between text-[0.875rem] px-[0rem] py-4 w-full'>
                              <div className='flex shrink-0 pr-2'>
                                <span
                                  className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-white capitalize"
                                  style={{ backgroundColor: items?.color }}
                                >
                                  {items.name[0]}
                                </span>
                              </div>
                              <div className='flex-1 px-4'>
                                <p className='text-[0.8125rem] font-medium leading-5 text-[#1b1b1b]'>{items.name}</p>
                                <p className='text-[0.8125rem] font-normal leading-4 text-[#5c5c5e]'>{items?.description}</p>
                              </div>
                              <div>
                                <span className='font-medium inline-flex items-center py-1 mx-2
                    capitalize leading-4 text-[#52555a] bg-light-gray-300 rounded-full text-[0.75rem] px-2'>{items?.privacy}</span>

                                {items.team_status === "active" ? null : <span className='inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs mx-2 
                    ring-1 ring-inset ring-gray-500/10 text-red-600 border-red-600 capitalize font-[500]'>{items?.board_status}</span>}
                              </div>
                              <div className='px-2 flex items-center'>

                                <Menu as="div" className="relative inline-block text-left">
                                  <div>
                                    <MenuButton className="rounded-md flex items-center justify-center hover:bg-light-gray-200 focus:bg-light-gray-200 w-[32px] h-[32px]">
                                      <span className="sr-only">Open options</span>
                                      <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle>
                                      </svg>
                                    </MenuButton>
                                  </div>

                                  <Transition
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                      <div className="py-1">
                                        <MenuItem>
                                          {({ focus }) => (
                                            <div
                                              onClick={() => handleEdit(items.id, items.base_id)}
                                              className={classNames(
                                                focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm'
                                              )}
                                            >
                                              Edit
                                            </div>
                                          )}
                                        </MenuItem>
                                        <MenuItem>
                                          {({ focus }) => (
                                            <Link
                                              to="#"
                                              className={classNames(
                                                focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm'
                                              )}
                                              onClick={() => handleDeleteBoard(items.id)}
                                            >
                                              Delete
                                            </Link>
                                          )}
                                        </MenuItem>
                                        <MenuItem>
                                          {({ focus }) => (
                                            <Link
                                              to="#"
                                              className={classNames(
                                                focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm'
                                              )}

                                            >
                                              Suspended
                                            </Link>
                                          )}
                                        </MenuItem>
                                        <MenuItem>
                                          {({ focus }) => (
                                            <Link
                                              to="#"
                                              className={classNames(
                                                focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm'
                                              )}

                                            >
                                              Archived
                                            </Link>
                                          )}
                                        </MenuItem>

                                      </div>
                                    </MenuItems>
                                  </Transition>
                                </Menu>
                              </div>
                            </li>
                          </ul>
                        </div>
                      )
                    })}
                  </>
                  )
                }
              </div>

            </div>
          </div>
        </main>
      </div>
      <BoardPage
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        baseId={baseId}
        // editBoardData={editBoardData} // Pass the board data

      // loading={loading}

      // channelId={{ value: channelData }}
      />
      <AlertPopup data={data} show={showAlert} setShow={() => setShowAlert(false)} />
    </>
  )
});
export default BaseChannel
