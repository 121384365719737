import { _baseRequest } from "../../../services/api";
import { COMMON } from "../../../utils/common";

const API_END_POINT = {
  FETCHDASHBOARDLIST: "/v1/item/dashboard/{0}/counts",
  FETCHINSIGHTDASHBOARD : "/v1/item-insight/{0}/Insights",
  FETCHACTIVITYDASHBOARD : "/v1/item-activity/{0}/activityList",
};

export const _fetchDashboardList = async (baseId: number, fromDate: string, toDate: string): Promise<{ data: any }> => {
  const url = `${COMMON.stringFormat(API_END_POINT.FETCHDASHBOARDLIST, baseId)}?fromDate=${fromDate}&toDate=${toDate}`;
  return _baseRequest(
    url,
    "GET",
  ).then((response) => {
    return response;
  }).catch((error) => {
    throw error;
  });
};

export const _fetchInsightDashboard = async (baseId: number): Promise<{ data: any }> => {
  return _baseRequest(
      COMMON.stringFormat(API_END_POINT.FETCHINSIGHTDASHBOARD, baseId),
      "GET",
  ).then((response) => {
      return response;
  }).catch((error) => {
      throw error;
  });
};

export const _fetchActivityDashboard = async (baseId: number): Promise<{ data: any }> => {
  return _baseRequest(
      COMMON.stringFormat(API_END_POINT.FETCHACTIVITYDASHBOARD, baseId),
      "GET",
  ).then((response) => {
      return response;
  }).catch((error) => {
      throw error;
  });
};