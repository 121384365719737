

// @ts-nocheck
import React, { useState, KeyboardEvent } from "react";
import MultiSelectIcons from "./MultiSelectIcons";
import { getColorForLabel } from "../utils/colorUtils";

interface Tag {
  id?: any;
  name?: string;
  label?: string;
  value: string;
  isNew?: boolean;
  
}

interface ExampleProps {
  selectedTags: Tag[];
  setSelectedTags: React.Dispatch<React.SetStateAction<Tag[]>>;
  handleBoardChange: (newValue: Tag[], fieldName: string) => void;
  data: any;
  tagsOptions: any;
}

const MultiSelectTags: React.FC<ExampleProps> = ({ selectedTags, setSelectedTags, handleBoardChange }) => {

  const [newTagValue, setNewTagValue] = useState<string>("");
  const [isInputVisible, setInputVisible] = useState<boolean>(false);

  const handleRemoveTag = (tagToRemove: Tag) => {
    setSelectedTags(prevTags => {
      const newTags = prevTags.filter(tag => {
        if (tag.value && tagToRemove.value) {
          return tag.value !== tagToRemove.value;
        }
        return tag !== tagToRemove;
      });
      handleBoardChange(newTags, 'tags');
      return newTags;
    });
  };


  const handleCreateOption = (inputValue: string) => {
    const trimmedValue = inputValue.trim();
    if (trimmedValue) {
      const newTags = trimmedValue.split(',').map(tag => ({
        value: tag.replace(/[^a-zA-Z0-9]/g, ""),
        isNew: true
      })).filter(tag => tag.value !== "");

      setSelectedTags(prevTags => {
        const updatedTags = [...prevTags, ...newTags];
        handleBoardChange(updatedTags, 'tags');
        return updatedTags;
      });
      setNewTagValue("");
      setInputVisible(false);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleCreateOption(newTagValue);
    } else if (e.key === 'Escape') {
      setNewTagValue("");
      setInputVisible(false);
    }
  };

  return (
    <div className='w-[70%]'>
      <div className="flex gap-1 items-center w-full flex-wrap pl-[10px]">
        {selectedTags?.map((tag, index) => {
          const borderColor = getColorForLabel(tag.value || tag);
          return (
            <div className="" key={tag.value || index}
            >
              {
                tag && (
                  <div

                    className="flex items-center max-w-full font-[500] bg-light-gray-100 rounded-full leading-[1.2] text-[0.75rem] px-[0.5rem] py-[5px] gap-1 mb-2"
                  >

                    <span
                      className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full border-2 border-solid  text-[0.625rem] font-medium text-white capitalize`}
                      style={{ borderColor: borderColor }}
                    />

                    <span className="text-[#52555a] capitalize">
                      {tag.value || tag}
                    </span>
                    <div onClick={() => handleRemoveTag(tag)}>
                      <MultiSelectIcons.Close />
                    </div>
                  </div>
                )
              }
            </div>
          );
        })}
      </div>

      <div
        className="custom-label placeholder:text-[13px] placeholder:text-[#6c6c6c] font-medium text-[13px] text-[#52555a] 
          cursor-pointer  inline-block rounded-md bg-white px-2 border-solid hover:!border-light-gray-200 border border-white"
        onClick={() => setInputVisible(!isInputVisible)}
      >
        Add Label
      </div>


      {isInputVisible && (
        <div className="flex items-center mt-2 relative">
          <input
            type="text"
            value={newTagValue}
            onChange={(e) => setNewTagValue(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Add label"
            className="border border-solid border-[#ccc] rounded px-2 py-1 mr-2 bg-[#f1f3f5] text-[13px] w-full  focus:ring-0 focus:shadow-none focus:border-[#ccc]"
          />
          <button
            onClick={() => handleCreateOption(newTagValue)}
            className="absolute right-0 bg-transparent text-[#1e1e1e] rounded px-3 py-1 flex items-center"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-1">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

export default MultiSelectTags;