import { useEffect, useState, useCallback, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from './components/Header'
import { UserSelectors } from '../auth/otp/otpSlice'
import { _getBase } from './baseAPI'
import { baseInfoData } from "./baseSlice"
import { useDispatch } from 'react-redux'
import { _base_invite, _base_invite_accept, _base_invite_reject } from '../setting/settingPages/baseAPI'
import AlertPopup from '../../components/AlertPopup'
import { Button } from '@lemonsqueezy/wedges'
const Base = () => {
	const { userInfo } = UserSelectors()
	const navigate = useNavigate()
	const [baseData, setBaseData] = useState([])
	const [invitationsData, setInvitationsData] = useState([])
	const [data, setData] = useState<any>()
	const [showAlert, setShowAlert] = useState(false)

	const dispatch = useDispatch()
	useEffect(() => {
		_getbaseData()
	}, [])

	const _getbaseData = () => {
		_getBase()
			.then((res) => {
				setBaseData(res?.data?.bases)
			})
			.catch((error) => {
				console.log('error', error)
			})
	}
	const baseHandler = async (value: any) => {
		localStorage.removeItem("itemStatusList");
		localStorage.removeItem("priorities");
		localStorage.removeItem("channelData");
		localStorage.removeItem("baseDetails");
		localStorage.removeItem("itemTagsList");
		localStorage.removeItem("baseId");
		localStorage.removeItem("sentiments");
		localStorage.removeItem("baseMembersDetails");
		localStorage.removeItem("itemActivityData");
		localStorage.removeItem("activeFiltersTopics");
		localStorage.removeItem("channelId");
		localStorage.removeItem("cachedFeedbackData");
		localStorage.removeItem("sentimentToItems");
		localStorage.removeItem("activeFiltersInsight");
		localStorage.removeItem("cachedFeedbackData");
		localStorage.removeItem("cachedInsightData");
		localStorage.removeItem("activeFilters");
		localStorage.removeItem("activeFiltersTopics");
		localStorage.removeItem("sentimentTopic");
		localStorage.removeItem("dashboardDataList");
		localStorage.removeItem("memberData");
		localStorage.removeItem("cachedBoardsData");
		localStorage.removeItem("activeFilters");
		localStorage.removeItem("cachedIntegrationData")
		localStorage.removeItem("integrationStatus")
		localStorage.removeItem("surveyResponseId")
		localStorage.removeItem("cachedIntegrationResponseData")
		localStorage.removeItem("activeFiltersCustomer")
		localStorage.removeItem("cachedCustomerInfoData")
		localStorage.removeItem("userProfileDetails")
		localStorage.removeItem("baseDetailsData")
	

		dispatch(baseInfoData(value))
		await localStorage.setItem("baseId", JSON.stringify(value.id))
		navigate(`/${value.id}/dashboard`)
	}

	// base invitation
	useEffect(() => {
		const fetchInvitationsData = async () => {
			try {
				const response = await _base_invite();
				setInvitationsData(response?.data?.bases);
			} catch (error) {
				console.log("Error fetching invitations:", error);
			}
		};

		fetchInvitationsData();
	}, []);



	// accept invitation
	const handleAcceptInvitation = async (baseId: any) => {

		try {
			const response = await _base_invite_accept(baseId);
			if (response.status === 200) {
				setData(response)
				setShowAlert(true)
			}
		} catch (error) {
			console.log("Error accepting invitation:", error);
		}
	};

	useEffect(() => {
		if (data) {
			window.location.reload();
		}
	}, [data]);

	// reject invitation
	const handleRejectInvitation = async (baseId: any) => {

		try {
			const response = await _base_invite_reject(baseId);
			if (response.status === 200) {
				setData(response)
				setShowAlert(true)
			}
		} catch (error) {
			console.log("Error accepting invitation:", error);
		}
	};


	return (
		<>
			<Header data={userInfo?.user} />
			<div className='container m-auto pb-6'>
				<div className='max-w-full lg:max-w-[500px] m-auto  px-5 lg:px-0'>
					{baseData?.length === 0 ? null : (
						<>
							<div className='border-b py-5 w-full '>
								<p className='text-xl text-slate-900 capitalize font-medium'>Get into Base</p>
							</div>
							{baseData.map((item: any, index) => {
								return (
									<div key={index} className='flex justify-between items-center my-5'>
										<div className="flex items-center gap-x-5">
											<span className='bg-[#a3a3a3] flex items-center justify-center h-14 w-14 flex-none rounded-full ring-1 ring-gray-900/10 text-white text-[20px] font-medium'>
												{item?.name?.[0]}
											</span>
											<div className='flex flex-col gap-1'>
												<p className='leading-6 text-[#25252d] text-base font-medium'>{item.name}</p>
												<span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit">
													{item?.base_plan}
												</span>
											</div>

										</div>


										<Button
											onClick={() => baseHandler(item)}
											size="sm" 		
											type="submit"
											className=" bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600">
											Choose
										</Button>

									</div>
								)
							})}
							<div className='relative mt-10'>
								<div className='absolute inset-0 flex items-center' aria-hidden='true'>
									<div className='w-full border-t border-gray-200' />
								</div>
								<div className='relative flex justify-center text-sm font-medium leading-6'>
									<span className='fontfamily bg-white px-6 text-gray-900 fontfamily'>OR</span>
								</div>
							</div>
						</>
					)}




					<div className='flex items-center justify-center m-10'>
						<Button
							onClick={() => navigate('/createbase')}
							type="submit"
							size="sm" 		
							className=" bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600"
						>
							Create New Base
						</Button>
					</div>





					{/* invitation data fetch */}
					{invitationsData?.length > 0 && (
						<div className='relative mt-10'>
							<div className='absolute inset-0 flex items-center' aria-hidden='true'>
								<div className='w-full border-t border-gray-200' />
							</div>
							<div className='relative flex justify-center text-sm font-medium leading-6'>
								<span className='fontfamily bg-white px-6 text-gray-900 fontfamily'>OR</span>
							</div>
						</div>
					)}


					{invitationsData.map((item: any, index) => {
						return (
							<div className='flex justify-between items-center my-5' key={index}>
								<div className="flex items-center gap-x-5">
									<span className='bg-[#a3a3a3] flex items-center justify-center h-14 w-14 flex-none rounded-full ring-1 ring-gray-900/10
									 text-white text-[12px] font-medium capitalize line-clamp-1'>
										{item.role}
									</span>
									<div className='flex flex-col gap-1'>
										<p className='text-base font-semibold leading-6 text-gray-900'>{item.name}</p>
										<span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit">
											{item.base_plan}
										</span>
									</div>
								</div>


								<div className='flex items-center gap-1'>
									<button
										type='button'
										onClick={() => handleAcceptInvitation(item.id)}
										className='rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white capitalize
											 shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>
										Accept
									</button>
									<button
										type='button'
										onClick={() => handleRejectInvitation(item.id)}
										className='rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 capitalize
											focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>
										Reject
									</button>
								</div>

							</div>
						)
					})}

				</div>
			</div>
			<AlertPopup data={data} show={showAlert} setShow={() => setShowAlert(false)} />
		</>
	)
}
export default Base
