import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { channel_member_update, _get_ChannelData } from '../features/setting/settingPages/baseAPI'
import Badge from './Badge';
import DropDown from './Dropdown';
import { SliderOverProps } from './types';


const roleList = [{ name: "admin" }, { name: "editor" }, { name: "reader" }]
const channel = true;

const SliderOvers: React.FC<SliderOverProps> = ({ show, setShow, baseId, memberId, setShowAlert, setData, memberData }) => {
  const [channelData, setChannelData] = useState([])
  const [selectValue, setSelectValue] = useState('')
  const [selectRole, setSelectRole] = useState("admin")


  const selectedMember = memberData?.find((member) => member?.id === memberId);
  const createdAtDate = selectedMember?.created_at ? new Date(selectedMember.created_at) : new Date();
  const formattedDate = createdAtDate.toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });


  useEffect(() => {
    getChannelData(baseId)
  }, [])


  const getChannelData = (id: any) => {
    _get_ChannelData(id).then((res) => {
      if (res?.status === 200) {
        setChannelData(res?.data)
      }
    }).catch((err) => {
      console.log("err", err)
    })
  }


  const channelHandler = (value: any) => {
    if (selectValue.length !== 0) {
      channel_member_update(selectValue, value.id, memberId).then((res) => {
        if (res.status === 200) {
          setShowAlert()
          setData(res)
          setSelectValue("")
        } else {
          setShowAlert()
          setData(res?.data)
          setSelectValue("")
        }
      }).catch((err) => {
        console.log("err", err)
      })
    }
  }


  return (
    <>
      <Transition.Root show={show} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setShow}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex lg:max-w-[40%] max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-full">
                    <div className="flex h-full flex-col overflow-y-scroll p-5 bg-white  shadow-xl">

                      <div className="flex items-start justify-between">
                        <div className='w-96'>
                          <div>
                            <h3 className='text-[20px] lg:text-[24px] font-medium leading-8 text-[#1b1b1b] tracking-[-0.01rem] pb-5'>Details</h3>
                            <div className='flex flex-col gap-3'>
                              <div className='flex items-center'>
                                <span className='wg-label inline-flex shrink-0 items-center gap-1 text-[14px] text-[#2f2f31] wg-antialiased  leading-5 w-[100px] font-medium'>Name : </span>
                                <p className="wg-label inline-flex shrink-0 items-center gap-1 text-[14px]  wg-antialiased  leading-5 text-gray-900">

                                  {selectedMember?.name}
                                  {/* <Badge status={items.status} /> */}
                                </p>
                              </div>
                              <div className='flex items-center'>
                                <span className='wg-label inline-flex shrink-0 items-center gap-1 text-[14px] text-[#2f2f31] wg-antialiased  leading-5 w-[100px] font-medium'>Email : </span>
                                <p className="wg-label inline-flex shrink-0 items-center gap-1 text-[14px] wg-antialiased  leading-5 text-gray-900">
                                  {selectedMember?.email}
                                </p>
                              </div>
                              <div className='flex items-center'>
                                <span className='wg-label inline-flex shrink-0 items-center gap-1 text-[14px] text-[#2f2f31] wg-antialiased  leading-5 w-[100px] font-medium'>Joining Date : </span>
                                <p className="wg-label inline-flex shrink-0 items-center gap-1 text-[14px] wg-antialiased  leading-5 text-gray-900">
                                  {formattedDate}
                                </p>
                              </div>
                              <div className='flex items-center'>
                                <span className='wg-label inline-flex shrink-0 items-center gap-1 text-[14px] text-[#2f2f31] wg-antialiased  leading-5 w-[100px] font-medium'>Base Role : </span>
                                <p className="wg-label inline-flex shrink-0 items-center gap-1 text-[14px] wg-antialiased  leading-5 text-gray-900">
                                  {selectedMember?.role}
                                </p>
                              </div>
                              <div className='flex items-center'>
                                <span className='wg-label inline-flex shrink-0 items-center gap-1 text-[14px] text-[#2f2f31] wg-antialiased  leading-5 w-[100px] font-medium'>Status : </span>
                                <p className="wg-label inline-flex shrink-0 items-center gap-1 text-[14px] wg-antialiased  leading-5 text-gray-900 mx-[-4px]">
                                  <Badge status={selectedMember?.status || ''} />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="ml-3 flex h-7 items-center mt-[48px] mr-6 lg:mr-0 lg:mt-0">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none 
                            focus:ring-2 focus:ring-primary focus:ring-offset-2"
                            onClick={setShow}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                        </div>
                      </div>

                      <div className="relative mt-7 flex-1 border-t">
                        <h3 className='text-[20px] lg:text-[24px] font-medium leading-8 text-[#1b1b1b] tracking-[-0.01rem] py-5'>Channels</h3>
                        {channelData?.length === 0 ? null : (<>
                          {channelData?.map((items: any, index: number) => {
                            return (
                              <div key={index} className='w-full mb-5 flex justify-between divide-gray-100 overflow-hidden bg-white 
                            shadow-sm ring-1 ring-gray-900/5 rounded-xl p-4'>
                                <div className='w-1/2'>
                                  <p className='wg-label inline-flex shrink-0 items-center gap-1 text-[14px] text-[#2f2f31] wg-antialiased  leading-5 font-medium'>
                                    {items.name}
                                    <b className='py-[2px] px-[4px] border 
                                rounded-[5px] text-[12px] text-primary border-primary font-[500] capitalize leading-4'>{items?.privacy}</b></p>
                                  <p className='text-[12px] leading-5 text-[#6b7280]'>{items?.description}</p>
                                </div>
                                <div className='w-1/2 flex items-center justify-end '>
                                  <DropDown channel={channel} onChangeValue={(e: any) => setSelectRole(e)} data={roleList} previousValue={selectRole}  style={{ width: '24%' }}  />
                                </div>
                              </div>
                            )
                          })}
                        </>)}
                      </div>

                    </div>

                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>

          </div>
        </Dialog>
      </Transition.Root>

    </>
  )
}
export default SliderOvers