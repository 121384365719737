import { _deleteInsightAPI, _deleteItemActivity, _deleteItemMulti, _fetchAllActivityByBaseId, _fetchFeedbackAPI,
     _fetchFeedbackActivitybyId, _fetchFeedbackListing, _fetchInsightbyId, _fetchItemInsight, _submitFeedbackAPI, _submitInsightAPI,
      _submitItemActivity, _updateFeedbackAPI, _updateInsightAPI } from './feedbacks.api';
import {
    SUBMIT_FEEDBACK_REQUEST,
    SUBMIT_FEEDBACK_SUCCESS,
    SUBMIT_FEEDBACK_FAILURE,
    FeedbackType,
    FeedbackActionTypes,
    FETCH_FEEDBACK_REQUEST,
    FETCH_FEEDBACK_SUCCESS,
    FETCH_FEEDBACK_FAILURE,
    FETCH_ITEM_ACTIVITY_REQUEST,
    FETCH_ITEM_ACTIVITY_SUCCESS,
    FETCH_ITEM_ACTIVITY_FAILURE,
    DELETE_ITEM_ACTIVITY_REQUEST,
    DELETE_ITEM_ACTIVITY_SUCCESS,
    DELETE_ITEM_ACTIVITY_FAILURE,
    FETCH_ITEM_INSIGHT_FAILURE,
    FETCH_ITEM_INSIGHT_REQUEST,
    FETCH_ITEM_INSIGHT_SUCCESS,
    FETCH_ALL_ACTIVITY_BY_BASE_ID_REQUEST,
    FETCH_ALL_ACTIVITY_BY_BASE_ID_SUCCESS,
    FETCH_ALL_ACTIVITY_BY_BASE_ID_FAILURE,
    SUBMIT_INSIGHT_REQUEST,
    SUBMIT_INSIGHT_SUCCESS,
    SUBMIT_INSIGHT_FAILURE,
    DELETE_INSIGHT_REQUEST,
    DELETE_INSIGHT_SUCCESS,
    DELETE_INSIGHT_FAILURE,
    FETCH_LIST_BY_ID_INSIGHT_REQUEST,
    FETCH_LIST_BY_ID_INSIGHT_SUCCESS,
    FETCH_LIST_BY_ID_INSIGHT_FAILURE,
    UPDATE_INSIGHT_REQUEST,
    UPDATE_INSIGHT_SUCCESS,
    UPDATE_INSIGHT_FAILURE,
    DELETE_FEEDBACK_LIST_REQUEST,
    DELETE_FEEDBACK_LIST_SUCCESS,
    DELETE_FEEDBACK_LIST_FAILURE,
    FETCH_FEEDBACK_LISTING_REQUEST,
    FETCH_FEEDBACK_LISTING_SUCCESS,
} from './feedbacks.types';
import { Dispatch } from 'redux';


export const submitFeedback = (feedback: FeedbackType) => async (dispatch: Dispatch<FeedbackActionTypes>) => {
    dispatch({ type: SUBMIT_FEEDBACK_REQUEST });
    try {
        const response = await _submitFeedbackAPI(feedback);
        dispatch({ type: SUBMIT_FEEDBACK_SUCCESS, payload: response.data });
        return response;
    } catch (error: any) {
        dispatch({ type: SUBMIT_FEEDBACK_FAILURE, payload: error.message });
    }
};

export const fetchFeedbackData = (id: number, baseId: number) => async (dispatch: Dispatch<FeedbackActionTypes>) => {
    dispatch({ type: FETCH_FEEDBACK_REQUEST });

    try {
        const response = await _fetchFeedbackAPI(id, baseId);
        dispatch({
            type: FETCH_FEEDBACK_SUCCESS,
            payload: response.data
        });
        return response.data;
    } catch (error: any) {
        console.error('API error:', error);
        dispatch({
            type: FETCH_FEEDBACK_FAILURE,
            payload: error.message
        });
        throw error;
    }
};

export const fetchFeedbackItemActivity = (itemId: number, activityId: number) => async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_ITEM_ACTIVITY_REQUEST });

    try {
        const response = await _fetchFeedbackActivitybyId(itemId, activityId);
        localStorage.setItem("itemActivityData", JSON.stringify(response));
        dispatch({ type: FETCH_ITEM_ACTIVITY_SUCCESS, payload: response });
        return response;
    } catch (error: any) {
        console.error('API error:', error);
        dispatch({ type: FETCH_ITEM_ACTIVITY_FAILURE, payload: error?.message });
    }
};


export const submitItemActivity = (baseId: number, feedback: FeedbackType) => async (dispatch: Dispatch<FeedbackActionTypes>) => {
    dispatch({ type: SUBMIT_FEEDBACK_REQUEST });
    try {
        const response = await _submitItemActivity(baseId, feedback);
        dispatch({ type: SUBMIT_FEEDBACK_SUCCESS, payload: response.data });
        return response.data;
    } catch (error: any) {
        dispatch({ type: SUBMIT_FEEDBACK_FAILURE, payload: error.message });
    }
};



export const deleteItemActivity = (baseId: number, itemId: number, feedback: FeedbackType) => async (dispatch: Dispatch<FeedbackActionTypes>) => {
    dispatch({ type: DELETE_ITEM_ACTIVITY_REQUEST });
    try {
        await _deleteItemActivity(baseId, itemId, feedback);
        dispatch({ type: DELETE_ITEM_ACTIVITY_SUCCESS, payload: itemId });
    } catch (error: any) {
        dispatch({ type: DELETE_ITEM_ACTIVITY_FAILURE, payload: error.message });
    }
};

export const fetchItemInsightData = (baseId: number, id: number) => async (dispatch: Dispatch<FeedbackActionTypes>) => {
    dispatch({ type: FETCH_ITEM_INSIGHT_REQUEST });

    try {
        const response = await _fetchItemInsight(baseId, id); 
        dispatch({
            type: FETCH_ITEM_INSIGHT_SUCCESS,
            payload: response.data
        });
        return response.data;
    } catch (error: any) {
        console.error('API error:', error);
        dispatch({
            type: FETCH_ITEM_INSIGHT_FAILURE,
            payload: error.message
        });
        throw error;
    }
};

export const updateFeedback = (id: number, baseId: number, feedback: FeedbackType) => async (dispatch: Dispatch<FeedbackActionTypes>) => {
    dispatch({ type: SUBMIT_FEEDBACK_REQUEST });
    try {
        const response = await _updateFeedbackAPI(id, baseId, feedback);
        dispatch({ type: SUBMIT_FEEDBACK_SUCCESS, payload: response.data }); 
        return response.data;
    } catch (error: any) {
        dispatch({ type: SUBMIT_FEEDBACK_FAILURE, payload: error.message });
    }
};

export const fetchAllActivityByBaseId = (baseId: number) => async (dispatch: Dispatch<FeedbackActionTypes>) => {
    dispatch({ type: FETCH_ALL_ACTIVITY_BY_BASE_ID_REQUEST });

    try {
        const response = await _fetchAllActivityByBaseId(baseId);
        dispatch({
            type: FETCH_ALL_ACTIVITY_BY_BASE_ID_SUCCESS,
            payload: response.data
        });
        return response.data;
    } catch (error: any) {
        console.error('API error:', error);
        dispatch({
            type: FETCH_ALL_ACTIVITY_BY_BASE_ID_FAILURE,
            payload: error.message
        });
        throw error;
    }
};


export const SubmitInsightData = (baseId: number, feedback: FeedbackType) => async (dispatch: Dispatch<FeedbackActionTypes>) => {
    dispatch({ type: SUBMIT_INSIGHT_REQUEST });
    try {
        const response = await _submitInsightAPI(baseId, feedback);
        dispatch({ type: SUBMIT_INSIGHT_SUCCESS, payload: response.data });
        return response.data;
    } catch (error: any) {
        dispatch({ type: SUBMIT_INSIGHT_FAILURE, payload: error.message });
    }
};


export const DeleteInsightData = (baseId: number, itemId: number, feedback: FeedbackType) => async (dispatch: Dispatch<FeedbackActionTypes>) => {
    dispatch({ type: DELETE_INSIGHT_REQUEST });
    try {
        await _deleteInsightAPI(baseId, itemId, feedback);
        dispatch({ type: DELETE_INSIGHT_SUCCESS, payload: itemId });
    } catch (error: any) {
        dispatch({ type: DELETE_INSIGHT_FAILURE, payload: error.message });
    }
};

export const fetchItemInsightById = (baseId: number, itemId: number) => async (dispatch: Dispatch<FeedbackActionTypes>) => {
    dispatch({ type: FETCH_LIST_BY_ID_INSIGHT_REQUEST });

    try {
        const response = await _fetchInsightbyId(baseId, itemId);
        dispatch({
            type: FETCH_LIST_BY_ID_INSIGHT_SUCCESS,
            payload: response?.data
        });
        return response;
    } catch (error: any) {
        console.error('API error:', error);
        dispatch({
            type: FETCH_LIST_BY_ID_INSIGHT_FAILURE,
            payload: error?.message
        });
        throw error;
    }
};

export const UpdateInsightData = (baseId: number, id: number, feedback: FeedbackType) => async (dispatch: Dispatch<FeedbackActionTypes>) => {
    dispatch({ type: UPDATE_INSIGHT_REQUEST });
    try {
        const response = await _updateInsightAPI(baseId, id, feedback);
        dispatch({ type: UPDATE_INSIGHT_SUCCESS, payload: response.data });
        return response.data;
    } catch (error: any) {
        dispatch({ type: UPDATE_INSIGHT_FAILURE, payload: error.message });
    }
};

// export const deleteItemFeedback = (baseId: number, itemIds: number[]) => async (dispatch: Dispatch<FeedbackActionTypes>) => {
//     dispatch({ type: DELETE_FEEDBACK_LIST_REQUEST });
//     try {
//       const response = await _deleteItemMulti(baseId, itemIds);
//       dispatch({ type: DELETE_FEEDBACK_LIST_SUCCESS, payload: response.data });
//       return response; // Return the full response
//     } catch (error: any) {
//       dispatch({ type: DELETE_FEEDBACK_LIST_FAILURE, payload: error.message });
//       throw error; // Re-throw the error to be caught in the component
//     }
//   };

export const deleteItemFeedback = (baseId: number, itemIds: number[]) => async (dispatch: Dispatch<FeedbackActionTypes>) => {
    dispatch({ type: DELETE_FEEDBACK_LIST_REQUEST });
    try {
      const response = await _deleteItemMulti(baseId, itemIds);
      dispatch({ type: DELETE_FEEDBACK_LIST_SUCCESS, payload: response.data });
      return response; // Return the full response
    } catch (error: any) {
      dispatch({ type: DELETE_FEEDBACK_LIST_FAILURE, payload: error.message });
      throw error;
    }
  };


  export const fetchFeedbackListingtData = (baseId: number, item_type: string, item_base_status_id: number, page: number, filters: any) => async (dispatch: Dispatch<FeedbackActionTypes>) => {
    dispatch({ type: FETCH_FEEDBACK_LISTING_REQUEST });

    try {
        const response = await _fetchFeedbackListing(baseId, item_type, item_base_status_id, page, filters);
        dispatch({
            type: FETCH_FEEDBACK_LISTING_SUCCESS,
            payload: response.data
        });
        return response.data;
    } catch (error: any) {
        console.error('API error:', error);
        dispatch({
            type: FETCH_FEEDBACK_LISTING_REQUEST,
            payload: error.message
        });
        throw error;
    }
};
