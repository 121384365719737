// @ts-nocheck
import React, { useEffect, useState, Fragment } from 'react';
import { _feedback_update_item, _item_TopicSearchByParam } from '../setting/settingPages/baseAPI';
import {
  deleteItemFeedback, fetchFeedbackData, fetchFeedbackItemActivity,
  fetchItemInsightData
} from '../feedbacks/feedback-redux/feedbacks.actions';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../hooks/hooks';
import { RootState } from '../../store/store';
import { Toaster, toast } from 'sonner';
import { formatDistance } from 'date-fns';
import { Link } from 'react-router-dom';
import { Dialog, Label, Listbox, Transition } from '@headlessui/react';
import { Button } from '@lemonsqueezy/wedges';

interface ViewFeedbackProps {
  activity: {
    id: number;
    type: string;
    person?: {
      name?: string;
      imageUrl?: string;
    };
    date: string;
    dateTime: string;
    comment?: string;
  }[];
  item?: {
    id: number;
    name: string;
    daysAgo: string;
    createdBy: string;
    avatarUrl: string;
    description: string;
    statusColor: string;
  };
  isSidebarOpen?: boolean;
  setIsSidebarOpen?: (open: boolean) => void;
  headernone?: boolean;
  handleSidebarToggle?: () => void;
}



const CustomerTopic: React.FC<ViewFeedbackProps> = ({ setItemInsight, itemId, setItemStatusActivity }) => {
  const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { data, loading, error } = useSelector((state: RootState) => state.feedbacks);


  const handleCustomerDelete = async () => {
    setDeleteLoading(true);
    try {
      const idsToDelete = [Number(id)];

      if (!idsToDelete || idsToDelete.length === 0) {
        toast.error('No items selected for deletion');
        return;
      }

      const deleteResponse = await dispatch(deleteItemFeedback(baseId, idsToDelete));

      if (!deleteResponse || !deleteResponse.data) {
        throw new Error('Invalid delete response received');
      }

      const successfulDeletes = deleteResponse.data
        .filter((item: any) => item.isSuccess)
        .map((item) => item.id);
      const failedDeletes = deleteResponse.data.filter((item: any) => !item.isSuccess);

      if (successfulDeletes.length > 0) {
        toast.success(`Successfully deleted item(s)`);
      }

      if (deleteResponse.status === 200) {
        setShowDeleteConfirmation(false);
        navigate(`/${baseId}/tickets`);
      }

      failedDeletes.forEach((item: any) => {
        toast.error(`Failed to delete item`);
      });
    } catch (error: any) {
      toast.error(`An error occurred while deleting item(s).`);
    } finally {
      setDeleteLoading(false);
    }
  };


  const handleConfirmDelete = () => {
    setShowDeleteConfirmation(true);
  }



  if (loading) return (
    <div className=" flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 0px)' }}>
      <div className="simple-spinner-loader">
        <span></span>
      </div>
    </div>
  );
  if (error) return <div>Error: {error}</div>;
  if (data?.length === 0) return <div>No feedback available</div>;

  // for create by user
  const baseMembersDetailsString = localStorage.getItem('baseMembersDetails');
  const baseMembersDetails = baseMembersDetailsString ? JSON.parse(baseMembersDetailsString) : [];


  const createdTimeSidebar = formatDistance(new Date(data[0].created_at), new Date(), { includeSeconds: true });
  const createdTimeAgoSidebar = createdTimeSidebar.replace(/^about\s/, '');
  const updatedTimeSidebar = formatDistance(new Date(data[0].updated_at), new Date(), { includeSeconds: true });
  const updatedTimeAgoSidebar = updatedTimeSidebar.replace(/^about\s/, '');



  return (
    <>
      <Toaster richColors />
      <div className=' mx-[-24px] min-w-[340px] w-full px-[1.5rem]'>
        <div className="w-full border-t border-l-0  border-solid border-light-gray-200 px-[1.5rem] pt-[1rem]">
          <div className="w-full flex items-center justify-between lg:justify-start py-1">
            <div className="mr-2 py-1 px-0 font-normal text-dark-black-200 text-[13px] w-[35%]">
              Created
            </div>
            <div className="relative w-[65%]">
              <span className="text-[13px] leading-4 whitespace-normal max-w-[140px] w-full text-[#52555a] text-start break-words font-medium">
                {createdTimeAgoSidebar} ago
              </span>
            </div>
          </div>
          {createdTimeAgoSidebar !== updatedTimeAgoSidebar && (
            <div className="w-full flex items-center justify-between lg:justify-start py-1">
              <div className="mr-2 py-1 px-0 font-normal text-dark-black-200 text-[13px] w-[35%]">
                Updated
              </div>
              <div className="relative w-[65%]">
                <span className="text-[13px] leading-4 whitespace-normal max-w-[140px] w-full text-[#52555a] text-start break-words font-medium">
                  {updatedTimeAgoSidebar} ago
                </span>
              </div>
            </div>)}
          {
            data[0].item_source_type &&
            <div className="w-full flex items-start justify-between lg:justify-start py-1">
              <div className="mr-2 py-1 px-0 font-normal text-dark-black-200 text-[13px] w-[35%]">
                Source
              </div>
              <div className="relative w-[65%]">
                <span className=" text-[13px] leading-4 whitespace-normal max-w-[140px] w-full text-[#52555a]  text-start break-words font-medium">
                  {data[0].item_source_type}
                </span>
              </div>
            </div>
          }

          {
            data[0]?.item_source_id &&
            <div className="w-full flex items-start justify-between lg:justify-start py-1">
              <div className="mr-2 py-0 px-0 font-normal text-dark-black-200 text-[13px] w-[35%] leading-inherit">
                Source Id
              </div>
              <div className="relative w-[65%]">
                <span className=" text-[13px] leading-4 whitespace-normal max-w-[140px] w-full text-[#52555a]  text-start break-words font-medium">
                  {data[0].item_source_id}
                </span>
              </div>
            </div>
          }

        </div>

      </div>
      <div className='flex justify-start w-full px-[1.5rem] py-4'>





        <Link to=""
          // onClick={() => handleCustomerDelete(id)} 
          onClick={(id) => handleConfirmDelete(id)}
          className="underline text-[13px] text-[#b91c1c]">Delete</Link>
      </div>
      <Transition.Root show={showDeleteConfirmation} as={Fragment}>
        <Dialog as="div" className="relative z-50 md:z-10 lg:z-10" onClose={() => setShowDeleteConfirmation(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center sm:items-start h-full items-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[500px] sm:p-6">
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to delete your Topic?
                    </p>
                  </div>
                  <div className="mt-5 sm:mt-4 flex gap-2 justify-end">
                    <Button
                      type="button"
                      onClick={handleCustomerDelete}
                      className="bg-red-600 hover:bg-red-500 focus-visible:outline-red-600 relative [&>span]:flex [&>span]:items-center [&>span]:gap-[8px]"
                      disabled={deleteLoading}
                    >
                      {deleteLoading ? (
                        <>
                          Delete
                          <div className="simple-spinner">
                            <span></span>
                          </div>
                        </>
                      ) : (
                        'Delete'
                      )}
                    </Button>
                    <button
                      type="button"
                      onClick={() => setShowDeleteConfirmation(false)}
                      className="mt-3 inline-flex leading-6 w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default CustomerTopic;
