// @ts-nocheck

import { useState, useEffect, useRef, Fragment } from 'react'
import { UserCircleIcon } from '@heroicons/react/24/solid'
import { _getProfileDetails, _updateProfile } from './profileAPI';
import AlertPopup from '../../../components/AlertPopup';
import DropDown from '../../../components/Dropdown';
import { Button, Input } from '@lemonsqueezy/wedges';
import { Toaster, toast } from 'sonner';
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import { Dialog, Transition } from '@headlessui/react';
import { useDispatch } from 'react-redux';
import { _userProfileImage } from '../../setting/settingPages/baseAPI';


const timeZoneList = [{ name: "Pacific/Midway" }, { name: "Pacific/Honolulu" }, { name: "Pacific/Marquesas" }, { name: "America/Anchorage" }, { name: "America/New_York" }]

const Profile = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [timeZone, setTimezone] = useState('America/New_York')
    const [data, setData] = useState<any>()
    const [showAlert, setShowAlert] = useState(false)
    const [file, setFile] = useState<File | null>(null);
    const [logoUrl, setLogoUrl] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [cropSrc, setCropSrc] = useState<string | null>(null);
    const [crop, setCrop] = useState<Crop>({
        unit: 'px',
        width: 250,
        height: 250,
        x: 0,
        y: 0,
        aspect: 1,
    });
    const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>(null);
    const [showCropModal, setShowCropModal] = useState(false);
    const imageRef = useRef<HTMLImageElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [logoLoading, setLogoLoading] = useState(true);
    const [profileImage, setProfileImage] = useState('')


    useEffect(() => {
        getProfileDetails()
    }, [])
    const getProfileDetails = () => {
        _getProfileDetails().then((res) => {
            if (res.status === 200) {
                setName(res?.data?.user?.name)
                setEmail(res?.data?.user?.email)
                setTimezone(res?.data?.user?.timezone)
                setProfileImage(res?.data?.user?.profile_pic)
            }
        }).catch((err) => {
            console.log("err", err)
        })
    }

    const updateProfile = () => {
        _updateProfile(name).then((res) => {
            console.log(res, "resres");
            
            if (res.status === 200) {
                // Get existing baseMembersDetails from local storage
                const baseMembersDetails = JSON.parse(localStorage.getItem('baseMembersDetails')) || [];
                
                // Find the index of the member to update
                const index = baseMembersDetails.findIndex(member => member.id === res.data.id);
                
                if (index !== -1) {
                    // Update the member details
                    baseMembersDetails[index] = res.data;
                    
                    // Save the updated list back to local storage
                    localStorage.setItem('baseMembersDetails', JSON.stringify(baseMembersDetails));
                } else {
                    // If the member was not found, you might want to handle this case
                    console.error('Member not found in local storage.');
                }
                
                setData(res);
                setShowAlert(true);
            } else {
                setData(res);
                setShowAlert(true);
            }
        }).catch((err) => {
            console.log("err", err);
        });
    };
    

    useEffect(() => {
        if (profileImage) {
            setLogoUrl(`${process.env.REACT_APP_API_BASE_URL}/${profileImage}`);
        }
    }, [profileImage]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const selectedFile = event.target.files[0];
            setFile(selectedFile);
            setCropSrc(URL.createObjectURL(selectedFile));
            setShowCropModal(true);
        }
    };

    const onImageLoaded = (event: React.SyntheticEvent<HTMLImageElement>) => {
        imageRef.current = event.currentTarget;
        setCrop(centerCrop(makeAspectCrop({ unit: 'px', width: 250, aspect: 1 }, 1, imageRef.current.width, imageRef.current.height)));

    };



    const getCroppedImage = (): Promise<File | null> => {
        if (!completedCrop || !imageRef.current || !canvasRef.current || !file) {
            return Promise.resolve(null);
        }

        const image = imageRef.current;
        const canvas = canvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        if (!ctx) {
            return Promise.resolve(null);
        }

        canvas.width = 250;
        canvas.height = 250;

        return new Promise((resolve) => {
            if (image.complete) {
                drawCanvas();
            } else {
                image.onload = drawCanvas;
            }

            function drawCanvas() {
                ctx.drawImage(
                    image,
                    crop.x * scaleX,
                    crop.y * scaleY,
                    crop.width * scaleX,
                    crop.height * scaleY,
                    0,
                    0,
                    250,
                    250
                );

                canvas.toBlob((blob) => {
                    if (!blob) {
                        resolve(null);
                        return;
                    }
                    const croppedFile = new File([blob], file.name, { type: file.type });
                    resolve(croppedFile);
                }, file.type,
                    1.0
                );
            }
        });
    };

    const handleCropSave = async () => {
        if (!completedCrop) {
            toast.error('Please complete the crop before saving.');
            return;
        }
    
        setLoading(true);
        try {
            const croppedFile = await getCroppedImage();
            if (!croppedFile) {
                throw new Error("Cropping failed or file is null.");
            }
    
            const response = await _userProfileImage(croppedFile);
            
    
            // Dispatch custom event after updating local storage
            window.dispatchEvent(new Event("userProfileDetailsUpdated"));
    
            if (response.status === 200) {
                toast.success('Upload successful!');
                setLogoUrl(`${process.env.REACT_APP_API_BASE_URL}/${response.data.profile_pic}`);
                localStorage.setItem('userProfileDetails', JSON.stringify(response));
            } else {
                throw new Error(`Upload failed with status: ${response.status}`);
            }
        } catch (error) {
            toast.error('Upload failed. Please try again.');
        } finally {
            setLoading(false);
            setShowCropModal(false);
        }
    };
    


    return (
        <>

            <main className='py-[34px] lg:py-[70px] px-0 lg:px-10 h-full flex'>
                <div className='px-[2px] sm:px-6 lg:px-8 w-full flex justify-center'>
                    <div className='w-full max-w-[520px]'>
                        <div className='flex flex-col gap-y-[6px]'>
                            <h2 className='text-[20px] lg:text-[24px] font-medium leading-8 text-[#1b1b1b] tracking-[-0.01rem]'>Profile</h2>
                            <p className='text-sm leading-6 text-[#5c5c5e] font-medium'>
                            This information will be displayed publicly so be careful what you share.
                            </p>
                        </div>
                        <div className='flex items-center mt-6 lg:mt-10 justify-between relative'>
                            <label className="flex flex-inherit h-[70px] w-[70px] rounded-full z-10 absolute top-[0px] left-[0px] cursor-move">
                                <input className="opacity-0 focus:outline-none cursor-move" type="file" onChange={handleFileChange} accept="image/*" />
                            </label>
                            {logoLoading && (
                                <div className="absolute top-[0px] left-[0px] h-[70px] w-[70px] rounded-full flex items-center justify-center bg-[#f5f5f5] ">
                                    <div className="flex items-center justify-center relative z-50 h-full">
                                        <div className="simple-spinner-loader">
                                            <span className='!w-4 !h-4'></span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <img
                                src={logoUrl}
                                alt=""
                                className="h-[70px] w-[70px] rounded-full border-[5px] border-solid border-[#f5f5f5] absolute top-[0px] left-[0px] cursor-move"
                                onLoad={() => setLogoLoading(false)}
                            />
                        </div>
                        <div className='mt-14 lg:mt-[6rem]'>
                            <label htmlFor="text" className="wg-label inline-flex shrink-0 items-center gap-1 text-[14px] text-[#2f2f31] wg-antialiased  leading-5">
                               Name
                            </label>
                            <div className="mt-2">
                                <Input
                                    id="name"
                                    name="name"
                                    type="text"
                                    autoComplete="text"
                                    placeholder='Enter your name'
                                    required
                                    value={name}
                                    onChange={(e) => setName((e.target as HTMLInputElement).value)}
                                    className="focus:outline-indigo-600 h-[36px]"
                                />
                            </div>
                        </div>
                        <div className='my-5'>
                            <label htmlFor="email" className="wg-label inline-flex shrink-0 items-center gap-1 text-[14px] text-[#2f2f31] wg-antialiased  leading-5">
                                Email
                            </label>
                            <div className="mt-2">
                                <Input
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail((e.target as HTMLInputElement).value)}
                                    autoComplete="email"
                                    placeholder='Enter your email'
                                    required
                                    disabled
                                    className="focus:outline-indigo-600 h-[36px]"
                                />
                            </div>
                        </div>
                        {/* <div>
                            <label htmlFor="password" className="wg-label inline-flex shrink-0 items-center gap-1 text-[14px] text-[#2f2f31] wg-antialiased  leading-5">
                               Timezone
                            </label>
                            <div className="mt-2">
                                <DropDown previousValue={timeZone} onChangeValue={(e: string) => setTimezone(e)} data={timeZoneList} />
                            </div>
                        </div> */}
                        <div className='flex justify-end mt-5'>
                            <Button
                                onClick={updateProfile}
                                type="submit"
                                className=" bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600"
                                size="sm"
                            >
                                Update
                            </Button>
                        </div>
                    </div>
                </div>
            </main>

            <AlertPopup data={data} show={showAlert} setShow={() => setShowAlert(false)} />
            <Transition.Root show={showCropModal} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => setShowCropModal(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-y-auto rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div>

                                        <ReactCrop
                                            crop={crop}
                                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                                            onComplete={(c) => {
                                                setCompletedCrop(c);
                                            }}
                                            className='w-full'
                                            aspect={1}
                                        >
                                            <img src={cropSrc} alt="Crop preview" onLoad={onImageLoaded} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                        </ReactCrop>
                                        <canvas ref={canvasRef} className="hidden" />
                                    </div>
                                    <div className='flex justify-end mt-5'>
                                        <button
                                            onClick={handleCropSave}
                                            className='group inline-flex shrink-0 select-none items-center justify-center text-sm font-medium
    leading-6 transition-colors duration-100 wg-antialiased focus:outline-0 focus-visible:outline focus-visible:outline-2 
    focus-visible:outline-offset-2 disabled:pointer-events-none gap-0 px-8  py-2 rounded-lg text-white outline-primary
    disabled:opacity-50 bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600'
                                            disabled={loading || !completedCrop}
                                        >
                                            {loading ? 'Save' : 'Save'}
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}
export default Profile
