// @ts-nocheck
import React, { Fragment, useEffect, useState } from 'react';
import { Button } from '@lemonsqueezy/wedges';
import { motion, AnimatePresence } from 'framer-motion';
import { deleteItemFeedback, updateFeedback } from './feedback-redux/feedbacks.actions';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../hooks/hooks';
import { RootState } from '../../store/store';
import { Toaster, toast } from 'sonner';
import { formatDistance } from 'date-fns';
import { TagsInput } from "react-tag-input-component";
import { Link } from 'react-router-dom';
import { Dialog, Label, Listbox, Transition } from '@headlessui/react';

interface ViewFeedbackProps {
  item?: {
    id: number;
    name: string;
    daysAgo: string;
    createdBy: string;
    avatarUrl: string;
    description: string;
    statusColor: string;
  };
  isSidebarOpen?: boolean;
  setIsSidebarOpen?: (open: boolean) => void;
  headernone?: boolean;
  handleSidebarToggle?: () => void;
}



const CustomerFeedback: React.FC<ViewFeedbackProps> = ({ itemId, data1, setItemStatusActivity }) => {
  const [collapsedCustomer, setCollapsedCustomer] = useState(false);
  const [collapsedCompany, setCollapsedCompany] = useState(false);
  const [editCustomer, setEditCustomer] = useState(false);
  const [editCompany, setEditCompany] = useState(false);
  const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;
  const dispatch = useAppDispatch();
  const [deleteOk, setDeleteOk] = useState(false)
  const { id } = useParams();
  const navigate = useNavigate();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { data, loading, error } = useSelector((state: RootState) => state.feedbacks);
  // for submit item customer
  const [customerInfo, setCustomerInfo] = useState({
    base_id: baseId,
    id: data[0]?.id,
    item_customer: [
      {
        item_customer_name: '',
        item_customer_unique_id: '',
        item_customer_email_id: '',
        item_customer_phone_number: '',
        item_customer_meta: '',
        item_customer_type: 'person',
        item_customer_label: '',
        item_customer_revenue: '',
      }
    ]
  });

  const [companyInfo, setCompanyInfo] = useState({
    base_id: baseId,
    id: data[0]?.id,
    item_customer: [
      {
        item_customer_name: '',
        item_customer_unique_id: '',
        item_customer_email_id: '',
        item_customer_phone_number: '',
        item_customer_meta: '',
        item_customer_type: 'company',
        item_customer_label: '',
        item_customer_revenue: '',
      }
    ]
  });
  const [validationError, setValidationError] = useState<string | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);




  const toggleCollapseCustomer = () => {
    setCollapsedCustomer(!collapsedCustomer);
  };

  const toggleCollapseCompany = () => {
    setCollapsedCompany(!collapsedCompany);
  };

  const handleCustomerEdit = () => {
    setEditCustomer((prevEditCustomer) => !prevEditCustomer);
  }

  const handleCompanyEdit = () => {
    setEditCompany((prevEditCustomer) => !prevEditCustomer);
  }



  useEffect(() => {
    if (baseId && id && data && data.length > 0, itemId, data1) {
      const feedbackItem = data[0] || data1[0];

      setCustomerInfo(prevState => ({
        ...prevState,
        id: feedbackItem.id || data[0]?.id || itemId,
        base_id: baseId,
        item_customer: [
          {
            item_customer_name: feedbackItem.item_person?.item_customer_name || '',
            item_customer_unique_id: feedbackItem.item_person?.item_customer_unique_id || '',
            item_customer_email_id: feedbackItem.item_person?.item_customer_email_id || '',
            item_customer_phone_number: feedbackItem.item_person?.item_customer_phone_number || '',
            item_customer_meta: feedbackItem.item_person?.item_customer_meta || '',
            item_customer_label: feedbackItem.item_person?.item_customer_label || '',
            item_customer_type: 'person',
            item_customer_revenue: feedbackItem.item_person?.item_customer_revenue || ''
          }
        ]
      }));
      setCompanyInfo(prevState => ({
        ...prevState,
        id: feedbackItem.id || data[0]?.id,
        base_id: baseId,
        item_customer: [
          {
            item_customer_name: feedbackItem.item_company?.item_customer_name || '',
            item_customer_unique_id: feedbackItem.item_company?.item_customer_unique_id || '',
            item_customer_email_id: feedbackItem.item_company?.item_customer_email_id || '',
            item_customer_phone_number: feedbackItem.item_company?.item_customer_phone_number || '',
            item_customer_meta: feedbackItem.item_company?.item_customer_meta || '',
            item_customer_label: feedbackItem.item_company?.item_customer_label || '',
            item_customer_type: 'company',
            item_customer_revenue: feedbackItem.item_company?.item_customer_revenue || ''
          }
        ]
      }));
    }
  }, [baseId, id, data, itemId, data1]);


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Array.isArray(e)) {
      setCompanyInfo(prevState => ({
        ...prevState,
        item_customer: [
          {
            ...prevState.item_customer[0],
            item_customer_label: e.join(','),
            item_customer_type: 'company'
          }
        ]
      }));
      setCustomerInfo(prevState => ({
        ...prevState,
        item_customer: [
          {
            ...prevState.item_customer[0],
            item_customer_label: e.join(', '),
            item_customer_type: 'person'
          }
        ]
      }));
      return;
    }
    const { name, value } = e.target;
    const isCompany = name.startsWith('company_');

    const updateState = isCompany ? setCompanyInfo : setCustomerInfo;
    const fieldName = isCompany ? name.replace('company_', '') : name;

    updateState(prevState => ({
      ...prevState,
      item_customer: [
        {
          ...prevState.item_customer[0],
          [fieldName]: value,
          item_customer_type: isCompany ? 'company' : 'person'
        }
      ]
    }));

    setValidationError(null);
  };

  const validateInfo = (info: typeof customerInfo | typeof companyInfo): boolean => {
    const { item_customer } = info;
    const { item_customer_email_id, item_customer_unique_id, item_customer_phone_number } = item_customer[0];

    return !!item_customer_email_id || !!item_customer_unique_id || !!item_customer_phone_number;
  };

  const handleSubmitItemCustomer = (info: typeof customerInfo | typeof companyInfo) => {
    if (!validateInfo(info)) {
      toast.error('At least one of Email, Unique ID, or Phone Number must be filled.');
      return;
    }

    setIsUpdating(true);

    const { id, base_id, item_customer } = info;

    const dataToSubmit = {
      id,
      base_id,
      item_customer: [
        {
          ...item_customer[0],
          item_customer_type: info === companyInfo ? 'company' : 'person'
        }
      ]
    };

    dispatch(updateFeedback(id, base_id, dataToSubmit))
      .then(() => {
        toast.success(`${info === companyInfo ? 'Company' : 'Customer'} information updated successfully`);
        setEditCustomer(false);
        setEditCompany(false)
        setCustomerInfo({
          base_id: baseId,
          id: data[0]?.id || itemId,
          item_customer: [
            {
              item_customer_name: '',
              item_customer_unique_id: '',
              item_customer_email_id: '',
              item_customer_phone_number: '',
              item_customer_meta: '',
              item_customer_type: 'person',
              item_customer_label: '',
              item_customer_revenue: '',
            }
          ]
        });
        setCompanyInfo({
          base_id: baseId,
          id: data[0]?.id || itemId,
          item_customer: [
            {
              item_customer_name: '',
              item_customer_unique_id: '',
              item_customer_email_id: '',
              item_customer_phone_number: '',
              item_customer_meta: '',
              item_customer_type: 'company',
              item_customer_label: '',
              item_customer_revenue: '',
            }
          ]
        });
      })
      .catch((error) => {
        console.error('Update error:', error);
        toast.error(`Failed to update ${info === companyInfo ? 'company' : 'customer'} information. Please try again.`);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const handleCustomerDelete = async () => {
    setDeleteLoading(true);
    try {
      const idsToDelete = [Number(id)];
      
      if (!idsToDelete || idsToDelete.length === 0) {
        toast.error('No items selected for deletion');
        return;
      }

      const deleteResponse = await dispatch(deleteItemFeedback(baseId, idsToDelete));

      if (!deleteResponse || !deleteResponse.data) {
        throw new Error('Invalid delete response received');
      }

      const successfulDeletes = deleteResponse.data
        .filter((item: any) => item.isSuccess)
        .map((item) => item.id);
      const failedDeletes = deleteResponse.data.filter((item: any) => !item.isSuccess);

      if (successfulDeletes.length > 0) {
        toast.success(`Successfully deleted item(s)`);
      }

      if (deleteResponse.status === 200) {
        setShowDeleteConfirmation(false);
        navigate(`/${baseId}/feedbacks`);
      }

      failedDeletes.forEach((item: any) => {
        toast.error(`Failed to delete item`);
      });
    } catch (error: any) {
      toast.error(`An error occurred while deleting item(s).`);
    } finally {
      setDeleteLoading(false);
    }
  };
  

  const handleConfirmDelete = () => {
    setShowDeleteConfirmation(true);
  }



  if (loading) return (
    <div className=" flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 0px)' }}>
      <div className="simple-spinner-loader">
        <span></span>
      </div>
    </div>
  );
  if (error) return <div>Error: {error}</div>;
  if (data?.length === 0) return <div>No feedback available</div>;


  const createdTimeSidebar = formatDistance(new Date(data[0].created_at), new Date(), { includeSeconds: true });
  const createdTimeAgoSidebar = createdTimeSidebar.replace(/^about\s/, '');
  const updatedTimeSidebar = formatDistance(new Date(data[0].updated_at), new Date(), { includeSeconds: true });
  const updatedTimeAgoSidebar = updatedTimeSidebar.replace(/^about\s/, '');


  return (
    <>
      <Toaster richColors />
      <aside className='flex flex-col justify-stretch min-h-[3rem] border-b border-t-0 border-l-0 border-r border-solid border-light-gray-200 px-[1.5rem] pt-[1.5rem] items-start gap-4  pb-[40px]'>
        <div className="flex items-center max-w-full font-[500] leading-[1.2] bg-light-gray-100 rounded-full text-[0.75rem] px-[0.5rem] py-[5px] gap-2">
          <span className="text-[#52555a] capitalize">Customer</span>
        </div>

        <div className="mb-2 mx-[-6px] w-full">
          <div className='flex justify-between'>
            <a
              href="#"
              onClick={toggleCollapseCustomer}
              className="h-8 items-center rounded-lg bg-transparent hover:bg-light-gray-100 text-dark-black-200 font-medium text-[0.8125rem] 
                  focus:outline-none px-2 justify-start inline-flex"
            >
              Person
              <div className={`${collapsedCustomer ? 'rotate-180' : 'rotate-0'} h-6 w-6 flex-shrink-0 transform transition-transform duration-300`}>
                <svg
                  width={24}
                  height={24}
                  fill="none"
                  className="h-6 w-6 fill-transparent stroke-current text-wedges-gray-400"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M15.25 10.75 12 14.25l-3.25-3.5"
                  />
                </svg>
              </div>
            </a>
            <Link to="" onClick={handleCustomerEdit} className="underline text-[13px] text-[#266df0]">Edit</Link>
          </div>
          {editCustomer ?
            <>
              <AnimatePresence initial={false}>
                {!collapsedCustomer && (
                  <motion.div
                    initial={{ height: 0 }}
                    animate={{ height: 'auto' }}
                    exit={{ height: 0, }}
                    transition={{ height: { duration: 0.5 }, }}
                    className="overflow-hidden"
                  >
                    <div className="">
                      <div className='w-full mt-5'>
                        <div className='flex gap-y-[24px] flex-col'>
                          <div className="relative">
                            <label
                              htmlFor="item_customer_name"
                              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                            >
                              Name
                            </label>
                            <input
                              id="item_customer_name"
                              name="item_customer_name"
                              type="text"
                              maxLength={30}
                              value={customerInfo.item_customer[0]?.item_customer_name}
                              onChange={handleInputChange}
                              // placeholder="Jane Smith"
                              className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                            />
                          </div>
                          <div className="relative">
                            <label
                              htmlFor="item_customer_email_id"
                              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                            >
                              Email
                            </label>
                            <input
                              id="item_customer_email_id"
                              name="item_customer_email_id"
                              maxLength={30}
                              value={customerInfo?.item_customer[0]?.item_customer_email_id}
                              onChange={handleInputChange}
                              type="email"
                              // placeholder="test@123gmail.com"
                              className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                            />
                          </div>
                          <div className="relative">
                            <label
                              htmlFor="item_customer_unique_id"
                              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                            >
                              Unique Id
                            </label>
                            <input
                              id="item_customer_unique_id"
                              name="item_customer_unique_id"
                              value={customerInfo?.item_customer[0]?.item_customer_unique_id}
                              onChange={handleInputChange}
                              maxLength={30}
                              type="text"
                              // placeholder="1234"
                              className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                            />
                          </div>
                          <div className="relative">
                            <label
                              htmlFor="item_customer_phone_number"
                              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                            >
                              Phone no
                            </label>
                            <input
                              id="item_customer_phone_number"
                              name="item_customer_phone_number"
                              value={customerInfo?.item_customer[0]?.item_customer_phone_number}
                              onChange={handleInputChange}
                              maxLength={30}
                              type="number"
                              // placeholder="1234567890"
                              className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                            />
                          </div>
                          <div className="relative">
                            <label
                              htmlFor="item_customer_meta"
                              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                            >
                              Meta
                            </label>
                            <input
                              id="item_customer_meta"
                              name="item_customer_meta"
                              value={customerInfo?.item_customer[0]?.item_customer_meta}
                              onChange={handleInputChange}
                              maxLength={1000}
                              type="text"
                              // placeholder="Enter meta value"
                              className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                            />
                          </div>
                          <div className="relative item-viewfeedback-label">
                            <label
                              htmlFor="item_customer_label"
                              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                            >
                              Label
                            </label>
                            <TagsInput
                              value={customerInfo?.item_customer[0]?.item_customer_label ? customerInfo.item_customer[0].item_customer_label.split(',').filter(Boolean) : []}
                              onChange={(tags) => handleInputChange(tags)}
                              id="company_item_customer_label"
                              name="company_item_customer_label"
                            />
                          </div>
                          <div className="relative">
                            <label
                              htmlFor="item_customer_revenue"
                              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                            >
                              Revenue
                            </label>
                            <input
                              id="item_customer_revenue"
                              name="item_customer_revenue"
                              value={customerInfo?.item_customer[0]?.item_customer_revenue}
                              onChange={handleInputChange}
                              maxLength={30}
                              type="text"
                              // placeholder="Enter Revenue"
                              className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                            />
                          </div>
                          <div className='w-auto flex justify-end'>
                            <Button
                              type="button"
                              className="ml-2 bg-indigo-500 h-[32px] hover:bg-indigo-600 focus-visible:outline-indigo-600 w-auto
                              [&>span]:flex [&>span]:items-center [&>span]:gap-[6px]"
                              size="sm"
                              onClick={() => handleSubmitItemCustomer(customerInfo)}
                              disabled={isUpdating}
                            >
                              {isUpdating ? (
                                <>
                                  Saving
                                  <div className="simple-spinner">
                                    <span></span>
                                  </div>
                                </>
                              ) : (
                                'Save'
                              )}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </>
            :
            <>
              {
                !editCustomer &&

                <AnimatePresence initial={false}>
                  {!collapsedCustomer && (
                    <motion.div
                      initial={{ height: 0 }}
                      animate={{ height: 'auto' }}
                      exit={{ height: 0, }}
                      transition={{ height: { duration: 0.5 }, }}
                      className="overflow-hidden"
                    >
                      <div className="w-full">
                        <div className="w-full flex items-center justify-between lg:justify-start py-1">
                          <div className="mr-2 py-1 px-2 font-normal text-dark-black-200 text-[13px] w-[30%]">
                            Name
                          </div>
                          <div className="relative w-[70%]">
                            <button
                              className="relative justify-center flex items-center cursor-pointer rounded-md bg-white py-1 px-2 text-left text-[12px] text-[#52555a]
                               font-medium bg-transparent border-solid hover:!border-light-gray-200 border border-white"
                            >
                              <div className="flex items-center gap-1">
                                <span className="flex h-[8px] shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-white capitalize w-0 mr-[-4px]" />
                                <span className="block truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full  overflow-hidden text-ellipsis text-start font-medium">
                                  {data[0]?.item_person?.item_customer_name}
                                </span>
                              </div>
                            </button>
                          </div>
                        </div>
                        <div className="w-full flex items-center justify-between lg:justify-start py-1">
                          <div className="mr-2 py-1 px-2 font-normal text-dark-black-200 text-[13px] w-[30%]">
                            Email
                          </div>
                          <div className="relative w-[70%]">
                            <button
                              className="relative justify-center flex items-center cursor-pointer rounded-md bg-white py-1 px-2 text-left  text-[12px] text-[#52555a] 
                              font-medium bg-transparent  border-solid hover:!border-light-gray-200 border border-white"
                            >
                              <div className="flex items-center gap-1">
                                <span className="flex h-[8px] shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-white capitalize w-0 mr-[-4px]" />
                                <span className="truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full  overflow-hidden text-ellipsis text-start">
                                  {data[0]?.item_person?.item_customer_email_id}
                                </span>
                              </div>
                            </button>
                          </div>
                        </div>
                        <div className="w-full flex items-center justify-between lg:justify-start py-1">
                          <div className="mr-2 py-1 px-2 font-normal text-dark-black-200 text-[13px] w-[30%]">
                            Unique Id.
                          </div>
                          <div className="relative w-[70%]">
                            <button
                              className="relative justify-center flex items-center cursor-pointer rounded-md bg-white py-1 px-2 text-left  text-[12px] text-[#52555a] 
                              font-medium bg-transparent  border-solid hover:!border-light-gray-200 border border-white"
                            >
                              <div className="flex items-center gap-1">
                                <span className="block truncate  text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full  overflow-hidden text-ellipsis text-start">
                                  {data[0]?.item_person?.item_customer_unique_id}

                                </span>
                              </div>
                            </button>
                          </div>
                        </div>
                        <div className="w-full flex items-center justify-between lg:justify-start py-1">
                          <div className="mr-2 py-1 px-2 font-normla text-dark-black-200 text-[13px] w-[30%]">
                            Phone
                          </div>
                          <div className="relative w-[70%]">
                            <button
                              className="relative justify-center flex items-center cursor-pointer rounded-md bg-white py-1 px-2 text-left text-[12px] text-[#52555a]
                               font-medium bg-transparent border-solid hover:!border-light-gray-200 border border-white"
                            >
                              <div className="flex items-center gap-1">
                                <span className="block truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full  overflow-hidden text-ellipsis text-start">
                                  {data[0]?.item_person?.item_customer_phone_number}
                                </span>
                              </div>
                            </button>
                          </div>
                        </div>
                        <div className="w-full flex items-center justify-between lg:justify-start py-1">
                          <div className="mr-2 py-1 px-2 font-normla text-dark-black-200 text-[13px] w-[30%]">
                            Meta
                          </div>
                          <div className="relative w-[70%]">
                            <button
                              className="relative justify-center flex items-center cursor-pointer rounded-md bg-white py-1 px-2 text-left text-[12px] text-[#52555a]
                               font-medium bg-transparent border-solid hover:!border-light-gray-200 border border-white"
                            >
                              <div className="flex items-center gap-1">
                                <span className="block truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full  overflow-hidden text-ellipsis text-start">
                                  {data[0]?.item_person?.item_customer_meta}
                                </span>
                              </div>
                            </button>
                          </div>
                        </div>
                        <div className="w-full flex items-center justify-between lg:justify-start py-1">
                          <div className="mr-2 py-1 px-2 font-normla text-dark-black-200 text-[13px] w-[30%]">
                            Label
                          </div>
                          <div className="relative w-[70%]">
                            <button
                              className="relative justify-center flex items-center cursor-pointer rounded-md bg-white py-1 px-2 text-left text-[12px] text-[#52555a]
                               font-medium bg-transparent border-solid hover:!border-light-gray-200 border border-white"
                            >
                              <div className="flex items-center gap-1">
                                <span className="block truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full  overflow-hidden text-ellipsis text-start">
                                  {data[0]?.item_person?.item_customer_label}
                                </span>
                              </div>
                            </button>
                          </div>
                        </div>
                        <div className="w-full flex items-center justify-between lg:justify-start py-1">
                          <div className="mr-2 py-1 px-2 font-normla text-dark-black-200 text-[13px] w-[30%]">
                            Revenue
                          </div>
                          <div className="relative w-[70%]">
                            <button
                              className="relative justify-center flex items-center cursor-pointer rounded-md bg-white py-1 px-2 text-left text-[12px] text-[#52555a]
                               font-medium bg-transparent border-solid hover:!border-light-gray-200 border border-white"
                            >
                              <div className="flex items-center gap-1">
                                <span className="block truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full  overflow-hidden text-ellipsis text-start">
                                  {data[0]?.item_person?.item_customer_revenue}
                                </span>
                              </div>
                            </button>
                          </div>
                        </div>

                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              }
            </>


          }



        </div>


        {/*  */}

        <div className="mb-2 mx-[-6px] w-full">
          <div className='flex justify-between'>
            <a
              href="#"
              onClick={toggleCollapseCompany}
              className="h-8 items-center rounded-lg bg-transparent hover:bg-light-gray-100 text-dark-black-200 font-medium text-[0.8125rem] 
                                focus:outline-none px-2 justify-start inline-flex"
            >
              Company
              <div className={`${collapsedCompany ? 'rotate-180' : 'rotate-0'} h-6 w-6 flex-shrink-0 transform transition-transform duration-300`}>
                <svg
                  width={24}
                  height={24}
                  fill="none"
                  className="h-6 w-6 fill-transparent stroke-current text-wedges-gray-400"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M15.25 10.75 12 14.25l-3.25-3.5"
                  />
                </svg>
              </div>
            </a>
            <Link to="" onClick={handleCompanyEdit} className="underline text-[13px] text-[#266df0]">Edit</Link>
          </div>
          {editCompany ?
            <>
              <AnimatePresence initial={false}>
                {!collapsedCompany && (
                  <motion.div
                    initial={{ height: 0 }}
                    animate={{ height: 'auto' }}
                    exit={{ height: 0, }}
                    transition={{ height: { duration: 0.5 }, }}
                    className="overflow-hidden"
                  >
                    <div className="">
                      <div className='w-full mt-5'>
                        <div className='flex gap-y-[24px] flex-col'>
                          <div className="relative">
                            <label
                              htmlFor="company_item_customer_name"
                              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                            >
                              Name
                            </label>
                            <input
                              id="company_item_customer_name"
                              name="company_item_customer_name"
                              type="text"
                              maxLength={30}
                              value={companyInfo?.item_customer[0]?.item_customer_name}
                              onChange={handleInputChange}
                              // placeholder="Jane Smith"
                              className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                            />
                          </div>
                          <div className="relative">
                            <label
                              htmlFor="company_item_customer_email_id"
                              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                            >
                              Email
                            </label>
                            <input
                              id="company_item_customer_email_id"
                              name="company_item_customer_email_id"
                              value={companyInfo?.item_customer[0]?.item_customer_email_id}
                              onChange={handleInputChange}
                              maxLength={30}
                              type="email"
                              // placeholder="test@123gmail.com"
                              className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                            />
                          </div>
                          <div className="relative">
                            <label
                              htmlFor="company_item_customer_unique_id"
                              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                            >
                              Unique Id
                            </label>
                            <input
                              id="company_item_customer_unique_id"
                              name="company_item_customer_unique_id"
                              type="text"
                              maxLength={30}
                              value={companyInfo.item_customer[0].item_customer_unique_id}
                              onChange={handleInputChange}
                              className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                            ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                            />
                          </div>
                          <div className="relative">
                            <label
                              htmlFor="company_item_customer_phone_number"
                              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                            >
                              Phone no
                            </label>
                            <input
                              id="company_item_customer_phone_number"
                              name="company_item_customer_phone_number"
                              value={companyInfo?.item_customer[0]?.item_customer_phone_number}
                              onChange={handleInputChange}
                              maxLength={30}
                              type="number"
                              // placeholder="1234567890"
                              className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                            />
                          </div>
                          <div className="relative">
                            <label
                              htmlFor="company_item_customer_meta"
                              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                            >
                              Meta
                            </label>
                            <input
                              id="company_item_customer_meta"
                              name="company_item_customer_meta"
                              value={companyInfo?.item_customer[0]?.item_customer_meta}
                              onChange={handleInputChange}
                              type="text"
                              maxLength={1000}
                              // placeholder="Enter meta value"
                              className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                            />
                          </div>
                          <div className="relative item-viewfeedback-label">
                            <label
                              htmlFor="company_item_customer_label"
                              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                            >
                              Label
                            </label>
                            <TagsInput
                              value={companyInfo?.item_customer[0]?.item_customer_label ? companyInfo.item_customer[0].item_customer_label.split(',').filter(Boolean) : []}
                              onChange={(tags) => handleInputChange(tags)}
                              id="company_item_customer_label"
                              name="company_item_customer_label"
                            />
                          </div>
                          <div className="relative">
                            <label
                              htmlFor="company_item_customer_revenue"
                              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                            >
                              Revenue
                            </label>
                            <input
                              id="company_item_customer_revenue"
                              name="company_item_customer_revenue"
                              value={companyInfo?.item_customer[0]?.item_customer_revenue}
                              onChange={handleInputChange}
                              maxLength={30}
                              type="text"
                              // placeholder="Enter Revenue"
                              className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                            />
                          </div>
                          <div className='w-auto flex justify-end'>
                            <Button
                              type="button"
                              className="ml-2 bg-indigo-500 h-[32px] hover:bg-indigo-600 focus-visible:outline-indigo-600 w-auto
                              [&>span]:flex [&>span]:items-center [&>span]:gap-[6px]"
                              size="sm"
                              onClick={() => handleSubmitItemCustomer(companyInfo)}
                              disabled={isUpdating}
                            >
                              {isUpdating ? (
                                <>
                                  Saving
                                  <div className="simple-spinner">
                                    <span></span>
                                  </div>
                                </>
                              ) : (
                                'Save'
                              )}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </>
            :
            <>
              {
                !editCompany &&

                <AnimatePresence initial={false}>
                  {!collapsedCompany && (
                    <motion.div
                      initial={{ height: 0 }}
                      animate={{ height: 'auto' }}
                      exit={{ height: 0, }}
                      transition={{ height: { duration: 0.5 }, }}
                      className="overflow-hidden"
                    >
                      <div className="w-full">
                        <div className="w-full flex items-center justify-between lg:justify-start py-1">
                          <div className="mr-2 py-1 px-2 font-normal text-dark-black-200 text-[13px] w-[30%]">
                            Name
                          </div>
                          <div className="relative w-[70%]">
                            <button
                              className="relative justify-center flex items-center cursor-pointer rounded-md bg-white py-1 px-2 text-left text-[12px] text-[#52555a]
                               font-medium bg-transparent border-solid hover:!border-light-gray-200 border border-white"
                            >
                              <div className="flex items-center gap-1">
                                <span className="flex h-[8px] shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-white capitalize w-0 mr-[-4px]" />
                                <span className="block truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full  overflow-hidden text-ellipsis text-start font-medium">
                                  {data[0]?.item_company?.item_customer_name}
                                </span>
                              </div>
                            </button>
                          </div>
                        </div>
                        <div className="w-full flex items-center justify-between lg:justify-start py-1">
                          <div className="mr-2 py-1 px-2 font-normal text-dark-black-200 text-[13px] w-[30%]">
                            Email
                          </div>
                          <div className="relative w-[70%]">
                            <button
                              className="relative justify-center flex items-center cursor-pointer rounded-md bg-white py-1 px-2 text-left  text-[12px] text-[#52555a] 
                              font-medium bg-transparent  border-solid hover:!border-light-gray-200 border border-white"
                            >
                              <div className="flex items-center gap-1">
                                <span className="flex h-[8px] shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-white capitalize w-0 mr-[-4px]" />
                                <span className="truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full  overflow-hidden text-ellipsis text-start">
                                  {data[0]?.item_company?.item_customer_email_id}
                                </span>
                              </div>
                            </button>
                          </div>
                        </div>
                        <div className="w-full flex items-center justify-between lg:justify-start py-1">
                          <div className="mr-2 py-1 px-2 font-normal text-dark-black-200 text-[13px] w-[30%]">
                            Unique Id
                          </div>
                          <div className="relative w-[70%]">
                            <button
                              className="relative justify-center flex items-center cursor-pointer rounded-md bg-white py-1 px-2 text-left  text-[12px] text-[#52555a] 
                              font-medium bg-transparent  border-solid hover:!border-light-gray-200 border border-white"
                            >
                              <div className="flex items-center gap-1">
                                <span className="block truncate  text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full  overflow-hidden text-ellipsis text-start">
                                  {data[0]?.item_company?.item_customer_unique_id}

                                </span>
                              </div>
                            </button>
                          </div>
                        </div>
                        <div className="w-full flex items-center justify-between lg:justify-start py-1">
                          <div className="mr-2 py-1 px-2 font-normla text-dark-black-200 text-[13px] w-[30%]">
                            Phone
                          </div>
                          <div className="relative w-[70%]">
                            <button
                              className="relative justify-center flex items-center cursor-pointer rounded-md bg-white py-1 px-2 text-left text-[12px] text-[#52555a]
                               font-medium bg-transparent border-solid hover:!border-light-gray-200 border border-white"
                            >
                              <div className="flex items-center gap-1">
                                <span className="block truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full  overflow-hidden text-ellipsis text-start">
                                  {data[0]?.item_company?.item_customer_phone_number}
                                </span>
                              </div>
                            </button>
                          </div>
                        </div>
                        <div className="w-full flex items-center justify-between lg:justify-start py-1">
                          <div className="mr-2 py-1 px-2 font-normla text-dark-black-200 text-[13px] w-[30%]">
                            Meta
                          </div>
                          <div className="relative w-[70%]">
                            <button
                              className="relative justify-center flex items-center cursor-pointer rounded-md bg-white py-1 px-2 text-left text-[12px] text-[#52555a]
                               font-medium bg-transparent border-solid hover:!border-light-gray-200 border border-white"
                            >
                              <div className="flex items-center gap-1">
                                <span className="block truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full  overflow-hidden text-ellipsis text-start">
                                  {data[0]?.item_company?.item_customer_meta}
                                </span>
                              </div>
                            </button>
                          </div>
                        </div>
                        <div className="w-full flex items-center justify-between lg:justify-start py-1">
                          <div className="mr-2 py-1 px-2 font-normla text-dark-black-200 text-[13px] w-[30%]">
                            Label
                          </div>
                          <div className="relative w-[70%]">
                            <button
                              className="relative justify-center flex items-center cursor-pointer rounded-md bg-white py-1 px-2 text-left text-[12px] text-[#52555a]
                               font-medium bg-transparent border-solid hover:!border-light-gray-200 border border-white"
                            >
                              <div className="flex items-center gap-1">
                                <span className="block truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full  overflow-hidden text-ellipsis text-start">
                                  {data[0]?.item_company?.item_customer_label}
                                </span>
                              </div>
                            </button>
                          </div>
                        </div>
                        <div className="w-full flex items-center justify-between lg:justify-start py-1">
                          <div className="mr-2 py-1 px-2 font-normla text-dark-black-200 text-[13px] w-[30%]">
                            Revenue
                          </div>
                          <div className="relative w-[70%]">
                            <button
                              className="relative justify-center flex items-center cursor-pointer rounded-md bg-white py-1 px-2 text-left text-[12px] text-[#52555a]
                               font-medium bg-transparent border-solid hover:!border-light-gray-200 border border-white"
                            >
                              <div className="flex items-center gap-1">
                                <span className="block truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full  overflow-hidden text-ellipsis text-start">
                                  {data[0]?.item_company?.item_customer_revenue}
                                </span>
                              </div>
                            </button>
                          </div>
                        </div>

                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              }
            </>


          }



        </div>
        <div className=' mx-[-24px] min-w-[340px] w-full'>
          <div className="w-full border-t border-l-0  border-solid border-light-gray-200 px-[1.5rem] pt-[1rem]">
            <div className="w-full flex items-center justify-between lg:justify-start py-1">
              <div className="mr-2 py-1 px-0 font-normal text-dark-black-200 text-[13px] w-[35%]">
                Created
              </div>
              <div className="relative w-[65%]">
                <span className="text-[13px] leading-4 whitespace-normal max-w-[140px] w-full text-[#52555a] text-start break-words font-medium">
                  {createdTimeAgoSidebar} ago
                </span>
              </div>
            </div>
            {createdTimeAgoSidebar !== updatedTimeAgoSidebar && (
              <div className="w-full flex items-center justify-between lg:justify-start py-1">
                <div className="mr-2 py-1 px-0 font-normal text-dark-black-200 text-[13px] w-[35%]">
                  Updated
                </div>
                <div className="relative w-[65%]">
                  <span className="text-[13px] leading-4 whitespace-normal max-w-[140px] w-full text-[#52555a] text-start break-words font-medium">
                    {updatedTimeAgoSidebar} ago
                  </span>
                </div>
              </div>)}
            {
              data[0].item_source_type &&
              <div className="w-full flex items-center justify-between lg:justify-start py-1">
                <div className="mr-2 py-1 px-0 font-normal text-dark-black-200 text-[13px] w-[35%]">
                  Source
                </div>
                <div className="relative w-[65%]">
                  <span className=" text-[13px] leading-4 whitespace-normal max-w-[140px] w-full text-[#52555a]  text-start break-words font-medium">
                    {data[0].item_source_type}
                  </span>
                </div>
              </div>
            }

            {
              data[0]?.item_source_id &&
              <div className="w-full flex items-start justify-between lg:justify-start py-1">
                <div className="mr-2 py-0 px-0 font-normal text-dark-black-200 text-[13px] w-[35%] leading-inherit">
                  Source Id
                </div>
                <div className="relative w-[65%]">
                  <span className=" text-[13px] leading-4 whitespace-normal max-w-[140px] w-full text-[#52555a]  text-start break-words font-medium">
                    {data[0].item_source_id}
                  </span>
                </div>
              </div>
            }

          </div>
        </div>
        <div className='flex justify-start w-full'>





          <Link to="" 
          // onClick={() => handleCustomerDelete(id)} 
          onClick={(id) => handleConfirmDelete(id)}
          className="underline text-[13px] text-[#b91c1c]">Delete</Link>
        </div>
      </aside>
      <Transition.Root show={showDeleteConfirmation} as={Fragment}>
        <Dialog as="div" className="relative z-50 md:z-10 lg:z-10" onClose={() => setShowDeleteConfirmation(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center sm:items-start h-full items-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[500px] sm:p-6">
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to delete your Feedback?
                    </p>
                  </div>
                  <div className="mt-5 sm:mt-4 flex gap-2 justify-end">
                    <Button
                      type="button"
                      onClick={handleCustomerDelete}
                      className="bg-red-600 hover:bg-red-500 focus-visible:outline-red-600 relative [&>span]:flex [&>span]:items-center [&>span]:gap-[8px]"
                      disabled={deleteLoading}
                    >
                      {deleteLoading ? (
                        <>
                          Delete
                          <div className="simple-spinner">
                            <span></span>
                          </div>
                        </>
                      ) : (
                        'Delete'
                      )}
                    </Button>
                    <button
                      type="button"
                      onClick={() => setShowDeleteConfirmation(false)}
                      className="mt-3 inline-flex leading-6 w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default CustomerFeedback;
