import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux";
import { userLogin } from "./loginSlice";
import { AppDispatch } from "../../../store/store";
import AlertPopup from "../../../components/AlertPopup"
import { Link } from 'react-router-dom';
import { formFieldsLogin } from "../formFields";
import { Button, Input } from "@lemonsqueezy/wedges";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import FeedbackLogo from "../../../assets/feedback.png"


export default function Login() {
  const dispatch = useDispatch<AppDispatch>()
  const history = useNavigate()
  const [showAlert, setShowAlert] = useState(false)
  const [data, setData] = useState<any>()
  const [disable, setDisable] = useState(false)
  const [showPassword, setShowPassword] = useState(false);

  //login
  const handle_login = (e: any) => {
    setDisable(true)
    e.preventDefault();
    dispatch(userLogin({ email: e.target[0].value, password: e.target[1].value, deviceId: "123123", deviceName: "WEB" }))
      .then(async (res) => {
        if (res?.payload?.status === 201) {
          await localStorage.setItem("token", res?.payload?.data?.token)
          history("/otp")
        } else if (res?.payload?.status === 200) {
          await localStorage.setItem("authorization", res?.payload?.data?.token)
          await localStorage.setItem("userName", res?.payload?.data?.user?.name)
          await localStorage.setItem("userEmail", res?.payload?.data?.user?.email)
          await localStorage.setItem("userAuth", res?.payload?.data?.user?.two_auth)
          await localStorage.setItem("userAuthId", res?.payload?.data?.user?.id)
          await localStorage.setItem("userAuthStatus", res?.payload?.data?.user?.status)
          history("/base")
        } else {
          setData(res?.payload?.data)
          setShowAlert(true)
        }
      }).catch((err) => {
        console.log("check", err)
      }).finally(() => {
        setDisable(false)
      })
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (<>
    {/* <Nav /> */}
    <div className="main-login-page">
    <div className="flex min-h-full flex-1 flex-col justify-center max-w-[487px] m-auto px-4">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <img src={FeedbackLogo} alt="" className='max-w-[208px] w-full m-auto'/>
        <h2 className="mt-12 text-center font-bold leading-9 text-dark-black-100 text-[24px]">
          Welcome back
        </h2>
        <div className="mt-6 grid grid-cols-1 gap-4">
          <Link to="#" className="flex w-full items-center h-[40px] justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 border border-solid border-[#e7e7e8]">
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth={0}
              viewBox="0 0 488 512"
              focusable="false"
              className=""
              aria-hidden="true"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" />
            </svg>
            <span className="text-[0.8125rem] font-semibold leading-6">Continue with Google</span>
          </Link>
        </div>
        <div className="relative mt-4">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-light-gray-200" />
          </div>
          <div className="relative flex justify-center text-[13px] font-normal leading-6">
            <span className="bg-white px-2 text-dark-black-200"> Or continue with </span>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <div className="bg-white">
          <form className="space-y-4" action="#" method="POST" onSubmit={(e) => handle_login(e)}>
            {formFieldsLogin.map(field => (
              <div key={field.id}>
                <label htmlFor={field.id} className="text-[0.875rem] block mb-2 font-medium text-dark-black-100">
                 { field.label}
                </label>
                <div className="mt-2 relative">
                  <Input
                    id={field.id}
                    name={field.id}
                    type={field.id === "password" && showPassword ? "text" : field.type}
                    autoComplete={field.autoComplete}
                    required={field.required}
                    className="focus:outline-indigo-600 h-[36px]  rounded-[0.375rem]"
                  />
                  {field.id === "password" && (
                    <span
                      className="absolute inset-y-0 right-[4px] px-2 h-[28px] top-1 flex items-center cursor-pointer hover:bg-light-gray-300 rounded"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <EyeIcon className="w-[1rem] h-[1rem]" /> : <EyeSlashIcon className="w-[1rem] h-[1rem]" />}
                    </span>
                  )}
                </div>
              </div>
            ))}


            <div className="flex items-center justify-between flex-wrap">
              {/* <div className="flex items-center gap-2">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <label htmlFor="remember-me" className="wg-label inline-flex shrink-0 items-center gap-1 text-surface-900 wg-antialiased">
                 Remember me
                </label>
              </div> */}
              <div className="text-sm leading-6">

                <Link to="/forgotpassword" className="font-normal text-dark-black-100 text-[13px] hover:underline">
                Forgot Password
                </Link>
              </div>
            </div>
            <div className="flex justify-end">
              <Button
                disabled={disable}
                type="submit"
                className=" bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600 w-full py-[6px] rounded-[0.375rem] text-[0.8125rem] font-semibold"
                size="sm"
              >

                Log in
              </Button>
            </div>
          </form>
          <div>
          </div>
        </div>
        <p className="mt-4 text-center text-[13px] text-[#0000007a]">
          No account yet?
          <Link to="/signup" className="text-dark-black-100 leading-[1.5] ml-[2px]">
          SignUp
          </Link>
        </p>
      </div>
    </div>
    </div>
    <AlertPopup data={data} show={showAlert} setShow={() => setShowAlert(false)} />
  </>
  )
}

