import React, { useState } from "react"
import { user_forgotpass } from './forgotPassAPI';
import { useNavigate } from "react-router-dom";
import { Button, Input } from "@lemonsqueezy/wedges";
import { Link } from "react-router-dom";
import FeedbackLogo from "../../../assets/feedback.png"

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const navigate = useNavigate()


    const validateEmail = (email: any) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleInputChange = (e: any) => {
        const { value } = e.target;
        setEmail(value);
        if (!validateEmail(value)) {
            setEmailError("Please enter a valid email address");
        } else {
            setEmailError("");
        }
    };

    const handle_forgotPass = (e: any) => {
        e.preventDefault();
        if (validateEmail(email)) {
            user_forgotpass(email)
                .then((res) => {
                    if (res.status === 200) {
                        navigate("/resetPassword");
                    }
                })
                .catch((err) => {
                    if (err.response && err.response.status === 400) {
                        setErrorMessage("Account does not exist");
                    } else {
                        console.log("check", err);
                    }
                });
        } else {
            setEmailError("Please enter a valid email address");
        }
    };

    return (<>
        <div className="flex min-h-full flex-1 flex-col justify-center max-w-[487px] m-auto px-4">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img src={FeedbackLogo} alt="" className='max-w-[208px] w-full m-auto'/>

                <h2 className="mt-12 text-center font-bold leading-9 text-dark-black-100 text-[24px]">
                    Reset your password
                </h2>

            </div>

            <div className="mt-4">
                <div className="bg-white">
                    <form className="space-y-4" action="#" method="POST" onSubmit={(e) => handle_forgotPass(e)}>
                        <div>
                            <label htmlFor="email" className="text-[0.875rem] block mb-2 font-medium text-dark-black-100">
                            Email
                            </label>
                            <div className="mt-2">
                                <Input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    value={email}
                                    onChange={handleInputChange}
                                    required
                                    className="focus:outline-indigo-600 h-[36px]  rounded-[0.375rem]"
                                />
                                {emailError && <p className="text-red-500 text-sm mt-1">{emailError}</p>}
                            </div>
                        </div>
                        {errorMessage && <p className="text-red-500 text-sm">{errorMessage}</p>}

                        <div className="flex justify-end">
                            <Button
                                type="submit"
                                className=" bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600 w-full py-[6px] rounded-[0.375rem] text-[0.8125rem] font-semibold"
                                size="sm"
                            >
                             Submit
                            </Button>
                        </div>
                        <p className="mt-4 text-center text-[14px] text-[#0000007a]">

                            <Link to="/login" className="text-dark-black-100 leading-[1.5] ml-[2px] hover:underline">
                                Back to log in
                            </Link>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    </>)
}
export default ForgotPassword