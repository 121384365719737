// projectBoardActionTypes.ts

export const CREATE_BOARD_REQUEST = 'CREAT_BOARD_REQUEST';
export const CREATE_BOARD_SUCCESS = 'CREATE_BOARD_SUCCESS';
export const CREATE_BOARD_FAILURE = 'CREATE_BOARD_FAILURE';


// Add new action types for fetching the project board list
export const FETCH_BOARD_LIST_REQUEST = 'FETCH_BOARD_LIST_REQUEST';
export const FETCH_BOARD_LIST_SUCCESS = 'FETCH_BOARD_LIST_SUCCESS';
export const FETCH_BOARD_LIST_FAILURE = 'FETCH_BOARD_LIST_FAILURE';

// for delete board
export const DELETE_BOARD_REQUEST = 'DELETE_BOARD_REQUEST';
export const DELETE_BOARD_SUCCESS = 'DELETE_BOARD_SUCCESS';
export const DELETE_BOARD_FAILURE = 'DELETE_BOARD_FAILURE';


// board by id 
export const FETCH_BOARD_BY_ID_REQUEST = 'FETCH_BOARD_BY_ID_REQUEST';
export const FETCH_BOARD_BY_ID_SUCCESS = 'FETCH_BOARD_BY_ID_SUCCESS';
export const FETCH_BOARD_BY_ID_FAILURE = 'FETCH_BOARD_BY_ID_FAILURE';


// update board
export const UPDATE_BOARD_REQUEST = 'UPDATE_BOARD_REQUEST';
export const UPDATE_BOARD_SUCCESS = 'UPDATE_BOARD_SUCCESS';
export const UPDATE_BOARD_FAILURE = 'UPDATE_BOARD_FAILURE';
