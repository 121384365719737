// @ts-nocheck

import React, { Fragment, useEffect, useState } from 'react';
import { Bars3Icon, PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from '@lemonsqueezy/wedges';
import { Dialog, Label, Listbox, Transition } from '@headlessui/react';
import { useDispatch } from 'react-redux';
import { Toaster, toast } from 'sonner';
import { useLocation, useNavigate } from 'react-router-dom';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { DialogPanel, DialogTitle } from '@headlessui/react'
import { fetchInsightGroupListData, submitInsightGroup } from '../../insights/insight-redux/insight.actions';
import debounce from 'lodash/debounce';
import { submitIntegrationGroup } from '../../integration/integration-redux/integration.actions';
import {
  PaginationState
} from '@tanstack/react-table'
import { useSearch } from '../../../context/SearchContext';

interface Board {
  id: number;
  name: string;
  color: string; 
}


interface Priority {
  id: number;
  name: string;
}

interface Sentiment {
  id: number;
  name: string;
}

interface HeaderProps {
  onClick: () => void;
  id: number | null;
  selectedTab: string;
  baseMemberDetails: any[];
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}
  // priority

  const priority: Priority[] = [
    { id: 1, name: 'Urgent' },
    { id: 2, name: 'High' },
    { id: 3, name: 'Medium' },
    { id: 4, name: 'Low' },
  ];

  localStorage.setItem('priorities', JSON.stringify(priority));

  // sentiment

  const sentiment: Sentiment[] = [
    { id: 101, name: 'positive', label: '#16a34a33' },
    { id: 102, name: 'negative', label: '#dc26261a' },
    { id: 103, name: 'spam', label: '#6b72801a' },
    { id: 104, name: 'mixed', label: '#1d4ed81a' },
  ];

  localStorage.setItem('sentiments', JSON.stringify(sentiment));


  const integrationStatus: Priority[] = [
    { id: 1, name: 'active', label: '#22c55e' },
    { id: 2, name: 'inactive', label: '#4b5563' },
    { id: 3, name: 'delete', label: '#eb4938' },
  ];

  localStorage.setItem('integrationStatus', JSON.stringify(integrationStatus));



  const selectInsightGroupType = [
    { id: 1, name: 'Positive', icon: <span className="text-[#1d2025] w-2 h-2 rounded-full bg-[#16a34a33] block" /> },
    { id: 2, name: 'Negative', icon: <span className="text-[#1d2025] w-2 h-2 rounded-full bg-[#dc26261a] block" /> },
    { id: 3, name: 'Request', icon: <span className="text-[#1d2025] w-2 h-2 rounded-full bg-[#1d4ed81a] block" /> },
  ]

  const selectIntegrationType = [
    { id: 1, name: 'Widget' },
    { id: 2, name: 'Api' },
    { id: 3, name: 'Survey' },
    { id: 4, name: 'Email' },
    { id: 5, name: 'Play Store' },
    { id: 6, name: 'App Store' }
  ]



const Header: React.FC<HeaderProps> = ({ onClick, id, selectedTab, onSearchChange }) => {
  const dispatch = useDispatch<any>();
  const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;
  const [groupInsightOpen, setGroupInsightOpen] = useState(false);
  const [name, setName] = useState("")
  const [nameError, setNameError] = useState<string>("");
  const location = useLocation();
  const [nameData, setNameData] = useState<string | undefined>(undefined);
  const [selectInsightGroup, setSelectInsightGroup] = useState(selectInsightGroupType[0]);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [isIntegrationVisible, setIsIntegrationVisible] = useState(false);
  const [dashboard, setDashboard] = useState(false)
  const [isInboxVisible, setIsInboxVisible] = useState(false);
  const [integrationOpen, setIntegrationOpen] = useState(false)
  const [IntegrationName, setIntegrationName] = useState("")
  const [IntegrationDetails, setIntegrationDetails] = useState("")
  const [IntegrationNameError, setIntegrationNameError] = useState<string>("");
  const [IntegrationDetailsError, setIntegrationDetailsError] = useState<string>("");
  const [selectIntegration, setSelectIntegration] = useState(selectIntegrationType[0]);
  const [insightData, setInsightData] = useState<any[]>([]);
  const { searchTerm, setSearchTerm } = useSearch();
  const [boardsShow, setBoardsShow] = useState(false)
  const [Insightpagination, setInsightPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 25,
    currentPage: 1,
    hasNextPage: false,
    totalPages: 1,
    totalIntegration: 0,
  })

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()


  const handleGroupInsightModal = () => {
    setGroupInsightOpen(true);
  };

  const handleIntegrationModal = () => {
    setIntegrationOpen(true)
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value); 
  };


  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      onSearchChange(searchTerm); 
      setSearchTerm(''); 
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    if (name === 'name') {
      setName(value);
      if (value.trim().length === 0) {
        setNameError("Name is required");
      } else {
        setNameError("");
      }
    } else if (name === 'integration name') {
      setIntegrationName(value);
      if (value.trim().length === 0) {
        setIntegrationNameError("Name is required");
      } else {
        setIntegrationNameError("");
      }
    } else if (name === 'integration details') {
      setIntegrationDetails(value);
      setIntegrationDetailsError("");
    }
  };



  const createInsightGroup = async (e: React.FormEvent) => {
    e.preventDefault();

    if (name.trim().length === 0) {
      setNameError("Name is required");
      return;
    }

    const lowerCaseRoleName = selectInsightGroup.name.toLowerCase();

    try {
      const result = await dispatch(submitInsightGroup(baseId, {
        insight_group: name,
        insight_group_sentiment: lowerCaseRoleName,
      }) as any);

      setName('');
      setSelectInsightGroup(selectInsightGroupType[0]);

      if (result.data && result.status === 200 && result.message === 'Success') {
        setGroupInsightOpen(false);
        toast.success("Insight group created successfully");

        try {
          const response = await dispatch(fetchInsightGroupListData(baseId));
          if (response.insight_groups && response.currentPage) {
            const newData = response.insight_groups;
            const newPagination = {
              currentPage: response.currentPage,
              hasNextPage: response.hasNextPage,
              totalPages: response.totalPages,
              pageSize: response.pageSize,
              pageIndex: response.currentPage - 1,
              total: response.totalInsights,
            };

            setInsightData(newData);
            setInsightPagination(newPagination);
            localStorage.setItem('groupListInsightData', JSON.stringify({
              data: newData,
              pagination: newPagination,
              timestamp: Date.now(),
            }));

          } else {
            toast.error('Unexpected response format');
          }
        } catch (fetchError) {
          toast.error('An error occurred while fetching the insight group list data');
        }
      } else {
        toast.error("Failed to create insight group");
      }
    } catch (error) {
      toast.error('An error occurred while creating the insight group');
    }
  };


  const createNewIntegration = async (e: React.FormEvent) => {
    e.preventDefault();

    if (IntegrationName.trim().length === 0) {
      setIntegrationNameError("Name is required");
      return;
    }

    setLoading(true);

    const lowerCaseRoleName = selectIntegration.name.toLowerCase();

    const payload: {
      base_id: number;
      integration_name: string;
      integration_type: string;
      integration_detail?: string;
    } = {
      base_id: baseId,
      integration_name: IntegrationName,
      integration_type: lowerCaseRoleName,
    };

    if (IntegrationDetails.trim().length > 0) {
      payload.integration_detail = IntegrationDetails;
    }

    try {
      const result = await dispatch(submitIntegrationGroup(baseId, payload) as any);

      setIntegrationName('');
      setIntegrationDetails('');
      setSelectIntegration(selectIntegrationType[0]);
      if (result.data && result.status === 200 && result.message === 'Success') {
        setIntegrationOpen(false);
        toast.success("Integration created successfully");
        navigate(`/integrations/${result?.data?.id}`)
      } else {
        toast.error("Failed to create integration");
      }
    } catch (error) {
      toast.error('An error occurred while creating the integration');
    } finally {
      setLoading(false);
    }
  };

  const updateVisibility = debounce(() => {
    const isInsightsPage = window.location.pathname.includes('insights');
    const isInboxPage = window.location.pathname.includes('inbox');
    const isIntegrationPage = window.location.pathname.includes('integration');
    const isDashboard = window.location.pathname.includes('dashboard')
    const isBoards = window.location.pathname.includes('board')

    setIsButtonVisible(selectedTab === 'Insights' || isInsightsPage);
    setIsIntegrationVisible(selectedTab === 'Integration' || isIntegrationPage);
    setIsInboxVisible(selectedTab === 'Inbox' || isInboxPage);
    setBoardsShow(selectedTab === 'Board' ||  isBoards)
  }, 100);

  useEffect(() => {
    updateVisibility();
  }, [selectedTab, location.pathname]);


  useEffect(() => {
    const path = location.pathname;
    const boardId = path.split('/').pop();

    if (boardId) {
      const storedChannelData = JSON.parse(localStorage.getItem('channelData') || '[]');
      const matchedBoard = storedChannelData?.find((board: { id: number }) => board.id.toString() === boardId);
      if (matchedBoard) {
        setNameData(matchedBoard.name);
      } else {
        setNameData(boardId)
      }
    }
  }, [location]);

  return (

    <>
      <Toaster richColors />
      {isInboxVisible ? '' :
        <div className="sticky top-0" style={{ zIndex: '0' }}>
          <div className="flex h-[56px] shrink-0 items-center gap-x-4 bg-white px-4 shadow-custom-1 sm:gap-x-6 sm:px-6 lg:px-4">
            <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={onClick}>
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />
            <div className="flex items-center justify-between w-full">
              <h2 className="text-black-100 font-semibold text-sm capitalize">{nameData}</h2>
              {/* search bar */}
              <div className='flex items-center gap-2'>
                {isIntegrationVisible && (
                  <Button
                    type="submit"
                    className="bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600 justify-start [&>span]:flex [&>span]:items-center [&>span]:gap-[6px] text-[13px] font-medium"
                    size="sm"
                    onClick={handleIntegrationModal}
                  >
                    <PlusCircleIcon className='w-[14px] h-[14px]' />
                    Create Integration
                  </Button>
                )}
                {isButtonVisible && !isIntegrationVisible && (
                  <Button
                    type="submit"
                    className="bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600 justify-start [&>span]:flex [&>span]:items-center [&>span]:gap-[6px] text-[13px] font-medium"
                    size="sm"
                    onClick={handleGroupInsightModal}
                  >
                    <PlusCircleIcon className='w-[14px] h-[14px]' />
                    Create Insight
                  </Button>
                )}
                {!boardsShow && !dashboard &&
                  !isIntegrationVisible && (
                    <>
                      <div className="flex gap-x-4 self-stretch lg:gap-x-6 px-[0.75rem]">
                        <form className="relative flex flex-1" onSubmit={handleSubmit}>
                          <label htmlFor="search-field" className="sr-only">
                            Search
                          </label>
                          <MagnifyingGlassIcon
                            className="pointer-events-none absolute inset-y-0 left-1.5 h-full w-4 text-[#000000eb]"
                            aria-hidden="true"
                          />
                          <input
                            className="w-full h-[32px] border border-solid border-[#00000014] rounded-md pl-7 text-[0.8125rem]"
                            placeholder="Search"
                            type="text"
                            value={searchTerm}
                            onChange={handleSearchChange} 
                          />
                        </form>


                      </div>
                    </>
                  )
                }
              </div>
              

            </div>
          </div>
        </div>
      }



      <Transition show={groupInsightOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 md:z-10 lg:z-10" onClose={() => setGroupInsightOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center sm:items-start h-full items-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel
                  transition
                  className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                >
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      onClick={() => setGroupInsightOpen(false)}
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                    </button>
                  </div>
                  <div className="">

                    <div className="mt-[-6px] w-full">
                      <DialogTitle as="h3" className="text-sm font-semibold leading-6 text-gray-900">
                        Create Insight Group
                      </DialogTitle>
                      <div className="mt-2">
                        <form action="#" onSubmit={(e) => createInsightGroup(e)} className="space-y-4 mt-8">


                          <div>
                            <label htmlFor="text" className="wg-label inline-flex shrink-0 
                            items-center gap-1 text-surface-900 wg-antialiased text-[13px] capitalize">
                              Insight Group name
                            </label>
                            <div className="mt-1">
                              <input
                                id="text"
                                name='name'
                                type='text'
                                autoComplete="text"
                                value={name}
                                onChange={(e) => handleChange(e)}
                                placeholder='Enter Name'
                                className="flex grow rounded-lg border bg-background 
                                w-full px-4 focus:outline-indigo-600 py-[6px] pl-3
                                h-[36px] text-[13px] leading-6 shadow-wg-xs transition-colors duration-100 placeholder:text-surface-500 outline-primary focus:outline focus:outline-2 focus:-outline-offset-1 text-surface-900 hover:border-surface-300 dark:hover:border-surface-200 border-surface-200 dark:border-surface-100"
                              />
                              <p className="mt-1 text-[13px] text-red-600" id="purpose-error">
                                {nameError}
                              </p>
                            </div>
                          </div>
                          <div className='flex flex-col gap-1 wg-antialiased'>
                            <Listbox value={selectInsightGroup} onChange={setSelectInsightGroup}>
                              {({ open }) => (
                                <>
                                  <Listbox.Label className="
                                  wg-label inline-flex shrink-0 
                                  items-center gap-1 text-surface-900 wg-antialiased text-[13px] capitalize
                                  ">Insight group sentiment</Listbox.Label>
                                  <div className="relative">
                                    <Listbox.Button className="relative w-full cursor-default h-[36px] rounded-lg bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                      <span className="truncate flex items-center gap-[6px] text-[13px]">
                                        {selectInsightGroup.icon}
                                        {selectInsightGroup.name}
                                      </span>
                                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-gray-400">
                                          <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                        </svg>
                                      </span>
                                    </Listbox.Button>

                                    <Transition
                                      show={open}
                                      as={Fragment}
                                      leave="transition ease-in duration-100"
                                      leaveFrom="opacity-100"
                                      leaveTo="opacity-0"
                                    >
                                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        {selectInsightGroupType.map((option) => (
                                          <Listbox.Option
                                            key={option.id}
                                            className={({ active }) =>
                                              classNames(
                                                active ? 'bg-gray-200 text-gray-900 text-[13px]' : 'text-gray-900 text-[13px]',
                                                'relative cursor-default select-none py-[6px] pl-3 pr-9 text-[13px]'
                                              )
                                            }
                                            value={option}
                                          >
                                            {({ selected }) => (
                                              <>
                                                <span className={classNames(selected ? 'font-semibold flex items-center gap-[6px] text-[13px]' : 'font-normal flex items-center gap-[6px] text-[13px]',
                                                  'flex items-center gap-[6px] truncate')}>
                                                  {option.icon}
                                                  {option.name}
                                                </span>
                                              </>
                                            )}
                                          </Listbox.Option>
                                        ))}
                                      </Listbox.Options>
                                    </Transition>
                                  </div>
                                </>
                              )}
                            </Listbox>

                          </div>
                          <div className="!mt-[3.5rem] !sm:mt-[10rem] sm:flex sm:flex-row-reverse">
                            <button
                              type="submit"
                              className="inline-flex w-full justify-center rounded-md bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600 px-3 py-2 text-sm font-semibold
                 text-white shadow-sm sm:ml-3 sm:w-auto"
                            >
                              Save
                            </button>
                            <button
                              type="button"
                              onClick={() => setGroupInsightOpen(false)}
                              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900
                 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            >
                              Cancel
                            </button>
                          </div>

                        </form>
                      </div>
                    </div>
                  </div>

                </DialogPanel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>


      <Transition show={integrationOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 md:z-10 lg:z-10" onClose={() => setIntegrationOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center sm:items-start h-full items-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel
                  transition
                  className="relative transform overflow-auto rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                >
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      onClick={() => setIntegrationOpen(false)}
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                    </button>
                  </div>
                  <div className="">

                    <div className="mt-[-6px] w-full">
                      <DialogTitle as="h3" className="text-sm font-semibold leading-6 text-gray-900">
                        Create New Integration
                      </DialogTitle>
                      <div className="mt-2">
                        <form action="#" onSubmit={(e) => createNewIntegration(e)} className="space-y-4 mt-8">
                          <div>
                            <label htmlFor="text" className="wg-label inline-flex shrink-0 
                            items-center gap-1 text-surface-900 wg-antialiased text-[13px] capitalize">
                              Integration name
                            </label>
                            <div className="mt-1">
                              <input
                                id="integration-name"
                                name='integration name'
                                type='text'
                                autoComplete="text"
                                value={IntegrationName}
                                onChange={handleChange}
                                placeholder='Enter Integration Name'
                                className="flex grow rounded-lg border bg-background 
                                      w-full px-4 focus:outline-indigo-600 py-[6px] pl-3
                                    h-[36px] text-[13px] leading-6 shadow-wg-xs transition-colors duration-100 placeholder:text-surface-500 outline-primary focus:outline focus:outline-2 focus:-outline-offset-1 text-surface-900 hover:border-surface-300 dark:hover:border-surface-200 border-surface-200 dark:border-surface-100"
                              />
                              <p className="mt-1 text-[13px] text-red-600" id="integration-name-error">
                                {IntegrationNameError}
                              </p>
                            </div>
                          </div>
                          <div>
                            <label htmlFor="integration-details" className="wg-label inline-flex shrink-0 
                            items-center gap-1 text-surface-900 wg-antialiased text-[13px] capitalize">
                              Integration Details
                            </label>
                            <div className="mt-1">
                              <textarea
                                id="integration-details"
                                name="integration details" 
                                rows={2}
                                autoComplete="text"
                                value={IntegrationDetails}
                                onChange={handleChange}
                                placeholder='Enter Integration Details'
                                className="flex grow rounded-lg border bg-background 
                                      w-full px-4 focus:outline-indigo-600 py-[6px] pl-3
                                    text-[13px] leading-6 shadow-wg-xs transition-colors duration-100 placeholder:text-surface-500 outline-primary focus:outline focus:outline-2 focus:-outline-offset-1 text-surface-900 hover:border-surface-300 dark:hover:border-surface-200 border-surface-200 dark:border-surface-100"
                              />

                            </div>
                          </div>
                          <div className='flex flex-col gap-1 wg-antialiased'>
                            <Listbox value={selectIntegration} onChange={setSelectIntegration}>
                              {({ open }) => (
                                <>
                                  <Listbox.Label className="
                                  wg-label inline-flex shrink-0 
                                  items-center gap-1 text-surface-900 wg-antialiased text-[13px] capitalize
                                  ">Integration Type</Listbox.Label>
                                  <div className="relative">
                                    <Listbox.Button className="relative w-full cursor-default h-[36px] rounded-lg bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                      <span className="truncate flex items-center gap-[6px] text-[13px]">
                                        {selectIntegration.name}
                                      </span>
                                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-gray-400">
                                          <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                        </svg>
                                      </span>
                                    </Listbox.Button>

                                    <Transition
                                      show={open}
                                      as={Fragment}
                                      leave="transition ease-in duration-100"
                                      leaveFrom="opacity-100"
                                      leaveTo="opacity-0"
                                    >
                                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full h-24 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        {selectIntegrationType.map((option) => (
                                          <Listbox.Option
                                            key={option.id}
                                            className={({ active }) =>
                                              classNames(
                                                active ? 'bg-gray-200 text-gray-900 text-[13px]' : 'text-gray-900 text-[13px]',
                                                'relative cursor-default select-none py-[6px] pl-3 pr-9 text-[13px]'
                                              )
                                            }
                                            value={option}
                                          >
                                            {({ selected }) => (
                                              <>
                                                <span className={classNames(selected ? 'font-semibold flex items-center gap-[6px] text-[13px]' : 'font-normal flex items-center gap-[6px] text-[13px]',
                                                  'flex items-center gap-[6px] truncate')}>
                                                  {option.name}
                                                </span>
                                              </>
                                            )}
                                          </Listbox.Option>
                                        ))}
                                      </Listbox.Options>
                                    </Transition>
                                  </div>
                                </>
                              )}
                            </Listbox>

                          </div>
                          <div className="!mt-[3.5rem] !sm:mt-[10rem] sm:flex sm:flex-row-reverse">
                            <button
                              type="submit"
                              className="inline-flex w-full justify-center rounded-md bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600 px-3 py-2 text-sm font-semibold
                 text-white shadow-sm sm:ml-3 sm:w-auto"
                            >
                              <div className='flex items-center  gap-[6px]'>
                                Save
                                {loading && (
                                  <div className="simple-spinner">
                                    <span></span>
                                  </div>
                                )}
                              </div>

                            </button>
                            <button
                              type="button"
                              onClick={() => setIntegrationOpen(false)}
                              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900
                 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            >
                              Cancel
                            </button>
                          </div>

                        </form>
                      </div>
                    </div>
                  </div>

                </DialogPanel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

    </>

  );
};

export default Header;