import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from '../features/auth/login/Login';
import SignUp from '../features/auth/signup/SignUp';
import ForgotPassword from '../features/auth/forgotpassword/ForgotPassword';
import Otp from '../features/auth/otp/Otp';
import Base from '../features/createBase/Base';
import CreateBaseChannel from '../features/setting/settingPages/CreateBaseChannel';
import Dashboard from "../features/dashboard/Index";
import Setting from "../features/setting/Index";
import CreateBase from "../features/createBase/CreateBase";
import Document from '../features/channels/channeldocuments/documentpages/Document';
import ResetPassword from '../features/auth/resetPassword/ResetPassword';
import ViewFeedback from '../features/feedbacks/ViewFeedback';
import ViewFeedbackSidebar from '../features/feedbacks/ViewFeedbackSidebar';
import ViewTopicSidebar from '../features/topic/ViewTopicSidebar';
import Home from '../features/dashboard/pages/Home';
import Inbox from '../features/dashboard/pages/Inbox';
import Profile from '../features/dashboard/pages/Profile';
import Insights from '../features/dashboard/pages/Insights';
import Topics from '../features/dashboard/pages/Topics';
import Boards from '../features/dashboard/pages/Boards';
import { NewChannelProvider } from '../features/context/NewChannelContext';
import BaseDetails from '../features/setting/settingPages/BaseDetails';
import BaseMemeber from '../features/setting/settingPages/BaseMemeber';
import BaseChannel from '../features/setting/settingPages/BaseChannel';
import FeedbackTags from '../features/setting/settingPages/FeedbackTags';
import FeedbackStatus from '../features/setting/settingPages/FeedbackStatus';
import Profile1 from "../features/user/profile/Profile";
import Secure from "../features/user/profile/Security";
import User from '../features/user/profile';
import ViewIntegration from '../features/integration/ViewIntegration';
import Integration from '../features/dashboard/pages/Integration';
import ViewIntegrationSidebar from '../features/integration/ViewIntegrationSidebar';
import ViewSurveyMode from '../features/integration/survey/ViewSurveyMode';
import Customer from '../features/dashboard/pages/Customer';
import { SearchProvider } from '../context/SearchContext';
import NotFound from './NotFound';

const PrivateRoute = ({ children }: any) => {
	const authenticated = !!localStorage.getItem('authorization');
	return authenticated ? children : <Navigate to="/login" replace />;
};

const PublicRoute = ({ children }: any) => {
	const authenticated = !!localStorage.getItem('authorization');
	return authenticated ? <Navigate to="/" replace /> : children;
};


export default function Routing() {
	const [authenticated, setAuthenticated] = useState(!!localStorage.getItem('authorization'));
	const [initialRoute, setInitialRoute] = useState("/");

	useEffect(() => {
		const path = window.location.pathname;
		if (path.includes('/board/')) {
			setInitialRoute(path);
		}
	}, []);

	const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
	const baseId = baseIdString ? JSON.parse(baseIdString) : null;

	return (
		<div className='h-[calc(100vh_-_0rem)]'>
			<BrowserRouter>
				<SearchProvider>
					<Routes>
						<Route path="/" element={authenticated ? <Base /> : <Navigate to="/login" replace />} />

						<Route path="/login" element={
							<PublicRoute>
								<Login />
							</PublicRoute>
						} />

						<Route path="/forgotpassword" element={
							<PublicRoute>
								<ForgotPassword />
							</PublicRoute>
						} />

						<Route path="/signup" element={
							<PublicRoute>
								<SignUp />
							</PublicRoute>
						} />

						<Route path="/otp" element={
							<PublicRoute>
								<Otp />
							</PublicRoute>
						} />

						<Route path="/base" element={<PrivateRoute><Base /></PrivateRoute>} />
						<Route path="/createbase" element={<PrivateRoute><CreateBase /></PrivateRoute>} />

						<Route path="/:baseId/*" element={
							<PrivateRoute>
								<NewChannelProvider>
									<Dashboard />
								</NewChannelProvider>
							</PrivateRoute>
						}>
							<Route path="dashboard" element={<Home />} />
							<Route path="inbox" element={<Inbox />} />
							<Route path="feedbacks" element={<Profile />} />
							<Route path="insights" element={<Insights />} />
							<Route path="tickets" element={<Topics />} />
							<Route path="integrations" element={<Integration />} />
							<Route path="customers" element={<Customer />} />
							<Route path="board/:boardId" element={<Boards />} />
						</Route>

						<Route path="/:baseId/setting/*" element={
							<PrivateRoute>
								<Setting />
							</PrivateRoute>
						}>
							<Route path="detail" element={<BaseDetails baseId={baseId} />} />
							<Route path="member" element={<BaseMemeber baseId={baseId} />} />
							<Route path="board" element={<BaseChannel baseId={baseId} />} />
							<Route path="label" element={<FeedbackTags />} />
							<Route path="status" element={<FeedbackStatus />} />
						</Route>

						<Route path="/user/:userId/*" element={
							<PrivateRoute>
								<User />
							</PrivateRoute>
						}>
							<Route path="profile" element={<Profile1 />} />
							<Route path="security" element={<Secure />} />
						</Route>

						<Route path="/:id/create/board" element={
							<PrivateRoute>
								<CreateBaseChannel />
							</PrivateRoute>
						} />

						<Route path="/document" element={
							<PrivateRoute>
								<Document />
							</PrivateRoute>
						} />

						<Route path="/resetPassword" element={
							<PrivateRoute>
								<ResetPassword />
							</PrivateRoute>
						} />

						<Route path="/feedback/:id" element={
							<PrivateRoute>
								<ViewFeedbackSidebar />
							</PrivateRoute>
						} />

						<Route path="/ticket/:id" element={
							<PrivateRoute>
								<ViewTopicSidebar />
							</PrivateRoute>
						} />

						<Route path="/view-feedback" element={
							<PrivateRoute>
								<ViewFeedback />
							</PrivateRoute>
						} />

						<Route path="/integrations/:id" element={
							<PrivateRoute>
								<ViewIntegrationSidebar />
							</PrivateRoute>
						} />

						<Route path="/survey/:id" element={
							// <PrivateRoute>
								<ViewSurveyMode />
							// </PrivateRoute>
						} />
						<Route path="/404" element={<NotFound />} />
						<Route path="*" element={<NotFound />} />
					</Routes>
				</SearchProvider>
			</BrowserRouter>
		</div>
	);
}