import React from 'react';

interface SmileyRatingProps {
  type: 'happy' | 'sad' | 'ok';
  isSelected: boolean;
  onSelect: (value: string) => void;
  value: string;
}

const SmileyRating: React.FC<SmileyRatingProps> = ({ type, isSelected, onSelect, value }) => {
  const getSmiley = () => {
    switch (type) {
      case 'sad':
        return '😞';
      case 'ok':
        return '😐';
      case 'happy':
        return '😊';
      default:
        return '';
    }
  };


  const isCurrentSelected = isSelected; 


  return (
    <div
      onClick={() => onSelect(type)}
      className={`py-2 px-4 text-[13px] rounded-lg w-[50px] md:w-[74px] lg:w-[74px] h-[50px] md:h-[74px] lg:h-[74px] flex items-center justify-center text-center leading-4 font-normal border
      ${isCurrentSelected ? 'ring-2 ring-inset ring-indigo-600 shadow-md bg-indigo-600 border-none' : 'border-gray-300 bg-white'}
    `}
      role="button"
      aria-pressed={isCurrentSelected}
    >
      <span className="text-[1.5rem] md:text-[2.5rem] lg:text-[2.8rem]">{getSmiley()}</span>
    </div>
  );
};

export default SmileyRating;
