// @ts-nocheck

import { Dispatch } from 'redux';
import { DELETE_INSIGHT_GROUP_LIST_FAILURE, DELETE_INSIGHT_GROUP_LIST_REQUEST, DELETE_INSIGHT_GROUP_LIST_SUCCESS, DELETE_INSIGHT_LIST_FAILURE, DELETE_INSIGHT_LIST_REQUEST, DELETE_INSIGHT_LIST_SUCCESS, FETCH_DASHBOARD_LIST_FAILURE, FETCH_DASHBOARD_LIST_REQUEST, FETCH_DASHBOARD_LIST_SUCCESS, FETCH_INSIGHT_FAILURE, FETCH_INSIGHT_GROUP_LIST_FAILURE, FETCH_INSIGHT_GROUP_LIST_REQUEST, FETCH_INSIGHT_GROUP_LIST_SUCCESS, FETCH_INSIGHT_REQUEST, FETCH_INSIGHT_SUCCESS, FETCH_ITEM_TOPIC_INSIGHT_FAILURE, FETCH_ITEM_TOPIC_INSIGHT_REQUEST, FETCH_ITEM_TOPIC_INSIGHT_SUCCESS, InsightActionTypes, InsightType, SUBMIT_INSIGHT_GROUP_FAILURE, SUBMIT_INSIGHT_GROUP_REQUEST, SUBMIT_INSIGHT_GROUP_SUCCESS, UPDATE_TICKET_INSIGHT_FAILURE, UPDATE_TICKET_INSIGHT_REQUEST, UPDATE_TICKET_INSIGHT_SUCCESS, UpdateTicketInsightPayload } from './insight.types';
import { _deleteInsightGroupMulti, _deleteInsightMulti, _fetchInsightAPI, _fetchInsightGroupList, _fetchItemTopicInsight, _submitInsightGroup, _updateTicketInsightAPI } from './insight.api';
import { RootState } from '../../../store/store';
import { ThunkAction } from '@reduxjs/toolkit';


const getKeyPrefix = (filterType: string) => {
  switch (filterType) {
    case 'Boards':
      return 'board_id';
    case 'label':
      return 'item_label';
    case 'status':
      return 'item_base_status_id';
    case 'assign':
      return 'item_assigned_to';
    case 'priority':
      return 'item_priority';
    case 'sentiment':
      return 'item_sentiment';
    case 'Created at':
      return 'created_from';
    case 'Updated at':
      return 'updated_from';
    default:
      return filterType.toLowerCase();
  }
};

export const fetchInsightData = (baseId: number, params: {
  page: number;
  pageSize: number;
  filters?: Record<string, any>;
  itemAssignedTo?: string;
}) => async (dispatch: Dispatch<InsightActionTypes>) => {
  dispatch({ type: FETCH_INSIGHT_REQUEST });

  let queryParams = new URLSearchParams();

  queryParams.append('page', params.page?.toString());
  queryParams.append('pageSize', params.pageSize?.toString());

  if (params.filters) {
    Object.entries(params.filters).forEach(([filterType, filterValues]) => {
      if (Array.isArray(filterValues) && filterValues.length > 0) {
        const keyPrefix = getKeyPrefix(filterType);

        filterValues.forEach((filterObj) => {
          let value;
          if (filterType === 'Boards' || filterType === 'status' || filterType === 'assign') {
            value = filterObj.id;
          } else if (filterType === 'label' || filterType === 'priority' || filterType === 'sentiment') {
            value = filterObj.value;
          } else if (filterType === 'Created at' || filterType === 'Updated at') {
            const [startDate, endDate] = filterObj.value.split(' - ').map((dateStr: string | number | Date) => {
              const date = new Date(dateStr);
              date.setHours(12, 0, 0, 0);
              return date.toISOString().split('T')[0];
            });
            queryParams.append(`${keyPrefix}`, startDate);
            queryParams.append(`${keyPrefix.replace('from', 'till')}`, endDate);
            return;
          }

          if (value !== undefined) {
            queryParams.append(keyPrefix, value.toString());
          }
        });
      }
    });
  }

  if (params.itemAssignedTo) {
    queryParams.append('item_assigned_to', params.itemAssignedTo);
  }

  try {
    const response = await _fetchInsightAPI(baseId, queryParams);
    dispatch({
      type: FETCH_INSIGHT_SUCCESS,
      payload: response.data.data
    });

    return response.data.data;
  } catch (error: any) {
    dispatch({
      type: FETCH_INSIGHT_FAILURE,
      payload: error.message
    });
    throw error;
  }
};

export const submitInsightGroup = (baseId: number, insightGroupData: { insight_group: string, insight_group_sentiment: string }): ThunkAction<Promise<any>, RootState, unknown, any> => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: 'SUBMIT_INSIGHT_GROUP_REQUEST' });
    try {
      const response = await _submitInsightGroup(baseId, insightGroupData);
      dispatch({ type: 'SUBMIT_INSIGHT_GROUP_SUCCESS', payload: response });
      return response;
    } catch (error) {
      dispatch({ type: 'SUBMIT_INSIGHT_GROUP_FAILURE', payload: error });
      throw error;
    }
  };
};

export const fetchInsightGroupListData = (baseId: number) => async (dispatch: Dispatch<InsightActionTypes>) => {
  dispatch({ type: FETCH_INSIGHT_GROUP_LIST_REQUEST });

  try {
    const response = await _fetchInsightGroupList(baseId);
    dispatch({
      type: FETCH_INSIGHT_GROUP_LIST_SUCCESS,
      payload: response.data
    });
    return response.data;
  } catch (error: any) {
    console.error('API error:', error);
    dispatch({
      type: FETCH_INSIGHT_GROUP_LIST_FAILURE,
      payload: error.message
    });
    throw error;
  }
};


export const deleteItemInsightGroup = (baseId: number, groupIds: number[]) => async (dispatch: Dispatch<InsightActionTypes>) => {
  dispatch({ type: DELETE_INSIGHT_GROUP_LIST_REQUEST });
  try {
    const response =  await _deleteInsightGroupMulti(baseId, groupIds);
    dispatch({ type: DELETE_INSIGHT_GROUP_LIST_SUCCESS, payload: response.data });
    return response;
  } catch (error: any) {
    dispatch({ type: DELETE_INSIGHT_GROUP_LIST_FAILURE, payload: error.message });
    throw error;
  }
};


export const deleteItemInsight = (baseId: number, insightIds: number[]) => async (dispatch: Dispatch<InsightActionTypes>) => {
  dispatch({ type: DELETE_INSIGHT_LIST_REQUEST });
  try {
    const response =  await _deleteInsightMulti(baseId, insightIds);
    dispatch({ type: DELETE_INSIGHT_LIST_SUCCESS, payload: response.data });
    return response;
  } catch (error: any) {
    dispatch({ type: DELETE_INSIGHT_LIST_FAILURE, payload: error.message });
    throw error;
  }
};

export const fetchItemInsightTopicData = (baseId: number, id: number) => async (dispatch: Dispatch<InsightActionTypes>) => {
  dispatch({ type: FETCH_ITEM_TOPIC_INSIGHT_REQUEST });

  try {
      const response = await _fetchItemTopicInsight(baseId, id); 
      dispatch({
          type: FETCH_ITEM_TOPIC_INSIGHT_SUCCESS,
          payload: response.data
      });
      return response.data;
  } catch (error: any) {
      console.error('API error:', error);
      dispatch({
          type: FETCH_ITEM_TOPIC_INSIGHT_FAILURE,
          payload: error.message
      });
      throw error;
  }
};

export const UpdateTicketInsightData = (baseId: number, payload: UpdateTicketInsightPayload) => async (dispatch: Dispatch<InsightActionTypes>) => {
  dispatch({ type: UPDATE_TICKET_INSIGHT_REQUEST });
  try {
    const response = await _updateTicketInsightAPI(baseId, payload);
    dispatch({ type: UPDATE_TICKET_INSIGHT_SUCCESS, payload: response.data });
    return response;
  } catch (error: any) {
    dispatch({ type: UPDATE_TICKET_INSIGHT_FAILURE, payload: error.message });
    throw error; 
  }
};
