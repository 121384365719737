// @ts-nocheck

import React, { Fragment, HTMLProps, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  ColumnDef,
  PaginationState,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
} from '@tanstack/react-table'
import { Link } from 'react-router-dom'
import { formatDistanceToNow, parse } from 'date-fns';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { MenuDropdown } from '../../components/MenuDropdown';
import { _baseRequest } from '../../services/api';
import { COMMON } from '../../utils/common';
import { DropdownGroup, IntegrationtTableProps, Person, Priority } from './type';
import ViewResponseQuickMode from './ViewResponseQuickMode';
import { Dialog, DialogPanel, Label, Listbox, Transition } from '@headlessui/react';
import { Button } from '@lemonsqueezy/wedges';
import { TrashIcon } from '@iconicicons/react';
import { deleteItemIntegrationResponse } from './integration-redux/integration.actions';
import { Toaster, toast } from 'sonner';


const dropdownGroups: DropdownGroup[] = [
  {
    items: [
      {
        label: `Delete`,
        className: "py-[0.375rem] px-[0.75rem] bg-white text-[#000000eb] text-[13px] hover:bg-[#e7e7e8]",
      },
    ],
  },
];

const integrationStatus: Priority[] = [
  { id: 1, name: 'active', label: '#22c55e' },
  { id: 2, name: 'inactive', label: '#4b5563' },
  { id: 3, name: 'delete', label: '#eb4938' },
];

localStorage.setItem('integrationStatus', JSON.stringify(integrationStatus));


function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!)

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return (
    <input
      type="checkbox"
      ref={ref}
      data-title=''
      className="h-4 w-4 rounded border-gray-300 text-indigo-600 ring-0 outline-none focus:outline-none focus:shadow-none focus:ring-transparent"
      {...rest}
      onClick={(e) => e.stopPropagation()}
    />
  )
}

const IntegrationResponseTable: React.FC<IntegrationtTableProps> = ({ id }: any) => {
  const [data, setData] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 25,
    currentPage: 1,
    hasNextPage: false,
    totalPages: 1,
    totalResponse: 0,
  })
  const [rowSelection, setRowSelection] = React.useState({})
  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;
  const [isModalOpenCheckbox, setIsModalOpenCheckbox] = useState(false);
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const [deleteOk, setDeleteOk] = useState(false)
  const [rowSelectionDelete, setRowSelectionDelete] = useState<number[]>([]);
  const dispatch = useDispatch();
  const isInitialMount = useRef(true);
  const [initialLoading, setInitialLoading] = useState(true);
  const [columns1, setColumns1] = useState<ColumnDef<TableData>[]>([]);
  const [quickViewMode, setQuickViewMode] = useState(false)
  const [quickData, setQuickData] = useState({});
  const [steps, setSteps] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);


  const handleQuickMode = (row: any) => {
    setQuickData(row)
    setQuickViewMode(true)
  }


  const _get_ItemIntegrationList = async (pageIndex, pageSize) => {
    const url = `${COMMON.apiBaseUrl()}/v1/integration-response/${baseId}/listResponse?integration_id=${id}`

    let params = new URLSearchParams();
    if (typeof params.append !== 'function') {
      params = {
        append: (key, value) => {
          if (!this[key]) {
            this[key] = [];
          }
          this[key].push(value);
        },
        toString: () => {
          return Object.entries(this)
            .filter(([key]) => key !== 'append' && key !== 'toString')
            .flatMap(([key, values]) => values.map(value => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`))
            .join('&');
        }
      };
    }

    params.append('page', pageIndex.toString());
    params.append('pageSize', pageSize.toString());


    try {
      const queryString = params.toString();

      const fullUrl = `${url}&${queryString}`;
      const response = await _baseRequest(fullUrl, 'GET');

      if (response.status === 200) {
        const parsedData = response.data.response.map(item => ({
          ...item,
          parsed_integration_response: JSON.parse(item.integration_response || '[]')
        }));

        return { ...response.data, response: parsedData };
      }
      return response;
    } catch (error) {
      throw new Error(`Error fetching item feedback list: ${error.message}`);
    }
  };



  const fetchItemFeedbackListData = useCallback(debounce(async (pageIndex, pageSize, isInitialFetch = false) => {
    setLoading(true);
    try {
      const fetchedSteps = await fetchIntegrationSteps();
      const response = await _get_ItemIntegrationList(pageIndex, pageSize);

      if (response) {

        const filteredData = response.response.map(item => {
          const parsedResponse = JSON.parse(item.integration_response || '[]');

          const formattedData = fetchedSteps.reduce((acc, step) => {
            const matchingResponse = parsedResponse.find(r => r.step_id === step.id);            
            acc[step.id] = matchingResponse ? matchingResponse.response : '-';
            return acc;
          }, {});

          return {
            ...item,
            ...formattedData
          };
        });

        const newPagination = {
          currentPage: response.currentPage,
          hasNextPage: response.hasNextPage,
          totalPages: response.totalPages,
          pageSize: response.pageSize,
          pageIndex: response.currentPage - 1,
          total: response.totalResponse,
        };

        setData(filteredData);
        setPagination(newPagination);
        setTotalPages(response.totalPages);

        localStorage.setItem('cachedIntegrationResponseData', JSON.stringify({
          data: filteredData,
          pagination: newPagination,
          timestamp: Date.now(),
        }));

        if (!isInitialFetch) {
          console.log('Data updated in background');
        }
      }
    } catch (error) {
      console.error('Error fetching item feedback list:', error);
    } finally {
      setLoading(false);
      if (isInitialFetch) {
        setInitialLoading(false);
      }
    }
  }, 500), []);




  useEffect(() => {
    const handleSidebarItemClick = () => {
      setPagination({
        pageIndex: 0,
        pageSize: 25,
        currentPage: 1,
        hasNextPage: false,
        totalPages: 1,
        total: 0,
      });
      setTotalPages(0);
      fetchItemFeedbackListData(1, 25, true);
    };

    window.addEventListener('paginationresetsetate', handleSidebarItemClick);

    return () => {
      window.removeEventListener('paginationresetsetate', handleSidebarItemClick);
    };
  }, [fetchItemFeedbackListData]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      const cachedData = localStorage.getItem('cachedIntegrationResponseData');

      if (cachedData) {
        setLoading(true);
        const { data: cachedItems, pagination: cachedPagination } = JSON.parse(cachedData);
        setData(cachedItems);
        setPagination(prevPagination => ({
          ...prevPagination,
          ...cachedPagination,
        }));
        setTotalPages(cachedPagination.totalPages);
        setLoading(false);
      } else {


        fetchItemFeedbackListData(pagination.currentPage, pagination.pageSize);
      }
    } else {
      fetchItemFeedbackListData(pagination.currentPage, pagination.pageSize);
    }
  }, [fetchItemFeedbackListData, pagination.currentPage, pagination.pageSize]);


  const handlePrevPage = () => {
    setLoading(false)
    if (pagination.currentPage > 1) {
      setPagination(prev => ({ ...prev, currentPage: prev.currentPage - 1 }));
    }
  };

  const handleNextPage = () => {
    setLoading(false)
    if (pagination.hasNextPage) {
      setPagination(prev => ({ ...prev, currentPage: prev.currentPage + 1 }));
    }
  };


  useEffect(() => {
    const handleSidebarItemClick = () => {
      setData([]);
      setPagination({
        pageIndex: 0,
        pageSize: 25,
        currentPage: 1,
        hasNextPage: false,
        totalPages: 1,
        total: 0,
      });
      setTotalPages(0);
      fetchItemFeedbackListData(1, 25, true);
    };

    window.addEventListener('sidebarItemClicked', handleSidebarItemClick);

    return () => {
      window.removeEventListener('sidebarItemClicked', handleSidebarItemClick);
    };
  }, [fetchItemFeedbackListData]);



  const fetchIntegrationSteps = async () => {
    try {
      const response = await _baseRequest(
        `${COMMON.apiBaseUrl()}/v1/integration_steps/${baseId}/${id}/integrationStepList`,
        'GET'
      );
      if (response.status === 200) {
        return response.data;
      }
      return [];
    } catch (error) {
      console.error('Error fetching integration steps:', error);
      return [];
    }
  };

  useEffect(() => {
    setSelectedRowsCount(Object.keys(rowSelection)?.length);
  }, [rowSelection]);

  const handleSelectAllClick = useCallback((checked) => {

    const newRowSelection = {};
    const newRowSelectionDelete = [];

    data.forEach((row, index) => {
      newRowSelection[index] = checked;
      if (checked) {
        newRowSelectionDelete.push(row.response_id);
      }
    });

    setRowSelection(newRowSelection);
    setRowSelectionDelete(newRowSelectionDelete);
    setIsModalOpenCheckbox(newRowSelectionDelete.length > 0);
  }, [data]);


  const handleCheckboxClick = (row, response_id) => {
    setSelectedRows((prevSelectedRows) => ({
      ...prevSelectedRows,
      [row.response_id]: !prevSelectedRows[row.response_id]
    }));
    setRowSelectionDelete((prevSelection) => {

      if (prevSelection?.includes(response_id)) {
        return prevSelection.filter((itemId) => itemId !== response_id);
      } else {
        return [...prevSelection, response_id];
      }
    });

    setIsModalOpenCheckbox(true);
  };


  useEffect(() => {
    const fetchAndSetColumns = async () => {
      const steps = await fetchIntegrationSteps();

      const checkboxColumn: ColumnDef<Person> = {
        id: 'select',
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: (e) => {
                table.toggleAllRowsSelected(e.target.checked);
                handleSelectAllClick(e.target.checked);
              },
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-0">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                // onChange: row.getToggleSelectedHandler(),
                onChange: (e) => {
                  row.getToggleSelectedHandler()(e);
                  handleCheckboxClick(row, row.original.response_id);
                },
              }}
            />
          </div>
        ),
      };

      const dynamicColumns: ColumnDef<TableData>[] = steps
        .filter(step => step.step_input_type !== 'text' && step.step_input_type !== 'heading')
        .map((step) => (
          {
            accessorKey: step.id,
            header: step.step_title,
            type: step.step_input_type,
            created: step.created_at,
            cell: (info) => info.getValue() || '-',
          }));

      setColumns1([checkboxColumn, ...dynamicColumns]);
    };

    fetchAndSetColumns();
  }, [handleSelectAllClick]);

  const handleConfirmDelete = (id) => {
    setDeleteOk(true)
  }

  const handleDeleteConfirm = async () => {
    setDeleteLoading(true);
    try {
      let groupIdsToDelete = Array.isArray(rowSelectionDelete) ? rowSelectionDelete : [rowSelectionDelete];

      const deleteResponse = await dispatch(deleteItemIntegrationResponse(baseId, groupIdsToDelete));

      if (!deleteResponse || !deleteResponse.data) {
        throw new Error('Invalid delete response received');
      }

      // Process the delete response
      const successfulDeletes = deleteResponse.data.filter((item) => item.isSuccess).map((item) => item.response_id);
      const failedDeletes = deleteResponse.data.filter((item) => !item.isSuccess);

      if (successfulDeletes.length > 0) {
        toast.success(`Successfully deleted item(s)`);
      }

      failedDeletes.forEach((item) => {
        toast.error(`Failed to delete item ${item.id}: ${item.reason}`);
      });

      if (successfulDeletes.length > 0) {
        const fetchedSteps = await fetchIntegrationSteps();
        const updatedResponse = await _get_ItemIntegrationList(
          pagination.pageIndex + 1,
          pagination.pageSize,
          false
        );

        if (updatedResponse) {          
          const filteredData = updatedResponse.response.map(item => {
            const parsedResponse = JSON.parse(item.integration_response || '[]');
  
            const formattedData = fetchedSteps.reduce((acc, step) => {
              const matchingResponse = parsedResponse.find(r => r.step_id === step.id);
              
              acc[step.id] = matchingResponse ? matchingResponse.response : '-';
              return acc;
            }, {});
  
            return {
              ...item,
              ...formattedData
            };
          });
          const newPagination = {
            currentPage: updatedResponse.currentPage,
            hasNextPage: updatedResponse.hasNextPage,
            totalPages: updatedResponse.totalPages,
            pageSize: updatedResponse.pageSize,
            pageIndex: updatedResponse.currentPage - 1,
            total: updatedResponse.totalResponse,
          };


          setData(filteredData);
          setPagination(newPagination);
          setTotalPages(updatedResponse.totalPages);

          localStorage.setItem('cachedIntegrationResponseData', JSON.stringify({
            data: filteredData,
            pagination: newPagination,
            timestamp: Date.now(),
          }));

          // Reset states
          setRowSelectionDelete([]);
          setDeleteOk(false);
          setIsModalOpenCheckbox(false);
          setSelectedRows({});
          setRowSelection({});
          setSelectedRowsCount(0);
        } else {
          console.error('Error fetching updated item feedback list:', updatedResponse);
          toast.error('Failed to fetch updated item feedback list');
        }
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(`An error occurred: ${error.response.data.message}`);
      } else {
        toast.error('An error occurred while deleting insight groups. Please try again later.');
      }
    } finally {      
      setDeleteLoading(false);
    }
  };


  const table = useReactTable({
    data,
    columns: columns1,
    state: {
      pagination,
      rowSelection,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
    onPaginationChange: setPagination,
  });


  return (
    <>
      <div className='mx-auto max-w-full'>
        <div className="px-4 sm:px-6 lg:px-8 feedback-table">
          <div className="flow-root h-full">
            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8 h-full">
              <div className="inline-block min-w-full py-2 align-middle h-full max-w-full">
                <div className="relative  h-full block pb-[5rem]">
                  <table className="block relative border-collapse overflow-x-auto w-full feedback-col overflow-y-auto integration-resonse-table-height h-full">
                    <thead
                      className=' bg-white grid feedback-thead feedback-table-row-response'
                    >
                      {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id} className='bg-white flex relative w-full'>
                          {headerGroup.headers.map(header => {

                            return (
                              <th key={header.id} colSpan={header.colSpan} scope="col"
                                className={`sticky bg-white flex flex-1 py-[0.75rem] px-[1rem] cursor-pointer text-[0.75rem] items-center justify-start
                                leading-[1rem] text-[#52555a] font-[500] border-b border-solid border-light-gray-200 min-w-[50px] md:min-w-[160px] lg:min-w-[150px]
                                ${header.id === 'select' ? 'lg:!min-w-[50px] !max-w-[50px] [&>div]:px-0' : ''} 
                       
                              `}
                              >
                                <div
                                  {...{
                                    className: header.column.getCanSort()
                                      ? 'cursor-pointer select-none '
                                      : '',
                                    onClick: header.column.getToggleSortingHandler(),
                                  }}
                                >
                                  <span className='truncate text-[13px] leading-4 whitespace-nowrap w-[90px] overflow-hidden text-ellipsis text-start block'>{flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                    {{
                                      asc: ' 🔼',
                                      desc: ' 🔽',
                                    }[header.column.getIsSorted() as string] ?? null}</span>
                                </div>
                              </th>
                            )
                          })}
                          <th
                            className={`sticky bg-white flex flex-1 py-[0.75rem] px-[1rem] cursor-pointer text-[0.75rem] items-center justify-start
                                    leading-[1rem] text-[#52555a] font-[500] border-r border-b border-t border-solid border-light-gray-200 min-w-10 max-w-[56px]`}
                          ></th>
                        </tr>
                      ))}

                    </thead>

                    <div>
                      {loading ? (
                        <div className="flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 191px)' }}>
                          <div className="simple-spinner-loader">
                            <span></span>
                          </div>
                        </div>
                      ) : pagination?.totalPages === 0 ? (
                        <div className="flex items-center justify-center z-50" style={{ height: 'calc(100vh - 191px)' }}>
                          <p>No data found</p>
                        </div>
                      ) : (

                        <tbody className="grid mt-[42px]">
                          {table.getRowModel().rows.map((row) => {
                            const allRowsSelected = table.getIsAllRowsSelected();
                            return (
                              <tr
                                key={row.id}
                                // data-selected={allRowsSelected ? true : selectedRows[row.id]}
                                onClick={() => handleQuickMode(row.original)}
                                className={`border-b relative border-solid border-light-gray-200 bg-white flex w-full ${selectedRows[row.id] ? 'bg-gray-100' : ''}`}
                              >
                                {row.getVisibleCells().map((cell) => (
                                  
                                  <td
                                    key={cell.id}
                                    // data-title={cell?.row?.original?.parsed_integration_response[0]?.response}
                                    className={`flex table-tooltip items-center justify-start text-start px-[1rem] py-[0.9rem] leading-[1rem]
                    flex-1 text-dark-black-200 !max-w-[117px] min-w-[150px]
                    ${/(_select)$/.test(cell.id) ? '!max-w-[50px] [&>div]:px-0 !min-w-[50px]' : ''} 
                  `}
                                  >
                                    <span  className=' text-[#1b1b1b] text-[13px] font-medium line-clamp-1 cursor-pointer'>
                                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </span>

                                  </td>
                                ))}
                                <td
                                  className={`flex items-center text-start px-[0.75rem] py-[1rem] leading-[1rem] flex-1 max-w-[50px] text-dark-black-200 justify-center [&>button]:w-auto [&>button]:bg-transparent [&>button]:hover:bg-transparent`}
                                >
                                  <MenuDropdown
                                    groups={dropdownGroups}
                                    image={
                                      <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        aria-hidden="true"
                                        focusable="false"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <circle cx="12" cy="12" r="1"></circle>
                                        <circle cx="12" cy="5" r="1"></circle>
                                        <circle cx="12" cy="19" r="1"></circle>
                                      </svg>
                                    }
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                    </div>

                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="fixed z-10 bg-white border-t border-solid border-light-gray-200 py-[0.5rem] px-[1rem] bottom-0 pagination-bottom">
        <div className="relative flex items-center justify-between">



          <div className="flex items-center gap-2">
            <span className="flex items-center gap-1">
              <div className='text-[0.875rem] font-[500] text-[#000000eb]'>Page

              </div>
            </span>
            <span className="flex items-center gap-1">

              <input
                type="number"
                value={pagination.currentPage}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) : 1;
                  setPagination((prevPagination) => ({
                    ...prevPagination,
                    currentPage: page,
                  }));
                }}
                className="w-[4rem] h-[2rem] text-[0.8125rem] px-[0.75rem] rounded-md border border-solid border-light-gray-100"
              />

              <span className='mr-1 text-[#000000eb] text-[0.8125rem]'>  of{' '}

                {totalPages}
              </span>
            </span>


            <div className='flex items-center gap-1'>

              <select
                value={table.getState().pagination.pageSize}
                onChange={e => {
                  table.setPageSize(Number(e.target.value))
                }}
                className="w-[6rem] h-[2rem] text-[0.8125rem] px-[0.75rem] rounded-md border border-solid border-light-gray-100 block"
              >
                {[25, 50, 100].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
              <div className='mr-1 text-[#000000eb] text-[0.8125rem] ml-[14px]'>
                {pagination.total} Items
              </div>
            </div>


          </div>
          <div className='flex gap-2'>
            <button
              className="border rounded py-2 px-2"
              onClick={handlePrevPage}
              disabled={pagination.currentPage === 1}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-2 w-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
              </svg>

            </button>
            <button
              className="border rounded py-2 px-2"
              onClick={handleNextPage}
              disabled={!pagination.hasNextPage}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-2 w-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
              </svg>

            </button>
          </div>
        </div>
      </div>
      <Dialog open={quickViewMode} onClose={setQuickViewMode} className="relative z-10">
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <DialogPanel
                // transition
                className="pointer-events-auto w-screen max-w-[40rem] transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
              >
                <div className="flex h-full flex-col  bg-white py-0 shadow-xl">
                  <div className="px-0">
                    <div className="flex items-start justify-between flex-col">
                      {/* <DialogTitle className="text-base font-semibold leading-6 text-gray-900">Panel title</DialogTitle> */}
                      {/* isSidebarOpen, setIsSidebarOpen, headernone, handleSidebarToggle, groupId  */}

                      <ViewResponseQuickMode
                        columns={columns1}
                        data={quickData}
                        isSidebarOpen={quickViewMode}
                        setIsSidebarOpen={setQuickViewMode} />
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>

      <div open={isModalOpenCheckbox} onClose={() => { }} className={`relative z-10 w-full ${isModalOpenCheckbox ? 'common-slider-one' : 'common-slider-two'}`}
      >
        {selectedRowsCount > 0 && (
          <div className="fixed inset-y-0 right-0 flex checkbox-modal h-[100%] w-[800px] max-w-[800px] bottom-0 top-[-70px]">
            <div className="flex h-auto flex-col overflow-y-scroll bg-white py-6 rounded-lg" style={{ boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1),0 2px 4px -1px rgba(0, 0, 0, 0.06)' }}>
              <div className="px-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <span className='text-dark-black-100 text-[13px] font-[700]'>{selectedRowsCount} selected</span>
                  <div className="flex items-center">
                    <div className="w-full flex items-center justify-between">
                      <div className="flex items-center gap-[0.5rem]">

                        <Button
                          onClick={(id) => handleConfirmDelete(id)}
                          className='text-[13px] text-dark-black-100 font-[600] [&>span]:font-[600] h-[32px] rounded-md flex-row-reverse border-[#e7e7e8] shadow-none'
                          after={<TrashIcon className='w-[13px] h-[13px] text-dark-black-100 font-[600] opacity-80' strokeWidth={3} />} variant="outline">
                          Delete
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        )}
      </div>
      <Transition.Root show={deleteOk} as={Fragment}>
        <Dialog as="div" className="relative z-50 md:z-10 lg:z-10" onClose={() => { }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center sm:items-start h-full items-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  transition
                  className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4
                   data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full
                    sm:max-w-[500px] sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                >
                  <span className='font-[600]'>Delete
                    <span> {selectedRowsCount} Responses</span>
                  </span>

                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to delete your responses?
                    </p>
                  </div>
                  <div className="mt-5 sm:mt-4 flex gap-2 justify-end">
                    <Button
                      type="button"
                      onClick={handleDeleteConfirm}
                      className={`bg-red-600 hover:hover:bg-red-500 focus-visible:outline-red-600 relative
                      [&>span]:flex [&>span]:items-center [&>span]:gap-[8px]`}
                    >
                      Delete
                      {deleteLoading && (
                        <div className="simple-spinner">
                          <span></span>
                        </div>
                      )}
                    </Button>
                    <button
                      type="button"
                      onClick={() => setDeleteOk(false)}
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>

              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

    </>
  );

}


export default IntegrationResponseTable;