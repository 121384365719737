
// const API_BASE_URL = "https://api.superteamhr.com"
const API_BASE_URL = "https://api.feedbacks.app"
export const COMMON = {
    apiBaseUrl() {
        if (API_BASE_URL) {
            return API_BASE_URL;
        }
    },
    stringFormat(s: string, ...args: any[]) {
        return s.replace(/{([0-9]+)}/g, (match, index) =>
            typeof args[index] === 'undefined' ? match : args[index]
        );
    },
}