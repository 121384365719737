import {
  CREATE_BOARD_SUCCESS,
  CREATE_BOARD_FAILURE,
  CREATE_BOARD_REQUEST,
  FETCH_BOARD_LIST_REQUEST,
  FETCH_BOARD_LIST_SUCCESS,
  FETCH_BOARD_LIST_FAILURE,
  DELETE_BOARD_REQUEST,
  DELETE_BOARD_SUCCESS,
  DELETE_BOARD_FAILURE,
  FETCH_BOARD_BY_ID_REQUEST,
  FETCH_BOARD_BY_ID_SUCCESS,
  FETCH_BOARD_BY_ID_FAILURE,
  UPDATE_BOARD_REQUEST,
  UPDATE_BOARD_SUCCESS,
  UPDATE_BOARD_FAILURE
} from './BoardActionTypes';
import { Dispatch } from '@reduxjs/toolkit';
import { _create_board, _getBoardById, _getBoardDelete, _get_board_list, _updateBoardApi } from './BoardApi';

// Action types for creating a project board
export const createBoard = (
  value: number,
  name: string,
  description: string,
  privacy: string,
  color: string,
  boardStatus: string,
  selectRoleName: string // Added parameter
) => async (dispatch: Dispatch) => {
  dispatch({ type: CREATE_BOARD_REQUEST });
  const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;

  const userId = JSON.parse(localStorage.getItem('userAuthId') || "");
  const userStatus = localStorage.getItem('userAuthStatus') || "";
  const lowerCaseRoleName = selectRoleName.toLowerCase(); // Convert to lowercase

  try {
    const response = await _create_board(
      baseId,
      name,
      description,
      privacy,
      color,
      userStatus,
      lowerCaseRoleName
    );

    dispatch({
      type: CREATE_BOARD_SUCCESS,
      payload: response.data // Assuming your API returns data field in the response
    });
  } catch (error) {
    dispatch({
      type: CREATE_BOARD_FAILURE,
      payload: (error as Error).message // Ensure error is of type Error
    });
  }
};

// Create a new action to fetch the project board list
export const fetchBoardList = (baseId: number) => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_BOARD_LIST_REQUEST });

  try {
    const response = await _get_board_list(baseId);
    localStorage.setItem('channelData', JSON.stringify(response.data));
    dispatch({
      type: FETCH_BOARD_LIST_SUCCESS,
      payload: response.data
    });
    return response.data; // Return the response data
  } catch (error) {
    dispatch({
      type: FETCH_BOARD_LIST_FAILURE,
      payload: (error as Error).message
    });
    throw error; // Re-throw the error so it can be caught in the handleSave function
  }
};


// Action to delete a project board
export const deleteBoard = (id: number) => async (dispatch: Dispatch) => {
  dispatch({ type: DELETE_BOARD_REQUEST });

  try {
    const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('baseId');
    const baseId = baseIdString ? JSON.parse(baseIdString) : null;

    if (!baseId) {
      throw new Error('baseId is undefined or null');
    }

    const response = await _getBoardDelete(id, baseId);

    dispatch({
      type: DELETE_BOARD_SUCCESS,
      payload: id // Assuming you want to remove the board by its id from the state
    });
    return response; // Return the response
  } catch (error) {
    dispatch({
      type: DELETE_BOARD_FAILURE,
      payload: (error as Error).message
    });
    throw error; // Re-throw the error so it can be caught if needed
  }
};

// board by id

// Action to fetch board by ID
export const fetchBoardById = (boardId: number) => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_BOARD_BY_ID_REQUEST });

  try {
    const baseId = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
    if (!baseId) {
      throw new Error('Base ID not found in localStorage');
    }

    const response = await _getBoardById(boardId, baseId);

    dispatch({
      type: FETCH_BOARD_BY_ID_SUCCESS,
      payload: response.data, // Assuming your API returns data field in the response
    });
  } catch (error) {
    dispatch({
      type: FETCH_BOARD_BY_ID_FAILURE,
      payload: (error as Error).message, // Ensure error is of type Error
    });
  }
};

// update board


export const updateBoard = (
  id: number,
  value: number,
  name: string,
  description: string,
  privacy: string,
  color: string,
  boardStatus: string,
  selectRoleName: string
) => async (dispatch: Dispatch) => {
  dispatch({ type: UPDATE_BOARD_REQUEST });

  try {
    const response = await _updateBoardApi(
      id,
      value.toString(), // Ensure value is converted to string
      name,
      description,
      privacy,
      color,
      boardStatus,
      selectRoleName
    );

    dispatch({
      type: UPDATE_BOARD_SUCCESS,
      payload: response.data, // Assuming your API returns data field in the response
    });
  } catch (error) {
    dispatch({
      type: UPDATE_BOARD_FAILURE,
      payload: (error as Error).message, // Ensure error is of type Error
    });
  }
};
