// @ts-nocheck
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Button } from '@lemonsqueezy/wedges';
import { _item_TopicSearchByParam } from '../setting/settingPages/baseAPI';
import { MenuDropdown } from '../../components/MenuDropdown';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { Bars3Icon, ChevronDownIcon, GlobeAltIcon, MagnifyingGlassIcon, RssIcon, SparklesIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { DropdownGroup, Feedback, Comment } from './type';
import { DeleteInsightData, SubmitInsightData, UpdateInsightData, deleteItemActivity, fetchFeedbackData, fetchFeedbackItemActivity, fetchItemInsightById, fetchItemInsightData, submitItemActivity } from './feedback-redux/feedbacks.actions';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../hooks/hooks';
import { RootState } from '../../store/store';
import { FeedbackType } from './feedback-redux/feedbacks.types';
import { Toaster, toast } from 'sonner';
import { formatDistance } from 'date-fns';
import CustomerFeedback from './CustomerFeedback';
import ViewFeedbackHeader from './ViewFeedbackHeader';
import ViewFeedbackDetails from './ViewFeedbackDetails';
import { getSentimentColor } from '../../utils/colorUtils';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import ReactMarkdown from 'react-markdown'; 


function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

interface ViewFeedbackProps {
  item?: {
    id: number;
    name: string;
    daysAgo: string;
    createdBy: string;
    avatarUrl: string;
    description: string;
    statusColor: string;
  };
  isSidebarOpen?: boolean;
  setIsSidebarOpen?: (open: boolean) => void;
  headernone?: boolean;
  handleSidebarToggle?: () => void;
}


const ViewFeedback: React.FC<ViewFeedbackProps> = ({ activity, item, isSidebarOpen, setIsSidebarOpen, headernone, handleSidebarToggle, itemId }) => {
  const [currentFeedback, setCurrentFeedback] = useState<Feedback | null>(null);
  const [commentText, setCommentText] = useState<string>('');
  const [comments, setComments] = useState<Comment[]>([]);
  const [commentError, setCommentError] = useState<string>('');
  const [isAsideOpen, setIsAsideOpen] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [itemStatusActivity, setItemStatusActivity] = useState<ItemStatus[]>([]);
  const [itemInsight, setItemInsight] = useState<ItemStatus[]>([]);
  const [isAddInsightAsideOpen, setIsAddInsightAsideOpen] = useState(false);
  const [isViewInsightAsideOpen, setIsViewInsightAsideOpen] = useState(false);
  const [isEditingInsight, setIsEditingInsight] = useState(false);
  const [selectedInsightId, setSelectedInsightId] = useState(null);
  const [fetchedInsightData, setFetchedInsightData] = useState(null);
  const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;
  const [shareWithCustomer, setShareWithCustomer] = useState(false);
  const [localLoading, setLocalLoading] = useState(true);
  const [removeTopic, setRemoveTopic] = useState(false)
  const [isEditInsightAsideOpen, setIsEditInsightAsideOpen] = useState(false);
  const [formDataInsight, setFormDataInsight] = useState<Partial<FeedbackType>>({
    itemInsight: '',
    itemContext: '',
  });

  const textareaRef = useRef(null);
  const textareaRef1 = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [attachedTopics, setAttachedTopics] = useState({});
  const [lastSearchedTerm, setLastSearchedTerm] = useState('');
  const searchInputRef = useRef(null);
  const searchContainerRef = useRef(null);
  const [itemAttachmentShow, setItemAttachmentShow] = useState('')
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { data, loading, error } = useSelector((state: RootState) => state.feedbacks);
  const [selectedInsight, setSelectedInsight] = useState([])
  const [imageZoom, setImageZoom] = useState(false)
  const sentimentOptionsInsight = [
    { id: 1, name: 'Positive' },
    { id: 2, name: 'Negative' },
    { id: 3, name: 'Request' },
  ]

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };


  const dropdownGroups: DropdownGroup[] = [
    {
      items: [
        {
          label: `Delete`,
          className: "py-[0.375rem] px-[0.75rem] bg-white text-[#000000eb] text-[13px] hover:bg-[#e7e7e8]",
        }
      ],
    },
  ];


  const handleSideUserInsightView = async (id) => {
    setIsViewInsightAsideOpen(true)
    setSelectedInsightId(id);
    const insightData = await dispatch(fetchItemInsightById(baseId, id));
    if (insightData.id === id) {
      setFetchedInsightData(insightData);
    } else {
      console.error('Fetched insight ID does not match the selected ID');
    }
  }


  useEffect(() => {
    const fetchData = async () => {
      if (itemId || id) {
        setLocalLoading(true);
        try {
          const res = await dispatch(fetchFeedbackData((parseInt(itemId, 10) || parseInt(id, 10)), baseId));
          setItemAttachmentShow(res)
        } catch (error) {
          console.error("Error fetching feedback data:", error);
        } finally {
          setLocalLoading(false);
        }
      }
    };

    fetchData();
  }, [dispatch, itemId, id, baseId]);


  useEffect(() => {
    const fetchData = async () => {
      if (id && baseId) {
        try {
          const getItemActivity = await dispatch(fetchFeedbackItemActivity(parseInt(id, 10), baseId));
          setItemStatusActivity(getItemActivity)

        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [dispatch, id, baseId]);

  useEffect(() => {
    fetchFeedbackItemActivity()
  }, [])



  const handleCommentSubmit = async () => {
    if (!commentText) {
      setCommentError('Please enter a comment');
      return;
    }
    const currentItem = itemStatusActivity.find((item) => item.id);

    const feedback = {
      base_id: baseId,
      item_id: currentItem?.item_id || itemId,
      item_activity_text: commentText,
      item_activity_type: 'comment',
      ...(shareWithCustomer && { item_activity_privacy: 'external' }),

    };

    await dispatch(submitItemActivity(baseId, feedback))
      .then((response) => {
        setItemStatusActivity(prev => [...prev, response]);

        setCommentText('');
        setCommentError(null);
        setShareWithCustomer('')
      })
      .catch((error) => {
        console.error(error);
        setCommentError('Failed to submit comment');
      });
  };

  const handleDeleteComment = async (itemId) => {
    try {
      await dispatch(deleteItemActivity(baseId, itemId));
      setItemStatusActivity(prev => prev.filter(item => item.id !== itemId));
      toast.success('Item deleted successfully');
    } catch (error) {
      console.error('Failed to delete item:', error);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      if (id || itemId) {
        setLocalLoading(true);
        try {
          const itemInsightRes = await dispatch(fetchItemInsightData(baseId, (parseInt(id, 10) || parseInt(itemId, 10))));
          setItemInsight(itemInsightRes)
        } catch (error) {
          console.error("Error fetching feedback data:", error);
        } finally {
          setLocalLoading(false);
        }
      }
    };

    fetchData();
  }, [dispatch, itemId, id, baseId]);

  const handleItemInsightFetched = (insightData) => {
    setItemInsight(insightData);
  };



  useEffect(() => {
    const fetchData = async () => {
      if (itemId) {
        setLocalLoading(true);
        try {
          const getItemActivity = await dispatch(fetchFeedbackItemActivity(parseInt(itemId, 10), baseId));
          setItemStatusActivity(getItemActivity)
        } catch (error) {
          console.error("Error fetching feedback data:", error);
        } finally {
          setLocalLoading(false);
        }
      }
    };

    fetchData();
  }, [dispatch, itemId, baseId]);


  const handleInsightData = async (itemId) => {
    try {
      await dispatch(DeleteInsightData(baseId, itemId));
      toast.success('Item deleted successfully');

      const firstItemId = data.length > 0 ? data[0].id : null;
      const nextItemId = firstItemId || itemId;

      if (nextItemId) {
        const itemInsightRes = await dispatch(fetchItemInsightData(baseId, parseInt(nextItemId, 10)));
        setItemInsight(itemInsightRes);
      } else {
        console.log('No items left in data array');
      }
    } catch (error) {
      console.error('Failed to delete item:', error);
    }
  };


  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '0px';
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = `${scrollHeight}px`;
    }
  }, [formDataInsight?.itemInsight]);

  useEffect(() => {
    if (textareaRef1.current) {
      textareaRef1.current.style.height = '0px';
      const scrollHeight = textareaRef1.current.scrollHeight;
      textareaRef1.current.style.height = `${scrollHeight}px`;
    }
  }, [formDataInsight?.itemContext]);


  const handleChangeInsight = (e) => {
    const { name, value } = e.target;
    setFormDataInsight(prev => ({ ...prev, [name]: value }));
  };


  const handleInsightSubmit = (e) => {
    e.preventDefault();

    // Check if either itemInsight or itemContext is provided
    if (!formDataInsight?.itemInsight && !formDataInsight?.itemContext) {
      toast.error('Either Insight or Context is mandatory.');
      return;
    }

    // Check if sentiment is provided
    if (!selectedInsight.name || !selectedInsight) {
      toast.error('Sentiment is mandatory.');
      return;
    }
    const selectedTopicIds = Object.keys(attachedTopics).filter(key => attachedTopics[key]);



    const insightData = {
      base_id: Number(baseId),
      item_id: Number(id) || Number(itemId),
      itemInsight: formDataInsight?.itemInsight,
      itemContext: formDataInsight?.itemContext,
      itemContextSentiment: selectedInsight.name,
      insight_ticket_id: (Number(selectedTopicIds[0]))
    };


    dispatch(SubmitInsightData(baseId, insightData))
      .then((response) => {
        const responseData = response;
        setItemInsight(prevInsights => ({
          ...prevInsights,
          [selectedInsight.name]: [
            ...(prevInsights[selectedInsight.category] || []),
            response
          ]
        }));

        setFormDataInsight({ itemInsight: '', itemContext: '' });
        setSelectedInsight('');
        toast.success('Successfully added');
      })
      .catch((error) => {
        console.error('Error submitting insight:', error);
        toast.error('Failed to add insight');
      });
  };

  const handleSideUserInghtOpen = () => {
    setIsAddInsightAsideOpen(true)
    setIsViewInsightAsideOpen(false)
    setIsEditingInsight(null)
    setSelectedInsightId(null);
    setFetchedInsightData(null);
    setFormDataInsight({
      itemInsight: '',
      itemContext: '',
      tags: [],
      topic: [],

    });
    setSelectedInsight('')
  }

  const handleInsightUpdate = async (e) => {
    e.preventDefault();
    if (!formDataInsight?.itemInsight && !formDataInsight?.itemContext) {
      toast.error('Either Insight or Context is mandatory.');
      return;
    }
    if (!selectedInsight.name || !selectedInsight) {
      toast.error('Sentiment is mandatory.');
      return;
    }
    const selectedTopicId = Object.keys(attachedTopics).find(key => attachedTopics[key]);

    const firstItemId = data.length > 0 ? data[0].id : null;
    const nextItemId = firstItemId || itemId;

    const insightData = {
      base_id: Number(baseId),
      item_id: Number(id) || Number(itemId),
      itemInsight: formDataInsight?.itemInsight,
      itemContext: formDataInsight?.itemContext,
      itemContextSentiment: selectedInsight.name,
      insight_ticket_id: selectedTopicId ? Number(selectedTopicId) : null

    };


    try {
      await dispatch(UpdateInsightData(baseId, formDataInsight.id, insightData));
      const updatedItemInsightRes = await dispatch(fetchItemInsightData(baseId, parseInt(id, 10)));
      setItemInsight(updatedItemInsightRes);

      setFormDataInsight({ itemInsight: '', itemContext: '' });
      setSelectedInsight('');
      setAttachedTopics({});
      toast.success('Successfully updated');
      setIsAddInsightAsideOpen(false);
      setIsViewInsightAsideOpen(true);
      const newInsight = await dispatch(fetchItemInsightById(baseId, formDataInsight.id));
      setFetchedInsightData(newInsight);
      setFormDataInsight(newInsight);
    } catch (error) {
      console.error('Error updating insight:', error);
      toast.error('Failed to update insight');
    }
  };

  const handleEditInsightOpen = async (itemId) => {
    try {
      setIsEditingInsight(true);
      setIsAddInsightAsideOpen(false);
      setIsEditInsightAsideOpen(true)
      setIsViewInsightAsideOpen(false)
      setSearchTerm('')
      const allPositiveNull = itemInsight.positive?.every(insight => insight.attached_item === null);
      const allNegativeNull = itemInsight.negative?.every(insight => insight.attached_item === null);
      const allRequestNull = itemInsight.request?.every(insight => insight.attached_item === null);

      if (allPositiveNull && allNegativeNull && allRequestNull) {
        setSelectedInsightId('');
      }

      const insightData = await dispatch(fetchItemInsightById(baseId, itemId));

      setFormDataInsight(insightData);
      const sentimentOption = sentimentOptionsInsight.find(option => option.name.toLowerCase() === insightData.itemContextSentiment.toLowerCase());
      setSelectedInsight(sentimentOption || { name: '' });



      const firstItemId = data.length > 0 ? data[0].id : null;
      const nextItemId = firstItemId || itemId;

      if (nextItemId) {
      } else {
        console.log('No items left in data array');
      }
    } catch (error) {
      console.error('Error fetching insight data:', error);
    }
  };

  const deleteAndEditGroup: DropdownGroup[] = [
    {
      items: [
        {
          label: `Delete`,
          className: "py-[0.375rem] px-[0.75rem] bg-white text-[#000000eb] text-[13px] hover:bg-[#e7e7e8]",
        },
        {
          label: `Edit`,
          className: "py-[0.375rem] px-[0.75rem] bg-white text-[#000000eb] text-[13px] hover:bg-[#e7e7e8]",
          onClick: (id) => handleEditInsightOpen(id),
        },
      ],
    },
  ];



  const performSearch = useCallback(async () => {
    if (searchTerm.length >= 3 && searchTerm !== lastSearchedTerm) {
      setIsSearching(true);
      try {
        const payload = { search: searchTerm };
        const results = await _item_TopicSearchByParam(Number(baseId), payload);
        setSearchResults(results.data.items);
        setLastSearchedTerm(searchTerm);
      } catch (error) {
        console.error('Error searching topics:', error);
        if (error.response && error.response.status === 422) {
          const errorMessage = error.response.data.data.errors.search || 'Validation error occurred';
          toast.error(errorMessage);
        } else {
          toast.error('Failed to search topics');
        }
      } finally {
        setIsSearching(false);
      }
    } else if (searchTerm.length < 3) {
      setSearchResults([]);
      setLastSearchedTerm('');
    }
  }, [searchTerm, baseId, lastSearchedTerm]);

  const handleTopicSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      performSearch();
    }
  };

  const handleSearchIconClick = () => {
    performSearch();
  };

  const handleAttachTopic = (itemId: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAttachedTopics({ [itemId]: true });
    setLocalLoading(false);
  };

  useEffect(() => {
    let timeoutId;
    const handleClickOutside = (event) => {
      if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
          performSearch();
        }, 300);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [performSearch]);



  if (loading) return (
    <div className=" flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 0px)' }}>
      <div className="simple-spinner-loader">
        <span></span>
      </div>
    </div>
  );
  if (error) return <div>Error: {error}</div>;
  if (data?.length === 0) return <div>No feedback available</div>;

  // for create by user
  const baseMembersDetailsString = localStorage.getItem('baseMembersDetails');
  const baseMembersDetails = baseMembersDetailsString ? JSON.parse(baseMembersDetailsString) : [];

  let cleanedTimeAgoViewInsight = '';

  if (fetchFeedbackData?.length > 0) {
    const createdAt = fetchedInsightData?.created_at;

    if (createdAt) {
      const parsedDate = new Date(createdAt);
      if (!isNaN(parsedDate.getTime())) {
        const timeAgoViewInsight = formatDistance(parsedDate, new Date(), { includeSeconds: true });
        cleanedTimeAgoViewInsight = timeAgoViewInsight.replace(/^about\s/, '');
      } else {
        console.error('Invalid created_at date:', createdAt);
      }
    }
  }

  let cleanedTimeAgoViewInsight1 = '';

  if (fetchFeedbackData?.length > 0) {
    const createdAt1 = fetchedInsightData?.updated_at;

    if (createdAt1) {
      const parsedDate = new Date(createdAt1);
      if (!isNaN(parsedDate.getTime())) {
        const timeAgoViewInsight1 = formatDistance(parsedDate, new Date(), { includeSeconds: true });
        cleanedTimeAgoViewInsight1 = timeAgoViewInsight1.replace(/^about\s/, '');
      } else {
        console.error('Invalid created_at date:', createdAt1);
      }
    }
  }


  const createdById = fetchedInsightData?.created_by;
  const matchingUser = baseMembersDetails.find(user => user.id === createdById);
  const createdByName = matchingUser ? matchingUser.name : 'AI';


  const createdById1 = fetchedInsightData?.updated_by;
  const matchingUser1 = baseMembersDetails.find(user => user.id === createdById1);
  const createdByName1 = matchingUser1 ? matchingUser1.name : '';



  // Function to get member details by ID
  const getMemberDetails = (id, creatorType) => {
    switch (creatorType) {


      case 'user':
        const userMember = baseMembersDetails.find(member => member.id == id);
        return userMember ? userMember.name : '';

      case 'ai':
        return <div className='relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[9px] flex items-center justify-center'><SparklesIcon className='w-3 h-3 text-dark-black-200' /></div>;

      case 'customer':
        return id;

      default:
        return '';
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const sentimentCategories = Object.keys(itemInsight || {});

  const isInsightEmpty = !itemInsight || Object.keys(itemInsight).length === 0;

  const handleImageZoom = () => {
    setImageZoom(true)
  }


  return (
    <>
      <Toaster richColors />
      <div className="sticky top-0">
        <div className='flex h-[46px] shrink-0 items-center gap-x-4 bg-white px-4 shadow-custom-1 sm:gap-x-6 sm:px-6 lg:px-4'>
          {!headernone && (
            <div className="">
              <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                onClick={() => setIsSidebarOpen?.(!isSidebarOpen)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          )}

          {/* Separator */}
          <ViewFeedbackHeader
            activity={activity}
            item={item}
            isAsideOpen={isAsideOpen}
            setIsAsideOpen={setIsAsideOpen}
            itemId={itemId}
            onItemInsightFetched={handleItemInsightFetched}
          />
        </div>
      </div>
      {data.map((feedback: FeedbackType, index: number) => (
        <div key={index} className='flex-1 p-0'>
          <div className='min-h-full max-w-full' style={{ margin: '0px auto' }}>
            <div className='flex items-stretch flex-row gap-0 w-full h-full relative overflow-x-hidden overflow-y-hidden'>
              <main className='relative flex-1 flex '>
                <div
                  className={isHovered ? 'overflowing-element hovered mq1023:max-w-full mq1800:max-w-[100%] mq3000:max-w-[100%] flex-1 w-full px-5 lg:px-[60px] overflow-y-auto view-feedback-data' : 'new-feedbackoverflow  mq1023:max-w-full mq1800:max-w-[100%] mq3000:max-w-[100%] flex-1 w-full px-5 lg:px-[60px] overflow-y-auto view-feedback-data overflowing-element'}
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}

                >
                  <div className='max-w-full md:max-w-[710px] lg:max-w-[710px] m-auto'>
                    {feedback.item_title &&
                      <div className="mb-4 mt-5 lg:mt-[55px]">
                        <div

                          className="border-none outline-none bg-transparent focus:outline-none focus:ring-0 text-[24px] leading-8  font-medium pb-2 text-dark-black-100 w-full p-0 m-0 placeholder:text-[#9e9ea0]"

                        >
                          {feedback?.item_title}
                        </div>
                      </div>
                    }
                    {feedback.item_details &&
                      <div className="mt-4">
                        {/* <div
                          className="border-none outline-none bg-transparent focus:outline-none focus:ring-0 text-[14px] text-dark-black-100 w-full p-0 m-0 placeholder:text-[#9e9ea0]"
                        >
                          {feedback?.item_details}
                        </div> */}
                        {/* <div
                          dangerouslySetInnerHTML={{ __html: feedback?.item_details }}
                          className="text-content"
                        /> */}
                         <div className=" mt-4">
                              {/* <h3 className="text-lg font-medium mb-2">Preview:</h3> */}
                              <ReactMarkdown>{feedback?.item_details}</ReactMarkdown>
                            </div>
                      </div>
                      
                    }

                    {
                      itemAttachmentShow &&
                      itemAttachmentShow.item_attachment &&
                      itemAttachmentShow.item_attachment.length > 0 && (
                        <img
                          onClick={handleImageZoom}
                          src={
                            itemAttachmentShow?.item_attachment?.[0]?.fileUrl
                              ? `${process.env.REACT_APP_API_BASE_URL}/${itemAttachmentShow.item_attachment[0].fileUrl}`
                              : ''
                          }
                          alt="Selected file preview"
                          className="mt-5 w-full object-cover rounded-md max-w-[250px]"
                        />
                      )
                    }


                    {/* <img src="https://picsum.photos/200/300" alt="Selected file preview" className="mt-5 w-full lg:w-[300px] h-[200px] object-cover rounded-md" /> */}
                    {/* )} */}
                    <hr className='my-5' />
                    {feedback?.item_summary &&
                      <div className='bg-[#ffeab6] p-5 rounded-xl mt-5'>
                        <div className=' flex items-center gap-[6px]   mb-4'>
                          <SparklesIcon className='w-4 h-4 text-dark-black-200' />
                          <p className='text-[13px] leading-4 text-dark-black-200 font-medium uppercase'>AI Summary
                          </p>
                        </div>
                        <p className='text-[14px] text-dark-black-100 font-normal' dangerouslySetInnerHTML={{ __html: feedback?.item_summary }}></p>
                      </div>
                    }
                    <div className='mt-8'>
                      <div className='flex items-center justify-between'>
                        <h4 className='font-medium text-dark-black-200  mr-1 text-[15px] flex items-center gap-[6px]'>
                          <GlobeAltIcon className="w-4 h-4 text-dark-black-200 " />
                          Insights
                        </h4>
                        <Button size='xs-icon'
                          onClick={handleSideUserInghtOpen}
                          className="bg-indigo-600 h-[32px] hover:bg-indigo-500 focus-visible:outline-indigo-600"
                        >Add Insight</Button>
                      </div>

                      {isInsightEmpty ? (
                        <p className='text-gray-500 mt-2 text-[13px]'>No insights available at the moment.</p>
                      ) : (
                        sentimentCategories.map((category) => (

                          <div key={category}>
                            <p className='font-medium text-dark-black-100 mr-1 text-[13px] mt-4'>
                              {capitalizeFirstLetter(category)}
                            </p>
                            {itemInsight[category] && Array?.isArray(itemInsight[category]) && itemInsight[category].length > 0 ? (
                              itemInsight[category]?.map((insight, index) => (

                                <li key={insight.id} className="relative flex gap-x-4 mt-4">
                                  <div className="relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[10px] flex items-center justify-center">
                                    <span className="text-[11px] font-medium text-gray-700">{index + 1}</span>
                                  </div>
                                  {
                                    insight?.itemInsight &&
                                    <div

                                      className="flex-auto rounded-md p-3 cursor-pointer ring-1 ring-inset ring-gray-200 feedback-delete-hover">
                                      <div className='flex items-start'>
                                        <div
                                          className={`${insight?.itemContext ? 'w-[95%] flex flex-col gap-1' : 'w-[95%] flex flex-col gap-0'}`}
                                          onClick={() => handleSideUserInsightView(insight.id)}>
                                          <div className="flex justify-between gap-x-4">
                                            {
                                              insight?.itemInsight &&
                                              <div className="text-[13px] leading-5 text-gray-500 flex items-start">
                                                <span className="font-medium text-dark-black-100 mr-1 leading-4">{insight?.itemInsight}</span>
                                              </div>
                                            }

                                          </div>
                                          <div className='flex items-center'>
                                            {/* {insight?.itemContext &&
                                              <span className='text-[13px] text-gray-500 italic leading-4'>'</span>
                                            } */}
                                            {
                                              insight?.itemContext &&
                                              <p className="text-[13px] text-gray-500 italic leading-4">'{insight?.itemContext}'</p>
                                            }
                                            {/* {insight?.itemContext &&
                                              <span className='text-[13px] text-gray-500 italic leading-4'>'</span>
                                            } */}


                                          </div>
                                          {
                                            insight.attached_item &&
                                            <div className='border-t border-solid border-light-gray-200 pt-3  mt-3'>
                                              {/* <Bars3Icon  className='w-4 h-4'/> */}
                                              <div className='flex items-center gap-1'>
                                                {
                                                  insight.attached_item.item_refernce_id &&
                                                  <div className='flex items-center'>
                                                    <span className='text-[13px] text-gray-500 leading-4'>
                                                      {insight.attached_item.item_refernce_id}
                                                      <span className='text-[13px] text-gray-500 leading-4  ml-1'>
                                                        -
                                                      </span>
                                                    </span>
                                                  </div>
                                                }

                                                {
                                                  insight.attached_item.item_title &&
                                                  <span className='text-[13px] text-dark-black-100 leading-4 line-clamp-1'>
                                                    {insight.attached_item.item_title}
                                                  </span>
                                                }

                                              </div>
                                              {
                                                insight.attached_item.item_details &&
                                                <span className='text-[13px] text-gray-500  leading-4 line-clamp-2 mt-2'>
                                                  {insight.attached_item.item_details}
                                                </span>
                                              }

                                            </div>
                                          }

                                        </div>
                                        <div className='w-[5%]'>
                                          <div className="[&>div]:shrink feedback-delete-btn-hover">


                                            <MenuDropdown
                                              itemId={insight?.id}
                                              onDelete={handleInsightData}
                                              groups={deleteAndEditGroup}
                                              style={{ backgroundColor: 'lightblue', border: '1px solid gray' }}  // Pass custom styles here
                                              image={
                                                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                  <circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle>
                                                </svg>
                                              } />

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  }

                                  {
                                    !insight.itemInsight &&
                                    <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 feedback-delete-hover flex flex-row-reverse justify-between">
                                      <div className="flex justify-between gap-x-4">
                                        {
                                          insight.itemInsight &&
                                          <div className="text-[13px] leading-5 text-gray-500 flex items-start">
                                            <span className="font-medium text-dark-black-100 mr-1 leading-4">{insight.itemInsight}</span>
                                          </div>
                                        }

                                        <div className="[&>div]:shrink feedback-delete-btn-hover">
                                          <button
                                            className="group shrink-0 select-none text-sm leading-6 transition-colors duration-100 wg-antialiased 
                           focus:outline-0 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:pointer-events-none
                            gap-1 outline-primary disabled:opacity-50 text-[#25252d] [&>span]:w-full
                             [&>span]:flex [&>span]:justify-end [&>span]:items-end [&>span]:px-0 font-medium !outline-none focus:outline-none justify-between 
                             p-0 bg-transparent  w-full rounded-lg inline-flex max-w-full cursor-pointer items-center"
                                            type="button"
                                            aria-haspopup="menu"
                                            aria-expanded="false"
                                            data-state="closed"
                                          >

                                            <MenuDropdown
                                              itemId={insight.id}
                                              onDelete={handleInsightData}
                                              groups={deleteAndEditGroup} image={
                                                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                  <circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle>
                                                </svg>
                                              } />
                                          </button>
                                        </div>
                                      </div>
                                      <div className='flex items-center'>
                                        {insight?.itemContext &&
                                          <span className='text-[13px] text-gray-500 italic leading-4'>'</span>
                                        }
                                        <p className="text-[13px] text-gray-500 italic leading-4">{insight?.itemContext}</p>
                                        {insight?.itemContext &&
                                          <span className='text-[13px] text-gray-500 italic leading-4'>'</span>
                                        }

                                      </div>
                                    </div>
                                  }

                                </li>
                              ))
                            ) : (
                              <p>No insights available for this category.</p>
                            )}

                          </div>
                        ))
                      )}
                    </div>


                    <div className="mt-[32px]">
                      {/* {currentFeedback?.id && */}
                      <h4 className='font-medium text-dark-black-200  mr-1 text-[15px] flex items-center gap-[6px]'>
                        <RssIcon className="w-4 h-4 text-dark-black-200 " />
                        Activity</h4>
                      <>
                        <ul role="list" className="space-y-6 mt-[0.9rem]">
                          {itemStatusActivity.map((activityItem, activityItemIdx) => {

                            const timeAgo = formatDistance(new Date(activityItem.created_at), new Date(), { includeSeconds: true });
                            const cleanedTimeAgo = timeAgo.replace(/^about\s/, '');
                            // const memberDetails = getMemberDetails(activityItem.created_by);
                            return (
                              <li key={activityItem.id} className="relative flex gap-x-4">
                                <div
                                  className={classNames(
                                    activityItemIdx === itemStatusActivity?.length - 1 ? 'h-6' : '-bottom-6',
                                    'absolute left-0 top-0 flex w-6 justify-center'
                                  )}
                                >
                                  <div className="w-px bg-gray-200" />
                                </div>
                                {activityItem.item_activity_type === 'comment' || activityItem.item_activity_type === 'email' ? (
                                  <>
                                    {/* <img
                                      src={activityItem?.person?.imageUrl}
                                      alt=""
                                      className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50" /> */}
                                    <div className="relative flex-none w-6 h-6 rounded-full bg-gray-200 text-[10px] flex items-center justify-center">
                                      <span className="text-[7px] font-medium text-gray-700"></span>
                                      {typeof getMemberDetails(activityItem.created_by, activityItem.creator_type) === 'string' &&
                                        getMemberDetails(activityItem.created_by, activityItem.creator_type).length > 0 ?
                                        getMemberDetails(activityItem.created_by, activityItem.creator_type).charAt(0).toUpperCase() :
                                        ''}
                                    </div>
                                    <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 feedback-delete-hover">
                                      <div className="flex justify-between gap-x-4">
                                        <div className=" leading-4 text-[13px] text-gray-500 flex items-start">
                                          {activityItem && <span className="font-medium text-dark-black-100 mr-1 flex items-center gap-1">
                                            {activityItem.creator_type === 'ai' &&
                                              <div className='relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[9px] flex items-center justify-center'>
                                                <SparklesIcon className='w-3 h-3 text-dark-black-200' />
                                              </div>
                                            }

                                            {activityItem.creator_type === 'user' &&
                                              <>
                                                {/* <div className='relative flex-none w-6 h-6 rounded-full bg-gray-200 text-[10px] flex items-center justify-center'>
                                                  {typeof getMemberDetails(activityItem.created_by, activityItem.creator_type) === 'string' &&
                                                    getMemberDetails(activityItem.created_by, activityItem.creator_type).length > 0 ?
                                                    getMemberDetails(activityItem.created_by, activityItem.creator_type).charAt(0).toUpperCase() :
                                                    ''}
                                                </div> */}
                                                {getMemberDetails(activityItem.created_by, activityItem.creator_type)}
                                              </>
                                            }


                                            {activityItem.creator_type === 'customer' &&
                                              <>
                                                {/* <div className='relative flex-none w-6 h-6 rounded-full bg-gray-200 text-[10px] flex items-center justify-center'>
                                                  {typeof getMemberDetails(activityItem.created_by, activityItem.creator_type) === 'string' &&
                                                    getMemberDetails(activityItem.created_by, activityItem.creator_type).length > 0 ?
                                                    getMemberDetails(activityItem.created_by, activityItem.creator_type).charAt(0).toUpperCase() :
                                                    ''}
                                                </div> */}
                                                {getMemberDetails(activityItem.created_by, activityItem.creator_type)}
                                              </>
                                            }
                                          </span>}
                                          {item && <span className="font-medium text-dark-black-100 mr-1 flex items-center gap-1">{item?.name}</span>}
                                          commented
                                          <div className='flex items-center gap-[6px] ml-[6px]'>
                                            <span className='flex h-[4px] w-[4px] shrink-0 items-center justify-center rounded-full
                                        border-none text-[0.625rem] font-medium text-white capitalize bg-[#6b7280]'></span>
                                            <time dateTime={activityItem.created_at} className="flex-none text-xs leading-5 text-gray-500">
                                              {cleanedTimeAgo} ago
                                            </time>
                                            {
                                              activityItem.item_activity_type === 'email' && <p className='flex-none text-xs leading-5 text-gray-500'> ( via email )</p>
                                            }
                                            {
                                              activityItem.item_activity_privacy === 'external' && <p className='flex-none text-xs leading-5 text-gray-500'> (shared with customer )</p>
                                            }
                                          </div>
                                        </div>
                                        <div className='[&>div]:shrink feedback-delete-btn-hover'>
                                          <MenuDropdown
                                            itemId={activityItem.id}
                                            onDelete={handleDeleteComment}
                                            groups={dropdownGroups} image={
                                              <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle>
                                              </svg>
                                            } />
                                        </div>
                                      </div>
                                      <p className="text-sm leading-6 text-gray-500">{activityItem.item_activity_text}</p>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                                      <div className="h-1 w-1 rounded-full bg-transparent ring-2 ring-[#7d7f83]" />
                                    </div>
                                    <p className="flex-auto py-0.5 text-[13px] leading-5 text-gray-500 flex items-center">
                                      {activityItem && <span className="font-medium text-dark-black-100 mr-1 flex items-center">
                                        {activityItem.creator_type === 'ai' &&

                                          <SparklesIcon className='w-3 h-3 text-dark-black-200' />

                                        }
                                        {activityItem.creator_type === 'user' &&
                                          <>
                                            {/* <div className='relative flex-none w-6 h-6 rounded-full bg-gray-200 text-[10px] flex items-center justify-center'>
                                              {typeof getMemberDetails(activityItem.created_by, activityItem.creator_type) === 'string' &&
                                                getMemberDetails(activityItem.created_by, activityItem.creator_type).length > 0 ?
                                                getMemberDetails(activityItem.created_by, activityItem.creator_type).charAt(0).toUpperCase() :
                                                ''}
                                            </div> */}

                                          </>
                                        }


                                        {activityItem.creator_type === 'integration' &&
                                          <>

                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              strokeWidth="1.5"
                                              stroke="currentColor"
                                              aria-hidden="true"
                                              data-slot="icon"
                                              className="w-3 h-3 text-dark-black-200"
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v2.25A2.25 2.25 0 0 0 6 10.5Zm0 9.75h2.25A2.25 2.25 0 0 0 10.5 18v-2.25a2.25 2.25 0 0 0-2.25-2.25H6a2.25 2.25 0 0 0-2.25 2.25V18A2.25 2.25 0 0 0 6 20.25Zm9.75-9.75H18a2.25 2.25 0 0 0 2.25-2.25V6A2.25 2.25 0 0 0 18 3.75h-2.25A2.25 2.25 0 0 0 13.5 6v2.25a2.25 2.25 0 0 0 2.25 2.25Z"
                                              />
                                            </svg>


                                            <div className='text-dark-black-200 ml-1 flex items-center gap-1'>Integration {activityItem.item_activity_text}</div>
                                          </>
                                        }

                                        {activityItem.creator_type === 'ai' &&
                                          <>
                                            {getMemberDetails(activityItem.created_by)}
                                            <div className='text-dark-black-200 ml-1 flex items-center gap-1'>
                                              {activityItem.item_activity_text}
                                            </div>
                                          </>
                                        }
                                        {activityItem.creator_type === 'user' &&
                                          <>
                                            {getMemberDetails(activityItem.created_by, activityItem.creator_type)}

                                            <span className=''>
                                              {activityItem.item_activity_type === 'comment' && 'commented'}</span>
                                            <div className='text-dark-black-200 ml-1 flex items-center gap-1'> {activityItem.item_activity_text}</div>
                                          </>
                                        }


                                        {activityItem.creator_type === 'customer' &&
                                          <>
                                            {getMemberDetails(activityItem.created_by, activityItem.creator_type)}

                                            <span className='mr-[3px]'>
                                              {activityItem.item_activity_type === 'comment' && 'commented'}</span>
                                            <div className='text-dark-black-200 ml-1 flex items-center gap-1'> {activityItem.item_activity_text}</div>
                                          </>
                                        }

                                      </span>}
                                      {/* {item && <span className="font-medium text-dark-black-100 mr-1">{item?.name}</span>}
                                      <span className='text-dark-black-200 ml-1 block'>
                                        {activityItem.item_activity_text}
                                      </span> */}
                                    </p>
                                    {/* <div className='[&>div]:shrink'>
                                      <MenuDropdown groups={dropdownGroups} image={
                                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle>
                                        </svg>
                                      } />
                                    </div> */}

                                  </>
                                )}
                              </li>
                            )
                          })}
                        </ul>

                      </>


                      {comments
                        .filter(comment => comment.feedbackId === currentFeedback?.id)
                        .map(comment => (
                          <div key={comment.id} className="mt-4">
                            <ul>
                              <li className="relative flex gap-x-4 flex-col">
                                <div className='items-center relative flex gap-x-4'>
                                  <div className="h-8 absolute left-0 top-[-36px] flex w-6 justify-center z-[-99999999]">
                                    <div className="w-px bg-gray-200"></div>
                                  </div>

                                  <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                                    <img
                                      src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                      alt=""
                                      className="relative h-6 w-6 flex-none rounded-full bg-gray-50" />

                                  </div>
                                  <div className='flex items-start justify-between w-full'>
                                    <p className="flex-auto text-[13px] leading-5 text-gray-500 flex items-center"><span className="font-medium text-dark-black-100 mt-[-4px]">{comment.author}</span>

                                      <span className='text-dark-black-200 ml-1 block mt-[-4px]'> changed status to active</span>
                                    </p>
                                    <time dateTime="2023-01-24T09:20" className="flex-none py-0.5 text-xs leading-5 text-gray-500">{comment.createdAt}</time>
                                  </div>
                                </div>
                                <p className="text-sm leading-6 text-gray-500 pl-10 mt-[-8px]">{comment.text}</p>
                              </li>
                            </ul>
                          </div>
                        ))}

                      {/* {currentFeedback && ( */}

                      <div className="mt-10">
                        <textarea
                          className="outline-none bg-transparent focus:outline-none focus:ring-0 text-[14px] text-dark-black-100 w-full m-0 placeholder:text-[#9e9ea0]
                         border-1 border-solid border-light-gray-200 rounded-md p-2"
                          placeholder="Write a comment..."
                          value={commentText}
                          onChange={(e) => setCommentText(e.target.value)}
                        >
                        </textarea>
                        {commentError && <p className="text-xs text-red-600">{commentError}</p>}

                        <div className='flex items-center justify-between mb-[55px]'>
                          <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                              <input
                                id="comments"
                                name="comments"
                                type="checkbox"
                                checked={shareWithCustomer}
                                onChange={(e) => setShareWithCustomer(e.target.checked)}
                                aria-describedby="comments-description"
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label htmlFor="comments" className="font-medium text-dark-black-100 text-[13px]">
                                Share comment with customer
                              </label>
                              <p id="comments-description" className="text-[13px] leading-5 text-gray-500">
                                Kindly add customer email id to share updates.
                              </p>
                            </div>
                          </div>


                          <div className='flex justify-end'>
                            <Button
                              onClick={() => handleCommentSubmit(feedback.board_id)}
                              type="button"
                              className="bg-indigo-600 h-[32px] hover:bg-indigo-500 focus-visible:outline-indigo-600 mt-5 mb-8"
                              size="sm"
                            >
                              Comment
                            </Button>

                          </div>
                        </div>
                      </div>
                      {/* )}  */}
                    </div>
                  </div>
                </div>



                {/* feedback sidebar */}
                <div
                  className={`${headernone ? ' main-feedback-side flex flex-col min-w-[200px] bg-white border-t-0 border border-light-gray-200 border-solid absolute lg:static top-0 right-0 max-w-[80%] view-feedback-right-side' : 'flex flex-col min-w-[200px] bg-white border-t-0 border border-light-gray-200 border-solid absolute lg:static top-0 right-0 max-w-[80%] view-feedback-right-side'}`}


                  style={{
                    width: isAsideOpen ? '350px' : '350px',
                    transform: isAsideOpen ? 'none' : 'none',
                    marginRight: isAsideOpen ? '0' : '-400px',
                    height: headernone ? 'calc(100vh - 46px)' : '',
                    // boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1),0 2px 4px -1px rgba(0, 0, 0, 0.06)',
                    ...(window.innerWidth <= 768 && {
                      width: isAsideOpen ? '300px' : '300px',
                      marginRight: isAsideOpen ? '0' : '-300px',
                    }),
                  }}
                >
                  <div className='h-full'>
                    {/* <aside className='flex flex-col justify-stretch min-h-[3rem] border-b border-t-0 border-l-0 border-r border-solid border-light-gray-200 p-[1.5rem] items-start gap-4'>
                      <div className="flex items-center max-w-full font-[500] leading-[1.2] bg-light-gray-100 rounded-full text-[0.75rem] px-[0.5rem] py-[5px] gap-2">
                        <span
                          className="flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-white capitalize"
                          style={{ backgroundColor: "rgb(42, 180, 217)" }}
                        />
                        <span className="text-[#52555a] capitalize">lead</span>
                      </div>
                      <div className='flex items-center justify-between w-full'>
                        <a href="#" className="block flex-1">
                          <div className="relative flex min-w-0 flex-1 items-center">
                            <img className="h-9 w-9 rounded-full"
                              src="https://picsum.photos/200/300"
                              alt="" />
                            <div className="ml-2 truncate">
                              <Input
                                type="name"
                                value={editName}
                                onChange={handleChangeNameEdit}
                                placeholder="Enter your name"
                                className='truncate text-[13px] font-normal h-[22px] text-dark-black-100 border !border-white !outline-none !ring-0 p-0
                                   shadow-none placeholder:text-[12px] placeholder:text-[#7d7f83]  border-solid hover:!border-light-gray-200 px-2  rounded-md'
                              />
                              <Input
                                type="email"
                                value={editEmail}
                                onChange={handleChangeEmailEdit}
                                placeholder="Enter your email address"
                                className='truncate text-[13px] font-normal h-[22px] text-dark-black-200  border !border-white !outline-none !ring-0 p-0
                                   shadow-none placeholder:text-[12px] placeholder:text-[#7d7f83]  border-solid hover:!border-light-gray-200 px-2  rounded-md'
                              />

                            </div>
                          </div>
                        </a>
                        <div className='flex items-center text-start  leading-[1rem] flex-1 max-w-[50px] text-dark-black-200 justify-end [&>button]:w-auto  [&>button]:bg-transparent  [&>button]:hover:bg-transparent'>
                          <MenuDropdown groups={dropdownGroups} image={
                            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle>
                            </svg>
                          } />
                        </div>

                      </div>
                    </aside> */}
                    <ViewFeedbackDetails
                      setItemStatusActivity={setItemStatusActivity}
                      setItemInsight={setItemInsight}
                      itemId={itemId}
                      setLocalLoading={setLocalLoading}
                    />

                    <CustomerFeedback setItemInsight={setItemInsight} itemId={itemId} setItemStatusActivity={setItemStatusActivity} data1={data} />
                  </div>

                </div>
                {/*add insight sidebar */}
                <div className='flex flex-col min-w-[200px] bg-white border-t-0 border border-light-gray-200 border-solid absolute lg:fixed right-0 max-w-[80%] view-feedback-right-side'
                  style={{
                    width: isAddInsightAsideOpen ? '351px' : '350px',
                    transform: isAddInsightAsideOpen ? 'none' : 'none',
                    marginRight: isAddInsightAsideOpen ? '0' : '-400px',
                    top: headernone ? '102px' : '46px',
                    ...(window.innerWidth <= 768 && {
                      width: isAddInsightAsideOpen ? '300px' : '300px',
                      marginRight: isAddInsightAsideOpen ? '0' : '-300px'
                    }),
                  }}
                >
                  <div className='h-full'>
                    <aside className='flex flex-col justify-stretch
                     border-l-0 border-r border-solid border-light-gray-200 p-[13px] items-start gap-4'>

                      <div className="mx-auto w-full flex items-center justify-between">
                        <p className="text-center text-wedges-gray text-sm font-medium">
                          {isEditingInsight ? 'Edit Insight' : 'Add Insight'}
                        </p>
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                          onClick={() => setIsAddInsightAsideOpen(false)}
                        >
                          <span className="sr-only">Close</span>
                          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                        </button>

                      </div>
                      <div className='w-full'>
                        <div className='w-full mt-5 flex flex-col gap-6'>
                          <div className="relative">
                            <label
                              htmlFor="itemInsight"
                              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                            >
                              Insight
                            </label>
                            <textarea
                              id="itemInsight"
                              name="itemInsight"
                              value={formDataInsight?.itemInsight}
                              ref={textareaRef}
                              onChange={handleChangeInsight}
                              maxLength={300}
                              type="text"
                              className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2
                 focus:ring-inset focus:ring-indigo-600  sm:leading-6  h-[36px]"
                            />
                          </div>
                          <div className="relative">
                            <label
                              htmlFor="itemContext"
                              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                            >
                              Context
                            </label>
                            <textarea
                              id="itemContext"
                              name="itemContext"
                              value={formDataInsight?.itemContext}
                              ref={textareaRef1}
                              onChange={handleChangeInsight}
                              maxLength={300}
                              type="text"
                              className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 
                focus:ring-inset focus:ring-indigo-600  sm:leading-6  h-[36px]"
                            />
                          </div>
                          <div className='mt-[-2px] relative'>
                            <label
                              htmlFor="sentiment"
                              className={`absolute ${selectedInsight ? 'top-[-0px]' : 'top-[3px]'} left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]`}
                              style={{ zIndex: '99' }}
                            >
                              Sentiment
                            </label>
                            <Listbox value={selectedInsight} onChange={setSelectedInsight}>
                              <div className="relative mt-2">
                                <ListboxButton className="relative h-[36px] w-full cursor-pointer rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-[#52555a] shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  <span className="block truncate text-[13px] text-[#52555a]">{selectedInsight.name || selectedInsight}</span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronDownIcon aria-hidden="true" className="h-4 w-4 text-gray-400" />
                                  </span>
                                </ListboxButton>

                                <ListboxOptions
                                  transition
                                  className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 
                                  focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-[13px]"
                                >
                                  {sentimentOptionsInsight.map((person) => (
                                    <ListboxOption
                                      key={person.id}
                                      value={person}
                                      className="group relative h-[36px] select-none py-[6px] pl-4 pr-4 text-[#52555a] data-[focus]:bg-gray-200 data-[focus]:text-[#52555a] cursor-pointer"
                                    >
                                      <span className="block truncate font-normal group-data-[selected]:font-semibold">{person.name}</span>

                                    </ListboxOption>
                                  ))}
                                </ListboxOptions>
                              </div>
                            </Listbox>
                          </div>
                          {
                            formDataInsight.attached_item === null &&
                            <>
                              <div className="relative" ref={searchContainerRef}>
                                <label
                                  htmlFor="searchTerm"
                                  className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                >
                                  Ticket Search
                                </label>
                                <input
                                  id="searchTerm"
                                  name="searchTerm"
                                  ref={searchInputRef}
                                  value={searchTerm}

                                  onChange={handleTopicSearch}
                                  onKeyPress={handleKeyPress}
                                  type="search"
                                  className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
      ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 
      focus:ring-inset focus:ring-indigo-600 sm:leading-6 h-[36px] pr-10"
                                />
                                <div className="absolute inset-y-0 right-0 flex items-center pr-3">


                                  <MagnifyingGlassIcon
                                    className="h-5 w-5 text-gray-400 cursor-pointer"
                                    onClick={handleSearchIconClick}
                                  />

                                </div>
                              </div>

                              {
                                searchTerm &&
                                <>
                                  {searchResults.length > 0 && (

                                    <>
                                      {searchResults.map((result, index) => (
                                        <div className={`${attachedTopics[result.id] ? 'bg-blue-50 rounded-md p-3  text-blue-700 ring-blue-700/10' : 'flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 feedback-delete-hover'}`}>
                                          <div className="flex justify-between gap-x-4">
                                            <div className="text-[13px] leading-5 text-gray-500 flex items-center">
                                              {
                                                result.item_refernce_id &&
                                                <div className="flex items-center">
                                                  <p className='mr-1 text-[13px]'>
                                                    {result.item_refernce_id}
                                                  </p>
                                                  <span className="text-[#b1b1b1] text-[13px] mr-1">-</span>
                                                </div>
                                              }
                                              {
                                                result.item_sentiment &&
                                                <div className="flex items-center">
                                                  <p className='mr-1 text-[13px] capitalize'>
                                                    {result.item_sentiment}
                                                  </p>
                                                </div>
                                              }

                                            </div>


                                            <div
                                              className={`${attachedTopics[result.id] ? ' opacity-100' : 'feedback-delete-btn-hover'}`}>
                                              <button className='text-[13px] text-[#266df0]' onClick={handleAttachTopic(result.id)}
                                              >
                                                {attachedTopics[result.id] ? 'Selected' : 'Select'}
                                              </button>
                                            </div>

                                          </div>
                                          {
                                            result.item_title &&
                                            <span className="text-[13px] font-medium text-dark-black-100 mr-1 leading-4 line-clamp-2 mt-[5px]">
                                              {result.item_title}
                                            </span>
                                          }
                                          {
                                            result.item_details &&
                                            <div className="flex items-center">

                                              <p className="text-[13px] text-gray-500 leading-4 line-clamp-3 mt-[5px]">
                                                {result.item_details}
                                              </p>

                                            </div>
                                          }

                                        </div>

                                      ))}
                                    </>
                                  )}
                                </>
                              }
                            </>


                          }

                          <>
                            {
                              removeTopic &&
                              <>
                                <div className="relative" ref={searchContainerRef}>
                                  <label
                                    htmlFor="searchTerm"
                                    className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                  >
                                    Ticket Search
                                  </label>
                                  <input
                                    id="searchTerm"
                                    name="searchTerm"
                                    ref={searchInputRef}
                                    value={searchTerm}

                                    onChange={handleTopicSearch}
                                    onKeyPress={handleKeyPress}
                                    type="search"
                                    className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
  ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 
  focus:ring-inset focus:ring-indigo-600 sm:leading-6 h-[36px] pr-10"
                                  />
                                  <div className="absolute inset-y-0 right-0 flex items-center pr-3">


                                    <MagnifyingGlassIcon
                                      className="h-5 w-5 text-gray-400 cursor-pointer"
                                      onClick={handleSearchIconClick}
                                    />

                                  </div>
                                </div>
                                {
                                  searchTerm &&
                                  <>
                                    {searchResults.length > 0 && (

                                      <>
                                        {searchResults.map((result, index) => (
                                          <div className={`${attachedTopics[result.id] ? 'bg-blue-50 rounded-md p-3  text-blue-700 ring-blue-700/10' : 'flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 feedback-delete-hover'}`}>
                                            <div className="flex justify-between gap-x-4">
                                              <div className="text-[13px] leading-5 text-gray-500 flex items-center">
                                                {
                                                  result.item_refernce_id &&
                                                  <div className="flex items-center">
                                                    <p className='mr-1 text-[13px]'>
                                                      {result.item_refernce_id}
                                                    </p>
                                                    <span className="text-[#b1b1b1] text-[13px] mr-1">-</span>
                                                  </div>
                                                }
                                                {
                                                  result.item_sentiment &&
                                                  <div className="flex items-center">
                                                    <p className='mr-1 text-[13px] capitalize'>
                                                      {result.item_sentiment}
                                                    </p>
                                                  </div>
                                                }

                                              </div>


                                              <div
                                                className={`${attachedTopics[result.id] ? ' opacity-100' : 'feedback-delete-btn-hover'}`}>
                                                <button className='text-[13px] text-[#266df0]' onClick={handleAttachTopic(result.id)}
                                                >
                                                  {attachedTopics[result.id] ? 'Selected' : 'Select'}
                                                </button>
                                              </div>

                                            </div>
                                            {
                                              result.item_title &&
                                              <span className="text-[13px] font-medium text-dark-black-100 mr-1 leading-4 line-clamp-2 mt-[5px]">
                                                {result.item_title}
                                              </span>
                                            }
                                            {
                                              result.item_details &&
                                              <div className="flex items-center">

                                                <p className="text-[13px] text-gray-500 leading-4 line-clamp-3 mt-[5px]">
                                                  {result.item_details}
                                                </p>

                                              </div>
                                            }

                                          </div>

                                        ))}
                                      </>
                                    )}
                                  </>
                                }
                              </>
                            }

                            {
                              !removeTopic &&
                              <>
                                {formDataInsight.attached_item && !Array.isArray(formDataInsight.attached_item) && (

                                  <div className={`${formDataInsight.attached_item ? 'bg-blue-50 rounded-md p-3  text-blue-700 ring-blue-700/10' : 'flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 feedback-delete-hover'}`}>
                                    <div className="flex justify-between gap-x-4">
                                      <div className="text-[13px] leading-5 text-gray-500 flex items-center">
                                        {
                                          formDataInsight.attached_item.item_refernce_id &&
                                          <div className="flex items-center">
                                            <p className='mr-1 text-[13px]'>
                                              {formDataInsight.attached_item.item_refernce_id}
                                            </p>
                                            <span className="text-[#b1b1b1] text-[13px] mr-1">-</span>
                                          </div>
                                        }
                                        {
                                          formDataInsight.attached_item.item_sentiment &&
                                          <div className="flex items-center">
                                            <p className='mr-1 text-[13px] capitalize'>
                                              {formDataInsight.attached_item.item_sentiment}
                                            </p>
                                          </div>
                                        }

                                      </div>


                                      <div
                                        className={`${formDataInsight.attached_item ? ' opacity-100' : ' opacity-100'}`}>
                                        <button className='text-[13px] text-[#266df0]'
                                          onClick={() => setRemoveTopic(true)}
                                        >
                                          {formDataInsight.attached_item ? 'Remove' : 'Remove'}
                                        </button>
                                      </div>

                                    </div>
                                    {
                                      formDataInsight.attached_item.item_title &&
                                      <span className="text-[13px] font-medium text-dark-black-100 mr-1 leading-4 line-clamp-2 mt-[5px]">
                                        {formDataInsight.attached_item.item_title}
                                      </span>
                                    }
                                    {
                                      formDataInsight.attached_item.item_details &&
                                      <div className="flex items-center">

                                        <p className="text-[13px] text-gray-500 leading-4 line-clamp-3 mt-[5px]">
                                          {formDataInsight.attached_item.item_details}
                                        </p>

                                      </div>
                                    }

                                  </div>

                                )}
                              </>
                            }
                          </>


                        </div>
                        <div className='flex items-center justify-end mt-5'>
                          {isEditingInsight ?

                            <button
                              type="submit"
                              onClick={handleInsightUpdate}
                              // onClick={() => handleInsightUpdate(insight.id)}
                              className="inline-flex items-center relative justify-center whitespace-nowrap rounded-[5px] bg-indigo-600 font-medium leading-normal text-[#fefeff] min-w-[32px] h-[32px] px-[14px] text-[0.8125rem]"
                            >
                              Save
                            </button>
                            :
                            <button
                              type="submit"
                              onClick={handleInsightSubmit}
                              className="inline-flex items-center relative justify-center whitespace-nowrap rounded-[5px] bg-indigo-600 font-medium leading-normal text-[#fefeff] min-w-[32px] h-[32px] px-[14px] text-[0.8125rem]"
                            >
                              Save
                            </button>
                          }

                        </div>
                      </div>


                    </aside>
                  </div>

                </div>
                {/* edit insight sidebar */}
                <div className='flex flex-col min-w-[200px] bg-white border-t-0 border border-light-gray-200 border-solid absolute lg:fixed right-0 max-w-[80%] view-feedback-right-side'
                  style={{
                    width: isEditInsightAsideOpen ? '351px' : '350px',
                    transform: isEditInsightAsideOpen ? 'none' : 'none',
                    marginRight: isEditInsightAsideOpen ? '0' : '-400px',
                    top: headernone ? '102px' : '46px',
                    ...(window.innerWidth <= 768 && {
                      width: isEditInsightAsideOpen ? '300px' : '300px',
                      marginRight: isEditInsightAsideOpen ? '0' : '-300px'
                    }),
                  }}
                >
                  <div className='h-full'>
                    <aside className='flex flex-col justify-stretch
                     border-l-0 border-r border-solid border-light-gray-200 p-[13px] items-start gap-4'>

                      <div className="mx-auto w-full flex items-center justify-between">
                        <p className="text-center text-wedges-gray text-sm font-medium">
                          {isEditingInsight ? 'Edit Insight' : 'Add Insight'}
                        </p>
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                          onClick={() => setIsEditInsightAsideOpen(false)}
                        >
                          <span className="sr-only">Close</span>
                          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                        </button>

                      </div>
                      <div className='w-full'>
                        <div className='w-full mt-5 flex flex-col gap-6'>
                          <div className="relative">
                            <label
                              htmlFor="itemInsight"
                              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                            >
                              Insight
                            </label>
                            <textarea
                              id="itemInsight"
                              name="itemInsight"
                              value={formDataInsight?.itemInsight}
                              ref={textareaRef}
                              onChange={handleChangeInsight}
                              maxLength={300}
                              type="text"
                              className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2
                 focus:ring-inset focus:ring-indigo-600  sm:leading-6  h-[36px]"
                            />
                          </div>
                          <div className="relative">
                            <label
                              htmlFor="itemContext"
                              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                            >
                              Context
                            </label>
                            <textarea
                              id="itemContext"
                              name="itemContext"
                              value={formDataInsight?.itemContext}
                              ref={textareaRef1}
                              onChange={handleChangeInsight}
                              maxLength={300}
                              type="text"
                              className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 
                focus:ring-inset focus:ring-indigo-600  sm:leading-6  h-[36px]"
                            />
                          </div>
                          <div className='mt-[-2px] relative'>
                            <label
                              htmlFor="sentiment"
                              className={`absolute ${selectedInsight ? 'top-[-0px]' : 'top-[3px]'} left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]`}
                              style={{ zIndex: '99' }}
                            >
                              Sentiment
                            </label>
                            <Listbox value={selectedInsight} onChange={setSelectedInsight}>
                              <div className="relative mt-2">
                                <ListboxButton className="relative h-[36px] w-full cursor-pointer rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-[#52555a] shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  <span className="block truncate text-[13px] text-[#52555a]">{selectedInsight.name || selectedInsight}</span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronDownIcon aria-hidden="true" className="h-4 w-4 text-gray-400" />
                                  </span>
                                </ListboxButton>

                                <ListboxOptions
                                  transition
                                  className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 
                                  focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-[13px]"
                                >
                                  {sentimentOptionsInsight.map((person) => (
                                    <ListboxOption
                                      key={person.id}
                                      value={person}
                                      className="group relative h-[36px] select-none py-[6px] pl-4 pr-4 text-[#52555a] data-[focus]:bg-gray-200 data-[focus]:text-[#52555a] cursor-pointer"
                                    >
                                      <span className="block truncate font-normal group-data-[selected]:font-semibold">{person.name}</span>

                                    </ListboxOption>
                                  ))}
                                </ListboxOptions>
                              </div>
                            </Listbox>
                          </div>
                          {
                            formDataInsight.attached_item === null &&
                            <>
                              <div className="relative" ref={searchContainerRef}>
                                <label
                                  htmlFor="searchTerm"
                                  className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                >
                                  Ticket Search
                                </label>
                                <input
                                  id="searchTerm"
                                  name="searchTerm"
                                  ref={searchInputRef}
                                  value={searchTerm}

                                  onChange={handleTopicSearch}
                                  onKeyPress={handleKeyPress}
                                  type="search"
                                  className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
      ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 
      focus:ring-inset focus:ring-indigo-600 sm:leading-6 h-[36px] pr-10"
                                />
                                <div className="absolute inset-y-0 right-0 flex items-center pr-3">


                                  <MagnifyingGlassIcon
                                    className="h-5 w-5 text-gray-400 cursor-pointer"
                                    onClick={handleSearchIconClick}
                                  />

                                </div>
                              </div>

                              {
                                searchTerm &&
                                <>
                                  {searchResults.length > 0 && (

                                    <>
                                      {searchResults.map((result, index) => (
                                        <div className={`${attachedTopics[result.id] ? 'bg-blue-50 rounded-md p-3  text-blue-700 ring-blue-700/10' : 'flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 feedback-delete-hover'}`}>
                                          <div className="flex justify-between gap-x-4">
                                            <div className="text-[13px] leading-5 text-gray-500 flex items-center">
                                              {
                                                result.item_refernce_id &&
                                                <div className="flex items-center">
                                                  <p className='mr-1 text-[13px]'>
                                                    {result.item_refernce_id}
                                                  </p>
                                                  <span className="text-[#b1b1b1] text-[13px] mr-1">-one</span>
                                                </div>
                                              }
                                              {
                                                result.item_sentiment &&
                                                <div className="flex items-center">
                                                  <p className='mr-1 text-[13px] capitalize'>
                                                    {result.item_sentiment}
                                                  </p>
                                                </div>
                                              }

                                            </div>


                                            <div
                                              className={`${attachedTopics[result.id] ? ' opacity-100' : 'feedback-delete-btn-hover'}`}>
                                              <button className='text-[13px] text-[#266df0]' onClick={handleAttachTopic(result.id)}
                                              >
                                                {attachedTopics[result.id] ? 'Selected' : 'Select'}
                                              </button>
                                            </div>

                                          </div>
                                          {
                                            result.item_title &&
                                            <span className="text-[13px] font-medium text-dark-black-100 mr-1 leading-4 line-clamp-2 mt-[5px]">
                                              {result.item_title}
                                            </span>
                                          }
                                          {
                                            result.item_details &&
                                            <div className="flex items-center">

                                              <p className="text-[13px] text-gray-500 leading-4 line-clamp-3 mt-[5px]">
                                                {result.item_details}
                                              </p>

                                            </div>
                                          }

                                        </div>

                                      ))}
                                    </>
                                  )}
                                </>
                              }
                            </>


                          }

                          <>
                            {
                              removeTopic &&
                              <>
                                <div className="relative" ref={searchContainerRef}>
                                  <label
                                    htmlFor="searchTerm"
                                    className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                  >
                                    Ticket Search
                                  </label>
                                  <input
                                    id="searchTerm"
                                    name="searchTerm"
                                    ref={searchInputRef}
                                    value={searchTerm}

                                    onChange={handleTopicSearch}
                                    onKeyPress={handleKeyPress}
                                    type="search"
                                    className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                          ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 
                                           focus:ring-inset focus:ring-indigo-600 sm:leading-6 h-[36px] pr-10"
                                  />
                                  <div className="absolute inset-y-0 right-0 flex items-center pr-3">


                                    <MagnifyingGlassIcon
                                      className="h-5 w-5 text-gray-400 cursor-pointer"
                                      onClick={handleSearchIconClick}
                                    />

                                  </div>
                                </div>
                                {
                                  searchTerm &&
                                  <>
                                    {searchResults.length > 0 && (

                                      <>
                                        {searchResults.map((result, index) => (
                                          <div className={`${attachedTopics[result.id] ? 'bg-blue-50 rounded-md p-3  text-blue-700 ring-blue-700/10' : 'flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 feedback-delete-hover'}`}>
                                            <div className="flex justify-between gap-x-4">
                                              <div className="text-[13px] leading-5 text-gray-500 flex items-center">
                                                {
                                                  result.item_refernce_id &&
                                                  <div className="flex items-center">
                                                    <p className='mr-1 text-[13px]'>
                                                      {result.item_refernce_id}
                                                    </p>
                                                    <span className="text-[#b1b1b1] text-[13px] mr-1">-two</span>
                                                  </div>
                                                }
                                                {
                                                  result.item_sentiment &&
                                                  <div className="flex items-center">
                                                    <p className='mr-1 text-[13px] capitalize'>
                                                      {result.item_sentiment}
                                                    </p>
                                                  </div>
                                                }

                                              </div>


                                              <div
                                                className={`${attachedTopics[result.id] ? ' opacity-100' : 'feedback-delete-btn-hover'}`}>
                                                <button className='text-[13px] text-[#266df0]' onClick={handleAttachTopic(result.id)}
                                                >
                                                  {attachedTopics[result.id] ? 'Selected' : 'Select'}
                                                </button>
                                              </div>

                                            </div>
                                            {
                                              result.item_title &&
                                              <span className="text-[13px] font-medium text-dark-black-100 mr-1 leading-4 line-clamp-2 mt-[5px]">
                                                {result.item_title}
                                              </span>
                                            }
                                            {
                                              result.item_details &&
                                              <div className="flex items-center">

                                                <p className="text-[13px] text-gray-500 leading-4 line-clamp-3 mt-[5px]">
                                                  {result.item_details}
                                                </p>

                                              </div>
                                            }

                                          </div>

                                        ))}
                                      </>
                                    )}
                                  </>
                                }
                              </>
                            }

                            {
                              !removeTopic &&
                              <>
                                {formDataInsight.attached_item && !Array.isArray(formDataInsight.attached_item) && (

                                  <div className={`${formDataInsight.attached_item ? 'bg-blue-50 rounded-md p-3  text-blue-700 ring-blue-700/10' : 'flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 feedback-delete-hover'}`}>
                                    <div className="flex justify-between gap-x-4">
                                      <div className="text-[13px] leading-5 text-gray-500 flex items-center">
                                        {
                                          formDataInsight.attached_item.item_refernce_id &&
                                          <div className="flex items-center">
                                            <p className='mr-1 text-[13px]'>
                                              {formDataInsight.attached_item.item_refernce_id}
                                            </p>
                                            <span className="text-[#b1b1b1] text-[13px] mr-1">-</span>
                                          </div>
                                        }
                                        {
                                          formDataInsight.attached_item.item_sentiment &&
                                          <div className="flex items-center">
                                            <p className='mr-1 text-[13px] capitalize'>
                                              {formDataInsight.attached_item.item_sentiment}
                                            </p>
                                          </div>
                                        }

                                      </div>


                                      <div
                                        className={`${formDataInsight.attached_item ? ' opacity-100' : ' opacity-100'}`}>
                                        <button className='text-[13px] text-[#266df0]'
                                          onClick={() => setRemoveTopic(true)}
                                        >
                                          {formDataInsight.attached_item ? 'Remove' : 'Remove'}
                                        </button>
                                      </div>

                                    </div>
                                    {
                                      formDataInsight.attached_item.item_title &&
                                      <span className="text-[13px] font-medium text-dark-black-100 mr-1 leading-4 line-clamp-2 mt-[5px]">
                                        {formDataInsight.attached_item.item_title}
                                      </span>
                                    }
                                    {
                                      formDataInsight.attached_item.item_details &&
                                      <div className="flex items-center">

                                        <p className="text-[13px] text-gray-500 leading-4 line-clamp-3 mt-[5px]">
                                          {formDataInsight.attached_item.item_details}
                                        </p>

                                      </div>
                                    }

                                  </div>

                                )}
                              </>
                            }
                          </>


                        </div>
                        <div className='flex items-center justify-end mt-5'>
                          {isEditingInsight ?

                            <button
                              type="submit"
                              onClick={handleInsightUpdate}
                              // onClick={() => handleInsightUpdate(insight.id)}
                              className="inline-flex items-center relative justify-center whitespace-nowrap rounded-[5px] bg-indigo-600 font-medium leading-normal text-[#fefeff] min-w-[32px] h-[32px] px-[14px] text-[0.8125rem]"
                            >
                              Save
                            </button>
                            :
                            <button
                              type="submit"
                              onClick={handleInsightSubmit}
                              className="inline-flex items-center relative justify-center whitespace-nowrap rounded-[5px] bg-indigo-600 font-medium leading-normal text-[#fefeff] min-w-[32px] h-[32px] px-[14px] text-[0.8125rem]"
                            >
                              Save
                            </button>
                          }

                        </div>
                      </div>


                    </aside>
                  </div>

                </div>
                {/* view insight */}

                <div className='flex flex-col min-w-[200px] bg-white border-t-0 border border-light-gray-200 border-solid absolute lg:fixed right-0 max-w-[80%] view-feedback-right-side'
                  style={{
                    width: isViewInsightAsideOpen ? '351px' : '350px',
                    transform: isViewInsightAsideOpen ? 'none' : 'none',
                    marginRight: isViewInsightAsideOpen ? '0' : '-400px',
                    top: headernone ? '102px' : '46px',
                    ...(window.innerWidth <= 768 && {
                      width: isViewInsightAsideOpen ? '300px' : '300px',
                      marginRight: isViewInsightAsideOpen ? '0' : '-300px'
                    }),
                  }}
                >
                  <div className='h-full'>
                    <aside className='flex flex-col justify-stretch
                     border-l-0  border-solid border-light-gray-200  items-start'>

                      <div className="mx-auto w-full flex items-center justify-between p-[13px]">
                        <p className="text-center text-wedges-gray text-sm font-medium">
                          View Insight
                        </p>
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                          onClick={() => setIsViewInsightAsideOpen(false)}
                        >
                          <span className="sr-only">Close</span>
                          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                        </button>

                      </div>
                      {fetchedInsightData && !Array.isArray(fetchedInsightData) && (

                        <div key={`positive_${index}`} className='w-full'>
                          <div className='w-full'>
                            {
                              fetchedInsightData.itemInsight &&
                              <div className="w-full flex items-start justify-between flex-col lg:justify-start py-1 px-[13px]">
                                <div className="mr-2 py-1 px-0 font-normal text-dark-black-200 text-[13px] w-full">
                                  Insight Title
                                </div>
                                <div className="relative w-full">
                                  <span className=" text-[13px] leading-4 whitespace-normal max-w-[140px] w-full text-[#52555a]  text-start break-words font-medium">
                                    {fetchedInsightData.itemInsight}
                                  </span>
                                </div>
                              </div>
                            }
                            {
                              fetchedInsightData.itemContext &&
                              <div className="w-full flex items-start justify-between flex-col lg:justify-start py-1 px-[13px]">
                                <div className="mr-2 py-1 px-0 font-normal text-dark-black-200 text-[13px] w-full">
                                  Insight Context
                                </div>
                                <div className="relative w-full">
                                  <span className=" text-[13px] leading-4 whitespace-normal max-w-[140px] w-full text-[#52555a]  text-start break-words font-medium">
                                    {fetchedInsightData.itemContext}
                                  </span>
                                </div>
                              </div>
                            }
                            {
                              fetchedInsightData.itemContextSentiment &&
                              <div className="w-full flex items-start justify-between
                               flex-col lg:justify-start py-1 px-[13px]"


                              >
                                <div className="mr-2 py-1 px-0 font-normal text-dark-black-200 text-[13px] w-full">
                                  Sentiment
                                </div>
                                <div className="relative w-full">
                                  <span className=" capitalize text-[13px] leading-4 whitespace-normal max-w-[140px] 
                                  w-full text-[#fff]  text-start break-words font-medium rounded-full px-2 py-[6px]"
                                    style={{ backgroundColor: getSentimentColor(fetchedInsightData.itemContextSentiment) }}
                                  >
                                    {fetchedInsightData.itemContextSentiment}
                                  </span>
                                </div>
                              </div>
                            }
                            {/* {fetchedInsightData?.attached_item === null &&
                              <div className='w-full px-[13px]'>
                                <div className="text-left py-4   font-normal text-dark-black-200 text-[13px] mt-2">
                                  Topic
                                </div>
                                {
                                  !viewInsightTopicAdd &&
                                  <Link
                                    to=""
                                    onClick={() => SetviewInsightTopicAdd(true)}

                                    className="text-[13px] text-[#266df0]"
                                    size="sm" >

                                    Add Topic
                                  </Link>
                                }

                                {
                                  viewInsightTopicAdd &&
                                  <Link
                                    to=""
                                    onClick={() => SetviewInsightTopicAdd(false)}

                                    className="text-[13px] text-[#266df0]"
                                    size="sm" >

                                    Close
                                  </Link>
                                }

                              </div>

                            }

                            {fetchedInsightData?.attached_item === null && viewInsightTopicAdd &&

                              <div className=' mt-[2rem] px-[13px]'>
                                <div className="relative" ref={searchContainerRef}>
                                  <label
                                    htmlFor="searchTerm"
                                    className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                  >
                                    Ticket Search
                                  </label>
                                  <input
                                    id="searchTerm"
                                    name="searchTerm"
                                    ref={searchInputRef}
                                    value={searchTerm}

                                    onChange={handleTopicSearch}
                                    onKeyPress={handleKeyPress}
                                    type="search"
                                    className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 
focus:ring-inset focus:ring-indigo-600 sm:leading-6 h-[36px] pr-10"
                                  />
                                  <div className="absolute inset-y-0 right-0 flex items-center pr-3">


                                    <MagnifyingGlassIcon
                                      className="h-5 w-5 text-gray-400 cursor-pointer"
                                      onClick={handleSearchIconClick}
                                    />

                                  </div>
                                </div>

                                {searchResults.length > 0 && (

                                  <>
                                    {searchResults.map((result, index) => (
                                      <div className={`${attachedTopics[result.id] ? 'bg-blue-50 rounded-md p-3 my-5  text-blue-700 ring-blue-700/10' : 'flex-auto my-5 rounded-md p-3 ring-1 ring-inset ring-gray-200 feedback-delete-hover'}`}>
                                        <div className="flex justify-between gap-x-4">
                                          <div className="text-[13px] leading-5 text-gray-500 flex items-center">
                                            {
                                              result.item_refernce_id &&
                                              <div className="flex items-center">
                                                <p className='mr-1 text-[13px]'>
                                                  {result.item_refernce_id}
                                                </p>
                                                <span className="text-[#b1b1b1] text-[13px] mr-1">-</span>
                                              </div>
                                            }
                                            {
                                              result.item_sentiment &&
                                              <div className="flex items-center">
                                                <p className='mr-1 text-[13px] capitalize'>
                                                  {result.item_sentiment}
                                                </p>
                                              </div>
                                            }

                                          </div>


                                          <div
                                            className={`${attachedTopics[result.id] ? ' opacity-100' : 'feedback-delete-btn-hover'}`}>
                                            <button className='text-[13px] text-[#266df0]' onClick={handleAttachTopic(result.id)}
                                            >
                                              {attachedTopics[result.id] ? 'Selected' : 'Select'}
                                            </button>
                                          </div>

                                        </div>
                                        {
                                          result.item_title &&
                                          <span className="text-[13px] font-medium text-dark-black-100 mr-1 leading-4 line-clamp-2 mt-[5px]">
                                            {result.item_title}
                                          </span>
                                        }
                                        {
                                          result.item_details &&
                                          <div className="flex items-center">

                                            <p className="text-[13px] text-gray-500 leading-4 line-clamp-3 mt-[5px]">
                                              {result.item_details}
                                            </p>

                                          </div>
                                        }

                                      </div>

                                    ))}
                                  </>
                                )}
                          
                              </div>


                            } */}



                            {
                              fetchedInsightData?.attached_item &&
                              <>

                                <div className="text-left px-[13px] py-4   font-normal text-dark-black-200 text-[13px]">
                                  Topic
                                </div>
                              </>
                            }
                            {fetchedInsightData?.attached_item &&
                              <div className='bg-blue-50 rounded-md mx-[13px] p-3 text-blue-700 ring-blue-700/10 mb-5'>
                                <div className='flex items-start gap-1'>
                                  {
                                    fetchedInsightData?.attached_item?.id &&
                                    <div className='flex items-center'>
                                      <span className='text-[13px] text-gray-500 leading-4'>
                                        {fetchedInsightData?.attached_item?.item_refernce_id}
                                        <span className='text-[13px] text-gray-500 leading-4  ml-1'>
                                          -
                                        </span>
                                      </span>
                                    </div>
                                  }
                                  {
                                    fetchedInsightData?.attached_item?.item_title &&
                                    <span className='text-[13px] text-dark-black-100 leading-4 font-medium line-clamp-3'>
                                      {fetchedInsightData?.attached_item?.item_title}
                                    </span>
                                  }

                                </div>
                                {
                                  fetchedInsightData?.attached_item?.item_title &&
                                  <>
                                    <div className="w-full flex items-start justify-between flex-col lg:justify-start">
                                      {/* <div className="mr-2 py-1 px-0 font-normal text-dark-black-200 text-[13px] w-full">
                                           Topic Detail
                                         </div> */}
                                      <div className="relative w-full mt-2">
                                        <span className="text-[13px] leading-4  line-clamp-5 font-normal w-full text-[#52555a]">
                                          {fetchedInsightData?.attached_item?.item_details}
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                }
                              </div>
                            }




                          </div>
                        </div>
                      )}
                      <div
                        className={`${fetchedInsightData?.attached_item ? 'mt-0 w-full px-[13px] flex items-center gap-x-1 pb-2' : 'mt-5 w-full px-[13px] flex items-center gap-x-1 pb-2'}`}

                      >
                        <div className="text-left  font-normal text-dark-black-200 text-[13px]">
                          Created
                        </div>

                        {
                          cleanedTimeAgoViewInsight &&
                          <div className="text-[13px] leading-4  line-clamp-5  font-normal text-dark-black-200">
                            {cleanedTimeAgoViewInsight} ago
                          </div>
                        }
                        {
                          createdByName &&
                          <div className="text-[13px] leading-4  line-clamp-5 font-normal text-dark-black-200 flex items-center gap-1"> by
                            {
                              createdByName === 'AI' &&
                              <SparklesIcon className='w-4 h-4 font-normal text-dark-black-200' />
                            }
                            <span className='ml-0'>
                              {createdByName}
                            </span>
                          </div>
                        }


                      </div>
                      {
                        fetchedInsightData?.updated_by &&
                        <div className='w-full px-[13px] flex items-center gap-x-1'>
                          <div className="text-left   font-normal text-dark-black-200 text-[13px]">
                            Updated
                          </div>

                          {
                            cleanedTimeAgoViewInsight1 &&
                            <div className="text-[13px] leading-4  line-clamp-5 font-normal text-dark-black-200">
                              {cleanedTimeAgoViewInsight1} ago
                            </div>
                          }
                          {
                            createdByName1 &&
                            <div className="text-[13px] leading-4  line-clamp-5 font-normal text-dark-black-200"> by {createdByName1}</div>
                          }


                        </div>
                      }



                    </aside>
                  </div>

                </div>
              </main>

            </div>
          </div>
        </div>
      ))}


      <Transition show={imageZoom} as={Fragment}>
        <Dialog as="div" className="relative z-50 md:z-10 lg:z-10" onClose={() => setImageZoom(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center sm:items-start h-full items-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg bg-white px-4 py-4 text-left shadow-xl transition-all sm:mb-8 sm:mt-[32px]  sm:w-full sm:max-w-[680px]">

                  <div className="absolute right-[7px] top-0 hidden pr-4 pt-6 sm:block">
                    <button
                      type="button"
                      className="rounded-md text-black hover:text-black focus:outline-none"
                      onClick={() => setImageZoom(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                  <div>
                    <img
                      src={
                        itemAttachmentShow?.item_attachment?.[0]?.fileUrl
                          ? `${process.env.REACT_APP_API_BASE_URL}/${itemAttachmentShow.item_attachment[0].fileUrl}`
                          : ''
                      }
                      alt="Selected file preview"
                      className=" w-full object-cover rounded-md"
                    />

                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

    </>
  );
};

export default ViewFeedback;

