// @ts-nocheck

import { DELETE_INSIGHT_GROUP_LIST_FAILURE, DELETE_INSIGHT_GROUP_LIST_REQUEST, DELETE_INSIGHT_GROUP_LIST_SUCCESS, DELETE_INSIGHT_LIST_FAILURE, DELETE_INSIGHT_LIST_REQUEST, DELETE_INSIGHT_LIST_SUCCESS, FETCH_DASHBOARD_LIST_FAILURE, FETCH_DASHBOARD_LIST_REQUEST, FETCH_DASHBOARD_LIST_SUCCESS, FETCH_INSIGHT_FAILURE, FETCH_INSIGHT_REQUEST, FETCH_INSIGHT_SUCCESS, InsightActionTypes, InsightState, UPDATE_TICKET_INSIGHT_FAILURE, UPDATE_TICKET_INSIGHT_REQUEST, UPDATE_TICKET_INSIGHT_SUCCESS } from "./insight.types";

const initialState: InsightState = {
    loading: false,
    error: '',
    success: false,
    data: [],
    allActivityItem: {
        itemsActivities: [],
        totalItemActivity: 0,
        hasNextPage: false,
        pageSize: 10,
        totalPages: 0,
    },
    insightGroups: [],
};


const insightReducer = (state = initialState, action: InsightActionTypes): InsightState => {
    switch (action.type) {
        case FETCH_INSIGHT_REQUEST:
            return {
                ...state,
                loading: true,
                error: '',
                success: false
            };
        case DELETE_INSIGHT_GROUP_LIST_REQUEST:
        case DELETE_INSIGHT_LIST_REQUEST:
        case UPDATE_TICKET_INSIGHT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_INSIGHT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                data: [action.payload],
                error: ''
            };
        case DELETE_INSIGHT_GROUP_LIST_SUCCESS:
        case DELETE_INSIGHT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                insightGroups: state.insightGroups?.filter((group: { id: number; }) => {
                    const deletedItem = action.payload.find((item: { id: number; isSuccess: boolean; }) => item.id === group.id);
                    return !deletedItem || !deletedItem.isSuccess;
                }),
            };
        case UPDATE_TICKET_INSIGHT_SUCCESS:
            return {
                ...state,
                loading: false,
                insightGroups: state.insightGroups.filter((group: { id: number; }) => !action.payload.includes(group.id)),
            };
        case FETCH_INSIGHT_FAILURE:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case DELETE_INSIGHT_GROUP_LIST_FAILURE:
        case DELETE_INSIGHT_LIST_FAILURE:
        case UPDATE_TICKET_INSIGHT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default insightReducer;
