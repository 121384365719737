// @ts-nocheck
import React, { useEffect, useRef, useState } from "react"
import { v4 as uuidv4 } from 'uuid';
import Datepicker from "react-tailwindcss-datepicker";
import { format } from 'date-fns';
import InsightTable from "../../insights/list/InsightTable";
// import DragList from "../../insights/insightKanban/DragList";
import { fetchInsightGroupListData } from "../../insights/insight-redux/insight.actions";
import { useAppDispatch } from "../../../hooks/hooks";
import InsightGroupTable from "../../insights/list/InsightGroupTable";
import { useLocation, useOutletContext } from "react-router-dom";
import { useSearch } from "../../../context/SearchContext";
import { Sentiment } from "../../integration/type";


// Define the sentiments
const sentiments: Sentiment[] = [
  { id: 101, name: 'positive', label: '#16a34a33' },
  { id: 102, name: 'negative', label: '#dc26261a' },
  { id: 104, name: 'request', label: '#1d4ed81a' },
];

export default function Insights() {
  const [activeButton, setActiveButton] = useState('one');
  const [sectionHeight, setSectionHeight] = useState(0);
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);
  const [createdMenuOpen, setCreatedMenuOpen] = useState(false);
  const storedFilters = localStorage.getItem('activeFiltersInsight');
  const { searchTerm } = useOutletContext<{ searchTerm: string }>();
  const initialFilters = storedFilters ? JSON.parse(storedFilters) : {
    sentiment: [], 'Created at': []
  };
  const [activeFilters, setActiveFilters] = useState<any>({
    ...initialFilters,
    search: searchTerm,
  });

  const [searchQuery, setSearchQuery] = useState("");
  const selectedValuesRef = useRef<HTMLDivElement>(null);
  const [activeIndex1, setActiveIndex1] = useState(-1);
  const [activeState, setActiveState] = useState('All Insights');
  const [sentimentMenuOpen, setSentimentMenuOpen] = useState(false);
  const [localItemSentiment, setLocalItemSentiment] = useState([])
  const [sentimentSearchQuery, setSentimentSearchQuery] = useState("");
  const [openCategoryMenu, setOpenCategoryMenu] = useState(null);
  const location = useLocation();


  const [editingFilter, setEditingFilter] = useState(null);

  const dispatch = useAppDispatch();
  const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;
  const { clearAll } = useSearch();
  useEffect(() => {
    setActiveFilters((prevFilters: any) => ({ ...prevFilters, search: searchTerm }));
  }, [searchTerm]);

  // Reset filters on route change
  useEffect(() => {
    setActiveFilters({
      ...initialFilters,
      search: '',
    });
  }, [location.pathname]);


  const handleInsightGroupList = async () => {
    setActiveState('Insight Groups')
    dispatch(fetchInsightGroupListData(baseId, 1, 10, activeFilters));
    localStorage.removeItem('activeState', 'Insight Groups');
  };


  const toggleFilter = (category: any, newFilter: any) => {
    setActiveFilters((prevFilters: { [x: string]: never[]; }) => {
      const categoryFilters = prevFilters[category] || [];
      const existingFilterIndex = categoryFilters.findIndex(
        (filter: { id: any; }) => filter.id === newFilter.id
      );

      let updatedCategoryFilters;
      if (existingFilterIndex !== -1) {
        updatedCategoryFilters = categoryFilters.map((filter, index) =>
          index === existingFilterIndex ? newFilter : filter
        );
      } else {
        updatedCategoryFilters = [...categoryFilters, newFilter];
      }
      return {
        ...prevFilters,
        [category]: updatedCategoryFilters
      };
    });
  };

  useEffect(() => {
    const storedSentimentToItems = localStorage.getItem('sentimentToItems');
    if (storedSentimentToItems) {
      const parsedItems = JSON.parse(storedSentimentToItems);
      if (parsedItems) {
        setLocalItemSentiment(parsedItems);
      }
    } else {
      setLocalItemSentiment(sentiments);
      localStorage.setItem('sentimentToItems', JSON.stringify(sentiments));
    }
  }, []);




  const itemsRef1 = useRef([]);
  useEffect(() => {
    const handleKeyDown = (event: { key: string; }) => {
      if (event.key === "ArrowUp") {
        setActiveIndex1((prevIndex) => {
          const newIndex = Math.max(prevIndex - 1, 0);
          scrollItemIntoView(newIndex);
          return newIndex;
        });
      } else if (event.key === "ArrowDown") {
        setActiveIndex1((prevIndex) => {
          const newIndex1 = Math.min(prevIndex + 1, localItemSentiment.length - 1);
          scrollItemIntoView(newIndex);
          scrollItemIntoView(newIndex1);
          return Math.max(newIndex, newIndex1, newIndex2, newIndex3, newIndex4);
        });
      } else if (event.key === "Enter") {
        if (activeIndex1 >= 0) {
          let newFilter = null;

          if (sentimentMenuOpen && activeIndex1 < localItemSentiment.length) {
            const selectedItem = localItemSentiment[activeIndex1];
            newFilter = {
              id: selectedItem.id,
              label: "Sentiment",
              value: selectedItem.name,
              icon: <BadgeSentiment borderColor={selectedItem.label} value={selectedItem.name} />
            };
            setSentimentMenuOpen(false);
            setSentimentSearchQuery("");
          }


          if (newFilter) {
            setActiveFilters((prevFilters: { [x: string]: any; }) => ({
              ...prevFilters,
              [newFilter.label]: [...(prevFilters[newFilter.label] || []), newFilter]
            }));
          }
        }
      }
    };

    const scrollItemIntoView = (index: number) => {
      const item = itemsRef1.current[index];
      if (item) {
        item.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [activeFilters, setActiveFilters, activeIndex1,
    , localItemSentiment, setSentimentMenuOpen, setSentimentSearchQuery, setSentimentMenuOpen

  ]);



  const BadgeSentiment = ({ borderColor, value }) => (
    <>
      {value === 'positive' ? (
        <span
          className="w-2 h-2 rounded-full block bg-[#15803d]"
        >

        </span>
      ) : value === 'negative' ? (
        <span
          className="w-2 h-2 rounded-full block bg-[#b91c1c] "
        >

        </span>
      ) : value === 'spam' ? (
        <span
          className="w-2 h-2 rounded-full block bg-[#4b5563]"
        >

        </span>
      ) : value === 'mixed' ? (
        <span
          className="w-2 h-2 rounded-full block bg-[#1d4ed8] "
        >
        </span>
      ) : value === 'request' ? (
        <span
          className="w-2 h-2 rounded-full block bg-[#1d4ed8] "
        >
        </span>
      ) : null}
    </>
  );

  const hasActiveFilters = Object.values(activeFilters).some(filters => filters.length > 0);

  const mainItems = [
    {
      id: uuidv4(),
      label: 'Sentiment',
      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[14px] h-[14px] text-[#1e1e1e]">
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
      </svg>
      ,
      menuOpen: sentimentMenuOpen,
      setMenuOpen: setSentimentMenuOpen,
    },
    {
      id: uuidv4(),
      label: 'Created at',
      icon: <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" focusable="false" aria-hidden="true"
        height="0.9em" width="0.9em" xmlns="http://www.w3.org/2000/svg">
        <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
        <line x1="16" y1="2" x2="16" y2="6"></line>
        <line x1="8" y1="2" x2="8" y2="6"></line>
        <line x1="3" y1="10" x2="21" y2="10"></line>
      </svg>,
      menuOpen: createdMenuOpen,
      setMenuOpen: setCreatedMenuOpen,
    }
  ];


  // sentiment
  const sentimentItems = localItemSentiment?.map((item, index) => ({
    id: item.id,
    label: item.name,
    icon: <BadgeSentiment borderColor={item.label} value={item.name} />,
    onClick: () => {
      toggleFilter('sentiment', {
        id: item.id,
        value: item.name,
        icon: <BadgeSentiment borderColor={item.label} value={item.name} />
      });
      setSentimentMenuOpen(false);
      setSentimentSearchQuery("");
      setActiveIndex1(index);
    },
    className: activeIndex1 === index ? 'bg-[#f1f1f2]' : '',
  }));


  const handleMainMenuClick = () => {
    setIsMainMenuOpen(!isMainMenuOpen);
    setSentimentMenuOpen(false)
  };


  const filteredSentimentItems = sentimentItems.filter(item =>
    item.label.toLowerCase().includes(sentimentSearchQuery.toLowerCase())
  );


  const filteredMainItems = mainItems.filter(item =>
    item.label.toLowerCase().includes(searchQuery.toLowerCase())
  );



  const removeFilter = (category: any, id: any) => {
    if (category === 'search') {
      setActiveFilters((prevFilters: any) => ({
        ...prevFilters,
        search: ''
      }));
    } else {
      setActiveFilters((prevFilters: { [x: string]: any; }) => ({
        ...prevFilters,
        [category]: (prevFilters[category] || []).filter((filter: { id: any; }) => filter.id !== id)
      }));
    }
  };


  useEffect(() => {
    if (selectedValuesRef.current) {
      setSectionHeight(selectedValuesRef.current.offsetHeight);
    } else {
      setSectionHeight(0);
    }
  }, [activeFilters]);


  const handleClearAll = () => {
    clearAll()
    setActiveFilters([]);
  }

  const FilterButton: React.FC<any> = ({ label, onClick, activeFilters }) => {
    const totalActiveFilters = activeFilters
      ? Object.values(activeFilters).reduce((acc, filters) => acc + filters.length, 0)
      : 0;

    const isActive = totalActiveFilters > 0;

    const buttonClassName = `
      ml-0 lg:ml-[0.5rem] 
      flex items-center gap-[0.4rem] md:gap-[0.5rem] lg:gap-[0.5rem] 
      rounded-md py-[6px] px-[0.5rem] 
      text-[12px] md:text-[0.8125rem] lg:text-[0.8125rem] 
      font-semibold
      transition-colors duration-200
      ${isActive
        ? 'bg-blue-500 text-white hover:bg-blue-600'
        : 'bg-white text-dark-black-100 hover:bg-light-gray-100'}
    `;


    return (
      <button
        type="button"
        className={`ml-0 lg:ml-[0.5rem] flex items-center gap-[0.4rem] md:gap-[0.5rem] lg:gap-[0.5rem] rounded-md py-[6px] px-[0.5rem] hover:bg-light-gray-100 text-[12px] md:text-[0.8125rem] lg:text-[0.8125rem] ${hasActiveFilters ? 'text-red' : 'text-dark-black-100'
          }`}
        onClick={onClick}
      >
        <svg
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          aria-hidden="true"
          focusable="false"
          height="1em"
          width="1em"
          className={hasActiveFilters ? '#text-[#111827] ' : 'text-[#0000007a]'}
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
        </svg>
        <span
          className={hasActiveFilters ? '#text-[#111827] leading-[1.2] text-[12px] md:text-[0.8125rem] lg:text-[0.8125rem] font-semibold' : 'text-[#0000007a]  leading-[1.2] text-[12px] md:text-[0.8125rem] lg:text-[0.8125rem] font-semibold'}
        >
          {label}
        </span>
      </button>
    );
  };



  const sentimentMenuRef = useRef(null);
  const createdMenuRef = useRef(null);
  const mainMenuRef = useRef(null);
  const handleClickOutside = (event: { target: any; }) => {
    if (sentimentMenuRef.current && !sentimentMenuRef.current.contains(event.target)) {
      setSentimentMenuOpen(false);
    }
    if (createdMenuRef.current && !createdMenuRef.current.contains(event.target)) {
      setCreatedMenuOpen(false);
    }
    if (mainMenuRef.current && !mainMenuRef.current.contains(event.target)) {
      setIsMainMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // created at
  const [value, setValue] = useState({
    startDate: null,
    endDate: null
  });


  const handleValueChange = (newValue: any) => {

    if (newValue.startDate && newValue.endDate) {
      const formattedStartDate = format(new Date(newValue.startDate), 'd MMMM yyyy');
      const formattedEndDate = format(new Date(newValue.endDate), 'd MMMM yyyy');

      const dateFilter = {
        id: 'date-range',
        label: 'Date Range',
        value: `${formattedStartDate} - ${formattedEndDate}`,
        icon: null,
      };

      setActiveFilters((prevFilters: any) => ({
        ...prevFilters,
        ['Created at']: [dateFilter],
      }));
    }
    clearPaginationFromState()
    setValue(newValue);
    setCreatedMenuOpen(false);

  }

  const handleValueClick = (category: any, id: any) => {
    setOpenCategoryMenu(category);
    setEditingFilter({ category, id });;
    setSentimentMenuOpen(category === 'sentiment');
    setCreatedMenuOpen(category === 'Created at');
  };

  const renderIcon = (icon: any) => {
    if (icon && icon.type && icon.props) {

      return React.createElement(icon.type, icon.props);
    }

    return null;
  };


  // New function to handle updating a specific filter
  const handleFilterUpdate = (category: any, oldId: any, newId: any, newValue: any, newIcon: any) => {
    setActiveFilters((prevFilters: { [x: string]: any[]; }) => ({
      ...prevFilters,
      [category]: prevFilters[category].map((filter: { id: any; }) =>
        filter.id === oldId ? { ...filter, id: newId, value: newValue, icon: { type: newIcon.type, props: newIcon.props } } : filter

      )
    }));
    setOpenCategoryMenu(null);
    setEditingFilter(null);
    setSentimentMenuOpen(false);
    setCreatedMenuOpen(false);

  };

  // Set activeFilters to local storage

  useEffect(() => {
    localStorage.setItem('activeFiltersInsight', JSON.stringify(activeFilters));
  }, [activeFilters]);


  const clearPaginationFromState = () => {
    window.dispatchEvent(new CustomEvent('paginationresetsetate'));
  };


  return (
    <>
      <div className={`fixed top-[56px] feedback-table-section`}>
        <div className='flex h-full lg:h-[45px] shrink-0 justify-between items-center gap-1 md:gap-2 lg:gap-4 bg-white shadow-custom-1 py-2 px-2 md:py-4 lg:py-4 md:px-4 lg:px-4 flex-wrap lg:flex-nowrap'>
          <div className='flex items-center rounded-md'>
            <button
              className={`px-[6px] md:px-2 lg:px-2 text-[12px] md:text-[0.8125rem] lg:text-[0.8125rem] leading-[1.2] font-semibold py-[5px]
               border border-r-0 border-solid border-[rgba(0, 0, 0, 0.08)] rounded-tl-md rounded-bl-md ${activeState === 'All Insights' ? 'bg-[#6b7280] text-white  border-[#6b7280]' : 'bg-[#f9fafa] text-[#0000007a] hover:text-black-100'}`}
              onClick={() => {
                setActiveState('All Insights');
              }}
            >
              All Insights
            </button>
            <button
              className={`px-[6px] md:px-2 lg:px-2 text-[12px] md:text-[0.8125rem] lg:text-[0.8125rem] leading-[1.2] font-semibold py-[5px] border
               border-solid border-[rgba(0, 0, 0, 0.08)] rounded-tr-md rounded-br-md ${activeState === 'Insight Groups' ? 'bg-[#6b7280] text-white border-[#6b7280]' : 'bg-[#f9fafa] text-[#0000007a] hover:text-black-100'}`}
              onClick={handleInsightGroupList}
            >
              Insight Groups
            </button>
            <div className="relative inline-block text-left">
              <FilterButton label="Filter" onClick={handleMainMenuClick} />
              {isMainMenuOpen && (
                <div ref={mainMenuRef} className="absolute right-0 z-10 mt-2 left-0 mq575:w-[160px] mq3000:w-[234px] origin-top-right rounded-md bg-white filter-dropdown border border-solid border-[#00000014]"
                  style={{ boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)' }}>
                  <div className="py-1">
                    <input
                      type="search"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="w-full outline-none border-t-0 border-l-0 border-r-0 border-b border-solid border-[#00000029] 
                      rounded-none text-sm h-[36px] pl-[10px] pr-[30px focus:outline-none focus:shadow-none focus:ring-0 focus:border-[#00000029] font-normal text-[#9f9f9fff]"
                      placeholder="Filter..."
                    />
                    <span className="right-0 w-[2.25rem] h-[2.25rem] text-sm flex items-center justify-center absolute text-[#000000eb]  opacity-60 top-[2px]" style={{ zIndex: 2 }}>F</span>

                    {filteredMainItems.map((item) => (
                      <button
                        key={item.id}
                        className="text-dark-black-100 flex items-center gap-[0.5rem] px-[0.75rem] py-[0.375rem] h-[32px] text-[13px] font-normal cursor-pointer w-full hover:bg-[#f1f1f2] focus:bg-[#f1f1f2] active:bg-[#e7e7e8]"
                        onClick={() => {
                          item.setMenuOpen(true);
                          setIsMainMenuOpen(false);
                        }}
                      >
                        {item.icon}
                        {item.label}
                      </button>
                    ))}
                  </div>
                </div>
              )}


              {sentimentMenuOpen && (
                <div ref={sentimentMenuRef} className="absolute right-0 z-10 mt-2 left-0 mq575:w-[160px] mq3000:w-[234px] origin-top-right rounded-md bg-white filter-dropdown border border-solid border-[#00000014]"
                  style={{ boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)' }}>
                  <div className="py-1">
                    <input
                      type="search"
                      value={sentimentSearchQuery}
                      onChange={(e) => setSentimentSearchQuery(e.target.value)}
                      className="w-full outline-none border-t-0 border-l-0 border-r-0 border-b border-solid border-[#00000029] 
                      rounded-none text-sm h-[36px] pl-[10px] pr-[30px focus:outline-none focus:shadow-none focus:ring-0 focus:border-[#00000029] font-normal text-dark-black-100"
                      placeholder="Status"
                    />
                    <span className="right-0 w-[2.25rem] h-[2.25rem] text-sm flex items-center justify-center absolute text-[#000000eb]  opacity-60 top-[2px]" style={{ zIndex: 2 }}>F</span>
                    <div className=" overflow-auto max-h-[200px]">
                      {filteredSentimentItems.map((item, index) => (
                        <button
                          key={item.id}
                          ref={(el) =>
                            (itemsRef1.current[index] = el)
                          }
                          className={`text-dark-black-100 flex items-center gap-[0.5rem] px-[0.75rem] py-[0.375rem] h-[32px] text-[13px] font-normal cursor-pointer w-full hover:bg-[#f1f1f2] focus:bg-[#f1f1f2] ${item.className}`}

                          onClick={() => {
                            if (editingFilter && editingFilter.category === 'sentiment') {
                              handleFilterUpdate('sentiment', editingFilter.id, item.id, item.label, item.icon);
                              clearPaginationFromState()
                            } else {
                              item.onClick();
                              clearPaginationFromState()
                            }
                          }}
                        >
                          {item.label === 'positive' ? (
                            <span
                              className="w-2 h-2 rounded-full block bg-[#15803d]"
                            >
                            </span>
                          ) : item.label === 'negative' ? (
                            <span
                              className="w-2 h-2 rounded-full block bg-[#b91c1c] "
                            >
                            </span>
                          ) : item.label === 'spam' ? (
                            <span
                              className="w-2 h-2 rounded-full block bg-[#4b5563]"
                            >
                            </span>
                          ) : item.label === 'mixed' ? (
                            <span
                              className="w-2 h-2 rounded-full block bg-[#1d4ed8] "
                            >
                            </span>
                          ) : item.label === 'request' ? (
                            <span
                              className="w-2 h-2 rounded-full block bg-[#1d4ed8] "
                            >
                            </span>
                          ) : null}
                          <span className=" capitalize whitespace-nowrap w-[150px] overflow-hidden text-ellipsis text-start">    {item.label}</span>

                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              )}


              {createdMenuOpen && (
                <div ref={createdMenuRef} className="absolute right-0 z-10 mt-2 left-0 mq575:w-[160px] mq3000:w-[234px] origin-top-right rounded-md bg-white filter-dropdown border
                 border-solid border-[#00000014] date-picker"
                  style={{ boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)' }}>
                  <Datepicker
                    value={value}
                    onChange={handleValueChange}
                    showShortcuts={true}
                  />
                </div>

              )}

            </div>
          </div>

        </div>

        <div className="flex items-end md:items-center lg:items-center justify-between px-4">
          <div className={`${!hasActiveFilters ? 'py-0' : 'py-2 flex justify-start flex-wrap w-full gap-2'}`} ref={selectedValuesRef}>


            {Object.entries(activeFilters).map(([category, filters]) =>
              category === 'search' ? (
                filters && (
                  <div key="search" className="flex justify-start flex-wrap shrink-0 items-center bg-white py-0 pr-0 md:py-0 lg:py-0 md:pr-0 lg:pr-0 lg:flex-nowrap filter-btn">
                    <span className="border border-[#e5e7eb] border-solid text-[#6b7280] text-[0.75rem] gap-[0.675rem] leading-4 pr-1 pl-[0.625rem] py-1 bg-white rounded-md items-center inline-flex">
                      <span className="capitalize">Search</span>
                      <span className="bg-[#e5e7eb] w-[1px] h-[1rem] mx-1"></span>
                      <span className="text-[#111827] font-medium capitalize cursor-pointer">{filters}</span>
                      <button
                        onClick={() => removeFilter('search', 'search')}
                        className="text-[#6b7280] rounded justify-center items-center w-[1.25rem] h-[1.25rem] flex ml-[-.375rem] cursor-pointer"
                      >
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" aria-hidden="true">
                          <path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path>
                        </svg>
                      </button>
                    </span>
                  </div>
                )
              ) : (
                Array.isArray(filters) && filters.length > 0 ? (
                  filters.map(({ id, value, icon, color }) => {

                    return (
                      <div key={id} className="flex justify-start flex-wrap shrink-0 items-center bg-white py-0 pr-0 md:py-0 lg:py-0 md:pr-0 lg:pr-0 lg:flex-nowrap filter-btn">
                        <span className="border border-[#e5e7eb] border-solid text-[#6b7280] text-[0.75rem] gap-[0.675rem] leading-4 pr-1 pl-[0.625rem] py-1 bg-white rounded-md items-center inline-flex">
                          <span className="text-[#1d2025] w-2 h-2 rounded-full bg-transparent border-2 border-solid border-[#7d7f83] block"></span>
                          <span className="capitalize">{category}</span>
                          <span className="bg-[#e5e7eb] w-[1px] h-[1rem] mx-1"></span>

                          <span className="mr-[-6px]"> {renderIcon(icon)}</span>
                          {/* {icon.props} */}
                          {/* {renderIcon(icon.type, icon.props)} */}

                          {/* {storeIconInfo(icon)} */}

                          {color && (
                            <span
                              className="flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full text-[0.625rem] font-medium text-white"
                              style={{ backgroundColor: color }}
                            ></span>
                          )}
                          <span
                            className="text-[#111827] font-medium capitalize cursor-pointer"
                            onClick={() => handleValueClick(category, id)}
                          >
                            {value}
                          </span>
                          <button
                            onClick={() => removeFilter(category, id)}
                            className="text-[#6b7280] rounded justify-center items-center w-[1.25rem] h-[1.25rem] flex ml-[-.375rem] cursor-pointer"
                          >
                            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" aria-hidden="true">
                              <path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path>
                            </svg>
                          </button>
                        </span>
                      </div>
                    );
                  })
                ) : null
              )
            )}





          </div>
          {hasActiveFilters && (
            <button
              className="inline-flex items-center py-1 justify-center rounded-md font-semibold min-w-[5rem] text-[0.8125rem] px-3 hover:bg-light-gray-100"
              onClick={handleClearAll}
            >
              Clear All
            </button>
          )}
        </div>



      </div>
      <div className=''>
        {activeButton === 'one' && (
          activeState === 'Insight Groups' ?
            <InsightGroupTable sectionHeight={sectionHeight} activeFilters={activeFilters} /> :
            <InsightTable sectionHeight={sectionHeight} activeFilters={activeFilters} />
        )}

        {/* {activeButton === 'two' && <DragList />} */}
      </div>
    </>

  )
}
