
// @ts-nocheck
import { Droppable } from "react-beautiful-dnd";
import ListItem from "./ListItem";
import { ItemType } from "./interface";
// import { MenuDropdown } from "../../../components/MenuDropdown";

type ComponentType = {
  prefix: {
    title: string;
    color: string;
  };
  elements: Array<ItemType>;
};



// const dropdownGroups: DropdownGroup[] = [
//   {
//     items: [
//       {
//         label: `Hide`,
//         className: "py-[0.375rem] px-[0.75rem] bg-white text-[#000000eb] text-[13px] hover:bg-[#e7e7e8]",
//       },
//     ],
//   },
// ];

const DraggableElement = ({ prefix, elements, initialLoading }: ComponentType) => {  
  return (
    <div className="droppable" style={{ transition: ' transform 0ms linear 0s' }}>

      <Droppable droppableId={prefix.title}>
        {(provided, snapshot) => (
          
          <div 
            {...provided.droppableProps} 
            ref={provided.innerRef} 
            className={`drag-container min-h-[100px] ${snapshot.isDraggingOver ? 'bg-gray-100' : ''}`}
          >
            {elements.map((item: ItemType, index: number) => (
              <ListItem key={item.id} item={item} index={index} />
            ))}
            {provided.placeholder}
            {elements.length === 0 && !snapshot.isDraggingOver && (
              <div className="text-dark-black-200 text-[0.8125rem] font-normal text-left">
              No items here
              </div>
            )}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default DraggableElement;