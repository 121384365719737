import {
    ChartPieIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'

import Profile from "../user/profile/Profile"
import Secure from "../user/profile/Security"



type RouteProps = {
    layout: string;
    pages: {
        icon:any;
        name: string;
        path: string;
        id: string;
        component: () => JSX.Element;
    }[];
}[]

export const routesProfile: RouteProps = [
    {
        layout: "profile",
        pages: [
            {
                icon: XMarkIcon,
                name: "profile",
                path: "/profile",
                id: 'profile',
                component: Profile,
            },
            {
                icon: ChartPieIcon,
                name: "security",
                path: "/security",
                id: 'security',
                component: Secure,
            }
        ],
    },
];


