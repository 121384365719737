
import axios, { AxiosError } from "axios";
import { COMMON } from "../utils/common";

type API_METHOD = "GET" | "POST" | "DELETE" | "PUT" | "PATCH"


const apiClient = axios.create({
    baseURL: COMMON.apiBaseUrl(),
    headers: {
        'Content-type': 'application/json'
    },
    withCredentials: true
});

const _baseRequest = (
    url: string,
    method: API_METHOD = 'GET',
    data?: object,
    headers?: Record<string, string> 
): Promise<any> => {
    return apiClient
        .request({
            method,
            url,
            data,
            headers
        })
        .then((response) => response.data)

        .catch((err: AxiosError | Error) =>
            Promise.reject({
                name: err.name,
                message: err.message,
                status: (err as AxiosError).response?.status || -1,
                data: (err as AxiosError).response?.data
            })
        );
};

// const _authorizedRequest = async (
//     url: string,
//     method: API_METHOD = 'GET',
//     data?: object,
// ): Promise<any> => {
//     return _baseRequest(url, method, data);
// };

export const fetchChannel = (baseId: number) => apiClient.get(`/v1/board/${baseId}/Boards`);
export const fetchBaseDetails1 = (baseId: number) => apiClient.get(`/v1/base/${baseId}/baseDetail`);
export const fetchbaseMemberDetails = (baseId: number) => apiClient.get(`/v1/base/${baseId}/settings/members`);
export const fetchItemTagsList = (baseId: number) => apiClient.get(`/v1/item_tags/${baseId}/tagList`);
export const fetchItemStatusList = (baseId: number) => apiClient.get(`/v1/item_base_status/${baseId}/baseStatusList`);
export const fetchUserProfileDetails = () => apiClient.get(`/v1/profile`);


export { _baseRequest }