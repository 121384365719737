// @ts-nocheck
import React, { Fragment, HTMLProps, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  ColumnDef,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
} from '@tanstack/react-table'
import { MenuDropdown } from '../../../components/MenuDropdown'
import { format } from 'date-fns';
import { _baseRequest } from '../../../services/api';
import { COMMON } from '../../../utils/common';
import { useDispatch } from 'react-redux';
import { TagIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Button } from "@lemonsqueezy/wedges";
import { Toaster, toast } from 'sonner';
import { deleteItemFeedback } from '../../feedbacks/feedback-redux/feedbacks.actions';
import { debounce } from 'lodash';
import { DropdownGroup, PaginationState1, Person, customerTableProps } from '../type';
import { Dialog, DialogPanel, Label, Transition } from '@headlessui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { TagsInput } from 'react-tag-input-component';
import { updateCustomerInfoData } from '../customer-redux/customer.actions';
import { useNavigate } from 'react-router-dom';


function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!)

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return (
    <input
      type="checkbox"
      ref={ref}
      className="h-4 w-4 rounded border-gray-300 text-indigo-600 ring-0 outline-none focus:outline-none focus:shadow-none focus:ring-transparent"
      {...rest}
    />
  )
}

const CustomerTable: React.FC<customerTableProps> = ({ sectionHeight, activeFilters, itemAssignedTo, customerType } :any) => {
  const [data, setData] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<PaginationState1>({
    pageIndex: 0,
    pageSize: 25,
    currentPage: 1,
    hasNextPage: false,
    totalPages: 1,
    totalItems: 0,
  })
  const [rowSelection, setRowSelection] = React.useState({})
  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;
  const [isModalOpenCheckbox, setIsModalOpenCheckbox] = useState(false);
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const [deleteOk, setDeleteOk] = useState(false)
  const [rowSelectionDelete, setRowSelectionDelete] = useState<number[]>([]);
  const dispatch = useDispatch();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const isInitialMount = useRef(true);
  const [initialLoading, setInitialLoading] = useState(true);
  const channelDataString = localStorage.getItem('channelData');
  const channelData = channelDataString ? JSON.parse(channelDataString) : [];
  const [updateData, setUpdateData] = useState(null)
  const [quickViewMode, setQuickViewMode] = useState(false)
  const [formData, setFormData] = useState({
    item_customer_name: updateData?.item_customer_name || '',
    item_customer_email_id: updateData?.item_customer_email_id || '',
    item_customer_unique_id: updateData?.item_customer_unique_id || '',
    item_customer_phone_number: updateData?.item_customer_phone_number || '',
    item_customer_meta: updateData?.item_customer_meta || '',
    item_customer_label: updateData?.item_customer_label?.split(',').filter(Boolean) || [],
    item_customer_revenue: updateData?.item_customer_revenue || '',
  });
  const [loadingCustomer, setLoadingCustomer] = useState(false)
  var date = new Date();
  const navigate = useNavigate();




  const _get_ItemCutomerList = async (pageIndex: number, pageSize : number, filters:any, itemAssignedTo: any, customerType: any, onFilterUpdate: any) => {
    const API_END_POINT = COMMON.apiBaseUrl();
    const url = `${API_END_POINT}/v1/item-customer/list/${baseId}/customers`;
    const params = new URLSearchParams({ page: pageIndex.toString(), pageSize: pageSize.toString() });

    const appendParam = (key: any, value: any) => {
      params.append(key, value);
    };

    if (filters) {
      Object.entries(filters).forEach(([filterType, filterValues]) => {
        if (Array.isArray(filterValues) && filterValues.length > 0) {
          const keyPrefix = getKeyPrefix(filterType);

          filterValues.forEach((filterObj) => {
            let value;
            if (filterType === 'Boards' || filterType === 'status' || filterType === 'assign') {
              value = filterObj.id;
            } else if (filterType === 'label' || filterType === 'priority' || filterType === 'sentiment') {
              value = filterObj.value;
            } else if (filterType === 'Created at') {
              const [startDate, endDate] = filterObj.value.split(' - ').map((dateStr: any) => {
                const date = parseDate(dateStr);
                date.setHours(12, 0, 0, 0);
                return date;
              });
              appendParam('created_from', formatDate(startDate));
              appendParam('created_till', formatDate(endDate));
              return;
            } else if (filterType === 'Updated at') {
              const [startDate, endDate] = filterObj.value.split(' - ').map((dateStr: any) => {
                const date = parseDate(dateStr);
                date.setHours(12, 0, 0, 0);
                return date;
              });
              appendParam('updated_from', formatDate(startDate));
              appendParam('updated_till', formatDate(endDate));
              return;
            }

            if (value !== undefined) {
              appendParam(keyPrefix, value.toString());
            }
          });
        }
      });
    }


    if (filters.search) {
      params.append('search', filters.search);
    }

    const loginUserId = localStorage.getItem("userAuthId");
    if (itemAssignedTo) {
      appendParam('item_customer_type', customerType);
    }

    try {
      const fullUrl = `${url}?${params.toString()}`;
      return await _baseRequest(fullUrl, 'GET');

    } catch (error: any) {
      console.error('Error in _get_ItemCutomerList:', error);
      throw new Error(`Error fetching item feedback list: ${error.message}`);
    }
  };



  const getKeyPrefix = (filterType: string) => {
    switch (filterType) {
      case 'Boards':
        return 'board_id';
      case 'label':
        return 'item_label';
      case 'status':
        return 'item_base_status_id';
      case 'assign':
        return 'item_assigned_to';
      case 'priority':
        return 'item_priority';
      case 'sentiment':
        return 'item_sentiment';
      case 'Created at':
        return 'created_from';
      case 'Updated at':
        return 'updated_from';
      case 'customer type':
        return 'item_customer_type ';
      default:
        return filterType.toLowerCase();
    }
  };

  const parseDate = (dateString: { split: (arg0: string) => [any, any, any]; }) => {
    const [day, month, year] = dateString.split(' ');
    const monthIndex = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].indexOf(month);
    return new Date(year, monthIndex, parseInt(day));
  };

  const formatDate = (date: { toISOString: () => string; }) => {
    return date.toISOString().split('T')[0];
  };


  const fetchItemFeedbackListData = useCallback(debounce(async (pageIndex, pageSize, filters, isInitialFetch = false, itemAssignedTo, customerType) => {
    setLoading(true);
    try {
      const response = await _get_ItemCutomerList(pageIndex, pageSize, filters, itemAssignedTo, customerType);

      if (response.status === 200) {
        const newData = response.data.item_customers;
        const newPagination = {
          currentPage: response.data.currentPage,
          hasNextPage: response.data.hasNextPage,
          totalPages: response.data.totalPages,
          pageSize: response.data.pageSize,
          pageIndex: response.data.currentPage - 1,
          total: response.data.totalItemCustomers,
        };

        setData(newData);
        setPagination(newPagination);
        setTotalPages(response.data.totalPages);
        localStorage.setItem('cachedCustomerInfoData', JSON.stringify({
          data: newData,
          pagination: newPagination,
          timestamp: Date.now(),
        }));
        if (!isInitialFetch) {
          console.log('Data updated in background');
        }
      }
    } catch (error) {
      console.error('Error fetching item feedback list:', error);
    } finally {
      setLoading(false);
      if (isInitialFetch) {
        setInitialLoading(false);
      }
    }
  }, 500), []);

  useEffect(() => {
    const handleSidebarItemClick = () => {
      setPagination({
        pageIndex: 0,
        pageSize: 25,
        currentPage: 1,
        hasNextPage: false,
        totalPages: 1,
        total: 0,
      });
      setTotalPages(0);
      fetchItemFeedbackListData(1, 25, true);
    };

    window.addEventListener('paginationresetsetate', handleSidebarItemClick);

    return () => {
      window.removeEventListener('paginationresetsetate', handleSidebarItemClick);
    };
  }, [fetchItemFeedbackListData]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      const cachedData = localStorage.getItem('cachedCustomerInfoData');
      if (cachedData) {
        setLoading(true);
        const { data: cachedItems, pagination: cachedPagination } = JSON.parse(cachedData);
        setData(cachedItems);
        setPagination(prevPagination => ({
          ...prevPagination,
          ...cachedPagination,
        }));
        setTotalPages(cachedPagination.totalPages);
        setLoading(false);
      } else {
        fetchItemFeedbackListData(pagination.currentPage, pagination.pageSize, activeFilters, true, itemAssignedTo, customerType);
      }
    } else {
      fetchItemFeedbackListData(pagination.currentPage, pagination.pageSize, activeFilters, true, itemAssignedTo, customerType);
    }
  }, [fetchItemFeedbackListData, pagination.currentPage, pagination.pageSize, activeFilters, itemAssignedTo, customerType]);


  const handlePrevPage = () => {
    setLoading(false)
    if (pagination.currentPage > 1) {
      setPagination(prev => ({ ...prev, currentPage: prev.currentPage - 1 }));
    }
  };

  const handleNextPage = () => {
    setLoading(false)
    if (pagination.hasNextPage) {
      setPagination(prev => ({ ...prev, currentPage: prev.currentPage + 1 }));
    }
  };

  const handleUpdateSideBarOpen = (data: React.SetStateAction<null> | Person) => {
    setQuickViewMode(true)
    setUpdateData(data)
    setFormData(updateData)

  }

  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    updateData.name = value
    setUpdateData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTagsChange = (tags : any) => {
    setFormData((prevData: any) => ({
      ...prevData,
      item_customer_label: tags.join(','),
    }));


  };


  const handleSubmitItemCustomer = () => {
    setLoadingCustomer(true);
    const dataToSubmit = {
      id: updateData.id,
      base_id: baseId,
      item_customer_name: formData?.item_customer_name,
      item_customer_unique_id: formData?.item_customer_unique_id,
      item_customer_email_id: formData?.item_customer_email_id,
      item_customer_phone_number: formData?.item_customer_phone_number,
      item_customer_meta: formData?.item_customer_meta,
      item_customer_label: formData?.item_customer_label,
      item_customer_image: formData?.item_customer_image,
      item_customer_revenue: formData?.item_customer_revenue,
      item_customer_parent_id: formData?.item_customer_parent_id,
      item_customer_type: updateData?.item_customer_type,
    };

    dispatch(updateCustomerInfoData(baseId, updateData.id, dataToSubmit))
      .then(() => {
        toast.success('Information updated successfully');
        setFormData({
          item_customer_name: '',
          item_customer_unique_id: '',
          item_customer_email_id: '',
          item_customer_phone_number: '',
          item_customer_meta: '',
          item_customer_label: '',
          item_customer_image: '',
          item_customer_revenue: '',
          item_customer_parent_id: '',
          item_customer_type: updateData?.item_customer_type,
        });
        fetchItemFeedbackListData(pagination.currentPage, pagination.pageSize, activeFilters, true, itemAssignedTo, customerType);
      })
      .catch((error: any) => {
        console.error('Update error:', error);
        toast.error('Failed to update information. Please try again.');
      })
      .finally(() => {
        setLoadingCustomer(false);
        setQuickViewMode(false);
      });
  };



  useEffect(() => {
    const handleSidebarItemClick = () => {
      setData([]);
      setPagination({
        pageIndex: 0,
        pageSize: 25,
        currentPage: 1,
        hasNextPage: false,
        totalPages: 1,
        total: 0,
      });
      setTotalPages(0);
      fetchItemFeedbackListData(1, 25, true);
    };

    window.addEventListener('sidebarItemClicked', handleSidebarItemClick);

    return () => {
      window.removeEventListener('sidebarItemClicked', handleSidebarItemClick);
    };
  }, [fetchItemFeedbackListData]);


  const handleSelect = (row: Row<Person>) => {
    setSelectedRows((prevSelectedRows) => {
      const newRowState = { ...prevSelectedRows }
      newRowState[row.id] = !prevSelectedRows[row.id]
      return newRowState
    })
  }


  const handleSelectAllClick = useCallback((checked: any) => {
    const newRowSelection = {};
    const newRowSelectionDelete = [];

    data.forEach((row, index) => {
      newRowSelection[index] = checked;
      if (checked) {
        newRowSelectionDelete.push(row.id);
      }
    });

    setRowSelection(newRowSelection);
    setRowSelectionDelete(newRowSelectionDelete);
    setIsModalOpenCheckbox(newRowSelectionDelete.length > 0);
  }, [data]);


  const handleCheckboxClick = (row: any, id: any) => {
    setSelectedRows((prevSelectedRows) => ({
      ...prevSelectedRows,
      [row.id]: !prevSelectedRows[row.id]
    }));
    setRowSelectionDelete((prevSelection) => {

      if (prevSelection?.includes(id)) {
        return prevSelection.filter((itemId) => itemId !== id);
      } else {
        return [...prevSelection, id];
      }
    });

    setIsModalOpenCheckbox(true);
  };



  const columns = useMemo<ColumnDef<Person>[]>(() => [
    {
      id: 'select',
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: (e) => {
              table.toggleAllRowsSelected(e.target.checked);
              handleSelectAllClick(e.target.checked);
            },
          }}
        />
      ),
      cell: ({ row }) => (
        <div className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: (e) => {
                row.getToggleSelectedHandler()(e);
                handleCheckboxClick(row, row.original.id);
              },
            }}
          />
        </div>
      ),
    },
    {
      accessor: 'name',
      header: 'Name',
      cell: info => {
        return (
          <div className='w-full cursor-pointer' onClick={() => handleUpdateSideBarOpen(info.row.original)}>
            <div className=' relative'>
              <div data-title={info.row.original.item_customer_name || ''} className=' table-tooltip hover:overflow-visible tabletooltip-title'>
                <div className=' text-[#1b1b1b] text-[13px] font-medium line-clamp-1'>
                  {info.row.original.item_customer_name || ''}
                </div>
              </div>

            </div>

          </div>
        );
      },
      sort: (a: { name: string; name: string }, b: { name: string; name: string }) => {
        const textA = a.name || a.name;
        const textB = b.name || b.name;
        return textA.localeCompare(textB);
      },
    },
    {
      accessor: 'email',
      header: 'Email',
      cell: info => {
        return (
          <div className='w-full cursor-pointer' onClick={() => handleUpdateSideBarOpen(info.row.original)}>
            <div className=' relative'>
              <div data-title={info.row.original.item_customer_email_id || ''} className='table-tooltip flex items-center font-[500] leading-[1.2]  text-[0.75rem] px-[0rem] py-[5px] gap-2'>

                <div className=' text-[#1b1b1b] text-[13px] font-medium line-clamp-1'>
                  {info.row.original.item_customer_email_id || ''}
                </div>
              </div>

            </div>
          </div>
        );
      },
      sort: (a: { email: string }, b: { email: any }) => a.email.localeCompare(b.email),
    },
    {
      accessor: 'phone',
      header: 'Phone',
      cell: info => {
        return (
          <div className='w-full cursor-pointer' onClick={() => handleUpdateSideBarOpen(info.row.original)}>
            <div className=' relative'>
              <div data-title={info.row.original.item_customer_phone_number || ''} className='table-tooltip flex items-center font-[500] leading-[1.2]  text-[0.75rem] px-[0rem] py-[5px] gap-2'>

                <div className=' text-[#1b1b1b] text-[13px] font-medium line-clamp-1'>
                  {info.row.original.item_customer_phone_number || ''}
                </div>
              </div>

            </div>
          </div>
        );
      },
      sort: (a: { phone: string }, b: { phone: any }) => a.phone.localeCompare(b.phone),
    },
    {
      accessor: 'type',
      header: 'Type',
      cell: info => {
        return (
          <div className='w-full cursor-pointer' onClick={() => handleUpdateSideBarOpen(info.row.original)}>
            <div className=' relative'>
              <div data-title={info.row.original.item_customer_type || ''} className='table-tooltip flex items-center max-w-fit font-[500] leading-[1.2] rounded-full text-[0.75rem] px-[0rem] py-[5px] gap-[6px]'>
                {
                  info.row.original.item_customer_type === 'company' ? (
                    <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium capitalize text-blue-700 ring-1 ring-inset ring-blue-700/10">
                      {info.row.original.item_customer_type}
                    </span>
                  ) : (
                    <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium capitalize text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                      {info.row.original.item_customer_type || ''}
                    </span>
                  )
                }

              </div>
            </div>
          </div>
        )

      },
      sort: (a: { type: string }, b: { type: any }) => a.type.localeCompare(b.type),
    },
    {
      accessor: 'revenue',
      header: 'Revenue',
      cell: info => {
        return (
          <div className='w-full cursor-pointer' onClick={() => handleUpdateSideBarOpen(info.row.original)}>
            <div className=' relative'>
              <div data-title={info.row.original.item_customer_revenue || ''} className='table-tooltip flex items-center max-w-fit font-[500] leading-[1.2] rounded-full text-[0.75rem] px-[0rem] py-[5px] gap-[6px]'>
                <div className=' text-[#1b1b1b] text-[13px] font-medium line-clamp-1'  >
                  {info.row.original.item_customer_revenue}
                </div>
              </div>
            </div>
          </div>
        )

      },
      sort: (a: { revenue: string }, b: { revenue: any }) => a.revenue.localeCompare(b.revenue),
    },
    {
      accessor: 'received',
      header: 'Last Received',
      cell: info => {
        let formattedDate: any
        let formattedDatetooltip: any
        if (info.row.original.last_item_received_time) {
          formattedDate = format(new Date(info.row.original.last_item_received_time), 'MMM d');
          formattedDatetooltip = format(new Date(info.row.original.last_item_received_time), 'MMM d');
        } else {
          formattedDate = ''
          formattedDatetooltip = ''
        }
        return (
          <div className='w-full cursor-pointer' onClick={() => handleUpdateSideBarOpen(info.row.original)}>
            <div className=' relative'>
              <div data-title={formattedDatetooltip || ''} className='table-tooltip flex items-center max-w-fit font-[500] leading-[1.2] rounded-full text-[0.75rem] px-[0rem] py-[5px] gap-[6px]'>
                <div className=' text-[#1b1b1b] text-[13px] font-medium line-clamp-1'  >
                  {formattedDate || ''}
                </div>
              </div>
            </div>
          </div>
        )

      },
      sort: (a: { received: string }, b: { received: any }) => a.received.localeCompare(b.received),
    },
  ], [handleSelectAllClick]);


  // delete row
  const handleConfirmDelete = (id : any) => {
    setDeleteOk(true)
  }

  const handleDeleteConfirm = async () => {
    setDeleteLoading(true);
    try {
      let groupIdsToDelete = Array.isArray(rowSelectionDelete) ? rowSelectionDelete : [rowSelectionDelete];

      const deleteResponse = await dispatch(deleteItemFeedback(baseId, groupIdsToDelete));

      if (!deleteResponse || !deleteResponse.data) {
        throw new Error('Invalid delete response received');
      }

      const successfulDeletes = deleteResponse.data.filter((item: { isSuccess: any; }) => item.isSuccess).map((item: { id: any; }) => item.id);
      const failedDeletes = deleteResponse.data.filter((item: { isSuccess: any; }) => !item.isSuccess);

      if (successfulDeletes.length > 0) {
        toast.success(`Successfully deleted item(s)`);
      }
      failedDeletes.forEach((item: { id: any; reason: any; }) => {
        toast.error(`Failed to delete item ${item.id}: ${item.reason}`);
      });

      if (successfulDeletes.length > 0) {
        const updatedResponse = await _get_ItemCutomerList(
          pagination.pageIndex + 1,
          pagination.pageSize,
          activeFilters,
          false,
          itemAssignedTo,
          customerType
        );

        if (updatedResponse.status === 200) {
          const newData = updatedResponse.data.item_customers;
          const newPagination = {
            currentPage: updatedResponse.data.currentPage,
            hasNextPage: updatedResponse.data.hasNextPage,
            totalPages: updatedResponse.data.totalPages,
            pageSize: updatedResponse.data.pageSize,
            pageIndex: updatedResponse.data.currentPage - 1,
          };

          setData(newData);
          setPagination(newPagination);
          setTotalPages(updatedResponse.data.totalItemCustomers);

          localStorage.setItem('cachedCustomerInfoData', JSON.stringify({
            data: newData,
            pagination: newPagination,
            timestamp: Date.now(),
          }));

          setRowSelectionDelete([]);
          setDeleteOk(false);
          setIsModalOpenCheckbox(false);
          setSelectedRows({});
          setRowSelection({});
          setSelectedRowsCount(0);
        } else {
          console.error('Error fetching updated item feedback list:', updatedResponse);
          toast.error('Failed to fetch updated item feedback list');
        }
      }
    } catch (error : any) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(`An error occurred: ${error.response.data.message}`);
      } else {
        console.error('Error deleting insight groups:', error);
        toast.error('An error occurred while deleting insight groups. Please try again later.');
      }
    } finally {
      setDeleteLoading(false);
    }
  };


  const handleSelectType = (id: number, type: string) => {
    navigate(`/${baseId}/feedbacks`, {
      state: {
        updateFilter: {
          category: type,
          oldId: type,
          newId: id.toString(),
          newValue: '',
          newIcon: {
            type: 'CustomIcon',  // Name of the icon component or a meaningful label
            props: {
              className:
                "flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full text-[0.625rem] font-medium text-white bg-[#7d7f83]",
            },
          },        }
      }
    });
  };


  const table = useReactTable({
    data,
    columns,
    state: {
      pagination,
      rowSelection,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
    onPaginationChange: setPagination,


  });


  useEffect(() => {
    const applyStyles = () => {
      const tableElement = document.querySelector('.feedback-col') as HTMLElement | null;
      const tableElement1 = document.querySelector('.feedback-thead') as HTMLElement | null;
      const isTabScreen = window.matchMedia('(max-width: 1499px)').matches;
      const isSmallScreen = window.matchMedia('(max-width: 767px)').matches;
      const hasSearchFilter = !!activeFilters.search;

      if (tableElement) {
        if (isSmallScreen) {
          tableElement.style.marginTop = sectionHeight ? `calc(50px + ${sectionHeight}px)` : '50px';
        } else if (isTabScreen) {
          tableElement.style.marginTop = sectionHeight ? `calc(45px + ${sectionHeight}px)` : '46px';
        } else {
          tableElement.style.marginTop = sectionHeight ? `calc(87px + ${sectionHeight}px)` : '87px';
        }
      }

      if (tableElement1) {
        if (isSmallScreen) {
          tableElement1.style.top = sectionHeight ? `calc(80px + ${sectionHeight}px)` : '80px';
        } else if (isTabScreen) {
          tableElement1.style.top = sectionHeight ? `calc(101px + ${sectionHeight}px)` : '101px';
        } else {
          tableElement1.style.top = sectionHeight ? `calc(101px + ${sectionHeight}px)` : '101px';
        }

        if (sectionHeight) {
          tableElement1.style.borderTop = '1px solid #e5e7eb';
        } else {
          tableElement1.style.borderTop = 'none';
        }
      }
    };

    applyStyles();

    window.addEventListener('resize', applyStyles);
    return () => {
      window.removeEventListener('resize', applyStyles);
    };
  }, [sectionHeight]);


  return (
    <>
      <div className='mx-auto max-w-full'>
        <div className="px-4 sm:px-6 lg:px-8 feedback-table">
          <div className="flow-root h-full">
            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8 h-full">
              <div className="inline-block min-w-full py-2 align-middle h-full max-w-full">
                <div className="relative  h-full block pb-[5rem]">
                  <table className="block border-collapse overflow-x-auto w-full feedback-col overflow-y-auto feedback-table-height h-full">
                    <thead
                      className='mq1499:sticky mq3000:fixed bg-white grid feedback-thead feedback-table-row'
                    >
                      {table.getHeaderGroups().map((headerGroup: any) => (
                        <tr key={headerGroup.id} className='bg-white flex relative w-full'>
                          {headerGroup.headers.map((header: any) => {

                            return (
                              <th key={header.id} colSpan={header.colSpan} scope="col"
                                className={`sticky bg-white flex flex-1 py-[0.75rem] px-[1rem] cursor-pointer text-[0.75rem] items-center justify-start
                                   leading-[1rem] text-[#52555a] font-[500] border-b  border-solid border-light-gray-200 max-w-[300px] min-w-[50px] md:min-w-[160px] lg:min-w-[50px]
                                  ${header.id === 'select' ? '!max-w-[50px] [&>div]:px-0' : ''} 
                                    ${header.id === 'ID' ? 'max-w-[60px]' : ''}
                                    ${header.id === 'Name' ? '!min-w-[106px]' : ''}
                                    ${header.id === 'Email' ? '!min-w-[110px]' : ''}
                                    ${header.id === 'Phone' ? '!min-w-[110px]' : ''}
                                    ${header.id === 'Type' ? '!min-w-[106px]' : ''}
                                    ${header.id === 'Revenue' ? '!min-w-[106px]' : ''}
                                    ${header.id === 'Last Received' ? '!min-w-[106px]' : ''}
                                  `}
                              >
                                <div
                                  {...{
                                    className: header.column.getCanSort()
                                      ? 'cursor-pointer select-none '
                                      : '',
                                    onClick: header.column.getToggleSortingHandler(),
                                  }}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                  {{
                                    asc: ' 🔼',
                                    desc: ' 🔽',
                                  }[header.column.getIsSorted() as string] ?? null}
                                </div>
                              </th>
                            )
                          })}
                          <th
                            className={`sticky bg-white flex flex-1 py-[0.75rem] px-[1rem] cursor-pointer text-[0.75rem] items-center justify-start
                             leading-[1rem] text-[#52555a] font-[500] border-r border-b  border-solid border-light-gray-200 min-w-10 max-w-[56px]`}
                          ></th>
                        </tr>
                      ))}
                    </thead>
                    <div>
                      {loading ? (
                        <div className="flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 191px)' }}>
                          <div className="simple-spinner-loader">
                            <span></span>
                          </div>
                        </div>
                      ) : pagination.totalPages === 0 ? (
                        <div className="flex items-center justify-center z-50" style={{ height: 'calc(100vh - 191px)' }}>
                          <p>No data found</p>
                        </div>
                      ) : (

                        <tbody className="grid">
                          {table.getRowModel().rows.map((row) => {
                            const allRowsSelected = table.getIsAllRowsSelected();                           
                            const dropdownGroups: DropdownGroup[] = [
                              {
                                items: [
                                  {
                                    onClick: () => handleSelectType(row.original.id, row.original.item_customer_type),
                                    label: `Check Feedback`,
                                    className: "py-[10px] px-[0.75rem] bg-white text-[#000000eb] text-[13px] hover:bg-[#e7e7e8] [&>span]:text-[13px]",
                                  },
                                  {
                                    label: `Delete`,
                                    className: "py-[10px] px-[0.75rem] bg-white text-[#000000eb] text-[13px] hover:bg-[#e7e7e8] border-t border-solid border-light-gray-200 [&>span]:text-[13px]",
                                  }
                                ],
                              },
                            ];
                            
                            
                            return (
                              <tr
                                key={row.id}
                                data-selected={allRowsSelected ? true : selectedRows[row.id]}
                                onClick={() => handleSelect(row)}
                                className={`border-b border-solid border-light-gray-200 bg-white flex w-full relative ${selectedRows[row.id] ? 'bg-gray-100' : ''}`}
                              >
                                {row.getVisibleCells().map((cell) => (
                                  <td
                                    key={cell.id}
                                    className={`flex items-center justify-start text-start px-[1rem] py-[0.9rem] leading-[1rem]
                                         flex-1 text-dark-black-200 max-w-[350px]
                                         ${/(_select)$/.test(cell.id) ? 'max-w-[50px] [&>div]:px-0' : ''} 
                                         ${/(_ID)$/.test(cell.id) ? 'max-w-[60px]' : ''}
                                         ${/(_Name)$/.test(cell.id) ? '!max-w-[350px]' : ''}
                                         ${/(_Email)$/.test(cell.id) ? '!min-w-[106px]' : ''}
                                         ${/(_Phone)$/.test(cell.id) ? '!min-w-[110px]' : ''}
                                         ${/(_Type)$/.test(cell.id) ? '!min-w-[106px]' : ''}
                                         ${/(_Revenue)$/.test(cell.id) ? '!min-w-[106px]' : ''}
                                         ${/(_Last Received)$/.test(cell.id) ? '!min-w-[106px]' : ''}
                                       `}
                                  >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                  </td>
                                ))}
                                <td
                                  className={`flex items-center text-start px-[0.75rem] py-[1rem] leading-[1rem] flex-1 max-w-[50px] text-dark-black-200 justify-center [&>button]:w-auto [&>button]:bg-transparent [&>button]:hover:bg-transparent`}
                                >
                                  <MenuDropdown
                                    groups={dropdownGroups}
                                    image={
                                      <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        aria-hidden="true"
                                        focusable="false"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <circle cx="12" cy="12" r="1"></circle>
                                        <circle cx="12" cy="5" r="1"></circle>
                                        <circle cx="12" cy="19" r="1"></circle>
                                      </svg>
                                    }
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>



                      )}
                    </div>

                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed z-10 bg-white border-t border-solid border-light-gray-200 py-[0.5rem] px-[1rem] bottom-0 pagination-bottom">
        <div className="relative flex items-center justify-between">



          <div className="flex items-center gap-2">
            <span className="flex items-center gap-1">
              <div className='text-[0.875rem] font-[500] text-[#000000eb]'>Page
              </div>
            </span>
            <span className="flex items-center gap-1">

              <input
                type="number"
                value={pagination.currentPage}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) : 1;
                  setPagination((prevPagination) => ({
                    ...prevPagination,
                    currentPage: page,
                  }));
                }}
                className="w-[4rem] h-[2rem] text-[0.8125rem] px-[0.75rem] rounded-md border border-solid border-light-gray-100"
              />

              <span className='mr-1 text-[#000000eb] text-[0.8125rem]'>  of{' '}

                {totalPages}
              </span>
            </span>


            <div className='flex items-center gap-1'>

              <select
                value={table.getState().pagination.pageSize}
                onChange={e => {
                  table.setPageSize(Number(e.target.value))
                }}
                className="w-[6rem] h-[2rem] text-[0.8125rem] px-[0.75rem] rounded-md border border-solid border-light-gray-100 block"
              >
                {[25, 50, 100].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
              <div className='mr-1 text-[#000000eb] text-[0.8125rem] ml-[14px]'>
                {pagination.total} Items
              </div>
            </div>


          </div>
          <div className='flex gap-2'>
            <button
              className="border rounded py-2 px-2"
              onClick={handlePrevPage}
              disabled={pagination.currentPage === 1}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-2 w-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
              </svg>

            </button>
            <button
              className="border rounded py-2 px-2"
              onClick={handleNextPage}
              disabled={!pagination.hasNextPage}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-2 w-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
              </svg>

            </button>
          </div>
        </div>
      </div>
      <div open={isModalOpenCheckbox} onClose={() => { }} className={`relative z-10 w-full ${isModalOpenCheckbox ? 'common-slider-one' : 'common-slider-two'}`}
      >
        {selectedRowsCount > 0 && (
          <div className="fixed inset-y-0 right-0 flex checkbox-modal h-[100%] w-[800px] max-w-[800px] bottom-0 top-[-70px]">
            <div className="flex h-auto flex-col overflow-y-scroll bg-white py-6 rounded-lg" style={{ boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1),0 2px 4px -1px rgba(0, 0, 0, 0.06)' }}>
              <div className="px-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <span className='text-dark-black-100 text-[13px] font-[700]'>{selectedRowsCount} selected</span>
                  <div className="flex items-center">
                    <div className="w-full flex items-center justify-between">
                      <div className="flex items-center gap-[0.5rem]">
                        <Button className='text-[13px] text-dark-black-100 font-[600] [&>span]:font-[600] h-[32px] rounded-md flex-row-reverse border-[#e7e7e8] shadow-none'
                          after={<TagIcon className='w-[13px] h-[13px] text-dark-black-100 font-[600] opacity-80' strokeWidth={3} />} variant="outline">
                          Add tags
                        </Button>
                        <Button
                          onClick={(id) => handleConfirmDelete(id)}
                          className='text-[13px] text-dark-black-100 font-[600] [&>span]:font-[600] h-[32px] rounded-md flex-row-reverse border-[#e7e7e8] shadow-none'
                          after={<TrashIcon className='w-[13px] h-[13px] text-dark-black-100 font-[600] opacity-80' strokeWidth={3} />} variant="outline">
                          Delete
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        )}
      </div>
      <Transition.Root show={deleteOk} as={Fragment}>
        <Dialog as="div" className="relative z-50 md:z-10 lg:z-10" onClose={() => { }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center sm:items-start h-full items-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4
                   data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full
                    sm:max-w-[500px] sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                >
                  <span className='font-[600]'>Delete
                    <span> {selectedRowsCount} Ticket</span>
                  </span>

                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to delete your insights?
                    </p>
                  </div>
                  <div className="mt-5 sm:mt-4 flex gap-2 justify-end">
                    <Button
                      type="button"
                      onClick={handleDeleteConfirm}
                      className={`bg-red-600 hover:hover:bg-red-500 focus-visible:outline-red-600 relative
                      [&>span]:flex [&>span]:items-center [&>span]:gap-[8px]`}
                    >
                      Delete
                      {deleteLoading && (
                        <div className="simple-spinner">
                          <span></span>
                        </div>
                      )}
                    </Button>
                    <button
                      type="button"
                      onClick={() => setDeleteOk(false)}
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>

              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>




      <Dialog open={quickViewMode} onClose={setQuickViewMode} className="relative z-10">
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <DialogPanel
                className="pointer-events-auto w-screen max-w-[40rem] transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
              >
                <div className="flex h-full flex-col  bg-white py-0 shadow-xl">
                  <div className="px-0">
                    <div className="flex items-start justify-between flex-col">
                      <aside className='flex flex-col h-screen w-full justify-stretch min-h-[3rem] border-b border-t-0 border-l-0 border-r border-solid border-light-gray-200 px-[1.5rem] pt-[1.5rem] items-start gap-4  pb-[40px]'>
                        <div className='flex items-start w-full gap-2 justify-between'>
                          <div className="flex items-center max-w-full font-[500] leading-[1.2] bg-light-gray-100 rounded-full text-[0.75rem] px-[0.5rem] py-[5px] gap-2">
                            <span className="text-[#52555a] capitalize">Customer</span>
                          </div>
                          <button
                            onClick={() => setQuickViewMode(false)}
                            style={{ transitionProperty: 'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform' }}
                            className='rounded-md  duration-200 font-[600] text-[0.8125rem] h-[32px] w-[32px] flex items-center justify-center border border-solid border-[#e7e7e8]'>

                            <XMarkIcon className='w-[1.1em] h-[1.1em] text-[#000]' />
                          </button>
                        </div>
                        <AnimatePresence initial={false}>

                          <motion.div
                            initial={{ height: 0 }}
                            animate={{ height: 'auto' }}
                            exit={{ height: 0, }}
                            transition={{ height: { duration: 0.5 }, }}
                            className=" w-full"
                          >
                            <div className="">
                              <div className="w-full mt-5">
                                <div className="flex gap-y-[24px] flex-col">
                                  {/* Name Field */}
                                  <div className="relative">
                                    <label
                                      htmlFor="item_customer_name"
                                      className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                    >
                                      Name
                                    </label>
                                    <input
                                      id="item_customer_name"
                                      name="item_customer_name"
                                      type="text"
                                      maxLength={30}
                                      value={updateData?.item_customer_name}
                                      onChange={handleInputChange}
                                      className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                                    />
                                  </div>

                                  {/* Email Field */}
                                  <div className="relative">
                                    <label
                                      htmlFor="item_customer_email_id"
                                      className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                    >
                                      Email
                                    </label>
                                    <input
                                      id="item_customer_email_id"
                                      name="item_customer_email_id"
                                      maxLength={30}
                                      value={updateData?.item_customer_email_id}
                                      onChange={handleInputChange}
                                      type="email"
                                      className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                                    />
                                  </div>

                                  {/* Unique ID Field */}
                                  <div className="relative">
                                    <label
                                      htmlFor="item_customer_unique_id"
                                      className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                    >
                                      Unique Id
                                    </label>
                                    <input
                                      id="item_customer_unique_id"
                                      name="item_customer_unique_id"
                                      value={updateData?.item_customer_unique_id}
                                      onChange={handleInputChange}
                                      maxLength={30}
                                      type="text"
                                      className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                                    />
                                  </div>

                                  {/* Phone Number Field */}
                                  <div className="relative">
                                    <label
                                      htmlFor="item_customer_phone_number"
                                      className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                    >
                                      Phone no
                                    </label>
                                    <input
                                      id="item_customer_phone_number"
                                      name="item_customer_phone_number"
                                      value={updateData?.item_customer_phone_number}
                                      onChange={handleInputChange}
                                      maxLength={30}
                                      type="number"
                                      className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                                    />
                                  </div>

                                  {/* Meta Field */}
                                  <div className="relative">
                                    <label
                                      htmlFor="item_customer_meta"
                                      className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                    >
                                      Meta
                                    </label>
                                    <input
                                      id="item_customer_meta"
                                      name="item_customer_meta"
                                      value={updateData?.item_customer_meta}
                                      onChange={handleInputChange}
                                      maxLength={1000}
                                      type="text"
                                      className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                                    />
                                  </div>

                                  <div className="relative item-viewfeedback-label">
                                    <label
                                      htmlFor="item_customer_label"
                                      className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                    >
                                      Label
                                    </label>
                                    <TagsInput
                                      value={updateData?.item_customer_label}
                                      onChange={handleTagsChange}
                                      id="company_item_customer_label"
                                      name="company_item_customer_label"
                                    />

                                  </div>

                                  {/* Revenue Field */}
                                  <div className="relative">
                                    <label
                                      htmlFor="item_customer_revenue"
                                      className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                    >
                                      Revenue
                                    </label>
                                    <input
                                      id="item_customer_revenue"
                                      name="item_customer_revenue"
                                      value={updateData?.item_customer_revenue}
                                      onChange={handleInputChange}
                                      maxLength={30}
                                      type="text"
                                      className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                                    />
                                  </div>

                                  {/* Save Button */}
                                  <div className='w-auto flex justify-end'>
                                    <Button
                                      type="button"
                                      className="ml-2 bg-indigo-500 h-[32px] hover:bg-indigo-600 focus-visible:outline-indigo-600 w-auto
                 [&>span]:flex [&>span]:items-center [&>span]:gap-[6px]"
                                      size="sm"
                                      onClick={() => handleSubmitItemCustomer(updateData)}
                                    >
                                      {loadingCustomer ? (
                                        <>
                                          Save
                                          <div className="simple-spinner">
                                            <span></span>
                                          </div>
                                        </>
                                      ) : (
                                        'Save'
                                      )}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </motion.div>

                        </AnimatePresence>
                      </aside>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>


    </>
  );

}


export default CustomerTable;