

// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Toaster, toast } from 'sonner';
import StarRating from '../../../components/StarRating';
import SmileyRating from '../../../components/SmileyRating';
import ThumbRating from '../../../components/ThumbRating';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ConfettiExplosion from 'react-confetti-explosion';
import { SparklesIcon } from '@heroicons/react/24/outline';


interface StepData {
  id: string;
  step_input_type: string;
  step_title: string;
  value: any;
  type: string;
  step_input_options: string;
  step_mandatory: string;
  step_order: number;
  required: boolean;
  className: string;
}

interface FormErrors {
  [key: string]: string;
}


interface FormValues {
  [key: string]: StepData;
  // Add any other properties as needed

}


interface DynamicSurveyFormProps {
  formSteps: FormElement[];
  submitStepIntegrationResponse: (customerData: any, integrationResponse: any[]) => Promise<void>;
  formikHandleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // formikHandleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  // setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
  // values: any;
  customerData: any;
  setCustomerData: React.Dispatch<React.SetStateAction<any>>;
  integrationResponse: any[];
  setIntegrationResponse: React.Dispatch<React.SetStateAction<any[]>>;
  setShowThankYouMessage: React.Dispatch<React.SetStateAction<boolean>>;
  showThankYouMessage: boolean;
  setLoadingForm: boolean;
  loadingForm: boolean;
  customer_name: string;
  customer_email_id: string;
  customer_phone_no: string;
  customer_info: string;
}

const DynamicSurveyForm: React.FC<DynamicSurveyFormProps> = ({
  steps,
  formSteps,
  submitStepIntegrationResponse,
  // formikHandleBlur,
  customerData,
  setCustomerData,
  integrationResponse,
  setIntegrationResponse,
  setShowThankYouMessage,
  showThankYouMessage,
  setLoadingForm,
  loadingForm,
  values,
  customer_name,
  customer_email_id,
  customer_phone_no,
  customer_info,
}) => {
  const [selectedRating, setSelectedRating] = React.useState<number>(0);
  const [selectedSmiley, setSelectedSmiley] = React.useState<string>('');
  const [selectedThumb, setSelectedThumb] = useState<number | null>(null);
  const dropdownStep = steps.find((step: { step_input_type: string; }) => step.step_input_type === 'dropdown');
  const dropdownOptions = dropdownStep ? JSON.parse(dropdownStep.step_input_options) : [];
  const [selected, setSelected] = useState(dropdownOptions.length > 0 ? dropdownOptions[0] : null);
  const [formValues, setFormValues] = useState<FormValues>({});
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);



  const smileyOptions = ['sad', 'ok', 'happy'];


  useEffect(() => {
    if (isSubmitting) {
      const errors = validateForm();
      setFormErrors(errors);
      if (Object.keys(errors).length === 0) {
        const convertedData = convertData(formValues);
        submitForm(convertedData);

        setIsSubmitting(false);
      } else {

        setIsSubmitting(false);
      }
    }
  }, [isSubmitting]);


  const convertData = (formValues: any) => {
    return Object.values(formValues);
  };

  const handleInputChange = async (id: string, value: any, step: any) => {
    let newValue = {
      step_id: id,
      step_type: step.step_input_type,
      response: value,
      step_header: step.step_title,
    };

    setFormValues(prevValues => ({
      ...prevValues,
      [id]: newValue
    }));

    let updatedIntegrationResponse = [...integrationResponse];
    const existingIndex = updatedIntegrationResponse.findIndex(item => item.step_id === id);

    if (existingIndex !== -1) {
      updatedIntegrationResponse[existingIndex] = newValue;
    } else {
      updatedIntegrationResponse.push(newValue);
    }

    setIntegrationResponse(updatedIntegrationResponse);

    if (['name', 'email', 'phone'].includes(id)) {
      const updatedCustomerData = { ...customerData, [id]: value };
      setCustomerData(updatedCustomerData);
      await submitStepIntegrationResponse(updatedCustomerData, updatedIntegrationResponse);
    } else if (['dropdown', 'radio', 'likert', 'numberscale', 'star', 'smiley', 'thumbsup'].includes(step.step_input_type)) {
      await submitStepIntegrationResponse(customerData, updatedIntegrationResponse);
    }

    if (formErrors[id]) {
      setFormErrors(prevErrors => ({
        ...prevErrors,
        [id]: '',
      }));
    }
  };


  const handleBlur = async (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    await submitStepIntegrationResponse(customerData, integrationResponse);
  };




  const validateForm = () => {
    const errors: { [key: string]: string } = {};
    steps.forEach((step: any) => {
      if (step.step_mandatory?.trim().toLowerCase() === 'yes' && !formValues[step.id]?.response) {
        errors[step.id] = 'This field is required';
      }
    });
    return errors;
  };


  const handleCheckboxChange = (e, id, optionId, step) => {
    const isChecked = e.target.checked;

    setFormValues((prevValues) => {
      const checkboxOptions = JSON.parse(step.step_input_options);
      const labelMap = checkboxOptions.reduce((acc, option) => {
        acc[option.id] = option.label;
        return acc;
      }, {});

      const existingResponses = prevValues[id]?.response || [];
      const newResponses = isChecked
        ? [...existingResponses, optionId]
        : existingResponses.filter((response) => response !== optionId);

      const newValue = {
        step_id: id,
        step_type: step.step_input_type,
        response: newResponses,
        step_header: step.step_title,
      };

      const updatedIntegrationResponse = integrationResponse.filter(item => item.step_id !== id);
      updatedIntegrationResponse.push({
        ...newValue,
        response: newResponses.map(id => labelMap[id]),
      });

      setIntegrationResponse(updatedIntegrationResponse);
      submitStepIntegrationResponse(customerData, updatedIntegrationResponse);

      return {
        ...prevValues,
        [id]: newValue,
      };
    });
  };



  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const errors = validateForm();
    const hasValidationErrors = Object.keys(errors).length > 0;

    if (hasValidationErrors) {
      setFormErrors(errors);
      toast.error('Please fill all required fields');
      return;
    }

    setLoadingForm(true)


    try {
      const isFull = true
      await submitStepIntegrationResponse(customerData, integrationResponse, hasValidationErrors, "ert", "Erte", isFull);

      setFormValues({});
      setSelectedRating(0);
      setSelectedSmiley('');
      setSelectedThumb(null);
      setSelected(dropdownOptions.length > 0 ? dropdownOptions[0] : null);
      setFormErrors({});

      toast.success('Form submitted successfully!');
      setShowThankYouMessage(true);
      setShowConfetti(true);
    } catch (error) {
      toast.error('Please fill required field.');
    } finally {
      setLoadingForm(false);
    }

  };
  const handleRedireact = () => {
    window.location.reload();
  }

  const renderFormElement = (step: { step_input_type: any; id: any; step_title: any; step_mandatory: any; step_input_options: any; }) => {
    const { id, step_input_type, step_title, step_mandatory, step_input_options } = step;
    const isRequired = step_mandatory?.trim().toLowerCase() === 'yes';

    const commonProps = {
      id,
      name: id,
      value: formValues[id]?.response || '',
      onChange: (e: any) => handleInputChange(id, e.target.value, step),
      onBlur: handleBlur,
      required: isRequired,
      className: `mt-1 p-2 px-4 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 placeholder:text-[14px] md:placeholder:text-[16px] lg:placeholder:text-[14px] text-[14px] md:text-sm lg:text-sm ${formErrors[id] ? 'border-red-500' : ''}`
    };
    switch (step.step_input_type) {
      case 'text':
        return (
          <div key={step.id} className="mb-4 flex flex-col gap-y-2">
            <label htmlFor={step.id} className="block text-[14px] md:text-[16px] lg:text-[16px] leading-6 font-medium text-gray-700">
              {step.step_title}
            </label>

          </div>
        );
      case 'heading':
        return (
          <div key={step.id} className="mb-4 flex flex-col gap-y-2">
            <h2 className="text-[16px] md:text-[18px] lg:text-xl font-bold">{step.step_title}</h2>
          </div>
        );
      case 'input':
        return (
          <div key={step.id} className="mb-4 flex flex-col gap-y-1 md:gap-y-2 lg:gap-y-2">
            <label htmlFor={step.id} className="block text-[14px] md:text-[16px] lg:text-[16px] leading-6 font-medium text-gray-700">
              {step.step_title}
              {isRequired && <span className='text-red-500 ml-1'>*</span>}
            </label>
            <input
              {...commonProps}
              type={step_input_type === 'number' ? 'number' : 'text'}
              placeholder="Enter your answer"
              onBlur={handleBlur}
            />
            {formErrors[id] && <p className="mt-1 text-sm text-red-500">{formErrors[id]}</p>}
          </div>
        );
      case 'textarea':
        return (
          <div key={step.id} className="mb-4 flex flex-col gap-y-1 md:gap-y-2 lg:gap-y-2">
            <label htmlFor={step.id} className="block text-[14px] md:text-[16px] lg:text-[16px] leading-6 font-medium text-gray-700">
              {step.step_title}
              {isRequired && <span className='text-red-500 ml-1'>*</span>}
            </label>
            <textarea
              {...commonProps}
              type={step_input_type === 'number' ? 'number' : 'textarea'}
              placeholder="Enter your answer"
            />
            {formErrors[id] && <p className="mt-1 text-sm text-red-500">{formErrors[id]}</p>}
          </div>
        );
      case 'email':
        return (
          <div key={step.id} className="mb-4 flex flex-col gap-y-1 md:gap-y-2 lg:gap-y-2">
            <label htmlFor={step.id} className="block text-[14px] md:text-[16px] lg:text-[16px] leading-6 font-medium text-gray-700">
              {step.step_title}
              {isRequired && <span className='text-red-500 ml-1'>*</span>}
            </label>
            <input

              placeholder="Enter your answer"
              {...commonProps}
              type={step_input_type === 'number' ? 'number' : 'text'}
            />
            {formErrors[id] && <p className="mt-1 text-sm text-red-500">{formErrors[id]}</p>}
          </div>
        );
      case 'number':
        return (
          <div key={step.id} className='mb-4 flex flex-col gap-y-1 md:gap-y-2 lg:gap-y-2'>
            <label htmlFor={step.id} className="block text-[14px] md:text-[16px] lg:text-[16px] leading-6 font-medium text-gray-700">
              {step.step_title}
              {isRequired && <span className='text-red-500 ml-1'>*</span>}
            </label>
            <input
              placeholder="Enter your answer"
              {...commonProps}
              type={step_input_type === 'number' ? 'number' : 'textarea'}
            />
            {formErrors[id] && <p className="mt-1 text-sm text-red-500">{formErrors[id]}</p>}
          </div>
        )
      case 'dropdown':
        const dropdownOptions = JSON.parse(step.step_input_options);
        return (
          <div key={id} className="mb-4"

          >
            <Listbox
              as="div"
              value={formValues[id]?.response || ''}
              onChange={(value) => handleInputChange(id, value, step)}

            >
              <label className="block text-[14px] md:text-[16px] lg:text-[16px] leading-6 font-medium text-gray-700">
                {step_title}
                {isRequired && <span className='text-red-500 ml-1'>*</span>}
              </label>
              <div className="relative mt-2 md:mt-3 lg:mt-3">
                <Listbox.Button
                  className={`relative w-full cursor-default rounded-md bg-white py-[7px] pl-4 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ${formErrors[id] ? 'ring-red-500' : 'ring-gray-300'
                    } focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                >
                  <span className="block truncate text-gray-700">
                    {formValues[id]?.response || 'Select an option'}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                  </span>
                </Listbox.Button>

                <Listbox.Options
                  className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                  {dropdownOptions?.map((option) => (
                    <Listbox.Option
                      key={option?.id}
                      value={option?.label}
                      className="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-indigo-600 hover:text-white"
                    >
                      {({ selected, active }) => (
                        <>
                          <span className={`block truncate ${selected ? 'font-semibold text-[14px] md:text-[16px] lg:text-[16px]' : 'font-normal text-[14px] md:text-[16px] lg:text-[16px]'}`}>
                            {option?.label}
                          </span>
                          {selected && (
                            <span className={`absolute inset-y-0 right-0 flex items-center pr-4 ${active ? 'text-white' : 'text-indigo-600'}`}>
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          )}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </div>
            </Listbox>

            {formErrors[id] && <p className="mt-1 text-sm text-red-500">{formErrors[id]}</p>}
          </div>
        );
      case 'radio':
        const radioOptions = JSON.parse(step.step_input_options);
        return (
          <div key={id} className="mb-4 flex flex-col gap-y-1 md:gap-y-2 lg:gap-y-2">
            <fieldset>
              <legend className="block text-[14px] md:text-[16px] lg:text-[16px] leading-6 font-medium text-gray-700">
                {step.step_title}
                {isRequired && <span className='text-red-500 ml-1'>*</span>}
              </legend>
              <div className="mt-2 md:mt-3 lg:mt-3 flex flex-col gap-y-3">
                {Object.entries(radioOptions).map(([value, { label }]) => (
                  <div key={value} className="flex items-center space-x-2 md:space-x-3 lg:space-x-3">
                    <input
                      type="radio"
                      id={`${id}-${value}`}
                      name={id}
                      value={value}
                      checked={formValues[id]?.response === label}
                      onChange={(e) => handleInputChange(id, label, step)}
                      className="h-[16px] md:h-[18px] lg:h-[18px] w-[16px] md:w-[18px] lg:w-[18px] border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                    <label htmlFor={`${id}-${value}`} className="text-[14px] md:text-[16px] lg:text-[16px] font-normal text-gray-700">
                      {label}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
            {formErrors[id] && <p className="mt-1 text-sm text-red-500">{formErrors[id]}</p>}
          </div>
        );
      case 'checkbox':
        const checkboxOptions = JSON.parse(step.step_input_options);

        return (
          <div key={id} className="mb-4 flex flex-col gap-y-3">
            <fieldset>
              <legend className="block text-[14px] md:text-[16px] lg:text-[16px] leading-6 font-medium text-gray-700">
                {step.step_title}
                {isRequired && <span className='text-red-500 ml-1'>*</span>}
              </legend>
              <div className="mt-3 flex flex-col gap-y-1 md:gap-y-2 lg:gap-y-2">
                {checkboxOptions.map((option) => {
                  const { id: optionId, label } = option;
                  const isChecked = formValues[id]?.response?.includes(optionId);

                  return (
                    <div key={optionId} className="flex items-center space-x-2 md:space-x-3 lg:space-x-3">
                      <input
                        type="checkbox"
                        id={`${id}-${optionId}`}
                        name={id}
                        value={optionId}
                        checked={isChecked}
                        onChange={(e) => handleCheckboxChange(e, id, optionId, step)}
                        className="h-[16px] md:h-[18px] lg:h-[18px] w-[16px] md:w-[18px] lg:w-[18px] text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                      />
                      <label htmlFor={`${id}-${optionId}`} className="text-[14px] md:text-[16px] lg:text-[16px] font-normal text-gray-700">
                        {label}
                      </label>
                    </div>
                  );
                })}
              </div>
            </fieldset>
            {formErrors[id] && <p className="mt-1 text-sm text-red-500">{formErrors[id]}</p>}
          </div>
        );
      case 'likert':
        const likertOptions = JSON.parse(step.step_input_options);
        return (
          <div key={id} className="mb-4 flex flex-col gap-y-2">
            <fieldset>
              <legend className="block text-[14px] md:text-[16px] lg:text-[16px] leading-6 font-medium text-gray-700">
                {step.step_title}
                {isRequired && <span className='text-red-500 ml-1'>*</span>}
              </legend>
              <div className="mt-3 md:mt-4 lg:mt-4 flex items-center gap-2 flex-wrap lg:flex-nowrap">
                {likertOptions.map(({ id: optionId, label }: any) => (
                  <div key={optionId} className="flex items-center">
                    <input
                      type="radio"
                      id={`${id}-${optionId}`}
                      name={id}
                      value={label}
                      className="hidden peer"
                      checked={formValues[id]?.response === label}
                      onChange={() => handleInputChange(id, label, step)}
                      onBlur={() => handleBlur(id, label, step)}
                    />
                    <label
                      htmlFor={`${id}-${optionId}`}
                      className="flex items-center justify-center text-gray-700 font-normal peer-checked:font-medium peer-checked:text-white p-2 md:p-4 lg:p-4 border border-gray-300 rounded-md cursor-pointer peer-checked:bg-indigo-600 peer-checked:border-indigo-600"
                    >
                      <span className="text-sm peer-checked:text-indigo-600 ">{label}</span>
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
            {formErrors[id] && <p className="mt-1 text-sm text-red-500">{formErrors[id]}</p>}
          </div>
        )
      case 'numberscale':
        const numberOptions = JSON.parse(step.step_input_options);
        return (
          <div key={id} className="mb-4 flex flex-col gap-y-2">
            <fieldset>
              <legend className="block text-[14px] md:text-[16px] lg:text-[16px] leading-6 font-medium text-gray-700">{step.step_title}
                {isRequired && <span className='text-red-500 ml-1'>*</span>}
              </legend>
              <div className="mt-3 md:mt-4 lg:mt-4 flex  items-center gap-2 flex-wrap lg:flex-nowrap">
                {numberOptions.map(({ id: optionId, label }: any) => (
                  <div key={optionId} className="flex items-center">
                    <input
                      type="radio"
                      id={`${id}-${optionId}`}
                      name={id}
                      value={label}
                      checked={formValues[id]?.response === label}
                      onChange={() => handleInputChange(id, label, step)}
                      className="hidden peer"
                    />
                    <label
                      htmlFor={`${id}-${optionId}`}
                      className="flex items-center w-[50px] h-[50px] font-normal peer-checked:font-medium justify-center text-gray-700 peer-checked:text-white p-2 md:p-4 lg:p-4 border border-gray-300 rounded-md cursor-pointer peer-checked:bg-indigo-600 peer-checked:border-indigo-600"
                    >
                      <span className="text-sm  peer-checked:text-indigo-600">{label}</span>
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
            {formErrors[id] && <p className="mt-1 text-sm text-red-500">{formErrors[id]}</p>}
          </div>
        )
      case 'star':
        const starOptions = JSON.parse(step.step_input_options);
        return (
          <div className="mb-4 flex flex-col gap-y-2">
            <fieldset>
              <legend className="block text-[14px] md:text-[16px] lg:text-[16px] leading-6 font-medium text-gray-700">{step.step_title}
                {isRequired && <span className='text-red-500 ml-1'>*</span>}
              </legend>
              <div className="mt-3 flex flex-col space-y-2 ">
                <StarRating value={formValues[id]?.response || 0}
                  onChange={(value) => handleInputChange(id, value, step)} />
              </div>
            </fieldset>
            {formErrors[id] && <p className="mt-1 text-sm text-red-500">{formErrors[id]}</p>}
          </div>
        );
      case 'smiley':
        return (
          <div className="mb-4 flex flex-col gap-y-2">
            <fieldset>
              <legend className="block text-[14px] md:text-[16px] lg:text-[16px] leading-6 font-medium text-gray-700">{step.step_title}
                {isRequired && <span className='text-red-500 ml-1'>*</span>}
              </legend>
              <div className="mt-4 flex  items-center gap-x-2 md:gap-x-5 lg:gap-x-6 gap-y-2 flex-wrap lg:flex-nowrap">
                {smileyOptions.map((type) => (
                  <SmileyRating
                    key={type}
                    type={type}
                    value={formValues[id]?.response || ''}
                    isSelected={formValues[id]?.response === type}
                    onSelect={(value) => handleInputChange(id, value, step)}
                  />
                ))}
              </div>
            </fieldset>
            {formErrors[id] && <p className="mt-1 text-sm text-red-500">{formErrors[id]}</p>}
          </div>
        );
      case 'thumbsup':
        return (
          <div className="mb-4 flex flex-col gap-y-2">
            <fieldset>
              <legend className="block text-[14px] md:text-[16px] lg:text-[16px] leading-6 font-medium text-gray-700">
                {step.step_title}
                {isRequired && <span className='text-red-500 ml-1'>*</span>}
              </legend>
              <div className="mt-4 flex items-center gap-x-2 md:gap-x-5 lg:gap-x-6 gap-y-2 flex-wrap lg:flex-nowrap">
                <ThumbRating
                  type="thumbs-down"
                  isSelected={formValues[id]?.response === -1}
                  onSelect={(value) => handleInputChange(id, value, step)}
                />
                <ThumbRating
                  type="thumbs-up"
                  isSelected={formValues[id]?.response === 1}
                  onSelect={(value) => handleInputChange(id, value, step)}
                />
              </div>
            </fieldset>
            {formErrors[id] && <p className="mt-1 text-sm text-red-500">{formErrors[id]}</p>}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <form className=" space-y-10 md:space-y-10 lg:space-y-12">
      {!showThankYouMessage &&
        <>
          {steps.map(renderFormElement)}
          <div className="mt-6 flex items-center justify-start gap-x-6">

            <button
              onClick={handleSubmit}
              type="submit"
              className='mt-10 md:mt-16 lg:mt-20 capitalize group inline-flex shrink-0 select-none items-center justify-center text-sm font-medium
                       leading-6 transition-colors duration-100 wg-antialiased focus:outline-0 focus-visible:outline focus-visible:outline-2 
                       focus-visible:outline-offset-2 disabled:pointer-events-none gap-0 px-8  py-2 rounded-lg text-white outline-primary
                        disabled:opacity-50 bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600'
            >
              <div className='flex items-center gap-x-1'>
                {`Share feedback ->`}
                {loadingForm && (
                  <div className="simple-spinner">
                    <span></span>
                  </div>
                )}

              </div>
            </button>

          </div>
        </>
      }
      {showThankYouMessage && (
        <div className="mt-4 p-4 bg-green-100 text-green-800 border border-green-300 rounded-md relative">
          <h4 className='mb-4 text-green-800 h-[100%] w-full p-0 text-[16px] md:text-[20px] lg:text-[24px] leading-5 lg:leading-[42px] font-medium flex items-start md:items-center lg:items-center gap-2'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
            </svg>
            Thank You for your honest feedback!
          </h4>
          <p className='text-[16px] md:text-[16px] lg:text-[16px] leading-6 font-normal text-green-800 w-full p-0 m-0'>
            We appreciate you taking the time to share your thoughts with us. Your feedback is invaluable in helping us improve and provide a better experience for you.
          </p>

          {showConfetti && (
            <div className="flex justify-center items-center absolute inset-0">
              <ConfettiExplosion />
            </div>
          )}
        </div>
      )}


      {showThankYouMessage && (
        <div className="">
          <button
            onClick={handleRedireact}
            type="submit"
            className='mt-0 capitalize group inline-flex shrink-0 select-none items-center justify-center text-sm font-medium
                       leading-6 transition-colors duration-100 wg-antialiased focus:outline-0 focus-visible:outline focus-visible:outline-2 
                       focus-visible:outline-offset-2 disabled:pointer-events-none gap-0 px-8  py-2 rounded-lg text-white outline-primary
                        disabled:opacity-50 bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600'
          >
            <div className='flex items-center gap-x-1'>
              {`Share new feedback`}
            </div>
          </button>
        </div>
      )}
    </form>
  );
};

export default DynamicSurveyForm;
