import React, { Fragment, useState } from "react"
import { Transition } from '@tailwindui/react'
import Header from "../documentheader/Header"
import ChannelDropDown from "../../../../components/ChanneldropDown"
import { Input } from "@lemonsqueezy/wedges"
const channelList = [{ name: "copy link" }, { name: "share" }, { name: "archive" }, { name: "delete" }]

const Document = () => {
   const [show, setShow] = useState(false)
   return (<>
      <Header />
      <div className="w-full h-screen flex justify-between ">
         <div className="w-[97.5%] flex justify-between h-full">
            <div className='w-full bg-gray-100 p-5'>
               <div className="w-3/4 h-full items-center m-auto bg-white p-10">
                  <textarea
                     rows={4}
                     name="comment"
                     id="comment"
                     className="block w-full rounded-md border-0  p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                     defaultValue={''}
                  />
               </div>
            </div>
            <Transition
               show={show}
               enter="transition-opacity duration-75"
               enterFrom="opacity-0"
               enterTo="opacity-100"
               leave="transition-opacity duration-150"
               leaveFrom="opacity-100"
               leaveTo="opacity-0"
            >
               <div className="w-[350px]  h-full bg-white border-r p-3 ">
                  <div className="flex justify-between items-center w-full">
                     <p className="fontfamily text-base font-semibold ">Followup</p>
                     <p className="fontfamily text-base font-[400]">Request all</p>
                  </div>
                  <div className='relative w-full flex my-3 text-gray-500 border rounded-md overflow-hidden  focus:bg-white focus:border-indigo-600'>
                     <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className=' w-5 h-5 my-auto text-gray-400 mx-2 '
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'>
                        <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z' />
                     </svg>
                     <Input type='text' placeholder='Search' className='block border-l-0 w-full rounded-md border-0 py-1.5 text-gray-900  placeholder:text-gray-400 focus:outline-none !ring-0 sm:text-sm sm:leading-6' />
                  </div>
                  <div className='w-full flex justify-between items-center py-3'>
                     <div className='w-48 flex items-center cursor-pointer'>
                        <div className='mr-2 w-8 h-8 rounded-full flex justify-center items-center bg-slate-300'>
                           <p className='text-sm fontfamily'>V</p>
                        </div>
                        <div className='ml-2 w-[70%]'>
                           <p className="block text-sm font-medium leading-6 text-gray-900">Vikas</p>
                           <p className="text-sm leading-4 text-gray-500">Admin</p>
                        </div>
                     </div>
                     <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                        Request
                     </span>
                  </div>
                  <div className='w-full flex justify-between items-center py-3'>
                     <div className='w-48 flex items-center cursor-pointer'>
                        <div className='mr-2 w-8 h-8 rounded-full flex justify-center items-center bg-slate-300'>
                           <p className='text-sm fontfamily'>G</p>
                        </div>
                        <div className='ml-2 w-[70%]'>
                           <p className="block text-sm font-medium leading-6 text-gray-900">Govind</p>
                           <p className="text-sm leading-4 text-gray-500">Manager</p>
                        </div>
                     </div>
                     <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                        Request
                     </span>
                  </div>

               </div>
            </Transition>
         </div>
         <div className="lg:w-[2.5%] w-[50px] h-full flex flex-col items-center p-2">
            <div onClick={() => setShow(!show)} className="p-2 hover:bg-gray-100 rounded-md mt-2 cursor-pointer">
               <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5 ">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
               </svg>
            </div>
            <div className="p-2 hover:bg-gray-100 rounded-md mt-2 cursor-pointer">
               <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z" />
               </svg>
            </div>
            <div className="mt-3 border-b border-gray-500" />
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5 mt-5 cursor-pointer">
               <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5 my-5 cursor-pointer">
               <path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
            </svg>
            <ChannelDropDown data={channelList} previousValue={"active"} onChangeValue={(e) => alert(e)} />
         </div>
      </div>


   </>)
}
export default Document