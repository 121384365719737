import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';
import * as api from './loginAPI';
import { UserLogin } from '../../../reducer/type';

export interface AuthState {
    loading: boolean;
    userInfo: UserLogin | null;
}

const initialState: AuthState = {
    loading: false,
    userInfo: null,
};

export const userLogin = createAsyncThunk(
    'user/login',
    async (
        options: UserLogin,
        { rejectWithValue }
    ) => {
        try {
            return await api.user_login(options.email, options.password, options.deviceId, options.deviceName);
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
export const userLogout = createAsyncThunk(
    'user/logout',
    async () => {
        try {
            return await api.user_logout();
        } catch (err) {
           console.log("err",err)
        }
    }
);

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
    },
});

export default userSlice.reducer;

