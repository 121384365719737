import React, { Suspense } from 'react';
import Routing from './router/Routing';

function App() {
	return (
		<Suspense fallback={null}>
			<Routing />
		</Suspense>
	);
}

export default App;
