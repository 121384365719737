// @ts-nocheck

import React, { Fragment, ReactNode, useState, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Cog6ToothIcon,
  FlagIcon,
  XMarkIcon
} from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react'
import { user_logout } from '../../auth/login/loginAPI';
import { UserCircleIcon } from '@heroicons/react/20/solid'
import { MenuDropdown } from '../../../components/MenuDropdown';
import {
  CursorArrowRippleIcon,
} from '@heroicons/react/24/outline'
import { LogInIcon, UserIcon, SettingsIcon, InboxIcon, UserCheckIcon, PlusIcon } from "@iconicicons/react";
import BaseLogo from "../../../assets/images/logo/base-logo.png"
import { routes } from '../Routes';
import SendDetails from './SendDetails';
import { useDispatch } from 'react-redux';
import { useSearch } from '../../../context/SearchContext';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}
interface sidebarProps {
  onClickValue: boolean
  onClick: any,
  id: string | null,
  channelData: {}[]
  onTabChange?: (selectedTabName: string) => void;
  onTabChange1?: (selectedTabName: string) => void;
  onChannelIdChange?: (id: number) => void;
}

interface DropdownItem {
  className?: string;
  icon: ReactNode;
  label: string;
  shortcut?: any;
  disabled?: boolean;
  onClick?: () => void;
  href?: string;

}

interface DropdownGroup {
  items: DropdownItem[];
  image?: ReactNode;
  label?: any;
  name?: string
}



const Sidebar: React.FC<sidebarProps> = ({ onClickValue, onClick, id, channelData, onTabChange, onTabChange1, onChannelIdChange }: any) => {

  const navigate = useNavigate()
  const [bgColor, setBgColor] = useState("Dashboard")
  const [bgId, setBgId] = useState()
  const [open, setOpen] = useState(false)
  const [selectedTab, setSelectedTab] = useState('problem')
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [updateChannelName, setUpdateChannelName] = useState("")
  const [updateBaseLogo, setUpdateBaseLogo] = useState("")
  const [updateProfileImage, setUpdateProfileImage] = useState("")
  const { setSearchTerm } = useSearch();
  const [baseDetails, setBaseDetails] = useState('')
  const [userDetails, setUserDetails] = useState('')
  const [showBaseSetting, setShowBaseSetting] = useState<boolean>(false);
  const [baseMemberDetails, setBaseMemberDetails] = useState([]);
  const [storedChannelData, setStoredChannelData] = useState<any[]>([]);
  const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;

  const renderTabContent = () => {
    switch (selectedTab) {
      case 'problem':
        return (
          <div className='flex flex-col gap-y-3'>
            <h2 className="text-[15px] text-[#303031] font-medium leading-[1.4375rem]">Contact Us</h2>
            <p className="text-[13px] text-[#5e5e5f] font-normal leading-normal">What is the issue? If you’re reporting a bug, what are the steps you took so we can reproduce the behaviour?</p>
            <span className='border-b border-solid border-[#e3e3e3] mx-[-32px]'></span>
          </div>
        )
      case 'question':
        return (
          <div className='flex flex-col gap-y-3'>
            <h2 className="text-[15px] text-[#303031] font-medium leading-[1.4375rem]">Ask a question</h2>
            <p className="text-[13px] text-[#5e5e5f] font-normal leading-normal w-[90%]">How can we help? Please share any relevant information we may need to answer your question.</p>
            <span className='border-b border-solid border-[#e3e3e3] ml-[-32px] mr-[-64px]'></span>
          </div>
        )
      case 'feedback':
        return (
          <div className='flex flex-col gap-y-3'>
            <h2 className="text-[15px] text-[#303031] font-medium leading-[1.4375rem]">Send feedback</h2>
            <p className="text-[13px] text-[#5e5e5f] font-normal leading-normal">How can we improve Linear? If you have a feature request, can you also share how you would use it and why it’s important to you?</p>
            <span className='border-b border-solid border-[#e3e3e3] mx-[-32px]'></span>
          </div>)
      default:
        return null
    }
  }

  const getPlaceholderText = () => {
    switch (selectedTab) {
      case 'problem':
        return 'Something seems wrong...';
      case 'question':
        return 'How do I...';
      case 'feedback':
        return 'What if...';
      default:
        return 'Please enter your message...';
    }
  };





  useEffect(() => {
    const storedData = localStorage.getItem("boardDataItem");
    if (storedData) {
      setStoredChannelData(JSON.parse(storedData));
    }
  }, []);

  useEffect(() => {
    // Retrieve and parse the stored channel data
    const storedData = localStorage.getItem("baseDetails");

    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        if (parsedData && parsedData.name) {
          setUpdateChannelName(parsedData.name);
        }
      } catch (error) {
        console.error("Error parsing stored channel data:", error);
      }
    }
  }, []);


  useEffect(() => {
    const storedData = localStorage.getItem("baseDetails");
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        if (parsedData && parsedData?.logo) {
          setUpdateBaseLogo(parsedData?.logo);
          setBaseDetails(parsedData);
        }
      } catch (error) {
        console.error("Error parsing stored channel data:", error);
      }
    }
  }, []);


  useEffect(() => {
    const storedData = localStorage.getItem("userProfileDetails");
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        if (parsedData) {
          setUserDetails(parsedData);
        }
      } catch (error) {
        console.error("Error parsing stored channel data:", error);
      }
    }
  }, []);

  useEffect(() => {
    const handleUserProfileDetailsUpdated = () => {
      const storedData = localStorage.getItem("userProfileDetails");
      if (storedData) {
        try {
          const parsedData = JSON.parse(storedData);
          
          const profilePic = parsedData?.user?.profile_pic || parsedData?.data?.profile_pic;
          if (profilePic) {
            setUpdateProfileImage(profilePic);
            setUserDetails(parsedData);
          }
        } catch (error) {
          console.error("Error parsing stored channel data:", error);
        }
      }
    };

    window.addEventListener("userProfileDetailsUpdated", handleUserProfileDetailsUpdated);

    handleUserProfileDetailsUpdated();
    return () => {
      window.removeEventListener("userProfileDetailsUpdated", handleUserProfileDetailsUpdated);
    };
  }, []);

  useEffect(() => {
    if (userDetails && baseMemberDetails.length > 0) {
      const currentUserId = userDetails?.user?.id;
      const baseMember = baseMemberDetails.find(member => member.id === currentUserId);
      if (baseMember && baseMember.role === "admin") {
        setShowBaseSetting(true);
      } else {
        setShowBaseSetting(false);
      }
    }
  }, [userDetails, baseMemberDetails]);


  useEffect(() => {
    // Retrieve and parse the stored channel data
    const storedData = localStorage.getItem("baseDetails");

    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        if (parsedData && parsedData.data && parsedData.data.base && parsedData.data.base.name) {
          setUpdateChannelName(parsedData && parsedData.data && parsedData.data.base && parsedData.data.base.name);
        }
        if (parsedData && parsedData.data && parsedData.data.base && parsedData.data.base.logo) {
          setUpdateBaseLogo(parsedData && parsedData.data && parsedData.data.base && parsedData.data.base.logo);
        }
      } catch (error) {
        console.error("Error parsing stored channel data:", error);
      }
    }
  }, []);


  // useEffect(() => {
  //   if (channelData.length > 0) {
  //     localStorage.setItem("boardDataItem", JSON.stringify(channelData));
  //     setStoredChannelData(channelData);
  //   }
  // }, [channelData]);

  useEffect(() => {
    setStoredChannelData(channelData);
  }, [channelData]);

  useEffect(() => {
    if (localStorage.getItem("baseId") !== baseId) {
    }
  }, [baseId, channelData]);

  const [newChannelId1, setNewChannelId1] = useState<any>("")

  const _channelHandler = async (item: { id: number, name: string }) => {
    setBgColor(item.name)
    await localStorage.setItem("channelId", JSON.stringify(item.id))
    setNewChannelId1(item.id)
    if (onChannelIdChange) {
      onChannelIdChange(item.id); // Send the newChannelId1 to Dashboard
    }
  }

  const _logout = () => {
    user_logout().then((res) => {
      if (res?.status === 200) {
        localStorage.clear()
        navigate("/login")
      }
    })
  }

  const onTabsChange = (selectedTabName: any) => {
    onTabChange(selectedTabName);
  };

  const onTabChangeCustom = (selectedTabName: any) => {

    onTabChange1(selectedTabName)
  }

  const userAuthId = localStorage.getItem("userAuthId")




  const dropdownGroups: DropdownGroup[] = [
    {
      items: [
        {
          icon: <UserCircleIcon className='h-5 w-5 text-gray-300' aria-hidden='true' />,
          label: baseDetails?.name,
          shortcut: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              className="h-[24px] w-6 text-wedges-purple-500 !opacity-100"
            >
              <path
                stroke="#7047EB"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="m7.75 12.75 2.25 2.5 6.25-6.5"
              ></path>
            </svg>

          ),
          className: "text-wedges-purple-500 font-medium",
        },
      ],
    },
    {
      items: [
        {
          icon: <UserIcon />,
          label: userDetails?.user?.name || `${localStorage.getItem("userName")}`,
          className: "capitalize",
        },
        {
          icon: <InboxIcon />,
          label: `${localStorage.getItem("userEmail")}`,
        }
      ],
    },
    ...(showBaseSetting ? [{
      items: [
        {
          icon: <SettingsIcon />,
          label: "Base Setting",
          href: `/${id}/setting/detail`,
          className: "capitalize",
        },
        {
          icon: <CursorArrowRippleIcon width={24} height={24} className="h-[22px] w-[22px] fill-transparent font-medium stroke-current text-wedges-gray-400" aria-hidden="true" />,
          label: "Create/Switch Base",
          href: `/base`,
          className: "capitalize",
        },
      ],
    }] : []),
    ...(!showBaseSetting ? [{
      items: [
        {
          icon: <CursorArrowRippleIcon width={24} height={24} className="h-[22px] w-[22px] fill-transparent font-medium stroke-current text-wedges-gray-400" aria-hidden="true" />,
          label: "Create/Switch Base",
          href: `/base`,
          className: "capitalize",
        },
      ],
    }] : []),
    {
      items: [
        {
          icon: <UserIcon />,
          label: "My Account",
          href: `/user/${userAuthId}/profile`,
          className: "capitalize",
        }
      ],
    },
    {
      items: [
        {
          icon: <LogInIcon />,
          label: "Logout",
          onClick: _logout,
          className: "capitalize"
        },
      ],
    },
  ];

  const handleClickSetting = () => {
    navigate(`/${id}/setting/detail`);
    setOpen(true);
  }

  const handleFileUpload = (event: any) => {
    setUploadedFiles([...uploadedFiles, ...event.target.files]);
  };

  const baseIdString1 = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
  const baseId1 = baseIdString1 ? JSON.parse(baseIdString1) : null;
  const [itemStatus, setItemStatus] = useState([])
  const [itemTags, setItemTags] = useState([])



  const handleTabChange = (selectedTabName: any) => {
    setSelectedTab(selectedTabName);
  }
  const handleTabChange1 = (selectedTabName: any) => {
    setSelectedTab(selectedTabName);
  }

  const dispatch = useDispatch<any>();

  // useEffect(() => {
  //   dispatch(fetchBaseDetails(baseId1));
  // }, [baseId, dispatch]);


  // useEffect(() => {
  //   dispatch(fetchBaseMemberDetails(baseId1))
  //     .then((response: any) => {
  //       setBaseMemberDetails(response);
  //     })
  //     .catch((error: any) => {
  //       console.error('Error fetching base member details:', error);
  //     });
  // }, [baseId1, dispatch]);


  // base member details


  const fetchBaseMemberDetails = async (baseId: number) => {
    const storedBaseMember = localStorage.getItem('baseMembersDetails');

    if (storedBaseMember) {
      const parsedData = JSON.parse(storedBaseMember);
      const baseMembers = parsedData;

      setBaseMemberDetails(baseMembers);
    } else {
      console.log('No base member details found in localStorage.');
    }
  };


  useEffect(() => {
    const baseIdString = localStorage.getItem('baseId');
    if (baseIdString) {
      const baseId = parseInt(baseIdString, 10);
      fetchBaseMemberDetails(baseId);
    }
  }, []);


  // item tags api

  const fetchItemTagsList = async (baseId: number) => {
    const storedItemTags = localStorage.getItem('itemTagsList');
    if (storedItemTags) {
      const parsedData = JSON.parse(storedItemTags);
      // const { data } = parsedData;
      setItemTags(parsedData);
    }
  };

  useEffect(() => {
    const baseIdString = localStorage.getItem('baseId');
    if (baseIdString) {
      const baseId = parseInt(baseIdString, 10);
      fetchItemTagsList(baseId);
    }
  }, []);

  // get item  status list
  const fetchItemStatusList = async (baseId: number) => {
    const storedItemStatus = localStorage.getItem('itemStatusList');

    if (storedItemStatus) {
      const parsedData = JSON.parse(storedItemStatus);
      if (parsedData) {
        setItemStatus(parsedData);
      } else {
        console.error("Invalid data format in local storage.");
      }
    } else {
      console.error("No item status data found in local storage.");
    }
  };

  useEffect(() => {
    const baseIdString = localStorage.getItem('baseId');
    if (baseIdString) {
      const baseId = parseInt(baseIdString, 10);
      fetchItemStatusList(baseId);
    }
  }, []);

  // 
  const clearLocalStorageAndNotify = (tabName: any) => {
    localStorage.removeItem('cachedFeedbackData');
    localStorage.removeItem('cachedInsightData');
    localStorage.removeItem('groupListInsightData');
    localStorage.removeItem('cachedTopicData');
    localStorage.removeItem('cachedBoardsData');

    onTabChange(tabName);
    window.dispatchEvent(new CustomEvent('sidebarItemClicked'));
  };
  const [activeItem, setActiveItem] = useState('');


  // const handleChannelClick = (value: any) => {
  //   setActiveItem(value.id);
  //   onTabChangeCustom(value.name);
  //   // Add any other necessary logic here
  // };
  // const handleChannelClick = (value: any) => {
  //   setActiveItem(`board/${value.id}`);
  //   onTabChange1(value.name);
  // };


  const handleChannelClick = (value: any) => {
    const boardPath = `/board/${value.id}`;
    setActiveItem(boardPath);
    onTabChange1(value.name);
    navigate(`/${id}${boardPath}`);
    setSearchTerm('');
  };

  const handleItemClick = (itemName: any) => {
    setActiveItem(itemName.toLowerCase());
    onTabsChange(itemName);
    setSearchTerm('');
    // Add any other necessary logic here
  };


  const location = useLocation();
  const { boardId } = useParams();

  const isActive = (path: any) => {
    if (typeof path !== 'string') {
      return false;
    }
    const currentPath = location.pathname;
    if (path.includes('/board/')) {
      return currentPath.includes(path);
    }
    return currentPath.endsWith(path) || currentPath.startsWith(path);
  };


  const imageUrl = updateProfileImage
    ? `${process.env.REACT_APP_API_BASE_URL}/${updateProfileImage}`
    : 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80';


  return (
    <>

      <div>
        <Transition.Root show={onClickValue} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={onClick}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-[82%] top-[-14px] flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => onClick(false)}>
                        <span className="sr-only">Close sidebar</span>

                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-4 overflow-y-auto bg-light-pink px-6 pb-4 py-4">
                    <div className="flex shrink-0 items-center px-[0rem] justify-between">
                      <Link to={`/${baseId}/dashboard`}>
                        <div className='flex gap-[0.5rem] items-center cursor-pointer h-[32px] px-[0.25rem]'>
                          <img
                            className="h-6 w-6 rounded-[5px]"
                            src={`${process.env.REACT_APP_API_BASE_URL}/${updateBaseLogo}`}
                            alt="base logo"
                          />
                          <span className='leading-[1.2] font-semibold whitespace-nowrap text-[0.8125rem] cursor-pointer capitalize'>{updateChannelName}</span>
                        </div>
                      </Link>
                      <div className="flex items-center gap-x-4 lg:gap-x-6 w-[120px] relative z-50 [&>button]:hover:bg-transparent">
                        {/* Profile dropdown */}
                        <MenuDropdown
                          groups={dropdownGroups}
                          image={
                            <img
                              className="h-6 w-6 rounded-full bg-gray-50"
                              src={imageUrl}
                              alt=""
                            />
                          }
                        />
                      </div>
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-5">
                        <li>
                          <ul role="list" >
                            {routes.map(({ layout, pages }, key) => (
                              <>
                                {pages.map((item, index) => (
                                  <li key={`${index}+${key}`} className='h-[32px] mb-[2px] py-[2px] sidebar-nav'>
                                    <Link to={`/${id}${item.path}`}
                                      className={classNames(
                                        activeItem === item.name.toLowerCase()
                                          ? ' text-black-100 bg-[#00000014] hover:bg-[rgb(0 0 0 / 6%)] cursor-pointer'
                                          : 'text-black-100 hover:text-black-100 hover:bg-gray-100 cursor-pointer',
                                        'group flex gap-x-[10px] p-0 text-[13px]  m-0 min-h-[32px] w-full cursor-pointer leading-5 items-center rounded-[0.375rem] font-medium text-wedges-gray-900 focus:outline-none px-2'
                                      )}
                                      onClick={() => {
                                        handleItemClick(item.name)
                                        setBgColor(item.name)
                                        onTabsChange(item.name);
                                        onClick(false);
                                        clearLocalStorageAndNotify(item.name)
                                      }}

                                    >
                                      <item.icon
                                        className={classNames(
                                          item.name ? 'text-[#0000007a]' : 'text-red-100 group-hover:text-black-100',
                                          'h-[1.1em] w-[1.1em] shrink-0'
                                        )}
                                        aria-hidden="true"
                                      />
                                      {item.name}
                                    </Link>
                                  </li>
                                ))}
                              </>
                            ))}
                          </ul>
                        </li>
                        <li>
                          <div className='flex Items-center justify-between	 px-[0.75rem] hover:bg-[#0000000f]  rounded-[0.375rem] my-[2px]'>
                            <a href="" onClick={() => navigate(`/setting/detail/${id}/channel`)}
                              className="text-[13px] font-[500] text-dark-black-200 mt-[-1px] capitalize">
                              Boards</a>
                            <a href="" onClick={() => navigate(`/${id}/create/board`)}
                              className="font-normal text-black-100 flex items-center">
                              <PlusIcon className='h-4 w-4 text-black-100' /></a>
                          </div>
                          <ul role="list" >
                            {channelData && (<>
                              {channelData?.map((value: any, index: any) => (
                                <li key={index} className='h-[32px] first:mt-[0.4rem] mb-[2px] py-[2px] ml-[2px]'>
                                  <Link
                                    to={`/${id}/board/${value.id}`}
                                    onClick={() => {
                                      _channelHandler(value);
                                      setBgId(value.id);
                                      onTabChangeCustom(value.name);
                                      onClick(false)
                                      clearLocalStorageAndNotify(value.name)
                                      handleChannelClick(value)
                                    }}
                                    className={classNames(
                                      activeItem === `board/${value.id}`
                                        ? ' text-black-100 bg-[#00000014] hover:bg-[rgb(0 0 0 / 6%)] cursor-pointer'
                                        : 'text-black-100 hover:text-black-100 hover:bg-gray-100 cursor-pointer',
                                      'group flex gap-x-[10px] p-0 text-[13px] justify-between m-0 min-h-[32px] w-full cursor-pointer leading-5 items-center rounded-[0.375rem] font-medium text-wedges-gray-900 focus:outline-none px-2'

                                    )}
                                  >
                                    <div className='flex items-center gap-x-[10px]'>
                                      <span
                                        className="flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-[2px] border-none text-[0.625rem] font-medium text-white capitalize"
                                        style={{ backgroundColor: value.color }}
                                      >
                                      </span>
                                      <span className="truncate text-[13px] leading-4 ">{value.name}</span>

                                    </div>
                                    <div className=' whitespace-normal pr-[6px] text-[0.75rem] text-[#1d2025] uppercase opacity-60 font-[500]'>
                                      20
                                    </div>
                                  </Link>
                                </li>
                              ))}
                            </>)}
                          </ul>
                        </li>
                        <div className='mt-auto'>
                          <li className=" list-none sidebar-nav">
                            <Link
                              to={`/${id}/setting/detail`}
                              onClick={() => navigate(`/setting/detail/${id}`)}
                              className='group flex gap-x-[10px] p-0 text-[13px] m-0 min-h-[32px] w-full cursor-pointer leading-5 items-center rounded-[0.375rem] font-medium text-wedges-gray-900 focus:outline-none px-2'
                            >
                              <Cog6ToothIcon
                                className="h-[1.1em] w-[1.1em] shrink-0 text-[#0000007a] group-hover:text-black-100"
                                aria-hidden="true"
                              />
                              Settings
                            </Link>
                          </li>
                          <li className="list-none sidebar-nav">
                            <Link
                              to="#"
                              onClick={handleClickSetting}
                              className='group flex gap-x-[10px] p-0 text-[13px] m-0 min-h-[32px] w-full cursor-pointer leading-5 items-center rounded-[0.375rem] font-medium text-wedges-gray-900 focus:outline-none px-2'
                            >
                              <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" focusable="false" aria-hidden="true"
                                className="shrink-0 text-[#0000007a] group-hover:text-black-100" height="1.1em" width="1.1em" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="12" cy="12" r="10"></circle><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>

                              Help & support
                            </Link>
                          </li>
                        </div>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-[9] lg:flex lg:w-[225px] lg:flex-col">
          <div className="flex grow flex-col bg-white pt-[0.75rem] px-0 pb-4 sidebar-des border-r border-gray-100">
            <div className="flex shrink-0 items-center px-[0.75rem] justify-between">
              <Link to={`/${baseId}/dashboard`}>
                <div className='flex gap-[0.5rem] items-center cursor-pointer h-[32px] px-[0.25rem]'>
                  <img
                    className="h-6 w-6 rounded-[5px]"
                    src={`${process.env.REACT_APP_API_BASE_URL}/${updateBaseLogo}`}
                    alt="base logo"
                  />
                  <span className='leading-[1.2] font-semibold whitespace-nowrap text-[0.8125rem] cursor-pointer truncate w-full max-w-[170px] min-w-[130px] overflow-hidden text-ellipsis text-start capitalize '>{updateChannelName}</span>
                </div>
              </Link>
              <div className="flex items-center gap-x-4 lg:gap-x-6 w-[350px] relative z-50 [&>button]:hover:bg-transparent">
                {/* Profile dropdown */}


                <MenuDropdown
                  groups={dropdownGroups}
                  image={
                    <img
                      className="h-6 w-6 rounded-full bg-gray-50"
                      src={imageUrl}
                      alt=""
                    />
                  }
                />




              </div>
            </div>

            {/*  feedback modal popup */}
            <SendDetails id={baseId1} selectedTab={selectedTab} itemStatus={itemStatus}
              itemTags={itemTags} baseMemberDetails={baseMemberDetails} onClick={function (): void {
                throw new Error('Function not implemented.');
              }} />



            <nav className="flex flex-col px-[0.75rem]">
              <ul role="list" className="flex flex-1 flex-col gap-y-5">
                <li>
                  <ul role="list" className="">
                    {routes.map(({ layout, pages }, key) => (
                      <>
                        {pages.map((item, index) => (
                          <li key={`${index}+${key}`} className='h-[32px] first:mt-[1rem] mb-[2px] py-[2px] sidebar-nav'>
                            <Link to={`/${id}${item.path}`}
                              onClick={() => {
                                handleItemClick(item.name)
                                setBgColor(item.name)
                                onTabsChange(item.name);
                                clearLocalStorageAndNotify(item.name)
                              }}

                              className={classNames(
                                isActive(item.path)
                                  ? 'text-black-100 bg-gray-200 hover:bg-gray-300'
                                  : 'text-black-100 hover:text-black-100 hover:bg-gray-100',
                                'group flex gap-x-[10px] p-0 text-[13px] m-0 min-h-[32px] w-full cursor-pointer leading-5 items-center rounded-[0.375rem] font-medium text-wedges-gray-900 focus:outline-none px-2'
                              )}
                            >
                              <item.icon
                                className={classNames(
                                  item.name ? 'text-[#0000007a]' : 'text-red-100 group-hover:text-black-100',
                                  'h-[1.1em] w-[1.1em] shrink-0'
                                )}
                                aria-hidden="true"
                              />
                              {item.name}
                            </Link>
                          </li>
                        ))}
                      </>
                    ))}
                  </ul>
                </li>
                <li>
                  <div className='flex Items-center justify-between	 px-[0.75rem] hover:bg-[#0000000f]  rounded-[0.375rem] my-[2px]'>
                    <Link to={`/setting/detail/${id}/channel`} onClick={() => navigate(`/setting/detail/${id}/channel`)}
                      className="text-[13px] font-[500] text-dark-black-200 mt-[-1px] capitalize">
                      Boards</Link>
                    <Link to={`/${id}/create/board`} onClick={() => navigate(`/${id}/create/board`)} className="font-normal text-black-100 flex items-center">
                      <PlusIcon className="h-4 w-4 text-black-100" />
                    </Link>
                  </div>
                  <ul role="list" className='max-h-[310px] overflow-x-auto mx-[-0.75rem]'>
                    {storedChannelData && (<>
                      {storedChannelData?.map((value: any, index: any) => (
                        <li key={index} className='h-[32px] first:mt-[0.4rem] mb-[2px] py-[2px] mx-[0.75rem]'>
                          <Link
                            // to="/dashboard/feedbacks/"
                            to={`/${id}/board/${value.id}`}
                            onClick={() => {
                              _channelHandler(value); setBgId(value.id);
                              onTabChangeCustom(value.name);
                              handleChannelClick(value)
                            }}
                            className={classNames(
                              isActive(`/board/${value.id}`)
                                ? 'text-black-100 bg-gray-200 hover:bg-gray-300'
                                : 'text-black-100 hover:text-black-100 hover:bg-gray-100',
                              'group flex gap-x-[10px] p-0 text-[13px] m-0 min-h-[32px] w-full cursor-pointer leading-5 items-center rounded-[0.375rem] font-medium text-wedges-gray-900 focus:outline-none px-2'
                            )}

                          >
                            <div className='flex items-center gap-x-[10px]'>
                              <span
                                className="flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-[2px] border-none text-[0.625rem] font-medium text-white capitalize"
                                style={{ backgroundColor: value.color }}
                              >
                              </span>
                              <span className="truncate text-[13px] leading-4 whitespace-nowrap w-[140px] overflow-hidden text-ellipsis text-start">{value.name}</span>

                            </div>
                            <div className=' whitespace-normal pr-[6px] text-[0.75rem] text-[#1d2025] uppercase opacity-60 font-[500]'>
                              20
                            </div>
                          </Link>
                        </li>
                      ))}
                    </>)}
                  </ul>
                </li>
              </ul>
            </nav>
            <li className="list-none px-[12px] sidebar-nav mt-auto">
              <Link
                to={`/${id}/setting/detail`}
                onClick={() => navigate(`/${id}/setting/detail`)}
                className='group flex gap-x-[10px] p-0 text-[13px] m-0 min-h-[32px] w-full cursor-pointer leading-5 items-center rounded-[0.375rem] font-medium text-wedges-gray-900 focus:outline-none px-2'
              >
                <Cog6ToothIcon
                  className="h-[1.1em] w-[1.1em] shrink-0 text-[#0000007a] group-hover:text-black-100"
                  aria-hidden="true"
                />
                Settings
              </Link>
            </li>
            <li className="list-none px-[12px] sidebar-nav">
              <Link
                to="#"
                // onClick={haShare feedbackndleClickSetting}
                className='group flex gap-x-[10px] p-0 text-[13px] m-0 min-h-[32px] w-full cursor-pointer leading-5 items-center rounded-[0.375rem] font-medium text-wedges-gray-900 focus:outline-none px-2'
              >
                <FlagIcon className='h-[1.1em] w-[1.1em] shrink-0 text-[#0000007a] group-hover:text-black-100' />

                Share feedback
              </Link>
            </li>
          </div>
        </div>


      </div>
      <Transition show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50 md:z-10 lg:z-10" onClose={() => setOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-8 py-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[680px]">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">

                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      {/* <Dialog.Title as="h3" className="text-[15px] text-[#303031] font-medium leading-[1.4375rem]">
                        Contact us
                      </Dialog.Title> */}
                      <div className="mt-2">
                        {renderTabContent()}
                      </div>
                      <div className="mt-4">
                        <div className="flex space-x-2 flex-wrap md:flex-nowrap lg:flex-nowrap">
                          <button
                            type="button"
                            className={`px-4 py-2 ${selectedTab === 'problem' ? 'hover:bg-[#f7f7f7] inline-flex items-center justify-center whitespace-nowrap rounded-[5px] font-medium leading-normal help-tab-button min-w-[32px] h-[32px] px-[14px] text-[0.8125rem]' : 'hover:bg-[#f7f7f7] min-w-[32px] h-[32px] px-[14px] flex items-center text-[0.8125rem] text-[#303031]  rounded-[5px] bg-transparent font-medium'}`}
                            onClick={() => setSelectedTab('problem')}
                          >
                            <svg
                              width={16}
                              height={16}
                              viewBox="0 0 16 16"
                              fill="#303031"
                              aria-label="Urgent Priority"
                              className='mr-[10px]'
                            >
                              <path d="M3 1C1.91067 1 1 1.91067 1 3V13C1 14.0893 1.91067 15 3 15H13C14.0893 15 15 14.0893 15 13V3C15 1.91067 14.0893 1 13 1H3ZM7 4L9 4L8.75391 8.99836H7.25L7 4ZM9 11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55228 10 9 10.4477 9 11Z" />
                            </svg>

                            Problem
                          </button>
                          <button
                            type="button"
                            className={`px-4 py-2 ${selectedTab === 'question' ? 'hover:bg-[#f7f7f7] inline-flex items-center justify-center whitespace-nowrap rounded-[5px] font-medium leading-normal help-tab-button min-w-[32px] h-[32px] px-[14px] text-[0.8125rem]' : 'hover:bg-[#f7f7f7] min-w-[32px] h-[32px] px-[14px] flex items-center text-[0.8125rem] text-[#303031]  rounded-[5px] bg-transparent font-medium'}`}
                            onClick={() => setSelectedTab('question')}
                          >
                            <svg className='mr-[10px]' width="16" height="16" viewBox="0 0 16 16" fill="#5e5e5f" role="img" focusable="false" aria-hidden="true" >
                              <path d="M8.43251 9.62059H7.14081V9.52145C7.14856 8.14871 7.54422 7.72545 8.2308 7.30601C8.70016 7.01621 9.0609 6.65014 9.0609 6.1163C9.0609 5.51764 8.58379 5.12869 7.9903 5.12869C7.44337 5.12869 6.91583 5.47188 6.88092 6.18875H5.5C5.53879 4.73975 6.64042 4 7.99806 4C9.47983 4 10.5 4.80839 10.5 6.09724C10.5 6.97045 10.0539 7.54242 9.34019 7.96187C8.70791 8.34318 8.44026 8.71306 8.43251 9.52145V9.62059Z"></path><path d="M8.673 11.1611C8.66912 11.6263 8.27735 12 7.81963 12C7.34639 12 6.96237 11.6263 6.96625 11.1611C6.96237 10.7035 7.34639 10.3298 7.81963 10.3298C8.27735 10.3298 8.66912 10.7035 8.673 11.1611Z"></path></svg>
                            Question
                          </button>
                          <button
                            type="button"
                            className={`px-4 py-2 ${selectedTab === 'feedback' ? 'hover:bg-[#f7f7f7] inline-flex items-center justify-center whitespace-nowrap rounded-[5px] font-medium leading-normal help-tab-button min-w-[32px] h-[32px] px-[14px] text-[0.8125rem]' : 'hover:bg-[#f7f7f7] min-w-[32px] h-[32px] px-[14px] flex items-center text-[0.8125rem] text-[#303031]  rounded-[5px] bg-transparent font-medium'}`}
                            onClick={() => setSelectedTab('feedback')}
                          >
                            <svg className='mr-[10px]' width="16" height="16" viewBox="0 0 16 16" fill="#5e5e5f" role="img" focusable="false" aria-hidden="true">
                              <path d="M11 5H5v.75h6V5ZM5 6.5h4.5v.75H5V6.5ZM8.75 8H5v.75h3.75V8Z"></path><path d="M3.2 2h9.6c.66 0 1.2.54 1.2 1.2v7.2c0 .66-.54 1.2-1.2 1.2H4.4L2 14V3.2C2 2.54 2.54 2 3.2 2Zm1.2 8.4h8.4V3.2H3.2v8.4l1.2-1.2Z"></path></svg>
                            Feedback
                          </button>
                        </div>
                        <textarea
                          className="mt-4 w-full rounded-none border-none shadow-none focus:border-none focus:ring-0 text-[15px] text-[#303031]"
                          rows={7}
                          placeholder={getPlaceholderText()}
                        ></textarea>
                        <div className="mt-2 flex items-center space-x-2 text-gray-500">
                          <input
                            type="file"
                            id="file-upload"
                            className="hidden"
                            multiple
                            onChange={handleFileUpload}
                          />
                          <label htmlFor="file-upload" className="flex items-center cursor-pointer">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="#5e5e5f" role="img" focusable="false" aria-hidden="true">
                              <path d="M12.6429 7.69048L8.92925 11.4041C7.48164 12.8517 5.34347 13.0101 4.16667 11.8333C2.98733 10.654 3.14447 8.52219 4.59216 7.07451L8.00206 3.66461C8.93557 2.73109 10.2976 2.63095 11.0333 3.36667C11.7681 4.10139 11.6658 5.4675 10.7361 6.39727L7.32363 9.8097C6.90202 10.2313 6.32171 10.2741 6.02381 9.97619C5.72651 9.6789 5.76949 9.09718 6.1989 8.66776L9.29048 5.57619C9.56662 5.30005 9.56662 4.85233 9.29048 4.57619C9.01433 4.30005 8.56662 4.30005 8.29048 4.57619L5.1989 7.66776C4.24737 8.6193 4.13865 10.091 5.02381 10.9762C5.9095 11.8619 7.37984 11.7535 8.32363 10.8097L11.7361 7.39727C13.1876 5.94573 13.3564 3.68975 12.0333 2.36667C10.7099 1.04326 8.45782 1.20884 7.00206 2.66461L3.59216 6.07451C1.62229 8.04437 1.39955 11.0662 3.16667 12.8333C4.93146 14.5981 7.9596 14.3737 9.92925 12.4041L13.6429 8.69048C13.919 8.41433 13.919 7.96662 13.6429 7.69048C13.3667 7.41433 12.919 7.41433 12.6429 7.69048Z"></path>
                            </svg>
                            <span className='text-[#5e5e5f] text-[13px]'>Attach images, files or videos.</span>
                          </label>

                        </div>
                      </div>
                    </div>
                  </div>
                  <span className='border-b border-solid border-[#e3e3e3] mx-[-32px] block py-[6px]'></span>
                  <div className="flex items-center justify-between gap-[22px] pt-4 flex-wrap md:flex-nowrap lg:flex-nowrap">

                    <span className="text-[#5e5e5f] text-[0.75rem] leading-normal font-normal">You can also email us at
                      <a href="mailto:support@linear.app">support@linear.app</a>. We can’t respond to every request but we read all of them.</span>

                    <button
                      type="button"
                      className=" inline-flex items-center justify-center whitespace-nowrap rounded-[5px] bg-indigo-600 font-medium leading-normal help-btn-setting text-[#fefeff] min-w-[32px] h-[32px] px-[14px] text-[0.8125rem]"
                      onClick={() => setOpen(false)}
                    >
                      Send message
                    </button>
                    {/* <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button> */}
                  </div>

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

    </>
  )
}
export default Sidebar
