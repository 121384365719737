// topic.types.ts

export const UPDATE_CUSTOMER_INFO_DATA_REQUEST = 'UPDATE_CUSTOMER_INFO_DATA_REQUEST';
export const UPDATE_CUSTOMER_INFO_DATA_SUCCESS = 'UPDATE_CUSTOMER_INFO_DATA_SUCCESS';
export const UPDATE_CUSTOMER_INFO_DATA_FAILURE = 'UPDATE_CUSTOMER_INFO_DATA_FAILURE';

export interface CustomerType {
    itemId: any;
    base_id: number;
    board_id?: number;
    item_type?: string;
    item_title?: string;
    item_text?: string;
    item_details: string;
    item_tag_id?: number;
    item_base_status_id?: number[];
    item_sentiment?: string;
    item_meta?: string;
    item_revenue?: string;
    item_user_id?: number;
    item_group_id?: number;
    item_priority?: string;
    item_source_type?: string;
    item_summary?: string;
    item_source_id?: string;
    ai_check?: string;
    ai_check_time?: string;
    ai_check_source?: string;
    ai_check_tokens?: string;
    insight_ticket_id?: number;
    item_user_name?: string;
    item_user_unique_identifier?: string;
    item_user_email_id?: string;
    item_user_phone_number?: string;
    item_user_meta?: string;
    item_user_segment?: string;
    item_user_group_name?: string;
    item_user_group_unique_identifier?: string;
    item_user_group_revenue?: string;
    item_user_group_segment?: string;
    item_user_group_meta?: string;
    id?: number;
    name?: string;
    color?: string;
    base_status_title?: string;
    tag_title?: string;
    item_customer_name?: string;
    item_customer_unique_id?: string;
    item_customer_email_id?: string;
    item_customer_phone_number?: string;
    item_customer_meta?: string;
    item_customer_label?: string;
    item_customer_type?: string;
    item_customer_image?: string;
    item_customer_revenue?: string;
    item_customer_parent_id?: number;
}

export interface CustomerState {
    loading: boolean;
    error: string;
    success: boolean;
    data: CustomerType[];
    insightGroups: any[];
    item: AllActivityItem;
}

export interface AllActivityItem {
    itemsActivities: any[];
    totalItemActivity: number;
    hasNextPage: boolean;
    pageSize: number;
    totalPages: number;
}

export type CustomerActionTypes =
    | { type: typeof UPDATE_CUSTOMER_INFO_DATA_REQUEST }
    | { type: typeof UPDATE_CUSTOMER_INFO_DATA_SUCCESS, payload: CustomerType }
    | { type: typeof UPDATE_CUSTOMER_INFO_DATA_FAILURE, payload: string }