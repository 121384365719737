import React from 'react'
import IntegrationSurvey from './IntegrationSurvey'

function IntegrationSteps({ integration }: any) {

    return (
        <div>
            {
                integration?.integration_type === 'survey' &&
                <IntegrationSurvey integration={integration} />
            }

        </div>
    )
}

export default IntegrationSteps