// @ts-nocheck

import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Bars3Icon, ChevronDownIcon, ChevronUpDownIcon, PlusCircleIcon, UserCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from '@lemonsqueezy/wedges';
import { Dialog, Label, Transition } from '@headlessui/react';
import { fetchBoardList } from '../../setting/boardApi/BoardActions';
import { useDispatch } from 'react-redux';
// import { ChevronUpDownIcon } from 'lucide-react';
import { MemberData } from '../../../reducer/type';
import { SubmitFeedbackAttachment, submitFeedback } from '../../feedbacks/feedback-redux/feedbacks.actions';
import { Feedback } from '../../feedbacks/type';
import { Toaster, toast } from 'sonner';
import { FeedbackType } from '../../feedbacks/feedback-redux/feedbacks.types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { useNavigate } from 'react-router-dom';
import { ChartBarIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import { _submitFeedbackAttachment } from '../../feedbacks/feedback-redux/feedbacks.api';
import RichTextEditor from '../../../components/RichTextEditor';
import ReactMarkdown from 'react-markdown';  // Import react-markdown


interface Board {
  id: number;
  name: string;
  color: string;
}

interface Status {
  id: number;
  name: string;
  color: string;
  base_status_title: string
}

interface Tags {
  id: number;
  name: string;
  color: string;
  tag_title: string
}

interface Member {
  id: number;
  name: string;
  color: string;
  display_name: string;
}

interface Priority {
  id: number;
  name: string;
}

interface Sentiment {
  id: number;
  name: string;
}

interface HeaderProps {
  onClick: () => void;
  id: number | null;
  selectedTab: string;
  itemStatus: any[];
  itemTags: any[];
  baseMemberDetails: any[];
}


function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const SendDetails: React.FC<HeaderProps> = ({ onClick, id, selectedTab, itemStatus, itemTags, baseMemberDetails }) => {

  const [selectedBoard, setSelectedBoard] = useState<Board | null>(null);
  const [boardsData, setBoardsData] = useState<Board[]>([]);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const dropdownRef = useRef<HTMLDivElement>(null);
  // item status
  const [isStatusOpen, setStatusIsOpen] = useState(false);
  const [searchStatusQuery, setSearchStatusQuery] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<FeedbackType | null>(null);
  const [statusRemove, setStatusRemove] = useState<any[]>([]);
  const statusRef = useRef<HTMLDivElement>(null);
  const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;
  // item tags
  const [isTagsOpen, setTagsIsOpen] = useState<boolean>(false);
  const [searchTagsQuery, setSearchTagsQuery] = useState<string>('');
  const [selectedTags, setSelectedTags] = useState<Tags[]>([]);
  const [tagsRemove, setTagsRemove] = useState<any[]>([]);
  const tagsRef = useRef<HTMLDivElement>(null);

  // assign to base member
  const [isMemberOpen, setMemberIsOpen] = useState(false);
  const [searchMemberQuery, setSearchMemberQuery] = useState('');
  const [selectedMember, setSelectedMember] = useState<Member | null>(null);
  const [MemberRemove, setMemberRemove] = useState<any[]>([]);
  const membersRef = useRef<HTMLDivElement>(null);

  // priority
  const [isPriorityOpen, setPriorityIsOpen] = useState(false);
  const [searchPriorityQuery, setSearchPriorityQuery] = useState('');
  const [selectedPriority, setSelectedPriority] = useState<Priority | null>(null);
  const [PriorityRemove, setPriorityRemove] = useState<any[]>([]);
  const prioritysRef = useRef<HTMLDivElement>(null);
  const [openFeedbackTab, setOpenFeedbackTab] = useState('Feedback')
  const feedbackTabs = [
    { name: 'Feedback', href: '#' },
    { name: 'Ticket', href: '#' }
  ]
  const [itemAttachment, setItemAttachment] = useState([])
  const [files, setFiles] = useState<File[]>([]);



  const dispatch = useDispatch<any>();

  useEffect(() => {
    const baseIdString = localStorage.getItem('baseId');
    if (baseIdString) {
      const baseId = Number(baseIdString);
      if (!isNaN(baseId)) {
        const fetchData = async () => {
          const storedBoardData = localStorage.getItem('channelData');
          if (storedBoardData) {
            const parsedData = JSON.parse(storedBoardData);
            if (Array.isArray(parsedData)) {
              setBoardsData(parsedData);
            } else if (parsedData && parsedData.data) {
              setBoardsData(parsedData.data);
            }
          } else {
            // try {
            //     const response = await dispatch(fetchBoardList(baseId));
            //     const boards = response.payload; 
            //     setBoardsData(boards);
            //     localStorage.setItem('channelData', JSON.stringify({ data: boards }));
            // } catch (error) {
            //     console.error('Failed to fetch boards:', error);
            // }
          }
        };
        fetchData();
      }
    }
  }, [dispatch]);


  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsFeedbackOpen(false);
      }
      if (statusRef.current && !statusRef.current.contains(event.target as Node)) {
        setStatusIsOpen(false);
      }
      if (tagsRef.current && !tagsRef.current.contains(event.target as Node)) {
        setTagsIsOpen(false);
      }
      if (membersRef.current && !membersRef.current.contains(event.target as Node)) {
        setMemberIsOpen(false);
      }
      if (prioritysRef.current && !prioritysRef.current.contains(event.target as Node)) {
        setPriorityIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filteredBoards = boardsData?.filter(board =>
    board.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // status
  const filteredStatus = itemStatus?.data?.filter(status =>
    status.base_status_title.toLowerCase().includes(searchStatusQuery.toLowerCase())
  );

  const handleRemoveStatus = (id: any) => {
    setStatusRemove((prevFilters: any) => prevFilters.filter((filter: any) => filter.id !== id));
  };

  const handleFeedbacksModal = () => {
    setFeedbackOpen(true);
  };

  // tags

  const filteredTags = itemTags?.data?.filter(tags =>
    tags.tag_title.toLowerCase().includes(searchTagsQuery.toLowerCase())
  );

  const handleTagSelection = (tag: Tags) => {
    setSelectedTags(prevTags => {
      const tagIndex = prevTags.findIndex(t => t.id === tag.id);
      setTagsIsOpen(false)
      setSearchTagsQuery('')
      if (tagIndex > -1) {
        return prevTags.filter(t => t.id !== tag.id);
      } else {
        return [...prevTags, tag];
      }
    });
  };

  const handleRemoveTag = (id: number) => {
    setSelectedTags(prevTags => prevTags.filter(tag => tag.id !== id));
  };


  const filteredMembers = baseMemberDetails.filter(member =>
    member.name.toLowerCase().includes(searchMemberQuery.toLowerCase())
  );

  const handleRemoveMember = (id: any) => {
    setMemberRemove((prevFilters: any) => prevFilters.filter((filter: any) => filter.id !== id));
  };


  const getMemberInitials = (members: MemberData[]): string[] => {
    return members.map(member =>
      member.display_name ? member.display_name.charAt(0).toUpperCase() : ''
    ).filter(initial => initial !== '');
  };

  const memberInitials = getMemberInitials(baseMemberDetails);

  // priority

  const priority: Priority[] = [
    { id: 1, name: 'Urgent' },
    { id: 2, name: 'High' },
    { id: 3, name: 'Medium' },
    { id: 4, name: 'Low' },
  ];

  localStorage.setItem('priorities', JSON.stringify(priority));

  // sentiment

  const sentiment: Sentiment[] = [
    { id: 101, name: 'positive', label: '#22c55e' },
    { id: 102, name: 'negative', label: '#dc26261a' },
    { id: 103, name: 'spam', label: '#6b72801a' },
    { id: 104, name: 'mixed', label: '#1d4ed81a' },
  ];

  localStorage.setItem('sentiments', JSON.stringify(sentiment));



  const filteredPriority = priority.filter(priority =>
    priority.name.toLowerCase().includes(searchPriorityQuery.toLowerCase())
  );

  const handleRemovePriority = (id: any) => {
    setPriorityRemove((prevFilters: any) => prevFilters.filter((filter: any) => filter.id !== id));
  };

  // post submit feedback
  const { error, success } = useSelector((state: RootState) => state.feedbacks);
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState<Partial<FeedbackType>>({
    item_title: '',
    item_details: '',
    item_base_status_id: '',
    item_tag_id: '',
    item_assigned_to: '',
    item_priority: '',
    board_id: '',
    item_type: ''
  });




  const handleFeedbackSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const base_id = localStorage.getItem('baseId');

    if (formData.item_details || selectedPriority) {

      const feedbackData: any = {
        base_id: Number(base_id),
        item_type: openFeedbackTab === 'Feedback' ? 'feedback' : 'ticket',
      };

      if (formData.item_title) feedbackData.item_title = formData.item_title;
      if (formData.item_details) feedbackData.item_details = formData.item_details;
      if (selectedStatus) feedbackData.item_base_status_id = selectedStatus.id;
      if (selectedTags.length > 0) feedbackData.item_tag_id = selectedTags.map(tag => tag.id).join(',');
      if (selectedMember) feedbackData.item_assigned_to = selectedMember.id;
      if (selectedPriority) feedbackData.item_priority = selectedPriority.name;
      if (selectedBoard) feedbackData.board_id = selectedBoard.id;
      setLoading(true);

      if (itemAttachment) {
        feedbackData.item_attachment = itemAttachment
      }

      dispatch(submitFeedback(feedbackData as FeedbackType))
        .then((response: any) => {
          setFormData({
            item_title: '',
            item_details: '',
            item_base_status_id: '',
            item_tag_id: '',
            item_assigned_to: '',
            item_priority: '',
            board_id: '',
            item_type: '',
            item_attachment: ''
          });
          // setSelectedStatus(null); 
          setSelectedPriority(null);
          toast.success('Successfully created feedback');
          if (response.status === 200) {
            setLoading(false);
            setFeedbackOpen(false);
            navigate(`/feedback/${response.data.id}`);
          }

        })
        .catch((error: any) => {
          setLoading(false);
          console.error('Error submitting feedback:', error);
          toast.error('Failed create feedback');
        });
    } else {
      toast.error('Item Details are mandatory.');
      setFeedbackOpen(true)
    }
  };

  useEffect(() => {
    if (selectedStatus && selectedTags && selectedMember && selectedPriority) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        item_base_status_id: selectedStatus.id,
        item_tag_id: selectedTags.map(tag => tag.id),
        item_assigned_to: selectedMember ? selectedMember.id : '',
        item_priority: selectedPriority ? selectedPriority.name : '',
        board_id: selectedBoard ? selectedBoard.id : ''

      }));
    }
  }, [selectedStatus, selectedTags, selectedMember, selectedPriority]);



  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const textareaRef = useRef(null);
  const textareaRef1 = useRef(null);
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '0px';
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = `${scrollHeight}px`;
    }
  }, [formData.item_title]);

  useEffect(() => {
    if (textareaRef1.current) {
      textareaRef1.current.style.height = '0px';
      const scrollHeight = textareaRef1.current.scrollHeight;
      textareaRef1.current.style.height = `${scrollHeight}px`;
    }
  }, [formData.item_details]);


  const variants = {
    hidden: { opacity: 1, y: 0 },
    visible: { opacity: 1, y: 0 },
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (!files || files.length === 0) {
      console.error("No files selected");
      return;
    }

    if (files) {
      setFiles(Array.from(files));
    }

    const file = files[0];
    try {
      const response = await _submitFeedbackAttachment(baseId, file);
      setItemAttachment(response.data);
    } catch (error) {
      if (error.response) {
        console.error("Error response data:", error.response.data);
      }
    }
  };

  const handleRemoveFile = (fileName: string) => {
    setFiles(files.filter(file => file.name !== fileName));
  };

  return (

    <>
      <Toaster richColors />
      
      <div className='flex flex-col px-[0.75rem] mt-[1rem]'>

        <Button
          type="submit"
          className="bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600 justify-start [&>span]:flex [&>span]:items-center [&>span]:gap-[6px] text-[13px] font-medium"
          size="sm"
          onClick={handleFeedbacksModal}
        >
          <PlusCircleIcon className='w-[14px] h-[14px]' />
          Add Feedback
        </Button>
      </div>

      <Transition show={feedbackOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 md:z-10 lg:z-10" onClose={() => setFeedbackOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center sm:items-start h-full items-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg bg-white px-4 py-4 text-left shadow-xl transition-all sm:mb-8 sm:mt-[32px]  sm:w-full sm:max-w-[680px]">
                  <form onSubmit={handleFeedbackSubmit}>
                    <div className="absolute right-0 top-0 hidden pr-4 pt-6 sm:block">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                        onClick={() => setFeedbackOpen(false)}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      <div className="w-full mt-3 text-center sm:mt-0 sm:text-left">
                        {/* <div className="flex items-center gap-[6px]">
                          <nav className='relative flex w-[95%] h-full bg-[#f4f5f7] rounded-md p-[6px]' aria-label='Tabs'>
                            {feedbackTabs.map((tab) => (
                              <motion.a
                                key={tab.name}
                                onClick={() => setOpenFeedbackTab(tab.name)}
                                href={tab.href}
                                initial="hidden"
                                animate="visible"
                                variants={variants}
                                transition={{ duration: 0.3 }}
                                className={classNames(
                                  tab.name === openFeedbackTab
                                    ? 'border-none bg-[#4a5562] rounded-md text-[#fff]'
                                    : 'py-1 w-1/2 cursor-pointer select-none focus:outline-none',
                                  'py-1 w-1/2 cursor-pointer select-none group inline-flex items-center text-sm font-medium rounded-md justify-center'
                                )}
                              >
                                <span>{tab.name}</span>
                              </motion.a>
                            ))}
                          </nav>
                        </div> */}
                        {openFeedbackTab === 'Feedback' && (
                          <motion.div
                            className="mt-4"
                            initial={{ opacity: 1, y: 0 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8 }}
                          >
                            <div className='max-h-[650px] h-full overflow-y-auto'>
                              <textarea
                                type="text"
                                name="item_title"
                                placeholder="Issue title"
                                value={formData.item_title}
                                onChange={handleChange}
                                maxLength={300}
                                ref={textareaRef}
                                className="p-0 w-full rounded-none border-none shadow-none focus:border-none focus:ring-0 text-[18px] text-[#303031] font-medium placeholder:text-[#a0a0a2] h-[24px]"
                              />
                              <textarea
                                placeholder="Add description..."
                                name="item_details"
                                value={formData.item_details}
                                // required
                                type="text"
                                maxLength={10000}
                                ref={textareaRef1}
                                onChange={handleChange}
                                className=" w-full rounded-none border-none shadow-none focus:border-none focus:ring-0 text-[15px] text-[#303031] h-[36px] pl-0"
                              ></textarea>
                       
                            </div>
                            <div className="mt-2 flex flex-wrap gap-x-[6px] gap-y-[10px] items-center">
                              {/* for board status */}
                              <span className=''>
                                {boardsData?.length > 0 && (
                                  <div className="relative inline-block text-left" ref={dropdownRef}>
                                    <div>
                                      <button
                                        type="button"
                                        className="inline-flex justify-center items-center w-full rounded-md px-2
                 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-0
                  focus:ring-offset-2 new-feedback-modal-select h-[30px]"
                                        onClick={() => setIsFeedbackOpen(!isFeedbackOpen)}
                                      >
                                        <span className="flex items-center leading-[1.2]  md:text-[0.8125rem] 
                        bg-white text-[12px] font-medium text-[#5e5e5f] gap-1
                     
                        ">
                                          {selectedBoard ? (
                                            <>
                                              <span className='flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-[2px] border-none text-[0.625rem] font-medium text-white capitalize'
                                                style={{ backgroundColor: selectedBoard.color }}
                                              ></span>
                                              <span className="">{selectedBoard.name}</span>
                                            </>
                                          ) : (
                                            <>
                                              <span className='flex h-[8px] w-[8px] shrink-0 items-center justify-center
                                               rounded-[2px] text-[0.625rem] font-medium text-white border-2 border-solid border-[#7d7f83]'></span>

                                              board
                                            </>
                                          )}
                                        </span>
                                        <ChevronDownIcon className="-mr-1 ml-2 h-3 w-3" aria-hidden="true" />
                                      </button>
                                    </div>

                                    {isFeedbackOpen && (
                                      <div className="absolute right-0 z-10 mt-2 left-0 mq575:w-[160px] mq3000:w-[180px] origin-top-right rounded-md bg-white filter-dropdown border border-solid border-[#00000014]"
                                        style={{ boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)' }}
                                      >
                                        <div className="py-1">
                                          <input
                                            type="text"
                                            placeholder="boards..."
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                            className="w-full outline-none border-t-0 border-l-0 border-r-0 border-b border-solid border-[#00000029] 
                  rounded-none text-sm h-[36px] pl-[10px] pr-[30px focus:outline-none focus:shadow-none focus:ring-0 focus:border-[#00000029] font-normal text-[#9f9f9fff]"
                                            onClick={(e) => e.stopPropagation()}
                                          />
                                          <span className="right-0 w-[2.25rem] h-[2.25rem] text-sm flex items-center justify-center absolute text-[#000000eb]  opacity-60 top-[2px]" style={{ zIndex: 2 }}>F</span>
                                          <div className=" overflow-auto max-h-[200px]">
                                            {filteredBoards.map((board) => (

                                              <li
                                                key={board.id}
                                                onClick={() => {
                                                  setSelectedBoard(board);
                                                  setIsFeedbackOpen(false);
                                                  setSearchQuery('');
                                                }}
                                                className="text-dark-black-100 flex items-center gap-[0.5rem] px-[0.75rem] 
                      py-[0.375rem] h-[32px] text-[13px] font-normal cursor-pointer w-full hover:bg-[#f1f1f2] focus:bg-[#f1f1f2] active:bg-[#e7e7e8]"

                                              >
                                                <span className='flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-[2px] border-none text-[0.625rem] font-medium text-white capitalize'
                                                  style={{ backgroundColor: board.color }}
                                                ></span>
                                                {board.name}
                                              </li>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </span>

                              {/* for item status  */}
                              <span className="">
                                {itemStatus?.data?.length > 0 && (
                                  <div className="relative inline-block text-left" ref={statusRef}>
                                    <div>
                                      <button
                                        type="button"
                                        className="inline-flex justify-center items-center w-full rounded-[5px]
                 bg-white text-[12px] font-medium text-[#5e5e5f] hover:bg-gray-50 focus:outline-none focus:ring-0
                  focus:ring-offset-2 new-feedback-modal-select h-[30px] px-[8px]"
                                        onClick={() => setStatusIsOpen(!isStatusOpen)}
                                      >
                                        {selectedStatus ? (
                                          <>    <span className="border border-[#e5e7eb] border-solid text-[#6b7280] text-[0.75rem] gap-[0.675rem] leading-4 pr-1 pl-[0.625rem] 
                        py-1 bg-white rounded-md items-center inline-flex mx-[-10px]">
                                            <span className="text-[#1d2025] w-2 h-2 rounded-full bg-[#7d7f83] block"></span>
                                            Status
                                            <span className="bg-[#e5e7eb] w-[1px] h-[1rem] mx-1"></span>

                                            <span className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full text-[0.625rem] font-medium
                                 text-white ${selectedStatus.color}`}
                                            ></span>
                                            <span className="text-[#111827] font-medium">{selectedStatus.base_status_title}</span>
                                            <button
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleRemoveStatus(id);
                                                setSelectedStatus(null);
                                                // setStatusIsOpen(true);
                                              }}
                                              className="text-[#6b7280] rounded justify-center items-center w-[1.25rem] h-[1.25rem] flex ml-[-.375rem] cursor-pointer"
                                            >
                                              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" aria-hidden="true">
                                                <path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path>
                                              </svg>
                                            </button>
                                          </span>
                                          </>
                                        ) : (
                                          <div className='flex items-center gap-1'>
                                            <span className="text-[#1d2025] w-2 h-2 rounded-full bg-[#7d7f83] block"></span>
                                            Status
                                          </div>
                                        )}
                                      </button>
                                    </div>

                                    {isStatusOpen && (
                                      <div className="absolute right-0 z-10 mt-2 left-0 mq575:w-[160px] mq3000:w-[180px] origin-top-right rounded-md bg-white filter-dropdown border border-solid border-[#00000014]"
                                        style={{ boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)' }}
                                      >
                                        <div className="py-1">
                                          <input
                                            type="text"
                                            placeholder="status..."
                                            value={searchStatusQuery}
                                            onChange={(e) => setSearchStatusQuery(e.target.value)}
                                            className="w-full outline-none border-t-0 border-l-0 border-r-0 border-b border-solid border-[#00000029] 
                  rounded-none text-sm h-[36px] pl-[10px] pr-[30px focus:outline-none focus:shadow-none focus:ring-0 focus:border-[#00000029] font-normal text-[#9f9f9fff]"
                                            onClick={(e) => e.stopPropagation()}
                                          />
                                          <span className="right-0 w-[2.25rem] h-[2.25rem] text-sm flex items-center justify-center absolute text-[#000000eb]  opacity-60 top-[2px]" style={{ zIndex: 2 }}>F</span>
                                          <div className=" overflow-auto max-h-[200px]">
                                            {filteredStatus.map((status) => (
                                              <li
                                                key={status.id}
                                                onClick={() => {
                                                  setSelectedStatus(status);
                                                  setStatusIsOpen(false);
                                                  setSearchStatusQuery('');
                                                }}
                                                className="text-dark-black-100 flex items-center gap-[0.5rem] px-[0.75rem] 
                      py-[0.375rem] h-[32px] text-[13px] font-normal cursor-pointer w-full hover:bg-[#f1f1f2] focus:bg-[#f1f1f2] active:bg-[#e7e7e8]"

                                              >
                                                <span className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full text-[0.625rem] font-medium
                                 text-white ${status.color}`}
                                                ></span>
                                                <span className='truncate text-[13px] leading-4 whitespace-nowrap w-[140px] overflow-hidden text-ellipsis text-start'>{status.base_status_title}</span>
                                              </li>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </span>



                              {/* for item Tags  */}
                              <span className="">
                                {itemTags?.data?.length > 0 && (
                                  <div className="relative inline-block text-left" ref={tagsRef}>
                                    <div>
                                      <button
                                        type="button"
                                        className="inline-flex justify-center items-center w-full rounded-[5px] bg-white text-[12px] font-medium text-[#5e5e5f] hover:bg-gray-50 focus:outline-none focus:ring-0 focus:ring-offset-2 new-feedback-modal-select h-[30px] px-[8px]"
                                        onClick={() => setTagsIsOpen(!isTagsOpen)}
                                      >
                                        {selectedTags.length === 1 ? (
                                          <>
                                            <span key={selectedTags[0].id} className="border border-[#e5e7eb] border-solid text-[#6b7280]
                            text-[0.75rem] gap-[0.675rem] leading-4 pr-1 pl-[0.625rem] py-1 bg-white rounded-md items-center inline-flex mx-[-10px]">
                                              {/* <span className="text-[#1d2025] w-2 h-2 rounded-full bg-transparent border-2 border-solid border-[#7d7f83] block"></span> */}
                                              <svg className='mt-[1px]' stroke="#1e1e1e" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" focusable="false" aria-hidden="true"
                                                height="12px" width="12px" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path><line x1="7" y1="7" x2="7.01" y2="7"></line></svg>
                                              Tags
                                              <span className="bg-[#e5e7eb] w-[1px] h-[1rem] mx-1"></span>
                                              <span className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full text-[0.625rem] font-medium text-white ${selectedTags[0].color}`}></span>
                                              <span className="text-[#111827] font-medium">{selectedTags[0].tag_title}</span>
                                              <button
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  handleRemoveTag(selectedTags[0].id)
                                                  setSelectedStatus(null);
                                                  // setStatusIsOpen(true);
                                                }}
                                                className="text-[#6b7280] rounded justify-center items-center w-[1.25rem] h-[1.25rem] flex ml-[-.375rem] cursor-pointer"
                                              >
                                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" aria-hidden="true">
                                                  <path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path>
                                                </svg>
                                              </button>
                                            </span>
                                          </>
                                        ) : selectedTags.length > 1 ? (
                                          <span className="border border-[#e5e7eb] border-solid text-[#6b7280] text-[0.75rem] gap-[6px] leading-4 px-1 
                          py-1 bg-white rounded-md items-center inline-flex mx-[-10px] h-[30px]">
                                            <svg className='mt-[1px]' stroke="#1e1e1e" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" focusable="false" aria-hidden="true"
                                              height="12px" width="12px" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path><line x1="7" y1="7" x2="7.01" y2="7"></line></svg>
                                            Tags
                                            <span className="bg-[#e5e7eb] w-[1px] h-[1rem] mx-1"></span>

                                            {selectedTags.length}
                                            <span className="text-[#111827] font-medium">Selected</span>
                                          </span>
                                        ) : (
                                          <div className='flex items-center gap-1'>
                                            <svg className='mt-[1px]' stroke="#1e1e1e" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" focusable="false" aria-hidden="true"
                                              height="12px" width="12px" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path><line x1="7" y1="7" x2="7.01" y2="7"></line></svg>
                                            Tags
                                          </div>
                                        )}
                                      </button>
                                    </div>


                                    {isTagsOpen && (
                                      <div className="absolute right-0 z-10 mt-2 left-0 mq575:w-[160px] mq3000:w-[180px] origin-top-right rounded-md bg-white filter-dropdown border border-solid border-[#00000014]"
                                        style={{ boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)' }}
                                      >
                                        <div className="py-1">
                                          <input
                                            type="text"
                                            placeholder="tags..."
                                            value={searchTagsQuery}
                                            onChange={(e) => setSearchTagsQuery(e.target.value)}
                                            className="w-full outline-none border-t-0 border-l-0 border-r-0 border-b border-solid border-[#00000029] rounded-none text-sm h-[36px] pl-[10px] pr-[30px] focus:outline-none focus:shadow-none focus:ring-0 focus:border-[#00000029] font-normal text-[#9f9f9fff]"
                                            onClick={(e) => e.stopPropagation()}
                                          />
                                          <div className="overflow-auto max-h-[200px]">
                                            {filteredTags.map((tag) => (
                                              <li
                                                key={tag.id}
                                                onClick={() => handleTagSelection(tag)}
                                                className={`text-dark-black-100 flex items-center gap-[0.5rem] px-[0.75rem] py-[0.375rem] h-[32px] text-[13px] font-normal cursor-pointer w-full hover:bg-[#f1f1f2] focus:bg-[#f1f1f2] active:bg-[#e7e7e8] ${selectedTags.some(t => t.id === tag.id) ? 'bg-[#e7e7e8]' : ''
                                                  }`}
                                              >
                                                <span className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full text-[0.625rem] font-medium text-white ${tag.color}`}></span>
                                                <span className='truncate text-[13px] leading-4 whitespace-nowrap w-[140px] overflow-hidden text-ellipsis text-start'>{tag.tag_title}</span>


                                              </li>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </span>

                              {/* for base member  */}
                              <span className="">
                                {baseMemberDetails.length > 0 && (
                                  <div className="relative inline-block text-left" ref={membersRef}>
                                    <div>
                                      <button
                                        type="button"
                                        className="inline-flex justify-center items-center w-full rounded-[5px]
                 bg-white text-[12px] font-medium text-[#5e5e5f] hover:bg-gray-50 focus:outline-none focus:ring-0
                  focus:ring-offset-2 new-feedback-modal-select h-[30px] px-[8px]"
                                        onClick={() => setMemberIsOpen(!isMemberOpen)}
                                      >
                                        {selectedMember ? (
                                          <>    <span className="border border-[#e5e7eb] border-solid text-[#6b7280] text-[0.75rem] gap-[0.675rem] leading-4 pr-1 pl-[0.625rem] 
                        py-1 bg-white rounded-md items-center inline-flex mx-[-10px]">
                                            {/* <span className="text-[#1d2025] w-2 h-2 rounded-full bg-transparent border-2 border-solid border-[#7d7f83] block"></span> */}
                                            <UserCircleIcon className='w-[14px] h-[14px] text-[#1e1e1e]' />
                                            Assign to
                                            <span className="bg-[#e5e7eb] w-[1px] h-[1rem] mx-1"></span>

                                            {/* <span className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full text-[0.625rem] font-medium
                                 text-white ${selectedMember.color}`}
                            ></span> */}
                                            <div className="w-5 h-5 rounded-full bg-gray-200 flex items-center justify-center text-[10px]">
                                              <span className="text-[7px] font-medium text-gray-700"></span>{memberInitials}</div>
                                            <span className="text-[#111827] font-medium">{selectedMember.name}</span>
                                            <button
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleRemoveMember(id);
                                                setSelectedMember(null);
                                                // setTagsIsOpen(true);
                                              }}
                                              className="text-[#6b7280] rounded justify-center items-center w-[1.25rem] h-[1.25rem] flex ml-[-.375rem] cursor-pointer"
                                            >
                                              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" aria-hidden="true">
                                                <path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path>
                                              </svg>
                                            </button>
                                          </span>
                                          </>
                                        ) : (
                                          <div className='flex items-center gap-1'>
                                            <UserCircleIcon className='w-[14px] h-[14px] text-[#1e1e1e]' />
                                            Assign to
                                          </div>
                                        )}
                                      </button>
                                    </div>

                                    {isMemberOpen && (
                                      <div className="absolute right-0 z-10 mt-2 left-0 mq575:w-[160px] mq3000:w-[180px] origin-top-right rounded-md bg-white filter-dropdown border border-solid border-[#00000014]"
                                        style={{ boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)' }}
                                      >
                                        <div className="py-1">
                                          <input
                                            type="text"
                                            placeholder="Member..."
                                            value={searchMemberQuery}
                                            onChange={(e) => setSearchMemberQuery(e.target.value)}
                                            className="w-full outline-none border-t-0 border-l-0 border-r-0 border-b border-solid border-[#00000029] 
                  rounded-none text-sm h-[36px] pl-[10px] pr-[30px focus:outline-none focus:shadow-none focus:ring-0 focus:border-[#00000029] font-normal text-[#9f9f9fff]"
                                            onClick={(e) => e.stopPropagation()}
                                          />
                                          <span className="right-0 w-[2.25rem] h-[2.25rem] text-sm flex items-center justify-center absolute text-[#000000eb]  opacity-60 top-[2px]" style={{ zIndex: 2 }}>F</span>
                                          <div className=" overflow-auto max-h-[200px]">
                                            {filteredMembers.map((member) => (
                                              <li
                                                key={member.id}
                                                onClick={() => {
                                                  setSelectedMember(member);
                                                  setMemberIsOpen(false);
                                                  setSearchMemberQuery('');
                                                }}
                                                className="text-dark-black-100 flex items-center gap-[0.5rem] px-[0.75rem] 
                      py-[0.375rem] h-[32px] text-[13px] font-normal cursor-pointer w-full hover:bg-[#f1f1f2] focus:bg-[#f1f1f2] active:bg-[#e7e7e8]"

                                              >
                                                {/* <span className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full text-[0.625rem] font-medium
                                 text-white ${tags.color}`}
                                ></span> */}
                                                <span className='truncate text-[13px] leading-4 whitespace-nowrap w-[140px] overflow-hidden text-ellipsis text-start'>  {member.name}</span>

                                              </li>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </span>
                              {/* for priority  */}
                              <span className="">
                                <div className="relative inline-block text-left" ref={prioritysRef}>
                                  <div>
                                    <button
                                      type="button"
                                      className="inline-flex justify-center items-center w-full rounded-[5px]
                 bg-white text-[12px] font-medium text-[#5e5e5f] hover:bg-gray-50 focus:outline-none focus:ring-0
                  focus:ring-offset-2 new-feedback-modal-select h-[30px] px-[8px]"
                                      onClick={() => setPriorityIsOpen(!isPriorityOpen)}
                                    >
                                      {selectedPriority ? (
                                        <>    <span className="border border-[#e5e7eb] border-solid text-[#6b7280] text-[0.75rem] gap-[0.675rem] leading-4 pr-1 pl-[0.625rem] 
                        py-1 bg-white rounded-md items-center inline-flex mx-[-10px]">
                                          <ChartBarIcon className='w-[14px] h-[14px] text-[#1e1e1e]' />
                                          Priority
                                          <span className="bg-[#e5e7eb] w-[1px] h-[1rem] mx-1"></span>
                                          <span className="text-[#111827] font-medium">{selectedPriority.name}</span>
                                          <button
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handleRemovePriority(id);
                                              setSelectedPriority(null);
                                              // setTagsIsOpen(true);
                                            }}
                                            className="text-[#6b7280] rounded justify-center items-center w-[1.25rem] h-[1.25rem] flex ml-[-.375rem] cursor-pointer"
                                          >
                                            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" aria-hidden="true">
                                              <path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path>
                                            </svg>
                                          </button>
                                        </span>
                                        </>
                                      ) : (
                                        <div className='flex items-center gap-1'>
                                          <ChartBarIcon className='w-[14px] h-[14px] text-[#1e1e1e]' />
                                          Priority
                                        </div>
                                      )}
                                    </button>
                                  </div>

                                  {isPriorityOpen && (
                                    <div className="absolute right-0 z-10 mt-2 left-0 mq575:w-[160px] mq3000:w-[180px] origin-top-right rounded-md bg-white filter-dropdown border border-solid border-[#00000014]"
                                      style={{ boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)' }}
                                    >
                                      <div className="py-1">
                                        <input
                                          type="text"
                                          placeholder="Priority..."
                                          value={searchPriorityQuery}
                                          onChange={(e) => setSearchPriorityQuery(e.target.value)}
                                          className="w-full outline-none border-t-0 border-l-0 border-r-0 border-b border-solid border-[#00000029] 
                  rounded-none text-sm h-[36px] pl-[10px] pr-[30px focus:outline-none focus:shadow-none focus:ring-0 focus:border-[#00000029] font-normal text-[#9f9f9fff]"
                                          onClick={(e) => e.stopPropagation()}
                                        />
                                        <span className="right-0 w-[2.25rem] h-[2.25rem] text-sm flex items-center justify-center absolute text-[#000000eb]  opacity-60 top-[2px]" style={{ zIndex: 2 }}>F</span>
                                        <div className=" overflow-auto max-h-[200px]">
                                          {filteredPriority.map((priority) => (
                                            <li
                                              key={priority.id}
                                              onClick={() => {
                                                setSelectedPriority(priority);
                                                setPriorityIsOpen(false);
                                                setSearchPriorityQuery('');
                                              }}
                                              className="text-dark-black-100 flex items-center gap-[0.5rem] px-[0.75rem] 
                      py-[0.375rem] h-[32px] text-[13px] font-normal cursor-pointer w-full hover:bg-[#f1f1f2] focus:bg-[#f1f1f2] active:bg-[#e7e7e8]"

                                            >
                                              {/* <span className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full text-[0.625rem] font-medium
                                 text-white ${tags.color}`}
                                ></span> */}
                                              <span className='truncate text-[13px] leading-4 whitespace-nowrap w-[140px] overflow-hidden text-ellipsis text-start'>{priority.name}</span>


                                            </li>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                {/* )} */}
                              </span>
                            </div>
                          </motion.div>
                        )}
                        {openFeedbackTab === 'Ticket' && (
                          <motion.div
                            className="mt-4"
                            initial={{ opacity: 1, y: 0 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8 }}
                          >
                            <div className='max-h-[650px] h-full overflow-y-auto'>
                              <textarea
                                type="text"
                                name="item_title"
                                placeholder="Issue title"
                                value={formData.item_title}
                                onChange={handleChange}
                                maxLength={300}
                                ref={textareaRef}
                                className="p-0 w-full rounded-none border-none shadow-none focus:border-none focus:ring-0 text-[18px] text-[#303031] font-medium placeholder:text-[#a0a0a2] h-[24px]"
                              />
                              <textarea
                                placeholder="Add description..."
                                name="item_details"
                                value={formData.item_details}
                                // required
                                type="text"
                                maxLength={10000}
                                ref={textareaRef1}
                                onChange={handleChange}
                                className=" w-full rounded-none border-none shadow-none focus:border-none focus:ring-0 text-[15px] text-[#303031] h-[36px] pl-0"
                              ></textarea>
                               <div className="markdown-preview mt-4">
                      <h3 className="text-lg font-medium mb-2">Preview:</h3>
                      <ReactMarkdown>{formData.item_details}</ReactMarkdown>
                    </div>
                            </div>
                            <div className="mt-2 flex flex-wrap gap-x-[6px] gap-y-[10px] items-center">
                              {/* for board status */}
                              <span className=''>
                                {boardsData.length > 0 && (
                                  <div className="relative inline-block text-left" ref={dropdownRef}>
                                    <div>
                                      <button
                                        type="button"
                                        className="inline-flex justify-center items-center w-full rounded-md px-2
           bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-0
            focus:ring-offset-2 new-feedback-modal-select h-[30px]"
                                        onClick={() => setIsFeedbackOpen(!isFeedbackOpen)}
                                      >
                                        <span className="flex items-center leading-[1.2]  md:text-[0.8125rem] 
                  bg-white text-[12px] font-medium text-[#5e5e5f] gap-1
               
                  ">
                                          {selectedBoard ? (
                                            <>
                                              <span className='flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-white capitalize'
                                                style={{ backgroundColor: selectedBoard.color }}
                                              ></span>
                                              <span className="">{selectedBoard.name}</span>
                                            </>
                                          ) : (
                                            <>
                                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                strokeWidth={1.5} stroke="currentColor" className="w-3 h-3">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.666 3.888A2.25 2.25 0 0 0 13.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 0 1-.75.75H9a.75.75 0 0 1-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 0 1-2.25 2.25H6.75A2.25 2.25 0 0 1 4.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 0 1 1.927-.184" />
                                              </svg>

                                              board
                                            </>
                                          )}
                                        </span>
                                        <ChevronDownIcon className="-mr-1 ml-2 h-3 w-3" aria-hidden="true" />
                                      </button>
                                    </div>

                                    {isFeedbackOpen && (
                                      <div className="absolute right-0 z-10 mt-2 left-0 mq575:w-[160px] mq3000:w-[180px] origin-top-right rounded-md bg-white filter-dropdown border border-solid border-[#00000014]"
                                        style={{ boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)' }}
                                      >
                                        <div className="py-1">
                                          <input
                                            type="text"
                                            placeholder="boards..."
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                            className="w-full outline-none border-t-0 border-l-0 border-r-0 border-b border-solid border-[#00000029] 
            rounded-none text-sm h-[36px] pl-[10px] pr-[30px focus:outline-none focus:shadow-none focus:ring-0 focus:border-[#00000029] font-normal text-[#9f9f9fff]"
                                            onClick={(e) => e.stopPropagation()}
                                          />
                                          <span className="right-0 w-[2.25rem] h-[2.25rem] text-sm flex items-center justify-center absolute text-[#000000eb]  opacity-60 top-[2px]" style={{ zIndex: 2 }}>F</span>
                                          <div className=" overflow-auto max-h-[200px]">
                                            {filteredBoards.map((board) => (

                                              <li
                                                key={board.id}
                                                onClick={() => {
                                                  setSelectedBoard(board);
                                                  setIsFeedbackOpen(false);
                                                  setSearchQuery('');
                                                }}
                                                className="text-dark-black-100 flex items-center gap-[0.5rem] px-[0.75rem] 
                py-[0.375rem] h-[32px] text-[13px] font-normal cursor-pointer w-full hover:bg-[#f1f1f2] focus:bg-[#f1f1f2] active:bg-[#e7e7e8]"

                                              >
                                                <span className='flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-white capitalize'
                                                  style={{ backgroundColor: board.color }}
                                                ></span>
                                                {board.name}
                                              </li>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </span>

                              {/* for item status  */}
                              <span className="">
                                {itemStatus?.data?.length > 0 && (
                                  <div className="relative inline-block text-left" ref={statusRef}>
                                    <div>
                                      <button
                                        type="button"
                                        className="inline-flex justify-center items-center w-full rounded-[5px]
           bg-white text-[12px] font-medium text-[#5e5e5f] hover:bg-gray-50 focus:outline-none focus:ring-0
            focus:ring-offset-2 new-feedback-modal-select h-[30px] px-[8px]"
                                        onClick={() => setStatusIsOpen(!isStatusOpen)}
                                      >
                                        {selectedStatus ? (
                                          <>    <span className="border border-[#e5e7eb] border-solid text-[#6b7280] text-[0.75rem] gap-[0.675rem] leading-4 pr-1 pl-[0.625rem] 
                  py-1 bg-white rounded-md items-center inline-flex mx-[-10px]">
                                            <span className="text-[#1d2025] w-2 h-2 rounded-full bg-[#7d7f83] block"></span>
                                            Status
                                            <span className="bg-[#e5e7eb] w-[1px] h-[1rem] mx-1"></span>

                                            <span className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full text-[0.625rem] font-medium
                           text-white ${selectedStatus.color}`}
                                            ></span>
                                            <span className="text-[#111827] font-medium">{selectedStatus.base_status_title}</span>
                                            <button
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleRemoveStatus(id);
                                                setSelectedStatus(null);
                                                // setStatusIsOpen(true);
                                              }}
                                              className="text-[#6b7280] rounded justify-center items-center w-[1.25rem] h-[1.25rem] flex ml-[-.375rem] cursor-pointer"
                                            >
                                              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" aria-hidden="true">
                                                <path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path>
                                              </svg>
                                            </button>
                                          </span>
                                          </>
                                        ) : (
                                          <div className='flex items-center gap-1'>
                                            <span className="text-[#1d2025] w-2 h-2 rounded-full bg-[#7d7f83] block"></span>
                                            Status
                                          </div>
                                        )}
                                      </button>
                                    </div>

                                    {isStatusOpen && (
                                      <div className="absolute right-0 z-10 mt-2 left-0 mq575:w-[160px] mq3000:w-[180px] origin-top-right rounded-md bg-white filter-dropdown border border-solid border-[#00000014]"
                                        style={{ boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)' }}
                                      >
                                        <div className="py-1">
                                          <input
                                            type="text"
                                            placeholder="status..."
                                            value={searchStatusQuery}
                                            onChange={(e) => setSearchStatusQuery(e.target.value)}
                                            className="w-full outline-none border-t-0 border-l-0 border-r-0 border-b border-solid border-[#00000029] 
            rounded-none text-sm h-[36px] pl-[10px] pr-[30px focus:outline-none focus:shadow-none focus:ring-0 focus:border-[#00000029] font-normal text-[#9f9f9fff]"
                                            onClick={(e) => e.stopPropagation()}
                                          />
                                          <span className="right-0 w-[2.25rem] h-[2.25rem] text-sm flex items-center justify-center absolute text-[#000000eb]  opacity-60 top-[2px]" style={{ zIndex: 2 }}>F</span>
                                          <div className=" overflow-auto max-h-[200px]">
                                            {filteredStatus.map((status) => (
                                              <li
                                                key={status.id}
                                                onClick={() => {
                                                  setSelectedStatus(status);
                                                  setStatusIsOpen(false);
                                                  setSearchStatusQuery('');
                                                }}
                                                className="text-dark-black-100 flex items-center gap-[0.5rem] px-[0.75rem] 
                py-[0.375rem] h-[32px] text-[13px] font-normal cursor-pointer w-full hover:bg-[#f1f1f2] focus:bg-[#f1f1f2] active:bg-[#e7e7e8]"

                                              >
                                                <span className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full text-[0.625rem] font-medium
                           text-white ${status.color}`}
                                                ></span>
                                                <span className='truncate text-[13px] leading-4 whitespace-nowrap w-[140px] overflow-hidden text-ellipsis text-start'>{status.base_status_title}</span>
                                              </li>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </span>



                              {/* for item Tags  */}
                              <span className="">
                                {itemTags?.data?.length > 0 && (
                                  <div className="relative inline-block text-left" ref={tagsRef}>
                                    <div>
                                      <button
                                        type="button"
                                        className="inline-flex justify-center items-center w-full rounded-[5px] bg-white text-[12px] font-medium text-[#5e5e5f] hover:bg-gray-50 focus:outline-none focus:ring-0 focus:ring-offset-2 new-feedback-modal-select h-[30px] px-[8px]"
                                        onClick={() => setTagsIsOpen(!isTagsOpen)}
                                      >
                                        {selectedTags.length === 1 ? (
                                          <>
                                            <span key={selectedTags[0].id} className="border border-[#e5e7eb] border-solid text-[#6b7280]
                      text-[0.75rem] gap-[0.675rem] leading-4 pr-1 pl-[0.625rem] py-1 bg-white rounded-md items-center inline-flex mx-[-10px]">
                                              {/* <span className="text-[#1d2025] w-2 h-2 rounded-full bg-transparent border-2 border-solid border-[#7d7f83] block"></span> */}
                                              <svg className='mt-[1px]' stroke="#1e1e1e" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" focusable="false" aria-hidden="true"
                                                height="12px" width="12px" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path><line x1="7" y1="7" x2="7.01" y2="7"></line></svg>
                                              Tags
                                              <span className="bg-[#e5e7eb] w-[1px] h-[1rem] mx-1"></span>
                                              <span className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full text-[0.625rem] font-medium text-white ${selectedTags[0].color}`}></span>
                                              <span className="text-[#111827] font-medium">{selectedTags[0].tag_title}</span>
                                              <button
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  handleRemoveTag(selectedTags[0].id)
                                                  setSelectedStatus(null);
                                                  // setStatusIsOpen(true);
                                                }}
                                                className="text-[#6b7280] rounded justify-center items-center w-[1.25rem] h-[1.25rem] flex ml-[-.375rem] cursor-pointer"
                                              >
                                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" aria-hidden="true">
                                                  <path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path>
                                                </svg>
                                              </button>
                                            </span>
                                          </>
                                        ) : selectedTags.length > 1 ? (
                                          <span className="border border-[#e5e7eb] border-solid text-[#6b7280] text-[0.75rem] gap-[6px] leading-4 px-1 
                    py-1 bg-white rounded-md items-center inline-flex mx-[-10px] h-[30px]">
                                            <svg className='mt-[1px]' stroke="#1e1e1e" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" focusable="false" aria-hidden="true"
                                              height="12px" width="12px" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path><line x1="7" y1="7" x2="7.01" y2="7"></line></svg>
                                            Tags
                                            <span className="bg-[#e5e7eb] w-[1px] h-[1rem] mx-1"></span>

                                            {selectedTags.length}
                                            <span className="text-[#111827] font-medium">Selected</span>
                                          </span>
                                        ) : (
                                          <div className='flex items-center gap-1'>
                                            <svg className='mt-[1px]' stroke="#1e1e1e" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" focusable="false" aria-hidden="true"
                                              height="12px" width="12px" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path><line x1="7" y1="7" x2="7.01" y2="7"></line></svg>
                                            Tags
                                          </div>
                                        )}
                                      </button>
                                    </div>


                                    {isTagsOpen && (
                                      <div className="absolute right-0 z-10 mt-2 left-0 mq575:w-[160px] mq3000:w-[180px] origin-top-right rounded-md bg-white filter-dropdown border border-solid border-[#00000014]"
                                        style={{ boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)' }}
                                      >
                                        <div className="py-1">
                                          <input
                                            type="text"
                                            placeholder="tags..."
                                            value={searchTagsQuery}
                                            onChange={(e) => setSearchTagsQuery(e.target.value)}
                                            className="w-full outline-none border-t-0 border-l-0 border-r-0 border-b border-solid border-[#00000029] rounded-none text-sm h-[36px] pl-[10px] pr-[30px] focus:outline-none focus:shadow-none focus:ring-0 focus:border-[#00000029] font-normal text-[#9f9f9fff]"
                                            onClick={(e) => e.stopPropagation()}
                                          />
                                          <div className="overflow-auto max-h-[200px]">
                                            {filteredTags.map((tag) => (
                                              <li
                                                key={tag.id}
                                                onClick={() => handleTagSelection(tag)}
                                                className={`text-dark-black-100 flex items-center gap-[0.5rem] px-[0.75rem] py-[0.375rem] h-[32px] text-[13px] font-normal cursor-pointer w-full hover:bg-[#f1f1f2] focus:bg-[#f1f1f2] active:bg-[#e7e7e8] ${selectedTags.some(t => t.id === tag.id) ? 'bg-[#e7e7e8]' : ''
                                                  }`}
                                              >
                                                <span className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full text-[0.625rem] font-medium text-white ${tag.color}`}></span>
                                                <span className='truncate text-[13px] leading-4 whitespace-nowrap w-[140px] overflow-hidden text-ellipsis text-start'>{tag.tag_title}</span>


                                              </li>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </span>

                              {/* for base member  */}
                              <span className="">
                                {baseMemberDetails.length > 0 && (
                                  <div className="relative inline-block text-left" ref={membersRef}>
                                    <div>
                                      <button
                                        type="button"
                                        className="inline-flex justify-center items-center w-full rounded-[5px]
           bg-white text-[12px] font-medium text-[#5e5e5f] hover:bg-gray-50 focus:outline-none focus:ring-0
            focus:ring-offset-2 new-feedback-modal-select h-[30px] px-[8px]"
                                        onClick={() => setMemberIsOpen(!isMemberOpen)}
                                      >
                                        {selectedMember ? (
                                          <>    <span className="border border-[#e5e7eb] border-solid text-[#6b7280] text-[0.75rem] gap-[0.675rem] leading-4 pr-1 pl-[0.625rem] 
                  py-1 bg-white rounded-md items-center inline-flex mx-[-10px]">
                                            {/* <span className="text-[#1d2025] w-2 h-2 rounded-full bg-transparent border-2 border-solid border-[#7d7f83] block"></span> */}
                                            <UserCircleIcon className='w-[14px] h-[14px] text-[#1e1e1e]' />
                                            Assign to
                                            <span className="bg-[#e5e7eb] w-[1px] h-[1rem] mx-1"></span>

                                            {/* <span className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full text-[0.625rem] font-medium
                           text-white ${selectedMember.color}`}
                      ></span> */}
                                            <div className="w-5 h-5 rounded-full bg-gray-200 flex items-center justify-center text-[10px]">
                                              <span className="text-[7px] font-medium text-gray-700"></span>{memberInitials}</div>
                                            <span className="text-[#111827] font-medium">{selectedMember.name}</span>
                                            <button
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleRemoveMember(id);
                                                setSelectedMember(null);
                                                // setTagsIsOpen(true);
                                              }}
                                              className="text-[#6b7280] rounded justify-center items-center w-[1.25rem] h-[1.25rem] flex ml-[-.375rem] cursor-pointer"
                                            >
                                              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" aria-hidden="true">
                                                <path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path>
                                              </svg>
                                            </button>
                                          </span>
                                          </>
                                        ) : (
                                          <div className='flex items-center gap-1'>
                                            <UserCircleIcon className='w-[14px] h-[14px] text-[#1e1e1e]' />
                                            Assign to
                                          </div>
                                        )}
                                      </button>
                                    </div>

                                    {isMemberOpen && (
                                      <div className="absolute right-0 z-10 mt-2 left-0 mq575:w-[160px] mq3000:w-[180px] origin-top-right rounded-md bg-white filter-dropdown border border-solid border-[#00000014]"
                                        style={{ boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)' }}
                                      >
                                        <div className="py-1">
                                          <input
                                            type="text"
                                            placeholder="Member..."
                                            value={searchMemberQuery}
                                            onChange={(e) => setSearchMemberQuery(e.target.value)}
                                            className="w-full outline-none border-t-0 border-l-0 border-r-0 border-b border-solid border-[#00000029] 
            rounded-none text-sm h-[36px] pl-[10px] pr-[30px focus:outline-none focus:shadow-none focus:ring-0 focus:border-[#00000029] font-normal text-[#9f9f9fff]"
                                            onClick={(e) => e.stopPropagation()}
                                          />
                                          <span className="right-0 w-[2.25rem] h-[2.25rem] text-sm flex items-center justify-center absolute text-[#000000eb]  opacity-60 top-[2px]" style={{ zIndex: 2 }}>F</span>
                                          <div className=" overflow-auto max-h-[200px]">
                                            {filteredMembers.map((member) => (
                                              <li
                                                key={member.id}
                                                onClick={() => {
                                                  setSelectedMember(member);
                                                  setMemberIsOpen(false);
                                                  setSearchMemberQuery('');
                                                }}
                                                className="text-dark-black-100 flex items-center gap-[0.5rem] px-[0.75rem] 
                py-[0.375rem] h-[32px] text-[13px] font-normal cursor-pointer w-full hover:bg-[#f1f1f2] focus:bg-[#f1f1f2] active:bg-[#e7e7e8]"

                                              >
                                                {/* <span className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full text-[0.625rem] font-medium
                           text-white ${tags.color}`}
                          ></span> */}
                                                <span className='truncate text-[13px] leading-4 whitespace-nowrap w-[140px] overflow-hidden text-ellipsis text-start'>  {member.name}</span>

                                              </li>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </span>
                              {/* for priority  */}
                              <span className="">
                                <div className="relative inline-block text-left" ref={prioritysRef}>
                                  <div>
                                    <button
                                      type="button"
                                      className="inline-flex justify-center items-center w-full rounded-[5px]
           bg-white text-[12px] font-medium text-[#5e5e5f] hover:bg-gray-50 focus:outline-none focus:ring-0
            focus:ring-offset-2 new-feedback-modal-select h-[30px] px-[8px]"
                                      onClick={() => setPriorityIsOpen(!isPriorityOpen)}
                                    >
                                      {selectedPriority ? (
                                        <>    <span className="border border-[#e5e7eb] border-solid text-[#6b7280] text-[0.75rem] gap-[0.675rem] leading-4 pr-1 pl-[0.625rem] 
                  py-1 bg-white rounded-md items-center inline-flex mx-[-10px]">
                                          <ChartBarIcon className='w-[14px] h-[14px] text-[#1e1e1e]' />
                                          Priority
                                          <span className="bg-[#e5e7eb] w-[1px] h-[1rem] mx-1"></span>
                                          <span className="text-[#111827] font-medium">{selectedPriority.name}</span>
                                          <button
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handleRemovePriority(id);
                                              setSelectedPriority(null);
                                              // setTagsIsOpen(true);
                                            }}
                                            className="text-[#6b7280] rounded justify-center items-center w-[1.25rem] h-[1.25rem] flex ml-[-.375rem] cursor-pointer"
                                          >
                                            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" aria-hidden="true">
                                              <path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path>
                                            </svg>
                                          </button>
                                        </span>
                                        </>
                                      ) : (
                                        <div className='flex items-center gap-1'>
                                          <ChartBarIcon className='w-[14px] h-[14px] text-[#1e1e1e]' />
                                          Priority
                                        </div>
                                      )}
                                    </button>
                                  </div>

                                  {isPriorityOpen && (
                                    <div className="absolute right-0 z-10 mt-2 left-0 mq575:w-[160px] mq3000:w-[180px] origin-top-right rounded-md bg-white filter-dropdown border border-solid border-[#00000014]"
                                      style={{ boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)' }}
                                    >
                                      <div className="py-1">
                                        <input
                                          type="text"
                                          placeholder="Priority..."
                                          value={searchPriorityQuery}
                                          onChange={(e) => setSearchPriorityQuery(e.target.value)}
                                          className="w-full outline-none border-t-0 border-l-0 border-r-0 border-b border-solid border-[#00000029] 
            rounded-none text-sm h-[36px] pl-[10px] pr-[30px focus:outline-none focus:shadow-none focus:ring-0 focus:border-[#00000029] font-normal text-[#9f9f9fff]"
                                          onClick={(e) => e.stopPropagation()}
                                        />
                                        <span className="right-0 w-[2.25rem] h-[2.25rem] text-sm flex items-center justify-center absolute text-[#000000eb]  opacity-60 top-[2px]" style={{ zIndex: 2 }}>F</span>
                                        <div className=" overflow-auto max-h-[200px]">
                                          {filteredPriority.map((priority) => (
                                            <li
                                              key={priority.id}
                                              onClick={() => {
                                                setSelectedPriority(priority);
                                                setPriorityIsOpen(false);
                                                setSearchPriorityQuery('');
                                              }}
                                              className="text-dark-black-100 flex items-center gap-[0.5rem] px-[0.75rem] 
                py-[0.375rem] h-[32px] text-[13px] font-normal cursor-pointer w-full hover:bg-[#f1f1f2] focus:bg-[#f1f1f2] active:bg-[#e7e7e8]"

                                            >
                                              {/* <span className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full text-[0.625rem] font-medium
                           text-white ${tags.color}`}
                          ></span> */}
                                              <span className='truncate text-[13px] leading-4 whitespace-nowrap w-[140px] overflow-hidden text-ellipsis text-start'>{priority.name}</span>


                                            </li>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                {/* )} */}
                              </span>
                            </div>
                          </motion.div>
                        )}

                      </div>
                    </div>
                    <span className="border-b border-solid border-[#e3e3e3] mx-[-16px] block py-[6px]"></span>
                    <div className="flex items-center justify-between gap-[22px] pt-3 flex-wrap md:flex-nowrap lg:flex-nowrap">
                      <div className='flex items-center gap-[22px]'>
                        {files.length === 0 && (


                          <div className="flex items-center space-x-2 text-gray-500">
                            <input
                              type="file"
                              id="file-upload"
                              className="hidden"
                              multiple
                              onChange={handleFileChange} // Add onChange handler here
                            />
                            <label htmlFor="file-upload" className="flex items-center cursor-pointer">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="#5e5e5f"
                                role="img"
                                focusable="false"
                                aria-hidden="true"
                              >
                                <path d="M12.6429 7.69048L8.92925 11.4041C7.48164 12.8517 5.34347 13.0101 4.16667 11.8333C2.98733 10.654 3.14447 8.52219 4.59216 7.07451L8.00206 3.66461C8.93557 2.73109 10.2976 2.63095 11.0333 3.36667C11.7681 4.10139 11.6658 5.4675 10.7361 6.39727L7.32363 9.8097C6.90202 10.2313 6.32171 10.2741 6.02381 9.97619C5.72651 9.6789 5.76949 9.09718 6.1989 8.66776L9.29048 5.57619C9.56662 5.30005 9.56662 4.85233 9.29048 4.57619C9.01433 4.30005 8.56662 4.30005 8.29048 4.57619L5.1989 7.66776C4.24737 8.6193 4.13865 10.091 5.02381 10.9762C5.9095 11.8619 7.37984 11.7535 8.32363 10.8097L11.7361 7.39727C13.1876 5.94573 13.3564 3.68975 12.0333 2.36667C10.7099 1.04326 8.45782 1.20884 7.00206 2.66461L3.59216 6.07451C1.62229 8.04437 1.39955 11.0662 3.16667 12.8333C4.93146 14.5981 7.9596 14.3737 9.92925 12.4041L13.6429 8.69048C13.919 8.41433 13.919 7.96662 13.6429 7.69048C13.3667 7.41433 12.919 7.41433 12.6429 7.69048Z"></path>
                              </svg>
                            </label>
                          </div>
                        )
                        }
                        <div className="mt-0">
                          {files.map(file => (
                            <div key={file.name} className="flex items-center space-x-2  border p-1 rounded-md">
                              <span className="flex items-center leading-[1.2]  md:text-[0.8125rem] 
                        bg-white text-[12px] font-medium text-[#5e5e5f] gap-1
                               ">{file.name}</span>
                              <button
                                type="button"
                                onClick={() => handleRemoveFile(file.name)}
                                className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none '
                                aria-label="Remove file"
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                </svg>

                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="inline-flex items-center relative justify-center whitespace-nowrap rounded-[5px] bg-indigo-600 font-medium leading-normal help-btn-setting text-[#fefeff] min-w-[32px] h-[32px] px-[14px] text-[0.8125rem]"
                        onClick={() => setFeedbackOpen(false)}
                      >
                        <div className='flex items-center flex-row-reverse gap-[6px]'>
                          {loading && (
                            <div className="simple-spinner">
                              <span></span>
                            </div>
                          )}
                          Create Issue

                        </div>
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>

  );
};

export default SendDetails;