
import { _baseRequest } from "../../../services/api";

const API_END_POINT = {
    FORGOTPASS: "/v1/auth/forgot/password"
}

export const user_forgotpass = async (
    email: string,
) => {
    return _baseRequest(API_END_POINT.FORGOTPASS, "POST", {
        email,
    }).then((response) => {
        return response;
    }).catch((error) => {
        return error
    });
};

