import React, { useEffect, useState } from 'react';
import Sidebar from '../dashboard/sidebar/Sidebar';
import { _getChannel1 } from '../setting/settingPages/baseAPI';
import ViewIntegration from './ViewIntegration';
import { useDispatch } from 'react-redux';


const ViewIntegrationSidebar: React.FC = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [selectedTab, setSelectedTab] = useState<string>('');
    const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
    const baseId = baseIdString ? JSON.parse(baseIdString) : null;

    const [channelData, setChannelData] = useState<any>(null);
    const dispatch = useDispatch<any>();

    useEffect(() => {
        if (baseId) {
            const fetchData = async () => {
                const storedChannelData = localStorage.getItem('channelData');
                if (storedChannelData) {
                    setChannelData(JSON.parse(storedChannelData));
                } else {
                    const response = await dispatch(_getChannel1(baseId));
                    setChannelData(response.payload);
                }

            };
            fetchData();
        }
    }, [baseId, dispatch]);

    const handleTabChange = (selectedTabName: any) => {
        setSelectedTab(selectedTabName);
    }
    const handleTabChange1 = (selectedTabName: any) => {
        setSelectedTab(selectedTabName);
    }

    return (

        <div>
            <Sidebar channelData={channelData} id={baseId} onClickValue={sidebarOpen} onClick={(e: boolean) => setSidebarOpen(e)} onTabChange={handleTabChange} onTabChange1={handleTabChange1} />
            <div className='lg:pl-[225px]'>
                <ViewIntegration isSidebarOpen={sidebarOpen} setIsSidebarOpen={setSidebarOpen} />
            </div>
        </div>

    );
};

export default ViewIntegrationSidebar;
