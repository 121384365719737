
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon, } from '@heroicons/react/24/outline'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { routes } from '../Routes'
import { routesProfile } from '../ProfileRoutes'
import { useDispatch } from 'react-redux'
import { clearBaseInfo } from '../../createBase/baseSlice'
import { Button } from '@lemonsqueezy/wedges'
import { Link } from 'react-router-dom'
import { getUserAndBaseData } from '../settingPages/useUserAndBaseData'


const teams = [
  { id: 1, name: 'profile', href: '/setting/profile/', initial: 'P', current: false },
  { id: 2, name: 'security', href: '/setting/secure/', initial: 'S', current: false },
]

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

interface sidebarProps {
  onClickValue: boolean
  onClick: any,
  id: any

}

const Sidebar: React.FC<sidebarProps> = ({ onClickValue, onClick, id }) => {
  
  const [bgColor, setBgColor] = useState("Base Details")

  const navigate = useNavigate()

  const dispatch = useDispatch(); // Use the useDispatch hook

  const handleTabChange = (teamName: string, href: string) => {
    setBgColor(teamName);
    navigate(href);
    dispatch(clearBaseInfo())
  };



  const location = useLocation();
  const { boardId } = useParams();

  const isActive = (path: any) => {
    if (typeof path !== 'string') {
      return false;
    }
    const currentPath = location.pathname;
    // if (path.includes('/board/')) {
    //   return currentPath.includes(path);
    // }
    return currentPath.endsWith(path) || currentPath.startsWith(path);
  };

  const userAuthId = localStorage.getItem("userAuthId")
  const { showBaseSetting } = getUserAndBaseData();

  return (<>
    <Transition.Root show={onClickValue} as={Fragment}>
      <Dialog as='div' className='relative z-1 bg-slate-900' onClose={onClick}>
        <Transition.Child
          as={Fragment}
          enter='transition-opacity ease-linear duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity ease-linear duration-300'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'>
          <div className='fixed inset-0 bg-gray-900/80' />
        </Transition.Child>

        <div className='fixed inset-0 flex z-50'>
          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='-translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='-translate-x-full'>
            <Dialog.Panel className='relative mr-16 flex w-full max-w-xs flex-1'>
              <Transition.Child
                as={Fragment}
                enter='ease-in-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in-out duration-300'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'>
                <div className='absolute left-full top-0 flex w-16 justify-center pt-5'>
                  <button type='button' className='-m-2.5 p-2.5' onClick={() => onClick(false)}>
                    <span className='sr-only'>Close sidebar</span>
                    {/* <XMarkIcon className='h-6 w-6 text-white' aria-hidden='true' /> */}
                  </button>
                </div>
              </Transition.Child>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-4 overflow-y-auto bg-light-pink px-6 pb-4 py-4">
                <div className='flex gap-[0.5rem] items-center h-[32px] px-[0.25rem]'>
                  <Button
                    onClick={() => navigate(`/dashboard/dashboard/${id}`)}
                    type='button'
                    className="bg-light-pink  hover:bg-gray-100 text-black-100
             focus-visible:outline-light-pink [&>span]:w-full  [&>span]:flex px-0  [&>span]:items-center"
                    size="sm"
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='w-4 h-4 mr-0'>
                      <path strokeLinecap='round' strokeLinejoin='round' d='M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18' />
                    </svg>

                  </Button>
                  <span className='leading-[1.2] font-semibold 
                whitespace-nowrap text-[0.8125rem]'> Settings   </span>
                </div>

                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col gap-y-5">
                    <li>
                      <ul role='list'>
                        {routes.map(({ layout, pages }, key) => (
                          <>
                            {pages.map((item, index) => (
                              <li key={`${index}+${key}`} className='h-[32px] mb-[2px] py-[2px] sidebar-nav'>
                                <Link to={`/${id}/${layout}${item.path}`}

                                  onClick={() => {
                                    handleTabChange(item.name, `/${layout}${item.path}/${id}`);
                                    onClick(false); // Add this line to close the sidebar
                                  }}
                                  className={classNames(
                                    isActive(item.path)
                                      ? ' text-black-100 bg-[#00000014] hover:bg-[rgb(0 0 0 / 6%)]'
                                      : 'text-black-100 hover:text-black-100 hover:bg-gray-100',
                                    'group flex gap-x-[10px] p-0 text-[13px]  m-0 min-h-[32px] w-full cursor-pointer leading-5 items-center rounded-[0.375rem] font-normal text-wedges-gray-900 focus:outline-none px-2'
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.name ? 'text-[#0000007a]' : 'text-red-100 group-hover:text-black-100',
                                      'h-[1.1em] w-[1.1em] shrink-0'
                                    )}
                                    aria-hidden='true'
                                  />
                                  {item.name}

                                </Link>
                              </li>
                            ))}
                          </>
                        ))}
                      </ul>
                    </li>
                    <li>
                      <div className='flex Items-center justify-between	 px-[0.75rem] hover:bg-[#0000000f]  rounded-[0.375rem] my-[2px]'>
                        <span className="text-[13px] font-[500] text-black-100 mt-[-1px] capitalize">
                          Your Account</span>
                      </div>
                      <ul role="list" className="">
                        {routesProfile.map(({ pages }, key) => (
                          <>
                            {pages.map((team, index) => (
                              <li key={team.name} className='h-[32px] first:mt-[0.4rem] mb-[2px] py-[2px] ml-[2px]'>
                                <Link
                                  onClick={() => {
                                    setBgColor(team.name);
                                    navigate(`${team.name}/${id}`)
                                    onClick(false); // Add this line to close the sidebar
                                  }}
                                  to={`/${id}/setting/${team.name}`}
                                  className={classNames(
                                    isActive(team.name)
                                      ? ' text-black-100 bg-[#00000014] hover:bg-[rgb(0 0 0 / 6%)] capitalize'
                                      : 'text-black-100 hover:text-black-100 hover:bg-gray-100 capitalize',
                                    'group flex gap-x-[10px] p-0 text-[13px] justify-between m-0 min-h-[32px] capitalize w-full cursor-pointer leading-5 items-center rounded-[0.375rem] font-normal text-wedges-gray-900 focus:outline-none px-2'

                                  )}
                                >
                                  {/* <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg bg-indigo-500 text-[0.625rem] font-medium text-white">
                                    {team.name[0]}
                                  </span> */}
                                  <span className='truncate'>{team.name}</span>
                                </Link>
                              </li>
                            ))}
                          </>
                        ))}
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>



    {/* for desktop */}

    <div className='hidden lg:fixed lg:inset-y-0 lg:z-[9] lg:flex lg:w-[225px] lg:flex-col'>
      <div className="flex grow flex-col bg-white pt-[0] px-0 pb-4 sidebar-des border-r border-gray-100">
        <div className='flex gap-[0.5rem] items-center h-[48px] px-[14px]'>
          <div className='flex items-center gap-[0.5rem] h-[23px]'>
            <Button
              onClick={() => navigate(`/${id}/dashboard`)}
              type='button'
              className="bg-transparent  hover:bg-transparent text-black-100
             focus-visible:outline-light-pink [&>span]:w-full  [&>span]:px-0 px-0  [&>span]:items-center"
              size="sm"
            >
              <svg width="16" height="16" viewBox="0 0 16 16" fill="#575859"
                role="img" focusable="false" aria-hidden="true" >
                <path d="M10.7803 4.78033C11.0732 4.48744 11.0732 4.01256 10.7803 3.71967C10.4874 3.42678 10.0126 3.42678 9.71967 3.71967L5.71967 7.71967C5.42933 8.01001 5.42643 8.47986 5.71318 8.77376L9.61581 12.7738C9.90508 13.0702 10.3799 13.0761 10.6764 12.7868C10.9729 12.4976 10.9787 12.0227 10.6895 11.7262L7.30417 8.25649L10.7803 4.78033Z"></path></svg>


            </Button>
            <span className='leading-[1.4375rem] font-medium  text-[0.9375rem] text-[#1a1a1a]'> Settings   </span>
          </div>

        </div>




        <nav className="flex overflow-y-auto h-1/2 flex-col px-[14px]">
          <ul role="list" className="flex flex-1 flex-col gap-y-2">
            {
              showBaseSetting &&(
                <>
                <li>
                    <div className='flex items-center gap-2'>
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="#575859"
                        role="img" focusable="false" aria-hidden="true">
                        <path d="M3.25 3C3.11193 3 2.99836 2.88751 3.01541 2.7505C3.13822 1.76368 3.97992 1 5 1H11C12.0201 1 12.8618 1.76368 12.9846 2.7505C13.0016 2.88751 12.8881 3 12.75 3L3.25 3Z">
                        </path>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M3.5 4C3.22386 4 3 4.22386 3 4.5V14.5C3 14.7761 3.22386 15 3.5 15H5.5C5.77614 15 6 14.7761 6 14.5V12.5C6 12.2239 6.22386 12 6.5 12H9.5C9.77614 12 10 12.2239 10 12.5V14.5C10 
                         14.7761 10.2239 15 10.5 15H12.5C12.7761 15 13 14.7761 13 14.5V4.5C13 4.22386 12.7761 4 12.5 4H3.5ZM11 8C10.4477 8 10 8.44772
                          10 9V9.6C10 9.82091 10.1791 10 10.4 10H11.6C11.8209 10 12 9.82091 12 9.6V9C12 8.44772 11.5523 8 11 8ZM10 6C10 5.44772
                           10.4477 5 11 5C11.5523 5 12 5.44772 12 6V6.6C12 6.82091 11.8209 7 11.6 7H10.4C10.1791 7 10 6.82091 10 6.6V6ZM8 5C7.44772 
                           5 7 5.44772 7 6V6.6C7 6.82091 7.17909 7 7.4 7H8.6C8.82091 7 9 6.82091 9 6.6V6C9 5.44772 8.55228 5 8 5ZM7 9C7 8.44772 7.44772
                            8 8 8C8.55228 8 9 8.44772 9 9V9.6C9 9.82091 8.82091 10 8.6 10H7.4C7.17909 10 7 9.82091 7 9.6V9ZM5 8C4.44772 8 4 8.44772 4
                             9V9.6C4 9.82091 4.17909 10 4.4 10H5.6C5.82091 10 6 9.82091 6 9.6V9C6 8.44772 5.55228 8 5 8ZM4 6C4 5.44772 4.44772 5 5
                              5C5.55228 5 6 5.44772 6 6V6.6C6 6.82091 5.82091 7 5.6 7H4.4C4.17909 7 4 6.82091 4 6.6V6Z"></path>
                      </svg><span className='text-[#575859] font-medium text-[0.8125rem]'>Workspace</span></div>
                  </li>
                  <li>
                    <ul role="list" className="">
                      {routes.map(({ layout, pages }, key) => (
                        <>
                          {pages.map((item, index) => (
                            <li key={index} className='h-[28px] mb-[2px] py-[2px] sidebar-nav pl-[18px]'>
                               <Link to={`/${id}/${layout}${item.path}`}
                                onClick={() => {
                                  setBgColor(item.name);
                                  handleTabChange(item.name, `/${id}/${layout}${item.path}`);
                                }}
                                // to={`/${id}/setting${item.path}`}
                                className={classNames(
                                  isActive(item.path)
                                    ? ' text-black-100 bg-[#00000014] hover:bg-[rgb(0 0 0 / 6%)] capitalize'
                                    : 'text-black-100 hover:text-black-100 hover:bg-gray-10 capitalize',
                                  'group flex gap-x-[10px] p-0 text-[0.8125rem]  m-0 min-h-[28px] capitalize w-full cursor-pointer leading-5 items-center rounded-[4px] font-medium text-wedges-gray-900 focus:outline-none pr-[2px] pl-[6px]'
                                )}
                          
      
                              >
                                {/* <item.icon
                                  className={classNames(
                                    item.name ? 'text-[#0000007a]' : 'text-red-100 group-hover:text-black-100',
                                    'h-[1.1em] w-[1.1em] shrink-0'
                                  )}
                                  aria-hidden="true"
                                /> */}
                                {item.name}
      
                              </Link>
                            </li>
                          ))}
                        </>
                      ))}
                    </ul>
                  </li>
              
                </>
              )
            }
       
          

            <li className='mt-[12px]'>
              <div className='flex items-center gap-2'>
                <svg width="16" height="16" viewBox="0 0 16 16"
                  fill="#575859" role="img" focusable="false" aria-hidden="true" ><path d="M8 4a2 2 0 0 0-2 2v.5a2 2 0 0 0 4 0V6a2 2 0 0 0-2-2Z">
                  </path>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Zm-2.879-4.121-1.01 1.01a5.5 5.5 0 1 1 7.778 0l-1.01-1.01A3 3 0 0 0 8.757 10H7.243a3 3 0 0 0-2.122.879Z">
                  </path>
                </svg>
                <span className='text-[#575859] font-medium text-[0.8125rem]'>My Account</span>
              </div>


              <ul role="list" className="">
                {routesProfile.map(({ pages }, key) => (
                  <>
                    {pages.map((team, index) => (
                      
                      <li key={team.name} className='h-[28px] mb-[2px] py-[2px] sidebar-nav pl-[18px]'>
                        <Link
                          onClick={() => { setBgColor(team.name); navigate(`/user/${userAuthId}/${team.name}`) }}
                          to={`/user/${userAuthId}/${team.name}`}
                          className={classNames(
                            isActive(team.name)
                              ? ' text-black-100 bg-[#00000014] hover:bg-[rgb(0 0 0 / 6%)] capitalize'
                              : 'text-black-100 hover:text-black-100 hover:bg-gray-100 capitalize',
                            'group flex gap-x-[10px] p-0 text-[0.8125rem]  m-0 min-h-[28px] w-full capitalize cursor-pointer leading-5 items-center rounded-[4px] font-medium text-wedges-gray-900 focus:outline-none pr-[2px] pl-[6px]'
                          )}


                        >
                          {/* <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg bg-indigo-500 text-[0.625rem] font-medium text-white">
                            {team.name[0]}
                          </span> */}
                          <span className='truncate'>{team.name}</span>
                        </Link>
                      </li>
                    ))}
                  </>
                ))}
              </ul>
            </li>

          </ul>
        </nav>
      </div>
    </div>


  </>)
}
export default Sidebar