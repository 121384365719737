// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { _feedback_update_item, _item_TopicSearchByParam } from '../setting/settingPages/baseAPI';
import { motion, AnimatePresence } from 'framer-motion';
import { Listbox, ListboxButton, ListboxOptions, Transition } from '@headlessui/react'
import MultiSelectTags from '../../components/MultiSelectTags';
import { fetchFeedbackData, fetchFeedbackItemActivity } from '../feedbacks/feedback-redux/feedbacks.actions';
import { Toaster, toast } from 'sonner';
import { useAppDispatch } from '../../hooks/hooks';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fetchItemInsightTopicData } from '../insights/insight-redux/insight.actions';


function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

interface ViewFeedbackProps {
  item?: {
    id: number;
    name: string;
    daysAgo: string;
    createdBy: string;
    avatarUrl: string;
    description: string;
    statusColor: string;
  };
  isSidebarOpen?: boolean;
  setIsSidebarOpen?: (open: boolean) => void;
  headernone?: boolean;
  handleSidebarToggle?: () => void;
}

interface ItemStatus {
  id: any;
  base_id: number;
  item_base_status_id: string;
}

interface ItemTags {
  base_id: number;
  item_label: string;
}

interface ItemBoard {
  id: any;
  base_id: number;
}

interface Priority {
  name: any;
  id: any;
}

interface Sentiment {
  id: number;
  name: string;
}



const ViewTopicDetails: React.FC<ViewFeedbackProps> = ({ setItemStatusActivity, setItemInsight, itemId, setLocalLoading }) => {
  const [statusOptions, setStatusOptions] = useState<{ id: number, name: string, label: string }[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<{ id: number, name: string, label: string } | null>(null);
  const [tagsOptions, setTagsOptions] = useState<{ id: number, name: string, label: string, value?: string; isNew?: boolean; }[]>([]);
  const [selectedTags, setSelectedTags] = useState<{ id: number; name: string; label: string; value?: string; isNew?: boolean; }[]>([]);
  const [memberOptions, setMemberOptions] = useState<{ id: number, name: string, label: string }[]>([]);
  const [selectedMember, setSelectedMember] = useState<{ id: number, name: string, label: string } | null>(null);
  const [priorityOptions, setPriorityOptions] = useState<{ id: number, name: string, label: string }[]>([]);
  const [selectedPriority, setSelectedPriority] = useState<{ id: number, name: string, label: string } | null>(null);
  const [boardOptions, setBoardOptions] = useState<{ id: number, name: string, label: string }[]>([]);
  const [selectedBoard, setSelectedBoard] = useState<{ id: number, name: string, label: string } | null>(null);
  const [sentimentOptions, setSentimentOptions] = useState<{ id: number, name: string, label: string }[]>([]);
  const [selectedSentiment, setSelectedSentiment] = useState<{ id: number, name: string, label: string } | null>(null);
  const [collapsed, setCollapsed] = useState(false);
  const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;
  const dispatch = useAppDispatch();
  const { id } = useParams();


  const { data, loading, error } = useSelector((state: RootState) => state.feedbacks);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  // item status
  useEffect(() => {
    const storedStatus = localStorage.getItem("itemStatusList");

    if (storedStatus) {
      const parsedStatus = JSON.parse(storedStatus);

      if (parsedStatus && parsedStatus.data) {
        const statusOptions = parsedStatus.data.map((status: any) => ({
          id: status.id,
          name: status.base_status_title,
          label: status.color
        }));
        setStatusOptions(statusOptions);
      } else {
        console.error("Invalid data format in local storage.");
      }
    }
  }, []);
  
  

  useEffect(() => {
    if (data && data.length > 0 && statusOptions.length > 0) {
        const itemStatus = localStorage.getItem('itemStatusList');
        const itemStatusArray = itemStatus !== null ? JSON.parse(itemStatus) : [];
        
        const statusObject = itemStatusArray.data.find((status: ItemStatus) => status.id === data[0].item_base_status_id);

        if (statusObject) {
            const initialStatus = statusOptions.find(option => option.name === statusObject.base_status_title);
            setSelectedStatus(initialStatus || null);
        }
    }
}, [data, statusOptions]);


  // item tags
  useEffect(() => {
    const storedTags = localStorage.getItem("itemTagsList");
    if (storedTags) {
      const parsedTags = JSON.parse(storedTags);
      const tagsOptions = parsedTags.data.map((tag: any) => ({
        id: tag.id,
        name: tag.tag_title,
        label: tag.color,
        value: tag.tag_title



      }));
      setTagsOptions(tagsOptions)
    }
  }, []);



  // useEffect(() => {
  //   if (data && data.length > 0 && tagsOptions.length > 0) {

  //     const itemTags = localStorage.getItem('itemTagsList');
  //     const itemTagsArray = itemTags !== null ? JSON.parse(itemTags) : [];

  //     // Convert item_label to number array for comparison
  //     const itemTagIds = data.flatMap((item: any) =>
  //       item?.item_label?.split(',')?.map((tagId: string) => parseInt(tagId))
  //     );

  //     const tagsObjects = itemTagsArray.filter((tag: ItemTags) => itemTagIds.includes(tag.id));

  //     if (tagsObjects) {
  //       const initialTags = tagsObjects.map((tag: any) => tagsOptions.find(option => option.id === tag.id));

  //       setSelectedTags(initialTags);
  //     } else {
  //       setSelectedTags([]);
  //     }
  //     const itemTagIds = data[0]?.item_label?.split(',')?.map((tagId: string) => parseInt(tagId)) || [];

  //     const initialTags = itemTagsArray
  //       .filter((tag: Tag) => itemTagIds.includes(tag.id))
  //       .map((tag: Tag) => ({
  //         ...tagsOptions.find(option => option.id === tag.id),
  //         value: tag.tag_title,
  //         isNew: false
  //       }));

  //     setSelectedTags(initialTags);
  //   }
  // }, [data, tagsOptions]);

  useEffect(() => {
    if (data && data.length > 0) {
      const itemTagIds = data[0]?.item_label?.split(',') || [];
      // const itemTagIds = itemTagIds.filter(tag => tag && tag.trim() !== "");
      setSelectedTags(itemTagIds);
    }
  }, [data, tagsOptions]);

  useEffect(() => {
  }, [selectedTags]);

  // base member 
  useEffect(() => {
    const storedMember = localStorage.getItem("baseMembersDetails");
    if (storedMember) {
      const parsedMember = JSON.parse(storedMember);
      const memberOptions = parsedMember.map((member: any) => ({
        id: member.id,
        name: member.name,
        profile_pic: member.profile_pic
        // label: member.color
      }));
      setMemberOptions(memberOptions);
    }
  }, []);

  useEffect(() => {
    if (data && data.length > 0 && memberOptions.length > 0) {
      const itemMember = localStorage.getItem('baseMembersDetails');

      const itemMemberArray = itemMember !== null ? JSON.parse(itemMember) : [];
      const memberObject = itemMemberArray.find(
        (member) => member.id === data[0].item_assigned_to
      );

      if (memberObject) {
        const initialMember = memberOptions.find((option) => option.name === memberObject.name);

        if (initialMember) {
          const updatedInitialMember = {
            ...initialMember,
            profile_pic: memberObject.profile_pic,
          };

          setSelectedMember(updatedInitialMember);
        } else {
          setSelectedMember(null);
        }
      }
    }
  }, [data, memberOptions]);

  // priorities  
  useEffect(() => {
    // Fetch priorities from localStorage or an API
    const storedPriority = localStorage.getItem("priorities");
    if (storedPriority) {
      const parsedPriority = JSON.parse(storedPriority);
      const priorityOptions = parsedPriority.map((priority: any) => ({
        id: priority.id,
        name: priority.name,
      }));
      setPriorityOptions(priorityOptions);
    }
  }, []);

  useEffect(() => {
    if (data && data.length > 0 && priorityOptions.length > 0) {

      const itemPriority = localStorage.getItem('priorities');
      const itemPriorityArray = itemPriority !== null ? JSON.parse(itemPriority) : [];
      const priorityObject = itemPriorityArray.find((pri: Priority) => pri.name === data[0].item_priority);

      if (priorityObject) {
        const initialPriority = priorityOptions.find(option => option.name === priorityObject.name);
        setSelectedPriority(initialPriority || null);
      }
    }
  }, [data, priorityOptions]);


  // sentiment  
  useEffect(() => {
    const storedSentiment = localStorage.getItem("sentimentTopic");
    if (storedSentiment) {
      const parsedSentiment = JSON.parse(storedSentiment);
      const sentimentOptions = parsedSentiment.map((sentiment: any) => ({
        id: sentiment.id,
        name: sentiment.name,
        label: sentiment.label
      }));
      setSentimentOptions(sentimentOptions);
    }
  }, []);

  useEffect(() => {
    if (data && data.length > 0 && sentimentOptions.length > 0) {
      const itemSentiment = localStorage.getItem('sentimentTopic');
      const itemSentimentArray = itemSentiment !== null ? JSON.parse(itemSentiment) : [];
      const sentimentObject = itemSentimentArray.find((sen: Sentiment) => sen.name === data[0].item_sentiment);
      if (sentimentObject) {
        const initialSentiment = sentimentOptions.find(option => option.name === sentimentObject.name);
        setSelectedSentiment(initialSentiment || null);
      }
    }
  }, [data, sentimentOptions]);


    // board
    useEffect(() => {
      let storedBoard;
      if (localStorage.getItem("boardDataItem")) {
          storedBoard = localStorage.getItem("boardDataItem") || localStorage.getItem("channelData")
      } else {
          storedBoard = localStorage.getItem("boardDataItem") || localStorage.getItem("channelData")
      }
      if (storedBoard) {
          const parsedBoard = JSON.parse(storedBoard);
          const boardOptions = parsedBoard.map((board) => ({
              id: board.id,
              name: board.name,
              label: board.color,
          }));
          setBoardOptions(boardOptions);
      }
  }, []);

  useEffect(() => {
    if (data && data.length > 0 && boardOptions.length > 0) {

      const storedBoard = localStorage.getItem("boardDataItem") || localStorage.getItem("channelData")
      const itemBoardArray = storedBoard !== null ? JSON.parse(storedBoard) : [];
      const boardObject = itemBoardArray.find((board) => board.id === data[0].board_id);

      if (boardObject) {
        const initialBoard = boardOptions.find(option => option.name === boardObject.name);
        setSelectedBoard(initialBoard || null);
      }
    }
  }, [data, boardOptions]);


  const handleBoardChange = async (newValue, fieldName) => {
    if (!data || data.length === 0) return;
    const currentItem = data[0];
    let updatedField = {};

    switch (fieldName) {
      case 'status':
        setSelectedStatus(newValue);
        updatedField = { item_base_status_id: newValue.id };
        break;
      case 'tags':
        // updatedField = { item_label: newValue.map(tag => tag.id).join(',') };
        setSelectedTags(newValue);
        updatedField = {
          item_label: newValue.map(tag => tag.value || tag).join(',')
        };
        break;
      case 'board':
        setSelectedBoard(newValue);
        updatedField = { board_id: newValue.id };
        break;
      case 'priority':
        setSelectedPriority(newValue);
        updatedField = { item_priority: newValue.name };
        break;
      case 'member':
        setSelectedMember(newValue);
        updatedField = { item_assigned_to: newValue.id };
        break;
      case 'sentiment':
        setSelectedSentiment(newValue);
        updatedField = { item_sentiment: newValue.name };
        break
      default:
        console.error('Unknown field:', fieldName);
        return;
    }

    try {
      await _feedback_update_item(currentItem.id, baseId, updatedField);
      toast.success('Item Updated');
      const newUpdateItem = await dispatch(fetchFeedbackItemActivity(parseInt(id, 10) || itemId, baseId));
      setItemStatusActivity(newUpdateItem)
    } catch (error) {
      console.error('Error updating item:', error);
      toast.error('Item Update failed');
    }
  };


  if (loading) return (
    <div className=" flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 0px)' }}>
      <div className="simple-spinner-loader">
        <span></span>
      </div>
    </div>
  );
  if (error) return <div>Error: {error}</div>;
  if (data?.length === 0) return <div>No feedback available</div>;

  const baseMembersDetailsString = localStorage.getItem('baseMembersDetails');
  const baseMembersDetails = baseMembersDetailsString ? JSON.parse(baseMembersDetailsString) : [];



  return (
    <>
      <Toaster richColors />
      <aside className='h-auto flex flex-col justify-stretch min-h-[3rem] 
                    border-b border-l-0 border-r border-solid border-light-gray-200 p-[1.5rem] items-start gap-4'>
        <div className=" flex items-start justify-center w-full">
          <div className="mx-auto w-full">
            <p className="text-center text-wedges-gray text-sm" />
            <div className='flex items-center max-w-full w-fit mb-5 font-[500] leading-[1.2] bg-light-gray-100 rounded-full text-[0.75rem] px-[0.5rem] py-[5px] gap-2'>
              <span className='text-[#52555a] capitalize'>
                {data[0]?.item_type}
              </span>

            </div>
            <div className="mb-2 mx-[-6px]">
              <a
                href="#"
                onClick={toggleCollapse}
                className="h-8 items-center rounded-lg bg-transparent hover:bg-light-gray-100 text-dark-black-200 font-medium text-[0.8125rem] 
                                focus:outline-none px-2 justify-start inline-flex"
              >
                Details
                <div className={`${collapsed ? 'rotate-180' : 'rotate-0'} h-6 w-6 flex-shrink-0 transform transition-transform duration-300`}>
                  <svg
                    width={24}
                    height={24}
                    fill="none"
                    className="h-6 w-6 fill-transparent stroke-current text-wedges-gray-400"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M15.25 10.75 12 14.25l-3.25-3.5"
                    />
                  </svg>
                </div>
              </a>

              <AnimatePresence initial={false}>
                {!collapsed && (
                  <motion.div
                    initial={{ height: 0, overflow: 'hidden', backgroundColor: 'white' }}
                    animate={{ height: 'auto', overflow: 'inherit', backgroundColor: 'white' }}
                    exit={{ height: 0, overflow: 'hidden', backgroundColor: 'white' }}
                    transition={{ height: { duration: 0.5 }, overflow: 'hidden', backgroundColor: 'red' }}
                  // className="overflow-hidden"
                  >
                    <div className="pr-4 pl-[0px]">
                      <div className='w-full'>
                        <div className="w-full flex items-center justify-between lg:justify-start py-1">
                          <div className="mr-2 py-1 px-2 font-normal text-dark-black-200 text-[13px] w-[30%]">
                            Boards
                          </div>


                          <Listbox value={selectedBoard} onChange={(newValue) => handleBoardChange(newValue, 'board')}>
                            {({ open }) => (
                              <div className="relative w-[70%]">
                                <ListboxButton className="relative justify-center flex items-center cursor-pointer capitalize rounded-md bg-white py-1 px-2 text-left text-[12px] text-[#52555a] font-medium bg-transparent border-solid hover:!border-light-gray-200 border border-white">
                                  <div className="flex items-center gap-2">
                                    {selectedBoard ? (
                                      <span
                                        className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-white capitalize bg-[${selectedBoard?.label}]`}
                                      />
                                    ) : (
                                      <span className="flex h-[8px] shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-white capitalize w-0 mr-[-4px]" />
                                    )}
                                    <span className="block truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full  overflow-hidden text-ellipsis text-start font-medium">
                                      {selectedBoard ? selectedBoard?.name : 'Add board'}
                                    </span>
                                  </div>
                                </ListboxButton>
                                <Transition show={open} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                                  <ListboxOptions className="absolute z-10 w-[160px] left-[-50px] mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {boardOptions.map((board) => (
                                      <Listbox.Option
                                        key={board.id}
                                        className={({ active }) =>
                                          classNames(
                                            active ? 'bg-light-gray-100 text-dark-black-100 text-[13px]' : '',
                                            !active ? 'text-dark-black-100 text-[13px]' : '',
                                            'relative cursor-default select-none py-1.5 pl-3 pr-9 text-[13px]'
                                          )
                                        }
                                        value={board}
                                      >
                                        {({ selected }) => (
                                          <div className="flex items-center gap-2">
                                            <span
                                              className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full border-none text-[0.625rem]
                                                             font-medium text-white capitalize bg-[${board?.label}]`}
                                            />
                                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate font-medium text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full  overflow-hidden text-ellipsis text-start')}>
                                              {board?.name}
                                            </span>
                                          </div>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </ListboxOptions>
                                </Transition>
                              </div>
                            )}
                          </Listbox>


                        </div>
                        <div className="w-full flex items-center justify-between lg:justify-start py-1">
                          <div className="mr-2 py-1 px-2 font-normal text-dark-black-200 text-[13px] w-[30%]">
                            Status
                          </div>



                          <Listbox value={selectedStatus} onChange={(newValue) => handleBoardChange(newValue, 'status')}>
                            {({ open }) => (
                              <div className="relative  w-[70%]">
                                <ListboxButton className="relative justify-center flex items-center cursor-pointer rounded-md bg-white py-1 px-2 text-left 
                                                              text-[12px] text-[#52555a] font-medium bg-transparent 
                                                               border-solid hover:!border-light-gray-200 border border-white">
                                  <div className='flex items-center gap-2'>
                                    {selectedStatus ? (
                                      <span
                                        className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-white capitalize ${selectedStatus?.label}`}
                                      />
                                    ) : (
                                      <span className="flex h-[8px] shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-white capitalize w-0 mr-[-4px]" />
                                    )}
                                    <span className="truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full  overflow-hidden text-ellipsis text-start">
                                      {selectedStatus ? selectedStatus?.name : 'Add status'}
                                    </span>
                                  </div>
                                </ListboxButton>

                                <Transition show={open} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                                  <ListboxOptions className="absolute z-10 w-[120px] left-[-50px] mt-1 max-h-60 
                                                       overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {statusOptions.map((status) => (

                                      <Listbox.Option
                                        key={status.id}
                                        className={({ active }) =>
                                          classNames(
                                            active ? 'bg-light-gray-100 text-dark-black-100 text-[13px]' : '',
                                            !active ? 'text-dark-black-100 text-[13px]' : '',
                                            'relative cursor-default select-none py-1.5 pl-3 pr-9 text-[13px]'
                                          )
                                        }
                                        value={status}
                                      >
                                        {({ selected }) => (
                                          <div className="flex items-center gap-2">
                                            <span
                                              className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center
                                                            rounded-full border-none text-[0.625rem] font-medium text-white capitalize ${status.label}`}
                                            />
                                            <span
                                              className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate font-medium text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full  overflow-hidden text-ellipsis text-start')}
                                            >
                                              {status?.name}
                                            </span>
                                          </div>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </ListboxOptions>
                                </Transition>
                              </div>
                            )}
                          </Listbox>
                        </div>


                        <div className="w-full flex items-center justify-between lg:justify-start py-1">
                          <div className="mr-2 py-1 px-2 font-normal text-dark-black-200 text-[13px] w-[30%]">
                            Assign to
                          </div>
                          <Listbox value={selectedMember} onChange={(newValue) => handleBoardChange(newValue, 'member')}>
                            {({ open }) => (
                              <div className="relative  w-[70%]">
                                <ListboxButton className="relative justify-center flex items-center cursor-pointer rounded-md bg-white py-1 px-2 text-left 
                         text-[12px] text-[#52555a] font-medium bg-transparent border-solid 
                         hover:!border-light-gray-200 border border-white">
                                  <div className="flex items-center gap-2">
                                    {selectedMember ? (
                                      selectedMember?.profile_pic ? (
                                        <img
                                          src={`${process.env.REACT_APP_API_BASE_URL}/${selectedMember.profile_pic}`}
                                          alt="Profile"
                                          className="w-5 h-5 object-cover rounded-full"
                                        />
                                      ) : (
                                        <div className="w-[32px] h-5 rounded-full bg-gray-200 flex items-center justify-center text-[10px]">
                                          <span className="text-[7px] font-medium text-gray-700">
                                            {selectedMember.name.charAt(0).toUpperCase()}
                                          </span>
                                        </div>
                                      )
                                    ) : (
                                      <div className=" h-5 rounded-full  flex items-center justify-center text-[10px]">
                                        <span className="text-[7px] font-medium text-gray-700"></span>
                                      </div>
                                    )}

                                    <span className="block truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full overflow-hidden text-ellipsis text-start">
                                      {selectedMember ? selectedMember.name : 'Add Assign to'}
                                    </span>
                                  </div>
                                </ListboxButton>

                                <Transition show={open} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                                  <ListboxOptions className="absolute z-10 w-[150px] left-[-50px] mt-1 max-h-60 
                                                     overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {memberOptions.map((member) => (
                                      <Listbox.Option
                                        key={member.id}
                                        className={({ active }) =>
                                          classNames(
                                            active ? 'bg-light-gray-100 text-dark-black-100 text-[13px]' : '',
                                            !active ? 'text-dark-black-100 text-[13px]' : '',
                                            'relative cursor-default select-none py-1.5 pl-3 pr-3 text-[13px]'
                                          )
                                        }
                                        value={member}
                                      >
                                        {({ selected }) => (
                                          <div className="flex items-center gap-2">
                                           {member ? (
                                      <img
                                        src={
                                          member?.profile_pic
                                            ? `${process.env.REACT_APP_API_BASE_URL}/${member?.profile_pic}`
                                            : ''
                                        }
                                        alt="Profile"
                                        className="w-5 h-5 object-cover rounded-full"
                                      />
                                    ) : (
                                      <div className='w-[32px] h-5 rounded-full bg-gray-200 flex items-center justify-center text-[10px]'>
                                        <span className="text-[7px] font-medium text-gray-700">
                                          {member?.name.charAt(0).toUpperCase()}
                                        </span>
                                      </div>
                                    )}
                                            <span
                                              className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate font-medium text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full  overflow-hidden text-ellipsis text-start')}
                                            >
                                              {member.name}
                                            </span>
                                          </div>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </ListboxOptions>
                                </Transition>
                              </div>
                            )}
                          </Listbox>

                        </div>
                        <div className="w-full flex items-center justify-between lg:justify-start py-1">
                          <div className="mr-2 py-1 px-2 font-normla text-dark-black-200 text-[13px] w-[30%]">
                            Priority
                          </div>
                          <Listbox value={selectedPriority} onChange={(newValue) => handleBoardChange(newValue, 'priority')}>
                            {({ open }) => (
                              <div className="relative w-[70%]">
                                <Listbox.Button className="relative justify-center flex items-center cursor-pointer capitalize rounded-md bg-white py-1 px-2 text-left text-[12px] text-[#52555a] font-medium bg-transparent border-solid hover:!border-light-gray-200 border border-white">
                                  <div className='flex items-center gap-2'>
                                    <span className="block truncate truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px]
                                                   w-full  overflow-hidden text-ellipsis text-start">
                                      {selectedPriority ? selectedPriority?.name : 'Add Priority'}
                                    </span>
                                  </div>
                                </Listbox.Button>

                                <Transition show={open} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                                  <Listbox.Options className="absolute z-10 w-[120px] left-[-50px] mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {priorityOptions.map((pri) => (

                                      <Listbox.Option
                                        key={pri.id}
                                        className={({ active }) => (
                                          `relative cursor-default select-none py-1.5 pl-3 pr-9 text-[13px] ${active ? 'bg-light-gray-100 text-dark-black-100' : 'text-dark-black-100'}`
                                        )}
                                        value={pri}
                                      >
                                        {({ selected }) => (
                                          <div className="flex items-center gap-2">
                                            {/* {selectedPriority &&
                                                            <span className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-white capitalize`}>
                                                              <span className={pri.label} />

                                                            </span>
                                                          } */}
                                            <span className={`block truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full  
                                                          overflow-hidden text-ellipsis text-start ${selected ? 'font-semibold' : 'font-normal'}`}>
                                              {pri.name}
                                            </span>
                                          </div>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            )}
                          </Listbox>
                        </div>
                        <div className="w-full flex items-center justify-between lg:justify-start py-1">
                          <div className="mr-2 py-1 px-2 font-normla text-dark-black-200 text-[13px] w-[30%]">
                            Sentiment
                          </div>
                          <Listbox value={selectedSentiment} onChange={(newValue) => handleBoardChange(newValue, 'sentiment')}>
                            {({ open }) => (
                              <div className="relative w-[70%]">
                                <Listbox.Button className="relative justify-center flex items-center cursor-pointer rounded-md bg-white
                               py-1 px-2 text-left text-[12px] text-[#52555a] font-medium bg-transparent">
                                  <div className='flex items-center gap-2'>
                                  {selectedSentiment ? (
                                      <>
                                        {selectedSentiment.name === 'positive' ? (
                                          <span
                                            className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 h-[24px] text-xs font-medium text-green-700 capitalize"
                                            style={{ borderColor: selectedSentiment?.label, borderWidth: 1, borderStyle: 'solid' }}
                                          >
                                            {selectedSentiment.name}
                                          </span>
                                        ) : selectedSentiment.name === 'negative' ? (
                                          <span
                                            className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 h-[24px] text-xs font-medium text-red-700 capitalize"
                                            style={{ borderColor: selectedSentiment?.label, borderWidth: 1, borderStyle: 'solid' }}
                                          >
                                            {selectedSentiment.name}
                                          </span>
                                        ) : selectedSentiment.name === 'spam' ? (
                                          <span
                                            className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 h-[24px] text-xs font-medium text-gray-600 capitalize"
                                            style={{ borderColor: selectedSentiment?.label, borderWidth: 1, borderStyle: 'solid' }}
                                          >
                                            {selectedSentiment.name}
                                          </span>
                                        ) : selectedSentiment.name === 'mixed' ? (
                                          <span
                                            className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 h-[24px] text-xs font-medium text-blue-700 capitalize"
                                            style={{ borderColor: selectedSentiment?.label, borderWidth: 1, borderStyle: 'solid' }}
                                          >
                                            {selectedSentiment.name}
                                          </span>
                                        ) : (
                                          <span
                                            className="inline-flex items-center rounded-md bg-white px-2 py-1 h-[24px] text-xs font-medium text-black capitalize"
                                            style={{ borderColor: selectedSentiment?.label, borderWidth: 1, borderStyle: 'solid' }}
                                          >
                                            {selectedSentiment.name}
                                          </span>
                                        )}
                                      </>
                                    ) : (
                                      <span className="flex h-[8px] shrink-0 items-center justify-center rounded-full border-none text-[13px] font-medium capitalize mr-[-4px]">
                                        Add Sentiment
                                      </span>
                                    )}

                                  </div>
                                </Listbox.Button>

                                <Transition show={open} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                                  <Listbox.Options className="absolute z-10 w-[120px] left-[-50px] mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {sentimentOptions.map((sen) => (

                                      <Listbox.Option
                                        key={sen.id}
                                        className={({ active }) => (
                                          `relative cursor-default select-none py-1.5 pl-3 pr-9 text-[13px] ${active ? 'bg-light-gray-100 text-dark-black-100' : 'text-dark-black-100'}`
                                        )}
                                        value={sen}
                                      >
                                        {({ selected }) => (
                                          <div className="flex items-center gap-2">
                                            <span
                                              style={{
                                                backgroundColor: sen?.label,
                                              }}
                                              className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full border-none text-[0.625rem]
                                                             font-medium text-white capitalize`}
                                            />
                                            <span className={`block truncate capitalize text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full  
                                                          overflow-hidden text-ellipsis text-start ${selected ? 'font-semibold' : 'font-normal'}`}>
                                              {sen?.name}
                                            </span>
                                          </div>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            )}
                          </Listbox>
                        </div>
                        <div>

                          <div className="w-full flex items-start justify-between lg:justify-start py-1 flex-1">
                            <div className="mr-2 py-1 px-2 font-normal text-dark-black-200 w-[30%] text-[13px]">
                              Label
                            </div>

                            <MultiSelectTags selectedTags={selectedTags}
                              setSelectedTags={setSelectedTags}
                              options={tagsOptions}
                              handleBoardChange={handleBoardChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            <p />
          </div>
        </div>
      </aside>
    </>
  );
};

export default ViewTopicDetails;
