import { useState } from 'react';
import Header from './settingHeader/Header';
import Sidebar from './settingSidebar/SideBar';
import { routes } from './Routes';
import { routesProfile } from './ProfileRoutes';
import { Routes, Route, Outlet } from "react-router-dom";
import BaseChannel from './settingPages/BaseChannel';

const Setting: React.FC = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
    const baseId = baseIdString ? JSON.parse(baseIdString) : null;

    return (
        <div className='overflow-auto h-full'>
            <Sidebar id={baseId} onClickValue={sidebarOpen} onClick={(e: boolean) => setSidebarOpen(e)} />
            <div className='lg:pl-[225px]'>
                <Header id={baseId} onClick={() => setSidebarOpen(true)} />
                <div className='px-5 lg:px-0 w-full h-screen mb-[140px]'>
                <main className='py-0'>
                    <div className='w-full'>
                        <Outlet />
                    </div>
                </main>
                    {/* <Routes>
                        {routes.map(({ layout, pages }) => (
                            layout === "setting" &&
                            pages.map(({ path, component: Component }) => {
                                return (
                                    // <Route key={path} path={`/${baseId}/setting${path}`} element={<Component baseId={baseId} />} />
                                    <></>
                                );
                            })
                        ))}
                        <Route path={`/detail/${baseId}/channel`} element={<BaseChannel baseId={baseId} />} />
                    </Routes>
                    <Routes>
                        {routesProfile.map(({ layout, pages }) => (
                            layout === "profile" &&
                            pages.map(({ path, component: Component }) => {
                                return (
                                    <Route key={path} path={`${path}${baseId}`} element={<Component />} />
                                );
                            })
                        ))}
                    </Routes> */}
                </div>
            </div>
        </div>
    );
};

export default Setting;
