// import React, { ReactNode } from "react";
// import { Button, DropdownMenu } from "@lemonsqueezy/wedges";
// import { Link } from "react-router-dom";


// interface DropdownProps {
//     groups: DropdownGroup[];
//     image?: ReactNode; // Add image prop
//     onDelete?: () => void;  // New prop for delete action

// }

// interface DropdownItem {
//     className?: string;
//     icon?: ReactNode;
//     label: any;
//     shortcut?: any;
//     disabled?: boolean;
//     onClick?: () => void;
//     href?: string; // Add href property with type string
// }

// interface DropdownGroup {
//     items: DropdownItem[];
// }


// export function MenuDropdown({ groups, image }: DropdownProps) {
//     return (
//         <DropdownMenu>
//             <DropdownMenu.Trigger asChild>
//                 <Button
//                     className="text-[#25252d] [&>span]:w-full [&>span]:flex [&>span]:justify-end [&>span]:items-end [&>span]:px-0 font-medium !outline-none focus:outline-none justify-between p-0 bg-transparent hover:bg-wedges-gray-50
//                      w-full rounded-lg inline-flex max-w-full cursor-pointer items-center"
//                 >
//                   {image}
//                 </Button>
//             </DropdownMenu.Trigger>

//             <DropdownMenu.Content className="w-[232px] z-50 relative">
//                 {groups.map((group, groupIndex) => (
//                     <React.Fragment key={groupIndex}>
//                         {groupIndex > 0 && <DropdownMenu.Separator />}
//                         <DropdownMenu.Group>
//                             {group.items.map((item, itemIndex) => (                                
//                                 <DropdownMenu.Item
//                                 onClick={item.onClick}
//                                 className={`pr-6 !pl-4 gap-x-[12px] flex ${item.className}`} key={itemIndex} disabled={item.disabled}>
//                                     {item.icon}

//                                     {item.href ? ( // Check if href exists
//                                         <Link to={item.href} className="w-full">{item.label}</Link>
//                                     ) : (
//                                         <span className="line-clamp-1">{item.label}</span>
//                                     )}
//                                     <DropdownMenu.Shortcut >
//                                         {item.shortcut}
//                                     </DropdownMenu.Shortcut>
//                                 </DropdownMenu.Item>
//                             ))}
//                         </DropdownMenu.Group>
//                     </React.Fragment>
//                 ))}
//             </DropdownMenu.Content>
//         </DropdownMenu>
//     );
// }

// @ts-nocheck


import React, { ReactNode } from "react";
import { Button, DropdownMenu } from "@lemonsqueezy/wedges";
import { Link } from "react-router-dom";

interface DropdownProps {
  groups: DropdownGroup[];
  image?: ReactNode;
  itemId?: number; 
  onDelete?: (id: number) => void;
  style?: React.CSSProperties;

}

interface DropdownItem {
  className?: string;
  icon?: ReactNode;
  label: any;
  shortcut?: any;
  disabled?: boolean;
  onClick?: (id?: number) => void;
  href?: string;
  style?: React.CSSProperties;

}

interface DropdownGroup {
  items: DropdownItem[];
}

export function MenuDropdown({ groups, image, itemId, onDelete, style }: DropdownProps) {
  const   handleItemClick = (item: DropdownItem) => {
    // if (item.onClick && itemId !== undefined) {

    //   item.onClick(itemId);
    // } else if (item.label === "Delete" && onDelete && itemId !== undefined) {
    //   onDelete(itemId);
    // }
    if (item.onClick) {
      if (itemId !== undefined) {
        item.onClick(itemId);
      } else {
        item.onClick();
      }
    } else if (item.label === "Delete" && onDelete && itemId !== undefined) {
      onDelete(itemId);
    }
    
  };

  return (
    <DropdownMenu>
      <DropdownMenu.Trigger asChild>
        <Button

          className="text-[#25252d] [&>span]:w-full [&>span]:flex [&>span]:justify-end [&>span]:items-end [&>span]:px-0 font-medium !outline-none focus:outline-none justify-between p-0 bg-transparent hover:bg-transparent w-full rounded-lg inline-flex max-w-full cursor-pointer items-center"
        >
          {image}
        </Button>
      </DropdownMenu.Trigger>
      {/* <div style={style} className="[&>div]:right-[42%] [&>div]:!absolute"> */}
        <DropdownMenu.Content className="w-[232px] z-50 relative">
          {groups.map((group, groupIndex) => (
            <React.Fragment key={groupIndex}>
              {groupIndex > 0 && <DropdownMenu.Separator />}
              <DropdownMenu.Group>
                {group.items.map((item, itemIndex) => (
                  <DropdownMenu.Item
                    onClick={() => handleItemClick(item)}
                    className={`pr-6 !pl-4 gap-x-[12px] flex ${item.className}`}
                    key={itemIndex}
                    disabled={item.disabled}
                  >
                    {item.icon}

                    {item.href ? (
                      <Link to={item.href} className="w-full">{item.label}</Link>
                    ) : (
                      <span className="truncate text-[14px] leading-4 whitespace-nowrap w-[150px] overflow-hidden text-ellipsis text-start">{item.label}</span>
                    )}
                    <DropdownMenu.Shortcut>
                      <div className="ml-[-24px]">
                      {item.shortcut}
                      </div>
                    </DropdownMenu.Shortcut>
                  </DropdownMenu.Item>
                ))}
              </DropdownMenu.Group>
            </React.Fragment>
          ))}
        </DropdownMenu.Content>
      {/* </div> */}

    </DropdownMenu>
  );
}