
import {
    CLOSE_FORM_ASIDE,
    DELETE_INTEGRATION_RESPONSES_LIST_REQUEST,
    FETCH_ALL_STEPS_INTEGRATION_DETAILS_FAILURE,
    FETCH_ALL_STEPS_INTEGRATION_DETAILS_REQUEST,
    FETCH_ALL_STEPS_INTEGRATION_DETAILS_SUCCESS,
    FETCH_INTEGRATION_COUNT_RESPONSE_FAILURE,
    FETCH_INTEGRATION_COUNT_RESPONSE_REQUEST,
    FETCH_INTEGRATION_COUNT_RESPONSE_SUCCESS,
    FETCH_INTEGRATION_DETAILS_FAILURE, FETCH_INTEGRATION_DETAILS_REQUEST, FETCH_INTEGRATION_DETAILS_SUCCESS, FeedbackState,
    IntegrationActionTypes,
    SELECT_ELEMENT_INTEGRATION_STEP,
    SUBMIT_SURVEY_STEP_INTEGRATION_FAILURE,
    SUBMIT_SURVEY_STEP_INTEGRATION_REQUEST,
    SUBMIT_SURVEY_STEP_INTEGRATION_SUCCESS
} from './integration.types';

export const initialState: FeedbackState = {
    loading: false,
    error: '',
    success: false,
    data: [],
    selectedElementId: null,
    selectedElement: null,
    isFormAsideOpen: false,
    isFormAsideView: false,
    integrationDetails: {
        // Provide default values or leave empty based on your use case
        allActivityItem: [],
        itemId: null,
        base_id: 0,
        item_details: '',
    },
    allIntegrationList: [],
    allIntegrationDetails: {
        allActivityItem: [],
        itemId: null,
        base_id: 0,
        item_details: '',
    },
    allActivityItem: {
        itemsActivities: [],
        totalItemActivity: 0,
        hasNextPage: false,
        pageSize: 10,
        totalPages: 0,
    },
};

const integrationReducer = (state = initialState, action: IntegrationActionTypes): FeedbackState => {
    switch (action.type) {
        case FETCH_INTEGRATION_DETAILS_REQUEST:
        case FETCH_INTEGRATION_COUNT_RESPONSE_REQUEST:
        // case SUBMIT_SURVEY_STEP_INTEGRATION_REQUEST:
        case FETCH_ALL_STEPS_INTEGRATION_DETAILS_REQUEST:
            return { ...state, loading: false, error: '', success: false };
        case DELETE_INTEGRATION_RESPONSES_LIST_REQUEST:
            return {
                ...state,
                loading: false,
            };
        case FETCH_INTEGRATION_DETAILS_SUCCESS:
        case FETCH_INTEGRATION_COUNT_RESPONSE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                success: true,
                data: Array.isArray(action.payload) ? action.payload : [action.payload]
            };

        case SUBMIT_SURVEY_STEP_INTEGRATION_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case FETCH_INTEGRATION_DETAILS_FAILURE:
        case FETCH_INTEGRATION_COUNT_RESPONSE_FAILURE:
        case FETCH_ALL_STEPS_INTEGRATION_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false
            };
        case SUBMIT_SURVEY_STEP_INTEGRATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false
            };
        case SELECT_ELEMENT_INTEGRATION_STEP:
            return {
                ...state,
                selectedElementId: action.payload.id,
                selectedElement: action.payload.element,
                isFormAsideOpen: true,
                isFormAsideView: true,
            };
        case CLOSE_FORM_ASIDE:
            return {
                ...state,
                isFormAsideOpen: false,
                isFormAsideView: false,
            };

        default:
            return state;
    }
};

export default integrationReducer;


