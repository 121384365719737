import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux";
import { userOtp } from "./otpSlice";
import { AppDispatch } from "../../../store/store";
import AlertPopup from "../../../components/AlertPopup"
import Nav from '../../../components/NavBar';
import { Button, Input } from '@lemonsqueezy/wedges';
import FeedbackLogo from "../../../assets/feedback.png"

const Otp = () => {
	const dispatch = useDispatch<AppDispatch>()
	const navigate = useNavigate()
	const [showAlert, setShowAlert] = useState(false)
	const [data, setData] = useState<any>()
	const [disable, setDisable] = useState(false)
	const [token, setToken] = useState<string | null>(null);

	useEffect(() => {
		// Retrieve token from local storage when the component mounts
		const storedToken = localStorage.getItem('token');
		setToken(storedToken);
	}, []);

	const handle_otp = async (e: any) => {
		e.preventDefault();
		dispatch(userOtp({ otp: e.target[0].value, deviceId: "12121212121", deviceName: "WEB", token: token }))
			.then(async (res) => {
				if (res?.payload?.status === 200) {
					await localStorage.setItem("authorization", res?.payload?.data?.token)
					await localStorage.setItem("userName", res?.payload?.data.user?.name)
					await localStorage.setItem("userEmail", res?.payload?.data?.user?.email)
					await localStorage.setItem("userAuth", res?.payload?.data?.user?.two_auth)
					navigate("/base")
				} else {
					setData(res?.payload?.data)
					setShowAlert(true)
				}
			}).catch((err) => {
				console.log("check", err)
			}).finally(() => {
				setDisable(false)
			})
	}

	return (
		<>
			{/* <Nav /> */}
			<div className="flex min-h-full flex-1 flex-col justify-center max-w-[487px] m-auto px-4">
			<div className="sm:mx-auto sm:w-full sm:max-w-md">
				<img src={FeedbackLogo} alt="" className='max-w-[208px] w-full m-auto'/>
				<h2 className="mt-12 text-center font-bold leading-9 text-dark-black-100 text-[24px]">
					OTP
					</h2>
				</div>
				<div className="mt-6 grid grid-cols-1 gap-4">
					<div className="bg-whiteForgot">
						<form className="space-y-6" action="#" onSubmit={(e) => handle_otp(e)}>
							<div>
								<label htmlFor="otp" className="wg-label inline-flex shrink-0 items-center gap-1 text-surface-900 wg-antialiased">
								Enter Otp
								</label>
								<div className="mt-2">
									<Input
										id="otp"
										name="otp"
										type="number"
										required
										className="focus:outline-indigo-600"
									/>
								</div>
							</div>
							<div className='flex justify-end'>
								<Button
									type="submit"
									className=" bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600"
									size="sm" 						
											>
									Submit
								</Button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<AlertPopup data={data} show={showAlert} setShow={() => setShowAlert(false)} />

		</>
	)
}
export default Otp
