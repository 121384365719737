
import { _baseRequest } from "../../services/api";

const API_END_POINT = {
    GETBASE: "/v1/base",
}

export const _getBase = async () => {
    return _baseRequest(API_END_POINT.GETBASE, "GET").then((getResponse) => {
        return getResponse;
    }).catch((error) => {
        // if(error?.data?.status === 401){
        //     alert(error?.data?.message)
        // }
        return error
    });
};

export const create_base = async (name: string) => {
    return _baseRequest(API_END_POINT.GETBASE, "POST", {
        name: name,
    }).then((res) => {
        return res
    }).catch((error) => {
        return error
    });
};


