import { CustomerActionTypes, CustomerState, UPDATE_CUSTOMER_INFO_DATA_FAILURE, UPDATE_CUSTOMER_INFO_DATA_SUCCESS } from "./customer.types";



const initialState: CustomerState = {
    loading: false,
    error: '',
    success: false,
    data: [],
    item: {
        itemsActivities: [],
        totalItemActivity: 0,
        hasNextPage: false,
        pageSize: 10,
        totalPages: 0,
    },
    insightGroups: [],
};


const customerReducer = (state = initialState, action: CustomerActionTypes): CustomerState => {
    switch (action.type) {
        case UPDATE_CUSTOMER_INFO_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                success: true,
                data: [...state.data, action.payload]
            };
        case UPDATE_CUSTOMER_INFO_DATA_FAILURE:
        default:
            return state;
    }
};

export default customerReducer;

