import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import user from '../features/auth/otp/otpSlice';
import Base from "../features/createBase/baseSlice";
import feedbacksReducer from '../features/feedbacks/feedback-redux/feedbacks.reducer';
import BoardReducer from '../features/setting/boardApi/BoardReducer';
import insightReducer from '../features/insights/insight-redux/insight.reducer';
import dashboardReducer from '../features/dashboard/dashboard-redux/dashboard.reducer';
import integrationReducer from '../features/integration/integration-redux/integration.reducer';
import { TypedUseSelectorHook, useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import customerReducer from '../features/customer/customer-redux/customer.reducer';
export const store = configureStore({
  reducer: {
    user: user,
    base: Base,
    Board: BoardReducer,
    feedbacks: feedbacksReducer,
    insights: insightReducer,
    dashboard: dashboardReducer,
    integration: integrationReducer,
    customer: customerReducer

  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;