// @ts-nocheck

import { useState, useEffect, Fragment, useRef, useCallback } from 'react';
import Sidebar from './sidebar/Sidebar';
import Header from './header/Index';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNewChannel } from '../context/NewChannelContext';
import { _getChannel1, _fetchBaseDetails, _fetchItemTagsList, _fetchItemStatusList, _fetchBaseMemberDetails, _fetchUserProfileDetails } from '../setting/settingPages/baseAPI';
import { debounce } from 'lodash';

const Dashboard: React.FC = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState<string>('');
    const [baseMemberDetails, setBaseMemberDetails] = useState<any>(null);
    const [baseDetails, setBaseDetails] = useState<any>(null);
    const { newChannelId, setNewChannelId } = useNewChannel();
    const [UserProfile, setUserProfile] = useState<any>(null)

    const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
    const baseId = baseIdString ? JSON.parse(baseIdString) : null;

    const [channelData, setChannelData] = useState<any>(null);
    const [itemTags, setItemTags] = useState<any>(null);
    const [itemStatus, setItemStatus] = useState<any>(null);

    const dispatch = useDispatch<any>();
    const navigate = useNavigate();
    const location = useLocation();

    const [searchTerm, setSearchTerm] = useState(''); 

    const handleSearchChange = (term: string) => {
        setSearchTerm(term); 
    };


    // Fetch data on mount
    useEffect(() => {
        if (baseId) {
            const fetchData = async () => {
                const storedChannelData = localStorage.getItem('channelData');
                if (storedChannelData) {
                    setChannelData(JSON.parse(storedChannelData));
                } else {
                    const response = await dispatch(_getChannel1(baseId));
                    setChannelData(response.payload);
                }

                const storedItemTags = localStorage.getItem('itemTagsList');
                if (storedItemTags) {
                    setItemTags(JSON.parse(storedItemTags));
                } else {
                    const tagsResponse = await dispatch(_fetchItemTagsList(baseId));
                    setItemTags(tagsResponse.payload);
                }

                const storedItemStatus = localStorage.getItem('itemStatusList');
                if (storedItemStatus) {
                    setItemStatus(JSON.parse(storedItemStatus));
                } else {
                    const statusResponse = await dispatch(_fetchItemStatusList(baseId));
                    setItemStatus(statusResponse.payload);
                }

                // base memeber
                const storedBaseMember = localStorage.getItem('baseMembersDetails');
                if (storedBaseMember) {
                    setBaseMemberDetails(JSON.parse(storedBaseMember));
                } else {
                    try {
                        const baseResponse = await dispatch(_fetchBaseMemberDetails(baseId));
                        setBaseMemberDetails(baseResponse.payload);
                    } catch (error) {
                        console.error("Error fetching base member details:", error);
                    }
                }

                // base 
                const storedBase = localStorage.getItem('baseDetails');
                if (storedBase) {
                    setBaseDetails(JSON.parse(storedBase));
                } else {
                    try {
                        const baseResponse = await dispatch(_fetchBaseDetails(baseId));
                        setBaseDetails(baseResponse.payload);
                    } catch (error) {
                        console.error("Error fetching base member details:", error);
                    }
                }

                // profile 
                const storedUser = localStorage.getItem('userProfileDetails');
                if (storedUser) {
                    setUserProfile(JSON.parse(storedUser));
                } else {
                    try {
                        const userResponse = await dispatch(_fetchUserProfileDetails());
                        setUserProfile(userResponse.payload);
                    } catch (error) {
                        console.error("Error fetching base member details:", error);
                    }
                }
            };
            fetchData();
        }
    }, [baseId, dispatch]);




    useEffect(() => {
        if (channelData) {
            localStorage.setItem('channelData', JSON.stringify(channelData));
        }
        if (itemTags) {
            localStorage.setItem('itemTagsList', JSON.stringify(itemTags));
        }
        if (itemStatus) {
            localStorage.setItem('itemStatusList', JSON.stringify(itemStatus));
        }
        if (baseMemberDetails) {
            localStorage.setItem('baseMembersDetails', JSON.stringify(baseMemberDetails));
        }
        if (baseDetails) {
            localStorage.setItem('baseDetails', JSON.stringify(baseDetails));
        }
        if (UserProfile) {
            localStorage.setItem('userProfileDetails', JSON.stringify(UserProfile));
        }
    }, [channelData, itemTags, itemStatus, baseMemberDetails, baseDetails, UserProfile]);

    const handleTabChange = useCallback(debounce((selectedTabName: string) => {
        setSelectedTab(selectedTabName);
    }, 300), []);


    const handleTabChange1 = (selectedTabName: any) => {
        setSelectedTab(selectedTabName);
    }
    // Callback function to receive newChannelId1 from Sidebar
    const handleNewChannelIdChange = (id: number) => {
        setNewChannelId(id);
    };


    const hasNavigated = useRef(false);

    useEffect(() => {
        const path = location.pathname;
        if (path.includes('/board/') && baseId && !hasNavigated.current) {
            const boardId = path.split('/').pop();
            const desiredPath = `/${baseId}/board/${boardId}`;

            if (path !== desiredPath) {
                hasNavigated.current = true;
                navigate(desiredPath, { replace: true });
            }
        }
    }, [baseId, navigate, location]);



    if (!channelData || !itemTags || !itemStatus || !baseMemberDetails || !baseDetails) {
        return <div></div>;
    }



    return (
        <div className='h-[calc(100vh_-_4rem)]'>
            <Sidebar channelData={channelData} id={baseId} onClickValue={sidebarOpen} onClick={(e: boolean) => setSidebarOpen(e)} onTabChange={handleTabChange}
                onTabChange1={handleTabChange1} onChannelIdChange={handleNewChannelIdChange} />
            <div className='lg:pl-[225px]'>
                <Header id={baseId} onClick={() => setSidebarOpen(true)} selectedTab={selectedTab} baseMemberDetails={baseMemberDetails} onSearchChange={handleSearchChange} />
                <main className='py-0'>
                    <div className='w-full'>
                        <Outlet context={{ searchTerm }} />
                    </div>
                </main>
            </div>
        </div>
    );
};

export default Dashboard;
