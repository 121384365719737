

import { _baseRequest } from "../../../services/api";

const API_END_POINT = {
    RESETPASS: "/v1/auth/reset/password"
}

export const user_resetpass = async (
    code: string,
    password:string
) => {
    return _baseRequest(API_END_POINT.RESETPASS, "POST", {
        code,
        password
    }).then((response) => {
        return response;
    }).catch((error) => {
        return error
    });
};

