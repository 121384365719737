import {
	Bars3Icon,
	ChartPieIcon,
	XMarkIcon,
	UsersIcon,
	IdentificationIcon
} from '@heroicons/react/24/outline'
import BaseDetails from './settingPages/BaseDetails'; 
import BaseBilling from './settingPages/BaseBilling';
import BaseChannel from './settingPages/BaseChannel';
import BaseLog from './settingPages/BaseLog';
import BaseMember from "./settingPages/BaseMemeber"
import FeedbackTags from './settingPages/FeedbackTags';
import FeedbackStatus from './settingPages/FeedbackStatus';

type RouteProps = {
	layout: string;
	pages: {
		icon: React.ForwardRefExoticComponent<Omit<React.SVGProps<SVGSVGElement>, "ref">>;
		name: string;
		path: string;
		id: string;
		component:any;
	}[];
}[]

export const routes: RouteProps = [
	{
		layout: "setting",
		pages: [
			{
				icon: IdentificationIcon,
				name: "Detail",
				path: "/detail",
				id: 'detail',
				component: BaseDetails,
			},{
				icon: UsersIcon,
				name: "Member",
				path: "/member",
				id: 'member',
				component: BaseMember,
			},{
				icon: Bars3Icon,
				name: "Board",
				path: "/board",
				id: 'board',
				component: BaseChannel,
			},
			// {
			// 	icon: XMarkIcon,
			// 	name: "Label",
			// 	path: "/label",
			// 	id: 'label',
			// 	component: FeedbackTags,
			// },
			{
				icon: XMarkIcon,
				name: "Status",
				path: "/status",
				id: 'status',
				component: FeedbackStatus,
			},
			// {
			// 	icon: ChartPieIcon,
			// 	name: "Log",
			// 	path: "/log",
			// 	id: 'log',
			// 	component: BaseLog,
			// },{
			// 	icon: XMarkIcon,
			// 	name: "Billing",
			// 	path: "/billing",
			// 	id: 'billing',
			// 	component: BaseBilling,
			// },
		],
	},
];


