// NewChannelContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';

interface NewChannelContextType {
    newChannelId: number | null;
    setNewChannelId: (id: number | null) => void;
}

const NewChannelContext = createContext<NewChannelContextType | undefined>(undefined);

export const NewChannelProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [newChannelId, setNewChannelId] = useState<number | null>(null);

    return (
        <NewChannelContext.Provider value={{ newChannelId, setNewChannelId }}>
            {children}
        </NewChannelContext.Provider>
    );
};

export const useNewChannel = () => {
    const context = useContext(NewChannelContext);
    if (!context) {
        throw new Error('useNewChannel must be used within a NewChannelProvider');
    }
    return context;
};
