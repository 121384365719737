// @ts-nocheck

import React, { useState, useEffect, useRef } from 'react'
import { formatDistanceStrict } from 'date-fns';
import ViewFeedback from "../../feedbacks/ViewFeedback"
import { _baseRequest } from '../../../services/api';
import { debounce } from 'lodash';
import { SparklesIcon } from '@heroicons/react/24/outline';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { COMMON } from '../../../utils/common';


const InboxDetails = [
  { id: 'all', name: 'All updates' },
  { id: 'assignToMe', name: 'Assign to me' },
];

function Inbox() {
  const [activeTab, setActiveTab] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [allActivityItem, setAllActivityItem] = useState({
    itemsActivities: [],
    totalItemActivity: 0,
    currentPage: 1,
    hasNextPage: false,
    pageSize: 25,
    totalPages: 1
  });
  const [isLoading, setIsLoading] = useState(false);
  const [shouldLoadMore, setShouldLoadMore] = useState(false);

  const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;
  const listRef = useRef(null);
  const handleMouseOver = () => setIsHovered(true);
  const handleMouseOut = () => setIsHovered(false);
  const handleSidebarToggle = () => setIsSidebarOpen(!isSidebarOpen);
  const handleTabClick = (itemId) => setActiveTab(itemId);
  const [selected, setSelected] = useState(InboxDetails[0])
  const loginUserId = localStorage.getItem("userAuthId");
  const { itemsActivities } = allActivityItem;
  const baseMembersDetailsString = localStorage.getItem('baseMembersDetails');
  const baseMembersDetails = baseMembersDetailsString ? JSON.parse(baseMembersDetailsString) : [];



  const handleSelectionChange = (item) => {
    setSelected(item);
    const itemAssignedTo = item.id === 'assign-to-me' ? loginUserId : '';
    loadMoreActivities(itemAssignedTo);
  };



  const loadMoreActivities = async (itemAssignedTo) => {
    if (allActivityItem.hasNextPage && !isLoading && shouldLoadMore) {
      setIsLoading(true);
      try {
        if (selected.name === "Assign to me") {
          fullUrl += `&item_assigned_to=${loginUserId}`;
        }
        const nextPage = allActivityItem.currentPage + 1;
        const fullUrl = `${COMMON.apiBaseUrl()}/v1/item-activity/${baseId}/activityList?page=${nextPage}&pageSize=${allActivityItem.pageSize}`;
        const response = await _baseRequest(fullUrl, 'GET');
        if (response.status === 200 && response.data.itemsActivities && response.data.itemsActivities.length > 0) {
          setAllActivityItem(prevState => ({
            ...response.data,
            itemsActivities: [...prevState.itemsActivities, ...response.data.itemsActivities],
          }));
        } else {
          setAllActivityItem(prevState => ({
            ...prevState,
            hasNextPage: false,
          }));
        }
      } catch (error) {
        console.error("Error fetching more activities:", error);
      } finally {
        setIsLoading(false);
        setShouldLoadMore(false);
      }
    }
  };


  const debouncedHandleScroll = debounce(() => {
    if (listRef.current && allActivityItem.hasNextPage && !isLoading) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      if (scrollHeight - scrollTop <= clientHeight * 1.5) {
        setShouldLoadMore(true);
      }
    }
  }, 200);


  useEffect(() => {
    if (shouldLoadMore) {
      loadMoreActivities();
    }
  }, [shouldLoadMore]);

  useEffect(() => {
    const fetchInitialData = async () => {
      let fullUrl = `${COMMON.apiBaseUrl()}/v1/item-activity/${baseId}/activityList?page=1&pageSize=${allActivityItem.pageSize}`;

      if (selected.name === "Assign to me") {
        fullUrl += `&item_assigned_to=${loginUserId}`;
      }

      try {
        setIsLoading(true);
        const response = await _baseRequest(fullUrl, 'GET');

        if (response.status === 200 && response.data.itemsActivities && response.data.itemsActivities.length > 0) {
          setAllActivityItem(response.data);
          setActiveTab(response.data.itemsActivities[0].item_id);
        } else {
          setAllActivityItem({
            itemsActivities: [],
            totalItemActivity: 0,
            currentPage: 1,
            hasNextPage: false,
            pageSize: 25,
            totalPages: 1
          });
        }
      } catch (error) {
        console.error("Error fetching initial data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (baseId) {
      fetchInitialData();
    }
  }, [baseId, selected]);



  const getMemberDetails = (id, creatorType) => {
    
    const userMember = baseMembersDetails.find(member => member.id == id);    
    switch (creatorType) {
      case 'user':
        return userMember ? { profile_pic: userMember.profile_pic, name: userMember.name } : null;
  
      case 'email':
        return userMember ? userMember.name : '';

      case 'ai':
        return <div className='relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[9px] flex items-center justify-center'><SparklesIcon className='w-3 h-3 text-dark-black-200' /></div>;

      case 'customer':
        return id;

      default:
        return '';
    }
  };

  const getShortFormTimeAgo = (createdAt) => {
    const timeAgo = formatDistanceStrict(new Date(createdAt), new Date(), {
      addSuffix: true,
      includeSeconds: true,
    });

    const shortFormTimeAgo = timeAgo
      .replace(/ hours?/, ' hrs')
      .replace(/ minutes?/, ' mins')
      .replace(/ seconds?/, ' secs')
      .replace(/ days?/, ' days')
      .replace(/ months?/, ' mon')
      .replace(/ years?/, ' yrs');

    const cleanedTimeAgo = shortFormTimeAgo
      .replace(/\sago$/, '')
      .replace(/^about\s/, '');

    return cleanedTimeAgo;
  };



  return (
    <div className="h-screen flex justify-start items-start data-inbox-feedback">
      <div className='flex flex-1 flex-col min-w-0 h-full'>
        <div className='flex  flex-row flex-1 relative overflow-hidden'>
          <div className={`flex flex-col min-h-0 ${isSidebarOpen ? 'mq767:min-w-full mq1023:min-w-[350px] mq3000:min-w-[350px] mq767:max-w-full mq1023:max-w-[350px] mq3000:max-w-[350px]' : 'min-w-0 max-w-0'} transition-all duration-300 ease-in-out`}>

            <header>
              <div className='px-4 flex items-center justify-stretch min-h-[46px] border-b border-light-gray-300 border-solid'>
                <div>
                  <h2 className='font-semibold text-sm mr-4'>Inbox</h2>
                </div>
                <div className='flex flex-1 justify-end'>




                  <Listbox value={selected} onChange={handleSelectionChange}>
                    <div className="relative">
                      <ListboxButton className="relative w-full rounded-md bg-white py-1.5 px-[10px] text-left text-gray-900
                                 focus:outline-none  text-[14px] sm:leading-4 border border-solid border-[#e7e7e8] font-medium cursor-pointer">
                        <div className='flex items-center gap-1.5'>
                          <span className="block truncate">{selected.name}</span>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                          </svg>

                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">

                          </span>
                        </div>
                      </ListboxButton>
                      <ListboxOptions className="absolute z-10 !min-w-[130px] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-sm *:
                       shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none
                      ">
                        {InboxDetails.map((item) => (
                          <ListboxOption
                            key={item.id}
                            value={item}
                            className="group relative cursor-pointer select-none py-[6px] px-4 text-gray-900 data-[focus]:bg-gray-200"
                          >
                            <span className="block truncate font-normal">{item.name}</span>
                          </ListboxOption>
                        ))}
                      </ListboxOptions>
                    </div>
                  </Listbox>


                </div>
              </div>
            </header>

            <div
              ref={listRef}
              className={isHovered ? 'inbox-height overflowing-element hovered flex-1 p-0' : 'inbox-height overflowing-element'}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              onScroll={debouncedHandleScroll}
            >
              <div className='m-auto min-h-full max-w-[80em]'>
                <ul className='py-2 relative max-w-full md:max-w-[340px] lg:max-w-[340px] h-full'>
                  {itemsActivities.map((item, index) => {
                    const cleanedTimeAgo = getShortFormTimeAgo(item.created_at);
                    const getSentimentColor = (sentiment) => {
                      switch (sentiment?.toLowerCase()) {
                        case 'positive':
                          return '#059212';
                        case 'negative':
                          return '#ff3737';
                        case 'spam':
                          return '#FFB22C';
                        case 'mixed':
                          return '#3da5ff';
                        default:
                          return '#a8a196';
                      }
                    };

                    const sentiment = item.item.item_sentiment?.trim(); // Trim whitespace
                    const bgColor = getSentimentColor(sentiment);

                    return (
                      <>

                        <div className='sm:block hidden'>
                          <li key={item.item_id} className='flex flex-row items-center justify-between text-sm p-0'
                            onClick={() => handleTabClick(item.item_id)}
                          >
                            <div
                              className={`flex flex-row items-center justify-between flex-1 cursor-pointer px-2 py-3 hover:bg-[#0000000a] ${item.item_id === activeTab ? 'bg-[#0000000a] text-white' : index === 0 ? 'bg-transparent' : 'bg-transparent'}`}
                            >
                              <div className='px-2 w-[1.5rem] self-start flex flex-col items-center justify-center h-full gap-2'>
                                <div className='h-[20px]'>
                                  <div
                                    className={`mt-[6px] focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm w-[9px] 
                               h-[9px] rounded-full border flex justify-center items-center`}
                                    style={{ backgroundColor: bgColor }}
                                  ></div>
                                </div>
                                <div className="relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[9px] flex items-center justify-center text-gray-700">
                                  <span className="text-[7px] font-medium text-gray-700"></span>

                                  {item.creator_type === 'ai' &&
                                    <div className='relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[9px] flex items-center justify-center'>
                                      <SparklesIcon className='w-3 h-3 text-dark-black-200' />
                                    </div>
                                  }
                                  {item.creator_type === 'user' && (() => {
                                    const memberDetails = getMemberDetails(item.item.updated_by || item.item.created_by, item.creator_type);                                  
                                    if (memberDetails) {
                                      if (memberDetails?.profile_pic) {
                                        return (
                                          <img
                                            src={`${process.env.REACT_APP_API_BASE_URL}/${memberDetails.profile_pic}`}
                                            alt='memberDetails'
                                            className=" rounded-full"
                                          />
                                        );
                                      } else if (memberDetails?.name) {
                                        return (
                                          <div className="avatar-initial">
                                            {memberDetails?.name?.charAt(0).toUpperCase()}
                                          </div>
                                        );
                                      }
                                    }
                                    return null;
                                  })()}




                                  {item.creator_type === 'customer' &&
                                    <>
                                      {typeof getMemberDetails(item.created_by, item.creator_type) === 'string' &&
                                        getMemberDetails(item.created_by, item.creator_type).length > 0 ?
                                        getMemberDetails(item.created_by, item.creator_type).charAt(0).toUpperCase() :
                                        ''}
                                    </>
                                  }

                                  {item.creator_type === 'integration' &&
                                    <div className='relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[9px] flex items-center justify-center'>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                        data-slot="icon"
                                        className="text-[#121217] h-[1.3em] w-[1.3em] shrink-0"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v2.25A2.25 2.25 0 0 0 6 10.5Zm0 9.75h2.25A2.25 2.25 0 0 0 10.5 18v-2.25a2.25 2.25 0 0 0-2.25-2.25H6a2.25 2.25 0 0 0-2.25 2.25V18A2.25 2.25 0 0 0 6 20.25Zm9.75-9.75H18a2.25 2.25 0 0 0 2.25-2.25V6A2.25 2.25 0 0 0 18 3.75h-2.25A2.25 2.25 0 0 0 13.5 6v2.25a2.25 2.25 0 0 0 2.25 2.25Z"
                                        />
                                      </svg>

                                    </div>
                                  }

                                </div>
                              </div>

                              <div className='px-2 flex-1 text-dark-black-100 flex flex-col gap-2'>
                                <div className='flex items-center flex-row gap-[2px]'>
                                  {
                                    item.item.item_refernce_id &&
                                    <p className='line-clamp-1 text-[13px]'>
                                      {item.item.item_refernce_id} -
                                    </p>
                                  }

                                  <p className='overflow-hidden text-ellipsis line-clamp-1 flex-1 text-[13px]'>
                                    {item.item.item_title || item.item.item_details}
                                  </p>

                                </div>
                                <div className='flex items-center flex-row gap-2'>
                                  {/* <span className='rounded-full inline-flex items-center justify-center w-4 h-4'>
                                  <img
                                    src={item.avatarUrl}
                                    alt=''
                                    className='w-full rounded-full h-full object-cover'
                                  />
                                </span> */}


                                  <span className='text-dark-black-200 overflow-hidden text-ellipsis line-clamp-1 flex-1 text-[13px]'>

                                    {item.creator_type === 'ai' &&
                                      <>
                                        {getMemberDetails(item.created_by)} {item.item_activity_text}
                                      </>
                                    }
                                    {item.creator_type === 'user' &&
                                      <>
                                      {item.creator_type === 'user' && (() => {
                                    const memberDetails = getMemberDetails(item.item.updated_by || item.item.created_by, item.creator_type);
                                    
                                    if (memberDetails) {
                                      if (memberDetails?.profile_pic) {
                                        return (
                                          <div className="avatar-initial">
                                       {memberDetails?.name}   {item.item_activity_text}
                                        </div>
                                        );
                                      } else if (memberDetails?.name) {
                                        return (
                                          <div className="avatar-initial">
                                            {item.item_activity_text}
                                          </div>
                                        );
                                      }
                                    }
                                    return null;
                                  })()}

                                      </>
                                    }


                                    {item.creator_type === 'customer' &&
                                      <>
                                        {getMemberDetails(item.created_by, item.creator_type)}
                                        <span className='mr-[3px]'>    {item.item_activity_type === 'comment' && 'commented'}</span>
                                        {item.item_activity_text}
                                      </>
                                    }
                                    {item.creator_type === 'integration' &&
                                      <p className=" ">
                                        Integration   {item.item_activity_text}
                                      </p>
                                    }



                                  </span>
                                  <span dateTime={item.created_at} className='text-dark-black-200 text-[13px] flex-shrink-0 ml-auto'>{cleanedTimeAgo}</span>

                                </div>
                              </div>
                            </div>
                          </li>
                        </div>
                        <div className='sm:hidden block'>
                          <li key={item.id} className='flex flex-row items-center justify-between text-sm p-0'

                            onClick={() => handleTabClick(item.item_id)}
                          >
                            <div
                              className={`flex flex-row items-center justify-between flex-1 cursor-pointer px-2 py-3 hover:bg-[#0000000a] ${index === 0 ? 'bg-light-gray-300' : 'bg-transparent'
                                }`}                                            >
                              <div className='px-2 w-[1.5rem] self-start'>
                                <div
                                  className={`mt-[4px] focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm w-[9px] 
                           h-[9px] rounded-full border flex justify-center items-center`}
                                  style={{ backgroundColor: bgColor }}
                                ></div>
                              </div>

                              <div
                                className={`flex flex-row items-center justify-between flex-1 cursor-pointer px-2 py-3 hover:bg-[#0000000a] ${index === 0 ? 'bg-light-gray-300' : 'bg-transparent'
                                  }`}                                            >
                                <div className='px-2 w-[1.5rem] self-start'>
                                  <div
                                    className={`mt-[6px] focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm w-[9px] 
                               h-[9px] rounded-full border flex justify-center items-center`}
                                    style={{ backgroundColor: bgColor }}
                                  ></div>
                                </div>

                                <div className='px-2 flex-1 text-dark-black-100 flex flex-col gap-[2px]'>
                                  <div className='flex items-center flex-row gap-2'>
                                    {
                                      item.item.item_refernce_id &&
                                      <p className='line-clamp-1 text-[13px]'>
                                        {item.item.item_refernce_id} -
                                      </p>
                                    }
                                    <p className='overflow-hidden text-ellipsis line-clamp-1 flex-1 text-[13px]'>
                                      {item.item.item_title || item.item.item_details}
                                    </p>
                                    <span dateTime={item.created_at} className='text-dark-black-200 text-[13px] flex-shrink-0 ml-auto'>{cleanedTimeAgo}</span>


                                  </div>
                                  <div className='flex items-center flex-row gap-2'>
                                    {/* <span className='rounded-full inline-flex items-center justify-center w-4 h-4'>
                                  <img
                                    src={item.avatarUrl}
                                    alt=''
                                    className='w-full rounded-full h-full object-cover'
                                  />
                                </span> */}

                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </div>

                      </>
                    )
                  })}
                  {isLoading && itemsActivities.hasNextPage &&
                    <div className=" flex items-center justify-center relative z-50 h-[25px]">
                      <div className="simple-spinner-loader">
                        <span></span>
                      </div>
                    </div>
                  }
                  {!isLoading && !itemsActivities.hasNextPage && (
                    <p className="text-center mt-4 text-[12px] text-gray-500">No more results.</p>
                  )}
                </ul>

              </div>



            </div>


          </div>
          <div className='border border-gray-200 w-full border-t-0'>
            {itemsActivities.map((item) => (

              item.item_id === activeTab && (
                <ViewFeedback
                  key={item.item_id}
                  activity={item}
                  item={item.item}
                  isSidebarOpen={isSidebarOpen}
                  setIsSidebarOpen={setIsSidebarOpen}
                  headernone={true}
                  handleSidebarToggle={handleSidebarToggle}
                  itemId={item.item_id}
                />
              )
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Inbox