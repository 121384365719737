// src/components/StatisticsCard.tsx
import React from 'react';

interface StatisticsCardProps {
    title: string;
    value: string;
    percentage: string;
    description: string;
    isPositive: boolean;
}

const StatisticsCard: React.FC<StatisticsCardProps> = ({ title, value, percentage, description, isPositive }) => {
    
    return (
        <div className="px-5 py-4 bg-white dashboard-card-main border-light-gray-200 border-solid border-l flex flex-col items-start inside-card">
            <div className="text-[0.8125rem] font-medium text-dark-black-200 leading-4">{title}</div>

            <div className='flex items-center gap-2 mt-[2px]'>
                <div className="text-2xl font-semibold text-dark-black-100">{value}</div>
                <div className='flex gap-1 items-center'>
                    {
                        isPositive ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="#0ea371" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" focusable="false">
                                <polyline points="22 7 13.5 15.5 8.5 10.5 2 17"></polyline>
                                <polyline points="16 7 22 7 22 13"></polyline>
                            </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" style={{transform : 'rotate(60deg)'}} viewBox="0 0 24 24" fill="none" stroke="#dc4a41" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" focusable="false">
                                <polyline points="22 7 13.5 15.5 8.5 10.5 2 17"></polyline>
                                <polyline points="16 7 22 7 22 13"></polyline>
                            </svg>
                    }

                    <div className={`text-[0.75rem] font-[700] ${isPositive ? 'text-[#0ea371]' : 'text-red-500'}`}>
                        {percentage}%
                    </div>

                </div>
            </div>

            <div className="text-[13px] text-dark-black-200 opacity-80">{description}</div>
        </div>
    );
};

export default StatisticsCard;
