//@ts-nocheck

import { useEffect, useRef, useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import StatisticsCard from "../../../components/StatisticsCard";
import { fetchActivityDashboardList, fetchDashboardListData, fetchInsightDashboardList } from "../dashboard-redux/dashboard.actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { format, subDays, startOfMonth, endOfMonth, startOfDay, endOfDay, isValid, formatDistance, formatDistanceStrict } from 'date-fns';
import { getSentimentColor } from "../../../utils/colorUtils";
import { RectangleStackIcon, RssIcon, SparklesIcon } from "@heroicons/react/24/outline";
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

const sentimentTopic: Sentiment[] = [
  { id: 101, name: 'positive', label: '#16a34a33' },
  { id: 102, name: 'negative', label: '#dc26261a' },
  { id: 103, name: 'request', label: '#1d4ed81a' },
];

localStorage.setItem('sentimentTopic', JSON.stringify(sentimentTopic));

export default function Home() {
  const options = ['Today', 'Yesterday', 'Last 7 days', 'Last 30 days', 'This month', 'Last month', 'Custom'];
  const [activeButton, setActiveButton] = useState('Today');
  const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;
  const [recentInsightList, setRecentInsightList] = useState([])
  const [recentActivityList, setRecentActivityList] = useState([])
  const baseMembersDetailsString = localStorage.getItem('baseMembersDetails');
  const baseMembersDetails = baseMembersDetailsString ? JSON.parse(baseMembersDetailsString) : [];
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [dashboardData, setDashboardData] = useState([])
  const [selectedOption, setSelectedOption] = useState('Today');
  const [dateRange, setDateRange] = useState({
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date())
  });
  const dispatch = useDispatch();
  const { loading, error, data } = useSelector((state: RootState) => state.dashboard);
  const isInitialMount = useRef(true);

  const getDateRange = (option) => {
    const today = new Date();
    switch (option) {
      case 'Today':
        return { startDate: startOfDay(today), endDate: endOfDay(today) };
      case 'Yesterday':
        const yesterday = subDays(today, 1);
        return { startDate: startOfDay(yesterday), endDate: endOfDay(yesterday) };
      case 'Last 7 days':
        return { startDate: startOfDay(subDays(today, 6)), endDate: endOfDay(today) };
      case 'Last 30 days':
        return { startDate: startOfDay(subDays(today, 29)), endDate: endOfDay(today) };
      case 'This month':
        return { startDate: startOfMonth(today), endDate: endOfMonth(today) };
      case 'Last month':
        const lastMonth = subDays(startOfMonth(today), 1);
        return { startDate: startOfMonth(lastMonth), endDate: endOfMonth(lastMonth) };
      default:
        return { startDate: startOfDay(today), endDate: endOfDay(today) };
    }
  };

  const handleSelectChange = (newValue) => {
    setSelectedOption(newValue);
    if (newValue !== 'Custom') {
      setDateRange(getDateRange(newValue));
    }
  };

  const handleDateRangeChange = (newValue) => {
    if (newValue && newValue.startDate && newValue.endDate) {
      setDateRange(newValue);
      setSelectedOption('Custom');
    } else {
      const today = new Date();
      setDateRange({ startDate: today, endDate: today });
      setSelectedOption('Today');
    }
  };

  useEffect(() => {
    if (selectedOption !== 'Custom') {
      setDateRange(getDateRange(selectedOption));
    }
  }, [selectedOption]);

  const getQueryParams = () => {
    const formatDate = (date) => {
      if (date instanceof Date && isValid(date)) {
        return format(date, 'yyyy-MM-dd');
      }
      console.warn('Invalid date:', date);
      return format(new Date(), 'yyyy-MM-dd');
    };

    const fromDate = formatDate(dateRange.startDate);
    const toDate = formatDate(dateRange.endDate);
    return `&fromDate=${fromDate}&toDate=${toDate}`;
  };

  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      const queryParams = getQueryParams();
    }
  }, [dateRange]);


  useEffect(() => {
    const fetchData = async () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
        setActiveButton('Today');
        setDateRange(getDateRange('Today'));
      } else if (dateRange.startDate && dateRange.endDate && baseId) {
        const fromDate = format(dateRange.startDate, 'yyyy-MM-dd');
        const toDate = format(dateRange.endDate, 'yyyy-MM-dd');

        try {
          const response = await dispatch(fetchDashboardListData(baseId, fromDate, toDate));
          setDashboardData(response); // Set the actual resolved data here
        } catch (error) {
          console.error('Failed to fetch dashboard data:', error);
        }
      }
    };

    fetchData();
  }, [dispatch, baseId, dateRange]);



  // recent insight list
  useEffect(() => {
    const fetchRecentInsights = async () => {
      const recentInsight = await dispatch(fetchInsightDashboardList(baseId));
      setRecentInsightList(recentInsight?.item_insight);
    };

    fetchRecentInsights();
  }, [dispatch, baseId]);


  // recent insight list
  useEffect(() => {
    const fetchRecentActivity = async () => {
      const recentActivity = await dispatch(fetchActivityDashboardList(baseId));
      setRecentActivityList(recentActivity.itemsActivities);
    };

    fetchRecentActivity();
  }, [dispatch, baseId]);

  // Function to get member details by ID
  const getMemberDetails = (id, creatorType) => {
    const userMember = baseMembersDetails.find(member => member.id == id);
    switch (creatorType) {
      case 'user':
        return userMember ? { profile_pic: userMember.profile_pic, name: userMember.name } : null;
      case 'ai':
        return <div className='relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[9px] flex items-center justify-center'><SparklesIcon className='w-3 h-3 text-dark-black-200' /></div>;
      case 'customer':
        return id;
      default:
        return '';
    }
  };

  const getShortFormTimeAgo = (createdAt) => {
    const timeAgo = formatDistanceStrict(new Date(createdAt), new Date(), {
      addSuffix: true,
      includeSeconds: true,
    });

    const shortFormTimeAgo = timeAgo
      .replace(/ hours?/, ' hrs')
      .replace(/ minutes?/, ' mins')
      .replace(/ seconds?/, ' secs')
      .replace(/ days?/, ' days')
      .replace(/ months?/, ' mon')
      .replace(/ years?/, ' yrs');

    const cleanedTimeAgo = shortFormTimeAgo
      .replace(/\sago$/, '')
      .replace(/^about\s/, '');

    return cleanedTimeAgo;
  };


  const handleMouseOver = () => setIsHovered(true);
  const handleMouseOut = () => setIsHovered(false);
  const handleMouseOver2 = () => setIsHovered2(true);
  const handleMouseOut2 = () => setIsHovered2(false);

  // if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 56px)' }}>
          <div className="simple-spinner-loader">
            <span></span>
          </div>
        </div>
      ) : (
        <div className="bg-[#f9fafa]">
          <div className="max-w-[83rem] px-0 mq1800:px-8 mx-auto w-full dashboard-card pt-6">
            <div className="bg-transparent">
              <div className="flex items-center space-x-4">
                <Listbox value={selectedOption} onChange={handleSelectChange}>
                  <div className="relative">
                    <Listbox.Button className="relative h-[30px] w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                      <span className="block truncate text-[13px] text-dark-black-100 text-ellipsis leading-4 w-full">{selectedOption}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-4 w-4 fill-transparent stroke-current ">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                        </svg>

                      </span>
                    </Listbox.Button>

                    <Listbox.Options className="absolute !min-w-[130px] !max-w-[150px] z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {options.map((option) => (
                        <Listbox.Option
                          key={option}
                          className={({ active }) =>
                            `relative cursor-default text-[13px] select-none py-2 pl-3 pr-9 ${active ? 'bg-gray-200 text-gray-900' : 'text-gray-900'
                            }`
                          }
                          value={option}
                        >
                          {({ selected, active }) => (
                            <>
                              <span className={`block text-dark-black-100  text-[13px] truncate ${selected ? 'font-semibold' : 'font-normal'}`}>
                                {option}
                              </span>


                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </div>
                </Listbox>
                <div className="relative home-date-picker">
                  <Datepicker
                    value={dateRange}
                    onChange={handleDateRangeChange}
                    className="custom-datepicker"
                    displayFormat="MMM DD, YYYY"
                    readOnly={selectedOption !== 'Custom'}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='bg-[#f9fafa] pt-8  p-8 main-des-section'>
            <div className="max-w-[83rem] mx-auto w-full border-light-gray-200 border-solid border rounded-md dashboard-card">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                <StatisticsCard
                  title="Feedback"
                  value={dashboardData?.feedback?.currentValue}
                  percentage={dashboardData?.feedback?.percentage}
                  description={`vs. ${dashboardData?.feedback?.lastValue} last period`}
                  isPositive={dashboardData?.feedback?.percentage > 0}
                />
                <StatisticsCard
                  title="Positive"
                  value={dashboardData?.insightPositive?.currentValue}
                  percentage={dashboardData?.insightPositive?.percentage}
                  description={`vs. ${dashboardData?.insightPositive?.lastValue} last period`}
                  isPositive={dashboardData?.insightPositive?.percentage > 0}
                />
                <StatisticsCard
                  title="Negative"
                  value={dashboardData?.insightNegative?.currentValue}
                  percentage={dashboardData?.insightNegative?.percentage}
                  description={`vs. ${dashboardData?.insightNegative?.lastValue} last period`}
                  isPositive={dashboardData?.insightNegative?.percentage > 0}
                />
                <StatisticsCard
                  title="Request"
                  value={dashboardData?.insightRequest?.currentValue}
                  percentage={dashboardData?.insightRequest?.percentage}
                  description={`vs. ${dashboardData?.insightRequest?.lastValue} last period`}
                  isPositive={dashboardData?.insightRequest?.percentage > 0}
                />
              </div>
              {/* <div className="bg-white p-4 rounded-lg shadow">
                        <RevenueChart />
                    </div> */}

            </div>
            <div className="max-w-[83rem] mx-auto mt-8 flex w-full gap-8 flex-wrap md:flex-nowrap lg:flex-nowrap">
              <div className="w-full md:w-1/2 lg:w-1/2">
                <div className="bg-white dashboard-card border-light-gray-200 border-solid border rounded-md min-h-full">
                  <h2 className="text-sm leading-3 font-[500] text-dark-black-100 px-5 pt-5 pb-2 mr-1 flex items-center gap-[6px]">
                    <RectangleStackIcon className="w-4 h-4 text-dark-black-100 " />
                    Insight</h2>
                  <div
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                    className={isHovered ? 'inbox-height-des overflowing-element-des hovered rounded-md flex-1 p-0' : 'inbox-height-des overflowing-element-des rounded-md'}
                  >
                    <table className="min-w-[300px] mq1400:max-w-[96%] mq1499:max-w-[96%] mq3000:max-w-[96%]">


                      <tbody className=" bg-white">
                        {recentInsightList && recentInsightList.length > 0 ? (
                          recentInsightList.map((insight) => (
                            <tr key={insight.id}>
                              <td className="py-3 px-5 text-[13px] text-dark-black-100 text-ellipsis leading-4 whitespace-nowrap w-full overflow-hidden mq1300:max-w-[400px] mq1499:max-w-[490px] mq3000:max-w-[574px]">
                                {insight.itemInsight}
                                {
                                  insight?.itemContext &&
                                  <span className="text-[13px] text-gray-500 italic leading-4 block mt-[8px] line-clamp-1 mq1300:max-w-[400px] mq1499:max-w-[490px] mq3000:max-w-[574px] overflow-hidden text-ellipsis">
                                    {insight?.itemContext}
                                  </span>
                                }

                              </td>

                              <td className="whitespace-nowrap pr-0 py-4 text-[13px] text-dark-black-100 text-ellipsis leading-4 capitalize"
                              >
                                {insight.itemContextSentiment === 'positive' ? (
                                  <span
                                    className="inline-flex items-center rounded-md  px-2 py-1 h-[24px] text-xs font-medium text-green-700 capitalize"
                                  >
                                    {insight.itemContextSentiment}
                                  </span>
                                ) : insight.itemContextSentiment === 'negative' ? (
                                  <span
                                    className="inline-flex items-center rounded-md  px-2 py-1 h-[24px] text-xs font-medium text-red-700 capitalize "
                                  >
                                    {insight.itemContextSentiment}
                                  </span>
                                ) : insight.itemContextSentiment === 'request' ? (
                                  <span
                                    className="inline-flex items-center rounded-md  px-2 py-1 h-[24px] text-xs font-medium text-gray-600 capitalize"
                                  >
                                    {insight.itemContextSentiment}
                                  </span>
                                ) : insight.itemContextSentiment === 'mixed' ? (
                                  <span
                                    className="inline-flex items-center rounded-md  px-2 py-1 h-[24px] text-xs font-medium text-blue-700 capitalize"
                                  >
                                    {insight.itemContextSentiment}
                                  </span>
                                ) : null}

                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="3" className="whitespace-nowrap py-4  px-5 text-[13px] text-dark-black-100 text-ellipsis leading-4">No recent insights available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 lg:w-1/2">
                <div className="flow-root bg-white dashboard-card border-light-gray-200 border-solid border rounded-md">
                  <div className="">
                    <h4 className='text-sm leading-3 font-[500] text-dark-black-100 mr-1 flex items-center gap-[6px] p-5'>
                      <RssIcon className="w-4 h-4 text-dark-black-100 " />
                      Activity</h4>
                    <>
                      <div className='m-auto min-h-full max-w-full'>
                        <ul className='py-2  relative max-w-full h-full'
                          onMouseOver={handleMouseOver2}
                          onMouseOut={handleMouseOut2}
                          className={isHovered2 ? 'inbox-height-des overflowing-element-des hovered rounded-md flex-1 px-5' : 'inbox-height-des overflowing-element-des rounded-md px-5'}
                        >
                          {recentActivityList.map((item, index) => {
                            const cleanedTimeAgo = getShortFormTimeAgo(item.created_at);
                            const getSentimentColor = (sentiment) => {
                              switch (sentiment?.toLowerCase()) {
                                case 'positive':
                                  return '#059212';
                                case 'negative':
                                  return '#ff3737';
                                case 'spam':
                                  return '#6b72801a';
                                case 'mixed':
                                  return '#3da5ff';
                                default:
                                  return '#a8a196';
                              }
                            };

                            const sentiment = item.item.item_sentiment?.trim();
                            const bgColor = getSentimentColor(sentiment);

                            return (
                              <>

                                <div className='block'>
                                  <li key={item.item_id} className='flex flex-row items-center justify-between text-sm p-0'
                                  >
                                    <div
                                      className={`flex flex-row items-center justify-between flex-1 cursor-pointer px-0 py-3 
                               ${index === 0 ? 'bg-transparent' : 'bg-transparent'
                                        }`}                                            >
                                      <div className='px-2 w-[1.5rem] self-start flex flex-col items-center justify-center h-full gap-2'>
                                        <div className='h-[20px]'>
                                          <div
                                            className={`mt-[6px] focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm w-[9px] 
                               h-[9px] rounded-full border flex justify-center items-center`}
                                            style={{ backgroundColor: bgColor }}
                                          ></div>
                                        </div>
                                        <div className="relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[9px] flex items-center justify-center">
                                          <span className="text-[7px] font-medium text-gray-700"></span>

                                          {item.creator_type === 'ai' &&
                                            <div className='relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[9px] flex items-center justify-center'>
                                              <SparklesIcon className='w-3 h-3 text-dark-black-200' />
                                            </div>
                                          }
                                          {item.creator_type === 'user' && (() => {
                                            const memberDetails = getMemberDetails(item.item.updated_by || item.item.created_by, item.creator_type);
                                            if (memberDetails) {
                                              if (memberDetails?.profile_pic) {
                                                return (
                                                  <img
                                                    src={`${process.env.REACT_APP_API_BASE_URL}/${memberDetails.profile_pic}`}
                                                    alt='memberDetails'
                                                    className=" rounded-full"
                                                  />
                                                );
                                              } else if (memberDetails?.name) {
                                                return (
                                                  <div className="avatar-initial">
                                                    {memberDetails?.name?.charAt(0).toUpperCase()}
                                                  </div>
                                                );
                                              }
                                            }
                                            return null;
                                          })()}



                                          {item.creator_type === 'customer' &&
                                            <>
                                              {typeof getMemberDetails(item.created_by, item.creator_type) === 'string' &&
                                                getMemberDetails(item.created_by, item.creator_type).length > 0 ?
                                                getMemberDetails(item.created_by, item.creator_type).charAt(0).toUpperCase() :
                                                ''}
                                            </>
                                          }

                                          {item.creator_type === 'integration' &&
                                            <div className='relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[9px] flex items-center justify-center'>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor"
                                                aria-hidden="true"
                                                data-slot="icon"
                                                className="text-[#121217] h-[1.3em] w-[1.3em] shrink-0"
                                              >
                                                <path
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v2.25A2.25 2.25 0 0 0 6 10.5Zm0 9.75h2.25A2.25 2.25 0 0 0 10.5 18v-2.25a2.25 2.25 0 0 0-2.25-2.25H6a2.25 2.25 0 0 0-2.25 2.25V18A2.25 2.25 0 0 0 6 20.25Zm9.75-9.75H18a2.25 2.25 0 0 0 2.25-2.25V6A2.25 2.25 0 0 0 18 3.75h-2.25A2.25 2.25 0 0 0 13.5 6v2.25a2.25 2.25 0 0 0 2.25 2.25Z"
                                                />
                                              </svg>

                                            </div>
                                          }
                                        </div>
                                      </div>

                                      <div className='pl-2 flex-1 text-dark-black-100 flex flex-col gap-2'>
                                        <div className='flex items-center flex-row gap-[2px]'>
                                          {
                                            item.item.item_refernce_id &&
                                            <p className='line-clamp-1 text-[13px]'>
                                              {item.item.item_refernce_id} -
                                            </p>
                                          }

                                          <p className='overflow-hidden text-ellipsis line-clamp-1 flex-1 text-[13px]'>
                                            {item.item.item_title || item.item.item_details}
                                          </p>

                                        </div>
                                        <div className='flex items-center flex-row gap-2'>



                                          <span className='text-dark-black-200 overflow-hidden text-ellipsis line-clamp-1 flex-1 text-[13px]'>

                                            {item.creator_type === 'ai' &&
                                              <>
                                                {getMemberDetails(item.created_by)} {item.item_activity_text}
                                              </>
                                            }
                                            {item.creator_type === 'user' &&
                                              <>
                                                {item.creator_type === 'user' && (() => {
                                                  const memberDetails = getMemberDetails(item.item.updated_by || item.item.created_by, item.creator_type);

                                                  if (memberDetails) {
                                                    if (memberDetails?.profile_pic) {
                                                      return (
                                                        <div className="avatar-initial">
                                                          {memberDetails?.name}   {item.item_activity_text}
                                                        </div>
                                                      );
                                                    } else if (memberDetails?.name) {
                                                      return (
                                                        <div className="avatar-initial">
                                                          {item.item_activity_text}
                                                        </div>
                                                      );
                                                    }
                                                  }
                                                  return null;
                                                })()}

                                              </>
                                            }


                                            {item.creator_type === 'customer' &&
                                              <>
                                                {getMemberDetails(item.created_by, item.creator_type)}
                                                <span className='mr-[3px]'>    {item.item_activity_type === 'comment' && 'commented'}</span>
                                                {item.item_activity_text}
                                              </>
                                            }

                                            {item.creator_type === 'integration' &&
                                              <p className=" ">
                                                Integration   {item.item_activity_text}
                                              </p>
                                            }


                                          </span>
                                          <span dateTime={item.created_at} className='text-dark-black-200 text-[13px] flex-shrink-0 ml-auto'>{cleanedTimeAgo}</span>

                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </div>


                              </>
                            )
                          })}

                        </ul>

                      </div>
                    </>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      )
      }
    </>
  );
}
