import { Fragment, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

type DropDownProps = {
    selectValue?: string,
    disable?: string
    data: {
        name: string
    }[],
    previousValue?: string,
    onChangeValue: (val: string) => void
}

export default function ChannelDropDown({ data, disable, onChangeValue, previousValue }: DropDownProps) {
    const [value, setValue] = useState(previousValue ? previousValue : "")
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="inline-flex items-center  justify-center gap-x-1.5 mt-1 font-semibold text-gray-900">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                    </svg>
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-[100px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        {data && (<>
                            {data.map((item: { name: string }, index) => {
                                return (
                                    <Menu.Item key={index}>
                                        {({ active }) => (
                                            <div
                                                onClick={() => {
                                                    setValue(item.name);
                                                    onChangeValue(item.name);
                                                }}
                                                className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 capitalize text-sm cursor-pointer'
                                                )}
                                            >
                                                {item.name === "suspended" ? "Delete" : item.name}
                                            </div>
                                        )}
                                    </Menu.Item>
                                )
                            })}

                        </>)}

                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}
