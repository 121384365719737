// @ts-nocheck
import React, { useState } from 'react';
import { _updateItemByAiCheck } from '../setting/settingPages/baseAPI';
import { Feedback } from './type';
import { fetchFeedbackData, fetchItemInsightData } from './feedback-redux/feedbacks.actions';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../hooks/hooks';
import { RootState } from '../../store/store';
import { Toaster, toast } from 'sonner';
import { SparklesIcon } from '@heroicons/react/24/outline';


interface ViewFeedbackProps {
    item?: {
        id: number;
        name: string;
        daysAgo: string;
        createdBy: string;
        avatarUrl: string;
        description: string;
        statusColor: string;
    };
    isSidebarOpen?: boolean;
    setIsSidebarOpen?: (open: boolean) => void;
    headernone?: boolean;
    handleSidebarToggle?: () => void;
    onItemInsightFetched: (insightData: any) => void;

}


const ViewFeedbackHeader: React.FC<ViewFeedbackProps> = ({ activity, item, isAsideOpen, setIsAsideOpen, headernone, handleSidebarToggle, itemId, onItemInsightFetched }) => {
    const [selectedTab, setSelectedTab] = useState<string>('');
    const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
    const baseId = baseIdString ? JSON.parse(baseIdString) : null;
    const [loadingState, setLoadingState] = useState(false)
    const { data, loading, error } = useSelector((state: RootState) => state.feedbacks);
    const dispatch = useAppDispatch();


    const handleApiByAiCheck = async () => {
        setLoadingState(true);
        try {
            const response = await _updateItemByAiCheck(Number(data[0]?.id), Number(baseId));

            if (response && response.status === 200) {
                toast.success('AI Check successful');

                if (response.data) {
                    const { id, base_id } = response.data;
                    await dispatch(fetchFeedbackData(parseInt(id, 10), base_id));
                    const insightData = await dispatch(fetchItemInsightData(base_id, parseInt(id, 10)));
                    onItemInsightFetched(insightData);
                }
            }
        } catch (error) {
            console.error('Error updating item tag:', error);
            toast.error('Failed to update item tag.');
        } finally {
            setLoadingState(false);
        }
    }


    if (loading) return (
        <div className=" flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 0px)' }}>
            <div className="simple-spinner-loader">
                <span></span>
            </div>
        </div>
    );
    if (error) return <div>Error: {error}</div>;
    if (data?.length === 0) return <div>No feedback available</div>;



    return (
        <>
            <Toaster richColors />
            <div className='flex items-center justify-between w-full'>
                <div className='flex items-center gap-2'>
                    <h2 className='text-black-100 font-medium text-sm'>{selectedTab ? selectedTab : `${data[0]?.item_refernce_id}`}</h2>
                    <button className='md:hidden sidebar-inbox-mobile' onClick={handleSidebarToggle}>
                        {`>`}
                    </button>
                </div>
                <div className='flex justify-between items-center gap-2'>
                    <button
                        onClick={handleApiByAiCheck}
                        style={{ transitionProperty: 'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform' }}
                        className='rounded-md  duration-200 font-[600] text-[0.8125rem] h-[32px] w-[32px] flex items-center justify-center border border-solid border-[#e7e7e8]'>
                        {
                            loadingState ?
                                <div className=" flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 0px)' }}>
                                    <div className="simple-spinner-loader [&>span]:w-[18px] [&>span]:h-[18px]">
                                        <span></span>
                                    </div>
                                </div>

                                : <SparklesIcon className='w-[1.1em] h-[1.1em] text-[#000]' />
                        }
                    </button>
                    <button
                        onClick={() => setIsAsideOpen(!isAsideOpen)}
                        style={{ transitionProperty: 'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform' }}
                        className='rounded-md  duration-200 font-[600] text-[0.8125rem] h-[32px] w-[32px] flex items-center justify-center border border-solid border-[#e7e7e8]'>
                        <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" aria-hidden="true" focusable="false"
                            height="1.1em" width="1.1em" xmlns="http://www.w3.org/2000/svg">
                            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="9" y1="3" x2="9" y2="21"></line></svg>
                    </button>

                </div>
            </div>

        </>
    );
};

export default ViewFeedbackHeader;
