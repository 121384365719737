
import React, { useEffect, useState } from 'react';
import { fetchAllStepsIntergrationDetails, fetchIntegrationCountResponse } from './integration-redux/integration.actions';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { v4 as uuidv4 } from 'uuid';
import { FormElement } from './integration-steps/types';
import { Dialog, DialogPanel, Label, Transition } from '@headlessui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { TagIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline'


function RatingResponse() {
  const { data, loading, error } = useAppSelector(state => state.integration);
  const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;
  const dispatch = useAppDispatch();
  const [formSteps, setFormSteps] = useState<FormElement[]>([]);
  const [quickViewMode, setQuickViewMode] = useState(false)
  const [integrationResponse, setIntegrationResponse] = useState<{
    responseCounts: { response: string; count: string; percentage: number }[];
    overallTopicCount: number;
  } | null>(null);
  const [selectedStep, setSelectedStep] = useState<FormElement | null>(null);


  useEffect(() => {
    const fetchSteps = async () => {
      if (data[0]?.id && baseId) {
        try {
          const response = await dispatch(fetchAllStepsIntergrationDetails(baseId, data[0].id, '?ratings=true'));

          if (Array.isArray(response)) {
            const formattedElements = response.map(step => ({
              ...step,
              id: step.id || uuidv4(),
              type: step.step_input_type,
              headerText: step.step_title,
              required: step.step_mandatory,
              options: JSON.parse(step.step_input_options || '{}')
            }));
            setFormSteps(formattedElements);
          } else {
            console.log("Response is not an array:", response);
          }
        } catch (error) {
          console.error("Failed to fetch integration steps:", error);
        }
      }
    };
    fetchSteps();
  }, [dispatch, data[0]?.id, baseId]);


  const handleResponse = async (step: any) => {
    try {
      const response = await dispatch(fetchIntegrationCountResponse(baseId, step.id, step.integration_id));
      setSelectedStep(step)
      setIntegrationResponse(response)
      setQuickViewMode(true);

    } catch (error) {
      console.error('Failed to fetch integration count response:', error);
    }
  };


  const renderFormElement = (element: FormElement) => {
    if (!element.options || !Array.isArray(element.options)) return null;

    switch (element.type) {
      case 'likert':
        return (
          <div className="flex gap-2 justify-start flex-wrap items-start">
            {element?.options.map((option) => (
              <div key={option.id} className="flex items-center space-x-2 relative group">
                <div className="py-2 px-4 text-[13px] relative w-[100px] h-[50px] text-center flex justify-center items-center leading-4 font-normal border rounded-lg bg-white text-dark-black-200 border-gray-300 hover:bg-gray-100" style={{ overflowWrap: "anywhere" }}>
                  {option.label}
                </div>
              </div>
            ))}
          </div>
        );
      case 'numberscale':
        return (
          <div className="flex justify-start gap-2 flex-wrap items-start">
            {element?.options.map((option) => (
              <div key={option.id} className="flex items-center space-x-2 relative group">
                <div className="py-2 px-4 text-[13px] rounded-lg relative w-[50px] h-[50px] flex items-center justify-center text-center leading-4 font-normal border bg-white text-dark-black-200 border-gray-300 hover:bg-gray-100">
                  {option.label}
                </div>
              </div>
            ))}
          </div>
        );
      case 'star':
        return (
          <div className="flex justify-start items-start">
            <div className="flex items-center gap-2 flex-wrap lg:flex-nowrap">
              {[1, 2, 3, 4, 5].map((star) => (
                <span key={star} style={{ color: "rgb(228, 229, 233)", fontSize: 24 }}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-10 md:size-14 lg:size-16">
                    <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
                  </svg>
                </span>
              ))}
            </div>
          </div>
        );
      case 'smiley':
        return (
          <div className="w-full flex items-center gap-2">
            {element?.options.map((option) => (
              <div key={option.id} className="flex gap-x-2 flex-col items-center">
                <div className="py-2 px-4 text-[13px] rounded-lg w-[50px] md:w-[74px] lg:w-[74px] h-[50px] md:h-[74px] lg:h-[74px] flex items-center justify-center text-center leading-4 font-normal border border-gray-300 bg-white" role="button" aria-pressed="false">
                  <span className="text-[1.5rem] md:text-[2.5rem] lg:text-[2.8rem]">
                    {option.type === 'sad' ? '😞' : option.type === 'ok' ? '😐' : '😊'}
                  </span>
                </div>
              </div>
            ))}
          </div>
        );
      case 'thumbsup':
        return (
          <div className="w-full flex items-center gap-2">
            {element?.options.map((option) => (
              <div key={option.id} className="py-2 px-4 text-[13px] rounded-lg w-[50px] md:w-[74px] lg:w-[74px] h-[50px] md:h-[74px] lg:h-[74px] flex items-center justify-center text-center leading-4 font-normal border border-gray-300 bg-white" role="button" aria-pressed="false">
                <span className="text-[1.5rem] md:text-[2.5rem] lg:text-[2.8rem]">
                  {option.type === 'thumbsup' ? '👍' : '👎'}
                </span>
              </div>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="mb-4 mt-5 lg:mt-[55px] pb-20 new-feedbackoverflow mq1023:max-w-full mq1800:max-w-[100%] mq3000:max-w-[100%] flex-1 w-full px-5 lg:px-[60px] overflow-y-auto view-feedback-data overflowing-element">
        <div className="max-w-full md:max-w-[710px] lg:max-w-[710px] m-auto">
          <ul>
            {formSteps.map((step, index) => (
              <li key={step.id} className="relative flex gap-x-4 mt-4 cursor-pointer" onClick={() => handleResponse(step)}>
                <div className="relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[10px] flex items-center justify-center">
                  <span className="text-[11px] font-medium text-gray-700">{step?.step_sequence}</span>
                </div>
                <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 feedback-delete-hover">
                  <div className="block items-start">
                    <div className="w-full flex flex-col gap-1">
                      <div className="flex items-center">
                        <div className="flex-1 p-0">
                          <div className="px-1">
                            <div className="flex flex-col gap-2 p-2 relative">
                              <div className="flex items-start justify-between">
                                <div className="flex flex-col gap-y-2 w-full">
                                  <div className="flex items-center gap-x-1">
                                    <span className="font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2 text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0">
                                      {step.type}
                                    </span>
                                  </div>
                                  <div className="w-[94%]">
                                    <div className="font-[500] text-[#121217] text-[14px]">
                                      {step.headerText}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {renderFormElement(step)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>





      <Dialog open={quickViewMode} onClose={setQuickViewMode} className="relative z-10">
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <DialogPanel
                className="pointer-events-auto w-screen max-w-[30rem] transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
              >
                <div className="flex h-full flex-col  bg-white py-0 shadow-xl">
                  <div className="px-0">
                    <div className="flex items-start justify-between flex-col">
                      <aside className='flex flex-col h-screen w-full justify-stretch min-h-[3rem] border-b border-t-0 border-l-0 border-r 
                      border-solid border-light-gray-200 items-start gap-4  pb-[40px]'>
                        <div className=' flex h-[46px] shrink-0 items-center gap-x-4 bg-white shadow-custom-1 w-full'>
                          <div className='px-[1.5rem] flex items-center w-full gap-2 justify-between'>
                            <p className='text-black-100 font-medium text-sm capitalize'>
                             {selectedStep?.type}
                            </p>

                            <button
                              onClick={() => setQuickViewMode(false)}
                              style={{ transitionProperty: 'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform' }}
                              className='rounded-md  duration-200 font-[600] text-[0.8125rem] h-[32px] w-[32px] flex items-center justify-center border border-solid border-[#e7e7e8]'>

                              <XMarkIcon className='w-[1.1em] h-[1.1em] text-[#000]' />
                            </button>
                          </div>

                        </div>
                        <div className='px-[1.5rem] w-full'>
                          <p className='border-none outline-none bg-transparent focus:outline-none focus:ring-0 text-[20px] leading-6  font-medium 
                        pb-2 text-dark-black-100 w-full p-0 m-0 placeholder:text-[#9e9ea0]'>
                            {selectedStep?.headerText}
                          </p>
                          {integrationResponse?.overallTopicCount && (
                            <p className='mt-3'>
                              Totol Count :   <span className='text-gray-900 text-[13px] capitalize'>{integrationResponse?.overallTopicCount}</span>
                            </p>
                           
                          )
                             
                          }
                          <div className="flex-auto flex flex-col gap-y-4 cursor-pointer w-full mt-5">
                            {integrationResponse?.responseCounts.map((item, index) => (
                              <div className="flex gap-x-2 justify-start items-start w-full" key={index}>
                                <div className="ring-1  flex flex-col gap-y-1 ring-inset ring-gray-200 feedback-delete-hover w-full rounded-md p-4">
                                  <p className='text-sm  flex gap-x-1 text-dark-black-200'>Response :
                                    <span className='text-gray-900 text-[13px] capitalize'>{item.response}</span>
                                  </p>
                                  <p className='text-sm  flex gap-x-1 text-dark-black-200'>Count :
                                    <span className='text-gray-900 text-[13px] capitalize'>{item.count}</span>
                                  </p>
                                  <p className='text-sm  flex gap-x-1 text-dark-black-200'>Percentage :
                                    <span className='text-gray-900 text-[13px] capitalize'>{item.percentage}%</span>
                                  </p>
                                </div>
                              </div>
                            ))}

                          </div>
                        </div>

                      </aside>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default RatingResponse;