import { createAsyncThunk, createSlice, isRejectedWithValue } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import * as api from './otpAPI'
import { RootState } from '../../../store/store'
import { UserOtp } from '../../../reducer/type'

export interface AuthState {
	loading: boolean;
	userInfo: UserOtp | null;
}

const initialState: AuthState = {
	loading: false,
	userInfo: null
}

export const userOtp = createAsyncThunk('user/otp', async (options: UserOtp, { rejectWithValue }) => {
	try {
		return await api.user_otp(options.token, options.otp, options.deviceId, options.deviceName)
	} catch (err) {
		return rejectWithValue(err)
	}
})

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(userOtp.pending, (state) => {
			state.loading = true
		})
		builder.addCase(userOtp.fulfilled, (state, action) => {
			state.userInfo = action.payload
			state.loading = false
		})
		builder.addCase(userOtp.rejected, (state, action) => {
			state.loading = false
		})

		// when purging reset back to the initial state
		// builder.addCase(PURGE, () => initialState);

		// matcher which matches any rejected (with value) action to catch
		// unauthorized errors and trigger a logout
		builder.addMatcher(isRejectedWithValue(), (state, action: any) => {
			// if a user is logged in and the action payload has a status
			// which is 401 (unauthorized), trigger a logout
			if (state.userInfo !== null && action.payload && action.payload.status && action.payload.status === 401) {
				// useImmediate so that the logout logic is not triggered
				// from within the reducer (which will disable us from
				// using getState, something we need to do when purging)
				// setImmediate(logoutAction);
			}
		})
	}
})

export default userSlice.reducer

interface UserSelectorsType {
    loading: boolean;
    userInfo: UserOtp | null;

}

export const UserSelectors = (): UserSelectorsType => {
    const loading = useSelector((state: RootState) => state.user.loading);
    const userInfo = useSelector((state: RootState) => state.user.userInfo);
    return {
        loading,
        userInfo,
    };
};
