
import { createAsyncThunk } from "@reduxjs/toolkit";
import { _baseRequest, fetchBaseDetails1, fetchbaseMemberDetails, fetchChannel, fetchItemStatusList, fetchItemTagsList, fetchUserProfileDetails } from "../../../services/api";
import { COMMON } from "../../../utils/common";
import { baseInfoDataFailure, baseInfoDataRequest, baseInfoDataSuccess } from "../../createBase/baseSlice";
import { boolean } from "yargs";
import axios from "axios";

const API_END_POINT = {
    GETBASEDETAILS: "/v1/base/{0}/baseDetail",
    ADDBASE: "/v1/base/{0}/invite",
    GETBASESETTINGMEMBER: "/v1/base/{0}/settings/members",
    GETBASEINVITATIONS: "/v1/base/{0}/invitations",
    CREATECHANNEL: "/v1/board/{0}/addBoard",
    GETCHANNELLIST: "/v1/board/{0}/Boards",
    GETCHANNELBYID: "/v1/board/{0}/{1}/boardDetail",
    CHANNELUPDATE: "/v1/channel/{0}/status",
    GETCHANNELDATA: "/v1/board/{0}/Boards",
    CHANNELMEMBERUPDATE: "/v1/channel/{0}/member/{1}",
    BASEINVITEMEMBER: "/v1/base/invite",
    BASEINVITEACCEPT: "/v1/base/invite/{0}",
    BASEINVITEREJECT: "/v1/base/invite/{0}",
    BASEPROJECTUPDATE: "/v1/board/update/{0}",
    CREATEITEMSTAGS: "/v1/item_tags/addTag",
    GETITEAMSTAGSLIST: "/v1/item_tags/{0}/tagList",
    DELETEITEMTAGS: "/v1/item_tags/{0}/{1}/deleteTag",
    GETITEMTAGBYID: "/v1/item_tags/{0}/{1}/tagDetail",
    UPDATEITEMTAGBYID: "/v1/item_tags/{0}/{1}/updateTag",
    CREATEITEMSSTATUS: "/v1/item_base_status/{0}/addBaseStatus",
    GETITEAMSSTATUSLIST: "/v1/item_base_status/{0}/baseStatusList",
    DELETEITEMSTATUS: "/v1/item_base_status/{0}/{1}/deleteBaseStatus",
    GETITEMSTATUSBYID: "/v1/item_base_status/{0}/{1}/baseStatusDetail",
    UPDATEITEMSTATUSBYID: "/v1/item_base_status/{0}/{1}/updateBaseStatus",
    PUTITEMFEEDBACK: "/v1/item/{0}/{1}/update",
    GETITEMFEEDBACKLISTDATA: "/v1/item/list/{0}/items",
    PATCHITEMFEEDBACKTOPICSEARCH: "/v1/item/search/{0}/items?item_type=ticket",
    UPDATEITEMBYAICHECK: "/v1/item/{0}/{1}/ai/check",
    UPDATEBASESTATUSORDER: "/v1/item_base_status/{0}/updateOrder",
    UPLOADBASELOGO: "/v1/base/{0}/logo",
    GETBASEDETAILSUPDATE: "/v1/base/{0}"
}


export const _getBaseDetails = async (value: number) => {
    return _baseRequest(COMMON.stringFormat(API_END_POINT.GETBASEDETAILS, value), "GET").then((response) => {
        return response;
    }).catch((error) => {
        return error
    });
};

export const _base_update = async (
    name: string,
    // timezone: string,
    description: string,
    id: number
) => {
    return _baseRequest(COMMON.stringFormat(API_END_POINT.GETBASEDETAILSUPDATE, id), "PUT", {
        name: name,
        // timezone: timezone,
        description : description
    }).then((res) => {
        return res
    }).catch((error) => {
        return error
    });
};

export const add_base = async (
    email: string,
    role: string,
    id: number
) => {
    return _baseRequest(COMMON.stringFormat(API_END_POINT.ADDBASE, id), "POST", {
        email,
        role
    }).then((res) => {
        return res
    }).catch((error) => {
        return error
    });
};

export const _getBaseSettingMember = async (value: number) => {
    return _baseRequest(COMMON.stringFormat(API_END_POINT.GETBASESETTINGMEMBER, value), "GET").then((response) => {
        return response;
    }).catch((error) => {
        return error
    });
};

export const _getBaseInvitations = async (value: number) => {
    return _baseRequest(COMMON.stringFormat(API_END_POINT.GETBASEINVITATIONS, value), "GET").then((response) => {
        return response;
    }).catch((error) => {
        return error
    });
};

export const _createChannel = async (
    value: number,
    name: string,
    description: string,
    privacy: string,
    color: string,
    boardStatus: string,
    selectRoleName: string // Added parameter
) => {
    const colorValue = color.substring(color.indexOf("[#") + 1, color.indexOf("]"));
    return _baseRequest(COMMON.stringFormat(API_END_POINT.CREATECHANNEL, value), "POST", {
        base_id: value,
        name: name,
        description: description,
        color: colorValue,
        privacy: privacy,
        board_status: boardStatus,
        board_view: selectRoleName // Add this to the request payload

    }).then((response) => {
        return response;
    }).catch((error) => {
        return error
    });
};

export const _getChannel = async (value: number) => {
    return _baseRequest(COMMON.stringFormat(API_END_POINT.GETCHANNELLIST, value), "GET").then((response) => {
        return response;
    }).catch((error) => {
        return error
    });
};


export const _getChannelDelete = async (id: number, baseId: number) => {
    const url = `/v1/board/${id}/${baseId}/deleteBoard`;
    return _baseRequest(url, "DELETE").then((response) => {
        return response;
    }).catch((error) => {
        return error;
    });
};

export const _get_ChannelData = async (
    id: number
) => {
    return _baseRequest(COMMON.stringFormat(API_END_POINT.GETCHANNELDATA, id), "GET", {
    }).then((res) => {
        return res
    }).catch((error) => {
        return error
    });
};

export const _channel_update = async (
    status: string,
    id: number
) => {
    return _baseRequest(COMMON.stringFormat(API_END_POINT.CHANNELUPDATE, id), "PUT", {
        status
    }).then((res) => {
        return res
    }).catch((error) => {
        return error
    });
};

export const channel_member_update = async (
    role: string,
    channelId: number,
    memberId: number,
) => {
    return _baseRequest(COMMON.stringFormat(API_END_POINT.CHANNELMEMBERUPDATE, channelId, memberId), "PUT", {
        role
    }).then((res) => {
        return res
    }).catch((error) => {
        return error
    });
};



export const _base_invite = async () => { // Remove the argument from here
    return _baseRequest(COMMON.stringFormat(API_END_POINT.BASEINVITEMEMBER), "GET")
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const _base_invite_accept = async (baseId: number) => {
    return _baseRequest(COMMON.stringFormat(API_END_POINT.BASEINVITEACCEPT, baseId), "PUT")
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const _getChannelById = async (value: number) => {
    return _baseRequest(COMMON.stringFormat(API_END_POINT.GETCHANNELBYID, value), "GET").then((response) => {
        return response;
    }).catch((error) => {
        return error
    });
};


export const _updateChannel = async (
    id: number,
    value: number,
    name: string,
    description: string,
    privacy: string,
    color: string,
    boardStatus: string,
    selectRoleName: string // Added parameter
) => {
    const colorValue = color.substring(color.indexOf("[#") + 1, color.indexOf("]")) || color;
    return _baseRequest(COMMON.stringFormat(API_END_POINT.BASEPROJECTUPDATE, id), "PUT", {
        base_id: value,
        name: name,
        description: description,
        color: colorValue,
        privacy: privacy,
        board_status: boardStatus,
        board_view: selectRoleName // Add this to the request payload
    }).then((response) => {
        return response;
    }).catch((error) => {
        return error
    });
};

export const _base_invite_reject = async (baseId: number) => {
    return _baseRequest(COMMON.stringFormat(API_END_POINT.BASEINVITEREJECT, baseId), "DELETE")
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const fetchBaseDetails = (baseId: number) => {    
    return async (dispatch: any) => {
        dispatch(baseInfoDataRequest());
        try {
            const response = await _getBaseDetails(baseId);
            localStorage.setItem('baseDetailsData', JSON.stringify(response.data.base));
            localStorage.setItem('baseDetails', JSON.stringify(response.data.base));
            dispatch(baseInfoDataSuccess(response.data.base));
        } catch (error: any) {
            dispatch(baseInfoDataFailure(error.message));
        }
    };
};



export const fetchBaseMemberDetails = (baseId: number) => {
    return async (dispatch: any) => {
        dispatch(baseInfoDataRequest());
        try {
            const response = await _getBaseSettingMember(baseId);
            localStorage.setItem('baseMembersDetails', JSON.stringify(response.data.baseMembers));
            dispatch(baseInfoDataSuccess(response.data.baseMembers));
            return response.data.baseMembers;
        } catch (error: any) {
            dispatch(baseInfoDataFailure(error.message));
        }
    };
};

export const fetchChannelData = (baseId: number) => {
    return async (dispatch: any) => {
        dispatch(baseInfoDataRequest());
        try {
            const response = await _get_ChannelData(baseId);
            localStorage.setItem('baseChannelDetails', JSON.stringify(response.data));
            dispatch(baseInfoDataSuccess(response.data));
        } catch (error: any) {
            dispatch(baseInfoDataFailure(error.message));
        }
    };
};

export const _createItemsTags = async (
    value: number,
    name: string,
    color: string
) => {
    return _baseRequest(COMMON.stringFormat(API_END_POINT.CREATEITEMSTAGS, value), "POST", {
        base_id: value,
        tag_title: name,
        color: color
    }).then((response) => {
        return response;
    }).catch((error) => {
        return error
    });
};

export const _get_ItemTagsList = async (
    id: number
) => {
    return _baseRequest(COMMON.stringFormat(API_END_POINT.GETITEAMSTAGSLIST, id), "GET", {
    }).then((res) => {
        return res
    }).catch((error) => {
        return error
    });
};

export const _getItemTagsDelete = async (baseId: number, itemId: number) => {
    return _baseRequest(COMMON.stringFormat(API_END_POINT.DELETEITEMTAGS, baseId, itemId), "DELETE", {
    }).then((res) => {
        return res;
    }).catch((error) => {
        return error;
    });
};

export const _getItemTagsById = async (itemId: number, baseId: number,) => {
    return _baseRequest(COMMON.stringFormat(API_END_POINT.GETITEMTAGBYID, itemId, baseId,), "GET", {
    }).then((res) => {
        return res;
    }).catch((error) => {
        return error;
    });
};


export const _updateItemTagsById = async (
    tagTitle: string,
    color: string,
    itemId: number,
    baseId: number
) => {
    const payload = {
        base_id: baseId,
        tag_title: tagTitle,
        color: color
    };

    try {
        const response = await _baseRequest(
            COMMON.stringFormat(API_END_POINT.UPDATEITEMTAGBYID, itemId, baseId),
            "PUT",
            payload
        );
        return response;
    } catch (error) {
        console.error('Error updating item tag:', error);
        throw error; // Re-throwing the error to handle it where this function is called
    }
};

export const _createItemsStatus = async (
    value: number,
    name: string,
    color: string
) => {
    return _baseRequest(COMMON.stringFormat(API_END_POINT.CREATEITEMSSTATUS, value), "POST", {
        base_id: value,
        base_status_title: name,
        color: color
    }).then((response) => {
        return response;
    }).catch((error) => {
        return error
    });
};

export const _get_ItemStatusList = async (
    id: number
) => {
    return _baseRequest(COMMON.stringFormat(API_END_POINT.GETITEAMSSTATUSLIST, id), "GET", {
    }).then((res) => {
        return res
    }).catch((error) => {
        return error
    });
};

export const _getItemStatusDelete = async (baseId: number, itemId: number) => {
    return _baseRequest(COMMON.stringFormat(API_END_POINT.DELETEITEMSTATUS, baseId, itemId), "DELETE", {
    }).then((res) => {
        return res;
    }).catch((error) => {
        return error;
    });
};


export const _getItemStatusById = async (itemId: number, baseId: number,) => {
    return _baseRequest(COMMON.stringFormat(API_END_POINT.GETITEMSTATUSBYID, itemId, baseId,), "GET", {
    }).then((res) => {
        return res;
    }).catch((error) => {
        return error;
    });
};


export const _updateItemStatusById = async (
    baseId: number,
    itemId?: number,
    isDefault?: boolean,
    tagTitle?: string,
    color?: string

) => {
    const payload = {
        base_id: baseId,
        base_status_title: tagTitle,
        color: color,
        isDefault: isDefault
    };

    try {
        const response = await _baseRequest(
            COMMON.stringFormat(API_END_POINT.UPDATEITEMSTATUSBYID, itemId, baseId),
            "PUT",
            payload
        );
        return response;
    } catch (error) {
        console.error('Error updating item tag:', error);
        throw error; // Re-throwing the error to handle it where this function is called
    }
};

// export const _updateItemStatusById = async (
//     baseId: number,
//     tagTitle: string,
//     color: string,
//     itemId: number
// ) => {
//     // Constructing the payload conditionally
//     const payload = {
//         base_id: baseId,
//         base_status_title: tagTitle,
//         color: color
//     };

//     try {
//         const response = await _baseRequest(
//             COMMON.stringFormat(API_END_POINT.UPDATEITEMSTATUSBYID, itemId, baseId),
//             "PUT",
//             payload
//         );
//         return response;
//     } catch (error) {
//         console.error('Error updating item tag:', error);
//         throw error;
//     }
// };



export const _feedback_update_item = async (
    id: number,
    base_id: number,
    options?: {
        item_tag_id?: number,
        item_base_status_id?: number,
        board_id?: number,
        item_priority?: number,
        item_assigned_to?: number,
        // itemOne?: string,
        // Add other fields as needed
    },
) => {
    const payload = {
        base_id,
        ...options,
    };

    return _baseRequest(COMMON.stringFormat(API_END_POINT.PUTITEMFEEDBACK, id, base_id), "PUT", payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};


// export const _get_ItemFeedbackList = async (
//     id: number
// ) => {
//     return _baseRequest(COMMON.stringFormat(API_END_POINT.GETITEMFEEDBACKLISTDATA, id), "GET", {
//     }).then((res) => {
//         return res
//     }).catch((error) => {
//         return error
//     });
// };

export const _get_ItemFeedbackList = async (
    id: number,
    pageIndex: number,
    pageSize: number
) => {
    const url = COMMON.stringFormat(API_END_POINT.GETITEMFEEDBACKLISTDATA, id);
    const params = {
        page: pageIndex + 1,
        pageSize,
    };

    return _baseRequest(url, "GET", params)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
};

export const _item_TopicSearchByParam = async (
    id: number,
    searchPayload: { search: string }

    // pageIndex: number,
    // pageSize: number
) => {
    const url = COMMON.stringFormat(API_END_POINT.PATCHITEMFEEDBACKTOPICSEARCH, id);
    const params = {
        //   page: pageIndex + 1,
        //   pageSize,
    };

    return _baseRequest(url, "PATCH", searchPayload)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
};

export const _updateItemByAiCheck = async (
    itemId: number,
    baseId: number
) => {
    // const payload = {
    //     base_id: baseId,
    // };

    try {
        const response = await _baseRequest(
            COMMON.stringFormat(API_END_POINT.UPDATEITEMBYAICHECK, itemId, baseId),
            "PUT",
            // payload
        );
        return response;
    } catch (error) {
        console.error('Error updating item tag:', error);
        throw error; // Re-throwing the error to handle it where this function is called
    }
};

export const _getChannel1 = createAsyncThunk('base/getChannel', async (baseId: number) => {
    const response = await fetchChannel(baseId);
    return response.data.data;
});

export const _fetchBaseDetails = createAsyncThunk('base/fetchBaseDetails', async (baseId: number) => {
    const response = await fetchBaseDetails1(baseId);
    return response.data.data.base;
});

export const _fetchBaseMemberDetails = createAsyncThunk('base/fetchBaseMemberDetails', async (baseId: number) => {
    const response = await fetchbaseMemberDetails(baseId);
    return response.data.data.baseMembers;
});

export const _fetchItemTagsList = createAsyncThunk('base/fetchItemTagsList', async (baseId: number) => {
    const response = await fetchItemTagsList(baseId);
    return response.data;
});

export const _fetchItemStatusList = createAsyncThunk('base/fetchItemStatusList', async (baseId: number) => {
    const response = await fetchItemStatusList(baseId);
    return response.data;
});

export const _fetchUserProfileDetails = createAsyncThunk('base/fetchUserProfileDetails', async () => {
    const response = await fetchUserProfileDetails();
    return response.data.data;
});

// reorder api status
export const _updateOrderBaseStatus = async (
    baseId: number,
    updates: { id: number; order: number }[] 
) => {
    const payload = {
        base_id: baseId,
        updates: updates,
    };

    try {
        const response = await _baseRequest(
            COMMON.stringFormat(API_END_POINT.UPDATEBASESTATUSORDER, baseId),
            "PUT",
            payload
        );
        return response;
    } catch (error) {
        console.error('Error updating item tag:', error);
        throw error;
    }
};


export const _uploadBaseLogo = async (baseID: number, file: File): Promise<any> => {
    const formData = new FormData();
    formData.append('image', file);

    try {
        const response = await _baseRequest(
            `/v1/base/${baseID}/logo`,
            'POST',
            formData,
            { 'Content-Type': 'multipart/form-data' }
        );
        return response;
    } catch (error) {
        throw error;
    }
};

export const _uploadBaseCover = async (baseID: number, file: File): Promise<any> => {
    const formData = new FormData();
    formData.append('image', file);

    try {
        const response = await _baseRequest(
            `/v1/base/${baseID}/add/cover`,
            'POST',
            formData,
            { 'Content-Type': 'multipart/form-data' }
        );
        return response;
    } catch (error) {
        throw error;
    }
};


export const _userProfileImage = async (file: File): Promise<any> => {
    const formData = new FormData();
    formData.append('image', file);

    try {
        const response = await _baseRequest(
            `/v1/profile/pic`,
            'POST',
            formData,
            { 'Content-Type': 'multipart/form-data' }
        );
        return response;
    } catch (error) {
        throw error;
    }
};