// @ts-nocheck

import React, {  HTMLProps, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  ColumnDef,
  PaginationState,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
} from '@tanstack/react-table'
import { MenuDropdown } from '../../../components/MenuDropdown'
import { Link } from 'react-router-dom'
import { formatDistanceToNow, parse } from 'date-fns';
import { _baseRequest } from '../../../services/api';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { COMMON } from '../../../utils/common';
import { DropdownGroup, IntegrationtTableProps, Person } from '../type';


const dropdownGroups: DropdownGroup[] = [
  {
    items: [
      {
        label: `Delete`,
        className: "py-[0.375rem] px-[0.75rem] bg-white text-[#000000eb] text-[13px] hover:bg-[#e7e7e8]",
      },
    ],
  },
];

const integrationStatus: Priority[] = [
  { id: 1, name: 'active', label: '#16a34a33' },
  { id: 2, name: 'inactive', label: '#4b5563' },
  { id: 3, name: 'delete', label: '#eb4938' },
];

localStorage.setItem('integrationStatus', JSON.stringify(integrationStatus));


function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!)

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return (
    <input
      type="checkbox"
      ref={ref}
      className="h-4 w-4 rounded border-gray-300 text-indigo-600 ring-0 outline-none focus:outline-none focus:shadow-none focus:ring-transparent"
      {...rest}
    />
  )
}

const IntegrationTable: React.FC<IntegrationtTableProps> = ({ sectionHeight, activeFilters, itemAssignedTo }) => {
  const [data, setData] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 25,
    currentPage: 1,
    hasNextPage: false,
    totalPages: 1,
    totalIntegration: 0,
  })
  const [rowSelection, setRowSelection] = React.useState({})
  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;
  const [isModalOpenCheckbox, setIsModalOpenCheckbox] = useState(false);
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const [deleteOk, setDeleteOk] = useState(false)
  const [rowSelectionDelete, setRowSelectionDelete] = useState<number[]>([]);
  const dispatch = useDispatch();
  const isInitialMount = useRef(true);
  const [initialLoading, setInitialLoading] = useState(true);

  const _get_ItemIntegrationList = async (pageIndex, pageSize, filters, itemAssignedTo) => {
    const url = `${COMMON.apiBaseUrl()}/v1/integration/${baseId}/integrationList`;
    let params = new URLSearchParams();
    if (typeof params.append !== 'function') {
      params = {
        append: (key, value) => {
          if (!this[key]) {
            this[key] = [];
          }
          this[key].push(value);
        },
        toString: () => {
          return Object.entries(this)
            .filter(([key]) => key !== 'append' && key !== 'toString')
            .flatMap(([key, values]) => values.map(value => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`))
            .join('&');
        }
      };
    }

    params.append('page', pageIndex.toString());
    params.append('pageSize', pageSize.toString());

    if (filters) {
      Object.entries(filters).forEach(([filterType, filterValues]) => {
        if (Array.isArray(filterValues) && filterValues?.length > 0) {
          const keyPrefix = getKeyPrefix(filterType);

          filterValues.forEach((filterObj) => {
            let value;
            if (filterType === 'Boards' || filterType === 'status' || filterType === 'assign') {
              value = filterObj.id;
            } else if (filterType === 'label' || filterType === 'priority' || filterType === 'sentiment') {
              value = filterObj.value;
            } else if (filterType === 'Created at') {
              const [startDate, endDate] = filterObj.value.split(' - ').map(dateStr => {
                const date = parseDate(dateStr);
                date.setHours(12, 0, 0, 0);
                return date;
              });
              params.append('created_from', formatDate(startDate));
              params.append('created_till', formatDate(endDate));
              return;
            } else if (filterType === 'Updated at') {
              const [startDate, endDate] = filterObj.value.split(' - ').map(dateStr => {
                const date = parseDate(dateStr);
                date.setHours(12, 0, 0, 0);
                return date;
              });
              params.append('updated_from', formatDate(startDate));
              params.append('updated_till', formatDate(endDate));
              return;
            }

            if (value !== undefined) {
              params.append(keyPrefix, value.toString());
            }
          });
        }
      });
    }
    const loginUserId = localStorage.getItem("userAuthId")
    if (itemAssignedTo) {
      params.append('assign_to', loginUserId);
    }
    try {
      const queryString = params.toString();
      const fullUrl = `${url}?${queryString}`;
      return await _baseRequest(fullUrl, 'GET');
    } catch (error) {
      console.error('Error in _get_ItemIntegrationList:', error);
      throw new Error(`Error fetching item feedback list: ${error.message}`);
    }
  };

  const getKeyPrefix = (filterType) => {
    switch (filterType) {
      case 'Boards':
        return 'board_id';
      case 'label':
        return 'item_label';
      case 'status':
        return 'item_base_status_id';
      case 'assign':
        return 'item_assigned_to';
      case 'priority':
        return 'item_priority';
      case 'sentiment':
        return 'item_sentiment';
      case 'Created at':
        return 'created_from';
      case 'Updated at':
        return 'updated_from';
      default:
        return filterType.toLowerCase();
    }
  };

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split(' ');
    const monthIndex = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].indexOf(month);
    return new Date(year, monthIndex, parseInt(day));
  };

  const formatDate = (date) => {
    return date.toISOString().split('T')[0];
  };

  const fetchItemFeedbackListData = useCallback(debounce(async (pageIndex, pageSize, filters, isInitialFetch = false, itemAssignedTo) => {
    setLoading(true);
    try {
      const response = await _get_ItemIntegrationList(pageIndex, pageSize, filters, itemAssignedTo);

      if (response.status === 200) {
        const newData = response.data.integration;
        const newPagination = {
          currentPage: response.data.currentPage,
          hasNextPage: response.data.hasNextPage,
          totalPages: response.data.totalPages,
          pageSize: response.data.pageSize,
          pageIndex: response.data.currentPage - 1,
          total: response.data.totalIntegration,
        };

        setData(newData);
        setPagination(newPagination);
        setTotalPages(response.data.totalPages);

        localStorage.setItem('cachedIntegrationData', JSON.stringify({
          data: newData,
          pagination: newPagination,
          timestamp: Date.now(),
        }));

        if (!isInitialFetch) {
          console.log('Data updated in background');
        }
      }
    } catch (error) {
      console.error('Error fetching item feedback list:', error);
    } finally {
      setLoading(false);
      if (isInitialFetch) {
        setInitialLoading(false);
      }
    }
  }, 500), []);


  useEffect(() => {
    const handleSidebarItemClick = () => {
      setPagination({
        pageIndex: 0,
        pageSize: 25,
        currentPage: 1,
        hasNextPage: false,
        totalPages: 1,
        total: 0,
      });
      setTotalPages(0);
      fetchItemFeedbackListData(1, 25, true);
    };

    window.addEventListener('paginationresetsetate', handleSidebarItemClick);

    return () => {
      window.removeEventListener('paginationresetsetate', handleSidebarItemClick);
    };
  }, [fetchItemFeedbackListData]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      const cachedData = localStorage.getItem('cachedIntegrationData');

      if (cachedData) {
        setLoading(true);
        const { data: cachedItems, pagination: cachedPagination } = JSON.parse(cachedData);
        setData(cachedItems);
        setPagination(prevPagination => ({
          ...prevPagination,
          ...cachedPagination,
        }));
        setTotalPages(cachedPagination.totalPages);
        setLoading(false);
      } else {


        fetchItemFeedbackListData(pagination.currentPage, pagination.pageSize, activeFilters, true, itemAssignedTo);
      }
    } else {
      fetchItemFeedbackListData(pagination.currentPage, pagination.pageSize, activeFilters, true, itemAssignedTo);
    }
  }, [fetchItemFeedbackListData, pagination.currentPage, pagination.pageSize, activeFilters, itemAssignedTo]);


  const handlePrevPage = () => {
    setLoading(false)
    if (pagination.currentPage > 1) {
      setPagination(prev => ({ ...prev, currentPage: prev.currentPage - 1 }));
    }
  };

  const handleNextPage = () => {
    setLoading(false)
    if (pagination.hasNextPage) {
      setPagination(prev => ({ ...prev, currentPage: prev.currentPage + 1 }));
    }
  };


  useEffect(() => {
    const handleSidebarItemClick = () => {
      setData([]);
      setPagination({
        pageIndex: 0,
        pageSize: 25,
        currentPage: 1,
        hasNextPage: false,
        totalPages: 1,
        total: 0,
      });
      setTotalPages(0);
      fetchItemFeedbackListData(1, 25, true);
    };

    window.addEventListener('sidebarItemClicked', handleSidebarItemClick);

    return () => {
      window.removeEventListener('sidebarItemClicked', handleSidebarItemClick);
    };
  }, [fetchItemFeedbackListData]);


  const handleSelect = (row: Row<Person>) => {
    setSelectedRows((prevSelectedRows) => {
      const newRowState = { ...prevSelectedRows }
      newRowState[row.id] = !prevSelectedRows[row.id]
      return newRowState
    })
  }

  const filteredData = useMemo(() => {
    if (!Array.isArray(activeFilters) || activeFilters?.length === 0) {
      return data;
    }

    return data.filter((item) => {
      const boardFilter = activeFilters.find(filter => filter.field === 'board_id');
      const matchesBoard = !boardFilter || item.board_id === boardFilter.value;

      return matchesBoard;
    });
  }, [data, activeFilters]);


  const handleSelectAllClick = useCallback((checked) => {
    const newRowSelection = {};
    const newRowSelectionDelete = [];

    data.forEach((row, index) => {
      newRowSelection[index] = checked;
      if (checked) {
        newRowSelectionDelete.push(row.id);
      }
    });

    setRowSelection(newRowSelection);
    setRowSelectionDelete(newRowSelectionDelete);
    setIsModalOpenCheckbox(newRowSelectionDelete.length > 0);
  }, [data]);


  const handleCheckboxClick = (row, id) => {
    setSelectedRows((prevSelectedRows) => ({
      ...prevSelectedRows,
      [row.id]: !prevSelectedRows[row.id]
    }));
    setRowSelectionDelete((prevSelection) => {

      if (prevSelection?.includes(id)) {
        return prevSelection.filter((itemId) => itemId !== id);
      } else {
        return [...prevSelection, id];
      }
    });

    setIsModalOpenCheckbox(true);
  };


  const columns = useMemo<ColumnDef<Person>[]>(() => [
    {
      id: 'select',
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: (e) => {
              table.toggleAllRowsSelected(e.target.checked);
              handleSelectAllClick(e.target.checked);
            },
          }}
        />
      ),
      cell: ({ row }) => (
        <div className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: (e) => {
                row.getToggleSelectedHandler()(e);
                handleCheckboxClick(row, row.original.id);
              },
            }}
          />
        </div>
      ),

    },
    {
      accessor: 'id',
      header: 'ID',
      cell: info => (

        <Link to={`/integrations/${info.row.original.id}`} className='w-full'>
          <span className="flex items-center  gap-[1rem] text-[13px] text-[#52555a]">
            {info?.row?.original?.integration_ref_Id}
          </span>
        </Link>
      ),
      sort: (a: { name: string }, b: { name: any }) => a.name.localeCompare(b.name),
    },
    {
      accessor: 'name',
      header: 'Name',
      cell: info => {
        return (
          <Link to={`/integrations/${info.row.original.id}`} className='w-full'>
            <div className=' relative'>
              <div data-title={info.row.original.integration_name} className=' table-tooltip hover:overflow-visible tabletooltip-title'>
                <div className=' text-[#1b1b1b] text-[13px] font-medium line-clamp-2 '>
                  {info.row.original.integration_name}
                </div>
              </div>

            </div>

          </Link>
        );
      },
      sort: (a: { name: string }, b: { name: any }) => a.name.localeCompare(b.name),
    },
    {
      accessor: 'type',
      header: 'Type',
      cell: info => {

        return (
          <Link to={`/integrations/${info.row.original.id}`} className='w-full'>
            <div className=' relative'>

              <div data-title={info.row.original.integration_type} className='table-tooltip flex items-center max-w-fit font-[500] leading-[1.2]  text-[0.75rem] px-[0.5rem] py-[5px] gap-2'>
                <span className='text-[#52555a] font-normal capitalize truncate text-[13px] leading-4 w-full text-start'>
                  {info.row.original.integration_type}
                </span>
              </div>

            </div>
          </Link>
        );
      },
      sort: (a: { type: string }, b: { type: any }) => a.type.localeCompare(b.type),
    },
    {
      accessor: 'activity',
      header: 'Activity',
      cell: info => {
        const lastSyncTime = info.row.original.last_sync;
        const isValidTime = /^[0-2]\d:[0-5]\d:[0-5]\d$/.test(lastSyncTime);

        if (isValidTime) {
          const timeDate = parse(lastSyncTime, 'HH:mm:ss', new Date());
          let relativeTime = formatDistanceToNow(timeDate, { addSuffix: true });
          relativeTime = relativeTime.replace(/^(in\s)?(about\s)?/, '');

          return (
            <Link to={`/integrations/${info.row.original.id}`} className='w-full'>
              <div data-title={info.row.original.last_sync} className="table-tooltip text-[#52555a] font-normal capitalize truncate text-[13px] leading-4 whitespace-nowrap max-w-[80px] w-full overflow-hidden text-ellipsis text-start">
                {relativeTime} ago
              </div>
            </Link>
          );
        } else {
          return (
            <Link to={`/integrations/${info.row.original.id}`} className='w-full'>
              <div data-title={info.row.original.last_sync} className="table-tooltip text-[#52555a] font-normal capitalize truncate text-[13px] leading-4 whitespace-nowrap max-w-[80px] w-full overflow-hidden text-ellipsis text-start">
                {info?.row?.original?.last_sync}
              </div>
            </Link>
          );
        }
      },
      sort: (a: { activity: string }, b: { activity: string }) => {
        const timeA = parse(a.activity, 'HH:mm:ss', new Date()).getTime();
        const timeB = parse(b.activity, 'HH:mm:ss', new Date()).getTime();

        return timeA - timeB;
      },
    },
    {
      accessor: 'response',
      header: 'Response',
      cell: info => {

        return (
          <Link to={`/integrations/${info.row.original.id}`} className='w-full'>
            <div className=' relative'>

              <div className='table-tooltip flex items-center max-w-fit font-[500] leading-[1.2]  text-[0.75rem] px-[0.5rem] py-[5px] gap-2'>
                <span className='text-[#52555a] font-normal capitalize truncate text-[13px] leading-4 whitespace-nowrap max-w-[50px] w-full overflow-hidden text-ellipsis text-start'>
                  {info.row.original?.response_count}
                </span>
              </div>

            </div>
          </Link>
        );
      },
      sort: (a: { response: string }, b: { response: any }) => a.response.localeCompare(b.response),
    },
    {
      accessor: 'board',
      header: 'Board',
      cell: info => {

        return (
          <Link to={`/integrations/${info.row.original.id}`} className='w-full'>
            <div className=' relative'>

              <div data-title={info.row.original?.board_id} className='table-tooltip flex items-center max-w-fit font-[500] leading-[1.2]  text-[0.75rem] px-[0.5rem] py-[5px] gap-2'>
                <span className='text-[#52555a] font-normal capitalize truncate text-[13px] leading-4  w-full text-start'>
                  {info.row.original?.board_id}
                </span>
              </div>

            </div>
          </Link>
        );
      },
      sort: (a: { board: string }, b: { board: any }) => a.board.localeCompare(b.board),
    },
    {
      accessor: 'status',
      header: 'Status',
      cell: info => {
        const status = info.row.original?.integration_status;
        let statusClass = '';

        switch (status) {
          case 'active':
            statusClass = 'inline-flex items-center rounded-md bg-green-50 px-2 py-1 font-medium text-[#15803d] ring-1 ring-inset ring-green-600/20';
            break;
          case 'inactive':
            statusClass = 'inline-flex items-center rounded-md bg-gray-50 px-2 py-1 font-medium text-[#4b5563] ring-1 ring-inset ring-gray-500/10';
            break;
          case 'delete':
            statusClass = 'inline-flex items-center rounded-md bg-red-50 px-2 py-1 font-medium text-[#b91c1c] ring-1 ring-inset ring-red-600/20';
            break;
          default:
            statusClass = 'inline-flex items-center rounded-md bg-gray-50 px-2 py-1 font-medium text-[#4b5563] ring-1 ring-inset ring-gray-500/10'; // Default style
        }

        return (
          <Link to={`/integrations/${info.row.original.id}`} className='w-full'>
            <div className='relative'>
              <div className={statusClass}>
                <span className='capitalize truncate text-[13px] leading-4 w-full text-start'>
                  {status}
                </span>
              </div>
            </div>
          </Link>
        );
      },
      sort: (a: { status: string }, b: { status: string }) => a.status.localeCompare(b.status),
    }


  ], [handleSelectAllClick]);



  const table = useReactTable({
    data,
    columns,
    state: {
      pagination,
      rowSelection,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
    onPaginationChange: setPagination,
  });


  useEffect(() => {
    const applyStyles = () => {
      const tableElement = document.querySelector('.feedback-col') as HTMLElement | null;
      const tableElement1 = document.querySelector('.feedback-thead') as HTMLElement | null;
      const isTabScreen = window.matchMedia('(max-width: 1499px)').matches;
      const isSmallScreen = window.matchMedia('(max-width: 767px)').matches;
      if (tableElement) {
        if (isSmallScreen) {
          tableElement.style.marginTop = sectionHeight ? `calc(50px + ${sectionHeight}px)` : '50px';
        } else if (isTabScreen) {
          tableElement.style.marginTop = sectionHeight ? `calc(45px + ${sectionHeight}px)` : '46px';
        } else {
          tableElement.style.marginTop = sectionHeight ? `calc(87px + ${sectionHeight}px)` : '87px';
        }
      }

      if (tableElement1) {
        if (isSmallScreen) {
          tableElement1.style.top = sectionHeight ? `calc(80px + ${sectionHeight}px)` : '80px';
        } else if (isTabScreen) {
          tableElement1.style.top = sectionHeight ? `calc(101px + ${sectionHeight}px)` : '101px';
        } else {
          tableElement1.style.top = sectionHeight ? `calc(101px + ${sectionHeight}px)` : '101px';
        }

        if (sectionHeight) {
          tableElement1.style.borderTop = '1px solid #e5e7eb';
        } else {
          tableElement1.style.borderTop = 'none';
        }
      }
    };

    applyStyles();

    window.addEventListener('resize', applyStyles);
    return () => {
      window.removeEventListener('resize', applyStyles);
    };
  }, [sectionHeight]);



  return (
    <>
      <div className='mx-auto max-w-full'>
        <div className="px-4 sm:px-6 lg:px-8 feedback-table">
          <div className="flow-root h-full">
            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8 h-full">
              <div className="inline-block min-w-full py-2 align-middle h-full max-w-full">
                <div className="relative  h-full block pb-[5rem]">
                  <table className="block border-collapse overflow-x-auto w-full feedback-col overflow-y-auto feedback-table-height h-full">
                    <thead
                      className='mq1499:sticky mq3000:fixed bg-white grid feedback-thead feedback-table-row'
                    >
                      {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id} className='bg-white flex relative w-full'>
                          {headerGroup.headers.map(header => {

                            return (
                              <th key={header.id} colSpan={header.colSpan} scope="col"
                                className={`sticky bg-white flex flex-1 py-[0.75rem] px-[1rem] cursor-pointer text-[0.75rem] items-center justify-start
                                leading-[1rem] text-[#52555a] font-[500] border-b  border-solid border-light-gray-200 min-w-[50px] md:min-w-[160px] lg:min-w-[50px]
                                   ${header.id === 'select' ? 'max-w-[50px] [&>div]:px-0' : ''} 
                                   ${header.id === 'ID' ? 'max-w-[60px]' : ''}
                                   ${header.id === 'Name' ? 'max-w-[-webkit-fill-available] !min-w-[370px]' : ''}
                                   ${header.id === 'Type' ? '!min-w-[106px]' : ''}
                                   ${header.id === 'Activity' ? '!min-w-[106px]' : ''}
                                   ${header.id === 'Response' ? '!min-w-[106px]' : ''}
                                   ${header.id === 'Board' ? '!min-w-[106px]' : ''}
                                  `}
                              >
                                <div
                                  {...{
                                    className: header.column.getCanSort()
                                      ? 'cursor-pointer select-none '
                                      : '',
                                    onClick: header.column.getToggleSortingHandler(),
                                  }}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                  {{
                                    asc: ' 🔼',
                                    desc: ' 🔽',
                                  }[header.column.getIsSorted() as string] ?? null}
                                </div>
                              </th>
                            )
                          })}
                          <th
                            className={`sticky bg-white flex flex-1 py-[0.75rem] px-[1rem] cursor-pointer text-[0.75rem] items-center justify-start
                                    leading-[1rem] text-[#52555a] font-[500] border-r border-b  border-solid border-light-gray-200 min-w-10 max-w-[56px]`}
                          ></th>
                        </tr>
                      ))}
                    </thead>

                    <div>
                      {loading ? (
                        <div className="flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 191px)' }}>
                          <div className="simple-spinner-loader">
                            <span></span>
                          </div>
                        </div>
                      ) : pagination.totalPages === 0 ? (
                        <div className="flex items-center justify-center z-50" style={{ height: 'calc(100vh - 191px)' }}>
                          <p>No data found</p>
                        </div>
                      ) : (

                        <tbody className="grid">
                          {table.getRowModel().rows.map((row) => {
                            const allRowsSelected = table.getIsAllRowsSelected();
                            return (
                              <tr
                                key={row.id}
                                data-selected={allRowsSelected ? true : selectedRows[row.id]}
                                onClick={() => handleSelect(row)}
                                className={`border-b border-solid border-light-gray-200 bg-white flex w-full relative ${selectedRows[row.id] ? 'bg-gray-100' : ''}`}
                              >
                                {row.getVisibleCells().map((cell) => (
                                  <td
                                    key={cell.id}
                                    className={`flex items-center justify-start text-start px-[1rem] py-[0.9rem] leading-[1rem]
                    flex-1 text-dark-black-200 max-w-[350px]
                    ${/(_select)$/.test(cell.id) ? 'max-w-[50px] [&>div]:px-0' : ''} 
                    ${/(_ID)$/.test(cell.id) ? 'max-w-[60px]' : ''}
                    ${/(_Name)$/.test(cell.id) ? '!max-w-[-webkit-fill-available] min-w-[370px]' : ''}
                    ${/(_Type)$/.test(cell.id) ? '!min-w-[106px]' : ''}
                    ${/(_Activity)$/.test(cell.id) ? '!min-w-[106px]' : ''}
                    ${/(_Response)$/.test(cell.id) ? '!min-w-[106px]' : ''}
                    ${/(_Board)$/.test(cell.id) ? '!min-w-[106px]' : ''}
                  `}
                                  >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                  </td>
                                ))}
                                <td
                                  className={`flex items-center text-start px-[0.75rem] py-[1rem] leading-[1rem] flex-1 max-w-[50px] text-dark-black-200 justify-center [&>button]:w-auto [&>button]:bg-transparent [&>button]:hover:bg-transparent`}
                                >
                                  <MenuDropdown
                                    groups={dropdownGroups}
                                    image={
                                      <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        aria-hidden="true"
                                        focusable="false"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <circle cx="12" cy="12" r="1"></circle>
                                        <circle cx="12" cy="5" r="1"></circle>
                                        <circle cx="12" cy="19" r="1"></circle>
                                      </svg>
                                    }
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                    </div>

                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="fixed z-10 bg-white border-t border-solid border-light-gray-200 py-[0.5rem] px-[1rem] bottom-0 pagination-bottom">
        <div className="relative flex items-center justify-between">



          <div className="flex items-center gap-2">
            <span className="flex items-center gap-1">
              <div className='text-[0.875rem] font-[500] text-[#000000eb]'>Page

              </div>
            </span>
            <span className="flex items-center gap-1">

              <input
                type="number"
                value={pagination.currentPage}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) : 1;
                  setPagination((prevPagination) => ({
                    ...prevPagination,
                    currentPage: page,
                  }));
                }}
                className="w-[4rem] h-[2rem] text-[0.8125rem] px-[0.75rem] rounded-md border border-solid border-light-gray-100"
              />

              <span className='mr-1 text-[#000000eb] text-[0.8125rem]'>  of{' '}

                {totalPages}
              </span>
            </span>


            <div className='flex items-center gap-1'>

              <select
                value={table.getState().pagination.pageSize}
                onChange={e => {
                  table.setPageSize(Number(e.target.value))
                }}
                className="w-[6rem] h-[2rem] text-[0.8125rem] px-[0.75rem] rounded-md border border-solid border-light-gray-100 block"
              >
                {[25, 50, 100].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
              <div className='mr-1 text-[#000000eb] text-[0.8125rem] ml-[14px]'>
                {pagination.total} Items
              </div>
            </div>


          </div>
          <div className='flex gap-2'>
            <button
              className="border rounded py-2 px-2"
              onClick={handlePrevPage}
              disabled={pagination.currentPage === 1}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-2 w-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
              </svg>

            </button>
            <button
              className="border rounded py-2 px-2"
              onClick={handleNextPage}
              disabled={!pagination.hasNextPage}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-2 w-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
              </svg>

            </button>
          </div>
        </div>
      </div>



    </>
  );

}


export default IntegrationTable;