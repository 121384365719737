import React from 'react';

interface ThumbRatingProps {
  type: 'thumbs-up' | 'thumbs-down';
  isSelected: boolean;
  onSelect: (value: number) => void;
  value: string;
}

const ThumbRating: React.FC<ThumbRatingProps> = ({ type, isSelected, onSelect, value }) => {
  const getThumbIcon = () => {
    switch (type) {
      case 'thumbs-down':
        return '👎';
      case 'thumbs-up':
        return '👍';
      default:
        return '';
    }
  };

  const handleClick = () => {
    const selectedValue = type === 'thumbs-up' ? 1 : -1;
    onSelect(selectedValue);
  };
 

  return (
    <div
    onClick={handleClick}
    className={`py-2 px-4 text-[13px] rounded-lg w-[50px] md:w-[74px] lg:w-[74px] h-[50px] md:h-[74px] lg:h-[74px] flex items-center justify-center text-center leading-4 font-normal border
    ${isSelected ? 'ring-2 ring-inset ring-indigo-600 shadow-md border-none bg-indigo-600' : 'border-gray-300 bg-white'}
  `}
      role="button"
      aria-pressed={isSelected}
    >
       <span className="text-[1.5rem] md:text-[2.5rem] lg:text-[2.8rem]">{getThumbIcon()}</span>
    </div>
  );
};

export default ThumbRating;
