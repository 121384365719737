
import { _baseRequest } from "../../../services/api";

const API_END_POINT = {
    SIGNUP: "/v1/auth/register"
}

export const user_signUp = async (
    name:string,
    email: string,
    password: string,
    deviceId: string,
    deviceName: string
) => {
    return _baseRequest(API_END_POINT.SIGNUP, "POST", {
        name,
        email,
        password,
        deviceId,
        deviceName
    }).then((response) => {
        return response;
    }).catch((error) => {
        if(error?.data?.status === 401){
            alert(error?.data?.message)
        }
        return error
    });
};

