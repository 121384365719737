// projectboardreducers.ts

import { Reducer } from '@reduxjs/toolkit';
import { CREATE_BOARD_REQUEST, CREATE_BOARD_SUCCESS, CREATE_BOARD_FAILURE, FETCH_BOARD_LIST_REQUEST, FETCH_BOARD_LIST_SUCCESS, FETCH_BOARD_LIST_FAILURE } from './BoardActionTypes';

interface ProjectBoardState {
  loading: boolean;
  error: any;
  data: any;
  projectBoard: {
    projectBoardList: any[];
  };
}

const initialState: ProjectBoardState = {
  loading: false,
  error: null,
  data: null,
  projectBoard: {
    projectBoardList: [], // Initialize the project board list as an empty array
  },
};

const BoardReducer: Reducer<ProjectBoardState> = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_BOARD_REQUEST:
      return { ...state, loading: true, error: null };
    case CREATE_BOARD_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case CREATE_BOARD_FAILURE:
      return { ...state, loading: false, error: action.payload, data: null };
      case FETCH_BOARD_LIST_REQUEST:
        return { ...state, loading: true, error: null };
      case FETCH_BOARD_LIST_SUCCESS:
        return { ...state, loading: false, projectBoardList: action.payload, error: null };
      case FETCH_BOARD_LIST_FAILURE:
        return { ...state, loading: false, error: action.payload, projectBoardList: [] };
    default:
      return state;
  }
};

export default BoardReducer;