// feedback.tsx
// @ts-nocheck

import React, { Fragment, HTMLProps, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  ColumnDef,
  PaginationState,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
} from '@tanstack/react-table'
import { MenuDropdown } from '../../../components/MenuDropdown'
import { Link } from 'react-router-dom'
// import { _get_ItemFeedbackList } from '../../setting/settingPages/baseAPI'
import { format } from 'date-fns'; // Import the date-fns library
import { _baseRequest } from '../../../services/api';
import { COMMON } from '../../../utils/common';
import { getColorForLabel } from '../../../utils/colorUtils';
import { useDispatch } from 'react-redux';
import { CheckIcon, TagIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Dialog, Label, Listbox, Transition } from '@headlessui/react';
import { PlusIcon } from "@iconicicons/react";
import { Button } from "@lemonsqueezy/wedges";
import { Toaster, toast } from 'sonner';
import { deleteItemFeedback } from '../../feedbacks/feedback-redux/feedbacks.actions';
import { debounce } from 'lodash';
type Person = {
  name: string
  email: string
  age: string
  type: string
  typeColor: string,
  status: string,
  statusColor: string,
  progress?: string,
  avatar?: string;
}

interface topicTableProps {
  sectionHeight?: number;
}

var date = new Date();
var formattedDate = date.toLocaleDateString('en-US', {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
});




interface DropdownItem {
  className?: string;
  icon?: ReactNode;
  label: string;
  shortcut?: any;
  disabled?: boolean;
  onClick?: () => void;
  href?: string; // Add href property with type string

}

interface DropdownGroup {
  items: DropdownItem[];
}


const columnHelper = createColumnHelper<Person>()

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!)

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return (
    <input
      type="checkbox"
      ref={ref}
      className="h-4 w-4 rounded border-gray-300 text-indigo-600 ring-0 outline-none focus:outline-none focus:shadow-none focus:ring-transparent"
      {...rest}
    />
  )
}

// export default function FeedbackTable() {
const TopicTable: React.FC<topicTableProps> = ({ sectionHeight, activeFilters, itemAssignedTo }) => {

  const [data, setData] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 25,
    currentPage: 1,
    hasNextPage: false,
    totalPages: 1,
    totalItems: 0,
  })
  const [rowSelection, setRowSelection] = React.useState({})
  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;
  const [isModalOpenCheckbox, setIsModalOpenCheckbox] = useState(false);
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const [deleteOk, setDeleteOk] = useState(false)
  const [rowSelectionDelete, setRowSelectionDelete] = useState<number[]>([]);
  const dispatch = useDispatch();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const isInitialMount = useRef(true);
  const [initialLoading, setInitialLoading] = useState(true);
  // Fetch channelData from localStorage
  const channelDataString = localStorage.getItem('channelData');
  const channelData = channelDataString ? JSON.parse(channelDataString) : [];


  // item status data 
  const itemStatus = localStorage.getItem('itemStatusList');
  const statusData = itemStatus ? JSON.parse(itemStatus) : [];

  // item tags data 
  const itemTags = localStorage.getItem('itemTagsList');
  const tagsData = itemTags ? JSON.parse(itemTags) : [];

  // item assign to
  const itemAssign = localStorage.getItem('baseMembersDetails');
  const assignData = itemAssign ? JSON.parse(itemAssign) : [];

  // item priority
  const itemPriority = localStorage.getItem('priorities');
  const priorityData = itemPriority ? JSON.parse(itemPriority) : [];



  const _get_ItemFeedbackList = async (pageIndex, pageSize, filters, itemAssignedTo) => {
    const API_END_POINT = COMMON.apiBaseUrl();
    const url = `${API_END_POINT}/v1/item/list/${baseId}/items?item_type=ticket`;
    const params = new URLSearchParams({ page: pageIndex.toString(), pageSize: pageSize.toString() });


    const appendParam = (key, value) => {
      params.append(key, value);
    };

    if (filters) {
      Object.entries(filters).forEach(([filterType, filterValues]) => {
        if (Array.isArray(filterValues) && filterValues.length > 0) {
          const keyPrefix = getKeyPrefix(filterType);

          filterValues.forEach((filterObj) => {
            let value;
            if (filterType === 'Boards' || filterType === 'status' || filterType === 'assign') {
              value = filterObj.id;
            } else if (filterType === 'label' || filterType === 'priority' || filterType === 'sentiment') {
              value = filterObj.value;
            } else if (filterType === 'Created at') {
              const [startDate, endDate] = filterObj.value.split(' - ').map(dateStr => {
                const date = parseDate(dateStr);
                date.setHours(12, 0, 0, 0);
                return date;
              });
              appendParam('created_from', formatDate(startDate));
              appendParam('created_till', formatDate(endDate));
              return;
            } else if (filterType === 'Updated at') {
              const [startDate, endDate] = filterObj.value.split(' - ').map(dateStr => {
                const date = parseDate(dateStr);
                date.setHours(12, 0, 0, 0);
                return date;
              });
              appendParam('updated_from', formatDate(startDate));
              appendParam('updated_till', formatDate(endDate));
              return;
            }

            if (value !== undefined) {
              appendParam(keyPrefix, value.toString());
            }
          });
        }
      });
    }

    if (filters.search) {
      params.append('search', filters.search);
    }

    const loginUserId = localStorage.getItem("userAuthId");
    if (itemAssignedTo) {
      appendParam('item_assigned_to', loginUserId);
    }

    try {
      const fullUrl = `${url}&${params.toString()}`;
      return await _baseRequest(fullUrl, 'GET');

    } catch (error) {
      console.error('Error in _get_ItemFeedbackList:', error);
      throw new Error(`Error fetching item feedback list: ${error.message}`);
    }
  };



  // Helper function to get the key prefix based on filter type
  const getKeyPrefix = (filterType) => {
    switch (filterType) {
      case 'Boards':
        return 'board_id';
      case 'label':
        return 'item_label';
      case 'status':
        return 'item_base_status_id';
      case 'assign':
        return 'item_assigned_to';
      case 'priority':
        return 'item_priority';
      case 'sentiment':
        return 'item_sentiment';
      case 'Created at':
        return 'created_from';
      case 'Updated at':
        return 'updated_from';
      default:
        return filterType.toLowerCase();
    }
  };

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split(' ');
    const monthIndex = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].indexOf(month);
    return new Date(year, monthIndex, parseInt(day));
  };

  const formatDate = (date) => {
    return date.toISOString().split('T')[0];
  };


  const fetchItemFeedbackListData = useCallback(debounce(async (pageIndex, pageSize, filters, isInitialFetch = false, itemAssignedTo) => {
    setLoading(true);
    try {
      const response = await _get_ItemFeedbackList(pageIndex, pageSize, filters, itemAssignedTo);

      if (response.status === 200) {
        const newData = response.data.items;
        const newPagination = {
          currentPage: response.data.currentPage,
          hasNextPage: response.data.hasNextPage,
          totalPages: response.data.totalPages,
          pageSize: response.data.pageSize,
          pageIndex: response.data.currentPage - 1,
          total: response.data.totalItems,
        };

        setData(newData);
        setPagination(newPagination);
        setTotalPages(response.data.totalPages);
        localStorage.setItem('cachedTopicData', JSON.stringify({
          data: newData,
          pagination: newPagination,
          timestamp: Date.now(),
        }));
        if (!isInitialFetch) {
          console.log('Data updated in background');
        }
      }
    } catch (error) {
      console.error('Error fetching item feedback list:', error);
    } finally {
      setLoading(false);
      if (isInitialFetch) {
        setInitialLoading(false);
      }
    }
  }, 500), []); // Adjust the debounce delay as needed

  useEffect(() => {
    const handleSidebarItemClick = () => {
      setPagination({
        pageIndex: 0,
        pageSize: 25,
        currentPage: 1,
        hasNextPage: false,
        totalPages: 1,
        total: 0,
      });
      setTotalPages(0);
      fetchItemFeedbackListData(1, 25, true);
    };

    window.addEventListener('paginationresetsetate', handleSidebarItemClick);

    return () => {
      window.removeEventListener('paginationresetsetate', handleSidebarItemClick);
    };
  }, [fetchItemFeedbackListData]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      const cachedData = localStorage.getItem('cachedTopicData');
      if (cachedData) {
        setLoading(true);
        const { data: cachedItems, pagination: cachedPagination } = JSON.parse(cachedData);
        setData(cachedItems);
        setPagination(prevPagination => ({
          ...prevPagination,
          ...cachedPagination,
        }));
        setTotalPages(cachedPagination.totalPages);
        setLoading(false);
      } else {
        fetchItemFeedbackListData(pagination.currentPage, pagination.pageSize, activeFilters, true, itemAssignedTo);
      }
    } else {
      fetchItemFeedbackListData(pagination.currentPage, pagination.pageSize, activeFilters, true, itemAssignedTo);
    }
  }, [fetchItemFeedbackListData, pagination.currentPage, pagination.pageSize, activeFilters, itemAssignedTo]);


  const handlePrevPage = () => {
    setLoading(false)
    if (pagination.currentPage > 1) {
      setPagination(prev => ({ ...prev, currentPage: prev.currentPage - 1 }));
    }
  };

  const handleNextPage = () => {
    setLoading(false)
    if (pagination.hasNextPage) {
      setPagination(prev => ({ ...prev, currentPage: prev.currentPage + 1 }));
    }
  };

  useEffect(() => {
    const handleSidebarItemClick = () => {
      setData([]);
      setPagination({
        pageIndex: 0,
        pageSize: 25,
        currentPage: 1,
        hasNextPage: false,
        totalPages: 1,
        total: 0,
      });
      setTotalPages(0);
      fetchItemFeedbackListData(1, 25, true);
    };

    window.addEventListener('sidebarItemClicked', handleSidebarItemClick);

    return () => {
      window.removeEventListener('sidebarItemClicked', handleSidebarItemClick);
    };
  }, [fetchItemFeedbackListData]);


  const handleSelect = (row: Row<Person>) => {
    setSelectedRows((prevSelectedRows) => {
      const newRowState = { ...prevSelectedRows }
      newRowState[row.id] = !prevSelectedRows[row.id]
      return newRowState
    })
  }


  const columns = useMemo<ColumnDef<Person>[]>(() => [
    {
      id: 'select',
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: (e) => {
              table.getToggleAllRowsSelectedHandler()(e);
              handleCheckboxClick(table);
            },
          }}
        />
      ),
      cell: ({ row }) => (
        <div className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: (e) => {
                row.getToggleSelectedHandler()(e);
                handleCheckboxClick(row, row.original.id);
              },
            }}
          />
        </div>
      ),
    },
    {
      accessor: 'id',
      header: 'ID',
      // cell: info => info.row.original.name,
      cell: info => (

        <Link to={`/ticket/${info.row.original.id}`} className='w-full'>
          <span className="flex items-center  gap-[1rem] text-[13px] text-[#52555a]">
            {/* {info.row.original.avatar ? (
              <img
                src={info.row.original.avatar}
                alt={info.row.original.name}
                className="w-6 h-6 rounded-full"
              />
            ) : (
              <div className="w-6 h-6 rounded-full bg-gray-200 flex items-center justify-center">
                <span className="text-[9.6px] font-medium text-gray-700">
                  {info.row.original?.name?.charAt(0)?.toUpperCase()}
                </span>
              </div>
            )} */}
            {info.row.original.item_refernce_id}
          </span>
        </Link>
      ),
      sort: (a: { name: string }, b: { name: any }) => a.name.localeCompare(b.name),
    },
    {
      accessor: 'title',
      header: 'Title',
      cell: info => {
        const { item_title, item_details } = info.row.original;
        const displayTitle = item_title || item_details;
        return (
          <Link to={`/ticket/${info.row.original.id}`} className='w-full'>
            <div className=' relative'>
              <div data-title={displayTitle} className=' table-tooltip hover:overflow-visible tabletooltip-title'>
                <div className=' text-[#1b1b1b] text-[13px] font-medium line-clamp-2 '>
                  {displayTitle}
                </div>
              </div>

            </div>

          </Link>
        );
      },
      sort: (a: { item_title: string; item_details: string }, b: { item_title: string; item_details: string }) => {
        const textA = a.item_title || a.item_details;
        const textB = b.item_title || b.item_details;
        return textA.localeCompare(textB);
      },
    },
    {
      accessor: 'board',
      header: 'Board',
      cell: info => {

        const boardIdToFilter = channelData.find((board: any) => board.id === info.row.original.board_id);
        return (
          <Link to={`/ticket/${info.row.original.id}`} className='w-full'>
            <div className=' relative'>

              {boardIdToFilter ? (
                <div data-title={boardIdToFilter.name} className='table-tooltip flex items-center max-w-fit font-[500] leading-[1.2] bg-light-gray-100 rounded-full text-[0.75rem] px-[0.5rem] py-[5px] gap-2'>
                  <span
                    className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center
                  rounded-full border-none text-[0.625rem] font-medium text-white capitalize bg-[${boardIdToFilter.color}]`}
                  ></span>
                  <span className='text-[#52555a] font-normal capitalize truncate text-[13px] leading-4 whitespace-nowrap max-w-[50px] w-full overflow-hidden text-ellipsis text-start'>
                    {boardIdToFilter.name}
                  </span>
                </div>

              ) : null}
            </div>
          </Link>
        );
      },
      sort: (a: { board: string }, b: { board: any }) => a.board.localeCompare(b.board),
    },
    {
      accessor: 'status',
      header: 'Status',
      cell: info => {
        const stautsIdToFilter = statusData?.data?.find((status: any) => status.id === info.row.original.item_base_status_id);
        return (
          <div className=' relative'>
            {stautsIdToFilter ? (
              <Link to={`/ticket/${info.row.original.id}`} className='w-full'>
                <div data-title={stautsIdToFilter.base_status_title} className=' table-tooltip flex items-center max-w-fit font-[500] leading-[1.2] bg-light-gray-100 rounded-full text-[0.75rem] px-[0.5rem] py-[5px] gap-[6px]'>
                  <span
                    className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center
                  rounded-full border-none text-[0.625rem] font-medium text-white capitalize ${stautsIdToFilter.color}`}
                  ></span>
                  <span className='text-[#52555a] font-normal capitalize truncate text-[13px] leading-4 whitespace-nowrap max-w-[80px] w-full overflow-hidden text-ellipsis text-start'>
                    {stautsIdToFilter.base_status_title}
                  </span>
                </div>
              </Link>
            ) : null}
          </div>

        )

      },
      sort: (a: { status: string }, b: { status: any }) => a.status.localeCompare(b.status),
    },
    // {
    //   accessor: 'tags',
    //   header: 'Tags',
    //   cell: info => {
    //     // const tagsIdToFilter = tagsData.find((tag: any) => tag.id === info.row.original.item_base_tag_id);
    //     const tagIds = info.row.original.item_tag_id; // Assuming this is an array of tag IDs
    //     const tagsIdToFilter = tagsData.filter((tag: any) => tagIds?.includes(tag.id));
    //     const slicedTags = tagsIdToFilter.slice(0, 5);

    //     return (
    //       <>
    //         {tagsIdToFilter ? (
    //           <Link to={`/ticket/${info.row.original.id}`} className='w-full flex items-center gap-1 table-tags-main'>

    //             {slicedTags.map((tag, index) => {

    //               const isLast = index === slicedTags.length - 1;

    //               return (
    //                 <div
    //                   data-title={tag.tag_title}
    //                   className={`flex items-center max-w-fit font-[500] leading-[1.2]
    //                  bg-light-gray-100 rounded-full text-[0.75rem] px-[0.5rem] py-[5px] gap-[6px] tags-one ${isLast ? 'tags-table-design' : ''}`}

    //                 >
    //                   <span className='text-[#52555a] truncate text-[13px] leading-4 whitespace-nowrap max-w-[84px] w-full overflow-hidden text-ellipsis text-start font-normal'>  {index === slicedTags.length - 1 ? `${tag.tag_title}` : ''}</span>
    //                   <span
    //                     className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center mt-[2px] ml-[-1px]
    //           rounded-full border-none text-[0.625rem] font-medium text-white capitalize ${tag.color}`}
    //                   ></span>

    //                 </div>
    //               )
    //             })}
    //           </Link>
    //         ) : null}
    //       </>
    //     )
    //   },
    //   sort: (a: { tags: string }, b: { tags: any }) => a.tags.localeCompare(b.tags),
    // },
    {
      accessor: 'label',
      header: 'Label',
      cell: info => {
        const tagIds = info.row.original.item_label?.split(',').map(tag => ({ tag_title: tag.trim(), color: getColorForLabel(tag.trim()) }));
        const slicedTags = tagIds?.slice(0, 5);
        return (
          <>
            {tagIds ? (
              <Link to={`/ticket/${info.row.original.id}`} className='w-full flex items-center gap-1 table-tags-main'>
                {slicedTags?.map((tag, index) => {
                  const isLast = index === slicedTags.length - 1;

                  return (
                    <div
                      key={index}
                      data-title={tag.tag_title}
                      className={`flex items-center max-w-fit font-[500] leading-[1.2]
                     bg-light-gray-100 rounded-full text-[0.75rem] px-[0.5rem] py-[5px] gap-[6px] tags-one ${isLast ? 'tags-table-design' : ''}`}
                    >
                      <span className='text-[#52555a] truncate text-[13px] leading-4 whitespace-nowrap max-w-[84px] w-full overflow-hidden text-ellipsis text-start font-normal'>
                        {index === slicedTags.length - 1 ? `${tag.tag_title}` : ''}
                      </span>
                      <span
                        className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center mt-[2px] ml-[-1px]
                        rounded-full border-2 border-solid text-[0.625rem] font-medium text-white capitalize`}
                        style={{ borderColor: tag.color }}
                      ></span>
                    </div>
                  );
                })}
              </Link>
            ) : null}
          </>
        );
      },
      sort: (a: { label: string }, b: { tags: any }) => a.label.localeCompare(b.label),
    },
    {
      accessor: 'assigned',
      header: 'Assigned',
      cell: info => {
        const assignIdToFilter = assignData.find((assign: any) => assign.id === info.row.original.item_assigned_to);
        return (
          <div className=' relative'>
            {assignIdToFilter ? (
              <Link to={`/ticket/${info.row.original.id}`} className='w-full'>
                <div data-title={assignIdToFilter.name} className='table-tooltip flex items-center max-w-fit font-[500] leading-[1.2] rounded-full text-[0.75rem] px-[0rem] py-[5px] gap-[6px]'>
                {assignIdToFilter ? (
                    <img
                      src={
                        assignIdToFilter?.profile_pic
                          ? `${process.env.REACT_APP_API_BASE_URL}/${assignIdToFilter?.profile_pic}`
                          : ''
                      }
                      alt="Profile"
                      className="w-5 h-5 object-cover rounded-full"
                    />
                  ) : (
                    <div className='w-[32px] h-5 rounded-full bg-gray-200 flex items-center justify-center text-[10px]'>
                      <span className="text-[7px] font-medium text-gray-700">
                        {assignIdToFilter?.name.charAt(0).toUpperCase()}
                      </span>
                    </div>
                  )}
                  {assignIdToFilter ? (
                    <span className='text-[#52555a] font-normal capitalize truncate text-[13px] leading-4 whitespace-nowrap max-w-[80px] w-full overflow-hidden text-ellipsis text-start'>
                      {assignIdToFilter.name}
                    </span>
                  ) : null}
                </div>
              </Link>
            ) : null}
          </div>
        )

      },
      sort: (a: { assigned: string }, b: { assigned: any }) => a.assigned.localeCompare(b.assigned),
    },
    // {
    //   accessor: 'priority',
    //   header: 'Priority',
    //   cell: info => {
    //     const priorityIdToFilter = priorityData.find((pri: any) => pri.name === info.row.original.item_priority);

    //     return (
    //       <div className=' relative'>
    //         {priorityIdToFilter ? (
    //           <Link to={`/ticket/${info.row.id}`} className='w-full'>
    //             <div data-title={priorityIdToFilter.name} className='table-tooltip flex items-center max-w-fit font-[500] leading-[1.2] bg-light-gray-100 rounded-full text-[0.75rem] px-[0.7rem] py-[5px] gap-[6px]'>

    //               {priorityIdToFilter ? (
    //                 <span className='text-[#52555a] font-normal capitalize truncate text-[13px] leading-4 whitespace-nowrap max-w-[80px] w-full overflow-hidden text-ellipsis text-start'>
    //                   {priorityIdToFilter.name}
    //                 </span>
    //               ) : null}
    //             </div>
    //           </Link>
    //         ) : null}
    //       </div>

    //     )

    //   },
    //   sort: (a: { priority: string }, b: { priority: any }) => a.priority.localeCompare(b.priority),
    // },
    {
      accessor: 'item_sentiment',
      header: 'Sentiment',
      cell: info => {

        const getSentimentColor = (sentiment) => {
          switch (sentiment?.toLowerCase()) {
            case 'positive':
              return '#16a34a33';
            case 'negative':
              return '#dc26261a';
            case 'request':
              return '#1d4ed81a';
            default:
              return 'transparent';
          }
        };

        const sentiment = info.row.original.item_sentiment?.trim(); // Trim whitespace
        const bgColor = getSentimentColor(sentiment);


        return (
          <Link to={`/ticket/${info.row.original.id}`} className='w-full'>
            {sentiment === 'positive' ? (
              <span
                className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 h-[24px] text-xs font-medium text-green-700 capitalize"
                style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
              >
                {sentiment}
              </span>
            ) : sentiment === 'negative' ? (
              <span
                className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 h-[24px] text-xs font-medium text-red-700 capitalize "
                style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
              >
                {sentiment}
              </span>
            ) : sentiment === 'spam' ? (
              <span
                className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 h-[24px] text-xs font-medium text-gray-600 capitalize"
                style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
              >
                {sentiment}
              </span>
            ) : sentiment === 'mixed' ? (
              <span
                className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 h-[24px] text-xs font-medium text-blue-700 capitalize"
                style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
              >
                {sentiment}
              </span>
            ) : null}
          </Link>

        )

      },
      sort: (a, b) => a.item_sentiment.localeCompare(b.item_sentiment), // Updated sorting function to use correct key
    },
    {
      accessor: 'activity',
      header: 'Activity',
      cell: info => {
        const formattedDate = format(new Date(info.row.original.created_at), 'MMM d');
        const formattedDatetooltip = format(new Date(info.row.original.created_at), 'MMM d');
        return (
          <Link to={`/ticket/${info.row.original.id}`} className='w-full'>
            <div data-title={formattedDatetooltip} className="table-tooltip text-[#52555a] font-normal capitalize truncate text-[13px] leading-4 whitespace-nowrap max-w-[80px] w-full overflow-hidden text-ellipsis text-start">
              {formattedDate}
            </div>
          </Link>
        );
      },
      sort: (a: { created_at: string }, b: { created_at: string }) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
    },
  ], []);

  // delete row

  useEffect(() => {
    setSelectedRowsCount(Object.keys(rowSelection)?.length);
  }, [rowSelection]);


  const handleCheckboxClick = (row, id) => {
    setSelectedRows((prevSelectedRows) => ({
      ...prevSelectedRows,
      [row.id]: !prevSelectedRows[row.id]
    }));
    setRowSelectionDelete((prevSelection) => {

      if (prevSelection?.includes(id)) {
        return prevSelection.filter((itemId) => itemId !== id);
      } else {
        return [...prevSelection, id];
      }
    });

    setIsModalOpenCheckbox(true);
  };

  const handleConfirmDelete = (id) => {
    setDeleteOk(true)
  }

  const handleDeleteConfirm = async () => {
    setDeleteLoading(true);
    try {
      let groupIdsToDelete = Array.isArray(rowSelectionDelete) ? rowSelectionDelete : [rowSelectionDelete];

      const deleteResponse = await dispatch(deleteItemFeedback(baseId, groupIdsToDelete));

      if (!deleteResponse || !deleteResponse.data) {
        throw new Error('Invalid delete response received');
      }

      // Process the delete response
      const successfulDeletes = deleteResponse.data.filter((item) => item.isSuccess).map((item) => item.id);
      const failedDeletes = deleteResponse.data.filter((item) => !item.isSuccess);

      if (successfulDeletes.length > 0) {
        toast.success(`Successfully deleted item(s)`);
      }
      // successfulDeletes.forEach((id) => {
      //   toast.success(`Successfully deleted item ${id}`);
      // });
      failedDeletes.forEach((item) => {
        toast.error(`Failed to delete item ${item.id}: ${item.reason}`);
      });

      if (successfulDeletes.length > 0) {
        const updatedResponse = await _get_ItemFeedbackList(
          pagination.pageIndex + 1,
          pagination.pageSize,
          activeFilters,
          false,
          itemAssignedTo
        );

        if (updatedResponse.status === 200) {
          const newData = updatedResponse.data.items;
          const newPagination = {
            currentPage: updatedResponse.data.currentPage,
            hasNextPage: updatedResponse.data.hasNextPage,
            totalPages: updatedResponse.data.totalPages,
            pageSize: updatedResponse.data.pageSize,
            pageIndex: updatedResponse.data.currentPage - 1,
            // total: updatedResponse.data.totalInsight,
          };

          setData(newData);
          setPagination(newPagination);
          setTotalPages(updatedResponse.data.totalPages);

          // Update local storage with new data
          localStorage.setItem('cachedTopicData', JSON.stringify({
            data: newData,
            pagination: newPagination,
            timestamp: Date.now(),
          }));

          // Reset states
          setRowSelectionDelete([]);
          setDeleteOk(false);
          setIsModalOpenCheckbox(false);
          setSelectedRows({});
          setRowSelection({});
          setSelectedRowsCount(0);
        } else {
          console.error('Error fetching updated item feedback list:', updatedResponse);
          toast.error('Failed to fetch updated item feedback list');
        }
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(`An error occurred: ${error.response.data.message}`);
      } else {
        console.error('Error deleting insight groups:', error);
        toast.error('An error occurred while deleting insight groups. Please try again later.');
      }
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleDeleteSingleRow = async (rowId?: string) => {
    
    setDeleteLoading(true);
    try {
      let idsToDelete: string[];
      
      if (rowId) {
        // Single row deletion
        idsToDelete = [rowId];
      } 
  
    const deleteResponse = await dispatch(deleteItemFeedback(baseId, idsToDelete));

      if (!deleteResponse || !deleteResponse.data) {
        throw new Error('Invalid delete response received');
      }

      // Process the delete response
      const successfulDeletes = deleteResponse.data.filter((item) => item.isSuccess).map((item) => item.id);
      const failedDeletes = deleteResponse.data.filter((item) => !item.isSuccess);

      if (successfulDeletes.length > 0) {
        toast.success(`Successfully deleted item(s)`);
      }
      // successfulDeletes.forEach((id) => {
      //   toast.success(`Successfully deleted item ${id}`);
      // });
      failedDeletes.forEach((item) => {
        toast.error(`Failed to delete item ${item.id}: ${item.reason}`);
      });

      if (successfulDeletes.length > 0) {
        const updatedResponse = await _get_ItemFeedbackList(
          pagination.pageIndex + 1,
          pagination.pageSize,
          activeFilters,
          false,
          itemAssignedTo
        );

        if (updatedResponse.status === 200) {
          const newData = updatedResponse.data.items;
          const newPagination = {
            currentPage: updatedResponse.data.currentPage,
            hasNextPage: updatedResponse.data.hasNextPage,
            totalPages: updatedResponse.data.totalPages,
            pageSize: updatedResponse.data.pageSize,
            pageIndex: updatedResponse.data.currentPage - 1,
            // total: updatedResponse.data.totalInsight,
          };

          setData(newData);
          setPagination(newPagination);
          setTotalPages(updatedResponse.data.totalPages);

          // Update local storage with new data
          localStorage.setItem('cachedTopicData', JSON.stringify({
            data: newData,
            pagination: newPagination,
            timestamp: Date.now(),
          }));

          // Reset states
          setRowSelectionDelete([]);
          setDeleteOk(false);
          setIsModalOpenCheckbox(false);
          setSelectedRows({});
          setRowSelection({});
          setSelectedRowsCount(0);
        } else {
          console.error('Error fetching updated item feedback list:', updatedResponse);
          toast.error('Failed to fetch updated item feedback list');
        }
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(`An error occurred: ${error.response.data.message}`);
      } else {
        console.error('Error deleting insight groups:', error);
        toast.error('An error occurred while deleting insight groups. Please try again later.');
      }
    } finally {
      setDeleteLoading(false);
    }
  };


  const table = useReactTable({
    data,
    columns,
    state: {
      pagination,
      rowSelection,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
    onPaginationChange: setPagination,


  });


  useEffect(() => {
    const applyStyles = () => {
      const tableElement = document.querySelector('.feedback-col') as HTMLElement | null;
      const tableElement1 = document.querySelector('.feedback-thead') as HTMLElement | null;
      const isTabScreen = window.matchMedia('(max-width: 1499px)').matches;
      const isSmallScreen = window.matchMedia('(max-width: 767px)').matches;
      const hasSearchFilter = !!activeFilters.search;

      // Apply margin-top to tableElement based on screen size
      if (tableElement) {
        if (isSmallScreen) {
          tableElement.style.marginTop = sectionHeight ? `calc(50px + ${sectionHeight}px)` : '50px';
        } else if (isTabScreen) {
          tableElement.style.marginTop = sectionHeight ? `calc(45px + ${sectionHeight}px)` : '46px';
        } else {
          tableElement.style.marginTop = sectionHeight ? `calc(87px + ${sectionHeight}px)` : '87px';
        }
      }

      // Apply top style to tableElement1 based on screen size and search filter
      if (tableElement1) {
        if (isSmallScreen) {
          tableElement1.style.top = sectionHeight ? `calc(80px + ${sectionHeight}px)` : '80px';
        } else if (isTabScreen) {
          tableElement1.style.top = sectionHeight ? `calc(101px + ${sectionHeight}px)` : '101px';
        } else {
          tableElement1.style.top = sectionHeight ? `calc(101px + ${sectionHeight}px)` : '101px';
        }

        if (sectionHeight) {
          tableElement1.style.borderTop = '1px solid #e5e7eb';
        } else {
          tableElement1.style.borderTop = 'none';
        }
      }
    };

    applyStyles();

    window.addEventListener('resize', applyStyles);
    return () => {
      window.removeEventListener('resize', applyStyles);
    };
  }, [sectionHeight]);


  return (
    <>
      <div className='mx-auto max-w-full'>
        <div className="px-4 sm:px-6 lg:px-8 feedback-table">
          <div className="flow-root h-full">
            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8 h-full">
              <div className="inline-block min-w-full py-2 align-middle h-full max-w-full">
                <div className="relative  h-full block pb-[5rem]">
                  <table className="block border-collapse overflow-x-auto w-full feedback-col overflow-y-auto feedback-table-height h-full">
                    <thead
                      className='mq1499:sticky mq3000:fixed bg-white grid feedback-thead feedback-table-row'
                    // className='sticky md:fixed lg:fixed bg-white grid mq767:top-[0px] mq1023:top-[116px] mq3000:top-[101px] feedback-thead feedback-table-row'
                    >
                      {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id} className='bg-white flex relative w-full'>
                          {headerGroup.headers.map(header => {

                            return (
                              <th key={header.id} colSpan={header.colSpan} scope="col"
                                className={`sticky bg-white flex flex-1 py-[0.75rem] px-[1rem] cursor-pointer text-[0.75rem] items-center justify-start
                                   leading-[1rem] text-[#52555a] font-[500] border-b  border-solid border-light-gray-200 min-w-[50px] md:min-w-[160px] lg:min-w-[50px]
                                  ${header.id === 'select' ? 'max-w-[50px] [&>div]:px-0' : ''} 
                                    ${header.id === 'ID' ? 'max-w-[60px]' : ''}
                                       ${header.id === 'Title' ? 'max-w-[-webkit-fill-available] !min-w-[370px]' : ''}
                                     ${header.id === 'Board' ? '!min-w-[106px]' : ''}
                                     ${header.id === 'Status' ? '!min-w-[110px]' : ''}
                                      ${header.id === 'Label' ? 'max-w-[250px] !min-w-[250px]' : ''}
                                      ${header.id === 'Assigned' ? '!min-w-[106px]' : ''}
                                     ${header.id === 'Priority' ? '!min-w-[106px]' : ''}
                                    ${header.id === 'Sentiment' ? '!min-w-[106px]' : ''}
                                     ${header.id === 'Activity' ? '!min-w-[106px]' : ''}
                                  `}
                              >
                                <div
                                  {...{
                                    className: header.column.getCanSort()
                                      ? 'cursor-pointer select-none '
                                      : '',
                                    onClick: header.column.getToggleSortingHandler(),
                                  }}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                  {{
                                    asc: ' 🔼',
                                    desc: ' 🔽',
                                  }[header.column.getIsSorted() as string] ?? null}
                                </div>
                              </th>
                            )
                          })}
                          <th
                            className={`sticky bg-white flex flex-1 py-[0.75rem] px-[1rem] cursor-pointer text-[0.75rem] items-center justify-start
                             leading-[1rem] text-[#52555a] font-[500] border-r border-b  border-solid border-light-gray-200 min-w-10 max-w-[56px]`}
                          ></th>
                        </tr>
                      ))}
                    </thead>
                    <div>
                      {loading ? (
                        <div className="flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 191px)' }}>
                          <div className="simple-spinner-loader">
                            <span></span>
                          </div>
                        </div>
                      ) : pagination.totalPages === 0 ? (
                        <div className="flex items-center justify-center z-50" style={{ height: 'calc(100vh - 191px)' }}>
                          <p>No data found</p>
                        </div>
                      ) : (

                        <tbody className="grid">
                          {table.getRowModel().rows.map((row) => {
                            const allRowsSelected = table.getIsAllRowsSelected();
                              const dropdownGroups: DropdownGroup[] = [
                              {
                                items: [
                                  {
                                    label: `Delete`,
                                    className: "py-[0.375rem] px-[0.75rem] bg-white text-[#000000eb] text-[13px] hover:bg-[#e7e7e8]",
                                    onClick: () => handleDeleteSingleRow(row.original.id),
                                  },
                                ],
                              },
                            ];
                            return (
                              <tr
                                key={row.id}
                                data-selected={allRowsSelected ? true : selectedRows[row.id]}
                                onClick={() => handleSelect(row)}
                                className={`border-b border-solid border-light-gray-200 bg-white flex w-full relative ${selectedRows[row.id] ? 'bg-gray-100' : ''}`}
                              >
                                {row.getVisibleCells().map((cell) => (
                                  <td
                                    key={cell.id}
                                    className={`flex items-center justify-start text-start px-[1rem] py-[0.9rem] leading-[1rem]
                                         flex-1 text-dark-black-200 max-w-[350px]
                                         ${/(_select)$/.test(cell.id) ? 'max-w-[50px] [&>div]:px-0' : ''} 
                                         ${/(_ID)$/.test(cell.id) ? 'max-w-[60px]' : ''}
                                         ${/(_Title)$/.test(cell.id) ? '!max-w-[-webkit-fill-available] min-w-[370px]' : ''}
                                         ${/(_Board)$/.test(cell.id) ? '!min-w-[106px] pl-1' : ''}
                                         ${/(_Label)$/.test(cell.id) ? 'max-w-[250px] !min-w-[250px]' : ''}
                                         ${/(_Status)$/.test(cell.id) ? '!min-w-[110px]' : ''}
                                         ${/(_Assigned)$/.test(cell.id) ? '!min-w-[106px]' : ''}
                                         ${/(_Priority)$/.test(cell.id) ? '!min-w-[106px]' : ''}
                                         ${/(_Sentiment)$/.test(cell.id) ? '!min-w-[106px]' : ''}
                                       `}
                                  >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                  </td>
                                ))}
                                <td
                                  className={`flex items-center text-start px-[0.75rem] py-[1rem] leading-[1rem] flex-1 max-w-[50px] text-dark-black-200 justify-center [&>button]:w-auto [&>button]:bg-transparent [&>button]:hover:bg-transparent`}
                                >
                                  <MenuDropdown
                                    groups={dropdownGroups}
                                    image={
                                      <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        aria-hidden="true"
                                        focusable="false"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <circle cx="12" cy="12" r="1"></circle>
                                        <circle cx="12" cy="5" r="1"></circle>
                                        <circle cx="12" cy="19" r="1"></circle>
                                      </svg>
                                    }
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>



                      )}
                    </div>

                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed z-10 bg-white border-t border-solid border-light-gray-200 py-[0.5rem] px-[1rem] bottom-0 pagination-bottom">
        <div className="relative flex items-center justify-between">



          <div className="flex items-center gap-2">
            <span className="flex items-center gap-1">
              <div className='text-[0.875rem] font-[500] text-[#000000eb]'>Page
              </div>
            </span>
            <span className="flex items-center gap-1">

              <input
                type="number"
                value={pagination.currentPage}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) : 1;
                  setPagination((prevPagination) => ({
                    ...prevPagination,
                    currentPage: page,
                  }));
                }}
                className="w-[4rem] h-[2rem] text-[0.8125rem] px-[0.75rem] rounded-md border border-solid border-light-gray-100"
              />

              <span className='mr-1 text-[#000000eb] text-[0.8125rem]'>  of{' '}

                {totalPages}
              </span>
            </span>


            <div className='flex items-center gap-1'>

              <select
                value={table.getState().pagination.pageSize}
                onChange={e => {
                  table.setPageSize(Number(e.target.value))
                }}
                className="w-[6rem] h-[2rem] text-[0.8125rem] px-[0.75rem] rounded-md border border-solid border-light-gray-100 block"
              >
                {[25, 50, 100].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
              <div className='mr-1 text-[#000000eb] text-[0.8125rem] ml-[14px]'>
                {pagination.total} Items
              </div>
            </div>


          </div>
          <div className='flex gap-2'>
            <button
              className="border rounded py-2 px-2"
              onClick={handlePrevPage}
              disabled={pagination.currentPage === 1}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-2 w-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
              </svg>

            </button>
            <button
              className="border rounded py-2 px-2"
              onClick={handleNextPage}
              disabled={!pagination.hasNextPage}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-2 w-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
              </svg>

            </button>
          </div>
        </div>
      </div>
      <div open={isModalOpenCheckbox} onClose={() => { }} className={`relative z-10 w-full ${isModalOpenCheckbox ? 'common-slider-one' : 'common-slider-two'}`}
      >
        {selectedRowsCount > 0 && (
          <div className="fixed inset-y-0 right-0 flex checkbox-modal h-[100%] w-[800px] max-w-[800px] bottom-0 top-[-70px]">
            <div className="flex h-auto flex-col overflow-y-scroll bg-white py-6 rounded-lg" style={{ boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1),0 2px 4px -1px rgba(0, 0, 0, 0.06)' }}>
              <div className="px-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <span className='text-dark-black-100 text-[13px] font-[700]'>{selectedRowsCount} selected</span>
                  <div className="flex items-center">
                    <div className="w-full flex items-center justify-between">
                      <div className="flex items-center gap-[0.5rem]">
                        <Button className='text-[13px] text-dark-black-100 font-[600] [&>span]:font-[600] h-[32px] rounded-md flex-row-reverse border-[#e7e7e8] shadow-none'
                          after={<TagIcon className='w-[13px] h-[13px] text-dark-black-100 font-[600] opacity-80' strokeWidth={3} />} variant="outline">
                          Add tags
                        </Button>
                        <Button
                          onClick={(id) => handleConfirmDelete(id)}
                          className='text-[13px] text-dark-black-100 font-[600] [&>span]:font-[600] h-[32px] rounded-md flex-row-reverse border-[#e7e7e8] shadow-none'
                          after={<TrashIcon className='w-[13px] h-[13px] text-dark-black-100 font-[600] opacity-80' strokeWidth={3} />} variant="outline">
                          Delete
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        )}
      </div>
      <Transition.Root show={deleteOk} as={Fragment}>
        <Dialog as="div" className="relative z-50 md:z-10 lg:z-10" onClose={() => { }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center sm:items-start h-full items-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  transition
                  className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4
                   data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full
                    sm:max-w-[500px] sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                >
                  <span className='font-[600]'>Delete
                    <span> {selectedRowsCount} Ticket</span>
                  </span>

                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to delete your insights?
                    </p>
                  </div>
                  <div className="mt-5 sm:mt-4 flex gap-2 justify-end">
                    <Button
                      type="button"
                      onClick={handleDeleteConfirm}
                      className={`bg-red-600 hover:hover:bg-red-500 focus-visible:outline-red-600 relative
                      [&>span]:flex [&>span]:items-center [&>span]:gap-[8px]`}
                    >
                      Delete
                      {deleteLoading && (
                        <div className="simple-spinner">
                          <span></span>
                        </div>
                      )}
                    </Button>
                    <button
                      type="button"
                      onClick={() => setDeleteOk(false)}
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>

              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );

}


export default TopicTable;