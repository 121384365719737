import { user_resetpass } from './resetPasswordAPI'
import Nav from '../../../components/NavBar'
import { formFieldsReset } from '../formFields'
import { Button, Input } from '@lemonsqueezy/wedges'
import FeedbackLogo from "../../../assets/feedback.png"


const ResetPassword = () => {

	const handle_resetPass = (e: any) => {
		e.preventDefault()
		let code = e.target[0].value
		let password = e.target[1].value
		user_resetpass(code, password)
			.then((res) => {
				console.log('resres', res)
			})
			.catch((err) => {
				console.log('check', err)
			})
	}

	return (<>
		{/* <Nav /> */}
		<div className="flex min-h-full flex-1 flex-col justify-center max-w-[487px] m-auto px-4">
		<div className="sm:mx-auto sm:w-full sm:max-w-md">
			<img src={FeedbackLogo} alt="" className='max-w-[208px] w-full m-auto'/>
			<h2 className="mt-12 text-center font-bold leading-9 text-dark-black-100 text-[24px]">
				Reset Password
				</h2>
			</div>

			<div className="mt-6 grid grid-cols-1 gap-4">
				<div className="bg-white">
					<form className="space-y-6" action="#" onSubmit={(e) => handle_resetPass(e)}>
						{formFieldsReset.map(field => (
							<div key={field.id}>
								<label htmlFor={field.id} className="wg-label inline-flex shrink-0 items-center gap-1 text-surface-900 wg-antialiased">
									{field.label}
								</label>
								<div className="mt-2">
									<Input
										id={field.id}
										name={field.id}
										type={field.type}
										autoComplete={field.autoComplete}
										required={field.required}
										className="focus:outline-indigo-600"
									/>
								</div>
							</div>
						))}
						<div className='flex justify-end'>
							<Button
								type="submit"
								className=" bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600"
								size="sm" 		
									>
								Submit
							</Button>
						</div>
					</form>

					<div>
					</div>
				</div>
			</div>
		</div>

	</>
	)
}
export default ResetPassword
