// @ts-nocheck
import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store/store';
import { Toaster, toast } from 'sonner';
import { formatDistance, parse, parseISO, isValid } from 'date-fns';
import { ViewIntegrationProps } from './type';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { deleteItemFeedback } from '../feedbacks/feedback-redux/feedbacks.actions';



const CustomerIntegration: React.FC<ViewIntegrationProps> = () => {
  const [localUsers, setLocalUsers] = useState<any[]>([]);
  const { data, loading, error } = useSelector((state: RootState) => state.integration);
  const dispatch = useAppDispatch();
  const baseIdString = localStorage.getItem('baseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;
console.log(data[0],"data");

  const navigate = useNavigate();
  const { id } = useParams();
    // Fetch local storage data and set it to state
    useEffect(() => {
      const storedUsers = localStorage.getItem('baseMembersDetails'); 
      if (storedUsers) {
        try {
          const parsedUsers = JSON.parse(storedUsers);
          setLocalUsers(parsedUsers);
        } catch (error) {
          console.error('Error parsing local storage data:', error);
        }
      }
    }, []);

    // const handleCustomerDelete = async () => {
    //   try {
    //     let idToDelete: number | null = null; 
    
    //     if (data[0]?.id) {
    //       // Check if the ID is a valid number
    //       const parsedId = parseInt(data[0].id, 10);
    //       if (!isNaN(parsedId)) {
    //         idToDelete = parsedId;
    //       } else {
    //         throw new Error('Invalid ID format. Expected a number.');
    //       }
    //     }
    
    //     if (idToDelete === null) {
    //       toast.error('No valid item selected for deletion');
    //       return;
    //     }
        
    //     const deleteResponse = await dispatch(deleteItemFeedback(baseId, [idToDelete]));
        
    //     if (!deleteResponse || !deleteResponse.data) {
    //       throw new Error('Invalid delete response received');
    //     }
    
    //     if (deleteResponse.status === 422) {
    //       throw new Error(deleteResponse.data.message || 'Validation error occurred');
    //     }
    
    //     const successfulDelete = deleteResponse.data.find((item: any) => item.isSuccess && item.id === idToDelete);
    //     const failedDelete = deleteResponse.data.find((item: any) => !item.isSuccess && item.id === idToDelete);
    
    //     if (successfulDelete) {
    //       toast.success(`Successfully deleted item`);
    //       navigate(`/${baseId}/integrations`);
    //     } else if (failedDelete) {
    //       toast.error(`Failed to delete item: ${failedDelete.reason || 'Unknown error'}`);
    //     } else {
    //       throw new Error('Unexpected response format');
    //     }
    
    //   } catch (error: any) {
    //     console.error('Error during delete:', error);
    //     toast.error(`An error occurred: ${error.message || 'Unknown error'}`);
    //   }
    // };

    
  if (loading) return (
    <div className=" flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 0px)' }}>
      <div className="simple-spinner-loader">
        <span></span>
      </div>
    </div>
  );
  if (error) return <div>Error: {error}</div>;
  if (data?.length === 0) return <div>No feedback available</div>;


  const createdTimeSidebar = formatDistance(new Date(data[0]?.created_at), new Date(), { includeSeconds: true });
  const createdTimeAgoSidebar = createdTimeSidebar?.replace(/^about\s/, '');
  const updatedTimeSidebar = formatDistance(new Date(data[0]?.updated_at), new Date(), { includeSeconds: true });
  const updatedTimeAgoSidebar = updatedTimeSidebar?.replace(/^about\s/, '');

  // Function to find the user's name by ID
  const findUserNameById = (id: number) => {
    const user = localUsers.find(user => user.id === id);
    return user ? user.name : 'Unknown User';
  };


  // Handle last sync timestamp
  const lastSyncTimestamp = data[0]?.last_sync;
  let lastSyncTime = 'Unknown Time';

  if (lastSyncTimestamp) {
    const parsedLastSync = parse(lastSyncTimestamp, 'HH:mm:ss', new Date());
    if (isValid(parsedLastSync)) {
      lastSyncTime = formatDistance(parsedLastSync, new Date(), { addSuffix: true });
      lastSyncTime = lastSyncTime.replace(/^about\s|^in\s/, '');
    }
  }

  return (
    <>
      <Toaster richColors />
      <aside className='flex flex-col justify-stretch min-h-[3rem] border-b border-t-0 border-l-0 border-r border-solid border-light-gray-200 px-[1.5rem] pt-[1.5rem]
       items-start gap-4  pb-[1.5rem]'>
       

        <div className='mx-[-24px] min-w-[340px] w-full'>
          <div className="w-full px-[1.5rem]">
          {
              data[0].last_sync &&
              <div className="w-full flex items-center justify-between lg:justify-start py-1">
                <div className="mr-2 py-1 px-0 font-normal text-dark-black-200 text-[13px] w-[35%]">
                  Last sync
                </div>
                <div className="relative w-[65%]">
                  <span className=" text-[13px] leading-4 whitespace-normal max-w-[140px] w-full text-[#52555a]  text-start break-words font-medium">
                  {lastSyncTime}
                  </span>
                </div>
              </div>
            }

            <div className="w-full flex items-center justify-between lg:justify-start py-1">
              <div className="mr-2 py-1 px-0 font-normal text-dark-black-200 text-[13px] w-[35%]">
                Created
              </div>
              <div className="relative w-[65%]">
                <span className="text-[13px] leading-4 whitespace-normal max-w-[140px] w-full text-[#52555a] text-start break-words font-medium">
                  {createdTimeAgoSidebar} ago
                </span>
              </div>
            </div>
            {createdTimeAgoSidebar !== updatedTimeAgoSidebar && (
              <div className="w-full flex items-center justify-between lg:justify-start py-1">
                <div className="mr-2 py-1 px-0 font-normal text-dark-black-200 text-[13px] w-[35%]">
                  Updated
                </div>
                <div className="relative w-[65%]">
                  <span className="text-[13px] leading-4 whitespace-normal max-w-[140px] w-full text-[#52555a] text-start break-words font-medium">
                    {updatedTimeAgoSidebar} ago
                  </span>
                </div>
              </div>)}
            {
              data[0].created_by &&
              <div className="w-full flex items-center justify-between lg:justify-start py-1">
                <div className="mr-2 py-1 px-0 font-normal text-dark-black-200 text-[13px] w-[35%]">
                  Created By
                </div>
                <div className="relative w-[65%]">
                  <span className=" text-[13px] leading-4 whitespace-normal max-w-[140px] w-full text-[#52555a]  text-start break-words font-medium">
                  {findUserNameById(data[0].created_by)}
                  </span>
                </div>
              </div>
            }

            {
              data[0]?.updated_by &&
              <div className="w-full flex items-center justify-between lg:justify-start py-1">
                <div className="mr-2 py-0 px-0 font-normal text-dark-black-200 text-[13px] w-[35%] leading-inherit">
                  Updated By
                </div>
                <div className="relative w-[65%]">
                  <span className=" text-[13px] leading-4 whitespace-normal max-w-[140px] w-full text-[#52555a]  text-start break-words font-medium">
                  {findUserNameById(data[0].created_by)}
                  </span>
                </div>
              </div>
            }

          </div>
        </div>
        {/* <div className='flex justify-end w-full px-[1.5rem]'>
          <Link to="" onClick={ handleCustomerDelete} className="underline text-[13px] text-[#b91c1c]">Delete</Link>
        </div> */}
    
      </aside>

    </>
  );
};

export default CustomerIntegration;
