import { HomeIcon } from '@heroicons/react/20/solid'
import { Button } from '@lemonsqueezy/wedges';
import { useNavigate } from 'react-router-dom';

const Header: React.FC = () => {
    const navigate = useNavigate()
    return (
        <div className='top-0 z-0 flex shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white sm:gap-x-6 sm:px-6'>
            <div className='flex flex-1 gap-x-4 justify-between self-stretch lg:gap-x-4 items-center p-2.5'>
                <div onClick={() => navigate(-1)} className='w-auto flex justify-center items-center cursor-pointer'>
                    <HomeIcon className='h-5 w-5 mr-2 shrink-0 text-[#737373] cursor-pointer' aria-hidden='true' />
                    <p className='text-base font-[600] fontfamily'>Header</p>
                </div>
                <Button
                    type="button"
                    className=" bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600"
                    size="sm"
                >
                    Edit
                </Button>

            </div>
        </div>
    )
}
export default Header