
import { _baseRequest } from "../../../services/api";

const API_END_POINT = {
    LOGIN: "/v1/auth/login",
    LOGOUT: "/v1/auth/logout",

}

export const user_login = async (
    email: string,
    password: string,
    deviceId: string,
    deviceName: string
) => {
    return _baseRequest(API_END_POINT.LOGIN, "POST", {
        email,
        password,
        deviceId,
        deviceName
    }).then((loginResponse) => {
        return loginResponse;
    }).catch((error) => {
        // if(error?.data?.status === 401){
        //     alert(error?.data?.message)
        // }
        return error
    });
};

export const user_logout = async () => {
    return _baseRequest(API_END_POINT.LOGOUT, "GET").then((res) => {
        return res
    }).catch((error) => {
        return error
    });
};

