import {
  FETCH_DASHBOARD_LIST_REQUEST, FETCH_DASHBOARD_LIST_SUCCESS, FETCH_DASHBOARD_LIST_FAILURE,
  DashboardActionTypes, DashboardType, FETCH_INSIGHT_DASHBOARD_LIST_REQUEST, FETCH_INSIGHT_DASHBOARD_LIST_SUCCESS,
  FETCH_INSIGHT_DASHBOARD_LIST_FAILURE, FETCH_ACTIVITY_DASHBOARD_LIST_REQUEST, FETCH_ACTIVITY_DASHBOARD_LIST_SUCCESS,
  FETCH_ACTIVITY_DASHBOARD_LIST_FAILURE
} from './dashboard.types';

interface DashboardState {
  loading: boolean;
  error: string;
  success: boolean;
  data: DashboardType | null;
}

const initialState: DashboardState = {
  loading: false,
  error: '',
  success: false,
  data: null,
};

const dashboardReducer = (state = initialState, action: DashboardActionTypes): DashboardState => {
  switch (action.type) {
    case FETCH_DASHBOARD_LIST_REQUEST:
    case FETCH_INSIGHT_DASHBOARD_LIST_REQUEST:
    case FETCH_ACTIVITY_DASHBOARD_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        success: false
      };
    case FETCH_DASHBOARD_LIST_SUCCESS:
    case FETCH_INSIGHT_DASHBOARD_LIST_SUCCESS:
    case FETCH_ACTIVITY_DASHBOARD_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
        error: ''
      };
    case FETCH_DASHBOARD_LIST_FAILURE:
    case FETCH_INSIGHT_DASHBOARD_LIST_FAILURE:
    case FETCH_ACTIVITY_DASHBOARD_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default dashboardReducer;
