export const SUBMIT_FEEDBACK_REQUEST = 'SUBMIT_FEEDBACK_REQUEST';
export const SUBMIT_FEEDBACK_SUCCESS = 'SUBMIT_FEEDBACK_SUCCESS';
export const SUBMIT_FEEDBACK_FAILURE = 'SUBMIT_FEEDBACK_FAILURE';

export const FETCH_FEEDBACK_REQUEST = 'FETCH_FEEDBACK_REQUEST';
export const FETCH_FEEDBACK_SUCCESS = 'FETCH_FEEDBACK_SUCCESS';
export const FETCH_FEEDBACK_FAILURE = 'FETCH_FEEDBACK_FAILURE';


export const FETCH_ITEM_ACTIVITY_REQUEST = 'FETCH_ITEM_ACTIVITY_REQUEST';
export const FETCH_ITEM_ACTIVITY_SUCCESS = 'FETCH_ITEM_ACTIVITY_SUCCESS';
export const FETCH_ITEM_ACTIVITY_FAILURE = 'FETCH_ITEM_ACTIVITY_FAILURE';

export const FETCH_ITEM_TYPE_REQUEST = 'FETCH_ITEM_TYPE_REQUEST';
export const FETCH_ITEM_TYPE_SUCCESS = 'FETCH_ITEM_TYPE_SUCCESS';
export const FETCH_ITEM_TYPE_FAILURE = 'FETCH_ITEM_TYPE_FAILURE';

export const DELETE_ITEM_ACTIVITY_REQUEST = 'DELETE_ITEM_ACTIVITY_REQUEST';
export const DELETE_ITEM_ACTIVITY_SUCCESS = 'DELETE_ITEM_ACTIVITY_SUCCESS';
export const DELETE_ITEM_ACTIVITY_FAILURE = 'DELETE_ITEM_ACTIVITY_FAILURE';

export const FETCH_ITEM_INSIGHT_REQUEST = 'FETCH_ITEM_INSIGHT_REQUEST';
export const FETCH_ITEM_INSIGHT_SUCCESS = 'FETCH_ITEM_INSIGHT_SUCCESS';
export const FETCH_ITEM_INSIGHT_FAILURE = 'FETCH_ITEM_INSIGHT_FAILURE';

export const UPDATE_FEEDBACK_REQUEST = 'UPDATE_FEEDBACK_REQUEST';
export const UPDATE_FEEDBACK_SUCCESS = 'UPDATE_FEEDBACK_SUCCESS';
export const UPDATE_FEEDBACK_FAILURE = 'UPDATE_FEEDBACK_FAILURE';

export const FETCH_ALL_ACTIVITY_BY_BASE_ID_REQUEST = 'FETCH_ALL_ACTIVITY_BY_BASE_ID_REQUEST';
export const FETCH_ALL_ACTIVITY_BY_BASE_ID_SUCCESS = 'FETCH_ALL_ACTIVITY_BY_BASE_ID_SUCCESS';
export const FETCH_ALL_ACTIVITY_BY_BASE_ID_FAILURE = 'FETCH_ALL_ACTIVITY_BY_BASE_ID_FAILURE';

export const SUBMIT_INSIGHT_REQUEST = 'SUBMIT_INSIGHT_REQUEST';
export const SUBMIT_INSIGHT_SUCCESS = 'SUBMIT_INSIGHT_SUCCESS';
export const SUBMIT_INSIGHT_FAILURE = 'SUBMIT_INSIGHT_FAILURE';

export const DELETE_INSIGHT_REQUEST = 'DELETE_INSIGHT_REQUEST';
export const DELETE_INSIGHT_SUCCESS = 'DELETE_INSIGHT_SUCCESS';
export const DELETE_INSIGHT_FAILURE = 'DELETE_INSIGHT_FAILURE';


export const FETCH_LIST_BY_ID_INSIGHT_REQUEST = 'FETCH_LIST_BY_ID_INSIGHT_REQUEST';
export const FETCH_LIST_BY_ID_INSIGHT_SUCCESS = 'FETCH_LIST_BY_ID_INSIGHT_SUCCESS';
export const FETCH_LIST_BY_ID_INSIGHT_FAILURE = 'FETCH_LIST_BY_ID_INSIGHT_FAILURE';

export const UPDATE_INSIGHT_REQUEST = 'UPDATE_INSIGHT_REQUEST';
export const UPDATE_INSIGHT_SUCCESS = 'UPDATE_INSIGHT_SUCCESS';
export const UPDATE_INSIGHT_FAILURE = 'UPDATE_INSIGHT_FAILURE';

export const DELETE_FEEDBACK_LIST_REQUEST = 'DELETE_FEEDBACK_LIST_REQUEST';
export const DELETE_FEEDBACK_LIST_SUCCESS = 'DELETE_FEEDBACK_LIST_SUCCESS';
export const DELETE_FEEDBACK_LIST_FAILURE = 'DELETE_FEEDBACK_LIST_FAILURE';

export const FETCH_FEEDBACK_LISTING_REQUEST = 'FETCH_FEEDBACK_LISTING_REQUEST';
export const FETCH_FEEDBACK_LISTING_SUCCESS = 'FETCH_FEEDBACK_LISTING_SUCCESS';
export const FETCH_FEEDBACK_LISTING_FAILURE = 'FETCH_FEEDBACK_LISTING_FAILURE';

export interface FeedbackType {
    item_assigned_to: any;
    item_label: any;
    allActivityItem: any[];
    itemId: any;
    base_id: number;
    board_id?: number;
    item_type?: string;
    item_title?: string;
    item_text?: string;
    item_details: string;
    item_tag_id?: number;
    item_base_status_id?: [];
    item_sentiment?: string;
    item_meta?: string;
    item_revenue?: string;
    item_user_id?: number;
    item_group_id?: number;
    item_priority?: string;
    item_source_type?: string;
    item_summary?: string;
    item_source_id?: string;
    ai_check?: string;
    ai_check_time?: string;
    ai_check_source?: string;
    ai_check_tokens?: string;
    insight_ticket_id?: number;
    item_user_name?: string;
    item_user_unique_identifier?: string;
    item_user_email_id?: string;
    item_user_phone_number?: string;
    item_user_meta?: string;
    item_user_segment?: string;
    item_user_group_name?: string;
    item_user_group_unique_identifier?: string;
    item_user_group_revenue?: string;
    item_user_group_segment?: string;
    item_user_group_meta?: string;
    id?: number;
    name?: string;
    color?: string;
    base_status_title?: string;
    tag_title?: string;
    item_customer_name?: string;
    item_customer_unique_id?: string;
    item_customer_email_id?: string;
    item_customer_phone_number?: string;
    item_customer_meta?: string;
    item_customer_label?: string;
    item_customer_type?: string;
    item_customer_image?: string;
    item_customer_revenue?: string;
    item_customer_parent_id?: number;
}



export interface FeedbackState {
    loading: boolean;
    error: string;
    success: boolean;
    data: FeedbackType[];
    insightGroups: any[];
    item: AllActivityItem;
}

export interface AllActivityItem {
    itemsActivities: any[];
    totalItemActivity: number;
    hasNextPage: boolean;
    pageSize: number;
    totalPages: number;
}

interface SubmitFeedbackRequestAction {
    type: typeof SUBMIT_FEEDBACK_REQUEST;
}

interface SubmitFeedbackSuccessAction {
    type: typeof SUBMIT_FEEDBACK_SUCCESS;
}

interface SubmitFeedbackFailureAction {
    type: typeof SUBMIT_FEEDBACK_FAILURE;
    payload: string;
}

// export type FeedbackActionTypes =
//     | SubmitFeedbackRequestAction
//     | SubmitFeedbackSuccessAction
//     | SubmitFeedbackFailureAction;
// Action types union
export type FeedbackActionTypes =
    | { type: typeof SUBMIT_FEEDBACK_REQUEST }
    | { type: typeof SUBMIT_FEEDBACK_SUCCESS, payload: FeedbackType }
    | { type: typeof SUBMIT_FEEDBACK_FAILURE, payload: string }
    | { type: typeof FETCH_FEEDBACK_REQUEST }
    | { type: typeof FETCH_FEEDBACK_SUCCESS, payload: FeedbackType }
    | { type: typeof FETCH_FEEDBACK_FAILURE, payload: string }
    | { type: typeof FETCH_ITEM_ACTIVITY_REQUEST }
    | { type: typeof FETCH_ITEM_ACTIVITY_SUCCESS, payload: FeedbackType }
    | { type: typeof FETCH_ITEM_ACTIVITY_FAILURE, payload: string }
    | { type: typeof FETCH_ITEM_TYPE_REQUEST }
    | { type: typeof FETCH_ITEM_TYPE_SUCCESS, payload: FeedbackType }
    | { type: typeof FETCH_ITEM_TYPE_FAILURE, payload: string }
    | { type: typeof DELETE_ITEM_ACTIVITY_REQUEST }
    | { type: typeof DELETE_ITEM_ACTIVITY_SUCCESS, payload: number }
    | { type: typeof DELETE_ITEM_ACTIVITY_FAILURE, payload: string }
    | { type: typeof FETCH_ITEM_INSIGHT_REQUEST }
    | { type: typeof FETCH_ITEM_INSIGHT_SUCCESS, payload: FeedbackType }
    | { type: typeof FETCH_ITEM_INSIGHT_FAILURE, payload: string }
    | { type: typeof UPDATE_FEEDBACK_REQUEST }
    | { type: typeof UPDATE_FEEDBACK_SUCCESS, payload: FeedbackType }
    | { type: typeof UPDATE_FEEDBACK_FAILURE, payload: string }
    | { type: typeof FETCH_ALL_ACTIVITY_BY_BASE_ID_REQUEST }
    | { type: typeof FETCH_ALL_ACTIVITY_BY_BASE_ID_SUCCESS, payload: FeedbackType }
    | { type: typeof FETCH_ALL_ACTIVITY_BY_BASE_ID_FAILURE, payload: string }
    | { type: typeof SUBMIT_INSIGHT_REQUEST }
    | { type: typeof SUBMIT_INSIGHT_SUCCESS, payload: FeedbackType }
    | { type: typeof SUBMIT_INSIGHT_FAILURE, payload: string }
    | { type: typeof DELETE_INSIGHT_REQUEST }
    | { type: typeof DELETE_INSIGHT_SUCCESS, payload: number }
    | { type: typeof DELETE_INSIGHT_FAILURE, payload: string }
    | { type: typeof FETCH_LIST_BY_ID_INSIGHT_REQUEST }
    | { type: typeof FETCH_LIST_BY_ID_INSIGHT_SUCCESS, payload: FeedbackType }
    | { type: typeof FETCH_LIST_BY_ID_INSIGHT_FAILURE, payload: string }
    | { type: typeof UPDATE_INSIGHT_REQUEST }
    | { type: typeof UPDATE_INSIGHT_SUCCESS, payload: FeedbackType }
    | { type: typeof UPDATE_INSIGHT_FAILURE, payload: string }
    | { type: typeof DELETE_FEEDBACK_LIST_REQUEST }
    | { type: typeof DELETE_FEEDBACK_LIST_SUCCESS, payload: number[] }
    | { type: typeof DELETE_FEEDBACK_LIST_FAILURE, payload: string }
    | { type: typeof FETCH_FEEDBACK_LISTING_REQUEST }
    | { type: typeof FETCH_FEEDBACK_LISTING_SUCCESS, payload: FeedbackType[] }
    | { type: typeof FETCH_FEEDBACK_LISTING_FAILURE, payload: string }
