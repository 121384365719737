import { Dispatch } from 'redux';
import { _fetchActivityDashboard, _fetchDashboardList, _fetchInsightDashboard } from './dashboard.api';
import { DashboardActionTypes, FETCH_DASHBOARD_LIST_REQUEST, FETCH_DASHBOARD_LIST_SUCCESS, FETCH_DASHBOARD_LIST_FAILURE, FETCH_INSIGHT_DASHBOARD_LIST_REQUEST, FETCH_INSIGHT_DASHBOARD_LIST_SUCCESS, FETCH_INSIGHT_DASHBOARD_LIST_FAILURE, FETCH_ACTIVITY_DASHBOARD_LIST_REQUEST, FETCH_ACTIVITY_DASHBOARD_LIST_SUCCESS, FETCH_ACTIVITY_DASHBOARD_LIST_FAILURE } from './dashboard.types';

export const fetchDashboardListData = (baseId: number, fromDate: string, toDate: string) => async (dispatch: Dispatch<DashboardActionTypes>) => {
  dispatch({ type: FETCH_DASHBOARD_LIST_REQUEST });

  try {
    const response = await _fetchDashboardList(baseId, fromDate, toDate);
    dispatch({
      type: FETCH_DASHBOARD_LIST_SUCCESS,
      payload: response.data
    });
    localStorage.setItem('dashboardDataList', JSON.stringify(response.data));
    return response.data;
  } catch (error: any) {
    console.error('API error:', error);
    dispatch({
      type: FETCH_DASHBOARD_LIST_FAILURE,
      payload: error.message
    });
    throw error;
  }
};

export const fetchInsightDashboardList = (baseId: number) => async (dispatch: Dispatch<DashboardActionTypes>) => {
  dispatch({ type: FETCH_INSIGHT_DASHBOARD_LIST_REQUEST });

  try {
    const response = await _fetchInsightDashboard(baseId);
    dispatch({
      type: FETCH_INSIGHT_DASHBOARD_LIST_SUCCESS,
      payload: response.data
    });
    return response.data;
  } catch (error: any) {
    console.error('API error:', error);
    dispatch({
      type: FETCH_INSIGHT_DASHBOARD_LIST_FAILURE,
      payload: error.message
    });
    throw error;
  }
};

export const fetchActivityDashboardList = (baseId: number) => async (dispatch: Dispatch<DashboardActionTypes>) => {
  dispatch({ type: FETCH_ACTIVITY_DASHBOARD_LIST_REQUEST });

  try {
    const response = await _fetchActivityDashboard(baseId);    
    dispatch({
      type: FETCH_ACTIVITY_DASHBOARD_LIST_SUCCESS,
      payload: response.data
    });
    return response.data;
  } catch (error: any) {
    console.error('API error:', error);
    dispatch({
      type: FETCH_ACTIVITY_DASHBOARD_LIST_FAILURE,
      payload: error.message
    });
    throw error;
  }
};