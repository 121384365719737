import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import {user_logout} from "../../auth/login/loginAPI"
import { Button } from '@lemonsqueezy/wedges';

type userProps = {
    data : {
        email:string
    } | null | undefined
}
const Nav : React.FC <userProps> = ({data}) => {
	const [navbar, setNavbar] = useState(false)

    let navigate = useNavigate();
	const _logout = () => {
		user_logout().then((res)=>{
          if(res?.status === 200){
			 localStorage.clear()
			 navigate("/login")
		  }
		})
	}

	return (
		<nav className='m-auto max-w-screen-xl px-5'>
			<div className=' justify-between px-0 mx-auto md:items-center md:flex md:px-8'>
				<div>
					<div className='flex items-center justify-between py-3 md:py-5 md:block'>
						<Button
							onClick={() => navigate(-1)}
							type='button'
							className=" bg-light-pink  hover:bg-gray-100 text-black-100 focus-visible:outline-light-pink flex items-center [&>span]:flex [&>span]:items-center"
							size="sm" 	
							>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 24 24'
								strokeWidth={1.5}
								stroke='currentColor'
								className='w-4 h-4 mr-1'>
								<path strokeLinecap='round' strokeLinejoin='round' d='M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18' />
							</svg>
							Go back
						</Button>

						<div className='md:hidden'>
							<button className='p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border' onClick={() => setNavbar(!navbar)}>
								{navbar ? (
									<svg xmlns='http://www.w3.org/2000/svg' className='w-6 h-6' viewBox='0 0 20 20' fill='currentColor'>
										<path
											fillRule='evenodd'
											d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
											clipRule='evenodd'
										/>
									</svg>
								) : (
									<svg
										xmlns='http://www.w3.org/2000/svg'
										className='w-6 h-6'
										fill='none'
										viewBox='0 0 24 24'
										stroke='currentColor'
										strokeWidth={2}>
										<path strokeLinecap='round' strokeLinejoin='round' d='M4 6h16M4 12h16M4 18h16' />
									</svg>
								)}
							</button>
						</div>
					</div>
				</div>
				<div>
					<div className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${navbar ? 'block' : 'hidden'}`}>
						<div className='items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0'>
							<div className='text-gray-600 flex items-center'>
								<p className='text-sm leading-6 text-gray-900'>{localStorage.getItem("userEmail")} | <b onClick={_logout} className='font-semibold text-indigo-600 cursor-pointer'>Logout</b></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</nav>
	)
}

export default Nav
